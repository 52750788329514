<template>
    <div class="row align-items-center mb-3 " >
        <div class="col-lg-9">
            <div class="d-flex justify-content-start align-content-start">
                <div>
                    <router-link class="router-link-to-profile text-decoration-none text-black" :to="{ name: 'showSingleGroup', params: { id: item.id } }">
                        
                                <img crossorigin="anonymous" 
                                    v-if="!item.cover"
                                    src="@/assets/groups/groups-default-cover-photo-gray.jpg"
                                    class="logoimage"
                                    alt="Loading..."
                                />
                                <img crossorigin="anonymous"  v-else :src="item.cover.src_url" class="logoimage" alt="..." />
                    </router-link>
                </div>
                <div :class="$i18n.locale == 'en' ? 'ms-3' : 'me-3'">
                    <router-link class="text-decoration-none text-black" :to="{ name: 'showSingleGroup', params: { id: item.id } }">
                        <span class="fw-bold">{{ item.name }}</span>
                    </router-link>
                    <p class="mb-0 text-muted" >
                        <span v-if="item.type == 1">{{ $t("Group Public") }} - </span>  
                        <span v-if="item.type == 0">{{ $t("Group Private") }} - </span>  
                            <span>{{ item.member_count }} {{ $t("member") }}</span>
                            .
                            <span v-if="item.last_activity_date != null"
                            > {{ $t("last active")}} {{ format_date(item.last_activity_date) }}</span
                            >
                            <span v-if="item.last_activity_date == null">
                            {{ $t("There are no other posts") }}</span
                            >
                    </p>
                    <!-- <p class="mb-0 text-muted">
                        <span :class="$i18n.locale == 'en' ? 'me-1' : 'ms-1'" v-for="cat in item.categories" :key="cat.id">
                            {{ $t(cat.name) }} 
                        </span>
                        
                    </p> -->
                </div>
            </div>
            <!-- {{ item }} -->
        </div>
        <div class="col-lg-3">
            <div class="mt-2 d-flex justify-content-center">
                <button @click="$router.push({ name : 'showSingleGroup' , params : { id: item.id }})" class="btn btn-primary " style="background-color: white;" v-if="item.member == null" >
                    <span class="mx-1 fw-bold" style="color: #8e4fff;">{{ $t('Join Group') }}</span>
                </button>
                <button v-else-if="item.member.access == 0" @click="$router.push({ name : 'showSingleGroup' , params : { id: item.id }})"  class="btn btn-primary d-flex align-items-center " style="background-color: #ffffff; color:#8e4fff"  >
                    <svg fill="#8e4fff" width="20" height="20" viewBox="0 0 200 200" data-name="Layer 1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><title></title><path d="M100,15a85,85,0,1,0,85,85A84.93,84.93,0,0,0,100,15Zm0,150a65,65,0,1,1,65-65A64.87,64.87,0,0,1,100,165Zm25-91.5-29,35L76,94c-4.5-3.5-10.5-2.5-14,2s-2.5,10.5,2,14c6,4.5,12.5,9,18.5,13.5,4.5,3,8.5,7.5,14,8,1.5,0,3.5,0,5-1l3-3,22.5-27c4-5,8-9.5,12-14.5,3-4,4-9,.5-13L138,71.5c-3.5-2.5-9.5-2-13,2Z"></path></g>
                    </svg>
                    <span class="mx-1 fw-bold" style="color: #8e4fff;">{{ $t('Pending') }}</span>
                </button>
                <button v-else-if="item.member.access == 1 || item.has_role === true" @click="$router.push({ name : 'showSingleGroup' , params : { id: item.id }})"  class="btn btn-primary d-flex align-items-center " style="background-color: #ffffff; color:#8e4fff;"  >
                    <svg fill="#8e4fff" width="20" height="20" viewBox="0 0 200 200" data-name="Layer 1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><title></title><path d="M100,15a85,85,0,1,0,85,85A84.93,84.93,0,0,0,100,15Zm0,150a65,65,0,1,1,65-65A64.87,64.87,0,0,1,100,165Zm25-91.5-29,35L76,94c-4.5-3.5-10.5-2.5-14,2s-2.5,10.5,2,14c6,4.5,12.5,9,18.5,13.5,4.5,3,8.5,7.5,14,8,1.5,0,3.5,0,5-1l3-3,22.5-27c4-5,8-9.5,12-14.5,3-4,4-9,.5-13L138,71.5c-3.5-2.5-9.5-2-13,2Z"></path></g>
                    </svg>
                    <span class="mx-1 fw-bold" style="color: #8e4fff;">{{ $t('Visit') }}</span>
                </button>
            </div>
        </div>
    </div>

</template>

<script>
import formatTime from "@/mixins/formatTime";

export default {
    name: "CardGroupComponent",
    props: ["item"],
    mixins: [formatTime],
    methods: {
        uppercaseFirst(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
    }
}
</script>

<style lang="scss" scoped>
.logoimage {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
</style>