<template>
  <!-- When Refresh Page -->
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      style="height: calc(100vh - 64px)"
      v-if="waittogetdata"
    >
      <div class="spinner-grow text-success" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow text-success" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-grow text-success" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div v-if="this.postinfo.type == 10">
        <PostQuestion
          :question="postinfo"
          @toggleAnswers="toggleAnswers"
          @show-reactions="showReactions"
          @hide-reactions="hideReactions"
          @react="react"
          @update-answers-count="updateAnswersCount"
          @delete-question="deleteQuestion"
          @data-update-question="dataFromUpdateQuestion"
        />
      </div>
      <div
        v-else
        class="row m-0"
        :dir="!DefineDeriction() ? 'ltr' : 'rtl'"
        style="height: calc(100vh - 71px) !important"
      >
        <div class="row">
          <!-- For SideBar -->
          <div class="col-4" style="background-color: #fdf8f8">
            <!-- For Close -->
            <button
              type="button"
              class="btn-close mb-3"
              @click="back"
              aria-label="Close"
            ></button>

            <!-- For Header Post -->
            <div
              v-if="postinfo.model"
              class="title mb-3 d-flex justify-content-between"
            >
              <div class="one d-flex justify-content-center align-items-center">
                <div v-if="postinfo.model.has_media_profile === true">
                  <img crossorigin="anonymous" 
                    v-if="postinfo.model.media[0].collection_name === 'profile'"
                    :src="postinfo.model.media[0].src_url"
                    alt=""
                  />
                  <img crossorigin="anonymous"  v-else :src="postinfo.model.media[1].src_url" alt="" />
                </div>
                <div v-else>
                  <img crossorigin="anonymous"  src="@/assets/img_friends/default1.png" alt="" />
                </div>
                <div class="info mx-2">
                  <p class="m-0 fw-bold" v-if="postinfo.model_type != 'Page'">
                    {{ postinfo.model.first_name }}
                    {{ postinfo.model.last_name }}
                  </p>
                  <p class="m-0 fw-bold" v-else>
                    {{ postinfo.model.name }}
                    <small style="color: green; font-weight: bold"> page</small>
                  </p>
                  <p class="m-0 text-muted">
                    {{ format_date(postinfo.created_at) }}
                  </p>
                </div>
              </div>
              <div class="tow mb-3">
                <!-- <ButtonsPost :post="postinfo" :USER="USER"></ButtonsPost> -->
              </div>
            </div>

            <!-- For Body -->
            <div class="body mb-5">
              <!-- <p style="word-wrap: break-word">{{ postinfo.content }}</p> -->
              <PostContent :post="postinfo"></PostContent>
            </div>

            <!-- Interaction Raw -->
            <div class="interaction mb-3 d-flex justify-content-between">
              <div>
                <span>{{ comments.length }} {{ $t("Comment") }}</span>
              </div>
              <div
                class="d-flex justify-content-center"
                @click="showinteractionmodel"
              >
                <div class="mx-1 d-flex align-items-center">
                  <img crossorigin="anonymous" 
                    class="img-reaction"
                    src="@/assets/reactions/like.gif"
                    alt="Like emoji"
                  />
                  <span class="">{{
                    postinfo.interactions_count_types.like
                  }}</span>
                </div>
                <div class="mx-1 d-flex align-items-center">
                  <img crossorigin="anonymous" 
                    class="img-reaction"
                    src="@/assets/reactions/love.gif"
                    alt="Like emoji"
                  />
                  <span class="">{{
                    postinfo.interactions_count_types.love
                  }}</span>
                </div>
                <div class="mx-1 d-flex align-items-center">
                  <img crossorigin="anonymous" 
                    class="img-reaction"
                    src="@/assets/reactions/care.gif"
                    alt="Like emoji"
                  />
                  <span class="">{{
                    postinfo.interactions_count_types.care
                  }}</span>
                </div>
                <div class="mx-1 d-flex align-items-center">
                  <img crossorigin="anonymous" 
                    class="img-reaction"
                    src="@/assets/reactions/haha.gif"
                    alt="Like emoji"
                  />
                  <span class="">{{
                    postinfo.interactions_count_types.haha
                  }}</span>
                </div>
                <div class="mx-1 d-flex align-items-center">
                  <img crossorigin="anonymous" 
                    class="img-reaction"
                    src="@/assets/reactions/wow.gif"
                    alt="Like emoji"
                  />
                  <span class="">{{
                    postinfo.interactions_count_types.wow
                  }}</span>
                </div>
                <div class="mx-1 d-flex align-items-center">
                  <img crossorigin="anonymous" 
                    class="img-reaction"
                    src="@/assets/reactions/angry.gif"
                    alt="Like emoji"
                  />
                  <span class="">{{
                    postinfo.interactions_count_types.angry
                  }}</span>
                </div>
                <div class="mx-1 d-flex align-items-center">
                  <img crossorigin="anonymous" 
                    class="img-reaction"
                    src="@/assets/reactions/sad.gif"
                    alt="Like emoji"
                  />
                  <span class="">{{
                    postinfo.interactions_count_types.sad
                  }}</span>
                </div>
              </div>
            </div>

            <InteractionComponent
              v-if="interactionModel && interaction_model"
              v-on:change="change()"
              :id="postinfo.id"
            />

            <!-- {{ comments[0] }} -->

            <!-- For Add Comment -->
            <div class="add_comment w-100 mt-3 ps-2 pe-2">
              <Mentionable
                class="w-100 Mentionable"
                :keys="['@']"
                :items="friendstagsarray"
                filtering-disabled
                offset="6"
                insert-space
                @open="loadIssues()"
                @search="loadIssues($event)"
              >
                <textarea
                  dir="auto"
                  class="vcomments__add-input ar_vcomment"
                  :placeholder="
                    !recording ? $t(`Your comment`) : $t('voice comment')
                  "
                  v-model="comment_body"
                  @input="resize()"
                  ref="textarea"
                ></textarea>

                <EmojiPicker
                  v-click-outside="onClickOutside"
                  v-if="this.visible_emoji == true"
                  :native="true"
                  @select="onSelectEmoji"
                />

                <span
                  :class="
                    DefineDeriction() ? 'commentsvgboxar' : 'commentsvgbox'
                  "
                  v-if="!recording"
                >
                  <span class="pointer" @click="open_emoji">
                    <EmojiSvg></EmojiSvg>
                  </span>
                  <label
                    for="uploadMediaFromCommentInShowPostPage"
                    class="pointer"
                  >
                    <AddMediaComment></AddMediaComment>
                  </label>
                  <!-- Audio  -->
                  <svg
                    @click="startRecord"
                    id="audioId"
                    class="pointer"
                    width="25"
                    height="25"
                    fill="#7a7a7a"
                    viewBox="0 0 1920 1920"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M960.315 96.818c-186.858 0-338.862 152.003-338.862 338.861v484.088c0 186.858 152.004 338.862 338.862 338.862 186.858 0 338.861-152.004 338.861-338.862V435.68c0-186.858-152.003-338.861-338.861-338.861M427.818 709.983V943.41c0 293.551 238.946 532.497 532.497 532.497 293.55 0 532.496-238.946 532.496-532.497V709.983h96.818V943.41c0 330.707-256.438 602.668-580.9 627.471l-.006 252.301h242.044V1920H669.862v-96.818h242.043l-.004-252.3C587.438 1546.077 331 1274.116 331 943.41V709.983h96.818ZM960.315 0c240.204 0 435.679 195.475 435.679 435.68v484.087c0 240.205-195.475 435.68-435.68 435.68-240.204 0-435.679-195.475-435.679-435.68V435.68C524.635 195.475 720.11 0 960.315 0Z"
                        fill-rule="evenodd"
                      ></path>
                    </g>
                  </svg>
                  <!-- Audio  -->
                </span>
                <span
                  :class="
                    DefineDeriction()
                      ? 'audoicontrolcommentsvgboxar'
                      : 'audoicontrolcommentsvgbox'
                  "
                  class=""
                  v-else
                >
                  <span class="text-danger" id="CommenttimerInShowPost"
                    >00:00</span
                  >
                  <svg
                    @click="clearRecord"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 16 22"
                    class="pointer mx-1"
                    style="color: #ff7f7f"
                  >
                    <path
                      d="M5,0,3,2H0V4H16V2H13L11,0ZM15,5H1V19.5A2.5,2.5,0,0,0,3.5,22h9A2.5,2.5,0,0,0,15,19.5Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <svg
                    width="25"
                    @click="sendMessage()"
                    class="pointer"
                    height="25"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M12.0004 18.5816V12.5M12.7976 18.754L15.8103 19.7625C17.4511 20.3118 18.2714 20.5864 18.7773 20.3893C19.2166 20.2182 19.5499 19.8505 19.6771 19.3965C19.8236 18.8737 19.4699 18.0843 18.7624 16.5053L14.2198 6.36709C13.5279 4.82299 13.182 4.05094 12.7001 3.81172C12.2814 3.60388 11.7898 3.60309 11.3705 3.80958C10.8878 4.04726 10.5394 4.8182 9.84259 6.36006L5.25633 16.5082C4.54325 18.086 4.18671 18.875 4.33169 19.3983C4.4576 19.8528 4.78992 20.2216 5.22888 20.394C5.73435 20.5926 6.55603 20.3198 8.19939 19.7744L11.2797 18.752C11.5614 18.6585 11.7023 18.6117 11.8464 18.5933C11.9742 18.5769 12.1036 18.5771 12.2314 18.5938C12.3754 18.6126 12.5162 18.6597 12.7976 18.754Z"
                        stroke="#7a7a7a"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </g>
                  </svg>
                </span>

                <template #no-result>
                  <div
                    class="allfriendsbox"
                    v-if="loadingForNewREsultInMentaion"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="allfriendsbox" v-else>
                    {{ $t("No Result") }}
                  </div>
                </template>

                <template #item-@="{ item }">
                  <div class="allfriendsbox">
                    {{ item.name }}
                  </div>
                </template>
              </Mentionable>
              <input
                type="file"
                id="uploadMediaFromCommentInShowPostPage"
                ref="uploadMediaFromCommentInShowPostPage"
                @change="uploadMediaFromCommentInShowPostPage()"
                hidden
                accept="image/*"
              />
            </div>

            <!-- preview div upload media -->
            <div class="text-center">
              <div v-if="Commentmedia" class="position-relative">
                <button
                  type="button"
                  class="btn-close"
                  :disabled="loadingStoreComment"
                  aria-label="Close"
                  style="
                    position: absolute;
                    top: 2px;
                    right: 2px;
                    cursor: pointer;
                    z-index: 9999;
                  "
                  @click="deletephotoFromComment"
                ></button>
                <img crossorigin="anonymous" 
                  :src="Commentmedia.url"
                  style="
                    width: 75px;
                    height: 75px;
                    margin: 2px;
                    border: 1px solid #777;
                  "
                />
              </div>
            </div>
            <!-- End preview div -->

            <!-- Load Photo -->
            <div class="px-1 mb-3" v-if="loadingStoreComment">
              <div class="" v-if="Commentmedia" style="border-radius: 25px">
                <p class="mb-1 text-danger text-center">
                  {{ $t("File is uploaded") }}
                </p>
                <div class="progress">
                  <div
                    id="brogressbarForOne"
                    class="progress-bar progress-bar-striped"
                    role="progressbar"
                    style="border-radius: 25px"
                    :style="`width: ${progressWidth}%`"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
            <!-- Load Photo -->

            <button
              :disabled="
                (!comment_body && !Commentmedia) ||
                loadingStoreComment ||
                recording
              "
              class="vcomments__add-button mt-2 mb-3 btn text-center"
              @click="commentStore"
            >
              {{ $t("Comment") }}
            </button>

            <!-- Switch Between voice Comment and other  -->
            <p dir="auto">
              <small
                @click="changetypeComment(false)"
                :class="!onlyVoice ? 'activecomment' : ''"
                class="pointer"
                >{{ $t("Comments") }}</small
              >
              <small
                @click="changetypeComment(true)"
                :class="onlyVoice ? 'activecomment' : ''"
                class="pointer mx-3"
                >{{ $t("Voice Comments") }}</small
              >
            </p>
            <!-- Switch Between voice Comment and other  -->

            <!-- Show All Comment -->
            <div v-if="!loading_comment" class="comments mt-5">
              <div
                class="vcomments__item"
                v-for="(comment, index) in comments"
                :key="index"
              >
                <div class="row">
                  <div class="col-12">
                    <div class="comment-avatar">
                      <img crossorigin="anonymous" 
                        v-if="comment.model.has_media_profile === false"
                        src="@/assets/img_friends/default1.png"
                        alt=""
                      />
                      <img crossorigin="anonymous" 
                        v-else
                        :src="comment.model.media[0].src_url"
                        alt=""
                      />
                    </div>
                    <div class="vcomments__info">
                      <span class="vcomments__author">
                        <router-link
                          class="router-link-to-profile"
                          :to="{
                            name: 'homeMyProfile',
                            params: { id: EncryptionRoute(comment.user_id) },
                          }"
                          >{{ comment.model.name }}
                        </router-link>
                      </span>
                      <div class="dropdown text-center">
                        <small
                          style="cursor: pointer"
                          :id="'dropdownMenuButton1' + comment.id"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img crossorigin="anonymous" 
                            class="image-dropdown"
                            src="@/assets/images/more.png"
                            alt="action more"
                          />
                        </small>
                        <ul
                          class="dropdown-menu"
                          :aria-labelledby="'dropdownMenuButton1' + comment.id"
                        >
                          <li v-if="USER.id == comment.user_id">
                            <a
                              class="dropdown-item"
                              @click="delete_comment(comment.id)"
                            >
                              <DeleteSvg></DeleteSvg>
                              {{ $t("Delete") }}
                            </a>
                          </li>
                          <li
                            v-if="USER.id == comment.user_id"
                            class="dropdown-item"
                            style="cursor: pointer"
                            @click="
                              editeComment(
                                comment.id,
                                comment.comment_body,
                                comment.tags,
                                comment
                              )
                            "
                          >
                            <img crossorigin="anonymous" 
                              src="@/assets/images/edit_mode_icon.png"
                              style="width: 20px; height: 20px"
                              alt=""
                            />
                            {{ $t("Edit") }}
                          </li>
                          <li
                            v-if="USER.id != comment.user_id"
                            class="dropdown-item"
                            style="cursor: pointer"
                            :data-bs-target="
                              '#staticCommentReport' + comment.id
                            "
                            data-bs-toggle="modal"
                          >
                            <!-- <ReportPostSvg></ReportPostSvg> -->
                            {{ $t("Report Comment") }}
                          </li>
                        </ul>
                      </div>
                      <!-- <CommentReportComponent :id="comment.id" /> -->

                      <span class="vcomments__time d-block mx-3">{{
                        format_date(comment.created_at)
                      }}</span>
                    </div>
                    <div
                      class="card-body mt-4 mb-2 px-5"
                      dir="auto"
                      :id="`showbodycomment${comment.id}`"
                    >
                      <div class="card-text vcomments__quote">
                        <div
                          v-if="comment.comment_body"
                          v-html="
                            this.showcontentComment(
                              comment.comment_body,
                              comment.tags
                            )
                          "
                          v-linkified
                        ></div>
                        <div
                          v-if="
                            comment.media && comment.media.media_type == 'Image'
                          "
                          class="mt-2 text-center"
                        >
                          <img crossorigin="anonymous" 
                            :src="comment.media.src_url"
                            style="
                              max-width: 150px;
                              max-height: 150px;
                              min-height: 75px;
                              min-width: 75px;
                            "
                            alt="CommentImage"
                          />
                        </div>
                        <div
                          v-else-if="
                            comment.media && comment.media.media_type == 'Sound'
                          "
                          class="mt-2 text-center"
                        >
                          <audio :src="comment.media.src_url" controls></audio>
                        </div>
                      </div>
                    </div>
                    <div
                      class="card-body mt-4 mb-2 visually-hidden px-5"
                      dir="auto"
                      :id="`editebodycomment${comment.id}`"
                    >
                      <Mentionable
                        class="w-100 Mentionable"
                        :keys="['@']"
                        :items="friendstagsarray"
                        filtering-disabled
                        offset="6"
                        insert-space
                        @open="loadIssues()"
                        @search="loadIssues($event)"
                      >
                        <textarea
                          dir="auto"
                          class="vcomments__add-input ar_vcomment"
                          v-model="editecommentinput"
                          :disabled="loadingStatusForEditeComment"
                          ref="textarea"
                        ></textarea>
                        <span
                          :class="
                            DefineDeriction()
                              ? 'commentsvgboxar'
                              : 'commentsvgbox'
                          "
                          v-if="!recording"
                        >
                          <span class="pointer" @click="open_emoji_Edit">
                            <EmojiSvg></EmojiSvg>
                          </span>
                        </span>
                        <EmojiPicker
                          v-click-outside="onClickOutsideEdit"
                          v-if="this.visible_emoji_Edit == true"
                          :native="true"
                          @select="onSelectEmojiEdit"
                        />
                        <template #no-result>
                          <div
                            class="allfriendsbox"
                            v-if="loadingForNewREsultInMentaion"
                          >
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span class="visually-hidden">Loading...</span>
                          </div>
                          <div class="allfriendsbox" v-else>
                            {{ $t("No Result") }}
                          </div>
                        </template>

                        <template #item-@="{ item }">
                          <div class="allfriendsbox">
                            {{ item.name }}
                          </div>
                        </template>
                      </Mentionable>
                      <div
                        class="d-flex mt-2 justify-content-start align-items-center"
                      >
                        <button
                          type="button"
                          v-if="
                            !IfdeleteImageFromCommentOrNot &&
                            !CommentmediaEdite &&
                            SelecteditemForEdit &&
                            SelecteditemForEdit.media
                          "
                          class="btn-close d-block"
                          @click="deleteImageFromComment"
                          aria-label="Close"
                        ></button>
                        <label
                          :for="`uploadMediaFroEditComment${comment.id}`"
                          class="mx-2 pointer"
                        >
                          <AddMediaComment></AddMediaComment>
                        </label>
                        <input
                          type="file"
                          :id="`uploadMediaFroEditComment${comment.id}`"
                          @change="uploadMediaFroEditComment()"
                          hidden
                          accept="image/*"
                        />
                      </div>
                      <div class="mt-2 text-center position-relative">
                        <img crossorigin="anonymous" 
                          v-if="CommentmediaEdite"
                          :src="CommentmediaEdite.url"
                          style="width: 150px; height: 150px"
                        />
                        <img crossorigin="anonymous" 
                          v-if="
                            !IfdeleteImageFromCommentOrNot &&
                            !CommentmediaEdite &&
                            SelecteditemForEdit &&
                            SelecteditemForEdit.media &&
                            SelecteditemForEdit.media.media_type == 'Image'
                          "
                          :src="SelecteditemForEdit.media.src_url"
                          style="
                            max-width: 150px;
                            max-height: 150px;
                            min-width: 75px;
                            min-height: 75px;
                          "
                          alt=""
                        />
                      </div>
                      <br />

                      <button
                        v-if="!loadingStatusForEditeComment"
                        :disabled="
                          (editecommentinput == '' ||
                            editecommentinput == null) &&
                          !CommentmediaEdite &&
                          ((SelecteditemForEdit &&
                            !SelecteditemForEdit.media) ||
                            (SelecteditemForEdit &&
                              SelecteditemForEdit.media &&
                              IfdeleteImageFromCommentOrNot))
                        "
                        class="btn btn-sm btn-success ms-3"
                        @click="EditCommentApi(comment.id, 0)"
                      >
                        {{ $t("Edit") }}
                      </button>
                      <button v-else class="btn btn-sm btn-success ms-3">
                        {{ $t("loading...") }}
                      </button>
                    </div>
                  </div>

                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <!-- Start Button Reaction to comment -->
                    <AddReactionToComment
                      v-on:changeint="changeint"
                      :comment="comment"
                    ></AddReactionToComment>
                    <!-- End Button Reaction to comment -->

                    <div class="ms-3 w-100 d-flex justify-content-between">
                      <span
                        style="cursor: pointer"
                        @click="showreplybox(comment.id, comment.model.name)"
                        >{{ $t("Reply") }}</span
                      >
                      <span
                        @click="showAllRepliesBox(comment.id)"
                        v-if="comment.count_replies > 0"
                        style="cursor: pointer"
                      >
                        {{ $t("AllReply") }} {{ comment.count_replies }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="visually-hidden mt-3"
                    :id="`addreplybox${comment.id}`"
                  >
                    <Mentionable
                      class="w-100 Mentionable"
                      :keys="['@']"
                      :items="friendstagsarray"
                      filtering-disabled
                      offset="6"
                      insert-space
                      @open="loadIssues()"
                      @search="loadIssues($event)"
                    >
                      <EmojiPicker
                        v-click-outside="onClickOutside1"
                        v-if="this.visible_emoji1 == true"
                        :native="true"
                        @select="onSelectEmoji1"
                      />
                      <textarea
                        dir="auto"
                        class="vcomments__add-input ar_vcomment"
                        :placeholder="
                          !recording1 ? $t(`Your comment`) : $t('voice comment')
                        "
                        v-model="reply_body"
                        @keyup.enter="replyStore(comment.id)"
                      ></textarea>

                      <span
                        :class="
                          DefineDeriction()
                            ? 'commentsvgboxar'
                            : 'commentsvgbox'
                        "
                        v-if="!recording1"
                      >
                        <span class="pointer" @click="open_emoji1">
                          <EmojiSvg></EmojiSvg>
                        </span>
                        <label
                          :for="`uploadMediaFromReplyOnCommentInShowPostPage${comment.id}`"
                          class="pointer"
                        >
                          <AddMediaComment></AddMediaComment>
                        </label>
                        <!-- Audio  -->
                        <svg
                          @click="startRecord1"
                          id="audioId"
                          class="pointer"
                          width="25"
                          height="25"
                          fill="#7a7a7a"
                          viewBox="0 0 1920 1920"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M960.315 96.818c-186.858 0-338.862 152.003-338.862 338.861v484.088c0 186.858 152.004 338.862 338.862 338.862 186.858 0 338.861-152.004 338.861-338.862V435.68c0-186.858-152.003-338.861-338.861-338.861M427.818 709.983V943.41c0 293.551 238.946 532.497 532.497 532.497 293.55 0 532.496-238.946 532.496-532.497V709.983h96.818V943.41c0 330.707-256.438 602.668-580.9 627.471l-.006 252.301h242.044V1920H669.862v-96.818h242.043l-.004-252.3C587.438 1546.077 331 1274.116 331 943.41V709.983h96.818ZM960.315 0c240.204 0 435.679 195.475 435.679 435.68v484.087c0 240.205-195.475 435.68-435.68 435.68-240.204 0-435.679-195.475-435.679-435.68V435.68C524.635 195.475 720.11 0 960.315 0Z"
                              fill-rule="evenodd"
                            ></path>
                          </g>
                        </svg>
                        <!-- Audio  -->
                      </span>
                      <span
                        :class="
                          DefineDeriction()
                            ? 'audoicontrolcommentsvgboxar'
                            : 'audoicontrolcommentsvgbox'
                        "
                        class=""
                        v-else
                      >
                        <span
                          class="text-danger"
                          :id="`timerOnReplyOnCommentInShowPost${comment.id}`"
                          >00:00</span
                        >
                        <svg
                          @click="clearRecord1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 16 22"
                          class="pointer mx-1"
                          style="color: #ff7f7f"
                        >
                          <path
                            d="M5,0,3,2H0V4H16V2H13L11,0ZM15,5H1V19.5A2.5,2.5,0,0,0,3.5,22h9A2.5,2.5,0,0,0,15,19.5Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                        <svg
                          width="25"
                          @click="sendMessage1()"
                          class="pointer"
                          height="25"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M12.0004 18.5816V12.5M12.7976 18.754L15.8103 19.7625C17.4511 20.3118 18.2714 20.5864 18.7773 20.3893C19.2166 20.2182 19.5499 19.8505 19.6771 19.3965C19.8236 18.8737 19.4699 18.0843 18.7624 16.5053L14.2198 6.36709C13.5279 4.82299 13.182 4.05094 12.7001 3.81172C12.2814 3.60388 11.7898 3.60309 11.3705 3.80958C10.8878 4.04726 10.5394 4.8182 9.84259 6.36006L5.25633 16.5082C4.54325 18.086 4.18671 18.875 4.33169 19.3983C4.4576 19.8528 4.78992 20.2216 5.22888 20.394C5.73435 20.5926 6.55603 20.3198 8.19939 19.7744L11.2797 18.752C11.5614 18.6585 11.7023 18.6117 11.8464 18.5933C11.9742 18.5769 12.1036 18.5771 12.2314 18.5938C12.3754 18.6126 12.5162 18.6597 12.7976 18.754Z"
                              stroke="#7a7a7a"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></path>
                          </g>
                        </svg>
                      </span>

                      <template #no-result>
                        <div
                          class="allfriendsbox"
                          v-if="loadingForNewREsultInMentaion"
                        >
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="allfriendsbox" v-else>
                          {{ $t("No Result") }}
                        </div>
                      </template>

                      <template #item-@="{ item }">
                        <div class="allfriendsbox">
                          {{ item.name }}
                        </div>
                      </template>
                    </Mentionable>
                    <input
                      type="file"
                      :id="`uploadMediaFromReplyOnCommentInShowPostPage${comment.id}`"
                      @change="
                        uploadMediaFromReplyOnCommentInShowPostPage(comment.id)
                      "
                      hidden
                      accept="image/*"
                    />

                    <!-- preview div upload media -->
                    <div class="text-center">
                      <div v-if="Commentmedia1" class="position-relative">
                        <button
                          type="button"
                          class="btn-close"
                          :disabled="loadingStoreComment1"
                          aria-label="Close"
                          style="
                            position: absolute;
                            top: 2px;
                            right: 2px;
                            cursor: pointer;
                            z-index: 9999;
                          "
                          @click="deletephotoFromComment1"
                        ></button>
                        <img crossorigin="anonymous" 
                          :src="Commentmedia1.url"
                          style="
                            width: 75px;
                            height: 75px;
                            margin: 2px;
                            border: 1px solid #777;
                          "
                        />
                      </div>
                    </div>
                    <!-- End preview div -->

                    <!-- Load Photo -->
                    <div class="px-1 mb-3" v-if="loadingStoreComment1">
                      <div
                        class=""
                        v-if="Commentmedia1"
                        style="border-radius: 25px"
                      >
                        <p class="mb-1 text-danger text-center">
                          {{ $t("File is uploaded") }}
                        </p>
                        <div class="progress">
                          <div
                            id="brogressbarForOne"
                            class="progress-bar progress-bar-striped"
                            role="progressbar"
                            style="border-radius: 25px"
                            :style="`width: ${progressWidth1}%`"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <!-- Load Photo -->
                  </div>
                  <!-- Show All Replies -->
                  <div
                    class="mt-3 ps-5 visually-hidden"
                    :id="`allreplies${comment.id}`"
                  >
                    <div
                      v-if="
                        replies.length > 0 &&
                        replies[0].commentable_id == comment.id
                      "
                    >
                      <!-- {{ replies.length }} -->
                      <!-- FoRALLREPLIES SHOW BOXES -->
                      <div
                        class="vcomments__item"
                        v-for="(reply, index1) in replies"
                        :key="index1"
                      >
                        <div class="row">
                          <div class="col-12">
                            <div class="comment-avatar">
                              <img crossorigin="anonymous" 
                                v-if="reply.model.has_media_profile === false"
                                src="@/assets/img_friends/default1.png"
                                alt=""
                              />
                              <img crossorigin="anonymous" 
                                v-else
                                :src="reply.model.media[0].src_url"
                                alt=""
                              />
                            </div>
                            <div class="vcomments__info">
                              <span class="vcomments__author">
                                <router-link
                                  class="router-link-to-profile"
                                  :to="{
                                    name: 'homeMyProfile',
                                    params: {
                                      id: EncryptionRoute(reply.user_id),
                                    },
                                  }"
                                  >{{ reply.model.name }}
                                </router-link>
                              </span>
                              <div class="dropdown text-center">
                                <small
                                  style="cursor: pointer"
                                  :id="'dropdownMenuButton1' + reply.id"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img crossorigin="anonymous" 
                                    class="image-dropdown"
                                    src="@/assets/images/more.png"
                                    alt="action more"
                                  />
                                </small>
                                <ul
                                  class="dropdown-menu"
                                  :aria-labelledby="
                                    'dropdownMenuButton1' + reply.id
                                  "
                                >
                                  <li v-if="USER.id == reply.user_id">
                                    <a
                                      class="dropdown-item"
                                      @click="delete_comment_Reply(reply)"
                                    >
                                      <DeleteSvg></DeleteSvg>
                                      {{ $t("Delete") }}
                                    </a>
                                  </li>
                                  <li
                                    v-if="USER.id == reply.user_id"
                                    @click="
                                      editeComment(
                                        reply.id,
                                        reply.comment_body,
                                        reply.tags,
                                        reply
                                      )
                                    "
                                    class="dropdown-item"
                                    style="cursor: pointer"
                                  >
                                    <img crossorigin="anonymous" 
                                      src="@/assets/images/edit_mode_icon.png"
                                      style="width: 20px; height: 20px"
                                      alt=""
                                    />
                                    {{ $t("Edit") }}
                                  </li>
                                  <li
                                    v-if="USER.id != reply.user_id"
                                    class="dropdown-item"
                                    style="cursor: pointer"
                                    :data-bs-target="
                                      '#staticCommentReport' + reply.id
                                    "
                                    data-bs-toggle="modal"
                                  >
                                    <!-- <ReportPostSvg></ReportPostSvg> -->
                                    {{ $t("Report Comment") }}
                                  </li>
                                </ul>
                              </div>
                              <!-- <CommentReportComponent :id="comment.id" /> -->

                              <span class="vcomments__time d-block mx-3">{{
                                format_date(reply.created_at)
                              }}</span>
                            </div>
                            <div
                              class="card-body mt-4 mb-2 px-5"
                              dir="auto"
                              :id="`showbodycomment${reply.id}`"
                            >
                              <div class="card-text vcomments__quote">
                                <div
                                  v-if="reply.comment_body"
                                  v-html="
                                    this.showcontentComment(
                                      reply.comment_body,
                                      reply.tags
                                    )
                                  "
                                  v-linkified
                                ></div>
                                <div
                                  v-if="
                                    reply.media &&
                                    reply.media.media_type == 'Image'
                                  "
                                  class="mt-2 text-center"
                                >
                                  <img crossorigin="anonymous" 
                                    :src="reply.media.src_url"
                                    style="
                                      max-width: 150px;
                                      max-height: 150px;
                                      min-height: 75px;
                                      min-width: 75px;
                                    "
                                    alt="CommentImage"
                                  />
                                </div>
                                <div
                                  v-else-if="
                                    reply.media &&
                                    reply.media.media_type == 'Sound'
                                  "
                                  class="mt-2 text-center"
                                >
                                  <audio
                                    :src="reply.media.src_url"
                                    controls
                                  ></audio>
                                </div>
                              </div>
                            </div>
                            <div
                              class="card-body mt-4 mb-2 visually-hidden px-5"
                              dir="auto"
                              :id="`editebodycomment${reply.id}`"
                            >
                              <Mentionable
                                class="w-100 Mentionable"
                                :keys="['@']"
                                :items="friendstagsarray"
                                filtering-disabled
                                offset="6"
                                insert-space
                                @open="loadIssues()"
                                @search="loadIssues($event)"
                              >
                                <textarea
                                  dir="auto"
                                  class="vcomments__add-input ar_vcomment"
                                  v-model="editecommentinput"
                                  :disabled="loadingStatusForEditeComment"
                                  ref="textarea"
                                ></textarea>
                                <span
                                  :class="
                                    DefineDeriction()
                                      ? 'commentsvgboxar'
                                      : 'commentsvgbox'
                                  "
                                  v-if="!recording"
                                >
                                  <span
                                    class="pointer"
                                    @click="open_emoji_Edit"
                                  >
                                    <EmojiSvg></EmojiSvg>
                                  </span>
                                </span>
                                <EmojiPicker
                                  v-click-outside="onClickOutsideEdit"
                                  v-if="this.visible_emoji_Edit == true"
                                  :native="true"
                                  @select="onSelectEmojiEdit"
                                />
                                <template #no-result>
                                  <div
                                    class="allfriendsbox"
                                    v-if="loadingForNewREsultInMentaion"
                                  >
                                    <span
                                      class="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div class="allfriendsbox" v-else>
                                    {{ $t("No Result") }}
                                  </div>
                                </template>

                                <template #item-@="{ item }">
                                  <div class="allfriendsbox">
                                    {{ item.name }}
                                  </div>
                                </template>
                              </Mentionable>
                              <div
                                class="d-flex mt-2 justify-content-start align-items-center"
                              >
                                <button
                                  type="button"
                                  v-if="
                                    !IfdeleteImageFromCommentOrNot &&
                                    !CommentmediaEdite &&
                                    SelecteditemForEdit &&
                                    SelecteditemForEdit.media
                                  "
                                  class="btn-close d-block"
                                  @click="deleteImageFromComment"
                                  aria-label="Close"
                                ></button>
                                <label
                                  :for="`uploadMediaFroEditComment${comment.id}`"
                                  class="mx-2 pointer"
                                >
                                  <AddMediaComment></AddMediaComment>
                                </label>
                                <input
                                  type="file"
                                  :id="`uploadMediaFroEditComment${comment.id}`"
                                  @change="uploadMediaFroEditComment()"
                                  hidden
                                  accept="image/*"
                                />
                              </div>
                              <div class="mt-2 text-center position-relative">
                                <img crossorigin="anonymous" 
                                  v-if="CommentmediaEdite"
                                  :src="CommentmediaEdite.url"
                                  style="width: 150px; height: 150px"
                                />
                                <img crossorigin="anonymous" 
                                  v-if="
                                    !IfdeleteImageFromCommentOrNot &&
                                    !CommentmediaEdite &&
                                    SelecteditemForEdit &&
                                    SelecteditemForEdit.media &&
                                    SelecteditemForEdit.media.media_type ==
                                      'Image'
                                  "
                                  :src="SelecteditemForEdit.media.src_url"
                                  style="
                                    max-width: 150px;
                                    max-height: 150px;
                                    min-width: 75px;
                                    min-height: 75px;
                                  "
                                  alt=""
                                />
                              </div>
                              <br />

                              <button
                                v-if="!loadingStatusForEditeComment"
                                :disabled="
                                  (editecommentinput == '' ||
                                    editecommentinput == null) &&
                                  !CommentmediaEdite &&
                                  ((SelecteditemForEdit &&
                                    !SelecteditemForEdit.media) ||
                                    (SelecteditemForEdit &&
                                      SelecteditemForEdit.media &&
                                      IfdeleteImageFromCommentOrNot))
                                "
                                class="btn btn-sm btn-success ms-3"
                                @click="EditCommentApi(reply.id, 1)"
                              >
                                {{ $t("Edit") }}
                              </button>
                              <button
                                v-else
                                class="btn btn-sm btn-success ms-3"
                              >
                                {{ $t("loading...") }}
                              </button>
                            </div>
                          </div>
                          <div
                            class="d-flex justify-content-between align-items-center"
                          >
                            <!-- Start Button Reaction to comment -->
                            <AddReactionToCommentReply
                              v-on:changeintReply="changeintReply"
                              :comment="reply"
                            ></AddReactionToCommentReply>
                            <!-- End Button Reaction to comment -->
                          </div>
                        </div>
                      </div>
                      <!-- FoRALLREPLIES SHOW BOXES -->
                    </div>
                  </div>
                  <div
                    class="mt-3 ps-5 visually-hidden"
                    :id="`loadingForAllReplies${comment.id}`"
                  >
                    <div class="spinner-grow text-success" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->
            </div>

            <!-- Spinner For Loading Comments Post -->
            <div v-else class="d-flex justify-content-center mt-5">
              <div class="spinner-border text-success" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>

          <!-- For Caursoul -->
          <!-- {{ postinfo}} -->
          <div v-if="postinfo.media.length > 0" class="col-8">
            <!-- {{ postinfo}} -->
            <!-- {{ postinfo.interactions_count_types }} -->
            <!-- interacted -->
            <div
              id="carouselExampleControls"
              class="carousel slide"
              data-bs-ride="carousel"
            >
              <div class="carousel-inner">
                <!-- <div v-if="isLoading" class="text-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div> -->
                <!-- <div v-else> -->
                <div v-if="postinfo.media.length === 1">
                  <div v-if="postinfo.media[0].media_type == 'Image'">
                    <div class="back" style="background: #131313">
                      <!-- <div class="layer"></div> -->
                      <img crossorigin="anonymous" 
                        :src="postinfo.media[0].src_url"
                        class="d-block"
                        alt="Image"
                        v-viewer
                      />
                    </div>
                    <!-- <div
                    class="back"
                    :style="{
                      'background-image':
                        'url(' + postinfo.media[0].src_url + ')',
                    }"
                  >
                    <img crossorigin="anonymous" 
                      :src="postinfo.media[0].src_url"
                      class="d-block"
                      alt="Image"
                    />
                  </div> -->
                  </div>
                  <div v-else>
                    <div class="back">
                      <video crossorigin="anonymous"  controls :src="postinfo.media[0].src_url"></video>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <viewer :images="postinfo.media">
                    <div
                      v-for="(media, index) in postinfo.media"
                      :key="index"
                      :class="['carousel-item', index === 0 ? 'active' : '']"
                    >
                      <div v-if="media.media_type == 'Image'">
                        <div class="back" style="background: #131313">
                          <div class="layer"></div>
                          <img crossorigin="anonymous" 
                            :src="media.src_url"
                            class="d-block"
                            alt="Image"
                          />
                        </div>
                      </div>

                      <div v-else>
                        <div class="back">
                          <video crossorigin="anonymous" 
                            :id="'vid' + index"
                            controls
                            :src="media.src_url"
                          ></video>
                        </div>
                      </div>

                      <button
                        class="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                        @click="stop(index, media)"
                      >
                        <span
                          class="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">{{
                          $t("Previous")
                        }}</span>
                      </button>
                      <button
                        class="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                        @click="stop(index, media)"
                      >
                        <span
                          class="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">{{ $t("Next") }}</span>
                      </button>
                    </div>
                  </viewer>
                </div>
                <!-- </div> -->
              </div>
            </div>
          </div>

          <!-- If Post Contain Life Event -->
          <div
            v-if="postinfo.type == 3 && postinfo.body"
            class="col-8 d-flex justify-content-center align-items-center"
          >
            <div class="w-50">
              <LifeEvent :item="postinfo.body"></LifeEvent>
            </div>
          </div>
          <!-- If Post Contain Life Event -->
          <!-- If Post Contain Life Event -->
          <div
            v-if="postinfo.type == 9"
            class="col-8 d-flex justify-content-center align-items-center"
          >
            <div>
              <PostPoll :post="postinfo"></PostPoll>
            </div>
          </div>
          <!-- If Post Contain Life Event -->

          <!-- If Post Contain Share Post -->
          <div
            v-if="postinfo.sharing_post && postinfo.parent"
            class="col-8 d-flex justify-content-center align-items-center"
          >
            <div class="w-100">
              <SharedPost :post="postinfo.parent" :showfooter="true" />
            </div>
          </div>
          <!-- If Post Contain Share Post -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostQuestion from "@/views/showPost/PostQuestion.vue";
import PostPoll from "@/Elements/post/body/PostPoll.vue";
import PostContent from "@/Elements/post/body/PostContent.vue";
import LangMixin from "@/mixins/LangMixin";
import { Mentionable } from "vue-mention";
import AddReactionToComment from "@/components/ShowComment/Comment/AddReactionToComment.vue";
import AddReactionToCommentReply from "@/components/ShowComment/Comment/AddReactionToCommentReply.vue";
// import CommentReportComponent from "@/components/HomeComponent/CommentReportComponent.vue";

import postServices from "@/services/HomePage/post.services";
import { useRoute } from "vue-router";
import InteractionComponent from "@/components/HomeComponent/InteractionComponent.vue";
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import CommentPostService from "@/services/Comment/CommentPostService";
import DeleteSvg from "@/components/OtherComponent/SVG/DeleteSvg.vue";
import formatTime from "@/mixins/formatTime";
// import ButtonsPost from "@/Elements/post/header/ButtonPostComponent.vue";
import LifeEvent from "@/Elements/GroupPost/LifeEvent.vue";
import FriendshipService from "@/services/Friendships/FriendshipService";
import SharedPost from "@/Elements/post/body/SharedPostComponent.vue";
import { DencryptionRoute, EncryptionRoute } from "@/utils/EncryptionRoute";

import vClickOutside from "click-outside-vue3";
// import picker compopnent
import EmojiPicker from "vue3-emoji-picker";
// import css
import "vue3-emoji-picker/css";

import EmojiSvg from "@/icons/GlobalIcon/EmojiSvg.vue";
import AddMediaComment from "@/icons/Groups/AddMediaComment.vue";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { uuid } from "vue-uuid";

export default {
  name: "ShowPostView",
  data() {
    return {
      postinfo: null,
      id: DencryptionRoute(useRoute().params.id),
      all: 0,
      isliked: null,
      waitingcomments: null,
      interaction_model: null,
      comment_body: "",
      waittogetdata: null,
      friends_ids: [],
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
      replies: [],
      idnewforcommenttoreply: null,
      reply_body: null,
      editecommentid: null,
      editecommentinput: null,
      loadingStatusForEditeComment: false,
      delete_friends_ids: [],
      loadingForNewREsultInMentaion: false,
      visible_emoji: false,
      visible_emoji1: false,

      onlyVoice: false,
      datasender: null,
      progressWidth: 0,
      progressWidth1: 0,
      // For Add Audio Comment
      timer: null,
      timer1: null,
      audioBlob: null,
      audioAction: "",
      recording: false,
      recording1: false,

      loadingStoreComment: false,
      loadingStoreComment1: false,
      media: null,
      media1: null,
      Commentmedia: null,
      Commentmedia1: null,

      // Edit Comment & Reply
      SelecteditemForEdit: null,
      CommentmediaEdite: null,
      mediaEdite: null,
      IfdeleteImageFromCommentOrNot: false,
      visible_emoji_Edit: false,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  mixins: [formatTime, LangMixin],
  methods: {
    // 1
    toggleAnswers() {
      this.postinfo.showAnswers = !this.postinfo.showAnswers;
    },
    // 2
    showReactions() {
      this.postinfo.showReactions = true;
    },
    // 3
    hideReactions() {
      this.postinfo.showReactions = false;
    },
    // 4
    react(data) {
      postServices
        .InteractionWithPost(data.questionId, { type: data.reactionType })
        .then((response) => {
          if (response.data.data != null) {
            const payload = [data.questionId, response.data.data];
            if (this.postinfo.id === payload[0]) {
              if (payload[1] != null) {
                if (this.postinfo.interacted != null) {
                  this.postinfo.interacted = payload[1].type;
                } else {
                  this.postinfo.interacted = payload[1].type;
                  this.postinfo.interactions_count++;
                }
              } else {
                this.postinfo.interacted = null;
                this.postinfo.interactions_count--;
              }
            }
          } else {
            const payload = [data.questionId, null];
            if (this.postinfo.id === payload[0]) {
              if (payload[1] != null) {
                if (this.postinfo.interacted != null) {
                  this.postinfo.interacted = payload[1].type;
                } else {
                  this.postinfo.interacted = payload[1].type;
                  this.postinfo.interactions_count++;
                }
              } else {
                this.postinfo.interacted = null;
                this.postinfo.interactions_count--;
              }
            }
          }
        });
      if (this.postinfo) {
        this.postinfo.showReactions = false;
      }
    },
    // 5
    updateAnswersCount() {
      if (this.postinfo && this.postinfo.answersCount != null) {
        this.postinfo.answersCount += 1;
      } else {
        console.error("postinfo or answersCount is not defined");
      }
    },
    // 6
    deleteQuestion(data) {
      postServices.deletepost(data.post_id).then(() => {
        this.$router.push({ name: "home" });
        // this.questions.splice(data.index, 1);
        this.$snotify.success("delete successfully", "Question");
      });
    },
    // 7
    dataFromUpdateQuestion(data) {
      this.postinfo.content = data.content;
      this.postinfo.friends_ids = data.friends_ids;
      this.postinfo.privacy = data.privacy;
      this.postinfo.media = data.media;
    },
    onClickOutsideEdit() {
      this.visible_emoji_Edit = false;
    },
    open_emoji_Edit() {
      if (this.visible_emoji_Edit == false) {
        this.visible_emoji_Edit = true;
      } else {
        this.visible_emoji_Edit = false;
      }
    },
    onSelectEmojiEdit(emoji) {
      this.editecommentinput += emoji.i;
    },
    onClickOutside() {
      this.visible_emoji = false;
    },
    open_emoji() {
      if (this.visible_emoji == false) {
        this.visible_emoji = true;
      } else {
        this.visible_emoji = false;
      }
    },
    onSelectEmoji(emoji) {
      this.comment_body += emoji.i;
    },
    onClickOutside1() {
      this.visible_emoji1 = false;
    },
    open_emoji1() {
      if (this.visible_emoji1 == false) {
        this.visible_emoji1 = true;
      } else {
        this.visible_emoji1 = false;
      }
    },
    onSelectEmoji1(emoji) {
      this.reply_body += emoji.i;
    },
    EncryptionRoute,
    DencryptionRoute,
    async loadIssues(searchText = null) {
      if (searchText != null) {
        this.loadingForNewREsultInMentaion = true;
        await FriendshipService.searchFriends(searchText).then((res) => {
          // console.log(res.data.data.items.length)
          if (res.data.FriendQuery.items.length > 0) {
            this.$store.commit(
              "friendStore/SET_NEW_friendstagsarray",
              res.data.FriendQuery.items
            );
          }
        });
        this.loadingForNewREsultInMentaion = false;
      } else {
        if (this.friendstagsarray.length < 4) {
          this.loadingForNewREsultInMentaion = true;
          this.$store.commit("friendStore/SET_NEW_friendstagsarray", []);
          await FriendshipService.searchFriends(searchText).then((res) => {
            this.$store.commit(
              "friendStore/SET_NEW_friendstagsarray",
              res.data.FriendQuery.items
            );
          });
          this.loadingForNewREsultInMentaion = false;
        }
      }
    },
    async showAllRepliesBox(id) {
      this.deletephotoFromComment1();
      if (this.replies.length > 0 && this.replies[0].commentable_id == id) {
        // console.log("a");
        document.getElementById(`loadingForAllReplies${id}`)
          ? document
              .getElementById(`loadingForAllReplies${id}`)
              .classList.add("visually-hidden")
          : "";
        document.getElementById(`allreplies${id}`)
          ? document
              .getElementById(`allreplies${id}`)
              .classList.remove("visually-hidden")
          : "";
      } else {
        this.replies = [];
        document.getElementById(`loadingForAllReplies${id}`)
          ? document
              .getElementById(`loadingForAllReplies${id}`)
              .classList.remove("visually-hidden")
          : "";
        document.getElementById(`allreplies${id}`)
          ? document
              .getElementById(`allreplies${id}`)
              .classList.toggle("visually-hidden")
          : "";
        let data = {
          comment_id: id,
        };
        await CommentPostService.Get_Replies_to_comment(data).then((res) => {
          // console.log('aaa',res.data.data.items)
          if (this.replies.length == 0) {
            this.replies = res.data.CommentRepliesQuery.items;
          } else {
            // this.replies = this.replies[0]
            res.data.CommentRepliesQuery.items.forEach((item) => {
              if (!this.replies.find((x) => x.id === item.id)) {
                this.replies.push(item);
              }
            });
          }
        });
        document.getElementById(`loadingForAllReplies${id}`)
          ? document
              .getElementById(`loadingForAllReplies${id}`)
              .classList.add("visually-hidden")
          : "";
      }
      this.idnewforcommenttoreply = id;
    },
    async showreplybox(id, name) {
      this.deletephotoFromComment1();
      this.reply_body = "@" + name.replaceAll(" ", "_") + " ";
      if (this.idnewforcommenttoreply != null) {
        document.getElementById(`addreplybox${this.idnewforcommenttoreply}`)
          ? document
              .getElementById(`addreplybox${this.idnewforcommenttoreply}`)
              .classList.add("visually-hidden")
          : "";
        document.getElementById(`addreplybox${id}`)
          ? document
              .getElementById(`addreplybox${id}`)
              .classList.remove("visually-hidden")
          : "";
      } else {
        document.getElementById(`addreplybox${id}`)
          ? document
              .getElementById(`addreplybox${id}`)
              .classList.remove("visually-hidden")
          : "";
      }
      this.idnewforcommenttoreply = id;
      await this.showAllRepliesBox(id);
      // console.log(this.idnewforcommenttoreply)
    },

    async replyStore(id) {
      // if (this.reply_body.trim() === "") return false;
      if (this.reply_body.trim() === "" && !this.Commentmedia1) return false;
      this.loadingStoreComment1 = true;
      let data = {
        comment_id: id,
      };

      if (this.Commentmedia1) {
        let storage = getStorage();
        let unique_number = uuid.v4() + this.media1.name;
        // let storageRef = ref(storage, "CommentVoice/" + unique_number);
        let storageRef = ref(storage, "CommentImages/" + unique_number);
        let item = null;
        const uploadTask = uploadBytesResumable(storageRef, this.media1);
        uploadTask.on("state_changed", (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.progressWidth1 = `${progress}`;
        });
        // Progress Bar
        await uploadBytes(storageRef, this.media).then((snapshot) => {
          let meme = snapshot.metadata.contentType.split("/");
          item = {
            size: snapshot.metadata.size,
            media_type: "Image",
            mime_type: meme[1],
            fullPath: snapshot.metadata.fullPath,
          };
          this.datasender1 = item;
        });
        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
          item.src_thum = url;
          item.src_icon = url;
        });
        data.media = this.datasender1;
      }

      if (this.reply_body.trim() != "") {
        let result = await this.filterCommentOrReplyContent(this.reply_body);
        if (result.length != 0) {
          data.comment_body = result;
        }
      }
      if (this.friends_ids.length > 0) {
        data.friends_ids = this.friends_ids;
      }
      if (this.page) {
        data.page_id = this.page.id;
      }

      await CommentPostService.Add_Comment_to_Post(data).then((response) => {
        // console.log(response.data.data)
        // commentable_id  id for comment_id
        this.replies.push(response.data.StoreComment);
        this.reply_body = "";
        this.deletephotoFromComment1();
        this.friends_ids = [];
        this.comments.forEach((item) => {
          if (item.id == response.data.StoreComment.commentable_id) {
            item.count_replies++;
          }
        });
      });
      this.loadingStoreComment1 = false;
    },

    editeComment(id, content, tags, item) {
      this.SelecteditemForEdit = item;
      this.IfdeleteImageFromCommentOrNot = false;
      this.mediaEdite = null;
      this.CommentmediaEdite = null;
      // Hide display comment in card and show textarea with comment content to update
      if (this.editecommentid == null) {
        // console.log(id)
        if (tags.length == 0) {
          content ? (content = content.replaceAll("^@^", "")) : "";
          this.editecommentinput = content;
        } else {
          if (content != null) {
            this.editecommentinput = content;
            tags.forEach((tag) => {
              this.editecommentinput = this.editecommentinput.replace(
                "^@^",
                " " + "@" + tag.name.replaceAll(" ", "_")
              );
            });
          } else {
            this.editecommentinput = "";
          }
        }
        this.editecommentid = id;
        document.getElementById(`showbodycomment${id}`)
          ? document
              .getElementById(`showbodycomment${id}`)
              .classList.add("visually-hidden")
          : "";
        if (item.media && item.media.media_type == "Sound") {
          console.log("aa");
        } else {
          document.getElementById(`editebodycomment${id}`)
            ? document
                .getElementById(`editebodycomment${id}`)
                .classList.remove("visually-hidden")
            : "";
        }
      } else {
        document.getElementById(`showbodycomment${this.editecommentid}`)
          ? document
              .getElementById(`showbodycomment${this.editecommentid}`)
              .classList.remove("visually-hidden")
          : "";
        document.getElementById(`editebodycomment${this.editecommentid}`)
          ? document
              .getElementById(`editebodycomment${this.editecommentid}`)
              .classList.add("visually-hidden")
          : "";
        document.getElementById(`Soundeditebodycomment${this.editecommentid}`)
          ? document
              .getElementById(`Soundeditebodycomment${this.editecommentid}`)
              .classList.add("visually-hidden")
          : "";

        this.editecommentid = id;
        if (tags.length == 0) {
          content ? (content = content.replaceAll("^@^", "")) : "";
          this.editecommentinput = content;
        } else {
          if (content != null) {
            this.editecommentinput = content;
            tags.forEach((tag) => {
              this.editecommentinput = this.editecommentinput.replace(
                "^@^",
                " " + "@" + tag.name.replaceAll(" ", "_")
              );
            });
          } else {
            this.editecommentinput = "";
          }
        }
        document.getElementById(`showbodycomment${id}`)
          ? document
              .getElementById(`showbodycomment${id}`)
              .classList.add("visually-hidden")
          : "";
        if (item.media && item.media.media_type == "Sound") {
          console.log("aa");
        } else {
          document.getElementById(`editebodycomment${id}`)
            ? document
                .getElementById(`editebodycomment${id}`)
                .classList.remove("visually-hidden")
            : "";
        }
      }
    },

    async EditCommentApi(id, status) {
      this.loadingStatusForEditeComment = true;
      let data = {
        comment_id: id,
      };
      if (this.IfdeleteImageFromCommentOrNot) {
        data.media = null;
      }
      if (this.CommentmediaEdite) {
        let storage = getStorage();
        let unique_number = uuid.v4() + this.mediaEdite.name;
        // let storageRef = ref(storage, "CommentVoice/" + unique_number);
        let storageRef = ref(storage, "CommentImages/" + unique_number);
        let item = null;
        const uploadTask = uploadBytesResumable(storageRef, this.mediaEdite);
        uploadTask.on("state_changed", (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.progressWidth = `${progress}`;
        });
        // Progress Bar
        await uploadBytes(storageRef, this.media).then((snapshot) => {
          let meme = snapshot.metadata.contentType.split("/");
          item = {
            size: snapshot.metadata.size,
            media_type: "Image",
            mime_type: meme[1],
            fullPath: snapshot.metadata.fullPath,
          };
          this.datasender = item;
        });
        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
          item.src_thum = url;
          item.src_icon = url;
        });
        data.media = this.datasender;
      }
      if (this.editecommentinput && this.editecommentinput != "") {
        let result = await this.filterCommentOrReplyContent(
          this.editecommentinput
        );
        if (result.length != 0) {
          data.comment_body = result;
        }
      } else {
        data.comment_body = null;
      }
      if (this.friends_ids.length > 0) {
        data.friends_ids = this.friends_ids;
      } else {
        data.friends_ids = [];
      }
      // let result = await this.filterCommentOrReplyContent(
      //   this.editecommentinput
      // );

      // if (result.length != 0) {
      //   data.comment_body = result;
      // }
      // if (this.friends_ids.length > 0) {
      //   data.friends_ids = this.friends_ids;
      // }

      await CommentPostService.Update_Comment(data).then((res) => {
        if (status == 0) {
          // if you are update comment so update from parent
          // this.$emit("EditeComment", res.data.data);
          this.comments.forEach((item) => {
            if (item.id == res.data.UpdateComment.id) {
              item.comment_body = res.data.UpdateComment.comment_body;
              item.tags = res.data.UpdateComment.tags;
              item.media = res.data.UpdateComment.media;
            }
          });
        } else {
          // if you are update reply so update from allreply array in this component
          this.replies.forEach((item) => {
            if (item.id == res.data.UpdateComment.id) {
              item.comment_body = res.data.UpdateComment.comment_body;
              item.tags = res.data.UpdateComment.tags;
              item.media = res.data.UpdateComment.media;
            }
          });
        }
      });
      this.loadingStatusForEditeComment = false;
      this.friends_ids = [];
      this.delete_friends_ids = [];
      document
        .getElementById(`editebodycomment${id}`)
        .classList.add("visually-hidden");
      document
        .getElementById(`showbodycomment${id}`)
        .classList.remove("visually-hidden");
    },

    async UpdateTagsInUpdateComment(tags) {
      //First add all old tags to deleted array
      tags.forEach((onetag) => {
        this.delete_friends_ids.push(onetag.id);
      });

      var delete_friends_ids = this.delete_friends_ids;

      // check if does not any changes in tags .... means update content only with the same tags
      var is_same =
        this.friends_ids.length == this.delete_friends_ids.length &&
        this.friends_ids.every(
          await function (element) {
            let a = delete_friends_ids.indexOf(element);
            return element === delete_friends_ids[a];
          }
        );
      // console.log(is_same)
      if (is_same) {
        // if true .... Example:  11 @abd @hassn to 00 @abd @hassn
        this.delete_friends_ids = [];
        return this.delete_friends_ids;
      } else {
        // if there is any changes in tags array

        if (this.friends_ids.length == 0) {
          // if deleted all tags from new update comment .... Example:  11 @abd @hassn or another tags to 00
          return this.delete_friends_ids;
        } else if (this.friends_ids.length > this.delete_friends_ids.length) {
          // if there are new tags more than old tags
          // Example:  @abd @hassn to   @ abd @hassn @mohammd
          let a = this.delete_friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          if (result.length == this.delete_friends_ids.length) {
            // we save the old tags and add new tags so delete_friends_ids array must be null
            this.delete_friends_ids = [];
          } else {
            // changes in old tags and add new tags
            // delete from delete_friends_ids array the values that will be in new tags
            result.forEach((item) => {
              const index = this.delete_friends_ids.findIndex((x) => x == item);
              this.delete_friends_ids.splice(index, 1);
            });
          }
          return this.delete_friends_ids;
        } else if (this.friends_ids.length < this.delete_friends_ids.length) {
          // if there are new tags less than old tags
          // Example:  @abd @hassn @mohammd to   @ abd @hassn
          let a = this.friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.delete_friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          // delete from delete_friends_ids array the values that will be in new tags
          result.forEach((item) => {
            const index = this.delete_friends_ids.findIndex((x) => x == item);
            this.delete_friends_ids.splice(index, 1);
          });
          return this.delete_friends_ids;
        } else {
          // change one tag to anoter one tag but not same value .... Example:  @hassn to @abd
          // console.log("mmm")
          let a = this.delete_friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          // console.log(resultd)
          result.forEach((item) => {
            const index = this.delete_friends_ids.findIndex((x) => x == item);
            this.delete_friends_ids.splice(index, 1);
          });
          // console.log(this.delete_friends_ids)
          return this.delete_friends_ids;
        }
      }
    },

    resize() {
      let element = this.$refs["textarea"];

      element.style.height = "11px";
      element.style.height = element.scrollHeight + "px";
    },
    stop(index, media) {
      // console.log(media)
      if (media.media_type != "Image") {
        var current = document.getElementById(`vid${index}`);
        current.pause();
      }
    },
    changeint(data, id) {
      // console.log(data, id)
      if (data) {
        let index = this.comments.findIndex((x) => x.id === data.comment_id);
        if (this.comments[index].interacted == null) {
          this.comments[index].count_interactions++;
        }
        this.comments[index].interacted = data.type;
      } else {
        let index1 = this.comments.findIndex((x) => x.id === id);
        this.comments[index1].interacted = null;
        this.comments[index1].count_interactions--;
      }
    },
    changeintReply(data, id) {
      // console.log(data, id)
      if (data) {
        let index = this.replies.findIndex((x) => x.id === data.comment_id);
        if (this.replies[index].interacted == null) {
          this.replies[index].count_interactions++;
        }
        this.replies[index].interacted = data.type;
      } else {
        let index1 = this.replies.findIndex((x) => x.id === id);
        this.replies[index1].interacted = null;
        this.replies[index1].count_interactions--;
      }
    },
    delete_comment(id) {
      this.$store.dispatch("comment/a_delete_comment", id);
      this.$snotify.success(
        this.$i18n.t("Deleted successfully"),
        this.$i18n.t("Comment")
      );
      this.comments = this.comments.filter((comment) => comment.id != id);
    },
    async delete_comment_Reply(comment) {
      await CommentPostService.Delete_Comment(comment.id);
      this.$snotify.success(
        this.$i18n.t("Deleted successfully"),
        this.$i18n.t("Reply")
      );
      this.replies = this.replies.filter((reply) => reply.id != comment.id);
      this.comments.forEach((item) => {
        if (item.id == comment.commentable_id) {
          item.count_replies--;
        }
      });
    },
    showcontentComment(content, array) {
      // console.log(array[0])
      if (content == null) {
        array.forEach((user) => {
          let a = `<a
          href="/profile/${this.EncryptionRoute(user.id)}
          "
          ><p class="mb-1 me-1"
                    style="cursor:pointer;font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
                  >
                    ${user.name.replaceAll(" ", "_")}
                  </p></a>`;

          content = content.replace("^@^", a);
          a = null;
        });
        // console.log("AFTER",content)
        content = content.replaceAll("^@^", "");
        return content;
      }
      if (array.length == 0) {
        return content;
      } else {
        array.forEach((user) => {
          let a = `<a
          href="/profile/${this.EncryptionRoute(user.id)}
          "
          ><p class="mb-1 me-1"
                    style="cursor:pointer; font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
                  >
                    ${user.name.replaceAll(" ", "_")}
                  </p></a>`;

          content = content.replace("^@^", a);
          a = null;
        });
        // console.log("AFTER",content)
        content = content.replaceAll("^@^", "");
        return content;
      }
    },
    filterCommentOrReplyContent(content) {
      if (content.match("@[^@ \t\r\n]")) {
        // if content contains tags
        //
        var output = "";
        let array = content.split(" "); // split string to array dependence on " "
        // console.log(array)
        array.forEach((item) => {
          if (item !== "") {
            if (!item.startsWith("@")) {
              // if item does not start with @ so it is content
              output = output + item + " ";
            } else {
              // if item start with @ so it is tag

              this.Allfriendstagsarray.forEach((friend) => {
                // selected id from friendstagsarray to any item start with @

                if (friend.value == item.substr(1)) {
                  if (!this.friends_ids.includes(friend.id)) {
                    output = output + "^@^ ";
                    this.friends_ids.push(friend.id);
                  }
                }
              });
              // filter array and remove duplicated values
              // if for any reason the user tage to the same driend more than one
              this.friends_ids = [...new Set(this.friends_ids)];
            }
          }
        });
        if (output.startsWith(" ")) {
          output = output.substring(1);
        }
        if (output.endsWith(" ")) {
          output = output.substring(0, output.length - 1);
        }
        return output; // return string from content contain tags and store tags id in friends_ids array
      } else {
        // content does not contain any tags
        return content;
      }
    },
    async commentStore() {
      // if (this.comment_body.trim() === "") return false;
      if (this.comment_body.trim() === "" && !this.Commentmedia) return false;
      this.loadingStoreComment = true;
      let data = {
        post_id: this.id,
      };
      if (this.Commentmedia) {
        let storage = getStorage();
        let unique_number = uuid.v4() + this.media.name;
        // let storageRef = ref(storage, "CommentVoice/" + unique_number);
        let storageRef = ref(storage, "CommentImages/" + unique_number);
        let item = null;
        const uploadTask = uploadBytesResumable(storageRef, this.media);
        uploadTask.on("state_changed", (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.progressWidth = `${progress}`;
        });
        // Progress Bar
        await uploadBytes(storageRef, this.media).then((snapshot) => {
          let meme = snapshot.metadata.contentType.split("/");
          item = {
            size: snapshot.metadata.size,
            media_type: "Image",
            mime_type: meme[1],
            fullPath: snapshot.metadata.fullPath,
          };
          this.datasender = item;
        });
        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
          item.src_thum = url;
          item.src_icon = url;
        });
        data.media = this.datasender;
      }
      if (this.comment_body.trim() != "") {
        let result = await this.filterCommentOrReplyContent(this.comment_body);
        if (result.length != 0) {
          data.comment_body = result;
        }
      }
      if (this.friends_ids.length > 0) {
        data.friends_ids = this.friends_ids;
      }
      if (this.page) {
        data.page_id = this.page.id;
      }

      CommentPostService.Add_Comment_to_Post(data)
        .then((response) => {
          this.comment_body = "";
          response.data.StoreComment.interacted = null;
          response.data.StoreComment.count_interactions = 0;
          this.comments.unshift(response.data.StoreComment);
          this.friends_ids = [];
          this.changetypeComment(false);
          this.comment_body = "";
          this.friends_ids = [];
          this.deletephotoFromComment();
          this.$snotify.success(
            this.$i18n.t(response.data.message[0]),
            "Comment"
          );
          this.loadingStoreComment = false;
          // this.$store.state.post.postDataAuth.comments_count++;
        })
        .catch(() => {});
      this.loadingStoreComment = false;
    },
    // For Add Audio Reply Comment
    updateTimer1(startTime, id) {
      this.timer1 = setInterval(function () {
        var currentTime = new Date().getTime();
        var elapsedTime = new Date(currentTime - startTime);
        var minutes = String(elapsedTime.getMinutes()).padStart(2, "0");
        var seconds = String(elapsedTime.getSeconds()).padStart(2, "0");
        // console.log('aaaa',id)
        document.getElementById(
          `timerOnReplyOnCommentInShowPost${id}`
        ).innerHTML = `${minutes}:${seconds}`;
      }, 1000);
    },
    sendMessage1() {
      this.audioAction = "send";
      this.mediaRecorder.stop();
      this.audioContext.close();
      clearInterval(this.timer1);
      this.recording1 = false;
    },
    clearRecord1() {
      if (this.recording1) {
        clearInterval(this.timer1);
        this.mediaRecorder.stop();
        this.audioContext.close();
        this.recording1 = false;
      }
    },
    startRecord1() {
      this.Commentmedia1 = null;
      this.media1 = null;
      this.audioChunks = [];
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((stream) => {
          var currentTime = new Date().getTime();
          this.updateTimer1(currentTime, this.idnewforcommenttoreply);
          this.audioContext = new AudioContext();
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              this.audioChunks.push(event.data);
            }
          };
          this.mediaRecorder.onstop = async () => {
            if (this.audioAction == "send") {
              this.loadingStoreComment = true;
              this.audioBlob = new Blob(this.audioChunks, {
                type: "audio/ogg",
              });
              let storage = getStorage();
              let unique_number = uuid.v4();
              let storageRef = ref(storage, "CommentVoice/" + unique_number);
              let item = null;
              const uploadTask = uploadBytesResumable(
                storageRef,
                this.audioBlob
              );
              uploadTask.on("state_changed", (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.progressWidth1 = `${progress}`;
              });
              // Progress Bar
              await uploadBytes(storageRef, this.media).then((snapshot) => {
                let meme = snapshot.metadata.contentType.split("/");
                item = {
                  size: snapshot.metadata.size,
                  media_type: "Sound",
                  mime_type: meme[1],
                  fullPath: snapshot.metadata.fullPath,
                };
                // this.datasender = item;
              });
              await getDownloadURL(storageRef).then((url) => {
                item.src_url = url;
                item.src_thum = url;
                item.src_icon = url;
              });
              let data = {
                comment_id: this.idnewforcommenttoreply,
              };
              data.media = item;
              if (this.page) {
                data.page_id = this.page.id;
              }
              if (this.comment_body.trim() != "") {
                let result = await this.filterCommentOrReplyContent(
                  this.comment_body
                );
                if (result.length != 0) {
                  data.comment_body = result;
                }
              }
              if (this.friends_ids.length > 0) {
                data.friends_ids = this.friends_ids;
              }

              await CommentPostService.Add_Comment_to_Post(data)
                .then((response) => {
                  this.replies.push(response.data.StoreComment);
                  this.reply_body = "";
                  this.deletephotoFromComment1();
                  this.friends_ids = [];
                  this.comments.forEach((item) => {
                    if (item.id == response.data.StoreComment.commentable_id) {
                      item.count_replies++;
                    }
                  });

                  let element = this.$refs["textarea"];
                  element.style.height = "44px";
                  this.datasender = null;
                })
                .catch(() => {
                  // console.log(error);
                  // this.$snotify.error(error.response.data.data.body[0], "Error");
                  let element = this.$refs["textarea"];
                  element.style.height = "44px";
                });

              // console.log('aaaaaaaa',item)
            }
            clearInterval(this.timer1);
          };
          this.mediaRecorder.start();
          this.recording1 = true;
        })
        .catch((error) => {
          console.error("Error accessing microphone:", error);
        });
    },
    // For Add Audio Reply Comment

    // For Add Audio Comment
    updateTimer(startTime) {
      this.timer = setInterval(function () {
        var currentTime = new Date().getTime();
        var elapsedTime = new Date(currentTime - startTime);
        var minutes = String(elapsedTime.getMinutes()).padStart(2, "0");
        var seconds = String(elapsedTime.getSeconds()).padStart(2, "0");
        document.getElementById(
          "CommenttimerInShowPost"
        ).innerHTML = `${minutes}:${seconds}`;
      }, 1000);
    },
    sendMessage() {
      this.audioAction = "send";
      this.mediaRecorder.stop();
      this.audioContext.close();
      clearInterval(this.timer);
      this.recording = false;
    },
    clearRecord() {
      if (this.recording) {
        clearInterval(this.timer);
        this.mediaRecorder.stop();
        this.audioContext.close();
        this.recording = false;
      }
    },
    startRecord() {
      this.Commentmedia = null;
      this.media = null;
      this.audioChunks = [];
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((stream) => {
          var currentTime = new Date().getTime();
          this.updateTimer(currentTime);
          this.audioContext = new AudioContext();
          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              this.audioChunks.push(event.data);
            }
          };
          this.mediaRecorder.onstop = async () => {
            if (this.audioAction == "send") {
              this.loadingStoreComment = true;
              this.audioBlob = new Blob(this.audioChunks, {
                type: "audio/ogg",
              });
              let storage = getStorage();
              let unique_number = uuid.v4();
              let storageRef = ref(storage, "CommentVoice/" + unique_number);
              let item = null;
              const uploadTask = uploadBytesResumable(
                storageRef,
                this.audioBlob
              );
              uploadTask.on("state_changed", (snapshot) => {
                const progress =
                  (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                this.progressWidth = `${progress}`;
              });
              // Progress Bar
              await uploadBytes(storageRef, this.media).then((snapshot) => {
                let meme = snapshot.metadata.contentType.split("/");
                item = {
                  size: snapshot.metadata.size,
                  media_type: "Sound",
                  mime_type: meme[1],
                  fullPath: snapshot.metadata.fullPath,
                };
                // this.datasender = item;
              });
              await getDownloadURL(storageRef).then((url) => {
                item.src_url = url;
                item.src_thum = url;
                item.src_icon = url;
              });
              let data = {
                post_id: this.id,
              };
              data.media = item;
              if (this.page) {
                data.page_id = this.page.id;
              }
              if (this.comment_body.trim() != "") {
                let result = await this.filterCommentOrReplyContent(
                  this.comment_body
                );
                if (result.length != 0) {
                  data.comment_body = result;
                }
              }
              if (this.friends_ids.length > 0) {
                data.friends_ids = this.friends_ids;
              }

              await CommentPostService.Add_Comment_to_Post(data)
                .then((response) => {
                  this.changetypeComment(true);
                  this.comment_body = "";
                  response.data.StoreComment.interacted = null;
                  response.data.StoreComment.count_interactions = 0;
                  this.comments.unshift(response.data.StoreComment);
                  this.changetypeComment(true);
                  this.comment_body = "";
                  this.friends_ids = [];
                  this.$snotify.success(
                    this.$i18n.t(response.data.message[0]),
                    "Comment"
                  );

                  this.$snotify.success("Commented successfully", "comment");
                  let element = this.$refs["textarea"];
                  element.style.height = "44px";
                  this.datasender = null;
                  console.log("End");
                })
                .catch(() => {
                  // console.log(error);
                  // this.$snotify.error(error.response.data.data.body[0], "Error");
                  let element = this.$refs["textarea"];
                  element.style.height = "44px";
                });

              // console.log('aaaaaaaa',item)
            }
            clearInterval(this.timer);
          };
          this.mediaRecorder.start();
          this.recording = true;
        })
        .catch((error) => {
          console.error("Error accessing microphone:", error);
        });
    },

    // For Add Audio Comment
    change() {
      this.interaction_model = null;
    },
    showinteractionmodel() {
      this.UPDATE_IS_MODAL_INTERACTION();
      this.interaction_model = true;
      if (document.documentElement.style.overflow === "hidden") {
        document.documentElement.style.overflow = "auto";
      } else {
        document.documentElement.style.overflow = "hidden";
      }
    },
    ...mapMutations({
      UPDATE_IS_MODAL_INTERACTION: "post/UPDATE_IS_MODAL_INTERACTION",
    }),
    async a_get_comment() {
      let data = {
        post_id: this.id,
        onlyVoice: this.onlyVoice,
      };
      await this.$store.dispatch("comment/a_getComments", data);
    },
    ...mapMutations({
      UPDATE_SHOW_POST: "post/UPDATE_SHOW_POST",
    }),
    async getpostinfo(id) {
      this.waittogetdata = true;
      return postServices
        .getpostinfo(id)
        .then((response) => {
          // console.log(response.data.data)
          this.postinfo = null;
          this.isLoading = false;
          // this.postinfo = response.data.PostByIDQuery;
          this.postinfo = {
            ...response.data.PostByIDQuery,
            showAnswers: false,
          };
          this.waittogetdata = null;
        })
        .catch((error) => {
          console.log(error.message);
        });
    },

    async getiteracion(id, data) {
      await postServices
        .GetAllInteractionWithPost(id, data)
        .then((response) => {
          // console.log(response.data.data.items)
          this.all = response.data.data.total;
          response.data.data.items.forEach((item) => {
            if (
              item.model.user_id == JSON.parse(localStorage.getItem("user")).id
            ) {
              this.isliked = true;
            }
          });
          this.isshow = null;
        });
    },

    back() {
      this.$router.go(-1);
    },
    ...mapActions({
      get_all_friends: "friendStore/get_all_friends",
      get_all_friends_For_Array_tags:
        "friendStore/get_all_friends_For_Array_tags",
    }),
    async changetypeComment(type) {
      if (type != this.onlyVoice) {
        this.$store.commit("comment/CLEAR_COMMENTS");
        this.onlyVoice = !this.onlyVoice;
        let data = {
          post_id: this.id,
          onlyVoice: this.onlyVoice,
        };
        await this.$store.dispatch("comment/a_getComments", data);
      }
    },

    uploadMediaFromReplyOnCommentInShowPostPage(id) {
      this.media1 = document.getElementById(
        `uploadMediaFromReplyOnCommentInShowPostPage${id}`
      ).files[0];
      let data = {
        type: document.getElementById(
          `uploadMediaFromReplyOnCommentInShowPostPage${id}`
        ).files[0].type,
        url: URL.createObjectURL(
          document.getElementById(
            `uploadMediaFromReplyOnCommentInShowPostPage${id}`
          ).files[0]
        ),
      };
      this.Commentmedia1 = data;
    },
    uploadMediaFromCommentInShowPostPage() {
      this.media = this.$refs.uploadMediaFromCommentInShowPostPage.files[0];
      let data = {
        type: this.$refs.uploadMediaFromCommentInShowPostPage.files[0].type,
        url: URL.createObjectURL(
          this.$refs.uploadMediaFromCommentInShowPostPage.files[0]
        ),
      };
      this.Commentmedia = data;
    },

    deletephotoFromComment1() {
      this.media1 = null;
      this.Commentmedia1 = null;
      this.datasender = null;
    },
    deletephotoFromComment() {
      this.media = null;
      this.Commentmedia = null;
      this.datasender = null;
    },

    // For Edit Comment With Media
    deleteImageFromComment() {
      this.IfdeleteImageFromCommentOrNot = true;
      this.mediaEdite = null;
      this.CommentmediaEdite = null;
    },
    uploadMediaFroEditComment() {
      this.mediaEdite = document.getElementById(
        `uploadMediaFroEditComment${this.SelecteditemForEdit.id}`
      ).files[0];
      let data = {
        type: document.getElementById(
          `uploadMediaFroEditComment${this.SelecteditemForEdit.id}`
        ).files[0].type,
        url: URL.createObjectURL(
          document.getElementById(
            `uploadMediaFroEditComment${this.SelecteditemForEdit.id}`
          ).files[0]
        ),
      };
      this.CommentmediaEdite = data;
      this.IfdeleteImageFromCommentOrNot = true;
    },
    // For Edit Comment With Media
  },
  components: {
    PostQuestion,
    PostPoll,
    InteractionComponent,
    DeleteSvg,
    // CommentReportComponent,
    AddReactionToComment,
    Mentionable,
    // ButtonsPost,
    AddReactionToCommentReply,
    LifeEvent,
    SharedPost,
    EmojiPicker,
    EmojiSvg,
    AddMediaComment,
    PostContent,
  },
  computed: {
    ...mapState("post", {
      showpost: "showpost",
    }),
    ...mapState("comment", {
      loading_comment: "loading_comment",
    }),
    ...mapGetters({
      USER: "auth/USER",
    }),
    ...mapGetters({
      comments: "comment/comments",
      friendstagsarray: "friendStore/friendstagsarray",
      Allfriendstagsarray: "friendStore/Allfriendstagsarray",
      // USER: "friendStore/USER",
    }),
    ...mapState("post", {
      interactionModel: "interactionModel",
    }),
  },
  async created() {
    // console.log(this.id)
    await this.getpostinfo(this.id);
    await this.a_get_comment();
    await this.getiteracion(this.id, 0);
    await this.UPDATE_SHOW_POST(this.id);
    this.friendstagsarray.length == 0 ? this.get_all_friends() : ""; // if we rfresh the page execute get all friends to have array tags
    this.Allfriendstagsarray.length == 0
      ? this.get_all_friends_For_Array_tags()
      : "";
    // console.log('aa', this.comments)
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "floating-vue/dist/style.css";
@import "@/Styles/Comments/comment/comment.scss";
@import "@/Styles/Comments/comment/commentsAll.scss";

.pointer {
  cursor: pointer;
}

.activecomment {
  border-bottom: 1px solid #8f5fee;
  color: #8f5fee;
}

.col-4:lang(ar) {
  padding: 0 0.5rem 0 0.5rem !important;
}

.col-4 {
  height: calc(100vh - 78px);
  overflow-y: scroll;
}
.one {
  img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: green !important;
}

// .d-block[data-v-2b000c5d] {
//   width: auto !important;
// }

.interaction {
  .img-reaction {
    width: 25px;
    height: 25px;
    margin: 0;
    border-radius: 50%;
  }
}

.add_comment {
  input {
    width: 100%;
    border-radius: 10px;
    padding: 0.2rem 1rem;
    height: 50px;
    outline: none;

    &:hover {
      outline: none;
    }
  }
}
#carouselExampleControls {
  height: 100%;
  // position: relative;

  .carousel-inner,
  .carousel-item.active {
    // height: 100% !important;
    height: calc(100vh - 78px);
    overflow: hidden;
    position: relative;

    .back {
      position: relative;
      height: calc(100vh - 78px);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-blend-mode: saturation;

      .d-block,
      video {
        border-radius: 10px;
        position: absolute;
        top: 50% !important;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto !important;
        height: auto !important;
        max-width: calc(100% - 20px) !important;
        max-height: calc(100% - 20px) !important;
        opacity: 1;
        z-index: 9;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px,
          rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
          rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px,
          rgba(0, 0, 0, 0.07) 0px 32px 64px;
      }
    }
    .cover_body {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    z-index: 10;
  }
}

.comments {
  .comment_box {
    border: 1px solid #777;
    border-radius: 10px;
    padding: 0.5rem 1rem;

    .comment-avatar {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .comment__info {
      padding: 0 0.5rem;
      margin-top: 0.1rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .col-4 {
    width: 100% !important;
  }

  .col-8 {
    order: -1;
    flex: 1 0 100%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .d-block,
  video {
    border-radius: 10px;
    position: absolute;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 20px) !important;
    max-height: calc(100% - 20px) !important;
    opacity: 1;
    z-index: 19 !important;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    z-index: 22 !important;
  }
}
video {
  width: 100%;
  height: 100%;
  border: 1px solid rgb(119, 119, 119);
  margin: 2px;
}

.rebly_button {
  padding: 0.1rem 0.5rem;
  cursor: pointer;
  border: 1px solid #77777774;
  border-radius: 5px;
  font-size: 14px;
}

.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0;
  background: transparent;
  border: 0;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}

.v3-emoji-picker {
  position: absolute;
  // margin-top: 46px;
  z-index: 1;
  right: 2rem;
}
.v3-emoji-picker:lang(ar),
.v3-emoji-picker:lang(ku),
.v3-emoji-picker:lang(ur) {
  position: absolute;
  // margin-top: 46px;
  z-index: 1;
  left: 0 !important;
}
span.emoji-visible {
  position: relative;
  float: right;
  margin: -43px 10px;
  cursor: pointer;
}
.image-visible {
  position: relative;
  float: right;
  margin: -43px 50px;
  cursor: pointer;
}
.mikesvg {
  position: relative;
  float: right;
  margin: -39px 90px !important;
  cursor: pointer;
}

.commentsvgbox,
.audoicontrolcommentsvgbox {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  right: 1rem;
}
.commentsvgboxar,
.audoicontrolcommentsvgboxar {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  left: 1rem;
}
</style>
