<template>
    <div class="modal fade" dir="auto" id="ModalForGetAllGiftsOnPost" data-bs-backdrop="static" data-bs-keyboard="false"
        tabindex="-1" aria-labelledby="staticBackdropLabelModalForGetAllGiftsOnPost" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title w-100" id="staticBackdropLabelModalForGetAllGiftsOnPost">{{ $t('All gifts') }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <LoaderSearch v-if="isLoading_allgiftsforPost" />
                    <div v-else>
                        <div v-if="allgiftsforPost.length > 0">
                            <div v-for="item in allgiftsforPost" :key="item.id" class="d-flex mb-3 justify-content-start align-items-start">
                                <div>
                                    <img crossorigin="anonymous"  :src="item.giftType.img_url" alt="Logo" width="100" height="75">
                                </div>
                                <div class="mx-4">
                                    <p class="fw-bold mb-1">{{ item.sender.name }}</p>
                                    <p class="mb-1">{{ item.giftType.name }}</p>
                                    <!-- <p class="mb-0">{{ item.amount }}</p> -->
                                </div>
                            </div>
                            <div v-if="loadMore"  class="text-center">
                                <button @click="loadmoregifts" class="btn btn-info">{{ $t('Load More') }}</button>
                            </div>
                        </div>
                        <div v-else>
                            <h5 class="text-center fw-bold">{{ $t('No gifts until now') }}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import LoaderSearch from '@/components/OtherComponent/LoaderSearch.vue';
export default {
    computed: {
        ...mapGetters({
            isLoading_allgiftsforPost: "gift/isLoading_allgiftsforPost",
            allgiftsforPost: "gift/allgiftsforPost",
            selectedPostId: "gift/selectedPostId",
            ModelTypeThatISendToItThisGift: "gift/ModelTypeThatISendToItThisGift",
            loadMore: "gift/loadMore",
        })
    },
    components: {
        LoaderSearch
    },
    methods: {
        ...mapMutations({
            ClearselectedPostId: "gift/ClearselectedPostId",
            ClearModelTypeThatISendToItThisGift: "gift/ClearModelTypeThatISendToItThisGift",
            }),
        CloseGiftModal(){
            this.ClearselectedPostId()
            this.ClearModelTypeThatISendToItThisGift()
        },
        ...mapActions({
            Load_More_Gifts: 'gift/Load_More_Gifts'
        }),
        loadmoregifts(){
            this.Load_More_Gifts()
        }
    },
}
</script>

<style lang="scss" scoped></style>