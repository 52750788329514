<template>
  <div
    class="write-post-container"
    v-for="(job, index) in userInformation.jobs"
    :key="index"
  >
    <h3>{{ $t("Job") }}</h3>
    <img crossorigin="anonymous"  src="@/assets/images/profile-job.png" alt="" class="image-job" />
    {{ job.title }} <span v-if="job.city">in {{ job.city.name }}</span>
  </div>
  <div v-show="!userInformation.jobs.length" class="text-center write-post-container">
    <h6>{{ $t("No Found Any Jobs") }}</h6>
    <img crossorigin="anonymous" 
      src="@/assets/5081-empty-box.gif"
      alt=""
      style="height: 60%; width: 35%; margin: 0px 7%"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapGetters({
      userInformation: "profile/userInformation",
    }),
  },
};
</script>

<style lang="scss" scoped>
.image-job {
  width: 30px;
}
.write-post-container {
  width: 100%;
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  color: #626262;
  margin-bottom: 10px;
  margin-left: 6px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
</style>
