<template>
  <div class="story">
    <div class="story_s">
      <div
        dir="auto"
        class="story_body"
        :style="
          `background-color: ${user.backStyle}; 
            background-image: ${user.backStyle};
            ${user.fontStyle};
            color: ${user.textStyle}
            `
        "
      >
        <div class="overlay"></div>
        <img crossorigin="anonymous" 
          v-if="user.has_media && (user.media[0].media_type == 'image' || user.media[0].media_type == 'Image')"
          class="story_img"
          :src="user.media[0].src_url"
          alt=""
        />
        <img crossorigin="anonymous" 
        v-else-if="user.has_media && (user.media[0].media_type == 'video' || user.media[0].media_type == 'Video')"
          class="story_img"
          :src="user.media[0].src_thum"
          alt=""
        />
        
        <p class="text_body" v-else>
          {{ shortenText(user.content, 30) }}
        </p>
        <p class="user_name" >{{ user.model.name }}</p>
        <img crossorigin="anonymous" 
          v-if="user.model.has_media_profile && user.model.media[0].collection_name == 'profile'"
          class="user_img"
          :src="user.model.media[0].src_url"
          alt="avatar"
        />
        <img crossorigin="anonymous" 
          v-else-if="user.model.has_media_profile && user.model.media[1].collection_name == 'profile'"
          class="user_img"
          :src="user.model.media[1].src_url"
          alt="avatar"
        />
        <img crossorigin="anonymous"  v-else class="user_img" src="@/assets/story_img/user.png" alt="avatar" />
        <svg
          class="green"
          v-if="getActive(user.created_at)"
          height="10"
          width="10"
          xmlns:dc="http://purl.org/dc/elements/1.1/"
          xmlns:cc="http://creativecommons.org/ns#"
          xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
          xmlns:svg="http://www.w3.org/2000/svg"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
          xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
          viewBox="0 0 448 448"
          id="svg2"
          version="1.1"
          inkscape:version="0.91 r13725"
          sodipodi:docname="dot.svg"
          fill="#00ff00"
          stroke="#00ff00"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <title id="title3336">dot</title>
            <defs id="defs4"></defs>
            
           
            <g
              inkscape:label="Layer 1"
              inkscape:groupmode="layer"
              id="layer1"
              transform="translate(0,-604.36224)"
            >
              <circle
                style="
                  fill-opacity: 1;
                  stroke: none;
                  stroke-width: 30;
                  stroke-linecap: round;
                  stroke-miterlimit: 4;
                  stroke-dasharray: none;
                  stroke-opacity: 1;
                "
                id="path3334"
                cx="224"
                cy="828.36224"
                r="192"
              ></circle>
            </g>
          </g>
        </svg>
      </div>
    </div>
    
    <!-- {{ user.created_at }} -->
  </div>
</template>
<script>
import ActivePersonMixin from "@/mixins/ActivePersonMixin"

export default {
  name: "AllStoriesComponent",
  props: ["user"],
  mixins: [ActivePersonMixin],

  methods: {
    shortenText: function (v, end) {
      return v.substring(0, end) + "...";
    },
  },
};
</script>
<style lang="scss" scoped>
.story {
  height: 170px;
  align-items: center;
  display: flex;

  .story_s {
    position: relative;
    margin-left: 10px;
    height: 170px;
    min-width: 100px;
    max-width: 100px;
    .story_body {
      height: 100%;
      position: relative;
      border-radius: 10px;
      cursor: pointer;

      .story_img {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      .user_img {
        border-radius: 100%;
        border: 3px solid rgb(78, 157, 231);
        position: absolute;
        width: 40px !important;
        height: 40px !important;
        top: 0.8rem;
        left: 0.5rem;
        background-color: beige;
      }

      .point {
        position: absolute;
        top: 0.4rem;
        left: 0.5rem;
        z-index: 999;
      }

      .user_name {
        position: absolute;
        bottom: 0;
        color: white;
        font-weight: 400;
        left: 0.5rem;
        font-size: small;
      }

      .text_body {
        font-size: small;
        position: absolute;
        padding: 10px;
        margin-top: 50%;
        text-align: justify;
        word-break: break-word;
      }
    }
    .overlay {
      width: 100px;
      height: 170px;
      background-image: linear-gradient(transparent, #00000094);
      position: absolute;
      top: 0;
      border-radius: 10px;
    }
  }
}
</style>
