<template>
  <div class="card">
    <div
      class="arrow-icon"
      @click="toggleDropdown(index)"
      v-if="canDeleteQuestion(question)"
      :style="DefineDeriction() ? 'left:10px' : 'right:10px'"
    >
      <ArrowIcon />
      <ul v-if="dropdownOpen === index" class="custom-dropdown">
        <li
          data-bs-toggle="modal"
          :data-bs-target="'#updateQuestion' + question.id"
        >
          {{ $t("Update Question") }}
        </li>
        <li @click="deleteQuestion(question.id, index)">
          {{ $t("Delete Post") }}
        </li>
      </ul>
    </div>
    <div class="user-info" :style="DefineDeriction() ? 'direction:rtl':  'direction:ltr'">
      <OwnerPhotoQuestion :question="question" />
      <div>
        <h3>{{ question.model.name }}</h3>
        <small>
          <FriendsIcon v-if="question.privacy == 'friends'" />
          <PublicIcon v-if="question.privacy == 'public'" />
          <JustMeIcon v-if="question.privacy == 'just_me'" />
        </small>
        &nbsp;
        <small class="date" dir="auto">{{
          formatRelativeDate(question.created_at)
        }}</small>
      </div>
    </div>
    <p class="question-text" dir="auto">{{ question.content }}</p>
    <div class="mt-2" v-if="question.has_media">
      <MediaQuestion :question="question" />
    </div>
    <AnswersContainer
      :question="question"
      :index="index"
      @toggleAnswers="toggleAnswers"
      @update-answers-count="updateAnswersCount"
      @update-show-reaction="showReactions"
      @update-hide-reaction="hideReactions"
      @react="react"
    />
  </div>
  <!-- Start Edit question component  -->
  <EditQuestion
    :question="question"
    @dataUpdateQuestion="dataFromUpdateQuestion"
  ></EditQuestion>
  <!-- End Edit question component  -->
</template>

<script>
import AnswersContainer from "@/components/questions/answers/AnswersContainer.vue";
import MediaQuestion from "@/components/questions/MediaQuestion.vue";
import OwnerPhotoQuestion from "@/components/questions/OwnerPhotoQuestion.vue";

import ArrowIcon from "@/components/questions/icons/ArrowIcon.vue";
import FriendsIcon from "@/components/questions/icons/FriendsIcon.vue";
import PublicIcon from "@/components/questions/icons/PublicIcon.vue";
import JustMeIcon from "@/components/questions/icons/JustMeIcon.vue";
import EditQuestion from "@/components/questions/edit/EditQuestion.vue";
import LangMixin from "@/mixins/LangMixin";
import { mapGetters } from "vuex";
export default {
  name: "QuestionComponent",
  props: ["question", "index"],
  mixins:[LangMixin],
  emits: [
    "toggleAnswers",
    "show-reactions",
    "hide-reactions",
    "react",
    "update-answers-count",
    "delete-question",
    "data-update-question",
  ],
  data() {
    return {
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
      isLoadingQuestion: false,
      questions: [],
      has_more_pages: false,
      isLoadingMoreQuestion: false,
      pageQuestion: 1,
      isLoading: false,
      dropdownOpen: null,
    };
  },

  components: {
    AnswersContainer,
    MediaQuestion,
    OwnerPhotoQuestion,
    ArrowIcon,
    EditQuestion,
    FriendsIcon,
    PublicIcon,
    JustMeIcon,
  },
  computed: {
    rankingExplanation() {
      return this.$t(
        "The ranking is based on the highest rated answers, then the most recent answers"
      );
    },
    ...mapGetters({
      USER: "auth/USER",
    }),
  },
  methods: {
    dataFromUpdateQuestion(data) {
      this.$emit("data-update-question", data);
    },
    canDeleteQuestion(question) {
      return (
        question.model.has_role || question.model.id == this.USER.profile.id
      );
    },

    toggleDropdown(index) {
      if (this.dropdownOpen === index) {
        this.dropdownOpen = null;
      } else {
        this.dropdownOpen = index;
      }
    },

    deleteQuestion(post_id, index) {
      this.$emit("delete-question", { post_id: post_id, index: index });
    },

    toggleAnswers(data) {
      this.$emit("toggleAnswers", data);
    },
    updateAnswersCount(index) {
      this.$emit("update-answers-count", index);
    },
    showReactions(questionId) {
      this.$emit("show-reactions", questionId);
    },
    hideReactions(questionId) {
      this.$emit("hide-reactions", questionId);
    },
    react(data) {
      this.$emit("react", data);
    },
    formatRelativeDate(dateString) {
      const date = new Date(dateString);
      const now = new Date();

      const diff = now - date;
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (seconds < 60) {
        return this.$i18n.t("Moments ago");
      } else if (minutes < 60) {
        return `${this.$i18n.t("since")} ${minutes} ${
          minutes > 1 ? this.$i18n.t("minutes") : this.$i18n.t("minute")
        }`;
      } else if (hours < 24) {
        return `${this.$i18n.t("since")} ${hours} ${
          hours > 1 ? this.$i18n.t("hours") : this.$i18n.t("hour")
        }`;
      } else if (days < 2) {
        return this.$i18n.t("yesterday");
      } else if (days < 7) {
        return `${this.$i18n.t("since")} ${days} ${
          days > 1 ? this.$i18n.t("days") : this.$i18n.t("day")
        }`;
      } else {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        return date.toLocaleDateString("ar", options);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  align-items: start;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}
.card {
  position: relative;
}
.arrow-icon {
  position: absolute;
  top: 10px;
  // right: 10px;
}

.arrow {
  fill: #007bff;
}

.arrow:hover {
  fill: #0056b3;
}

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

h3 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.date {
  font-size: 12px;
  color: #888;
}

.question-text {
  margin: 0;
  font-size: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: none;
  min-width: 150px;
  white-space: nowrap;
}

.custom-dropdown li {
  padding: 8px 16px;
  cursor: pointer;
  list-style-type: none;
}

.custom-dropdown li:hover {
  background-color: #f0f0f0;
}

.card:hover .custom-dropdown {
  display: block;
}
.custom-dropdown-answer {
  position: absolute;
  //   top: 40px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: none;
  min-width: 150px;
  white-space: nowrap;
}

.custom-dropdown-answer li {
  padding: 8px 16px;
  cursor: pointer;
  list-style-type: none;
}

.custom-dropdown-answer li:hover {
  background-color: #f0f0f0;
}

.card:hover .custom-dropdown-answer {
  display: block;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.specialBtn {
  background-color: #8f5fee;
  color: white !important;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.specialBtn:hover {
  background-color: #6e3ebf;
}

.specialBtn svg {
  width: 1em;
  height: 1em;
  margin-left: 5px;
  vertical-align: middle;
  transition: transform 0.3s ease;
}

.specialBtn:hover svg {
  transform: translateY(2px);
}
.no-answers-message {
  text-align: center;
  color: #888;
  font-size: 1.2em;
  margin: 20px 0;
}
</style>
