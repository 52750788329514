<template>
  
  <div >
    <div class="row m-0" dir="auto">
      <!-- SidBar -->
      <StoryShowSkeltonSideBar v-if="peforeloadingSideBar" />
      <div class="col-lg-3 sidebar position-relative" v-else>
        <!-- Greate Story -->
        <div class="visibleSideBarClass" style="position: absolute;top: 0;left: 0;width: 100%; height: 100%;background-color: transparent;z-index: 999;" 
          v-if="visibleSideBar"></div>
        <div class="d-flex aligm-items-center pt-2 button">
          <button
            type="button"
            class="btn-close mb-3"
            @click="back"
            aria-label="Close"
          ></button>
          <br />
          <button
            type="button"
            class="btn style-create-story w-100 forlarge ms-3 mb-3"
            @click="gotoaddstory"
          >
            {{ $t("Create Story") }}
          </button>
          <br />
          <button
            type="button"
            class="btn style-create-story w-100 formobile ms-3 mb-3"
            @click="stoptprogressandshowview()"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            {{ $t("Create Story") }}
          </button>
        </div>
        <CreateStoryFromStoriesViews
           />
        <!-- Greate Story -->
        
        <!-- Show Boxes Story -->
        <div
          v-for="(item, index) in Allusers_with_Story"
          :id="`boxstory${item.id}`"
          :class="[
            'box',
            (UserStory.length > 0 && item.user_id === UserStory[0].user_id && UserStory[0].model_type == 'Profile') 
            || 
            (UserStory.length > 0 && item.model_id == UserStory[0].model_id && UserStory[0].model_type == 'Page') ? 'active' : '',
          ]"
          :key="index"
        >
          <div @click="showStory(item)">
            <div class="d-flex justify-content-between align-items-center">
              <img crossorigin="anonymous" 
                v-if="item.has_media_profile === false"
                src="@/assets/story_img/user.png"
                alt=""
              />
              <img crossorigin="anonymous" 
                v-else-if="item.model.media[0].collection_name === 'profile'"
                :src="item.model.media[0].src_url"
                alt=""
              />
              <img crossorigin="anonymous"  v-else :src="item.model.media[1].src_url" alt="" />
              <p class="m-0 name">{{ item.model.name }}</p>
            </div>
            <div >
              <p
                class="m-0"
                style="color: #0baa92"
                v-if="item.model.stories_count === 1"
              >
                {{ $t("One") }}
              </p>
              <p
                class="m-0"
                style="color: #0baa92"
                v-else-if="item.model.stories_count === 2"
              >
                {{ $t("Tow") }}
              </p>
              <p
                class="m-0"
                style="color: #0baa92"
                v-else-if="item.model.stories_count > 2"
              >
                {{ item.model.stories_count }} {{ $t("more") }}
              </p>
              <p class="m-0" style="color: black" v-else>{{ $t("NoStory") }}</p>
            </div>
            
          </div>
        </div>
        <!-- Show Boxes Story -->

      </div> 
      <!-- End Col-lg-3 -->
      <!-- SidBar -->

        <StoryShowSkelton v-if="peforeloading" />
      <!-- Carusol -->
      <div v-else class="col-lg-9 p-0 overflow-hidden position-relative" style="height: calc(100vh - 71px)">
        <div id="carouselExampleControls" ref="carouselExampleControlsStory" class="carousel carousel-fade position-relative d-flex justify-content-center  p-0 slide h-100" style="background-color: black; " data-bs-interval="false">
          <div class="carousel-inner overflow-visible h-100 "  >
              

              <!-- Show Story -->
              <div
                v-for="(story, index) in UserStory"
                :key="story.id"
                
                :class="[
                  'carousel-item h-100 w-100 ',
                  index == indexprogress ? 'active' : '',
                ]"
              >

                <!-- tab -->
                <div class=" divprogress " style="padding: 5px">

                  <!-- Brogress tab -->
                  <div class="d-flex justify-content-center">
                    <div
                      v-for="(story1, index1) in UserStory"
                      :key="story1.id"
                      class="progress"
                      :style="{
                        width: 'calc(100% /' + UserStory.length + ')',
                        margin: '0 5px',
                        height: '5px',
                      }"
                    >
                      <div
                        :class="[
                          'progress-bar bg-success',
                          index1 < indexprogress ? 'fully' : '',
                        ]"
                        role="progressbar"
                        :style="{
                          width: index1 === indexprogress ? widthbar + '%' : ``,
                        }"
                        aria-valuenow="45"
                        aria-valuemin="0"
                        aria-valuemax="50"
                      ></div>
                      
                    </div>
                  </div>
                  <!-- Brogress tab -->

                  <!-- Info -->
                  <div class="mt-2 px-1  d-flex align-items-center justify-content-between">

                    <div class="d-flex justify-content-start align-items-center">
                      <!-- Name -->
                      <div
                        v-for="(item, index1) in Allusers_with_Story"
                        :key="index1"
                      >
                        <div class="" v-if="
                          (UserStory.length > 0 && UserStory[0].model_type == 'Page' && item.id === UserStory[0].model_id) ||
                          (UserStory.length > 0 && UserStory[0].model_type == 'Profile' && item.user_id === UserStory[0].user_id)
                        ">
                          <div
                            class="d-flex justify-content-center  align-items-center"
                          >
                            <img crossorigin="anonymous" 
                              v-if="item.has_media_profile === false"
                              height="25"
                              width="25"
                              class="img_logo"
                              src="@/assets/img_friends/default1.png"
                              alt=""
                            />
                            <img crossorigin="anonymous" 
                              v-else-if="item.media[0].collection_name === 'profile'"
                              class="rounded"
                              height="25"
                              width="25"
                              :src="item.media[0].src_url"
                              alt=""
                            />
                            <img crossorigin="anonymous" 
                              v-else
                              :src="item.media[1].src_url"
                              height="25"
                              width="25"
                              class="img_logo"
                              alt=""
                            />
                            <p class="mx-2 mb-0 name text-white fw-bold">
                              {{ item.name }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <!-- Name -->

                      <!-- Pause And Play -->
                      <div v-if="!story.has_media || (story.has_media && (story.media[0].media_type == 'image' || story.media[0].media_type == 'Image' ))">
                        <span style="cursor: pointer;" v-if="!pause" @click="stoptprogressandshowview()">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            fill="#fff"
                            class="bi bi-stop-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                            <path
                              d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z"
                            />
                          </svg>
                        </span>
                        <span style="cursor: pointer;" v-else @click="closeshowviewandrestoreprogress()">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            fill="#fff"
                            class="bi bi-play"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"
                            />
                          </svg>
                        </span>
                      </div>
                      <!-- For Video Story Play And Puse -->
                      <div v-else>
                        <span style="cursor: pointer;" v-if="pause" @click="StopVideoStory(story)">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            fill="#fff"
                            class="bi bi-stop-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                            <path
                              d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z"
                            />
                          </svg>
                        </span>
                        <span v-else style="cursor: pointer;" @click="playVideoStory(story)">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            fill="#fff"
                            class="bi bi-play"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"
                            />
                          </svg>
                        </span>
                        <!-- Mute -->
                        <span v-if="videoMute" class="mx-1" style="cursor: pointer;" @click="UnmuteVideo(story.id)">
                          <svg fill="#ffffff" width="26" height="26" viewBox="0 0 24 24" id="mute-2" data-name="Line Color" xmlns="http://www.w3.org/2000/svg" class="icon line-color" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path id="primary" d="M17,3,10.16,8H6A1,1,0,0,0,5,9v6a1,1,0,0,0,1,1h4.16L17,21Z" style="fill: none; stroke: #ffffff; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></path><line id="secondary" x1="3" y1="18.63" x2="21" y2="5.37" style="fill: none; stroke: #8f5fee; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></line></g>
                          </svg>
                        </span>
                        <!-- Un Mute -->
                        <span v-else class="mx-1" style="cursor: pointer;" @click="muteVideo(story.id)">
                          <svg width="26" height="26" fill="#8f5fee" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill-rule="evenodd" d="M11.553 3.064A.75.75 0 0112 3.75v16.5a.75.75 0 01-1.255.555L5.46 16H2.75A1.75 1.75 0 011 14.25v-4.5C1 8.784 1.784 8 2.75 8h2.71l5.285-4.805a.75.75 0 01.808-.13zM10.5 5.445l-4.245 3.86a.75.75 0 01-.505.195h-3a.25.25 0 00-.25.25v4.5c0 .138.112.25.25.25h3a.75.75 0 01.505.195l4.245 3.86V5.445z"></path><path d="M18.718 4.222a.75.75 0 011.06 0c4.296 4.296 4.296 11.26 0 15.556a.75.75 0 01-1.06-1.06 9.5 9.5 0 000-13.436.75.75 0 010-1.06z"></path><path d="M16.243 7.757a.75.75 0 10-1.061 1.061 4.5 4.5 0 010 6.364.75.75 0 001.06 1.06 6 6 0 000-8.485z"></path></g>
                          </svg>
                          
                          
                        </span>
                      </div>
                      <!-- For Video Story Play And Puse -->
                      <!-- Pause And Play -->
                    </div>

                  </div>
                  <!-- Info -->

                </div>
                <!-- Tab -->
                
                <!-- Start Of Show story div -->
                <div class="back">
                  <!-- <p class="text-white">{{ story.id }}</p> -->
                    <div v-if="story.has_media" class="cover_body">
                      <img crossorigin="anonymous" 
                        v-if="story.media[0].media_type == 'image' || story.media[0].media_type == 'Image'"
                        :src="story.media[0].src_url"
                        class="d-block img"
                        alt="Image"
                      />
                      <div v-else class="position-relative">
                        <video crossorigin="anonymous"  
                          @click="ClickOnVideo(story)"
                            :id="`videoinstory${story.id}`"
                            :src="story.media[0].src_url"  
                              style="width: 100%; height: 100%; object-fit: contain;cursor: pointer;"
                          >
                        </video>
                        <div v-if="!videostate" class="spinner-border text-primary" role="status" style="position: absolute; top: 45%; left: 43%;z-index: 999999;color: #8f5fee !important;">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="cover_body"
                      :style="[
                        story.backStyle || story.textStyle || story.fontStyle
                          ? `background-color: ${story.backStyle}; 
                                                background-image: ${story.backStyle};
                                                ${story.fontStyle};
                                                color: ${story.textStyle}
                                                `
                          : { 'background-color': '#777', color: '#000000' },
                      ]"
                    >
                      <p class="text_body">
                        {{ story.content }}
                      </p>
                    </div>
                  
                </div>
                <!-- End Of Show Story div -->

                <!-- Controllers -->
                <button
                  v-if="index != 0 && cickPrev"
                  @click="prev( UserStory[index], UserStory[index - 1])"
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">{{ $t("Previous") }}</span>
                </button>
                <button
                  v-if="index < UserStory.length - 1 && cickNext"
                  @click="next(UserStory[index + 1], UserStory[index])"
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">{{ $t("Next") }}</span>
                </button>
                <!-- Controllers -->

                <div class=" interaction_comment px-3 d-flex justify-content-between"  style="height: 42px !important;">
                  <!-- Add Reaction To Story -->
                    <div class="addreactiontostory d-inline-flex " v-if="UserStory.length > 0"
                      
                    >
                      <addInteractionComponents :story_id="story.id" :interacted="story.interacted" />
                    </div>
                    <div v-else></div>
                  <!-- Add Reaction To Story -->
                  <!-- Comment On Story -->
                    <div style="cursor: pointer;" @click="$router.push('/auth/login')" class="addcommenttostory d-flex align-items-center" 
                      >
                          <p class="mb-0 text-white commentword mx-2" >{{ $t('Comments') }}</p>
                          <svg 
                            class="svgcomment"
                              height="25" width="25"
                              viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000" stroke="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>comment-2</title> <desc>Created with Sketch Beta.</desc> <defs> </defs> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"> <g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-152.000000, -255.000000)" fill="#ffffff"> <path d="M168,281 C166.832,281 165.704,280.864 164.62,280.633 L159.912,283.463 L159.975,278.824 C156.366,276.654 154,273.066 154,269 C154,262.373 160.268,257 168,257 C175.732,257 182,262.373 182,269 C182,275.628 175.732,281 168,281 L168,281 Z M168,255 C159.164,255 152,261.269 152,269 C152,273.419 154.345,277.354 158,279.919 L158,287 L165.009,282.747 C165.979,282.907 166.977,283 168,283 C176.836,283 184,276.732 184,269 C184,261.269 176.836,255 168,255 L168,255 Z M175,266 L161,266 C160.448,266 160,266.448 160,267 C160,267.553 160.448,268 161,268 L175,268 C175.552,268 176,267.553 176,267 C176,266.448 175.552,266 175,266 L175,266 Z M173,272 L163,272 C162.448,272 162,272.447 162,273 C162,273.553 162.448,274 163,274 L173,274 C173.552,274 174,273.553 174,273 C174,272.447 173.552,272 173,272 L173,272 Z" id="comment-2" sketch:type="MSShapeGroup"> </path> </g> </g> </g>
                            </svg>
                        </div>
                        <!-- Show All Comment Or no -->
                  <!-- Comment On Story -->
                </div>
                
                
              </div>
              <!-- Show Story -->
          </div>

        </div>
      </div>
      <!-- Carusol -->
    </div>
      
  </div>
</template>

<script>
  import StoryShowSkelton from "@/components/SkeletonsLoaderComponent/StoryShowSkelton.vue";
  import StoryShowSkeltonSideBar from "@/components/SkeletonsLoaderComponent/StoryShowSkeltonSideBar.vue";
  import CreateStoryFromStoriesViews from "@/components/HomeComponent/Story/CreateStoryFromStoriesViews.vue";
  import { useRoute } from "vue-router";
  import { mapGetters } from "vuex";
  import ViewsService from "@/services/views/storyviews";
  import formatTime from "@/mixins/formatTime";
  import storyServices from "@/services/story/story.services";
  import addInteractionComponents from "@/components/Guest/Story/iteraction/addInteractionComponents.vue"


  export default {
    name: "StoriesView",
    components: {
      StoryShowSkeltonSideBar,
      StoryShowSkelton,
      CreateStoryFromStoriesViews,
      addInteractionComponents
    },
    data() {
      return {
        page: localStorage.getItem('page') ? JSON.parse(localStorage.getItem("page")) : "",
        id: useRoute().params.id,
        type: useRoute().query.type,
        Allusers_with_Story: [],
        indexprogress: 0,
        pause: false,
        peforeloading: false,
        peforeloadingSideBar: false,
        intervalitem: 0,
        widthbar: 0,
        viewstoryloading: false,
        itemsviewonstory: [],
        idforcommentstory: '',
        comment_status: null,
        allcommentsOnStory: [],
        allcommentsloading: null,
        lastpageinCommentsStory: 1,
        pageInCommentsList: 1,
        loadingformorecomment: null,
        videostate: true,
        videoMute: false,
        indexofleft: null,
        cickNext: true,
        cickPrev: true,
        visibleSideBar: false
      };
    },
    mixins: [formatTime],
    async mounted() {
      this.peforeloading = true;
      this.peforeloadingSideBar = true;
      // Determinate Story Type
      let obj =  {
        model_id: parseInt(this.id),
        model_type: this.type
      };

      (await this.UserStory.length) == 0
        ? this.$store.dispatch("story/getStories", obj)
        : "";
      await this.getAllStories();
      this.peforeloading = false;
      this.peforeloadingSideBar = false;
      // Story Is Video
      if(this.UserStory.length > 0 && this.UserStory[0].has_media && (this.UserStory[0].media[0].media_type == 'video' || this.UserStory[0].media[0].media_type == 'Video')){
        clearInterval(this.intervalitem);
        this.pause = false
      }else { // Story Not Video
        this.intervalitem = setInterval(this.startTimer, 100);
        this.pause = false
      }
      
      
    },
    unmounted() {
      this.indexprogress = 0
      this.widthbar = 0
      this.indexofleft = null
      clearInterval(this.intervalitem);
      this.$store.commit("story/Claer_USER_STORIES");
      // console.log(('unMonted'))
    },
    computed: {
      ...mapGetters("story", {
        UserStory: "User_Stories", // All Story For User In State
        isLoading: "isLoading_status",
        User: "Get_User", // UserId For Story In State
      }),
      
    },
    methods: {
      // goto (){
      //   return
      // },
      stopforInteraction(story){
        if(story.has_media && (story.media[0].media_type == 'video' || story.media[0].media_type == 'Video')){
          let video = document.getElementById(`videoinstory${story.id}`)
          video.pause()
          clearInterval(this.intervalitem);
          this.pause = false
        }else { // Story Not Video
          clearInterval(this.intervalitem);
          this.pause = false
        }
      },
      playforinteraction(story){
        if(story.has_media && (story.media[0].media_type == 'video' || story.media[0].media_type == 'Video')){
          let video = document.getElementById(`videoinstory${story.id}`)
          video.play()
          this.intervalitem = setInterval(this.startTimer, 100);
          this.pause = true
        }else { // Story Not Video
          this.intervalitem = setInterval(this.startTimer, 100);
          this.pause = true
        }
      },
      UnmuteVideo(id){
        let video = document.getElementById(`videoinstory${id}`)
        video.muted = !video.muted
        this.videoMute = false
      },
      muteVideo(id){
        let video = document.getElementById(`videoinstory${id}`)
        video.muted = !video.muted
        this.videoMute = true
      },
      ClickOnVideo(story){
        let video = document.getElementById(`videoinstory${story.id}`)
        if(video.paused){
          // console.log('yes')
          video.play()
          video.onwaiting = () => {
            // console.log('waiting')
            this.videostate = false
            clearInterval(this.intervalitem);
            this.pause = false;
          };
        
          video.onplaying = () => {
            // console.log('playing')
            this.videostate = true
            this.intervalitem = setInterval(this.startTimer, 100);
            this.pause = true;
          }; 
        }else {
          video.pause()
          clearInterval(this.intervalitem);
          this.pause = false;
          
        }
      },
      playVideoStory(story){
        let video = document.getElementById(`videoinstory${story.id}`)
        video.paused ? "" : video.currentTime = 0;
        video.play()
        video.onwaiting = () => {
          // console.log('waiting')
          this.videostate = false
          clearInterval(this.intervalitem);
          this.pause = false;
        };
      
        video.onplaying = () => {
          // console.log('playing')
          this.videostate = true
          this.intervalitem = setInterval(this.startTimer, 100);
          this.pause = true;
        };        
        // video.play()
        //   this.intervalitem = setInterval(this.startTimer, 100);
        //   this.pause = true;
      },
      StopVideoStory(story){
        let video = document.getElementById(`videoinstory${story.id}`)
        video.pause()
        clearInterval(this.intervalitem);
        this.pause = false;
      },

      async getAllStories() {
        await storyServices
          .getAllStoriesInGuest()
          .then((response) => {
            this.Allusers_with_Story = response.data.StoriesQuery.items;
            
          })
          .catch((error) => {
            console.log(error.message);
          });
      },
      back() {
        clearInterval(this.intervalitem);
        this.$router.push({ name: "guest" });
      },
      stoptprogressandshowview() {
        // console.log('story not video')
        clearInterval(this.intervalitem);
        this.pause = true;
      },
      async viewonstory(story) {
        this.stopforInteraction(story)
        this.viewstoryloading = true;
        await ViewsService.ShowViewsStory(story.id).then((res) => {
          this.itemsviewonstory = res.data.data.items;
        });
        this.viewstoryloading = false;
      },
      closeviewmodal() {
        // console.log(this.indexprogress)
        let story = null
        this.UserStory.forEach(item => {
          if(item.id == this.UserStory[this.indexprogress].id) {
            story = item
          }
        })
        // console.log(story)
        this.playforinteraction(story)
        this.itemsviewonstory = [];
      },
      closeshowviewandrestoreprogress() {
        this.intervalitem = setInterval(this.startTimer, 100);
        this.pause = false;
      },
      async showStory(item) {
        // console.log(item)
        this.visibleSideBar = true
        document.getElementById(`boxstory${item.id}`).classList.add('active')
        this.peforeloading = true;
        clearInterval(this.intervalitem);
        this.indexprogress = 0;
        this.widthbar = 0;
        this.pause = false;
        
        await this.$store.dispatch("story/getStories", item);
        
        this.peforeloading = false;
        if (this.UserStory[0] && this.Allusers_with_Story) {
          if(this.UserStory[0].model_type == 'Page'){
            this.Allusers_with_Story.forEach((storyitem) => {
              if (storyitem.id == this.UserStory[0].model_id) {
                this.indexofleft = this.Allusers_with_Story.indexOf(storyitem);
              }
            });
          }else {
            this.Allusers_with_Story.forEach((storyitem) => {
              if (storyitem.user_id == this.UserStory[0].user_id) {
                this.indexofleft = this.Allusers_with_Story.indexOf(storyitem);
              }
            });
          }
          
        }
        await new Promise(r => setTimeout(r, 400));
        // this.intervalitem = setInterval(this.startTimer, 100);
        if(item.last_story.has_media && (item.last_story.media[0].media_type == 'video' || item.last_story.media[0].media_type == 'Video')){
          this.playVideoStory(item.last_story)
          // clearInterval(this.intervalitem);
          // let video = document.getElementById(`videoinstory${item.last_story.id}`)
          // video.play()
          // this.intervalitem = setInterval(this.startTimer, 100);
          // this.pause = true
          // console.log('iiii')
        }else {
          this.intervalitem = setInterval(this.startTimer, 100);
          this.pause = false
        }
        // this.intervalitem = setInterval(this.startTimer, 100);
        this.visibleSideBar = false
      },
      startTimer() {
        // let indexofleft = null;
        if (this.UserStory[0] && this.Allusers_with_Story && this.indexofleft == null) {
          if(this.UserStory[0].model_type == 'Page'){
            this.Allusers_with_Story.forEach((storyitem) => {
              if (storyitem.id == this.UserStory[0].model_id) {
                this.indexofleft = this.Allusers_with_Story.indexOf(storyitem);
              }
            });
          }else {
            this.Allusers_with_Story.forEach((storyitem) => {
              if (storyitem.user_id == this.UserStory[0].user_id) {
                this.indexofleft = this.Allusers_with_Story.indexOf(storyitem);
              }
            });
          }
          
        }
        // console.log(this.indexofleft)
        if (this.widthbar < 100 && this.indexprogress < this.UserStory.length) {
          // if(this.UserStory[this.indexprogress].id) current story id
          if(!this.UserStory[this.indexprogress].has_media || (this.UserStory[this.indexprogress].has_media && ((this.UserStory[this.indexprogress].media[0].media_type == 'Image') || (this.UserStory[this.indexprogress].media[0].media_type == 'image')))){
            this.widthbar = this.widthbar + 2;
          }else {
            // console.log(this.UserStory[this.indexprogress].id)
            let dur = document.getElementById(`videoinstory${this.UserStory[this.indexprogress].id}`).duration
            // console.log(dur)
            // console.log(this.widthbar)
            this.widthbar = this.widthbar + ((100 / dur)/10);
          }
          
        } else if (
          this.widthbar > 98.8 &&
          this.indexprogress < this.UserStory.length - 1
        ) {
          if( (this.UserStory[this.indexprogress].has_media && ((this.UserStory[this.indexprogress].media[0].media_type == 'video') || (this.UserStory[this.indexprogress].media[0].media_type == 'Video')))){
            let video = document.getElementById(`videoinstory${this.UserStory[this.indexprogress].id}`)
            video.pause()
          }
          clearInterval(this.intervalitem);
          this.widthbar = 0;
          this.indexprogress++;
          if( (this.UserStory[this.indexprogress].has_media && ((this.UserStory[this.indexprogress].media[0].media_type == 'video') || (this.UserStory[this.indexprogress].media[0].media_type == 'Video')))){
            // clearInterval(this.intervalitem);
            // this.pause = false
            this.playVideoStory(this.UserStory[this.indexprogress])
            this.videoMute = false
          }else {
            this.intervalitem = setInterval(this.startTimer, 100);
            // this.pause = true
            this.pause = false
          }
        }  else {
          
          if (this.indexofleft < this.Allusers_with_Story.length - 1) {
            this.showStory(this.Allusers_with_Story[this.indexofleft + 1]);
            this.indexofleft++
            // console.log('indexofleft', indexofleft)
            // console.log('this.Allusers_with_Story.length', this.Allusers_with_Story.length)
          } else {
            clearInterval(this.intervalitem);
            this.back();
          }
        }
      },
      async next(data, current) {
        this.cickNext = false
        this.cickPrev = false
        
        // console.log(current)
        // console.log(data)
        if(current.has_media && (current.media[0].media_type == 'video' || current.media[0].media_type == 'Video')){
          this.StopVideoStory(current)
          this.videostate = true
          let video = document.getElementById(`videoinstory${current.id}`)
          // return video to start
          video.currentTime = 0;
          // return sound
          this.videoMute = false
        }
        clearInterval(this.intervalitem);
        this.indexprogress++;
        this.widthbar = 0;
        await new Promise(r => setTimeout(r, 400));
        
        if(data.has_media && (data.media[0].media_type == 'video' || data.media[0].media_type == 'Video')){
          // clearInterval(this.intervalitem);
          this.playVideoStory(data)
          // let video = document.getElementById(`videoinstory${data.id}`)
          // video.play()
          // this.intervalitem = setInterval(this.startTimer, 100);
          // this.pause = true
          // console.log('4545')
        }else {
          // this.pause = true
          this.intervalitem = setInterval(this.startTimer, 100);
          this.pause = false
        }
        
        this.cickNext = true
        this.cickPrev = true
      },
      async prev(current , data) {
        this.cickPrev = false
        this.cickNext = false
        if(current.has_media && (current.media[0].media_type == 'video' || current.media[0].media_type == 'Video')){
          this.StopVideoStory(current)
          this.videostate = true
          let video = document.getElementById(`videoinstory${current.id}`)
          // return video to start
          video.currentTime = 0;
          // return sound
          this.videoMute = false
        }
        clearInterval(this.intervalitem);
        this.indexprogress--;
        this.widthbar = 0;
        await new Promise(r => setTimeout(r, 400));
        if(data.has_media && (data.media[0].media_type == 'video' || data.media[0].media_type == 'Video')){
          // clearInterval(this.intervalitem);
          this.playVideoStory(data)
          // let video = document.getElementById(`videoinstory${data.id}`)
          // video.play()
          // this.intervalitem = setInterval(this.startTimer, 100);
          // this.pause = true
          // console.log('5656')
        }else {
          // this.pause = true
          this.intervalitem = setInterval(this.startTimer, 100);
          this.pause = false
        }
        // if (data && data.user_id != this.USER.id) {
        //     const send = {
        //     story_ids: [data.id],
        //     };
        //     ViewsService.AddViewsStory(send);
        // }
        this.cickPrev = true
        this.cickNext = true
      },

      gotoaddstory() {
        clearInterval(this.intervalitem);
        this.$router.push({ name: "AddStory" });
      },
    },
  };
</script>
<style lang="scss" scoped>

// SidBar
.style-create-story {
  background: $defaultColor;
  border: $defaultColor;
  color: #ffffff;
}
.style-create-story:hover {
  background: $defaultColor;
  border: $defaultColor;
  filter: brightness(80%);
  color: #ffffff;
}

.formobile {
  display: none;
}

.sidebar {
  background-color: #ffffff;
  height: calc(100vh - 71px);
  overflow-y: auto;
}

.box {
  cursor: pointer;
  border: 1px solid #0baa92bc;
  margin-bottom: 1rem;
  border-radius: 10px;
  padding: 0.5rem 1rem;

  &.active {
    border: 3px solid $defaultColor !important;
    background-color: $defaultColor;

    .name {
      color: white !important;
      font-weight: 500;
    }

    p {
      color: white !important;
    }
  }

  .name {
    color: #0baa92;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
// SidBar

// Carousl

  .carousel-inner {
    width: 46%;
    margin: 0 auto !important;
  }

.back {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .cover_body {
      height: 84%;
      width: 100%;
      border: 1px solid black;
      // border-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden !important;

      .img {
        object-fit: contain;
        // height: 90%;
        width: 100%;
        height: 100%;
      }

      p {
        width: 95%;
        text-align: center;
      }
    }
  
}


// progress
.divprogress {
  position: absolute;
  background-color: transparent !important;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;

  .progress-bar {
    background-color: $defaultColor !important;
  }

  .name {
    color: $defaultColor !important;
  }
  .img_logo {
    object-fit: cover;
    border-radius: 50% !important;
  }
}

.fully {
  width: 100% !important;
}

// Contollers
.carousel-control-next,
.carousel-control-prev {
  opacity: 1 !important;
}

.carousel-control-next {
  right: -15% !important;
}
.carousel-control-prev {
  left: -15% !important;
}

.carousel-control-next-icon, 
.carousel-control-prev-icon {
  background-color: $defaultColor;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blok {
  // background-color: blue;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 77;
  height: 110%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.interaction_comment {
  position: absolute;
  bottom: 0.4rem;
  left: 0rem;
  z-index: 999;
  width: 100%;
}

.addreactiontostory {
  background-color: transparent;
  border: 1px solid white;
  width: auto;
  border-radius: 10px;
  padding: 0 0.2rem;
  box-shadow: rgba(233, 232, 232, 0.685) 0px 1px 4px;
}

// Carousl

@media screen and (max-width: 992px) {
  .formobile {
    display: block !important;
  }

  .forlarge {
    display: none;
  }

  .col-lg-3 {
    display: none;
  }

  .col-lg-9 {
    width: 100%;
    height: calc(100vh - 125px) !important;
  
    .carousel-inner {
      width: 96% !important;
      margin: 0 auto !important;

      .carousel-control-next {
        right: -2% !important;
      }
      .carousel-control-prev {
        left: -2% !important;
      }
    }

    .interaction_comment {
      position: absolute;
      bottom: 0.1rem !important;
      left: 0rem;
      z-index: 999;
      width: 100%;
      // background-color: red !important;
      padding: 0 10px !important;

      .svgcomment {
        width: 20px !important;
        height: 20px !important;
      }

      .commentword {
        display: none !important;
      }
    }
  }


}


  // for modal views
  .modal-content {
  z-index: 99999999;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.modal-header:lang(ar) {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  button {
    text-align: left !important;
  }
}

.modal-body:lang(ar) {
  h5 {
    text-align: right !important;
  }
}
</style>
