<template>
  <div
    dir="auto"
    class="row m-0 justify-content-center"
    style="min-height: 100vh"
  >
    <!-- Start Sidebar -->
    <div class="col-md-3 left order-first">
      <div class="px-3">
        <span>
          <button
            @click="Backto()"
            type="button"
            class="btn-close mb-1"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            aria-label="Close"
          ></button>
        </span>
        <span class="font-mediaNas" style="font-size: 22px">
          &nbsp; &nbsp; <b style="color: #7543d9">Media</b>
          <b style="color: #601776">Nas</b></span
        >
      </div>
      <hr />
      <span>
        <router-link
          :to="{ name: 'discoverGroups' }"
          class="router-link-to-path"
          >{{ $t("Groups") }}</router-link
        >
        / {{ $t("Create group") }}</span
      >
      <h5 class="watch-txt" style="font-weight: 700">
        {{ $t("Create group") }}
      </h5>
      <br />
      <!-- user's Photo -->
      <div class="d-flex align-items-center">
        <img crossorigin="anonymous" 
          class="profile_image"
          v-if="USER.profile.has_media_profile === false"
          src="@/assets/story_img/user.png"
          alt=""
        />
        <img crossorigin="anonymous" 
          class="profile_image"
          v-else-if="USER.profile.media[0].collection_name === 'profile'"
          :src="USER.profile.media[0].src_url"
          alt=""
        />
        <img crossorigin="anonymous" 
          class="profile_image"
          v-else
          :src="USER.profile.media[1].src_url"
          alt=""
        />
        <div class="ms-1">
          <p class="mb-0">{{ full_name }}</p>
          <small class="text-muted">
            {{ $t("Admin") }}
          </small>
        </div>
      </div>
      <br />
      <div class="style-margin">
        <!-- input Group Name -->
        <div class="row">
          <div class="form-floating">
            <input
              ref="myNameRef"
              :class="{ 'is-invalid': nameError }"
              type="text"
              class="form-control vinput__add-input"
              id="floatingInput"
              placeholder="Group Name"
              dir="auto"
              v-model="name"
              :maxlength="maxLength"
              required
              autocomplete="off"
            />
            <label for="floatingInput">{{ $t("Group Name") }}</label>
            <div class="w-100">
              <p style="color: red">
                {{ this.nameErrMsg }}
              </p>
              <!-- From Server -->
              <small class="text-danger" v-if="errorMessages.name">
                {{ errorMessages.name[0] }}
              </small>
            </div>
          </div>
        </div>
        <br />
        <!-- input Privacy -->
        <div class="row">
          <div class="form-floating">
            <select
              class="form-select vinput__add-input"
              id="floatingSelect"
              aria-label="Floating label select example"
              v-model="type"
            >
              <option value="" selected disabled>
                {{ $t("Open this select menu") }}
              </option>
              <option value="1">{{ $t("public") }}</option>
              <option value="0">{{ $t("Private") }}</option>
            </select>
            <label for="floatingSelect">{{ $t("Privacy") }}</label>
          </div>
        </div>
        <!-- Button Create group -->
        <div class="d-flex justify-content-between px-1">
          <button
            :disabled="this.name == null || this.type == ''"
            class="btn btn-primary mt-4 btn-style"
            style="bottom: 20px"
            @click="create_new_group()"
            v-if="!loading"
          >
            {{ $t("Create") }}
          </button>
          <button
            v-else
            class="btn btn-primary mt-4 btn-style"
            style="bottom: 20px"
            type="button"
            disabled
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Loading...</span>
          </button>
        </div>
      </div>
    </div>
    <!-- End Sidebar -->

    <!-- Start Content -->
    <div class="container-fluid">
      <div class="content">
        <div class="col-lg-9 offset-md-3 right mb-0 pt-1 px-3 order-last">
          <div dir="auto">
            <!-- Start cover -->
            <img crossorigin="anonymous" 
              class="cover-img"
              src="@/assets/groups/groups-default-cover-photo-gray.jpg"
              alt=""
            />
            <!-- End cover -->

            <!-- Start div info -->
            <div class="info" dir="auto">
              <h5 v-if="!name" class="title title-null">
                {{ $t("Group Name") }}
              </h5>
              <h5 v-else class="title">{{ name }}</h5>
              <h6 class="title title-null">
                {{ $t("Group privacy") }} . 1 {{ $t("member") }}
              </h6>
              <p class="title">
                <!-- {{ this.$i18n.t(type) }} -->
                <span v-if="type === '1'">{{ $t("public") }}</span>
                <span v-if="type === '0'">{{ $t("Private") }}</span>
              </p>
            </div>

            <!-- End div info -->
          </div>
        </div>
      </div>
    </div>

    <!-- End  Content -->
  </div>
</template>

<script>
import SingleGroupServices from "@/services/GroupsServices/SingleGroupServices";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      name: "",
      nameError: false,
      nameErrMsg: "",
      type: "",
      maxLength: 50,
      errorMessages: {},
    };
  },
  watch: {
    name() {
      if (this.name.length == 0) {
        this.nameError = true;
        this.nameErrMsg = this.$i18n.t("name field is required");
      } else {
        this.nameError = false;
        this.nameErrMsg = "";
      }
    },
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
    }),
    // get Full Name
    full_name() {
      return (
        JSON.parse(localStorage.getItem("user")).profile.first_name +
        " " +
        JSON.parse(localStorage.getItem("user")).profile.last_name
      );
    },
  },
  methods: {
    validateInputs() {
      if (this.name == "") {
        this.$refs.myNameRef.focus();
      }
    },
    Backto() {
      this.$router.push({ name: "GroupsFeed" });
    },
    create_new_group() {
      this.loading = true;
      this.errorMessage = {};
      let datacreate = {
        name: this.name,
        type: this.type,
      };

      SingleGroupServices.s_create_new_group(datacreate)
        .then((res) => {
          this.$snotify.success(
            this.$i18n.t("The group has been added successfully"),
            this.$i18n.t("Group")
          );
          this.name = null;
          this.type = "";
          this.$router.push({
            name: "showSingleGroup",
            params: { id: res.data.StoreGroupMutation.id },
          });
        })
        .catch((error) => {
          if (
            error.graphQLErrors &&
            error.graphQLErrors.length > 0 &&
            error.graphQLErrors[0].extensions
          ) {
            const validationErrors =
              error.graphQLErrors[0].extensions.validation;
            if (validationErrors) {
              this.errorMessages = validationErrors;
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/Styles/Groups/sidebarCreateGroup.scss";
@import "@/Styles/Groups/createGroup.scss";
.form-floating > label {
  left: 4%;
  top: 0%;
}
</style>
