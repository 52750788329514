<template>
  <p
    class="mb-0 position-relative"
    :style="DefineDeriction() ? 'text-align: right;' : 'text-align: left;'"
  >
    <!-- if the name owner the post  is authenticate -->
    <!-- {{ post }} -->
    <span
      v-if="post.model_type == 'Profile' && USER.profile.id == post.model.id"
    >
      <b @click="goto(post.model.id)" class="name-style"
        >{{ post.model.first_name }} {{ post.model.last_name }}</b
      >
      <span v-if="post.tags.length > 0">
        <span v-if="post.type == 7">
          &nbsp; <span>{{ $t("Celebrate a birthday") }} &nbsp;</span>

          <b
            class="name-style"
            v-if="USER.id == post.tags[0].user_id"
            @click="goto(USER.profile.id)"
            >{{ post.tags[0].name }}</b
          >
          <b v-else @click="goto(post.tags[0].id)" class="name-style">{{
            post.tags[0].name
          }}</b>
          🎂
        </span>
        <span v-else>
          &nbsp; <span>{{ $t("With") }} &nbsp;</span>

          <b
            v-if="USER.id == post.tags[0].user_id"
            @click="goto(USER.profile.id)"
            class="name-style"
            >{{ post.tags[0].name }}</b
          >
          <b v-else @click="goto(post.tags[0].id)" class="name-style">{{
            post.tags[0].name
          }}</b>
        </span>
      </span>

      <a
        @click="Get_all_tags_on_post(post.id)"
        class="dropdown text-decoration-none mx-1 px-0"
        style="cursor: pointer"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span v-if="post.tags_count > 1" class="">
          {{ $t("and") }} {{ post.tags_count - 1 }} {{ $t("Others") }}
        </span>
      </a>
      <ul class="dropdown-menu">
        <li class="text-center" v-if="loadingalltags">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </li>
        <div v-else>
          <li
            class="dropdown-item pro guest"
            v-for="item in all"
            :key="item.id"
          >
            <span @click="goto(item.id)" class="name-style">
              <img crossorigin="anonymous" 
                v-if="item.has_media_profile === false"
                class="pd-image"
                src="@/assets/img_friends/default1.png"
                alt=""
              />
              <img crossorigin="anonymous" 
                v-else-if="item.media[0].collection_name === 'profile'"
                :src="item.media[0].src_url"
                class="pd-image"
                alt=""
              />
              <img crossorigin="anonymous" 
                v-else-if="item.media[1].collection_name === 'profile'"
                :src="item.media[1].src_url"
                class="pd-image"
                alt=""
              />
              <span class="mx-1">{{ item.name }}</span>
            </span>
          </li>
        </div>
      </ul>
    </span>
    <!-- if the name owner the post  is another user -->
    <span
      v-else-if="
        post.model_type == 'Profile' && USER.profile.id != post.model.id
      "
    >
      <b
        v-if="USER.profile.id == post.model.id"
        @click="goto(USER.profile.id)"
        class="name-style"
        >{{ post.model.first_name }} {{ post.model.last_name }}</b
      >

      <b
        @click="goto(post.model.id)"
        class="name-style"
        v-else
        :class="!DefineDeriction() ? 'me-2' : 'ms-2'"
        >{{ post.model.first_name }} {{ post.model.last_name }}</b
      >

      <svg
        class="green"
        v-if="getActive(post.model.last_seen)"
        height="10"
        width="10"
        xmlns:dc="http://purl.org/dc/elements/1.1/"
        xmlns:cc="http://creativecommons.org/ns#"
        xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
        xmlns:svg="http://www.w3.org/2000/svg"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
        viewBox="0 0 448 448"
        id="svg2"
        version="1.1"
        inkscape:version="0.91 r13725"
        sodipodi:docname="dot.svg"
        fill="#00ff00"
        stroke="#00ff00"
      >
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></g>
        <g id="SVGRepo_iconCarrier">
          <title id="title3336">dot</title>
          <defs id="defs4"></defs>
          <g
            inkscape:label="Layer 1"
            inkscape:groupmode="layer"
            id="layer1"
            transform="translate(0,-604.36224)"
          >
            <circle
              style="
                fill-opacity: 1;
                stroke: none;
                stroke-width: 30;
                stroke-linecap: round;
                stroke-miterlimit: 4;
                stroke-dasharray: none;
                stroke-opacity: 1;
              "
              id="path3334"
              cx="224"
              cy="828.36224"
              r="192"
            ></circle>
          </g>
        </g>
      </svg>

      <!-- {{ post.tags_count }} -->
      <span v-if="post.tags && post.tags.length == 1">
        <span v-if="post.type == 7">
          <span>{{ $t("Celebrate a birthday") }} &nbsp;</span>

          <b
            @click="goto(USER.profile.id)"
            class="name-style"
            v-if="USER.id == post.tags[0].user_id"
            >{{ post.tags[0].name }}</b
          >
          <b v-else @click="goto(post.tags[0].id)" class="name-style">{{
            post.tags[0].name
          }}</b>
          🎂
        </span>
        <span v-else>
          <span>{{ $t("With") }} &nbsp;</span>

          <b
            v-if="USER.id == post.tags[0].user_id"
            @click="goto(USER.profile.id)"
            class="name-style"
            >{{ post.tags[0].name }}</b
          >
          <b v-else @click="goto(post.tags[0].id)" class="name-style">{{
            post.tags[0].name
          }}</b>
        </span>
      </span>
      <span v-else-if="post.tags_count && post.tags_count > 1">
        <span>{{ $t("With") }} &nbsp;</span>

        <b
          v-if="USER.id == post.tags[0].user_id"
          @click="goto(USER.profile.id)"
          class="name-style"
          >{{ post.tags[0].name }}</b
        >
        <b v-else @click="goto(post.tags[0].id)" class="name-style">{{
          post.tags[0].name
        }}</b>
      </span>
      <a
        @click="Get_all_tags_on_post(post.id)"
        class="dropdown text-decoration-none mx-1 px-0"
        style="cursor: pointer"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span v-if="post.tags_count > 1" class="">
          {{ $t("and") }} {{ post.tags_count - 1 }} {{ $t("Others") }}
        </span>
      </a>
      <ul class="dropdown-menu">
        <li class="text-center" v-if="loadingalltags">
          <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </li>
        <div v-else>
          <li
            class="dropdown-item pro guest"
            v-for="item in all"
            :key="item.id"
          >
            <span @click="goto(item.user_id)" class="name-style">
              <img crossorigin="anonymous" 
                v-if="item.has_media_profile === false"
                class="pd-image"
                src="@/assets/img_friends/default1.png"
                alt=""
              />
              <img crossorigin="anonymous" 
                v-else-if="item.media[0].collection_name === 'profile'"
                :src="item.media[0].src_url"
                class="pd-image"
                alt=""
              />
              <img crossorigin="anonymous" 
                v-else-if="item.media[1].collection_name === 'profile'"
                :src="item.media[1].src_url"
                class="pd-image"
                alt=""
              />
              <span class="mx-1">{{ item.name }}</span>
            </span>
          </li>
        </div>
      </ul>
    </span>
    <!-- if the name owner the post  is Page -->
    <span
      v-else
      :style="DefineDeriction() ? 'text-align: right;' : 'text-align: left;'"
    >
      <b @click="gotoPage(post.model.id)" class="name-style"
        >{{ post.model.name }}
      </b>
      <small
        class="followbtn"
        v-if="!post.model.following"
        style=""
        @click="unfollow(post.model.id, 0)"
      >
        {{ $t("Follow") }}</small
      >
      <small
        class="followbtn"
        v-else
        style=""
        @click="follow(post.model.id, 2)"
      >
        {{ $t("unFollow") }}</small
      >
    </span>
    <span class="info-type-image" v-if="post.type == 2"
      >&nbsp; {{ $t("update the cover photo") }}</span
    >
    <span class="info-type-image" v-if="post.type == 1"
      >&nbsp; {{ $t("update the personal profile photo") }}</span
    >
  </p>
</template>
<script>
import LangMixin from "@/mixins/LangMixin";
import { EncryptionRoute } from "@/utils/EncryptionRoute";

import GeneralPagesService from "@/services/Pages/GeneralPageServices";
import { mapMutations } from "vuex";
import ProfileService from "@/services/Profile/ProfileService";
import ActivePersonMixin from "@/mixins/ActivePersonMixin";
export default {
  name: "NameProfile",
  props: ["post", "USER"],
  mixins: [ActivePersonMixin, LangMixin],
  data() {
    return {
      loadingalltags: false,
      all: [],
    };
  },
  methods: {
    EncryptionRoute,
    ...mapMutations({
      UPDATE_FOLLOWING: "post/UPDATE_FOLLOWING",
    }),
    async Get_all_tags_on_post(id) {
      this.loadingalltags = true;
      await ProfileService.Get_all_tags_on_post(id).then((res) => {
        // console.log(res.data.data.items)
        this.all = res.data.data.items;
      });
      this.loadingalltags = false;
    },
    follow(page_id, type) {
      GeneralPagesService.s_following_specific_page({
        page_id: page_id,
        type: type,
      }).then((res) => {
        this.$snotify.success(res.data.message, "Page");
        this.UPDATE_FOLLOWING(page_id);
      });
    },
    unfollow(page_id, type) {
      GeneralPagesService.s_following_specific_page({
        page_id: page_id,
        type: type,
      }).then((res) => {
        this.$snotify.success(res.data.message, "Page");
        this.UPDATE_FOLLOWING(page_id);
      });
    },
    goto(profile_id) {
      this.$store.dispatch("profile/a_get_user_info", profile_id);
      this.$store.dispatch("profile/getProfilePost", profile_id);
      window.location.pathname = "/profile/" + EncryptionRoute(profile_id);
      // this.$router.push({
      //   name: "homeMyProfile",
      //   params: { id: EncryptionRoute(profile_id) },
      // });
    },
    gotoPage(page_id){
      this.$router.push({
        name: "homeSinglePage",
        params: { id:page_id },
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.followbtn {
  color: green;
  font-weight: bold;
  margin: 0 5px;
  cursor: pointer;
}

// .followbtn:lang(ar) {
//   float: right;
// }
// .followbtn:lang(ku) {
//   float: right;
// }

.router-link-to-profile {
  text-decoration: none !important;
  color: #111;
}

.router-link-to-profile.active_link {
  color: #111 !important;
  border: none !important;
  font-weight: normal !important;
}
span.info-type-image {
  color: #9d9d9d;
  font-size: 14px;
}
@media screen and (max-width: 992px) {
  span.info-type-image {
    display: none;
  }
}
.dropdown {
  text-decoration: none !important;
  color: #111;
  font-weight: 500;
  cursor: pointer;
}

.pd-image {
  width: 30px;
  height: 25px;
  margin-right: 20px;
  border-radius: 6px;
  margin-bottom: 10px;
}

ul.dropdown-menu.show {
  height: 196px;
  top: 64px;
  left: 0;
  bottom: 0;
  right: 0;
  background: snow;
  overflow-y: scroll;
}
.name-style {
  cursor: pointer;
}
</style>
