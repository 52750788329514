<template>
  <main id="app" v-bind:class="color">
    <div class="content-box">
      <NavbarGuestComponent v-if="userAuth == 'Guest'" />
      <NavbarHomeComponent v-if="userAuth != 'Guest'" />
      <!-- <transition name="moveInUp">
      <router-view />
    </transition> -->
      <router-view />
    </div>
    <vue-progress-bar></vue-progress-bar>
    <vue-snotify />
    <!-- Gifts Modal -->
    <SendGift />
    <AllGifts />
    <!-- Gifts Modal -->
    <DeletePost />
    <PostReportComponent />
    <SavePost />
  </main>
</template>

<script>
import { isLanguageRTL  } from "./locales/i18n";
import NavbarGuestComponent from "@/components/Navbar/NavbarGuestComponent.vue";
import NavbarHomeComponent from "@/components/Navbar/NavbarHomeComponent.vue";
import SendGift from "@/Elements/post/footer/SendGift.vue";
import AllGifts from "@/Elements/post/footer/AllGifts.vue";
import init from "@/services/Firebase/Firbase_init";
import { mapGetters, mapActions } from "vuex";
import DeletePost from "@/Elements/post/deletepost/deletepost.vue"
import PostReportComponent from "@/Elements/post/reportpost/PostReportComponent.vue";
import SavePost from "@/Elements/post/savepost/SavePost.vue";

export default {
  components: {
    NavbarGuestComponent,
    NavbarHomeComponent,
    SendGift,
    AllGifts,
    DeletePost,
    PostReportComponent,
    SavePost
  },
  data() {
    return {
      browserLanguage: "",
      currentTheme: localStorage.getItem("theme-color"),
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    ...mapGetters({
      USER: "auth/USER",
      AUTHINTICATED: "auth/AUTHINTICATED",
      color: "auth/color",
      lang: "auth/lang",
    }),
    userAuth() {
      if (this.AUTHINTICATED) {
        let user = JSON.parse(localStorage.getItem("user")).profile.name;
        return user;
      } else {
        let user = "Guest";
        return user;
      }
    },
  },
  methods: {

    ...mapActions({
      // GET_ALL_NOTIFICATIONS: "notification/GET_ALL_NOTIFICATIONS",
      GetNumberUnClickedNotification:
        "notification/GetNumberUnClickedNotification",
    }),
    swicthTheme(theme) {
      localStorage.setItem("theme-color", theme);
      this.currentTheme = localStorage.getItem("theme-color");
    },
  },
  mounted() {
    this.browserLanguage = navigator.language || navigator.userLanguage;

    init.MessageListener();
    //  [App.vue specific] When App.vue is finish loading finish the progress bar
    this.$Progress.finish();
    
  },
  created() {
    var directionValue = isLanguageRTL(this.$i18n.locale) ? "rtl" : "ltr";
    document.documentElement.style.setProperty("--direction", directionValue);
    if (this.AUTHINTICATED) {
      // this.GET_ALL_NOTIFICATIONS();
      this.GetNumberUnClickedNotification();
      
    }

    //  [App.vue specific] When App.vue is first loaded start the progress bar
    this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        // parse meta tags
        this.$Progress.parseMeta(meta);
      }
      //  start the progress bar
      this.$Progress.start();
      //  continue to next page
      next();
    });
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach(() => {
      //  finish the progress bar
      this.$Progress.finish();
    });
  },
};
</script>

<style lang="scss">
@import "@/Styles/App/ThemesStyle.scss";
// @import "@/Styles/instgram.css";
@import 'vue-advanced-cropper/dist/style.css';
// @import '~vue-advanced-cropper/dist/theme.classic.scss';


html {
  scroll-behavior: smooth;
}

body {
  margin: 0 !important;

  @include scrollBar;
}
// START GLOBAL STYLE
input:lang(ar),
textarea:lang(ar) {
  text-align: right;
}

@media screen and (max-width: 992px) {
  .active_link {
    // background-color: white !important;
    color: #0d988c !important;
    font-weight: bold;
    border-bottom: none !important;
    svg path {
      fill: $defaultColor;
    }
  }

  .modal-backdrop {
    width: 0 !important;
    height: 0 !important;
    --bs-backdrop-zindex: 1050 !important;
    --bs-backdrop-bg: #fff !important;
    --bs-backdrop-opacity: 1 !important;
  }
}

.modal-backdrop.show {
  opacity: 0 !important;
}
.modal-backdrop {
  width: 0 !important;
  height: 0 !important;
  --bs-backdrop-zindex: 10 !important;
  --bs-backdrop-bg: #fff !important;
  --bs-backdrop-opacity: 1 !important;
}

.error_background {
  background-image: linear-gradient(
    to right top,
    $defaultColor,
    $defaultColor,
    $defaultColor,
    $defaultColor,
    $defaultColor
  );
  li {
    font-weight: bold;
    color: #9b2121;
  }
}
.custom_button {
  background-color: #0d988c;
  color: white;
  border: 1px solid #0d988c;

  &:hover {
    color: white;
  }
}

.custom_text {
  color: $defaultColor;
  font-weight: bold;
}
// END GLOBAL STYLE
.moveInUp-enter-active {
  animation: fadeIn 0.5s ease-in;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
//
::selection {
  background-color: #ffdb00;
  color: #000;
}
</style>
