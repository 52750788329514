<template>
  <div v-if="post.has_media" class="media_container">
    <div class="media_one" v-if="post.media.length === 1">
      <img crossorigin="anonymous" 
        v-if="post.media[0].media_type.includes('Image')"
        :src="post.media[0].src_url"
        alt=""
        v-viewer
      />
      <router-link
        v-else-if="post.media[0].media_type.includes('Video')"
        :to="{
          name: 'showGroupPost',
          params: { id:post.group_id, postid: post.id },
        }"
      >
        <video crossorigin="anonymous"  controls :src="post.media[0].src_url"></video>
      </router-link>
      <a
        v-else
        :href="post.media[0].src_url"
        download="download"
        class="style-pdf"
        target="_blank"
      >
        <div
          class="div"
          style="
            max-height: 100% !important;
            width: 100% !important;
            height: 100%;
            overflow: hidden;
            background: #fff;
            display: flex;
            align-items: center;
            border: none;
            background-color: #ffffff;
            box-shadow: rgba(50, 50, 93, 0.25) 0px -2px 3px -2px inset,
              rgba(0, 0, 0, 0.3) 0px 6px 29px -16px inset;
            border-radius: 2px;
            /* width: 100%; */
            padding: 22px 25px 25px 55px;
            /* margin: 8px 0px; */
            border: 0px solid;
          "
        >
          <div class="one">
            <PdfSvg></PdfSvg>
          </div>
          <div class="two">
            <span style="font-weight: 500">
              &nbsp; {{ post.media[0].fullPath.substr(12) + "" }}
            </span>
          </div>
          <div class="three">
            <DownloadSvg></DownloadSvg>
          </div>
        </div>
      </a>
    </div>
    <router-link
      :to="{
        name: 'showGroupPost',
        params: { id:post.group_id, postid: post.id },
      }"
    >
      <div
        class="media_two d-flex justify-content-between align-items-center"
        v-if="post.media.length === 2"
      >
        <div class="media_two_box">
          <img crossorigin="anonymous" 
            v-if="post.media[0].media_type.includes('Image')"
            :src="post.media[0].src_url"
            alt=""
          />
          <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
        </div>
        <div class="media_two_box">
          <img crossorigin="anonymous" 
            v-if="post.media[1].media_type.includes('Image')"
            :src="post.media[1].src_url"
            alt=""
          />
          <video crossorigin="anonymous"  v-else controls :src="post.media[1].src_url"></video>
        </div>
      </div>
      <div class="media_three" v-if="post.media.length === 3">
        <div class="media_three_box">
          <img crossorigin="anonymous" 
            v-if="post.media[0].media_type.includes('Image')"
            :src="post.media[0].src_url"
            alt=""
          />
          <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
        </div>
        <div class="media_three_box">
          <img crossorigin="anonymous" 
            v-if="post.media[1].media_type.includes('Image')"
            :src="post.media[1].src_url"
            alt=""
          />
          <video crossorigin="anonymous"  v-else controls :src="post.media[1].src_url"></video>
        </div>
        <div class="media_three_box_under">
          <img crossorigin="anonymous" 
            v-if="post.media[2].media_type.includes('Image')"
            :src="post.media[2].src_url"
            alt=""
          />
          <video crossorigin="anonymous"  v-else controls :src="post.media[2].src_url"></video>
        </div>
      </div>
      <div class="media_four" v-if="post.media.length === 4">
        <div class="media_four_box">
          <img crossorigin="anonymous" 
            v-if="post.media[0].media_type.includes('Image')"
            :src="post.media[0].src_url"
            alt=""
          />
          <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
        </div>
        <div class="media_four_box">
          <img crossorigin="anonymous" 
            v-if="post.media[1].media_type.includes('Image')"
            :src="post.media[1].src_url"
            alt=""
          />
          <video crossorigin="anonymous"  v-else controls :src="post.media[1].src_url"></video>
        </div>
        <div class="media_four_box">
          <img crossorigin="anonymous" 
            v-if="post.media[2].media_type.includes('Image')"
            :src="post.media[2].src_url"
            alt=""
          />
          <video crossorigin="anonymous"  v-else controls :src="post.media[2].src_url"></video>
        </div>
        <div class="media_four_box">
          <img crossorigin="anonymous" 
            v-if="post.media[3].media_type.includes('Image')"
            :src="post.media[3].src_url"
            alt=""
          />
          <video crossorigin="anonymous"  v-else controls :src="post.media[3].src_url"></video>
        </div>
      </div>
    </router-link>
    <div class="media_bigger" v-if="post.media.length > 4">
      <div class="media_bigger_box">
        <img crossorigin="anonymous" 
          v-if="post.media[0].media_type.includes('Image')"
          :src="post.media[0].src_url"
          alt=""
        />
        <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
      </div>
      <div class="media_bigger_box">
        <img crossorigin="anonymous" 
          v-if="post.media[1].media_type.includes('Image')"
          :src="post.media[1].src_url"
          alt=""
        />
        <video crossorigin="anonymous"  v-else controls :src="post.media[1].src_url"></video>
      </div>
      <div class="media_bigger_box">
        <img crossorigin="anonymous" 
          v-if="post.media[2].media_type.includes('Image')"
          :src="post.media[2].src_url"
          alt=""
        />
        <video crossorigin="anonymous"  v-else controls :src="post.media[2].src_url"></video>
      </div>
      <div class="media_bigger_box">
        <img crossorigin="anonymous" 
          v-if="post.media[3].media_type.includes('Image')"
          :src="post.media[3].src_url"
          alt=""
        />
        <video crossorigin="anonymous"  v-else controls :src="post.media[3].src_url"></video>
      </div>
      <p class="bigger_p d-flex align-items-center justify-content-center">
        <router-link
          style="text-decoration: none"
          :to="{
            name: 'showGroupPost',
            params: { id:post.group_id, postid: post.id },
          }"
          ><span>+{{ post.media.length - 4 }}</span></router-link
        >
      </p>
    </div>
  </div>
</template>

<script>
import DownloadSvg from "@/icons/Groups/DownloadSvg.vue";
import PdfSvg from "@/icons/Groups/PdfSvg.vue";

export default {
  props: ["post"],
  components: { PdfSvg, DownloadSvg },
};
</script>
<style lang="scss" scoped>
//
.media_container {
  max-height: 700px;
  width: 100% !important;
  overflow: hidden;
  background: #000;
  .media_one {
    max-height: 100% !important;
    width: 100% !important;
    height: 100%;
    overflow: hidden;
    // text-align: center;

    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: fill !important;
    }

    video {
      object-fit: contain;
      width: 100%;
      height: 350px;
    }
  }

  .media_two {
    height: 100%;
    width: 100% !important;
    overflow: hidden;

    .media_two_box {
      width: 50%;
      height: 100% !important;
      overflow: hidden;

      img {
        width: 304px;
        margin: auto;
        display: block;
        height: 237px;
      }

      video {
        width: 115%;
        height: 200px;
        object-fit: contain;
      }
    }
  }

  .media_three {
    height: 100%;
    width: 100% !important;
    overflow: hidden;

    .media_three_box {
      width: 50%;
      height: 50% !important;
      display: inline-block;
      overflow: hidden;

      img {
        width: 304px;
        margin: auto;
        display: block;
        height: 237px;
      }

      video {
        width: 410px;
        margin: auto;
        display: block;
        height: 248px;
        object-fit: cover;
      }
    }

    .media_three_box_under {
      width: 100%;
      margin: auto;
      display: block;
      height: 248px;
      overflow: hidden;

      img {
        width: 600px;
        margin: auto;
        display: block;
        height: 237px;
      }

      video {
        width: 100% !important;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .media_four {
    height: 100%;
    width: 100% !important;
    overflow: hidden;

    .media_four_box {
      width: 50% !important;
      height: 50% !important;
      display: inline-block;
      overflow: hidden;

      img {
        width: 100%;
        margin: auto;
        display: block;
        height: 200px;
      }

      video {
        width: 100%;
        margin: auto;
        display: block;
        height: 200px;
        object-fit: cover;
      }
    }
  }

  .media_bigger {
    height: 100%;
    width: 100% !important;
    overflow: hidden;
    position: relative;

    .media_bigger_box {
      width: 50% !important;
      height: 50% !important;
      display: inline-block;
      overflow: hidden;

      img {
        width: 330px !important;
        height: 200px !important;
      }

      video {
        width: 330px !important;
        height: 200px !important;
        object-fit: cover;
      }
    }

    .bigger_p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-weight: bolder;
      font-size: 20px;
      width: 100px;
      height: 100px;
      padding: 0 !important;
      background-color: rgba(119, 119, 119, 0.699);
      border-radius: 50%;
      cursor: pointer;
    }
  }
}

.div {
  display: inline-block;
  float: left;
  color: #000000;
  font-size: 14px;
}

.one {
  width: 10%;
  // background:red;
}

.two {
  width: 85%;
  // background:lightgreen;
}

.three {
  width: 10%;
}

.style-pdf:hover {
  cursor: pointer;
  filter: brightness(95%);
}
</style>
