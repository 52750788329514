import storyServices from "@/services/story/story.services";
export default {
    namespaced: true,
    state: {
        stories: [],
        ALL_FOR_GUEST: [],
        gueststories: [],
        isLoading: true,
        isloadingnewstory: null,
        isloadingaddmorestory: null,
        users_with_lastStory: [],
        isLoadingAllStory: true,
        current_page: 1,
        last_page: null
    },
    getters: {
        User_Stories(state) {
            return state.stories;
        },
        User_Stories_Guest(state) {
            return state.gueststories;
        },
        All_User_Stories_Guest(state) {
            return state.ALL_FOR_GUEST;
        },
        isLoading_status(state) {
            return state.isLoading;
        },
        Get_User(state) {
            if (state.stories[0])
                return state.stories[0].user_id;
            else return null;
        }
    },
    mutations: {
        IncreacePage(state){state.current_page++},
        TOOGLE_STORY_COMMENT_STATUS(state, payload) {
            state.stories.forEach(item => {
                if (item.id == payload) {
                    item.comment_status = !item.comment_status
                }
            })
        },
        ADD_INTERACTIO_TO_STORY(state, payload) {
            state.stories.forEach(item => {
                if (item.id == payload.story_id) {
                    item.interacted = payload.type
                }
            })
        },
        DELETE_ALL_INTERACTION(state, payload) {
            state.stories.forEach(item => {
                if (item.id == payload) {
                    item.interacted = null
                }
            })
        },
        SET_ALL_FOR_GUEST(state, payload) {
            state.ALL_FOR_GUEST.push(payload)
        },
        CLEAR_ALL_FOR_GUEST(state) {
            state.ALL_FOR_GUEST = []
        },
        SET_IS_LOADING_NEW_STORY(state) {
            state.isloadingnewstory = !state.isloadingnewstory
        },
        SET_USER_STORYIES(state, data) {
            state.stories = data;
            state.isLoading = false;
        },
        SET_USER_STORYIES_GUEST(state, data) {
            // state.gueststories = []
            state.gueststories = data;
            // state.isLoading = false;
            // console.log(state.gueststories)
        },
        CLEAR_USER_STORYIES_GUEST(state) {
            state.gueststories = []
        },
        Claer_USER_STORIES(state) {
            state.stories = [];
            state.isLoading = true;
        },
        ADD_NEW_STORY(state, payload) {
            if ((state.users_with_lastStory[0]) && (state.users_with_lastStory[0].user_id === payload.user_id)) {
                state.users_with_lastStory[0].last_story = payload
            } else {
                let data = {
                    blocked_until: null,
                    first_name: JSON.parse(localStorage.getItem("user")).profile.first_name,
                    has_media_cover: JSON.parse(localStorage.getItem("user")).profile.has_media_cover,
                    has_media_profile: JSON.parse(localStorage.getItem("user")).profile.has_media_profile,
                    id: payload.user_id,
                    isBlocked: 0,
                    last_name: JSON.parse(localStorage.getItem("user")).profile.first_name,
                    last_story: payload,
                    media: JSON.parse(localStorage.getItem("user")).profile.media,
                    middle_name: JSON.parse(localStorage.getItem("user")).profile.middle_name,
                    mutualfriends_count: 0,
                    name: JSON.parse(localStorage.getItem("user")).profile.name,
                    user_id: JSON.parse(localStorage.getItem("user")).profile.user_id
                }

                state.users_with_lastStory.unshift(data)
            }
        },
        DeleteStory(state, payload) {
            // console.log('ppp',payload)
            state.stories = state.stories.filter(story => story.id != payload)
            // console.log(state.stories)
        }
    },
    actions: {
        async getStories({ commit }, item) {
            commit("Claer_USER_STORIES");
                await storyServices.GetStoriesById(item).then(
                    response => {
                        // console.log(response.data.ShowStoriesQuery)
                        commit("SET_USER_STORYIES", response.data.ShowStoriesQuery.stories)
                    }
                )
            // if (item.last_story.model_type == 'Page') {
            //     return await storyServices.getPageStories(item.id).then(
            //         response => {
            //             commit("SET_USER_STORYIES", response.data.data)
            //         }
            //     )
            // } else {
            //     return await storyServices.getUserStories(item.id).then(
            //         response => {
            //             commit("SET_USER_STORYIES", response.data.data)
            //         }
            //     )
            // }

        },

        async getAllStoriesInGuest({ state }) {
            state.current_page = 1;
            state.last_page = null;
            let data = {
                page: state.current_page,
            }
            await storyServices.getAllStoriesInGuest(data)
                .then((response) => {
                    state.isLoadingAllStory = false;
                    state.last_page = response.data.StoriesQuery.last_page
                    state.users_with_lastStory = response.data.StoriesQuery.items;
                    // console.log(state.users_with_lastStory)
                })
                .catch(() => {
                });
        },

        async getNewAllStoriesInGuest({ state }) {
            if((state.current_page++) <= state.last_page) {
                let data = {
                    page: state.current_page,
                    page_id: null
                }
                await storyServices.getAllStories((data))
                    .then((response) => {
                        state.users_with_lastStory = state.users_with_lastStory.concat(response.data.StoriesQuery.items) // response.data.data.items;
                    })
            }
            // commit('IncreacePage')
        },

        async getAllStories({ state }, page) {
            state.current_page = 1;
            state.last_page = null;
            let data = {
                page: state.current_page,
            }

            page != '' ? data.page_id = page.id : ''
            await storyServices.getAllStories(data)
                .then((response) => {
                    state.isLoadingAllStory = false;
                    state.last_page = response.data.StoriesQuery.last_page
                    state.users_with_lastStory = response.data.StoriesQuery.items;
                })
                .catch(() => {
                });
        },

        async getNewAllStories({ state }) {
            if((state.current_page++) <= state.last_page) {
                state.isloadingaddmorestory = true
                let data = {
                    page: state.current_page,
                }
                await storyServices.getAllStories((data))
                    .then((response) => {
                        state.users_with_lastStory = state.users_with_lastStory.concat(response.data.StoriesQuery.items) // response.data.data.items;
                    })
                    state.isloadingaddmorestory = false
            }
            // commit('IncreacePage')
        },


    }

};