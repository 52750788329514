import { Media_FRAGMENT } from "@/graphql/Fragments/MediaData";
import gql from "graphql-tag";

export const STORE_ANSWER = gql`
${Media_FRAGMENT}
mutation StoreAnswer($page_id:Int , $post_id:Int , $body:String) {
    StoreAnswer(page_id: $page_id, post_id: $post_id, body: $body) {
        id
        model_type
        model_id
        post_id
        body
        rate
        ratesCount
        reviewed
        created_at
        updated_at
        model {
          ... on PageType {
            id
            name
            has_media_profile
            has_media_cover
            has_role
            media {
              ...MediaData
            }
          }
          ... on ProfileType {
            id
            first_name
            middle_name
            last_name
            name
            has_media_profile
            has_media_cover
            media {
              ...MediaData
            }
          }
        }
    }
}

`