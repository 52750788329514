<template>
  <svg
    class="bi bi-chevron-down ml-2"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      d="M8 5.5a.5.5 0 0 1 .5.5v4.793l1.646-1.647a.5.5 0 1 1 .708.708l-2.5 2.5a.5.5 0 0 1-.708 0l-2.5-2.5a.5.5 0 1 1 .708-.708L7.5 10.293V5.5a.5.5 0 0 1 .5-.5z"
    />
  </svg>
</template>
