import ReportService from "@/services/Report/report.services"
export default {
    namespaced: true,
    state: {
        AllReportsOnPostOrComment: null,
        AllReportsOnProfile: null,
        loadingReport: false
    },
    mutations: {},
    actions: {
        async GET_ALL_Reports_On_Post_Or_Comment({state}) {
            if(!state.AllReportsOnPostOrComment) {
                state.loadingReport = true
                await ReportService.getAllReportsOnPostOrComment().then(
                    response => {
                        state.AllReportsOnPostOrComment = response.data.data
                    }
                )
                state.loadingReport = false
            }
          },

        async GET_ALL_Reports_On_Profile({state}) {
            if(!state.AllReportsOnProfile) {
                state.loadingReport = true
                await ReportService.getAllReportsOnProfile().then(
                    response => {
                        state.AllReportsOnProfile = response.data.data
                    }
                )
                state.loadingReport = false
            }
        },
    }
};