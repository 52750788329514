<template>
  <div class="d-flex align-items-center">
    <div>
      <!-- image for user -->
      <!-- <router-link
                :to="{
                  name: 'showSingleGroup',
                  params: { id: post.group_id.id },
                }"
              > -->
      <img crossorigin="anonymous" 
        v-if="post.profile.has_media_cover === false"
        class="cover-img cover-container"
        src="@/assets/groups/groups-default-cover-photo.jpg"
        alt=""
      />
      <img crossorigin="anonymous"  v-else :src="post.profile.media[0].src_url" alt="" />

      <img crossorigin="anonymous" 
        style="
          border-radius: 50%;
          width: 25px;
          height: 25px;
          margin: 0 0px -12px -25px;
        "
        v-if="post.profile.has_media_profile === false"
        src="@/assets/img_friends/default1.png"
        alt=""
      />
      <div v-else>
        <img crossorigin="anonymous" 
          style="
            border-radius: 50%;
            width: 25px;
            height: 25px;
            margin: -65px 0px -11px 20px;
          "
          v-if="post.profile.media[0].collection_name === 'profile'"
          :src="post.profile.media[0].src_url"
          alt=""
        />
        <img crossorigin="anonymous" 
          style="
            border-radius: 50%;
            width: 25px;
            height: 25px;
            margin: -65px 0px -11px 20px;
          "
          v-else
          :src="post.profile.media[1].src_url"
          alt=""
        />
      </div>
      <!-- </router-link> -->
    </div>
    <div class="ms-1">
      <p class="mb-0">
        <router-link
          class="router-link-to-profile"
          :to="{
            name: 'showSingleGroup',
            params: { id: post.group_id },
          }"
        >
          <b class="style-user">{{ post.group_id.name }}</b>
        </router-link>
      </p>
    </div>
  </div>
  <!-- <div v-if="post.anonymous == 1">
    <img crossorigin="anonymous" 
     
      src="@/assets/story_img/user.png"
      alt=""
    />
    <div>
      <img crossorigin="anonymous" 
        v-if="post.profile.media[0].collection_name === 'profile'"
        :src="post.profile.media[0].src_url"
        alt=""
      />
      <img crossorigin="anonymous"  v-else :src="post.profile.media[1].src_url" alt="" />
    </div>
  </div>
  <div v-else>
    <img crossorigin="anonymous" 
      v-if="post.profile.has_media_profile === false"
      src="@/assets/story_img/user.png"
      alt=""
    />
    <div v-else>
      <img crossorigin="anonymous" 
        v-if="post.profile.media[0].collection_name === 'profile'"
        :src="post.profile.media[0].src_url"
        alt=""
      />
      <img crossorigin="anonymous"  v-else :src="post.profile.media[1].src_url" alt="" />
    </div>
  </div> -->
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["post"],
  computed: {
    ...mapGetters({
      singleGroup: "singleGroup/singleGroup",
    }),
  },
};
</script>
<style lang="scss" scoped>
img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
@media screen and (max-width: 992px) {
  img {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    object-fit: cover;
  }
}
</style>
