<template>
  <svg
  width="25"
  height="25"
  viewBox="0 0 1500 1500"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  fill="#000000"
>
  <circle cx="750" cy="750" r="750" fill="#ffda6b" />
  <ellipse cx="748.3" cy="1046.3" rx="220.6" ry="297.2" fill="#262c38" />
  <ellipse
    transform="rotate(-81.396 402.197 564.888)"
    cx="402.2"
    cy="564.9"
    rx="155.6"
    ry="109.2"
    fill="#262c38"
  />
  <ellipse
    transform="rotate(-8.604 1093.463 564.999)"
    cx="1093.2"
    cy="564.9"
    rx="109.2"
    ry="155.6"
    fill="#262c38"
  />
  <path
    d="M320.9 223s69.7-96.7 174-28.6M1177.5 223s-69.7-96.7-174-28.6"
    fill="none"
    stroke="#262c38"
    stroke-width="60"
    stroke-linecap="round"
    stroke-linejoin="round"
    stroke-miterlimit="10"
  />
</svg>

</template>
