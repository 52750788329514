<template>
  <div
    dir="auto"
    class="row m-0 justify-content-center"
    v-if="!this.is_loading"
  >
    <!-- Start Sidebar -->
    <div class="col-md-4 left order-first">
      <div class="content">
        <div class="card-header">
          <span>
            <router-link
              :to="{ name: 'HomeMarketPlace' }"
              class="router-link-to-path"
              >{{ $t("Market Place") }}</router-link
            >
            / <b style="font-size: 22px">{{ item.category.name }}</b></span
          >
          <!-- <span class="font-mediaNas" style="font-size: 22px">
            <b>{{ item.category.name }}</b>
          </span> -->
        </div>
        <div class="card-body">
          <!-- user's Photo -->
          <div class="d-flex align-items-center">
            <h2 class="mt-3">{{ item.name }}</h2>
          </div>
          <h5 v-if="item.element_status != null" style="color: #6e098d">
            {{ item.element_status }}
          </h5>
          <h6 v-if="item.price == null" style="color: rgb(82, 5, 5)">
            {{ $t("unavailable") }}
          </h6>
          <h6 v-else>{{ item.price }}$</h6>
          <span class="text-muted">
            {{ $t("Offered for sale") }}
            {{ format_date(item.created_at) }}
            {{ $t("in") }}
            {{ item.city.name }}
          </span>
          <hr />
          <h5>{{ $t("Seller Description") }}</h5>
          <p
            class="fs-smaller text-secondary"
            style="overflow-wrap: break-word"
          >
            {{ item.description }}
          </p>
          <!-- <p v-for="(item, index) in all_videos" :item="item" :key="index">
            <span>Contain video </span>
          </p> -->

          <GMapMap
            :center="{ lat: item.city.latitude, lng: item.city.longitude }"
            :zoom="13"
            map-type-id="terrain"
            style="width: 95%; height: 200px"
          >
          </GMapMap>
          <h6>{{ item.city.name }}</h6>
          <span class="text-muted">
            {{ $t("Location is approximate") }}
          </span>
          <h5>{{ $t("Additional details") }}</h5>
          <div v-if="item.category.parent.name == 'Vehicles'">
            <span class="text-muted" v-if="item.addtional_data[0].make != null">
              {{ $t("make") }} : {{ item.addtional_data[0].make }}
              <br />
            </span>
            <span class="text-muted" v-if="item.addtional_data[0].year != null">
              {{ $t("year") }} : {{ item.addtional_data[0].year }}
              <br />
            </span>
            <span
              class="text-muted"
              v-if="item.addtional_data[0].model != null"
            >
              {{ $t("model") }} : {{ item.addtional_data[0].model }}
              <br />
            </span>
            <span
              class="text-muted"
              v-if="item.addtional_data[0].mileage != null"
            >
              {{ $t("mileage") }} : {{ item.addtional_data[0].mileage }}
              <br />
            </span>
          </div>
          <div v-if="item.category.parent.name == 'ItemForSale'">
            <span
              class="text-muted"
              v-if="item.addtional_data[0].address != null"
            >
              {{ $t("address") }} : {{ item.addtional_data[0].address }}
              <br />
            </span>
            <span
              class="text-muted"
              v-if="item.addtional_data[0].phoneNumber != null"
            >
              {{ $t("phoneNumber") }} : {{ item.addtional_data[0].phoneNumber }}
              <br />
            </span>
          </div>
          <div v-if="item.category.parent.name == 'HomeForSaleOrRent'">
            <span
              class="text-muted"
              v-if="item.addtional_data[0].landryType != null"
            >
              {{ $t("landry Type") }} : {{ item.addtional_data[0].landryType }}
              <br />
            </span>
            <span
              class="text-muted"
              v-if="item.addtional_data[0].rentalType != null"
            >
              {{ $t("rental Type") }} : {{ item.addtional_data[0].rentalType }}
              <br />
            </span>
            <span
              class="text-muted"
              v-if="item.addtional_data[0].catFriendly != null"
            >
              {{ $t("cat Friendly") }} :
              {{ item.addtional_data[0].catFriendly }}
              <br />
            </span>
            <span
              class="text-muted"
              v-if="item.addtional_data[0].dogFriendly != null"
            >
              {{ $t("dog Friendly") }} :
              {{ item.addtional_data[0].dogFriendly }}
              <br />
            </span>
            <span
              class="text-muted"
              v-if="item.addtional_data[0].heatingType != null"
            >
              {{ $t("heatingType") }} : {{ item.addtional_data[0].heatingType }}
              <br />
            </span>
            <span
              class="text-muted"
              v-if="item.addtional_data[0].parkingType != null"
            >
              {{ $t("parkingType") }} : {{ item.addtional_data[0].parkingType }}
              <br />
            </span>
            <span
              class="text-muted"
              v-if="item.addtional_data[0].pricePerMonth != null"
            >
              {{ $t("pricePerMonth") }} :
              {{ item.addtional_data[0].pricePerMonth }}
              <br />
            </span>
            <span
              class="text-muted"
              v-if="item.addtional_data[0].rentalAddress != null"
              style="overflow-wrap: break-word"
            >
              {{ $t("rental Address") }} :
              {{ item.addtional_data[0].rentalAddress }}
              <br />
            </span>
            <span
              class="text-muted"
              v-if="item.addtional_data[0].bedroomsNumber != null"
            >
              {{ $t("bedroomsNumber") }} :
              {{ item.addtional_data[0].bedroomsNumber }}
              <br />
            </span>
            <span
              class="text-muted"
              v-if="item.addtional_data[0].bathroomsNumber != null"
            >
              {{ $t("bathroomsNumber") }} :
              {{ item.addtional_data[0].bathroomsNumber }}
              <br />
            </span>
            <span
              class="text-muted"
              v-if="item.addtional_data[0].airConditioningType != null"
            >
              {{ $t("airConditioningType") }} :
              {{ item.addtional_data[0].airConditioningType }}
              <br />
            </span>
            <span
              class="text-muted"
              v-if="item.addtional_data[0].propertySquareMeter != null"
            >
              {{ $t("propertySquareMeter") }} :
              {{ item.addtional_data[0].propertySquareMeter }}
              <br />
            </span>
          </div>
          <span class="text-muted">
            {{ $t("Location is approximate") }}
          </span>
          <hr />

          <div class="clearfix mb-1">
            <span class="float-start">
              {{ $t("Seller information") }}
            </span>

            <span
              class="float-end mx-3 mb-2"
              style="color: #3614cc; font-weight: 600"
            >
              {{ $t("Seller details") }}
            </span>
          </div>
          <div class="d-flex align-items-center">
            <img crossorigin="anonymous" 
              class="profile_image"
              v-if="item.seller.has_media_profile === false"
              src="@/assets/story_img/user.png"
              alt=""
            />
            <img crossorigin="anonymous" 
              class="profile_image"
              v-else-if="item.seller.media[0].collection_name === 'profile'"
              :src="item.seller.media[0].src_url"
              alt=""
            />
            <img crossorigin="anonymous" 
              class="profile_image"
              v-else
              :src="item.seller.media[1].src_url"
              alt=""
            />
            <div class="ms-1">
              <p class="mb-0">{{ item.seller.name }}</p>
              <!-- <small class="text-muted">
                {{ $t("Item for sale on Marketplace") }}
              </small> -->
            </div>
          </div>
          <br />
          <h6 class="text-muted">تم الانضمام الى ميديا ناس في 2023</h6>
          <br />
          <br />
          <br />
        </div>
        <!-- <div class="card-footer">
          <button
            class="btn btn-primary mt-4 btn-style"
            style="bottom: 20px; width: 100%"
          >
            {{ $t("Create") }}
          </button>
        </div> -->
      </div>
    </div>
    <!-- End Sidebar -->
    <!-- photos -->
    <div class="container-fluid">
      <div class="content" style="background: #000; height: 530px">
        <div
          class="col-lg-8 offset-md-4 right mb-0 pt-1 px-3 order-last center"
        >
          <div class="">
            <div class="row">
              <div class="col-md-12">
                <div
                  id="custCarousel"
                  class="carousel slide"
                  data-bs-ride="carousel"
                  align="center"
                >
                  <!-- slides -->
                  <div class="carousel-inner">
                    <div
                      v-for="(photo, index) in this.item.media"
                      :key="index"
                      :class="['carousel-item', index === 0 ? 'active' : '']"
                    >
                      <img crossorigin="anonymous" 
                        v-if="photo.media_type == 'Image'"
                        :src="photo.src_url"
                        alt="PhotosItem"
                        v-viewer
                        :style="{
                          width:
                            photo.width < 2100
                              ? photo.width
                              : photo.width > 2100
                              ? photo.width / 2 + 'px'
                              : '35%',
                        }"
                      />
                      <video crossorigin="anonymous" 
                        v-else
                        :id="'vid' + photo.id"
                        controls
                        :src="photo.src_url"
                      ></video>
                      <!-- Left right -->
                      <a
                      v-if="this.item.media.length > 1"
                        class="carousel-control-prev"
                        href="#custCarousel"
                        data-bs-slide="prev"
                        style="opacity: 1"
                        @click="stop(photo.id, photo)"
                      >
                        <PreviousSlide></PreviousSlide>
                      </a>
                      <a
                      v-if="this.item.media.length > 1"
                        class="carousel-control-next"
                        href="#custCarousel"
                        data-bs-slide="next"
                        style="opacity: 1"
                        @click="stop(photo.id, photo)"
                      >
                        <NextSlide></NextSlide>
                      </a>
                    </div>
                  </div>

                  <!-- Thumbnails -->
                  <ol class="carousel-indicators list-inline">
                    <li
                      v-for="(photo, index) in this.item.media"
                      :key="index"
                      :class="['list-inline-item', index === 0 ? 'active' : '']"
                    >
                      <a
                        :id="'carousel-selector-' + index"
                        class="selected"
                        :data-bs-slide-to="index"
                        data-bs-target="#custCarousel"
                        @click="stop(photo.id, photo)"
                      >
                        <img crossorigin="anonymous" 
                          :id="'vid' + photo.id"
                          v-if="photo.media_type == 'Image'"
                          :src="photo.src_url"
                          class="img-fluid"
                        />

                        <video crossorigin="anonymous" 
                          v-else
                          :id="'vid' + photo.id"
                          :src="photo.src_url"
                        ></video>
                      </a>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div
      class="d-flex align-items-center justify-content-center position-absolute top-50 start-50"
    >
      <LoadingComponent></LoadingComponent>
    </div>
  </div>
</template>

<script>
import PreviousSlide from "@/components/marketplace/icons/PreviousSlide.vue";
import NextSlide from "@/components/marketplace/icons/NextSlide.vue";

import formatTime from "@/mixins/formatTime";
import LoadingComponent from "@/components/marketplace/LoadingComponent.vue";
import MarketPlaceService from "@/services/MarketPlace/MarketPlaceService";
import { mapGetters } from "vuex";
export default {
  components: {
    LoadingComponent,
    PreviousSlide,
    NextSlide,
  },
  data() {
    return {
      item_id: this.$route.params.id,
      item: {},
      is_loading: false,
    };
  },
  mixins: [formatTime],
  created() {
    this.get_item();
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
    }),
    // get Full Name
    full_name() {
      return (
        JSON.parse(localStorage.getItem("user")).profile.first_name +
        " " +
        JSON.parse(localStorage.getItem("user")).profile.last_name
      );
    },
  },
  methods: {
    stop(index, media) {
      // console.log(media)
      if (media.media_type != "Image") {
        var current = document.getElementById(`vid${index}`);
        current.pause();
      }
    },
    async get_item() {
      this.is_loading = true;
      await MarketPlaceService.s_show_item(this.item_id)
        .then((res) => {
          this.item = res.data.data;

          this.is_loading = false;
        })
        .catch(() => {
          this.$snotify.error("something wrong!", "Error");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/Styles/Groups/sidebarCreateGroup.scss";
.card-body {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 480px;
  @include scrollBar;
}

.col-images-upload {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 10px;
  background: #ffffff;
}
.col-images-upload:hover {
  background: #d8d6d6;
}

// carousel
body {
  background-color: #7b1fa2;
}

.container {
  margin-top: 100px;
  margin-bottom: 100px;
}

.carousel-inner {
  height: 350px;
}
// .carousel-inner img {
//   width: 35%;
//   height: 100%;
// }
// .carousel-inner video {
//   width: 75%;
//   height: 100%;
// }

#custCarousel .carousel-indicators {
  position: static;
  margin-top: 20px;
}

#custCarousel .carousel-indicators > li {
  width: 100px;
}

#custCarousel .carousel-indicators li img {
  display: block;
  opacity: 0.5;
  height: 75px;
  width: 75px;
}
#custCarousel .carousel-indicators li video {
  display: block;
  opacity: 0.5;
  height: 75px;
  width: 75px;
}

#custCarousel .carousel-indicators li.active img {
  opacity: 1;
}

#custCarousel .carousel-indicators li:hover img {
  opacity: 0.75;
}

.carousel-item img {
  width: 50%;
  height: 350px;
}
.carousel-item video {
  width: 50%;
  height: 350px;
}
.center:lang(ar) {
  margin-right: 35% !important;
  margin-left: 0 !important;
}
.center:lang(ku) {
  margin-right: 35% !important;
  margin-left: 0 !important;
}
</style>
