<template>
  <div
    class="col-md-12 col-lg-5 col-sm-12 py-0 center"
    id="CenterInHome"
    style="scroll-behavior: smooth"
  >
    <!-- Story Section -->
    <StoryComponent />
    <!-- Story Section -->

    <!-- BirthDay -->
    <AlertBirthDayComponent
      v-if="BirthDates.length > 0 && !this.page"
      :items="BirthDates"
    />

    <!-- Alert of winners -->
    <!-- <AlertComponent
      v-if="Alerts.length > 0"
      v-on:makeRead="makeRead()"
      :items="Alerts"
    /> -->

    <!-- Add Post -->
    <AddpostComponent />

    <QuestionsComponents></QuestionsComponents>
    <!-- Post Section -->
    <div class="row me-0 mb-3 bg-white" v-if="this.all_categories.length != 0">
      <div class="">
        <div
          class="row justify-content-center overflow-hidden align-items-center wrapper"
        >
          <div class="col-12 h-100">
            <!-- Start -->
            <div class="wrapper" dir="auto">
              <div class="scroll-container">
                <div class="checkbox-container">
                  <swiper
                    :scrollbar="{
                      hide: true,
                    }"
                    :modules="modules"
                    class="mySwiper"
                    :slides-per-view="8"
                    :space-between="15"
                  >
                    <swiper-slide
                      v-for="(option, index) in orderedOptions"
                      :key="index"
                      :style="{ order: option.order }"
                      class="col-1 mx-1"
                    >
                      <div class="">
                        <input
                          type="checkbox"
                          :id="option.id"
                          name="selector"
                          v-model="filters.category"
                          @change="option.onChange"
                          :true-value="option.value"
                          :false-value="null"
                        />
                        <label
                          class="selector"
                          :for="option.id"
                          :title="option.title"
                        >
                          <component :is="option.icon"></component>
                        </label>
                      </div>
                      <div class="row text-center">
                        <label class="name-filter m-1" :for="option.id">{{
                          option.label
                        }}</label>
                      </div>
                    </swiper-slide>
                    <!-- All  -->
                    <!-- <swiper-slide
                      class="col-1 mx-2"
                      :style="{ order: allOption.order }"
                    >
                      <div class="">
                        <input
                          type="checkbox"
                          id="option-a"
                          name="selector"
                          @change="get_all"
                          :checked="check_home_filter"
                          v-model="filters.category"
                          :true-value="'all'"
                          :false-value="null"
                        />
                        <label
                          class="selector option-a"
                          for="option-a"
                          title="Home"
                        >
                          <ForYouIcon></ForYouIcon>
                        </label>
                      </div>
                      <div class="row text-center">
                        <label
                          class="name-filter mx-1 mt-1 mb-0"
                          for="option-a"
                          >{{ $t("All") }}</label
                        >
                        <label
                          class="mt-0 mb-1"
                          v-if="check_home_filter"
                          for=""
                        >
                          <svg
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModalForAllFilter"
                            style="cursor: pointer"
                            width="18"
                            viewBox="0 0 32 32"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                            xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
                            fill="#000000"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <title>minus-circle</title>
                              <desc>Created with Sketch Beta.</desc>
                              <defs></defs>
                              <g
                                id="Page-1"
                                stroke="none"
                                stroke-width="1"
                                fill="none"
                                fill-rule="evenodd"
                                sketch:type="MSPage"
                              >
                                <g
                                  id="Icon-Set-Filled"
                                  sketch:type="MSLayerGroup"
                                  transform="translate(-518.000000, -1089.000000)"
                                  fill="#983baf"
                                >
                                  <path
                                    d="M540,1106 L528,1106 C527.447,1106 527,1105.55 527,1105 C527,1104.45 527.447,1104 528,1104 L540,1104 C540.553,1104 541,1104.45 541,1105 C541,1105.55 540.553,1106 540,1106 L540,1106 Z M534,1089 C525.163,1089 518,1096.16 518,1105 C518,1113.84 525.163,1121 534,1121 C542.837,1121 550,1113.84 550,1105 C550,1096.16 542.837,1089 534,1089 L534,1089 Z"
                                    id="minus-circle"
                                    sketch:type="MSShapeGroup"
                                  ></path>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </label>
                      </div>
                    </swiper-slide> -->
                    <!--
                    <swiper-slide
                      v-for="(cat, index) in all_categories"
                      :key="index"
                      class="col-1 mx-2"
                      :style="{ order: all_categories_order.order }"
                    >
                      <div class="">
                        <input
                          @change="filter_posts_category"
                          :id="`radio1` + cat.id"
                          v-model="this.filters.page_category_id"
                          :value="cat.id"
                          type="radio"
                        />
                        <label
                          class="selector-following"
                          :for="`radio1` + cat.id"
                          :title="cat.name"
                        >
                          <img
                            crossorigin="another"
                            :src="cat.icon_url"
                            class="img-sub-cat"
                            style="
                              width: 45px;
                              height: 43px;
                              border-radius: 50%;
                            "
                          />
                        </label>
                      </div>
                      <div class="row text-center">
                        <div class="">
                          <label
                            class="name-filter m-1"
                            :for="`radio1` + cat.id"
                          >
                            {{ cat.name.slice(0, 10) }}
                          </label>
                        </div>
                      </div>
                    </swiper-slide>
                  -->
                  </swiper>
                  <!-- Add more checkboxes as needed -->
                </div>
              </div>
            </div>
            <!-- End -->
          </div>
        </div>
      </div>
    </div>

    <div v-if="isLoadingallPosts == true">
      <SkeltonComponent />
    </div>
    <!-- No Posts In Array -->
    <div v-else-if="!isLoadingallPosts && postData.length == 0">
      <div
        class="bg-white text-center py-3"
        style="
          border-radius: 5px;
          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
            rgba(0, 0, 0, 0.24) 0px 1px 2px;
        "
      >
        <p class="text-danger">{{ $t("There are no posts in this list") }}</p>
        <button
          class="btn btn-primar text-white"
          style="background-color: #983baf"
          @click="get_all"
        >
          {{ $t("Go to All Posts") }}
        </button>
      </div>
      <SuggestedComponent />
    </div>

    <div class="mb-5" v-else-if="postData.length > 0 && !isLoadingNewPost">
      <div
        v-for="(post, index) in postData.slice(0, randomnumber)"
        :key="index"
      >
        <span class="" v-if="post.model_type == 'Groups'">
          <GroupPostComponent
            @ChangeIdOfSelectedPostTocomment="ChangeIdOfSelectedPostTocomment"
            :IdOfSelectedPostTocomment="IdOfSelectedPostTocomment"
            :post="post"
          ></GroupPostComponent>
          <!-- <GroupPost :post="post"></GroupPost> -->
        </span>

        <PostComponentV2
          @ChangeIdOfSelectedPostTocomment="ChangeIdOfSelectedPostTocomment"
          :IdOfSelectedPostTocomment="IdOfSelectedPostTocomment"
          class="postsFormedianasviewinapi"
          :location="1"
          :data-view-number="post.id"
          :post="post"
          v-else
        />
      </div>
      <SuggestedComponent />
      <div v-for="(post, index) in postData.slice(randomnumber)" :key="index">
        <span class="" v-if="post.model_type == 'Groups'">
          <GroupPostComponent
            @ChangeIdOfSelectedPostTocomment="ChangeIdOfSelectedPostTocomment"
            :IdOfSelectedPostTocomment="IdOfSelectedPostTocomment"
            :post="post"
          ></GroupPostComponent>
          <!-- <GroupPost :post="post"></GroupPost> -->
        </span>

        <PostComponentV2
          @ChangeIdOfSelectedPostTocomment="ChangeIdOfSelectedPostTocomment"
          :IdOfSelectedPostTocomment="IdOfSelectedPostTocomment"
          class="postsFormedianasviewinapi"
          :location="1"
          :data-view-number="post.id"
          :post="post"
          v-else
        />
      </div>
      <div class="d-flex justify-content-center d-none" id="spinner">
        <div class="spinner-border text-success" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div
        v-if="finishedPost && filters.category == 'forYou'"
        class="bg-white text-center py-3"
        style="
          border-radius: 5px;
          box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px,
            rgba(0, 0, 0, 0.24) 0px 1px 2px;
        "
      >
        <p class="text-danger">{{ $t("No suggested posts available") }}</p>
        <button
          class="btn btn-primar text-white"
          style="background-color: #983baf"
          @click="get_all"
        >
          {{ $t("Go to All Posts") }}
        </button>
      </div>
    </div>

    <div v-else-if="postData.length > 0 && isLoadingNewPost">
      <SkeltonAddPostComponent />
      <div v-for="(post, index) in postData" :key="index">
        <span v-if="post.model_type == 'Groups'">
          <GroupPostComponent :post="post"></GroupPostComponent>
          <!-- <GroupPost :post="post"></GroupPost> -->
        </span>
        <PostComponentV2 :location="1" :post="post" v-else />
      </div>
    </div>

    <!-- Post Section -->

    <!-- Nobility Section -->
    <div v-if="postData.length > 0" v-click-outside="onClickOutside">
      <div class="NobilityContainer">
        <div class="position-relative">
          <div class="speeds" v-if="ShowBoxesSpeed">
            <div class="boxspeed" @click="ToogleNobility(1)">1</div>
            <div class="boxspeed" @click="ToogleNobility(2)">2</div>
            <div class="boxspeed" @click="ToogleNobility(3)">3</div>
            <div class="boxspeed" @click="ToogleNobility(4)">4</div>
            <div class="boxspeed" @click="ToogleNobility(5)">5</div>
          </div>
          <div class="Nobility" @click="ToogleSpeeds">
            <!-- To Start Timer -->
            <svg
              v-if="NobilityType == 'stop'"
              fill="#000000"
              viewBox="0 0 24 24"
              width="30"
              height="50"
              id="thunder"
              data-name="Flat Color"
              xmlns="http://www.w3.org/2000/svg"
              class="icon flat-color"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  id="primary"
                  d="M18.82,9.18A2,2,0,0,0,17,8H15.19l1.33-3.26a2,2,0,0,0-.19-1.84A2.06,2.06,0,0,0,14.62,2H10.28A2,2,0,0,0,8.37,3.27l-3.23,8a2,2,0,0,0,.2,1.83,2.06,2.06,0,0,0,1.71.9H9.81L8,20.74a1,1,0,0,0,.5,1.15A1.12,1.12,0,0,0,9,22a1,1,0,0,0,.76-.35l8.8-10.37A2,2,0,0,0,18.82,9.18Z"
                  style="fill: #f7ef8a"
                ></path>
              </g>
            </svg>
            <!-- To Stop Timer -->
            <svg
              v-else
              viewBox="0 0 24 24"
              width="30"
              height="50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M8 5V19M16 5V19"
                  stroke="#f7ef8a"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <!-- Nobility Section -->
  </div>
</template>

<script>
import { Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import ProfileIcon from "@/components/HomeComponent/FilterPosts/iconsFilters/ProfileIcon.vue";
import ForYouIcon from "@/components/HomeComponent/FilterPosts/iconsFilters/ForYouIcon.vue";
import AllIcon from "@/components/HomeComponent/FilterPosts/iconsFilters/AllIcon.vue";
import FriendsPostsIcon from "@/components/HomeComponent/FilterPosts/iconsFilters/FriendsPostsIcon.vue";
import YoutubeIcon from "@/components/HomeComponent/FilterPosts/iconsFilters/YoutubeIcon.vue";
import LiveIcon from "@/components/HomeComponent/FilterPosts/iconsFilters/LiveIcon.vue";
import MapDirectionIcon from "@/components/HomeComponent/FilterPosts/iconsFilters/MapDirection.vue";
import FollowingPostsIcon from "@/components/HomeComponent/FilterPosts/iconsFilters/FollowingPostsIcon.vue";
import ChatAiIcon from "@/components/HomeComponent/FilterPosts/iconsFilters/ChatAiIcon.vue";
import GroupPostsIcon from "@/components/HomeComponent/FilterPosts/iconsFilters/GroupPostsIcon.vue";
import GroupPostComponent from "@/components/GroupsComponents/GroupPostComponent.vue";
// import GroupPost from "@/Elements/GroupPost/GroupPost.vue";
import StoryComponent from "@/components/HomeComponent/StoryComponent.vue";
import PostComponentV2 from "@/Elements/post/PostComponent.vue";
import SkeltonComponent from "@/components/HomeComponent/SkeltonComponent.vue";
import SkeltonAddPostComponent from "@/components/HomeComponent/SkeltonAddPostComponent.vue";
import AddpostComponent from "@/components/HomeComponent/AddpostComponent.vue";
// import AlertComponent from "@/components/HomeComponent/AlertComponents/ShowAlertComponent.vue";
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import AlertService from "@/services/Supports/AlertService";
import PageServices from "@/services/Pages/PageServices";
import PostServices from "@/services/HomePage/post.services";
import { useRoute, useRouter } from "vue-router";
import vClickOutside from "click-outside-vue3";
import ProfileService from "@/services/Profile/ProfileService";
import AlertBirthDayComponent from "./BirthDay/AlertBirthDayComponent.vue";
import SuggestedComponent from "./Suggested/SuggestedComponent.vue";
import QuestionsComponents from "./Questions/QuestionsComponents.vue";

export default {
  name: "CenterHomeComponent",

  components: {
    QuestionsComponents,
    Swiper,
    SwiperSlide,
    ChatAiIcon,
    FollowingPostsIcon,
    GroupPostsIcon,
    FriendsPostsIcon,
    YoutubeIcon,
    LiveIcon,
    ProfileIcon,
    ForYouIcon,
    AllIcon,
    StoryComponent,
    AddpostComponent,
    SkeltonComponent,
    SkeltonAddPostComponent,
    // AlertComponent,
    GroupPostComponent,
    // GroupPost,
    PostComponentV2,
    AlertBirthDayComponent,
    SuggestedComponent,
    MapDirectionIcon,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },

  data() {
    return {
      modules: [Scrollbar],
      // allOption: {
      //   id: "option-a",
      //   title: "Home",
      //   label: this.$t("foryou"),
      //   value: "all",
      //   order: 0,
      // },
      all_categories_order: {
        order: 8,
      },
      options: [
        {
          id: "option-foryou",
          title: "Home",
          label: this.$t("Media"),
          value: "forYou",
          icon: "AllIcon",
          onChange: this.filter_posts,
          order: 1,
        },
        // {
        //   id: "option-p",
        //   title: "Users Posts",
        //   label: this.$t("Users"),
        //   value: "profiles",
        //   icon: "ProfileIcon",
        //   onChange: this.filter_posts,
        //   order: 2,
        // },
        {
          id: "option-b",
          title: "Friends Posts",
          label: this.$t("Friends"),
          value: "friends",
          icon: "FriendsPostsIcon",
          onChange: this.filter_posts,
          order: 3,
        },

        // {
        //   id: "option-d",
        //   title: "Following",
        //   label: this.$t("Groups"),
        //   value: "groups",
        //   icon: "GroupPostsIcon",
        //   onChange: this.filter_posts,
        //   order: 5,
        // },
        // {
        //   id: "option-following",
        //   title: "Page Following",
        //   label: this.$t("Following"),
        //   value: "following",
        //   icon: "FollowingPostsIcon",
        //   onChange: this.filter_posts,
        //   order: 6,
        // },
        {
          id: "option-map",
          title: "Maps Directions",
          label: this.$t("Maps"),
          value: "map",
          icon: "MapDirectionIcon",
          onChange: this.redirect_maps_direction,
          order: 7,
        },
        {
          id: "option-chatAi",
          title: "Chat Ai",
          label: this.$t("Chat Ai"),
          value: "chatAi",
          icon: "ChatAiIcon",
          onChange: this.redirectToChatAi,
          order: 8,
        },
        {
          id: "option-y",
          title: "Youtube",
          label: this.$t("Youtube"),
          value: "y",
          icon: "YoutubeIcon",
          onChange: this.redirectToYoutube,
          order: 9,
        },
        {
          id: "option-l",
          title: "Live",
          label: this.$t("Live"),
          value: "l",
          icon: "LiveIcon",
          onChange: this.redirectToLives,
          order: 10,
        },
      ],
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
      NobilityType: "stop",
      intervalitemNobility: 0,
      ShowBoxesSpeed: false,
      isClickedOutside: false,
      scroll: 0,
      Alerts: [],
      BirthDates: [],
      category: "",
      check_home_filter: true,
      filters: {
        category: "forYou",
        page_category_id: null,
        all_except: ["profiles"],
        // all_except: [], //'profiles', 'groups'
      },
      all_categories: [],
      intervalitem: 0,
      PostsIdToSend: [],
      PostsIdsThatSendBefore: [],
      router: useRouter(),
      route: useRoute(),
      IdOfSelectedPostTocomment: null,
      randomnumber: Math.floor(Math.random() * 6) + 2,
    };
  },

  methods: {
    redirectToChatAi() {
      this.$router.push({ name: "chatAi" });
    },
    redirectToLives() {
      this.$router.push({ name: "allLive" });
    },
    redirectToYoutube() {
      // window.location.href = 'https://www.youtube.com/shorts';
      window.open("https://www.youtube.com/shorts", "_blank");
    },
    redirect_maps_direction() {
      this.$router.push({ name: "maps" });
    },
    showScrollbar() {
      const swiperContainer = this.$refs.swiperContainer.$el;
      swiperContainer.style.overflowX = "auto";
    },
    hideScrollbar() {
      const swiperContainer = this.$refs.swiperContainer.$el;
      swiperContainer.style.overflowX = "hidden";
    },
    ChangeIdOfSelectedPostTocomment(payload) {
      this.IdOfSelectedPostTocomment = payload;
    },

    onClickOutside() {
      if (this.NobilityType != "stop") {
        this.ShowBoxesSpeed = false;
        this.NobilityType = "stop";
        clearInterval(this.intervalitemNobility);
      } else {
        this.ShowBoxesSpeed = false;
      }
    },

    ToogleSpeeds() {
      if (this.NobilityType == "stop") {
        this.ShowBoxesSpeed = true;
      } else {
        this.NobilityType = "stop";
        clearInterval(this.intervalitemNobility);
      }
    },

    ToogleNobility(speed) {
      this.ShowBoxesSpeed = false;
      this.NobilityType = "start";
      if (speed == 1) {
        this.intervalitemNobility = setInterval(
          this.handleScrollForNobility1,
          100
        ); // 0.1 second
      }
      if (speed == 2) {
        this.intervalitemNobility = setInterval(
          this.handleScrollForNobility2,
          100
        ); // 0.1 second
      }
      if (speed == 3) {
        this.intervalitemNobility = setInterval(
          this.handleScrollForNobility3,
          100
        ); // 0.1 second
      }
      if (speed == 4) {
        this.intervalitemNobility = setInterval(
          this.handleScrollForNobility4,
          100
        ); // 0.1 second
      }
      if (speed == 5) {
        this.intervalitemNobility = setInterval(
          this.handleScrollForNobility5,
          100
        ); // 0.1 second
      }
    },

    handleScrollForNobility1() {
      this.scroll = window.scrollY + 20;
      window.scroll(0, this.scroll);
    },
    handleScrollForNobility2() {
      this.scroll = window.scrollY + 40;
      window.scroll(0, this.scroll);
    },
    handleScrollForNobility3() {
      this.scroll = window.scrollY + 60;
      window.scroll(0, this.scroll);
    },
    handleScrollForNobility4() {
      this.scroll = window.scrollY + 80;
      window.scroll(0, this.scroll);
    },
    handleScrollForNobility5() {
      this.scroll = window.scrollY + 100;
      window.scroll(0, this.scroll);
    },

    async selectfilterfrommodal() {
      // console.log(this.filters.category)
      // console.log(this.filters.all_except)
      this.get_all();
    },

    changefilterinmodal(e) {
      let val = e.target.value;
      if (this.filters.all_except.includes(val)) {
        let index = this.filters.all_except.indexOf(val);
        this.filters.all_except.splice(index, 1);
      } else if (this.filters.all_except.includes(parseInt(val))) {
        let index = this.filters.all_except.indexOf(parseInt(val));
        this.filters.all_except.splice(index, 1);
      } else {
        this.filters.all_except.push(val);
      }
    },

    checked(cat) {
      this.filters.page_category_id = cat;
    },
    // get all categories
    async get_all_categories() {
      await PageServices.s_get_all_categories_has_posts(1).then((res) => {
        this.all_categories = res.data.data;
        // console.log(res.data.data)
        // res.data.data.forEach(item => {
        //   this.filters.all_except.push(item.id)
        // })
      });
    },

    // async GetAlerts() {
    //   await AlertService.GetAlertsForUser().then((res) => {
    //     // console.log(res.data.data.items)
    //     res.data.data.items.forEach((item) => {
    //       if (item.pivot.status == 0) {
    //         this.Alerts.push(item);
    //       }
    //     });
    //   });
    // },

    async GetAllBirthDays() {
      await ProfileService.allBirthDays().then((res) => {
        this.BirthDates = res.data.FriendsBirthdays.items;
        // console.log(this.BirthDates)
      });
    },

    async makeRead() {
      await AlertService.UpdateAlertStatus().then((res) => {
        // console.log(res.data.errorCode)
        if (res.data.errorCode == 0) {
          this.Alerts = [];
        }
        //
      });
    },

    ...mapActions({
      GET_ALL_POSTS: "post/GET_ALL_POSTS",
      GET_NEXT: "post/GET_NEXT_USER",
      GET_My_Pages: "generalpages/GET_My_Pages",
      GET_NEW_NOTIFICATIONS: "notification/GET_NEW_NOTIFICATIONS",
      GET_ALL_Reports_On_Post_Or_Comment:
        "report/GET_ALL_Reports_On_Post_Or_Comment",
    }),

    ...mapMutations({
      UPDATE_IS_LOADING_ALL_POSTS: "post/UPDATE_IS_LOADING_ALL_POSTS",
    }),

    async get_all() {
      // this.router.push(`/home?category=all`);
      this.router.push(`/home`);
      this.$store.commit("post/Initial_PostDataAuth");
      this.UPDATE_IS_LOADING_ALL_POSTS();
      this.check_home_filter = true;
      this.filters = {
        category: "all",
        page_category_id: null,
        // all_except: ["profiles"],
        all_except: this.filters.all_except,
      };
      await this.GET_ALL_POSTS({
        category: this.filters.category,
        all_except: this.filters.all_except,
      });

      this.UPDATE_IS_LOADING_ALL_POSTS();
    },

    async filter_posts() {
      // console.log(this.filters.category)
      this.$store.commit("post/Initial_PostDataAuth");
      this.UPDATE_IS_LOADING_ALL_POSTS();
      this.check_home_filter = false;
      this.filters.page_category_id = null;
      this.filters.all_except = [];
      if (this.filters.category == null) {
        this.filters.category = "forYou";
        this.router.push(`/home`);
        await this.GET_ALL_POSTS({
          category: this.filters.category,
          // page_category_id: null,
        });
      } else {
        this.filters.category == "forYou"
          ? this.router.push(`/home`)
          : this.router.push(`/home?category=${this.filters.category}`);
        await this.GET_ALL_POSTS({
          category: this.filters.category,
          // page_category_id: null,
          // all_except: this.filters.all_except,
        });
      }
      this.UPDATE_IS_LOADING_ALL_POSTS();
    },

    async filter_posts_category() {
      this.router.push(
        `/home?filter_by_page_category=${this.filters.page_category_id}`
      );
      this.$store.commit("post/Initial_PostDataAuth");
      this.UPDATE_IS_LOADING_ALL_POSTS();
      this.filters.all_except = [];
      this.check_home_filter = false;
      this.filters.category = null;
      await this.GET_ALL_POSTS({
        category: null,
        page_category_id: this.filters.page_category_id,
      });

      this.UPDATE_IS_LOADING_ALL_POSTS();
    },

    async handleScrollForPostsView() {
      clearInterval(this.intervalitem); // Stop Timer
      let allHieghtInWindow = window.scrollY + window.innerHeight; // Get hight From Top To Last pixel In Window
      let arryofallposts = document.querySelectorAll(
        ".postsFormedianasviewinapi"
      ); // Get All Posts That Show In Widow Or Above
      arryofallposts.forEach((item) => {
        if (item.offsetTop < allHieghtInWindow) {
          // if this post card that in hight
          let id = parseInt(item.getAttribute("data-view-number")); // Get Post Id
          if (this.PostsIdsThatSendBefore.length == 0) {
            // First Time Execute
            if (!this.PostsIdToSend.includes(id)) {
              // push If array dont contain Post Id
              this.PostsIdToSend.push(id);
            }
          } else {
            // add views method execute more than one
            if (!this.PostsIdsThatSendBefore.includes(id)) {
              // post Id not exesit in priveious ids array that send before
              if (!this.PostsIdToSend.includes(id)) {
                // post id not exist in post array ids that will send
                this.PostsIdToSend.push(id); // push post id to array that will send
              }
            }
          }
        }
      }); // End For Each
      if (this.PostsIdToSend.length > 0) {
        // if array will send contains item => method will execute
        let data = {
          posts_ids: this.PostsIdToSend,
        };
        await PostServices.addviews(data).then(() => {
          this.PostsIdsThatSendBefore = this.PostsIdsThatSendBefore.concat(
            this.PostsIdToSend
          );
          this.PostsIdToSend = [];
        });
      }
      this.intervalitem = setInterval(this.handleScrollForPostsView, 10000); // Active Timer Again
    },

    handleScrollForPostsViewWhenUnMounted() {
      // Function When Un mounted Execute
      let allHieghtInWindow = window.scrollY + window.innerHeight; // Get hight From Top To Last pixel In Window
      let arryofallposts = document.querySelectorAll(
        ".postsFormedianasviewinapi"
      ); // Get All Posts That Show In Widow Or Above
      arryofallposts.forEach((item) => {
        if (item.offsetTop < allHieghtInWindow) {
          // if this post card that in hight
          let id = parseInt(item.getAttribute("data-view-number")); // Get Post Id
          if (this.PostsIdsThatSendBefore.length == 0) {
            // First Time Execute
            if (!this.PostsIdToSend.includes(id)) {
              // push If array dont contain Post Id
              this.PostsIdToSend.push(id);
            }
          } else {
            // add views method execute more than one
            if (!this.PostsIdsThatSendBefore.includes(id)) {
              // post Id not exesit in priveious ids array that send before
              if (!this.PostsIdToSend.includes(id)) {
                // post id not exist in post array ids that will send
                this.PostsIdToSend.push(id); // push post id to array that will send
              }
            }
          }
        }
      }); // End For Each
      if (this.PostsIdToSend.length > 0) {
        // if array will send contains item => method will execute
        let data = {
          posts_ids: this.PostsIdToSend,
        };
        PostServices.addviews(data);
      }
    },

    async handleScroll() {
      // For Stop Play Video If Scroll Down
      if (document.querySelector(".postsFormedianasviewinapi")) {
        let arryofallposts = document.querySelectorAll(
          ".postsFormedianasviewinapi"
        ); //Get All Posts In Screen
        arryofallposts.forEach((item) => {
          if (
            item.offsetTop + window.innerHeight / 2 <
            window.scrollY + window.innerHeight
          ) {
            // If Post In Screen Show
            let arryofallvideos = item.querySelectorAll("video"); // Get All Video In Posts
            if (arryofallvideos.length > 0) {
              arryofallvideos.forEach((video) => {
                if (video.offsetTop > 0) {
                  // If Video In Posts That In Screen
                  // console.log(video.offsetHeight)
                  // console.log(item.offsetTop)
                  // console.log(window.scrollY)
                  if (
                    window.scrollY >
                    video.offsetHeight + item.offsetTop - 100
                  ) {
                    // console.log('=============')
                    video.pause();
                  }
                }
              });
            }
          }
        });
      }
      if (
        window.scrollY + window.innerHeight >=
        document.body.scrollHeight - 100
      ) {
        window.removeEventListener("scroll", this.handleScroll);
        // console.log(document.getElementById("spinner"))
        document.getElementById("spinner")
          ? document.getElementById("spinner").classList.remove("d-none")
          : "";
        // console.log(this.filters)
        await this.$store.dispatch("post/GET_NEXT_USER", this.filters);

        document.getElementById("spinner")
          ? document.getElementById("spinner").classList.add("d-none")
          : "";
        window.addEventListener("scroll", this.handleScroll);
      }
    },

    async StartOrRefreshHome() {
      if (this.route.query.filter_by_page_category) {
        this.$store.commit("post/Initial_PostDataAuth");
        this.UPDATE_IS_LOADING_ALL_POSTS();
        this.filters.all_except = [];
        this.check_home_filter = false;
        this.filters.category = null;
        this.filters.page_category_id =
          this.route.query.filter_by_page_category;
        await this.GET_ALL_POSTS({
          category: null,
          page_category_id: this.route.query.filter_by_page_category,
        });
        this.UPDATE_IS_LOADING_ALL_POSTS();
      } else {
        if (this.route.query.category) {
          if (this.route.query.category == "all") {
            this.$store.commit("post/Initial_PostDataAuth");
            this.UPDATE_IS_LOADING_ALL_POSTS();
            this.check_home_filter = true;
            this.filters = {
              category: "all",
              page_category_id: null,
              all_except: ["profiles"],
            };
            await this.GET_ALL_POSTS({
              category: this.filters.category,
              all_except: this.filters.all_except,
            });
            this.UPDATE_IS_LOADING_ALL_POSTS();
          } else {
            this.check_home_filter = false;
            this.filters.category = this.route.query.category;
            this.$store.commit("post/Initial_PostDataAuth");
            this.UPDATE_IS_LOADING_ALL_POSTS();
            this.check_home_filter = false;
            this.filters.page_category_id = null;
            this.filters.all_except = [];
            await this.GET_ALL_POSTS({
              category: this.route.query.category,
              page_category_id: null,
            });
            this.UPDATE_IS_LOADING_ALL_POSTS();
          }
        } else {
          // this.check_home_filter = true;
          // this.filters.category = "forYou";
          // this.filter_posts();

          this.check_home_filter = true;
          this.filters = {
            category: "forYou",
            // page_category_id: null,
            // all_except: ["profiles"],
          };
          await this.GET_ALL_POSTS({
            category: this.filters.category,
            all_except: this.filters.all_except,
          });
        }
      }
    },
  },

  async created() {
    window.scrollTo(0, 0);
    await this.get_all_categories();
    // this.filter_posts();
    await this.StartOrRefreshHome();
    await this.GET_My_Pages();
    // this.GET_NEW_NOTIFICATIONS();
    // this.GET_ALL_Reports_On_Post_Or_Comment();
    // this.$store.dispatch("collections/a_Get_UserCollections");
    // console.log("created")

    // await this.GetAlerts();
    await this.GetAllBirthDays();
  },

  computed: {
    ...mapState("post", {
      // postData: "postDataAuth",
      isLoadingNewPost: "isLoadingNewPost",
      isLoadingallPosts: "isLoadingallPosts",
      finishedPost: "finishedPost",
    }),
    ...mapGetters({
      postData: "post/postDataAuth",
    }),
    orderedOptions() {
      return [...this.options].sort((a, b) => a.order - b.order);
    },
  },

  mounted() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
    this.intervalitem = setInterval(this.handleScrollForPostsView, 10000); // 10 second
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    // if go to another route before add views method execute
    this.handleScrollForPostsViewWhenUnMounted();
    clearInterval(this.intervalitem);
    clearInterval(this.intervalitemNobility);
  },
};
</script>

<style lang="scss" scoped>
.NobilityContainer {
  position: fixed;
  bottom: 1rem;
  right: 3rem;
  z-index: 9999;
}

.Nobility {
  cursor: pointer;
  background-color: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.speeds {
  z-index: 999999999;
  position: absolute;
  top: -140px;
  left: 45%;
  transform: translateX(-50%);
}
.boxspeed {
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: black;
  background-color: #f7ef8a;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.2rem 0 0 0;
}

@media screen and (max-width: 992px) {
  .center {
    /* width: 100%; */
    /* padding-left: 1.5rem !important;  */
    /* padding-right: 0 !important;  */
  }
}

.partner-section-slider {
  height: 300px;
}
.partner-section-slider .swiper {
  width: 100%;
  height: 100%;
}

.partner-section-slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.partner-section-slider .swiper-slide-img-container {
  width: 80%;
  height: 80%;
}
.partner-section-slider .swiper-horizontal > .swiper-pagination-bullets,
.partner-section-slider .swiper-pagination-bullets.swiper-pagination-horizontal,
.partner-section-slider .swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}
.partner-section-slider .swiper-slide {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.partner-section-slider .swiper-slide-img-container {
  width: 80%;
  height: 80%;
}
.partner-section-slider .swiper-horizontal > .swiper-pagination-bullets,
.partner-section-slider .swiper-pagination-bullets.swiper-pagination-horizontal,
.partner-section-slider .swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0;
}

.partner-section-slider .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.center_loading {
  width: 100%;
}

$color: #a4a5a7;

@mixin typography {
  font-family: "PT Sans", sans-serif;
  letter-spacing: 1.1px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #777777;
  font-weight: 600;
}

@mixin centered-layout {
  // position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: 10px;
  margin-bottom: 0px;
}

.pill-container {
  & input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
  @include centered-layout;
  display: flex;
  flex-direction: row;
  // width: 100%;
  height: 3rem;
  box-sizing: border-box;
  .option-a {
    border-radius: 50%;
    width: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  .option-b {
    width: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  .option-c {
    width: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  .option-d {
    width: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  .option-e {
    width: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }

  .selector:last-child {
    border-right: 1.7px solid #983baf;
    border-radius: 50%;
  }
}

.btn-special {
  background-color: #983baf;
  color: white;
  padding: 0.5rem 4rem;
  border-color: 1px solid #983baf;
}

.selector {
  @include typography;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  cursor: pointer;
  background: lighten(#ffffff, 30%);
  border: 1.7px solid #983baf;
  border-radius: 50%;
  &:hover {
    background: lighten($color, 20%);
    color: #000000;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
  }
}

.filterinmodal {
  background: none !important;
  color: black !important;
  box-shadow: none !important;
  margin: 0 1rem;
}

input[type="checkbox"]:checked + label {
  background: #983baf;
  color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  #home-icon {
    stroke: #ffffff;
  }
  #icon_filter {
    stroke: #ffffff;
    fill: #ffffff;
  }
}
input[type="radio"]:checked + label {
  background: #983baf;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  #home-icon {
    stroke: #ffffff;
  }
  #icon_filter {
    stroke: #ffffff;
    fill: #ffffff;
  }
}
.checkbox-container {
  & input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  @include centered-layout;
  // display: flex;
  flex-direction: row;
  // width: 100%;
  box-sizing: border-box;

  .selector:last-child {
    border-right: 1px solid #983baf;

    padding: 2px;
    margin-right: 3px;
  }
}
.wrapper {
  // padding: 5px;
  background: $background-gradiant;
  // display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  border-radius: 4px;
  .selector-following {
    width: 45px;
    @include typography;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    cursor: pointer;
    background: lighten(#ffffff, 30%);
    border: 1px solid #983baf;

    font-size: 13px;
    margin-right: 6px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
      rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    &:hover {
      background: lighten($color, 20%);
      color: #000000;
    }
  }
}

//
.scroll-container {
  overflow-x: auto;
  word-wrap: normal;
  // white-space: nowrap;
}

.checkbox-container {
  & input[type="checkbox"] {
    opacity: 0;
    position: fixed;
    width: 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  @include centered-layout;
  // display: flex;
  flex-direction: row;
  // width: 100%;
  box-sizing: border-box;

  .selector:last-child {
    border-right: 1px solid #983baf;
    width: 48px;
    height: 45px;
    padding: 2px;
    margin-right: 3px;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

label.selector-following {
  border-radius: 50%;
}
.name-filter {
  font-size: 10px;
  cursor: pointer;
  font-family: sans-serif;
}
.row.bg-white.me-0.mb-3 {
  background-color: #ffffff;
  padding: 9px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 3px;
}
</style>
