<template>
  <div class="answer-card">
    <div class="answer-user-info">
      <div class="user-info-left">
        <OwnerPhotoQuestion :question="answer" />
        <div>
          <h4>{{ answer.model.name }}</h4>
          <small class="date" dir="auto">{{
            formatRelativeDate(answer.created_at)
          }}</small>
        </div>
      </div>

      <div class="options">
        <!-- <star-rating
          :max-stars="5"
          :initial-rating="answer.rate ? answer.rate : 0"
          @rating-selected="updateRating(ansIndex, $event, answer.id)"
          :reviewedStars="answer.reviewed"
        ></star-rating> -->

        <div class="star-rating">
          <span
            v-for="star in 5"
            :key="star"
            @click="selectRating(ansIndex, star, answer.id)"
            :class="{
              filled: star <= this.currentRating,
              reviewed: star === answer.reviewed,
            }"
          >
            <!-- :style="{ borderColor: star === reviewedStars ? 'red' : 'transparent' }" -->
            ★
            <span
              v-if="star === answer.reviewed && answer.reviewed"
              class="dot"
            ></span>
          </span>
        </div>
      </div>
      <div class="arrow-icon1" @click="toggleDropdownAnswer(ansIndex)">
        <ArrowIcon />
        <ul
          v-if="dropdownOpenAnswer === ansIndex"
          class="custom-dropdown-answer"
        >
          <li
            v-if="canDeleteAnswer(answer)"
            @click="showEditAnswerModal(ansIndex, answer.body)"
          >
            {{ $t("Update Answer") }}
          </li>
          <li
            v-if="canDeleteAnswer(answer)"
            @click="removeAnswer(answer.id, ansIndex)"
          >
            {{ $t("Delete answer") }}
          </li>
          <li
            v-if="answer.reviewed != null"
            @click="cancelRating(answer.id, ansIndex)"
          >
            {{ $t("cancel rating") }}
          </li>
        </ul>
        <div v-if="editingAnswerIndex !== null" class="backdrop"></div>
        <div v-if="editingAnswerIndex === ansIndex" class="edit-answer-modal">
          <div class="modal-content">
            <textarea
              v-model="editingAnswerContent"
              class="edit-answer-textarea"
            ></textarea>
            <div class="modal-buttons">
              <button
                @click="
                  saveEditedAnswer(
                    answer.id,
                    this.ansIndex,
                    editingAnswerContent
                  )
                "
                class="modal-save"
              >
                {{ $t("Save Changes") }}
              </button>
              <button @click="cancelEditAnswer" class="modal-cancel">
                {{ $t("Cancel") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p style="overflow-wrap: anywhere" dir="auto">
      {{ answer.body }}
    </p>
  </div>
</template>

<script>
import ArrowIcon from "@/components/questions/icons/ArrowIcon.vue";
import OwnerPhotoQuestion from "@/components/questions/OwnerPhotoQuestion.vue";
import { mapGetters } from "vuex";
export default {
  props: ["answer", "ansIndex"],
  data() {
    return {
      dropdownOpenAnswer: null,
      editingAnswerIndex: null,
      editingAnswerContent: "",

      currentRating: this.answer.rate,
    };
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
    }),
  },
  components: {
    ArrowIcon,
    OwnerPhotoQuestion,
  },
  watch: {
    'answer.rate': function (newRate) {
      this.currentRating = newRate;
    },
  },
  methods: {
    saveEditedAnswer(answer_id, index, body) {
      this.$emit("update-answer", {
        answer_id: answer_id,
        index: index,
        body: body,
      });
      this.editingAnswerIndex = null;
    },
    cancelEditAnswer() {
      this.editingAnswerIndex = null;
      this.editingAnswerContent = "";
    },
    showEditAnswerModal(index, content) {
      this.editingAnswerIndex = index;
      this.editingAnswerContent = content;
    },
    canDeleteAnswer(answer) {
      return answer.model.has_role || answer.model.id == this.USER.profile.id;
    },
    removeAnswer(answer_id, index) {
      this.$emit("remove-answer", { answer_id: answer_id, index: index });
    },
    cancelRating(answer_id, index){
      this.$emit("remove-rate", { answer_id: answer_id, index: index , rate:0 , reviewed:null });
    },
    toggleDropdownAnswer(index) {
      if (this.dropdownOpenAnswer === index) {
        this.dropdownOpenAnswer = null;
      } else {
        this.dropdownOpenAnswer = index;
      }
    },
    selectRating(answerIndex, rating, answer_id) {
      this.currentRating = rating;
      this.$emit("updateRating", {
        answerIndex: answerIndex,
        rating: rating,
        answer_id: answer_id,
      });
    },
    formatRelativeDate(dateString) {
      const date = new Date(dateString);
      const now = new Date();

      const diff = now - date;
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (seconds < 60) {
        return this.$i18n.t("Moments ago");
      } else if (minutes < 60) {
        return `${this.$i18n.t("since")} ${minutes} ${
          minutes > 1 ? this.$i18n.t("minutes") : this.$i18n.t("minute")
        }`;
      } else if (hours < 24) {
        return `${this.$i18n.t("since")} ${hours} ${
          hours > 1 ? this.$i18n.t("hours") : this.$i18n.t("hour")
        }`;
      } else if (days < 2) {
        return this.$i18n.t("yesterday");
      } else if (days < 7) {
        return `${this.$i18n.t("since")} ${days} ${
          days > 1 ? this.$i18n.t("days") : this.$i18n.t("day")
        }`;
      } else {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        return date.toLocaleDateString("ar", options);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.date {
  font-size: 12px;
  color: #888;
}

.answer-card {
  border: 1px solid #eee;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
}
.answer {
  font-size: 16px;
  font-weight: bold;
  color: #007bff;
  cursor: pointer;
  text-align: right;
}
.answer-card {
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: start;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  margin-bottom: 10px;
}

.answer-user-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.user-info-left {
  display: flex;
  align-items: center;
}

.answer-user-info img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.answer-user-info h4 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.answer-user-info .date {
  font-size: 12px;
  color: #888;
}

.answer-user-info p {
  margin: 0;
  font-size: 14px;
}

.options {
  display: flex;
  align-items: center;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: #007bff;
  margin-left: 5px;
}

select:focus {
  outline: none;
}

option[disabled] {
  color: #ccc;
}

.new-answer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.arrow-icon1 {
  //   position: absolute;
  top: 10px;
  right: 10px;
}

.custom-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: none;
  min-width: 150px;
  white-space: nowrap;
}

.custom-dropdown li {
  padding: 8px 16px;
  cursor: pointer;
  list-style-type: none;
}

.custom-dropdown li:hover {
  background-color: #f0f0f0;
}

.card:hover .custom-dropdown {
  display: block;
}
.custom-dropdown-answer {
  position: absolute;
  //   top: 40px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: none;
  min-width: 150px;
  white-space: nowrap;
}

.custom-dropdown-answer li {
  padding: 8px 16px;
  cursor: pointer;
  list-style-type: none;
}

.custom-dropdown-answer li:hover {
  background-color: #f0f0f0;
}

.card:hover .custom-dropdown-answer {
  display: block;
}

.edit-answer-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
  width: 80%;
  max-width: 500px;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.edit-answer-textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.modal-buttons button {
  margin-left: 10px;
  padding: 8px 16px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.modal-save {
  background-color: #742383;
  color: white;
}

.modal-cancel {
  background-color: #8d8282;
  color: white;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

@media (max-width: 600px) {
  .edit-answer-modal {
    width: 95%;
    padding: 10px;
  }

  .modal-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .modal-buttons button {
    margin: 5px 0;
    width: 100%;
  }
}
// raring
.star-rating {
  font-size: 24px;
}

.star-rating span {
  color: #ccc;
  cursor: pointer;
  position: relative;
  border: 2px solid transparent;
  border-radius: 30%;
}

.star-rating span.filled {
  color: #ffc107;
}

.star-rating span.reviewed {
  position: relative;
}

.star-rating span.reviewed .dot {
  position: absolute;
  top: 2px;
  left: 33%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  background-color: red;
  border-radius: 50%;
}
</style>
