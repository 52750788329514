<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 36"
    width="20"
    height="20"
    fill="none"
  >
    <circle cx="18" cy="18" r="18" fill="#D3D3D3" />
    <circle cx="12" cy="14" r="2" fill="#000" />
    <circle cx="24" cy="14" r="2" fill="#000" />
    <path
      d="M18 28c-4.418 0-8-3.582-8-8h2c0 3.308 2.692 6 6 6s6-2.692 6-6h2c0 4.418-3.582 8-8 8z"
      fill="#000"
    />
  </svg>
</template>
