<template>
  <div class="vcomments__btns">
    <div class="card-footer-comment">
      <div class="vcomments__bleft">
        <div class="d-flex justify-content-between align-items-center body_box">
          <small v-if="!comment.interacted" class="addreaction">
            <span @click="addReactionToPost(comment.id, 7)">{{ $t("Like") }}</span>
            <div class="box-reaction-items">
              <span @click="addReactionToPost(comment.id, 7)">
                <img crossorigin="anonymous" 
                  class="img-reactions"
                  src="@/assets/reactions/like.gif"
                  alt="Like emoji"
                />
              </span>
              <span @click="addReactionToPost(comment.id, 1)">
                <img crossorigin="anonymous" 
                  class="img-reactions"
                  src="@/assets/reactions/love.gif"
                  alt="Love emoji"
                />
              </span>
              <span @click="addReactionToPost(comment.id, 6)">
                <img crossorigin="anonymous" 
                  class="img-reactions"
                  src="@/assets/reactions/angry.gif"
                  alt="Haha emoji"
                />
              </span>
              <span @click="addReactionToPost(comment.id, 2)">
                <img crossorigin="anonymous" 
                  class="care-img img-reactions"
                  src="@/assets/reactions/care.gif"
                  alt="Care emoji"
                />
              </span>
              <span @click="addReactionToPost(comment.id, 3)">
                <img crossorigin="anonymous" 
                  class="img-reactions"
                  src="@/assets/reactions/haha.gif"
                  alt="Wow emoji"
                />
              </span>
              <span @click="addReactionToPost(comment.id, 4)">
                <img crossorigin="anonymous" 
                  class="img-reactions"
                  src="@/assets/reactions/wow.gif"
                  alt="Angry emoji"
                />
              </span>
              <span @click="addReactionToPost(comment.id, 5)">
                <img crossorigin="anonymous" 
                  class="img-reactions"
                  src="@/assets/reactions/sad.gif"
                  alt="Sad emoji"
                />
              </span>
            </div>
          </small>
          <small
            v-else
            class="addreaction"
            :class="{
              like: comment.interacted === 7,
              love: comment.interacted === 1,
              angry: comment.interacted === 6,
              HaHaHa: comment.interacted === 3,
              Sad: comment.interacted === 5,
              Care: comment.interacted === 2,
              WOW: comment.interacted === 4,
            }"
          >
            <span
              v-if="comment.interacted === 1"
              @click="addReactionToPost(comment.id, null)"
              >{{ $t("Love") }}</span
            >
            <span
              v-else-if="comment.interacted === 6"
              @click="addReactionToPost(comment.id, null)"
              >{{ $t("Angry") }}</span
            >
            <span
              v-else-if="comment.interacted === 3"
              @click="addReactionToPost(comment.id, null)"
              >{{ $t("HaHaHa") }}</span
            >
            <span
              v-else-if="comment.interacted === 5"
              @click="addReactionToPost(comment.id, null)"
              >{{ $t("Sad") }}</span
            >
            <span
              v-else-if="comment.interacted === 2"
              @click="addReactionToPost(comment.id, null)"
              >{{ $t("Care") }}</span
            >
            <span
              v-else-if="comment.interacted === 4"
              @click="addReactionToPost(comment.id, null)"
              >{{ $t("Wow") }}</span
            >
            <span v-else @click="addReactionToPost(comment.id, null)">{{
              $t("Like")
            }}</span>
            <div class="box-reaction-items">
              <span @click="addReactionToPost(comment.id, 7)">
                <img crossorigin="anonymous" 
                  class="img-reactions"
                  src="@/assets/reactions/like.gif"
                  alt="Like emoji"
                />
              </span>
              <span @click="addReactionToPost(comment.id, 1)">
                <img crossorigin="anonymous" 
                  class="img-reactions"
                  src="@/assets/reactions/love.gif"
                  alt="Love emoji"
                />
              </span>
              <span @click="addReactionToPost(comment.id, 6)">
                <img crossorigin="anonymous" 
                  class="img-reactions"
                  src="@/assets/reactions/angry.gif"
                  alt="Haha emoji"
                />
              </span>
              <span @click="addReactionToPost(comment.id, 2)">
                <img crossorigin="anonymous" 
                  class="care-img img-reactions"
                  src="@/assets/reactions/care.gif"
                  alt="Care emoji"
                />
              </span>
              <span @click="addReactionToPost(comment.id, 3)">
                <img crossorigin="anonymous" 
                  class="img-reactions"
                  src="@/assets/reactions/haha.gif"
                  alt="Wow emoji"
                />
              </span>
              <span @click="addReactionToPost(comment.id, 4)">
                <img crossorigin="anonymous" 
                  class="img-reactions"
                  src="@/assets/reactions/wow.gif"
                  alt="Angry emoji"
                />
              </span>
              <span @click="addReactionToPost(comment.id, 5)">
                <img crossorigin="anonymous" 
                  class="img-reactions"
                  src="@/assets/reactions/sad.gif"
                  alt="Sad emoji"
                />
              </span>
            </div>
            &nbsp;
            <span class="image-reaction">
              <svg
                v-if="comment.interacted.length === 0"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
              >
                <path fill="#767676" d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Z" />
                <path
                  fill="#fff"
                  d="M12.162 7.338c.176.123.338.245.338.674 0 .43-.229.604-.474.725.1.163.132.36.089.546-.077.344-.392.611-.672.69.121.194.159.385.015.62-.185.295-.346.407-1.058.407H7.5c-.988 0-1.5-.546-1.5-1V7.665c0-1.23 1.467-2.275 1.467-3.13L7.361 3.47c-.005-.065.008-.224.058-.27.08-.079.301-.2.635-.2.218 0 .363.041.534.123.581.277.732.978.732 1.542 0 .271-.414 1.083-.47 1.364 0 0 .867-.192 1.879-.199 1.061-.006 1.749.19 1.749.842 0 .261-.219.523-.316.666ZM3.6 7h.8a.6.6 0 0 1 .6.6v3.8a.6.6 0 0 1-.6.6h-.8a.6.6 0 0 1-.6-.6V7.6a.6.6 0 0 1 .6-.6Z"
                />
                <defs>
                  <linearGradient
                    id="a"
                    x1="8"
                    x2="8"
                    y2="16"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#18AFFF" />
                    <stop offset="1" stop-color="#0062DF" />
                  </linearGradient>
                </defs>
              </svg>
              <LikeSvg v-else-if="comment.interacted === 7"></LikeSvg>
              <LoveSvg v-else-if="comment.interacted === 1"></LoveSvg>
              <AngrySvg v-else-if="comment.interacted === 6"></AngrySvg>
              <CareSvg v-else-if="comment.interacted === 2"></CareSvg>
              <HahaSvg v-else-if="comment.interacted === 3"></HahaSvg>
              <SadSvg v-else-if="comment.interacted === 5"></SadSvg>
              <WowSvg v-else-if="comment.interacted === 4"></WowSvg>
            </span>
          </small>
          <div
            class="d-flex justify-content-center align-items-center"
            role="button"
            @click="showInteractionCommentModal"
          >
            <span class="ms-2">{{ comment.Interactions }}</span>
          </div>
          <InteractionReplyComment
            v-if="interactionModel && interactionComment_model"
            v-on:change="change()"
            :id="comment.id"
          ></InteractionReplyComment>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CommentPostService from "@/services/Comment/CommentPostService";
import { mapActions, mapMutations, mapState } from "vuex";
import InteractionReplyComment from "./InteractionReplyComment.vue";
import LikeSvg from "@/components/OtherComponent/SVG/Reactions/LikeSvg.vue";
import LoveSvg from "@/icons/reactions/LoveSvg.vue";
import AngrySvg from "@/icons/reactions/AngrySvg.vue";
import CareSvg from "@/icons/reactions/CareSvg.vue";
import HahaSvg from "@/icons/reactions/HahaSvg.vue";
import SadSvg from "@/icons/reactions/SadSvg.vue";
import WowSvg from "@/icons/reactions/WowSvg.vue";
export default {
  props: ["comment"],
  data() {
    return {
      interactionComment_model: null,
    };
  },
  methods: {
    change() {
      this.interactionComment_model = null;
    },
    ...mapMutations({
      UPDATE_IS_MODAL_INTERACTION: "comment/UPDATE_IS_MODAL_INTERACTION",
    }),
    ...mapActions({
      UPDATE_REPLY_TO_LIKE: "comment/UPDATE_REPLY_TO_LIKE",
    }),
    async addReactionToPost(id, data) {
      let res = "";
      try {
        res = await CommentPostService.InteractionWithComment(id, data);
        this.$emit('addreaction', res.data.data)
      } catch (err) {
        // console.log(err.response.data);
      }
    },
    showInteractionCommentModal() {
      this.UPDATE_IS_MODAL_INTERACTION();
      this.interactionComment_model = true;
      if (document.documentElement.style.overflow === "hidden") {
        document.documentElement.style.overflow = "auto";
      } else {
        document.documentElement.style.overflow = "hidden";
      }
    },
  },
  computed: {
    ...mapState("comment", {
      interactionModel: "interactionModel",
    }),
  },
  components: {
    InteractionReplyComment,
    LikeSvg,
    LoveSvg,
    AngrySvg,
    CareSvg,
    HahaSvg,
    SadSvg,
    WowSvg,
  },
};
</script>
<style lang="scss" scoped>
@import "@/Styles/Comments/reply/addReactionToReplies.scss";
</style>
