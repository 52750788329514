<template>
  <div
  v-if="this.isOpen"
    class="modal fade"
    id="addCreationType"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div    class="modal-dialog modal-dialog-centered ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="w-100" id="staticBackdropLabel">
            {{ $t("what are you thinking about?") }}
          </h5>
          <div class="close-dir">
            <span
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></span>
          </div>
        </div>
        <div class="modal-body">
          <div class="row m-2">
            <div
              class="col-2 col-sm-3 mx-0 mb-2"
              v-for="item in creationTypes"
              :key="item.type"
            >
              <div class="card" :class="item.cardClass">
                <div
                  class="card-body justify-content-center"
                  data-bs-toggle="modal"
                  :data-bs-target="item.modalTarget"
                  @click="item.onClick"
                >
                  <div class="bo">
                    <component :is="item.iconComponent"></component>
                  </div>
                  <p class="text-center title-door">{{ $t(item.title) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!------------------------------ Start Modal Post ---------------------->
  <AddNewPost  v-if="this.isOpen" ></AddNewPost>
  <!------------------------------ End Modal Post ------------------------>

  <!------------------------------ Start Modal T-Top ---------------------->
  <AddNewTtop v-if="this.isOpen" ></AddNewTtop>
  <!------------------------------ End Modal T-Top ------------------------>

  <!------------------------------ Start Modal Quote ---------------------->
  <AddNewQuote v-if="this.isOpen" ></AddNewQuote>
  <!----------------------------- End Modal Quote ----------------------->

  <!------------------------------ Start Modal Challenge ---------------------->
  <AddNewChallenge v-if="this.isOpen" ></AddNewChallenge>
  <!----------------------------- End Modal Challenge ----------------------->

  <!------------------------------ Start Modal Poll ---------------------->
  <AddNewPoll v-if="this.isOpen" ></AddNewPoll>
  <!----------------------------- End Modal Poll ----------------------->
  
  <!----------------------------- Start Modal Question ---------------------->
  <AddNewQuestion v-if="this.isOpen" ></AddNewQuestion>
  <!----------------------------- End Modal Question ----------------------->
  
</template>

<script>
import AddNewQuestion from "@/Elements/post/addPostForm/allTypes/question/AddNewQuestion.vue";
import AddNewPoll from "@/Elements/post/addPostForm/allTypes/poll/AddNewPoll.vue";
import AddNewPost from "@/Elements/post/addPostForm/allTypes/post/AddNewPost.vue";
import AddNewQuote from "@/Elements/post/addPostForm/allTypes/quotes/AddNewQuote.vue";
import AddNewTtop from "@/Elements/post/addPostForm/allTypes/Ttop/AddNewTtop.vue";
import AddNewChallenge from "@/Elements/post/addPostForm/allTypes/challenge/AddNewChallenge.vue";
import QuoteIcon from "@/components/OtherComponent/SVG/QuoteIcon.vue";
import PostIcon from "@/icons/post/PostIcon.vue";
import ReelsSvg from "@/components/OtherComponent/SVG/ReelsSvg.vue";
import ChallengeSvg from "@/components/OtherComponent/SVG/ChallengeSvg.vue";
import PollSvg from "@/components/OtherComponent/SVG/PollSvg.vue";
export default {
  props:["isOpen"],
  data() {
    return {
      type: null,
      creationTypes: [
        {
          type: "post",
          cardClass: "cardPost",
          modalTarget: "#addpersonalpostInHomePage",
          onClick: () => {},
          iconComponent: "PostIcon",
          title: "POST",
        },
        {
          type: "quote",
          cardClass: "cardQuote",
          modalTarget: "#addNewQuotePage",
          onClick: this.changeTypePost.bind(this, 6),
          iconComponent: "QuoteIcon",
          title: "Quote",
        },
        {
          type: "t-top",
          cardClass: "cardttop",
          modalTarget: "#addNewTTop",
          onClick: () => {},
          iconComponent: "ReelsSvg",
          title: "T-Top",
        },
        {
          type: "challenge",
          cardClass: "cardchallenge",
          modalTarget: "#addNewChallenge",
          onClick: () => {},
          iconComponent: "ChallengeSvg",
          title: "Challenge",
        },
        {
          type: "Poll",
          cardClass: "cardPoll",
          modalTarget: "#addNewPoll",
          onClick: () => {},
          iconComponent: "PollSvg",
          title: "Poll",
        },
        {
          type: "Question",
          cardClass: "cardQuestion",
          modalTarget: "#addNewQuestion",
          onClick: () => {},
          iconComponent: "PollSvg",
          title: "Question",
        },
      ],
    };
  },
  methods: {
    changeTypePost(type) {
      this.type = type;
    },
  },
  components: {
    AddNewQuestion,
    AddNewPoll,
    AddNewPost,
    AddNewQuote,
    AddNewTtop,
    AddNewChallenge,
    QuoteIcon,
    PostIcon,
    ReelsSvg,
    ChallengeSvg,
    PollSvg,
  },
};
</script>

<style lang="scss" scoped>
.cardPost {
  background: linear-gradient(
    to right top,
    #5a86d8,
    #519fff,
    #4489dd,
    #4489dd,
    #4489dd,
    #3780d8,
    #3780d8,
    #3780d8,
    #3780d8,
    #2e74c9,
    #2e74c9,
    #2e74c9
  );
  height: 100%;

  .card-body {
    cursor: pointer;
    .bo {
      position: relative;
      border-radius: 100%;
      background: white;
      margin-top: 50%;
      padding: 30%;
      display: flex;
      justify-content: center;

      svg {
        font-size: x-large !important;
      }
    }
  }
}
.close-dir:lang(ar) {
  direction: ltr;
}
.close-dir:lang(ku) {
  direction: ltr;
}
.card.cardQuote {
  background: radial-gradient(
    circle,
    rgba(146, 47, 182, 1) 0%,
    rgba(126, 42, 167, 1) 35%,
    rgba(192, 0, 255, 1) 100%
  );
  height: 100%;

  .card-body {
    cursor: pointer;
    .bo {
      position: relative;
      border-radius: 100%;
      background: white;
      margin-top: 50%;
      padding: 30%;
      display: flex;
      justify-content: center;

      svg {
        font-size: x-large !important;
      }
    }
  }
}
.card.cardttop {
  background-image: linear-gradient(
    to right top,
    #fe9f4b,
    #ff9851,
    #ff9157,
    #fe8a5e,
    #fc8464,
    #fd7c69,
    #fc746f,
    #fb6d75,
    #fb627e,
    #f95788,
    #f54e93,
    #ee47a0
  );
  height: 100%;

  .card-body {
    cursor: pointer;
    .bo {
      position: relative;
      border-radius: 100%;
      background: white;
      margin-top: 50%;
      padding: 30%;
      display: flex;
      justify-content: center;

      svg {
        font-size: x-large !important;
      }
    }
  }
}
.card.cardchallenge {
  background-image: linear-gradient(
    to right top,
    #69fe4b,
    #69f34d,
    #74f75a,
    #6ee956,
    #67e64e,
    #62e448,
    #63da4c,
    #6fdf59,
    #76dd62,
    #69d753,
    #57ce40,
    #4cc734
  );
  height: 100%;

  .card-body {
    cursor: pointer;
    .bo {
      position: relative;
      border-radius: 100%;
      background: white;
      margin-top: 50%;
      padding: 30%;
      display: flex;
      justify-content: center;

      svg {
        font-size: x-large !important;
      }
    }
  }
}
.card.cardPoll {
  background-image: linear-gradient(
    to right top,
    #758373,
    #b9bbb9,
    #b9bbb9,
    #b9bbb9,
    #b9bbb9,
    #b9bbb9,
    #b9bbb9,
    #b9bbb9,
    #bec0be,
    #b9bbb9,
    #b9bbb9,
    #b9bbb9
  );
  height: 100%;

  .card-body {
    cursor: pointer;
    .bo {
      position: relative;
      border-radius: 100%;
      background: white;
      margin-top: 50%;
      padding: 30%;
      display: flex;
      justify-content: center;

      svg {
        font-size: x-large !important;
      }
    }
  }
}
.card.cardQuestion {
  background: rgb(238, 174, 202);
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(152, 117, 133, 1) 40%,
    rgba(46, 47, 48, 1) 100%
  );
  height: 100%;

  .card-body {
    cursor: pointer;
    .bo {
      position: relative;
      border-radius: 100%;
      background: white;
      margin-top: 50%;
      padding: 30%;
      display: flex;
      justify-content: center;

      svg {
        font-size: x-large !important;
      }
    }
  }
}
.title-door {
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.row {
  .modal {
    background-color: rgba(255, 255, 255, 0.527);
    .modal-content {
      border-radius: 10px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

      .modal-body {
        label {
          cursor: pointer;
          font-weight: bold;
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>
