<template>
    <div>
        <p class="hover m-0">
            <span v-if="interacted == 7">
                <span class="fw-bold" >{{ $t("Like") }}</span>
                <img crossorigin="anonymous" 
                    class=""
                    src="@/assets/reactions/like.gif"
                    alt="Like emoji"
                    height="22"
                />
            </span>
            <span v-else-if="interacted == 6" dir="auto">
                <span class="fw-bold" >{{ $t("Angry") }}</span>
                <img crossorigin="anonymous" 
                    class=""
                    src="@/assets/reactions/angry.gif"
                    alt="Like emoji"
                    height="22"
                />
            </span>
            <span v-else-if="interacted == 2" dir="auto">
                <span class="fw-bold" >{{ $t("Care") }}</span>
                <img crossorigin="anonymous" 
                    class=""
                    src="@/assets/reactions/care.gif"
                    alt="Like emoji"
                    height="22"
                />
            </span>
            <span v-else-if="interacted == 3" dir="auto">
                <span class="fw-bold" >{{ $t("HaHaHa") }}</span>
                <img crossorigin="anonymous" 
                    class=""
                    src="@/assets/reactions/haha.gif"
                    alt="Like emoji"
                    height="22"
                />
            </span>
            <span v-else-if="interacted == 1" dir="auto">
                <span class="fw-bold" >{{ $t("Love") }}</span>
                <img crossorigin="anonymous" 
                    class=""
                    src="@/assets/reactions/love.gif"
                    alt="Like emoji"
                    height="22"
                />
            </span>
            <span v-else-if="interacted == 5" dir="auto">
                <span class="fw-bold" >{{ $t("Sad") }}</span>
                <img crossorigin="anonymous" 
                    class=""
                    src="@/assets/reactions/sad.gif"
                    alt="Like emoji"
                    height="22"
                />
            </span>
            <span v-else-if="interacted == 4" dir="auto">
                <span class="fw-bold" >{{ $t("WOW") }}</span>
                <img crossorigin="anonymous" 
                    class=""
                    src="@/assets/reactions/wow.gif"
                    alt="Like emoji"
                    height="22"
                />
            </span>
            <span v-else>{{ $t("Like") }}</span>
            <small class="box">
                <span @click="ToggleInteraction(id, 7)">
                    <img crossorigin="anonymous" 
                    class=""
                    src="@/assets/reactions/like.gif"
                    alt="Like emoji"
                    height="30"
                    />
                </span>
                <span @click="ToggleInteraction(id, 6)">
                    <img crossorigin="anonymous" 
                    class=""
                    src="@/assets/reactions/angry.gif"
                    alt="Like emoji"
                    height="30"
                    />
                </span>
                <span @click="ToggleInteraction(id, 2)">
                    <img crossorigin="anonymous" 
                    class=""
                    src="@/assets/reactions/care.gif"
                    alt="Like emoji"
                    height="30"
                    />
                </span>
                <span @click="ToggleInteraction(id,3)">
                    <img crossorigin="anonymous" 
                    class=""
                    src="@/assets/reactions/haha.gif"
                    alt="Like emoji"
                    height="30"
                    />
                </span>
                <span @click="ToggleInteraction(id,1)">
                    <img crossorigin="anonymous" 
                    class=""
                    src="@/assets/reactions/love.gif"
                    alt="Like emoji"
                    height="30"
                    />
                </span>
                <span @click="ToggleInteraction(id,5)">
                    <img crossorigin="anonymous" 
                    class=""
                    src="@/assets/reactions/sad.gif"
                    alt="Like emoji"
                    height="30"
                    />
                </span>
                <span @click="ToggleInteraction(id,4)">
                    <img crossorigin="anonymous" 
                    class=""
                    src="@/assets/reactions/wow.gif"
                    alt="Like emoji"
                    height="30"
                    />
                </span>
            </small>
        </p>
        
    </div>
</template>

<script>
import ReelsServices from "@/services/Reels/ReelsServices"
export default {
    name:"InteractionButton",
    props: ["id", "interacted"],
    methods: {
        async ToggleInteraction(id, type) {
                let data = {
                    comment_id: id,
                    type: type
                }
                await ReelsServices.ToggleInteraction(data).then( (res) => {
                    // console.log(res.data.data)
                    this.$emit('AddInteraction', res.data.data)
                })
        },
    }
}
</script>
<style lang="scss" scoped>

.hover {
    &:hover {
        .box {
            display: flex !important;
            justify-content: space-between;

            span {
                // background-color: white;
                margin: 0 2px; 

                &:hover {
                    img {
                        scale: 1.2;
                    }
                }
            }
        }
    }
}
    .box {
        display: none;
        position: absolute;
        top: -100%;
        left: 0;
        width: auto;
        // background-color: red;
        // border: 1px solid #777;
        padding: 0.2rem 0.5rem;
        border-radius: 5px;
    }
</style>