<template>
  <small v-if="post.interacted === null" class="addreaction" dir="auto">
    <span @click="addReactionToPost(post.id, 7)">{{ $t("Like") }}</span>
    <div class="box-reaction-items">
      <span @click="addReactionToPost(post.id, 7)">
        <img crossorigin="anonymous" 
          class="img-reaction"
          src="@/assets/reactions/like.gif"
          alt="Like emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 1)">
        <img crossorigin="anonymous" 
          class="img-reaction"
          src="@/assets/reactions/love.gif"
          alt="Love emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 6)">
        <img crossorigin="anonymous" 
          class="img-reaction"
          src="@/assets/reactions/angry.gif"
          alt="Haha emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 2)">
        <img crossorigin="anonymous" 
          class="care-img img-reaction"
          src="@/assets/reactions/care.gif"
          alt="Care emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 3)">
        <img crossorigin="anonymous" 
          class="img-reaction"
          src="@/assets/reactions/haha.gif"
          alt="Wow emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 4)">
        <img crossorigin="anonymous" 
          class="img-reaction"
          src="@/assets/reactions/wow.gif"
          alt="Angry emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 5)">
        <img crossorigin="anonymous" 
          class="img-reaction"
          src="@/assets/reactions/sad.gif"
          alt="Sad emoji"
        />
      </span>
    </div>
  </small>
  <small
    v-else
    class="addreaction"
    :class="{
      like: post.interacted === 7,
      love: post.interacted === 1,
      angry: post.interacted === 6,
      HaHaHa: post.interacted === 3,
      Sad: post.interacted === 5,
      Care: post.interacted === 2,
      WOW: post.interacted === 4,
    }"
  >
    <span
      v-if="post.interacted === 1"
      @click="addReactionToPost(post.id, null)"
      >{{ $t("Love") }}</span
    >
    <span
      v-else-if="post.interacted === 6"
      @click="addReactionToPost(post.id, null)"
      >{{ $t("Angry") }}</span
    >
    <span
      v-else-if="post.interacted === 3"
      @click="addReactionToPost(post.id, null)"
      >{{ $t("HaHaHa") }}</span
    >
    <span
      v-else-if="post.interacted === 5"
      @click="addReactionToPost(post.id, null)"
      >{{ $t("Sad") }}</span
    >
    <span
      v-else-if="post.interacted === 2"
      @click="addReactionToPost(post.id, null)"
      >{{ $t("Care") }}</span
    >
    <span
      v-else-if="post.interacted === 4"
      @click="addReactionToPost(post.id, null)"
      >{{ $t("Wow") }}</span
    >
    <span v-else @click="addReactionToPost(post.id, null)">{{
      $t("Like")
    }}</span>
    <div class="box-reaction-items">
      <span @click="addReactionToPost(post.id, 7)">
        <img crossorigin="anonymous" 
          class="img-reaction"
          src="@/assets/reactions/like.gif"
          alt="Like emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 1)">
        <img crossorigin="anonymous" 
          class="img-reaction"
          src="@/assets/reactions/love.gif"
          alt="Love emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 6)">
        <img crossorigin="anonymous" 
          class="img-reaction"
          src="@/assets/reactions/angry.gif"
          alt="Haha emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 2)">
        <img crossorigin="anonymous" 
          class="care-img img-reaction"
          src="@/assets/reactions/care.gif"
          alt="Care emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 3)">
        <img crossorigin="anonymous" 
          class="img-reaction"
          src="@/assets/reactions/haha.gif"
          alt="Wow emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 4)">
        <img crossorigin="anonymous" 
          class="img-reaction"
          src="@/assets/reactions/wow.gif"
          alt="Angry emoji"
        />
      </span>
      <span @click="addReactionToPost(post.id, 5)">
        <img crossorigin="anonymous" 
          class="img-reaction"
          src="@/assets/reactions/sad.gif"
          alt="Sad emoji"
        />
      </span>
    </div>
  </small>
</template>

<script>
import GroupPostServices from "@/services/GroupsServices/GroupPostServices";
import { mapActions, mapMutations } from "vuex";
export default {
  props: ["post"],
  methods: {
    ...mapMutations({
      UPDATE_FEATURED_POST_TO_LIKE: "singleGroup/UPDATE_FEATURED_POST_TO_LIKE",
    }),
    ...mapActions({
      UPDATE_POST_TO_LIKE: "singleGroup/UPDATE_POST_TO_LIKE",
      UPDATE_POST_FEED_TO_LIKE: "Group/UPDATE_POST_TO_LIKE",
      UPDATE_POST_TO_LIKE_GroupPostHome: "post/UPDATE_POST_TO_LIKE",
    }),
    async addReactionToPost(id, type) {
      GroupPostServices.s_reaction_to_post(id, type).then((response) => {
        if (type == null) {
          const data = [id, null];
          this.UPDATE_POST_FEED_TO_LIKE(data);
          this.UPDATE_FEATURED_POST_TO_LIKE(data);
          this.UPDATE_POST_TO_LIKE_GroupPostHome(data);
          this.UPDATE_POST_TO_LIKE(data);
        } else {
          const data = [id, response.data.HandleInteractionPostMutation];
          this.UPDATE_POST_FEED_TO_LIKE(data);
          this.UPDATE_FEATURED_POST_TO_LIKE(data);
          this.UPDATE_POST_TO_LIKE_GroupPostHome(data);
          this.UPDATE_POST_TO_LIKE(data);
        }
        // if (response.data.HandleInteractionPostMutation != null) {
        //   const data = [id, response.data.HandleInteractionPostMutation];
        //   this.UPDATE_POST_FEED_TO_LIKE(data);
        //   this.UPDATE_FEATURED_POST_TO_LIKE(data);
        //   this.UPDATE_POST_TO_LIKE_GroupPostHome(data);
        //   this.UPDATE_POST_TO_LIKE(data);
        // } else {
        //   const data = [id, null];
        //   this.UPDATE_POST_FEED_TO_LIKE(data);
        //   this.UPDATE_FEATURED_POST_TO_LIKE(data);
        //   this.UPDATE_POST_TO_LIKE_GroupPostHome(data);
        //   this.UPDATE_POST_TO_LIKE(data);
        // }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  .card_post {
    padding: 0 0rem 0 0;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    .card-footer {
      small {
        margin-left: 0.5rem;
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items:lang(ar) {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            // margin: 0 -160px;
            direction: rtl;
            span {
              margin: 0 5px;
            }
          }
          .box-reaction-items:lang(ku) {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            // margin: 0 -160px;
            direction: rtl;
            span {
              margin: 0 5px;
            }
          }
          .box-reaction-items {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            span {
              margin: 0 5px;
              // margin: 0px -110px;
            }
          }
        }
      }

      .like {
        color: #6e0e7a !important;
        font-weight: bold !important;
      }
      .love {
        color: red !important;
        font-weight: bold !important;
      }

      .angry {
        color: green !important;
        font-weight: bold !important;
      }

      .HaHaHa {
        color: #ffdf00 !important;
        font-weight: bold !important;
      }

      .Sad {
        color: black !important;
        font-weight: bold !important;
      }

      .WOW {
        color: rgb(64, 11, 211) !important;
        font-weight: bold !important;
      }

      .Care {
        color: rgb(236, 10, 161) !important;
        font-weight: bold !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .card-footer {
    small {
      margin-left: 1rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -100% !important;
          width: auto !important;
          span {
            margin: 0 5px;
            font-size: 10px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .div {
    padding: 0 0.5rem !important;
    .row {
      margin: 0 0 1rem 0 !important;

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items {
            display: inline-block !important;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.548);
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -40px;
            left: 50%;
            width: 50px !important;
            span {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .card-footer {
    small {
      margin-left: 0.2rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -150% !important;
          width: auto !important;
          span {
            margin: 0 3px !important;
            font-size: 2px !important;
          }
        }
      }
    }
  }
}

.img-reaction {
  width: 40px;
  transition: 0.2s ease;
}
.img-reaction:hover {
  transform: scale(1.5);
  cursor: pointer;
}
.care-img {
  width: 23px;
  margin: 11px;
}

.body_box:lang(ar) {
  flex-direction: row-reverse;

  div {
    direction: rtl !important;
  }
}

.card-footer:lang(ar) {
  flex-direction: row-reverse;

  .addreaction {
    .box-reaction-items {
      display: none;
    }
    cursor: pointer;
    position: relative;

    &:hover {
      .box-reaction-items {
        display: inline-flex !important;
        position: absolute;
        background-color: white;
        color: white;
        padding: 10px;
        border-radius: 5px;
        top: -60px !important;
        left: -100% !important;
        width: auto !important;
        span {
          margin: 0 5px;
        }
      }
    }
  }
}
</style>
