<template>
  <div
    id="rightScroll"
    dir="auto"
    class="col-md-3 py-3 right"
    style="position: fixed !important; right: 0"
  >
    <!-- Start Sidebar left -->

    <!-- Start div row HashTags -->
    <div class="row align-items-center margin-style-mypage style-shadow">
      <div class="col-md-12 left_box_info mt-2 margin-style">
        <p class="fw-bold">
          <svg
            width="27"
            height="27"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M11 7L8 17"
                stroke="#65676b"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M16 7L13 17"
                stroke="#65676b"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M18 10H7"
                stroke="#65676b"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M17 14H6"
                stroke="#65676b"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                stroke="#65676b"
                stroke-width="1.5"
              ></path>
            </g>
          </svg>
          &nbsp;<span class="text-my-page ">{{
            $t("HashTags")
          }}</span>
        </p>
      </div>

      <div class="loading" v-if="!awaitHashTags">
        <div class="margin-style">
          <!-- Start my page -->
          <div
            v-for="(hashTags, index) in HashTagsArray.slice(0, 3)"
            :key="index"
            class="page-box mb-2"
          >
            <router-link
              class="router-link-to-profile colorThemeLeftSidebar"
              style="text-decoration: none"
              :to="{
                name: 'Hashtags',
                query: { id: hashTags.id },
              }"
            >
              <div class="row text-primary px-3 text-hashtag">#{{ hashTags.name }}</div>
            </router-link>
          </div>
          <!-- End my Hashtags -->
        </div>
        <div
          v-show="!HashTagsArray.length"
          class="text-center"
          style="cursor: pointer; font-size: small"
        >
          <span>{{ $t("There is no HashTags yet") }} </span>
        </div>
      </div>
      <div v-else>
        <SkeltonHashTagsComponent :all="3" :title="false" />
      </div>
    </div>
    <!-- End div row HashTags -->

    <!-- Start Sidebar left -->
    <hr />
    <!-- <p
      class="title colorThemeLeftSidebar"
      style="color: black; font-weight: 600"
    >
      <FriendsSvg></FriendsSvg> {{ $t("Friends") }}
    </p> -->
    <div class="row left_box align-items-center">
      <input
        type="text"
        id="myInput"
        class="search-input"
        v-model="search"
        :placeholder="$t('search-friends')"
        title="Search"
        autocomplete="off"
      />
    </div>
    <div v-if="search != ''">
      <div v-if="loadingsearch">
        <LoaderSearch />
      </div>
      <div v-else>
        <div
          v-for="(friend, index) in SearchfriendsArray"
          :key="index"
          class="row box_right align-items-center"
        >
          <div dir="auto" class="col-md-1 box_right_img">
            <router-link
              v-if="friend.has_media_profile == false"
              class="router-link-to-profile colorThemeLeftSidebar"
              :to="{
                name: 'homeMyProfile',
                params: { id: EncryptionRoute(friend.user_id) },
              }"
            >
              <img crossorigin="anonymous"  src="@/assets/img_friends/default1.png" alt="" />
            </router-link>
            <div v-else>
              <router-link
                class="router-link-to-profile colorThemeLeftSidebar"
                :to="{
                  name: 'homeMyProfile',
                  params: { id: EncryptionRoute(friend.user_id) },
                }"
              >
                <img crossorigin="anonymous" 
                  v-if="friend.media[0].collection_name == 'profile'"
                  :src="friend.media[0].src_url"
                  alt=""
                />
                <img crossorigin="anonymous"  v-else :src="friend.media[1].src_url" alt="" />
              </router-link>
            </div>
          </div>
          <div class="col-md-7 box_right_info">
            <router-link
              class="router-link-to-profile colorThemeLeftSidebar d-flex justify-content-start align-items-center"
              :to="{
                name: 'homeMyProfile',
                params: { id: EncryptionRoute(friend.user_id) },
              }"
            >
              <!-- {{ friend }} -->
              <svg
                height="10"
                width="10"
                v-if="!getActive(friend.last_seen)"
                xmlns:dc="http://purl.org/dc/elements/1.1/"
                xmlns:cc="http://creativecommons.org/ns#"
                xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                xmlns:svg="http://www.w3.org/2000/svg"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                viewBox="0 0 448 448"
                id="svg2"
                version="1.1"
                inkscape:version="0.91 r13725"
                sodipodi:docname="dot.svg"
                fill="#000000"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <g
                    inkscape:label="Layer 1"
                    inkscape:groupmode="layer"
                    id="layer1"
                    transform="translate(0,-604.36224)"
                  >
                    <circle
                      style="
                        fill-opacity: 1;
                        stroke: none;
                        stroke-width: 30;
                        stroke-linecap: round;
                        stroke-miterlimit: 4;
                        stroke-dasharray: none;
                        stroke-opacity: 1;
                      "
                      id="path3334"
                      cx="224"
                      cy="828.36224"
                      r="192"
                    ></circle>
                  </g>
                </g>
              </svg>
              <svg
                height="10"
                width="10"
                v-else
                xmlns:dc="http://purl.org/dc/elements/1.1/"
                xmlns:cc="http://creativecommons.org/ns#"
                xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                xmlns:svg="http://www.w3.org/2000/svg"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                viewBox="0 0 448 448"
                id="svg2"
                version="1.1"
                inkscape:version="0.91 r13725"
                sodipodi:docname="dot.svg"
                fill="#00ff00"
                stroke="#00ff00"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <g
                    inkscape:label="Layer 1"
                    inkscape:groupmode="layer"
                    id="layer1"
                    transform="translate(0,-604.36224)"
                  >
                    <circle
                      style="
                        fill-opacity: 1;
                        stroke: none;
                        stroke-width: 30;
                        stroke-linecap: round;
                        stroke-miterlimit: 4;
                        stroke-dasharray: none;
                        stroke-opacity: 1;
                      "
                      id="path3334"
                      cx="224"
                      cy="828.36224"
                      r="192"
                    ></circle>
                  </g>
                </g>
              </svg>
              <p
                :class="$i18n.locale == 'en' ? 'ms-2' : 'me-2'"
                style="font-size: 12px"
              >
                {{ friend.name }}
              </p>
            </router-link>
          </div>
        </div>
        <div
          v-if="this.SearchfriendsArray.length == 0"
          class="text-center mt-3 style-txt"
        >
          {{ $t("No Friends") }}
        </div>
      </div>
    </div>
    <div v-else>
      <div
        v-for="(friend, index) in friends"
        :key="index"
        class="row box_right align-items-center"
      >
        <div dir="auto" class="col-md-1 box_right_img">
          <router-link
            v-if="friend.has_media_profile == false"
            class="router-link-to-profile colorThemeLeftSidebar"
            :to="{
              name: 'homeMyProfile',
              params: { id: EncryptionRoute(friend.user_id) },
            }"
          >
            <img crossorigin="anonymous"  src="@/assets/img_friends/default1.png" alt="" />
          </router-link>
          <div v-else>
            <router-link
              class="router-link-to-profile colorThemeLeftSidebar"
              :to="{
                name: 'homeMyProfile',
                params: { id: EncryptionRoute(friend.user_id) },
              }"
            >
              <img crossorigin="anonymous" 
                v-if="friend.media[0].collection_name == 'profile'"
                :src="friend.media[0].src_url"
                alt=""
              />
              <img crossorigin="anonymous"  v-else :src="friend.media[1].src_url" alt="" />
            </router-link>
          </div>
        </div>
        <div class="col-md-7 box_right_info">
          <router-link
            class="router-link-to-profile colorThemeLeftSidebar d-flex justify-content-start align-items-center"
            :to="{
              name: 'homeMyProfile',
              params: { id: EncryptionRoute(friend.user_id) },
            }"
          >
            <!-- {{ friend }} -->
            <svg
              height="10"
              width="10"
              v-if="!getActive(friend.last_seen)"
              xmlns:dc="http://purl.org/dc/elements/1.1/"
              xmlns:cc="http://creativecommons.org/ns#"
              xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
              xmlns:svg="http://www.w3.org/2000/svg"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
              xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
              viewBox="0 0 448 448"
              id="svg2"
              version="1.1"
              inkscape:version="0.91 r13725"
              sodipodi:docname="dot.svg"
              fill="#000000"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <g
                  inkscape:label="Layer 1"
                  inkscape:groupmode="layer"
                  id="layer1"
                  transform="translate(0,-604.36224)"
                >
                  <circle
                    style="
                      fill-opacity: 1;
                      stroke: none;
                      stroke-width: 30;
                      stroke-linecap: round;
                      stroke-miterlimit: 4;
                      stroke-dasharray: none;
                      stroke-opacity: 1;
                    "
                    id="path3334"
                    cx="224"
                    cy="828.36224"
                    r="192"
                  ></circle>
                </g>
              </g>
            </svg>
            <svg
              height="10"
              width="10"
              v-else
              xmlns:dc="http://purl.org/dc/elements/1.1/"
              xmlns:cc="http://creativecommons.org/ns#"
              xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
              xmlns:svg="http://www.w3.org/2000/svg"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
              xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
              viewBox="0 0 448 448"
              id="svg2"
              version="1.1"
              inkscape:version="0.91 r13725"
              sodipodi:docname="dot.svg"
              fill="#00ff00"
              stroke="#00ff00"
            >
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <g
                  inkscape:label="Layer 1"
                  inkscape:groupmode="layer"
                  id="layer1"
                  transform="translate(0,-604.36224)"
                >
                  <circle
                    style="
                      fill-opacity: 1;
                      stroke: none;
                      stroke-width: 30;
                      stroke-linecap: round;
                      stroke-miterlimit: 4;
                      stroke-dasharray: none;
                      stroke-opacity: 1;
                    "
                    id="path3334"
                    cx="224"
                    cy="828.36224"
                    r="192"
                  ></circle>
                </g>
              </g>
            </svg>
            <b
              :class="$i18n.locale == 'en' ? 'ms-2' : 'me-2'"
              style="font-size: 12px"
            >
              {{ friend.name }}
            </b>
          </router-link>
        </div>
      </div>
      <div v-if="this.friends.length == 0" class="text-center mt-3 style-txt">
        {{ $t("No Friends") }}
      </div>
    </div>
  </div>
</template>

<script>
// import FriendsSvg from "../OtherComponent/SVG/FriendsSvg.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import SkeltonHashTagsComponent from "@/components/HashTagsPost/SkeltonHashTagsComponent.vue";
import ServiceHashTags from "@/services/Hashtags/hashtagsService";
import ActivePersonMixin from "@/mixins/ActivePersonMixin";
// import globalSearchService from "@/services/Search/globalSearchService"
import LoaderSearch from "@/components/OtherComponent/LoaderSearch.vue";
import FriendshipService from "@/services/Friendships/FriendshipService";
import { EncryptionRoute } from "@/utils/EncryptionRoute";

export default {
  name: "RightHomeComponent",
  mixins: [ActivePersonMixin],

  data() {
    return {
      search: "",
      SearchfriendsArray: [],
      loadingsearch: false,
      awaitHashTags: false,
      HashTagsArray: [],
    };
  },
  async created() {
    await this.Get_Top_HashTags();
    await this.get_all_friends();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    ...mapGetters({
      friends: "friendStore/friends",

      moreExists_Friends: "friendStore/moreExists_Friends",
    }),
    filteredList() {
      return this.friends.filter((item) => {
        return item.first_name
          .toLowerCase()
          .includes(this.search.toLowerCase());
      });
    },
    ...mapState("generalpages", {
      mypages: "My_Pages",
      loading_my_page: "isloadinginmupages",
    }),
  },
  watch: {
    search() {
      this.search != "" ? this.getResults(this.search) : "";
    },
  },
  methods: {
    EncryptionRoute,
    async getResults(key) {
      this.loadingsearch = true;
      await FriendshipService.searchFriends(key).then((res) => {
        // this.users = res.data.data.items
        this.SearchfriendsArray = res.data.FriendQuery.items;
        // console.log(res.data.data.items)
      });
      this.loadingsearch = false;
    },
    async Get_Top_HashTags() {
      this.awaitHashTags = true;
      await ServiceHashTags.Get_Top_HashTags().then((res) => {
        this.HashTagsArray = res.data.Hashtags.items;
      });
      this.awaitHashTags = false;
    },
    ...mapActions({
      get_all_friends: "friendStore/get_all_friends",
      a_LoadMore_friends: "friendStore/a_LoadMore_friends",
    }),
    async handleScroll() {
      if (
        window.scrollY + window.innerHeight >=
          document.getElementById("rightScroll").scrollHeight - 50 &&
        this.moreExists_Friends
      ) {
        window.removeEventListener("scroll", this.handleScroll);
        // console.log('fired')
        document.getElementById("spinner")
          ? document.getElementById("spinner").classList.remove("d-none")
          : "";
        await this.a_LoadMore_friends();
        document.getElementById("spinner")
          ? document.getElementById("spinner").classList.add("d-none")
          : "";
        window.addEventListener("scroll", this.handleScroll);
      }
    },
    addpage() {
      this.$router.push({ name: "addpage" });
    },
  },
  components: {
    // FriendsSvg,
    SkeltonHashTagsComponent,
    LoaderSearch,
  },
};
</script>

<style lang="scss" scoped>
// .search-input {
//   border-radius: 13px;
//   margin-bottom: 10px;
//   width: 97%;
//   border: 0px;
// }
.title {
  margin-bottom: 0.3rem;
  color: #000000;
  font-size: 13px;
}
.box_right {
  margin-bottom: 0.5rem;

  .box_right_img {
    margin-right: 1rem;

    img {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      object-fit: unset;
    }
  }

  .box_right_info {
    p {
      margin-bottom: 0;
    }

    small {
      color: #777;
    }
  }
}

@media screen and (max-width: 992px) {
  .right {
    display: none !important;
  }
}

@media screen and (min-width: 993px) and (max-width: 1200px) {
  .title {
    margin-bottom: 1rem;
  }
  .box_right {
    margin-bottom: 1rem;

    .box_right_img {
      // margin-right: 1.5rem !important;
      text-align: start !important;

      img {
        width: 40px !important;
        height: 40px !important;
      }
    }

    .box_right_info {
      p {
        margin-bottom: 0;
        font-size: 12px;
      }

      small {
        color: #777;
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1300px) {
  .title {
    margin-bottom: 2rem;
  }
  .box_right {
    margin-bottom: 1.5rem !important;

    .box_right_img {
      margin-right: 1.5rem !important;

      img {
        width: 50px !important;
        height: 50px !important;
      }
    }

    .box_right_info {
      p {
        margin-bottom: 0;
      }

      small {
        color: #777;
      }
    }
  }
}
.col-md-3 .box_right_img:lang(ar) {
  margin-right: 2rem;
}
.col-md-3 .box_right_img:lang(ku) {
  margin-right: 2rem;
}
.right:lang(ar) {
  left: 0px;
  right: inherit;
  margin: 10px;
}
.right:lang(ku) {
  left: 0px;
  right: inherit;
  margin: 10px;
}
.right::-webkit-scrollbar {
  display: none;
}
.right {
  transition: all 0.3s ease-out;
  overflow: scroll;
  overflow-x: hidden;
  top: 60px;
  bottom: 0;

  // background: $rightSidebar;
  // linear-gradient(to right, #28023e, #9033cd)
  border-radius: 4px;
  margin: 0px;
}
.col-md-3 {
  width: 23%;
}
.style-txt {
  font-size: 14px;
}
#myInput {
  background-image: url("../../assets/images/search.png");
  background-position: 6px 7px;
  background-repeat: no-repeat;
  width: 92%;
  font-size: 14px;
  padding: 8px 20px 10px 40px;
  border: 1px solid #ddd;
  // margin-bottom: 12px;
  border-radius: 6px;
  margin: 6px;
}

.left {
  min-height: 100vh;
  padding: 1rem 1rem !important;

  .left_box_info {
    p {
      font-size: 15px !important;
    }
  }
}
.router-link-to-profile {
  text-decoration: none;
  font-size: 13px;
  // color: #000000;
  // font-weight: bold;
}
.text-my-page {
  color: #000000;
  font-size: 14px;
}
.btn-moreshow {
  background: #d7d7d7;
  padding: 2%;
  border-radius: 5px;
}
.btn-moreshow:hover {
  filter: brightness(80%);
  color: #000000;
  font-weight: 600;
}
.text-center.btn-moreshow {
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border: 2px solid #983baf;
}

div#rightScroll {
  @include applyDirection;
}
.text-hashtag {
    font-weight: bolder;
}
.row.align-items-center.margin-style-mypage.style-shadow {
    background: white;
    border: 1px solid #ddd;
    border-radius: 4%;
    box-shadow: 0px 0px 3px 1px #ccc;
}
</style>
