<template>
  <div class="answer-container">
    <div class="answer-and-like">
      <div
        class="answer"
        :class="{ rtl: isRtl }"
        @click="toggleAnswers(index, question.id)"
      >
        {{
          question.showAnswers
            ? this.$i18n.t("close answers")
            : this.$i18n.t("answers")
        }}
        ({{ question.answersCount }})
      </div>      
      <div class="like">
        <div class="reaction-wrapper" @mouseleave="hideReactions(question.id)">
          <button
            v-if="question.interacted == null"
            :class="['like-button', { liked: question.interacted !== null }]"
            @mouseover="showReactions(question.id)"
            @click="react(question.id, 7)"
          >
            <svg
              class="like-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              :class="{ 'liked-icon': question.interacted !== null }"
            >
              <path
                d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
              ></path>
            </svg>
            {{ question.interactions_count ? question.interactions_count : 0 }}
          </button>
          <button
            v-if="question.interacted != null"
            :class="['like-button', { liked: question.interacted !== null }]"
            @mouseover="showReactions(question.id)"
            @click="react(question.id, null)"
          >
            <!-- <svg
                  class="like-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  :class="{ 'liked-icon': question.interacted !== null }"
                >
                  <path
                    d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                  ></path>
                </svg> -->
            <svg
              class="like-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              :class="{ 'liked-icon': false }"
              v-if="question.interacted == null"
            >
              <path
                d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
              ></path>
            </svg>
            <LikeIcon v-if="question.interacted == 7" />
            <LoveIcon v-if="question.interacted == 1" />
            <AngryIcon v-if="question.interacted == 6" />
            <SadIcon v-if="question.interacted == 5" />
            <WowIcon v-if="question.interacted == 4" />
            <HahahaIcon v-if="question.interacted == 3" />
            <SupportReactionIcon v-if="question.interacted == 2" />
            {{ question.interactions_count ? question.interactions_count : 0 }}
          </button>

          <div
            class="reaction-box"
            v-if="question.showReactions"
            @mouseover="keepReactionsVisible"
            @mouseleave="hideReactions(question.id)"
          >
            <button @click="react(question.id, 7)"><LikeIcon /></button>
            <button @click="react(question.id, 1)"><LoveIcon /></button>
            <button @click="react(question.id, 6)"><AngryIcon /></button>
            <button @click="react(question.id, 3)"><SadIcon /></button>
            <button @click="react(question.id, 4)"><WowIcon /></button>
            <button @click="react(question.id, 5)"><HahahaIcon /></button>
            <button @click="react(question.id, 2)">
              <SupportReactionIcon />
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="question.showAnswers" class="answers">
      <hr />
      <div v-if="isLoading" class="loading-spinner">
        <LoadingIcon />
      </div>

      <!-- answers -->
      <div v-else :class="{ scrollable: question.answersCount > 5 }">
        <div class="new-answer">
          <div class="textarea-container">
            <textarea
              v-model="body"
              :style="{ height: 'auto' }"
              @input="resizeTextarea"
              class="form-control txt-area"
              type="text"
              :placeholder="$t('write your answer')"
              dir="auto"
            ></textarea>
            <div class="emoji-icon" @click="toggleEmojiPicker">
              <EmojiIcon />
            </div>
          </div>
          <div
            v-if="showEmojiPicker"
            class="row emoji-picker-container"
            v-click-outside="toggleEmojiPicker"
          >
            <Picker
              :data="emojiIndex"
              set="twitter"
              @emoji-click="addEmoji"
              @select="showEmoji"
            />
          </div>
          <div class="button-container" v-if="body.trim() != ''">
            <button
              class="save-answer-btn"
              @click="addAnswer(question.id)"
              :disabled="body.trim() === ''"
            >
              <span v-if="!loadingNewAnswer" class="btn-content">
                <SaveIcon />
                {{ $t("Post an answer") }}
              </span>
              <span v-else class="btn-content">{{ $t("Loading...") }}</span>
            </button>
          </div>
        </div>
        <!-- Start Tabs Answers -->
        <div class="tabs">
          <button
            :class="{ active: activeTab === 'all' }"
            @click="getAllAnswers(question.id)"
          >
            {{ $t("All") }}
          </button>
          <button
            :class="{ active: activeTab === 'best' }"
            @click="allBestAnswers(question.id)"
          >
            {{ $t("Best Answers") }}
          </button>
        </div>
        <!-- End Tabs Answers -->
        <!-- Start  Tab All -->
        <div v-if="activeTab === 'all'">
          <div v-for="(answer, ansIndex) in answers" :key="ansIndex">
            <AnswerCard
              :answer="answer"
              :ansIndex="ansIndex"
              @remove-answer="removeAnswer"
              @updateRating="updateRating"
              @update-answer="saveEditedAnswer"
              @remove-rate="cancelRating"
            />
          </div>
          <!-- Load More -->
          <div class="mb-5 text-center">
            <button
              class="btn specialBtn"
              @click="LoadMoreAnswers(question.id)"
              v-if="has_more_pages_Answer"
            >
              <span v-if="isLoadingMoreAnswer">{{ $t("Loading...") }}</span>
              <span v-else>{{ $t("Load More") }}</span>
              <ChevronDownIcon />
            </button>
          </div>
          <!-- Load More -->
        </div>
        <!-- End Tab All -->
        <!-- Start Tab Best -->
        <div v-if="activeTab === 'best'" class="best-answers">
          <p class="text-center">
            {{ rankingExplanation }}
          </p>
          <div v-for="(answer, ansIndex) in answers" :key="ansIndex">
            <AnswerCard
              :answer="answer"
              :index="ansIndex"
              @remove-answer="removeAnswer"
              @updateRating="updateRating"
              @saveEditedAnswer="saveEditedAnswer"
              @remove-rate="cancelRating"
            />
          </div>

          <!-- Load More -->
          <div class="mb-5 text-center">
            <button
              class="btn specialBtn"
              @click="LoadMoreAnswers(question.id)"
              v-if="has_more_pages_Answer"
            >
              <span v-if="isLoadingMoreAnswer">{{ $t("Loading...") }}</span>
              <span v-else>{{ $t("Load More") }}</span>
              <ChevronDownIcon />
            </button>
          </div>
          <!-- Load More -->
        </div>
        <!-- End Tab Best -->
        <!-- Check if answers are empty -->
        <div v-if="answers.length === 0" class="no-answers-message">
          {{ $t("Be the first to answer the question") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SupportReactionIcon from "@/components/questions/icons/emojis/SupportReactionIcon.vue";
import HahahaIcon from "@/components/questions/icons/emojis/HahahaIcon.vue";
import SadIcon from "@/components/questions/icons/emojis/SadIcon.vue";
import AngryIcon from "@/components/questions/icons/emojis/AngryIcon.vue";
import WowIcon from "@/components/questions/icons/emojis/WowIcon.vue";
import LikeIcon from "@/components/questions/icons/emojis/LikeIcon.vue";
import LoveIcon from "@/components/questions/icons/emojis/LoveIcon.vue";
import EmojiIcon from "@/components/questions/icons/EmojiIcon.vue";
import SaveIcon from "@/components/questions/icons/SaveIcon.vue";
import LoadingIcon from "@/components/questions/icons/LoadingIcon.vue";
import ChevronDownIcon from "@/components/questions/icons/ChevronDownIcon.vue";
import questionsServices from "@/services/HomePage/Questions/questionsServices";
import AnswerCard from "@/components/questions/answers/AnswerCard.vue";

import vClickOutside from "click-outside-vue3";
// all emoji sets.
import data from "emoji-mart-vue-fast/data/all.json";
import "emoji-mart-vue-fast/css/emoji-mart.css";
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src";

// Create emoji data index.
// We can change it (for example, filter by category) before passing to the component.
let emojiIndex = new EmojiIndex(data);

export default {
  props: ["question", "index"],
  data() {
    return {
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
      isRtl: document.documentElement.dir === "rtl",
      activeTab: "all",
      answers: [],
      isLoading: false,
      has_more_pages_Answer: false,
      isLoadingMoreAnswer: false,
      pageAnswer: 1,
      loadingNewAnswer: false,
      body: "",

      emojiIndex: emojiIndex,
      emojisOutput: "",
      showEmojiPicker: false,
    };
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },

  watch: {
    answers: {
      handler(newAnswers) {
        newAnswers.forEach((answer) => {
          this.$watch(
            () => answer.rate,
            () => {
            }
          );
          this.$watch(
            () => answer.reviewed,
            () => {
            }
          );
        });
      },
      deep: true,
    },
  },
  methods: {
    toggleAnswers(index, post_id) {
      this.$emit("toggleAnswers", {
        index: index,
        post_id: post_id,
      });
      this.getAllAnswers(post_id);
    },
    getAllAnswers(post_id) {
      this.activeTab = "all";
      this.pageAnswer = 1;
      let data = {
        post_id: post_id,
        page: this.pageAnswer,
      };
      this.isLoading = true;
      questionsServices.allAnswers(data).then((res) => {
        this.has_more_pages_Answer = res.data.GetAnswers.has_more_pages;
        this.answers = res.data.GetAnswers.items;
        this.isLoading = false;
      });
    },
    async LoadMoreAnswers(post_id) {
      this.isLoadingMoreAnswer = true;
      this.pageAnswer = this.pageAnswer + 1;
      let data = {
        post_id: post_id,
        page: this.pageAnswer,
      };
      await questionsServices.allAnswers(data).then((res) => {
        const newAnswers = res.data.GetAnswers.items;
        this.answers.push(...newAnswers);
        this.has_more_pages_Answer = res.data.GetAnswers.has_more_pages;
      });
      this.isLoadingMoreAnswer = false;
    },
    allBestAnswers(post_id) {
      this.activeTab = "best";
      this.pageAnswer = 1;
      let data = {
        post_id: post_id,
        page: this.pageAnswer,
        topAnswers: true,
      };
      this.isLoading = true;
      questionsServices.allAnswers(data).then((res) => {
        this.has_more_pages_Answer = res.data.GetAnswers.has_more_pages;
        this.answers = res.data.GetAnswers.items;
        this.isLoading = false;
      });
    },
    updateRating(data) {
      let payloadData = {};
      if (!this.page) {
        payloadData = {
          page_id: this.page.id,
          id: data.answer_id,
          rate: data.rating,
        };
      } else {
        payloadData = {
          id: data.answer_id,
          rate: data.rating,
        };
      }
      questionsServices.rating(payloadData).then(() => {
        const ans = this.answers.find((a) => a.id === data.answer_id);
        if (ans) {
          ans.rate = data.rating;
          ans.reviewed = data.rating;
        }
      });
    },
    cancelRating(data) {
      let payloadData = {};
      if (!this.page) {
        payloadData = {
          page_id: this.page.id,
          id: data.answer_id,
          rate: 0,
        };
      } else {
        payloadData = {
          id: data.answer_id,
          rate: 0,
        };
      }
      questionsServices.rating(payloadData).then(() => {
        const ans = this.answers.find((a) => a.id === data.answer_id);
        if (ans) {
          ans.rate = 0;
          ans.reviewed = null;
        }
   
        this.$snotify.success("cancel rating successfully", "Answer");
      });
    },
    saveEditedAnswer(data) {
      let dataAnswer = {};
      if (!this.page) {
        dataAnswer = {
          page_id: this.page.id,
          id: data.answer_id,
          body: data.body,
        };
      } else {
        dataAnswer = {
          id: data.answer_id,
          body: data.body,
        };
      }
      questionsServices.updateAnswer(dataAnswer).then(() => {
        this.answers[data.index].body = data.body;
        this.$snotify.success("update successfully", "question");
        // document.body.style.overflow = 'auto';
      });
    },
    removeAnswer(data) {
      questionsServices
        .deleteAnswer(data.answer_id)
        .then(() => {
          this.answers.splice(data.index, 1);
          this.$snotify.success("delete successfully", "Question");
        })
        .catch((error) => {
          console.error("Error deleting answer:", error);
          this.$snotify.error("Failed to delete the answer.", "Error");
        });
    },
    addEmoji(emoji) {
      this.body += emoji.native;
    },
    showEmoji(emoji) {
      this.emojisOutput = this.emojisOutput + emoji.native;
      this.body += emoji.native;
    },

    toggleEmojiPicker() {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    resizeTextarea(event) {
      const textarea = event.target;
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    addAnswer(post_id) {
      this.loadingNewAnswer = true;
      let answerData = {};
      if (!this.page) {
        answerData = {
          page_id: this.page.id,
          post_id: post_id,
          body: this.body,
        };
      } else {
        answerData = {
          post_id: post_id,
          body: this.body,
        };
      }
      if (this.body.trim() !== "") {
        questionsServices.saveAnswer(answerData).then((res) => {
         
          const newAnswer = res.data.StoreAnswer;
          newAnswer.rate = 0;
          newAnswer.reviewed = null;
          this.answers.unshift(newAnswer);
          // this.$emit("update-answers-count", this.index);
          // this.question.answersCount = this.question.answersCount + 1;
          this.body = "";
          this.loadingNewAnswer = false;
        });
      } else {
        this.loadingNewAnswer = false;
      }
    },
    showReactions(questionId) {
      this.$emit("update-show-reaction", questionId);
    },
    keepReactionsVisible() {
      //  reactions visible
    },
    hideReactions(questionId) {
      this.$emit("update-hide-reaction", questionId);
    },
    submitAnswer(questionId, event) {
      const answer = event.target.value;
      let answerData = {};
      if (!this.page) {
        answerData = {
          page_id: this.page.id,
          post_id: questionId,
          body: answer,
        };
      } else {
        answerData = {
          post_id: questionId,
          body: answer,
        };
      }
      questionsServices.saveAnswer(answerData).then(() => {
        event.target.value = "";
      });
    },
    react(questionId, reactionType) {
      this.$emit("react", {
        questionId: questionId,
        reactionType: reactionType,
      });
    },
  },
  components: {
    SupportReactionIcon,
    HahahaIcon,
    SadIcon,
    AngryIcon,
    WowIcon,
    LoveIcon,
    LikeIcon,
    EmojiIcon,
    SaveIcon,
    LoadingIcon,
    AnswerCard,
    ChevronDownIcon,
    Picker,
  },
  computed: {
    rankingExplanation() {
      return this.$t(
        "The ranking is based on the highest rated answers, then the most recent answers"
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.answer-container {
  margin-top: 10px;
}

.tabs {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  border-bottom: 2px solid #ddd;
}

.tabs button {
  padding: 1rem 2rem;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease;
  font-size: 1rem;
  font-weight: bold;
  color: #666;
}

.tabs button:hover {
  color: #000;
}

.tabs button.active {
  border-bottom: 2px solid #000;
  color: #000;
}

.answer-and-like {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.answer {
  cursor: pointer;
  color: blue;
}

.like {
  color: green;
  cursor: pointer;
}

.rtl {
  text-align: left;
}

.answers {
  margin-top: 10px;
  //   max-height: 250px;
  overflow-y: auto;
}

.answers.scrollable {
  max-height: unset;
  overflow-y: unset;
}

.user-info-left {
  display: flex;
  align-items: center;
}

.answer-user-info h4 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.answer-user-info .date {
  font-size: 12px;
  color: #888;
}

.answer-user-info p {
  margin: 0;
  font-size: 14px;
}

.options {
  display: flex;
  align-items: center;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  border: none;
  font-size: 14px;
  cursor: pointer;
  color: #007bff;
  margin-left: 5px;
}

select:focus {
  outline: none;
}

option[disabled] {
  color: #ccc;
}

.new-answer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.textarea-container {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}
//
.save-answer-btn {
  margin: 5px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  display: inline-block;
  position: relative;
}

.save-answer-btn .btn-content {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 17px;
  color: #983baf;
  border: 1px solid #817c83;
  border-radius: 3px;
  padding: 3px 3px;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
//
.custom-button {
  margin: 5px;
  background-color: #983baf;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: #872a9c;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.textarea-container {
  position: relative;
}

.emoji-icon {
  position: absolute;
  right: 1px;
  bottom: -7px;
  cursor: pointer;
}

.emoji-picker-container {
  position: relative;
  bottom: 40px;
  right: 10px;
  height: 15px;
  z-index: 2;
}
.txt-area {
  resize: none;
}

.specialBtn {
  background-color: #8f5fee;
  color: white !important;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.specialBtn:hover {
  background-color: #6e3ebf;
}

.specialBtn svg {
  width: 1em;
  height: 1em;
  margin-left: 5px;
  vertical-align: middle;
  transition: transform 0.3s ease;
}

.specialBtn:hover svg {
  transform: translateY(2px);
}
.no-answers-message {
  text-align: center;
  color: #888;
  font-size: 1.2em;
  margin: 20px 0;
}
.reaction-wrapper {
  position: relative;
}

.like-button {
  background-color: #f0f0f0;
  color: #555;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.like-button:hover {
  background-color: #e0e0e0;
}

.like-icon {
  width: 16px;
  height: 16px;
}

.liked {
  background-color: #cacee7;
  border-color: #cacee7;
  color: #0b0c0c;
}

.liked-icon {
  stroke: #e60000;
  fill: #e60000;
}

.reaction-box {
  display: none;
  position: absolute;
  top: -50px;
  left: -120px;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: row;
  padding: 10px;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .reaction-box {
    top: -50px;
    left: 175%;
    transform: translateX(-50%);
    width: auto;
  }
}

html[dir="rtl"] .reaction-box {
  left: auto;
  right: 130%;
  transform: translateX(50%);
}

.reaction-box button {
  background: none;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  text-align: left;
}

.reaction-box button:hover {
  background-color: #f0f0f0;
}

.answer-input {
  flex-grow: 1;
  margin-left: 52px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 15px;
  font-size: 13px;
}
.answer-input::placeholder {
  padding: 5px;
}
.answer-input:focus {
  border-color: #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

@keyframes border-expand {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1.05);
  }
}
.swiper-pagination {
  margin-top: 20px;
  text-align: center;
}

.reaction-wrapper:hover .reaction-box,
.reaction-box:hover {
  display: flex;
  direction: rtl;
}
</style>
