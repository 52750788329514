<template>
  <div class="vcomments__item">
    <div class="row">
      <div class="col-12">
        <div class="comment-avatar">
          <img crossorigin="anonymous" 
            v-if="comment.profile.has_media_profile === false"
            src="@/assets/story_img/user.png"
            alt=""
          />
          <img crossorigin="anonymous" 
            v-else-if="
              comment.profile.has_media_profile === true &&
              comment.profile.media[0].collection_name === 'profile'
            "
            class="pd-image"
            :src="comment.profile.media[0].src_url"
            alt=""
            v-viewer
          />
          <img crossorigin="anonymous" 
            v-else-if="
              comment.profile.has_media_profile === true &&
              comment.profile.media[1].collection_name === 'profile'
            "
            class="pd-image"
            :src="comment.profile.media[1].src_url"
            alt=""
            v-viewer
          />
        </div>
        <div class="vcomments__info">
          <span class="vcomments__author" dir="auto">
            <router-link
              v-if="this.USER.id == comment.profile.id"
              class="router-link-to-profile"
              :to="{
                name: 'homeMyProfile',
                params: { id: EncryptionRoute(USER.id) },
              }"
              >{{ comment.profile.name }}</router-link
            >
            <router-link
              v-else
              class="router-link-to-profile"
              :to="{
                
                name: 'homeMyProfile',
                params: { id: EncryptionRoute(comment.profile.id) },
              }"
              >{{ comment.profile.name }}</router-link
            >
          </span>
          <div class="dropdown text-center">
            <small
              style="cursor: pointer"
              :id="'dropdownMenuButton1' + comment.id"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img crossorigin="anonymous" 
                class="image-dropdown"
                src="@/assets/images/more.png"
                alt="action more"
              />
            </small>
            <ul
              class="dropdown-menu"
              :aria-labelledby="'dropdownMenuButton1' + comment.id"
            >
              <li
                v-if="this.USER.id == comment.profile.user_id"
                style="cursor: pointer"
              >
                <a class="dropdown-item" @click="delete_comment(comment)">
                  <DeleteSvg></DeleteSvg>
                  {{ $t("Delete") }}
                </a>
              </li>
              <li
                v-if="this.USER.id == comment.profile.user_id"
                @click="
                  ShowEditeComment(comment.id, comment.content, comment.tags)
                "
                class="dropdown-item"
                style="cursor: pointer"
              >
                <img crossorigin="anonymous" 
                  src="@/assets/images/edit_mode_icon.png"
                  style="width: 20px; height: 20px"
                  alt=""
                />
                {{ $t("Edit") }}
              </li>
              <!-- <li
                v-if="this.USER.id != comment.profile.id"
                class="dropdown-item"
                style="cursor: pointer"
                :data-bs-target="'#staticCommentReport' + comment.id"
                data-bs-toggle="modal"
              >
                <ReportPostSvg></ReportPostSvg>
                {{ $t("Report Comment") }}
              </li> -->
            </ul>
          </div>
          <!-- <CommentReportComponent :id="comment.id" /> -->

          <UpdateComment :comment="comment"></UpdateComment>
          <span class="vcomments__time">{{
            format_date(comment.created_at)
          }}</span>
        </div>
        <div class="card-body" dir="auto">
          <div class="card-text vcomments__quote m-4">
            <div :id="`editecomment${comment.id}`">
              <div
                v-if="comment.content"
                v-html="
                  this.showcontentComment(comment.content, comment.tags)
                "
                v-linkified
              ></div>
              <div v-if="comment.media && comment.media.media_type == 'Image'	" class="mt-2 text-center">
                <img crossorigin="anonymous"  :src="comment.media.src_url" style="max-width: 150px;max-height: 150px;min-height: 75px;min-width: 75px;" alt="CommentImage">
              </div>
              <div v-else-if="comment.media && comment.media.media_type == 'Sound'	" class="mt-2 text-center">
                <audio  :src="comment.media.src_url" controls></audio>
              </div>
            </div>

            <div :id="`inputeditecomment${comment.id}`" class="visually-hidden">
              <Mentionable
                class="w-100 Mentionable"
                :keys="['@']"
                :items="friendstagsarray"
                filtering-disabled
                offset="6"
                insert-space
                @open="loadIssues()"
                @search="loadIssues($event)"
              >
                <!-- <textarea
                  rows="3"
                  name=""
                  v-model="editecommentinput"
                  style="
                    height: 100%;
                    width: 100%;
                    border: 1px solid #ccc;
                    overflow-y: scroll;
                  "
                ></textarea> -->
                <EmojiPicker
                  v-click-outside="onClickOutside"
                  v-if="this.visible_emoji == true"
                  :native="true"
                  @select="onSelectEmoji"
                />
                <textarea
                  dir="auto"
                  class="vcomments__add-input ar_vcomment"
                  :placeholder="!recording ? $t(`Your comment`) : $t('voice comment')"
                  v-model="editecommentinput"
                  @input="resize()"
                  :disabled="loadingStoreComment"
                  ref="textarea"
                ></textarea>

                <span :class="DefineDeriction() ? 'commentsvgboxar' : 'commentsvgbox'" v-if="!recording">
                  <span  class="pointer" @click="open_emoji">
                    <EmojiSvg ></EmojiSvg>
                  </span>
                  
                </span>

                <template #no-result>
                  <div
                    class="allfriendsbox"
                    v-if="loadingForNewREsultInMentaion"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="allfriendsbox" v-else>
                    {{ $t("No Result") }}
                  </div>
                </template>

                <template #item-@="{ item }">
                  <div class="allfriendsbox">
                    {{ item.name }}
                  </div>
                </template>
              </Mentionable>
              <div class="d-flex mt-2 justify-content-start align-items-center">
                <button
                  type="button"
                  v-if="!IfdeleteImageFromCommentOrNot && !Commentmedia && comment.media && !onlyVoice"
                  class="btn-close d-block"
                  @click="deleteImageFromComment"
                  aria-label="Close"
                ></button>
                  <label v-if="!onlyVoice" :for="`uploadMediaFromComment${comment.id}`" class="mx-2 pointer">
                    <AddMediaComment></AddMediaComment>
                  </label>
                  <input
                        type="file"
                        :id="`uploadMediaFromComment${comment.id}`"
                        ref="uploadMediaFroEditCommentInGroup"
                        @change="uploadMediaFroEditCommentInGroup()"
                        hidden
                        accept="image/*"
                  />
                </div>
              <div class="mt-2 text-center position-relative">
                <img crossorigin="anonymous"  v-if="Commentmedia" :src="Commentmedia.url"
                  style="
                    width: 150px;
                    height: 150px;
                  ">
                <img crossorigin="anonymous"  v-if="!IfdeleteImageFromCommentOrNot && !Commentmedia && (comment.media && comment.media.media_type == 'Image')" :src="comment.media.src_url" style="width: 150px;height: 150px;" alt="">
              </div>
              <br />
              <button
                v-if="!loadingStatusForEditeComment"
                class="btn btn-sm ms-3"
                style="background: #8f5fee; font-weight: 600"
                @click="EditComment(comment.id)"
                :disabled="((editecommentinput == '' || editecommentinput == null) && !Commentmedia && (!comment.media ||(comment.media && IfdeleteImageFromCommentOrNot)))"
              >
                {{ $t("Edit") }}
              </button>
              <button
                v-if="!loadingStatusForEditeComment"
                class="btn btn-sm ms-3"
                style="background-color: #ddd"
                @click="cancle_update_comment(comment.id)"
              >
                {{ $t("Cancle") }}
              </button>
              <button v-else class="btn btn-sm btn-default ms-3">
                {{ $t("loading...") }}
              </button>
            </div>

            <!-- End Edit Comment  -->
          </div>
        </div>
      </div>
      <div class="vcomments__btns">
        <!-- Start Button Reaction to comment -->
        <AddReactionToComment :comment="comment"></AddReactionToComment>
        <!-- End Button Reaction to comment -->
        <div class="vcomments__bleft">
          <span
            class="vcomments__btn vcomments__btn--reply"
            @click="clickReply()"
            >{{ replyText }}</span
          >
        </div>
        <div class="vcomments__bright" v-show="this.replies.length > 0">
          <span>{{ comment.replies_count }}</span>
          <span
            class="vcomments__btn vcomments__btn--toggle"
            @click="toggleGroup()"
            >{{ toggleText }}</span
          >
        </div>
      </div>
      <div
        class="vcomments__group"
        v-show="!isGroupRolledUp"
        v-if="this.replies.length > 0"
      >
        <ReplyComponent
          v-for="reply in replies"
          :key="reply.id"
          :comment="reply"
          :friendstagsarray="friendstagsarray"
          v-on:deletereply="deletereply"
          v-on:addreactiontoreply="addreactiontoreply"
          v-on:editreply="editreply"
        ></ReplyComponent>
      </div>
      <AddComment
        ref="form"
        v-on:addreply="addreply"
        v-show="isReplying"
        v-bind:comment="comment"
      ></AddComment>
    </div>
  </div>
</template>
<script>
import GroupPostServices from "@/services/GroupsServices/GroupPostServices";
import { mapActions, mapGetters, mapState } from "vuex";
import AddComment from "@/components/GroupsComponents/CommentComponent/Comment/AddComment.vue";
// import CommentReportComponent from "@/components/HomeComponent/CommentReportComponent.vue";
import DeleteSvg from "@/components/OtherComponent/SVG/DeleteSvg.vue";
// import ReportPostSvg from "@/components/OtherComponent/SVG/ReportPostSvg.vue";
import UpdateComment from "../UpdateComment.vue";
import AddReactionToComment from "./AddReactionToComment.vue";
// import ReplyComponent from "../Replies/ReplyComponent.vue";
import ReplyComponent from "@/components/GroupsComponents/CommentComponent/Replies/ReplyComponent.vue";
import FriendshipService from "@/services/Friendships/FriendshipService";
import { Mentionable } from "vue-mention";
import formatTime from "@/mixins/formatTime";
import {EncryptionRoute} from "@/utils/EncryptionRoute";
import EmojiSvg from "@/icons/GlobalIcon/EmojiSvg.vue";
import LangMixin from "@/mixins/LangMixin";
import vClickOutside from "click-outside-vue3";
// import picker compopnent
import EmojiPicker from "vue3-emoji-picker";
// import css
import "vue3-emoji-picker/css";
import AddMediaComment from "@/icons/Groups/AddMediaComment.vue";
import { getStorage, ref, uploadBytes,uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { uuid } from "vue-uuid";

export default {
  name: "comment-component",
  props: ["comment", "editecommentid", "onlyVoice"],
  mixins: [formatTime, LangMixin],
  data: function () {
    return {
      isGroupRolledUp: true,
      isReplying: false,
      body_reply: this.comment.content,
      comment_id: this.comment.id,
      loadingStatusForEditeComment: false,
      editecommentinput: null,
      // editecommentid: null,
      friends_ids: [],
      delete_friends_ids: [],
      replies: [],
      loadingForNewREsultInMentaion: false,
      IfdeleteImageFromCommentOrNot: false, // true => i delete image from comment
      media: null,
      Commentmedia: null,
      datasender: null,
      visible_emoji: false,
    };
  },
  created() {
    this.getallreplies(this.comment_id);
    this.friendstagsarray.length == 0 ? this.get_all_friends() : ""; // if we rfresh the page execute get all friends to have array tags
    this.Allfriendstagsarray.length == 0
      ? this.get_all_friends_For_Array_tags()
      : "";
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {

    onClickOutside() {
      this.visible_emoji = false;
    },
    open_emoji() {
      if (this.visible_emoji == false) {
        this.visible_emoji = true;
      } else {
        this.visible_emoji = false;
      }
    },
    onSelectEmoji(emoji) {
      this.editecommentinput ? this.editecommentinput += emoji.i : this.editecommentinput = emoji.i;
    },
    resize() {
      let element = this.$refs["textarea"];

      element.style.height = "11px";
      element.style.height = element.scrollHeight + "px";
    },

    // For Edit Comment With Media
    deleteImageFromComment(){
      this.IfdeleteImageFromCommentOrNot = true
    },
    uploadMediaFroEditCommentInGroup(){
      this.media = this.$refs.uploadMediaFroEditCommentInGroup.files[0];
      let data = {
        type: this.$refs.uploadMediaFroEditCommentInGroup.files[0].type,
        url: URL.createObjectURL(this.$refs.uploadMediaFroEditCommentInGroup.files[0]),
      }
      this.Commentmedia = data;
      this.IfdeleteImageFromCommentOrNot = true
    },
    // For Edit Comment With Media

    EncryptionRoute,
    async loadIssues(searchText = null) {
      if (searchText != null) {
        this.loadingForNewREsultInMentaion = true;
        await FriendshipService.searchFriends(searchText).then(
          (res) => {
            // console.log(res.data.data.items.length)
            if (res.data.FriendQuery.items.length > 0) {
              this.$store.commit(
                "friendStore/SET_NEW_friendstagsarray",
                res.data.FriendQuery.items
              );
            }
          }
        );
        this.loadingForNewREsultInMentaion = false;
      } else {
        if (this.friendstagsarray.length < 4) {
          this.loadingForNewREsultInMentaion = true;
          this.$store.commit("friendStore/SET_NEW_friendstagsarray", []);
          await FriendshipService.searchFriends(searchText).then(
            (res) => {
              this.$store.commit(
                "friendStore/SET_NEW_friendstagsarray",
                res.data.FriendQuery.items
              );
            }
          );
          this.loadingForNewREsultInMentaion = false;
        }
      }
    },
    addreactiontoreply(payload) {
      this.replies.forEach((item) => {
        if (item.id === payload[0]) {
          if (payload[1] != null) {
            if (item.interacted) {
              item.interacted = payload[1].type;
            } else {
              item.interacted = payload[1].type;
              item.count_interactions++;
            }
          } else {
            item.interacted = null;
            item.count_interactions--;
          }
        }
      });
    },
    editreply(data) {
      this.replies = this.replies.map((item) =>
        item.id == data.id ? data : item
      );
    },
    async deletereply(comment) {
      await GroupPostServices.s_delete_comment(comment.id);
      // this.$store.dispatch("groupComment/a_delete_comment", comment.id);
      this.$store.commit("groupComment/Decrease_COUNT_REPLIES", comment);
      // const index1 = this.replies.findIndex((item) => item.id == comment.id);
      // this.replies.splice(index1, 1);
      this.replies = this.replies.filter((reply) => reply.id != comment.id);
    },
    addreply(data) {
      this.isReplying = false;
      this.isGroupRolledUp = false;
      data.interacted = null;
      data.interactions_count = 0;
      // data.commentable = [];
      this.replies.push(data);
    },

    async getallreplies(id) {
      let data = {
        comment_id: id
      }
      await GroupPostServices.s_all_get_replies_to_comment(data).then((res) => {
        // console.log(res.data.AllRepliesForCommentQuery);
        this.replies = res.data.AllRepliesForCommentQuery.items;
        // if (payload.hasReplies == true) {
        // }
      });
    },

    ...mapActions({
      get_all_friends: "friendStore/get_all_friends",
      get_all_friends_For_Array_tags:
        "friendStore/get_all_friends_For_Array_tags",
    }),
    async UpdateTagsInUpdateComment(tags) {
      //First add all old tags to deleted array
      tags.forEach((onetag) => {
        this.delete_friends_ids.push(onetag.id);
      });

      var delete_friends_ids = this.delete_friends_ids;

      // check if does not any changes in tags .... means update content only with the same tags
      var is_same =
        this.friends_ids.length == this.delete_friends_ids.length &&
        this.friends_ids.every(
          await function (element) {
            let a = delete_friends_ids.indexOf(element);
            return element === delete_friends_ids[a];
          }
        );
      // console.log(is_same)
      if (is_same) {
        // if true .... Example:  11 @abd @hassn to 00 @abd @hassn
        this.delete_friends_ids = [];
        return this.delete_friends_ids;
      } else {
        // if there is any changes in tags array

        if (this.friends_ids.length == 0) {
          // if deleted all tags from new update comment .... Example:  11 @abd @hassn or another tags to 00
          return this.delete_friends_ids;
        } else if (this.friends_ids.length > this.delete_friends_ids.length) {
          // if there are new tags more than old tags
          // Example:  @abd @hassn to   @ abd @hassn @mohammd
          let a = this.delete_friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          if (result.length == this.delete_friends_ids.length) {
            // we save the old tags and add new tags so delete_friends_ids array must be null
            this.delete_friends_ids = [];
          } else {
            // changes in old tags and add new tags
            // delete from delete_friends_ids array the values that will be in new tags
            result.forEach((item) => {
              const index = this.delete_friends_ids.findIndex((x) => x == item);
              this.delete_friends_ids.splice(index, 1);
            });
          }
          return this.delete_friends_ids;
        } else if (this.friends_ids.length < this.delete_friends_ids.length) {
          // if there are new tags less than old tags
          // Example:  @abd @hassn @mohammd to   @ abd @hassn
          let a = this.friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.delete_friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          // delete from delete_friends_ids array the values that will be in new tags
          result.forEach((item) => {
            const index = this.delete_friends_ids.findIndex((x) => x == item);
            this.delete_friends_ids.splice(index, 1);
          });
          return this.delete_friends_ids;
        } else {
          // change one tag to anoter one tag but not same value .... Example:  @hassn to @abd
          // console.log("mmm")
          let a = this.delete_friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          // console.log(resultd)
          result.forEach((item) => {
            const index = this.delete_friends_ids.findIndex((x) => x == item);
            this.delete_friends_ids.splice(index, 1);
          });
          // console.log(this.delete_friends_ids)
          return this.delete_friends_ids;
        }
      }
    },

    async EditComment(id) {
      // console.log(this.editecommentinput)
      this.loadingStatusForEditeComment = true;
      let data = {
        comment_id: id,
      };
      if(this.IfdeleteImageFromCommentOrNot) {
        data.media = null
      }
      if(this.Commentmedia) {
        let storage = getStorage();
        let unique_number = uuid.v4() + this.media.name;
        // let storageRef = ref(storage, "CommentVoice/" + unique_number);
        let storageRef = ref(storage, "CommentImages/" + unique_number);
        let item = null;
        const uploadTask = uploadBytesResumable(storageRef,  this.media);
          uploadTask.on('state_changed', 
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              this.progressWidth = `${progress}`
            }, 
          );
          // Progress Bar
        await uploadBytes(storageRef, this.media)
          .then((snapshot) => {
            let meme = snapshot.metadata.contentType.split("/");
            item = {
              size: snapshot.metadata.size,
              media_type: 'Image',
              mime_type: meme[1],
              fullPath: snapshot.metadata.fullPath,
            };
            this.datasender = item;
          })
        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
          item.src_thum = url;
          item.src_icon = url;
        });
        data.media = this.datasender
      
      }

      if(this.editecommentinput && this.editecommentinput != "") {
          let result = await this.filterCommentOrReplyContent(this.editecommentinput);
          if (result.length != 0) {
            data.content = result;
          }
        }else {
            data.content = null
          }
      if (this.friends_ids.length > 0) {
        data.friends_ids = this.friends_ids;
      }else {
        data.friends_ids = []
      }

      // let result = await this.filterCommentOrReplyContent(
      //   this.editecommentinput
      // );

      // if (result.length != 0) {
      //   data.content = result;
      // }
      // if (this.friends_ids.length > 0) {
      //   data.friends_ids = this.friends_ids;
      // }
      await GroupPostServices.Update_Comment(data).then(
        (res) => {
          // if (status == 0) {
          //   // if you are update comment so update from parent
          //   this.$emit("EditeComment", res.data.data);
          // }
          this.$store.commit("groupComment/EditComment", res.data.UpdateGroupComment);
          // this.$store.commit("comment/EditComment", res.data.UpdateComment);
        }
      );
      this.loadingStatusForEditeComment = false;
      this.friends_ids = [];
      this.delete_friends_ids = [];
      this.media = null
      this.Commentmedia = null
      this.datasender = null
      this.IfdeleteImageFromCommentOrNot = false
      document
        .getElementById(`editecomment${id}`)
        .classList.remove("visually-hidden");
      document
        .getElementById(`inputeditecomment${id}`)
        .classList.add("visually-hidden");
    },
    cancle_update_comment(id) {
      document
        .getElementById(`editecomment${id}`)
        .classList.remove("visually-hidden");
      document
        .getElementById(`inputeditecomment${id}`)
        .classList.add("visually-hidden");
    },
    // Filter for mention friend in comment
    filterCommentOrReplyContent(content) {
      if (content.match("@[^@ \t\r\n]")) {
        // if content contains tags
        //
        var output = "";
        let array = content.split(" "); // split string to array dependence on " "
        // console.log(array)
        array.forEach((item) => {
          if (item !== "") {
            if (!item.startsWith("@")) {
              // if item does not start with @ so it is content
              output = output + item + " ";
            } else {
              // if item start with @ so it is tag

              this.friendstagsarray.forEach((friend) => {
                // selected id from friendstagsarray to any item start with @

                if (friend.value == item.substr(1)) {
                  output = output + "^@^ ";
                  this.friends_ids.push(friend.id);
                }
              });
              // filter array and remove duplicated values
              // if for any reason the user tage to the same driend more than one
              this.friends_ids = [...new Set(this.friends_ids)];
            }
          }
        });
        if (output.startsWith(" ")) {
          output = output.substring(1);
        }
        if (output.endsWith(" ")) {
          output = output.substring(0, output.length - 1);
        }
        return output; // return string from content contain tags and store tags id in friends_ids array
      } else {
        // content does not contain any tags
        return content;
      }
    },

    ShowEditeComment(id, content, tags) {
      // Hide display comment in card and show textarea with comment content to update
      if (this.editecommentid == null) {
        // console.log('id', id)
        if (tags.length == 0) {
          // content = content.replaceAll("^@^", "");
          content ? content = content.replaceAll("^@^", "") : '';
          this.editecommentinput = content;
        } else {
          if (content != null) {
            this.editecommentinput = content;
            tags.forEach((tag) => {
              this.editecommentinput = this.editecommentinput.replace(
                "^@^",
                " " + "@" + tag.name.replaceAll(" ", "_")
              );
            });
          } else {
            this.editecommentinput = "";
          }
        }
        this.$emit('editeditecommentid', id)
        document.getElementById(`editecomment${id}`) ? document.getElementById(`editecomment${id}`).classList.add("visually-hidden") : '';
          document.getElementById(`inputeditecomment${id}`) ? document.getElementById(`inputeditecomment${id}`).classList.remove("visually-hidden") : '';
      } else {
        // console.log('b',this.editecommentid)
        document.getElementById(`editecomment${this.editecommentid}`) ? document.getElementById(`editecomment${this.editecommentid}`).classList.remove("visually-hidden") : '';
        document.getElementById(`inputeditecomment${this.editecommentid}`) ? document.getElementById(`inputeditecomment${this.editecommentid}`).classList.add("visually-hidden") : '';

        // this.editecommentid = id;
        this.$emit('editeditecommentid', id)
        if (tags.length == 0) {
          content ? content = content.replaceAll("^@^", "") : '';
          this.editecommentinput = content;
        } else {
          if (content != null) {
            this.editecommentinput = content;
            tags.forEach((tag) => {
              this.editecommentinput = this.editecommentinput.replace(
                "^@^",
                " " + "@" + tag.name.replaceAll(" ", "_")
              );
            });
          } else {
            this.editecommentinput = "";
          }
        }
        document.getElementById(`editecomment${id}`) ? document.getElementById(`editecomment${id}`).classList.add("visually-hidden") : '';
        document.getElementById(`inputeditecomment${id}`) ? document.getElementById(`inputeditecomment${id}`).classList.remove("visually-hidden") : '';
      }
    },

    // ShowEditeComment(id, content, tags) {
    //   // Hide display comment in card and show textarea with comment content to update
    //   if (this.editecommentid == null) {
    //     if (tags.length == 0) {
    //       content = content.replaceAll("^@^", "");
    //       this.editecommentinput = content;
    //     } else {
    //       if (content != null) {
    //         this.editecommentinput = content;
    //         tags.forEach((tag) => {
    //           this.editecommentinput = this.editecommentinput.replace(
    //             "^@^",
    //             " " + "@" + tag.name.replaceAll(" ", "_")
    //           );
    //         });
    //       } else {
    //         this.editecommentinput = "";
    //       }
    //     }

    //     this.editecommentid = id;
    //     document
    //       .getElementById(`editecomment${id}`)
    //       .classList.add("visually-hidden");
    //     document
    //       .getElementById(`inputeditecomment${id}`)
    //       .classList.remove("visually-hidden");
    //   } else {
    //     document
    //       .getElementById(`editecomment${this.editecommentid}`)
    //       .classList.remove("visually-hidden");
    //     document
    //       .getElementById(`inputeditecomment${this.editecommentid}`)
    //       .classList.add("visually-hidden");

    //     this.editecommentid = id;
    //     if (tags.length == 0) {
    //       content = content.replaceAll("^@^", "");
    //       this.editecommentinput = content;
    //     } else {
    //       if (content != null) {
    //         this.editecommentinput = content;
    //         tags.forEach((tag) => {
    //           this.editecommentinput = this.editecommentinput.replace(
    //             "^@^",
    //             " " + "@" + tag.name.replaceAll(" ", "_")
    //           );
    //         });
    //       } else {
    //         this.editecommentinput = "";
    //       }
    //     }
    //     document
    //       .getElementById(`editecomment${id}`)
    //       .classList.add("visually-hidden");
    //     document
    //       .getElementById(`inputeditecomment${id}`)
    //       .classList.remove("visually-hidden");
    //   }
    // },

    toggleGroup() {
      this.isGroupRolledUp = !this.isGroupRolledUp;
    },
    clickReply() {
      this.isReplying = !this.isReplying;
      if (this.isReplying) {
        var self = this;
        setTimeout(function () {
          self.$refs.form.$refs.input.focus();
        }, 0);
      }
    },
    changeText(data) {
      this.body_reply = data;
    },
    emit(data) {
      this.$emit("event_child", data);
    },

    replyStore() {
      if (this.body_reply.trim() === "") return false;
      GroupPostServices.s_addReplyOnAComment(this.comment_id, {
        content: this.body_reply,
      })
        .then(() => {
          this.body_reply = "";
          this.isReplying = false;
          this.isGroupRolledUp = false;
        })
        .catch(() => {
          // this.$snotify.error("something is wrong");
          this.$snotify.error("something is wrong!", "Error");
        });
    },
    delete_comment(comment) {
      let data = {
        comment_id: comment.id
      }
      this.$store.dispatch("groupComment/a_delete_comment", data);
      this.$store.commit("singleGroup/Decrease_COUNT_COMMENT", comment);
      // payload.commentable_id = comment 
      if (this.postData.length != 0) {
          this.$store.commit(
            "post/Decrease_COUNT_COMMENT",
            comment
          );
        }
      this.$snotify.success(
        this.$i18n.t("Deleted successfully"),
        this.$i18n.t("Comment")
      );
    },
    showcontentComment(content, array) {
      if (content == null) {
        array.forEach((user) => {
          let a = `<a 
          href="/profile/${this.EncryptionRoute(user.id)}
          "
          ><p class="mb-1 me-1" 
                    style="cursor:pointer;font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
                  >
                    ${user.name.replaceAll(" ", "_")}
                  </p></a>`;

          content = content.replace("^@^", a);
          a = null;
        });
        // console.log("AFTER",content)
        content = content.replaceAll("^@^", "");
        return content;
      }
      if (array.length == 0) {
        return content;
      } else {
        array.forEach((user) => {
          let a = `<a 
          href="/profile/${this.EncryptionRoute(user.id)}
          "
          ><p class="mb-1 me-1" 
                    style="cursor:pointer; font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
                  >
                    ${user.name.replaceAll(" ", "_")}
                  </p></a>`;

          content = content.replace("^@^", a);
          a = null;
        });
        // console.log("AFTER",content)
        content = content.replaceAll("^@^", "");
        return content;
      }
    },
    // showcontentComment(content, array) {
    //   if (content == null) {
    //     array.forEach((user) => {
    //       let a = `<a 
    //       href="/profile/${user.user_id}
    //       "
    //       ><p class="mb-1 me-1" 
    //                 style="cursor:pointer;font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
    //               >
    //                 ${user.name.replaceAll(" ", "_")}
    //               </p></a>`;

    //       content = content.replace("^@^", a);
    //       a = null;
    //     });
    //     // console.log("AFTER",content)
    //     content = content.replaceAll("^@^", "");
    //     return content;
    //   }
    //   if (array.length == 0) {
    //     return content;
    //   } else {
    //     array.forEach((user) => {
    //       console.log(user.pivot.status);
    //       if (user.pivot.status == 1) {
    //         let a = `<a 
    //         href="/profile/${user.user_id}
    //         "
    //         ><p class="mb-1 me-1" 
    //                   style="cursor:pointer; font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
    //                 >
    //                   ${user.name.replaceAll(" ", "_")}
    //                 </p></a>`;

    //         content = content.replace("^@^", a);
    //         a = null;
    //       }
    //       if (user.pivot.status == 0) {
    //         let a = `<a 
    //         href="/profile/${user.user_id}
    //         "
    //         ><p class="mb-1 me-1" 
    //                   style="cursor:pointer; font-weight: bold;color: gray;display: inline-block;padding: 2px 4px;"
    //                 >
    //                 <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#858585">

    //                   <g id="SVGRepo_bgCarrier" stroke-width="2"/>

    //                   <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

    //                   <g id="SVGRepo_iconCarrier"> <path d="M5.63605 5.63603L18.364 18.364M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#6c6a6a" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </g>

    //                 </svg>
    //                 ${user.name.replaceAll(" ", "_")}
    //                 </p></a>`;

    //         content = content.replace("^@^", a);
    //         a = null;
    //       }
    //     });
    //     // console.log("AFTER",content)
    //     content = content.replaceAll("^@^", "");
    //     return content;
    //   }
    // },
  },
  computed: {
    ...mapGetters({
      friendstagsarray: "friendStore/friendstagsarray",
      Allfriendstagsarray: "friendStore/Allfriendstagsarray",
      USER: "auth/USER",
      postData: "post/postDataAuth",
    }),
    replyText: function () {
      if (this.isReplying) {
        return this.$i18n.t("Cancel reply");
      } else {
        return this.$i18n.t("Reply");
      }
    },
    toggleText: function () {
      if (this.isGroupRolledUp) {
        return this.$i18n.t("Replies");
      } else {
        return this.$i18n.t("hide");
      }
    },
    // replies: function () {
    //   return this.comment.replies.filter(
    //     (item) => item.parent_id === this.comment.id
    //   );
    // },
    ...mapState("comment", {
      interactionModel: "interactionModel",
    }),
  },

  components: {
    AddComment,
    ReplyComponent,
    // CommentReportComponent,
    DeleteSvg,
    // ReportPostSvg,
    UpdateComment,
    AddReactionToComment,
    Mentionable,
    EmojiSvg,
    EmojiPicker,
    AddMediaComment
  },
};
</script>

<style lang="scss" scoped>
@import "floating-vue/dist/style.css";
.tagnameinshow {
  background-color: #d5d2d2d2;
  font-weight: bold;
  color: blue;
  display: inline-block;
  padding: 2px 4px;
}
.allfriendsbox {
  background-color: white !important;
  padding: 0.5rem !important;
  cursor: pointer;
  &:hover {
    background-color: rgba(60, 60, 60, 0.856) !important;
    color: white !important;
  }
}

.pointer {
  cursor: pointer;
}

.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0;
  background: transparent;
  border: 0;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}

.v3-emoji-picker {
  position: absolute;
  margin-top: 46px;
  z-index: 1;
  right: 2rem;
}
.v3-emoji-picker:lang(ar),
.v3-emoji-picker:lang(ku),
.v3-emoji-picker:lang(ur)
 {
  position: absolute;
  margin-top: 46px;
  z-index: 1;
  left: 0 !important;
}
span.emoji-visible {
  position: relative;
  float: right;
  margin: -43px 10px;
  cursor: pointer;
}
.commentsvgbox, .audoicontrolcommentsvgbox {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  right: 1rem;
}
.commentsvgboxar, .audoicontrolcommentsvgboxar {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  left: 1rem;
}

@import "@/Styles/Comments/comment/comment.scss";
</style>
