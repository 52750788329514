import { PARENT_DATA } from "@/graphql/Fragments/posts/ParentData";
import { POST_DATA } from "@/graphql/Fragments/posts/PostData";
import gql from "graphql-tag";

export const POST_BY_ID = gql`
  ${POST_DATA}
  ${PARENT_DATA}
  query PostByIDQuery($post_id: Int!) {
    PostByIDQuery(post_id: $post_id) {
      ...PostData
      answersCount
      parent {
        ...ParentData
      }
    }
  }
`;
