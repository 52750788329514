<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="arrow"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path
      d="M12 15.5l-6.71-6.71a1 1 0 0 1 1.42-1.42L12 12.67l5.29-5.29a1 1 0 0 1 1.42 1.42z"
    />
  </svg>
</template>
<style lang="scss" scoped>
.arrow {
  fill: #007bff;
}

.arrow:hover {
  fill: #0056b3;
}
</style>
