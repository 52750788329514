<template>
  <div class="box" dir="auto">
    <!-- SideBar -->
    <div class="side_bar">
      <div class="px-3">
        <button
          type="button"
          class="btn-close mb-3"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
          aria-label="Close"
        ></button>
      </div>
      <p class="pt-3 px-3">{{ $t("Add Reel") }}</p>
      <h5 class="px-3 fw-bold" ref="titleboxofvideo">
        {{ $t("Upload Video") }}
      </h5>
      <div class="boxofvideo" ref="boxofvideo">
        <div class="innerofboxvideo">
          <label for="reelsupload" class="playicon">
            <font-awesome-icon class="icon" icon="fa-solid fa-video" />
          </label>
          <p class="fw-bold mb-0">{{ $t("Upload Video") }}</p>
          <input
            type="file"
            id="reelsupload"
            ref="reelsfile"
            @change="
              uploadFileReels(
                this.media,
                this.postsmedia,
                this.errorinvideoreelsduration
              )
            "
            hidden
            accept="video/*"
          />
        </div>
      </div>

      <!-- Add Deescription and hashtags to reels -->

      <div class="px-3 hide" ref="textareafordesc">
        <h5 class="fw-bold">{{ $t("Add Description") }}</h5>
        <textarea
          :readonly="loading"
          class="input_text"
          v-model="content"
          name="content"
          id=""
          cols="30"
          rows="5"
          :placeholder="$t(`what are you thinking about?`)"
        ></textarea>
        <h5 class="fw-bold mt-3">{{ $t("Add HashTags") }}</h5>
        <input
          :readonly="loading"
          type="text"
          @keypress.enter.prevent="addHashtag()"
          class="input_text-field"
          v-model="hashtag"
          :placeholder="$t(`Press Enter To Add Hashtag`)"
        />
        <div class="mt-3">
          <div
            v-for="(item, index1) in allhashtags"
            class="mx-1"
            :key="index1"
            style="position: relative; display: inline-block"
          >
            <p
              style="
                padding: 3px 15px;
                border: 1px solid #777;
                border-radius: 15px;
              "
            >
              {{ item }}
            </p>
            <button
              :disabled="loading"
              type="button"
              class="btn-close"
              aria-label="Close"
              style="
                position: absolute;
                top: -5px;
                right: 0px;
                width: 5px;
                height: 5px;
                cursor: pointer;
              "
              @click="deleteitemfromhashtags(index1)"
            ></button>
          </div>
        </div>
      </div>

      <!-- Add Deescription and hashtags to reels -->
      <div class="progressall">
        <div v-if="!(errorinvideoreelsduration.length == 0)">
          <p class="mb-2 text-center" style="color: red">
            {{ errorinvideoreelsduration[0] }}
          </p>
        </div>
        <div class="d-flex justify-content-between boxprogress">
          <p class="span1" ref="span1"></p>
          <p class="span2" ref="span2"></p>
        </div>
        <div class="d-flex justify-content-between px-1">
          <!-- <span class="but">Next</span> -->
          <button
            class="but btn"
            :disabled="
              media.length == 0 ||
              errorinvideoreelsduration.length != 0 ||
              this.mediaCanvas.length == 0
            "
            ref="click1"
            @click="click1()"
          >
            {{ $t("Next") }}
          </button>
          <button
            :disabled="loading"
            class="btn hide"
            ref="priveous"
            @click="priveous()"
            style="background-color: #e4e6eb; color: black; width: 49%"
          >
            {{ $t("previous") }}
          </button>
          <button
            class="btn hide btn-primary"
            @click="click2()"
            ref="click2"
            style="width: 49%"
          >
            <span v-if="!loading"> {{ $t("Post1") }}</span>
            <span v-else>{{ $t("loading....") }}</span>
          </button>
        </div>
      </div>
    </div>
    <!-- SideBar -->
    <!-- //////////////////////////////////////////////////////////////////// -->
    <!-- Content -->
    <div class="content">
      <div class="show">
        <p class="mb-0 pt-1 px-3">{{ $t("preview") }}</p>
        <div class="innerbox" v-if="postsmedia.length == 0">
          <p class="fs-5 text-muted mb-0">{{ $t("preview your video") }}</p>
          <p>{{ $t("You can add video to preview here") }}</p>
        </div>
        <div class="position-relative h-100" v-else>
          <div class="buttons hide" ref="buttonsall">
            <div class="buttons_box">
              <font-awesome-icon
                class="icon"
                style="color: #e4e6ea"
                icon="fa-solid fa-thumbs-up"
              />
            </div>
            <p class="text-white mt-1 px-2 mb-2">0</p>
            <div class="buttons_box">
              <font-awesome-icon
                class="icon"
                style="color: #e4e6ea"
                icon="fa-solid fa-message"
              />
            </div>
            <p class="text-white px-2 mt-1 mb-2">0</p>
            <div class="buttons_box">
              <font-awesome-icon
                class="icon"
                style="color: #7f7f7f"
                icon="fa-solid fa-share"
              />
            </div>
          </div>
          <div
            v-for="(img, index) in postsmedia"
            :key="index"
            class="content-box"
          >
            <div class="row m-3">
              <div class="col-4">
                <div class="do-style">
                  <div>
                    <video crossorigin="anonymous"  id="reelsupload2" :src="img.url" controls></video>
                    <br />

                    <canvas
                      id="canvas"
                      ref="fileCanvas"
                      style="
                        height: 1px;
                        width: 1px;
                        overflow: auto;
                        clip-path: circle(20px at 10px 10px);
                      "
                    ></canvas>
                  </div>
                </div>
              </div>

              <div class="col-4">

                <div class="do-style" v-for="(img, index) in postsmediaCanvas" :key="index">
                  <img crossorigin="anonymous"  id="reelsupload2" :src="img.url" title="Cover of video" />
                </div>
              </div>
              <b style="color: red">{{
                $t("select Frame of video until put it as cover for ttop")
              }}</b>
              <button
                class="btn btn-primary m-2"
                @click="select_Cover_for_ttop()"
              >
                {{ $t("Select an Cover Image For T-Top") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- content -->

    <!-- Modal -->
    <!-- Modal -->
    <div
      class="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">
              {{ $t("Do you want to leave the page") }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p
              v-if="
                postsmedia.length > 0 ||
                allhashtags.length > 0 ||
                content != null
              "
            >
              {{
                $t(
                  "You have unsaved changes that will be lost if you leave the page"
                )
              }}
            </p>
            <p v-else>{{ $t("Are You Sure") }}</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              data-bs-dismiss="modal"
            >
              Follow up on the modification
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              @click="goto()"
            >
              leaving
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
  </div>
</template>

<script>
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { uuid } from "vue-uuid";
import ReelsService from "@/services/Reels/ReelsServices";
export default {
  name: "AddReels",
  data() {
    return {
      media: [],
      postsmedia: [],
      errorinvideoreelsduration: [],
      hashtag: null,
      allhashtags: [],
      content: null,
      datareelssender: {},
      loading: false,
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
      mediaCanvas: [],
      postsmediaCanvas: [],
    };
  },
  methods: {
    select_Cover_for_ttop() {
      this.mediaCanvas = [];
      this.postsmediaCanvas = [];
      var canvas = document.getElementById("canvas");
      var video = document.getElementById("reelsupload2");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

      const createImage = new Image();
      createImage.src = canvas.toDataURL("image/jpeg");
      createImage.onload = () => {
        const startX = video.videoWidth / 2 - createImage.width / 2;
        const startY = video.videoHeight / 2 - createImage.height / 2;
        canvas
          .getContext("2d")
          .drawImage(
            createImage,
            startX,
            startY,
            createImage.width,
            createImage.height
          );
        var blobBin = atob(createImage.src.split(",")[1]);
        var array = [];
        for (var i = 0; i < blobBin.length; i++) {
          array.push(blobBin.charCodeAt(i));
        }
        var file = new Blob([new Uint8Array(array)], { type: "image/jpeg" });
        const blobUrl = URL.createObjectURL(file);
        const file2 = new File([new Uint8Array(array)], "image.jpeg", {
          type: "image/jpeg",
        });
        this.mediaCanvas.push(file2);
        this.postsmediaCanvas.push({
          type: file.type,
          url: blobUrl,
        });
      };
    },

    async uploadFileReels(media, postsmedia, errorinvideoreelsduration) {
      var el = this.$refs.span1;
      var click1 = this.$refs.click1;
      el.classList.remove("active");
      click1.classList.remove("btn-primary");

      var vid = document.createElement("video");
      var fileURL = URL.createObjectURL(this.$refs.reelsfile.files[0]);
      vid.src = fileURL;
      let fileslength = this.$refs.reelsfile.files.length;
      let files = this.$refs.reelsfile.files;
      vid.ondurationchange = await function () {
        if (vid.duration <= 60) {
          postsmedia.pop();
          media.pop();
          errorinvideoreelsduration.pop();
          for (let i = 0; i < fileslength; i++) {
            media.push(files[i]);
            postsmedia.push({
              type: files[i].type,
              url: URL.createObjectURL(files[i]),
            });
          }
          el.classList.add("active");
          click1.classList.add("btn-primary");
        } else {
          postsmedia.pop();
          for (let i = 0; i < fileslength; i++) {
            postsmedia.push({
              type: files[i].type,
              url: URL.createObjectURL(files[i]),
            });
          }
          errorinvideoreelsduration.pop();
          errorinvideoreelsduration.push(
            "Video Must Be Less Or Equal Than 60 Seconds"
          );
        }
      };
    },

    click1() {
      this.$refs.boxofvideo.classList.add("hide");
      this.$refs.titleboxofvideo.classList.add("hide");
      this.$refs.span2.classList.add("active");
      this.$refs.buttonsall.classList.remove("hide");
      this.$refs.textareafordesc.classList.remove("hide");
      this.$refs.click1.classList.add("hide");
      this.$refs.priveous.classList.remove("hide");
      this.$refs.click2.classList.remove("hide");
    },

    priveous() {
      this.$refs.priveous.classList.add("hide");
      this.$refs.click2.classList.add("hide");
      this.$refs.textareafordesc.classList.add("hide");
      this.$refs.span2.classList.remove("active");
      this.$refs.boxofvideo.classList.remove("hide");
      this.$refs.titleboxofvideo.classList.remove("hide");
      this.$refs.click1.classList.remove("hide");
    },

    async click2() {
      this.loading = true;

      let item = null;
      for (let k = 0; k < this.media.length; k++) {
        let storage = getStorage();
        let unique_number = this.media[k].name + uuid.v4();
        let storageRef = ref(storage, "webTtop/" + unique_number);
        await uploadBytes(storageRef, this.media[k])
          .then((snapshot) => {
            let meme = snapshot.metadata.contentType.split("/");
            item = {
              size: snapshot.metadata.size,
              media_type: "Video",
              mime_type: meme[1],
              fullPath: snapshot.metadata.fullPath,
            };
          })
          .catch((error) => {
            this.$snotify.error(error.message, "Error");
          });
        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
        });
        this.datareelssender.video = item;
      }

      //   upload image Cover for Ttop
      for (let k = 0; k < this.mediaCanvas.length; k++) {
        let storage2 = getStorage();
        let unique_number2 = this.mediaCanvas[k].name + uuid.v4();
        let storageRef2 = ref(storage2, "webTtop/" + unique_number2);
        await uploadBytes(storageRef2, this.mediaCanvas[k]).catch(() => {
          this.$snotify.error("Server Error!", "web site");
        });
        await getDownloadURL(storageRef2).then((url) => {
          item.src_thum = url;
        });
        this.datareelssender.video = item;
      }

      try {
        let data = {
          content: this.content,
          hashtags: this.allhashtags,
        };
        if (!this.page) {
          await ReelsService.AddNewReels(this.datareelssender, data).then(
            (response) => {
              this.content = null;
              this.media = [];
              this.mediaCanvas = [];
              this.postsmedia = [];
              this.datasender = [];
              this.datareelssender = {};
              this.isloading = null;
              this.hashtag = null;
              this.allhashtags = [];
              this.errorinvideoreelsduration = [];
              this.$router.push({
                name: "ReelsView",
                params: { id: response.data.data.id },
              });
              this.loading = false;
            }
          );
        } else {
          data.page_id = this.page.id;
          await ReelsService.AddNewReels(this.datareelssender, data).then(
            (response) => {
              this.content = null;
              this.media = [];
              this.postsmedia = [];
              this.datasender = [];
              this.datareelssender = {};
              this.isloading = null;
              this.hashtag = null;
              this.allhashtags = [];
              this.errorinvideoreelsduration = [];
              this.$router.push({
                name: "ReelsView",
                params: { id: response.data.data.id },
              });
              this.loading = false;
            }
          );
        }
        this.$snotify.success("Added New T-top successfully", "Ttop");
      } catch (error) {
        this.content = null;
        this.media = [];
        this.mediaCanvas = [];
        this.postsmedia = [];
        this.datasender = [];
        this.datareelssender = {};
        this.isloading = null;
        this.hashtag = null;
        this.allhashtags = [];
        this.errorinvideoreelsduration = [];
        this.$snotify.error(error.message, "Error");
        this.loading = false;
      }
    },

    addHashtag() {
      this.allhashtags.push(this.hashtag);
      this.hashtag = null;
    },

    deleteitemfromhashtags(index) {
      this.allhashtags.splice(index, 1);
    },

    goto() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style lang="scss" scoped>
.hide {
  display: none;
}

textarea {
  border-radius: 5px;
  padding: 0.5rem 1rem;

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

input {
  width: 96%;
  margin-top: 1rem;
  border-radius: 5px;
  padding: 0.5rem 1rem;

  &:focus {
    box-shadow: none;
    outline: none;
  }
}
.block {
  display: block !important;
}

.box {
  height: calc(100vh - 79px);
  display: flex;
  justify-content: space-between;
  background-color: #e9ebee;
}
.side_bar,
.content {
  height: 100%;
}

.side_bar {
  background-color: white;
  width: 25%;
  position: relative;

  .boxofvideo {
    width: 95%;
    height: 300px;
    margin: 0 auto;
    border: 1px solid #77777797;
    border-radius: 5px;
    position: relative;

    .innerofboxvideo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      // cursor: pointer;

      .playicon {
        background-color: #e4e6eb;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        justify-content: center;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  .progressall {
    background-color: white;
    position: absolute;
    left: 0;
    bottom: 2rem;
    width: 99%;
    // width: 50% !important;
    .boxprogress {
      width: 100%;
      padding: 0 1rem;
      .span1,
      .span2 {
        width: 48%;
        height: 10px;
        border-radius: 15px;
        background-color: #f0f2f5;
      }
      .active {
        background-color: #1b74e4 !important;
        color: white !important;
      }
    }

    .but {
      margin: 0 auto;
      width: 90%;
      padding: 0.2rem 0;
      text-align: center;
      cursor: pointer;
    }
  }
}

.content {
  background-color: #ebedf0;
  width: 74%;
  // display: flex;
  position: relative;

  .show {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    height: 90%;
    width: 90%;
    background-color: white;
  }
  .innerbox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    border: 2px solid #e5e5e5;
    // box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    height: 85%;
    width: 95%;
    background-color: #f0f2f5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    border: 2px solid #e5e5e5;
    height: 98%;
    width: 98%;
    background-color: #1c1c1c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    video {
      object-fit: contain;
      width: 100%;
      height: 350px;
      border: 1px solid white;
      border-radius: 12px;
    }
    img {
      object-fit: contain;
      width: 100%;
      height: 350px;
      border: 1px solid white;
      border-radius: 12px;
    }
  }
  .do-style {
    max-height: 350px;
    width: 100% !important;
    overflow: hidden;
    background: #000;
    border-radius:11px;
  }
  .content-box-image-cover {
    position: absolute;
    top: 150%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    border: 2px solid #e5e5e5;
    height: 85%;
    width: 95%;
    background-color: #1c1c1c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      height: 90% !important;
      width: 40%;
      border: 1px solid white;
      border-radius: 12px;
    }
  }
  .buttons {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    z-index: 5;

    .buttons_box {
      background-color: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 2rem;
      height: 2rem;
    }
  }
}
</style>
