<template>
  <div class="dropstart" style="cursor: pointer">
    <a class="dropdown" data-bs-toggle="dropdown" aria-expanded="false">
      <MoreSvg></MoreSvg>
    </a>
    <ul class="dropdown-menu">
      <!-- {{ post }} -->
      <li
        v-if="
          (location == 1 && this.USER.id != post.model.id) ||
          (location == 3 && !singlePage.has_role) ||
          (location == 2 && this.USER.id != post.model.id)
        "
        @click="addbehaviour(post.id, 1)"
        class="dropdown-item pro guest d-flex justify-content-start align-items-center"
        :dir="!DefineDeriction() ? 'ltr' : 'rtl'"
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 32 32"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
          fill="#000000"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <title>plus-circle</title>
            <desc>Created with Sketch Beta.</desc>
            <defs></defs>
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
              sketch:type="MSPage"
            >
              <g
                id="Icon-Set-Filled"
                sketch:type="MSLayerGroup"
                transform="translate(-466.000000, -1089.000000)"
                fill="#000000"
              >
                <path
                  d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z"
                  id="plus-circle"
                  sketch:type="MSShapeGroup"
                ></path>
              </g>
            </g>
          </g>
        </svg>
        <div class="d-flex mx-2 flex-column">
          <span>{{ $t("Show more") }}</span>
          <small>{{ $t("More of your posts will be like that.") }}</small>
        </div>
      </li>
      <li
        v-if="
          (location == 1 && this.USER.id != post.model.id) ||
          (location == 1 && !singlePage.has_role) ||
          (location == 3 && !singlePage.has_role) ||
          (location == 2 && this.USER.id != post.model.id)
        "
        @click="addbehaviour(post.id, 0)"
        class="dropdown-item pro guest d-flex justify-content-start align-items-center"
        :dir="!DefineDeriction() ? 'ltr' : 'rtl'"
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 32 32"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
          fill="#000000"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <title>minus-circle</title>
            <desc>Created with Sketch Beta.</desc>
            <defs></defs>
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
              sketch:type="MSPage"
            >
              <g
                id="Icon-Set-Filled"
                sketch:type="MSLayerGroup"
                transform="translate(-518.000000, -1089.000000)"
                fill="#000000"
              >
                <path
                  d="M540,1106 L528,1106 C527.447,1106 527,1105.55 527,1105 C527,1104.45 527.447,1104 528,1104 L540,1104 C540.553,1104 541,1104.45 541,1105 C541,1105.55 540.553,1106 540,1106 L540,1106 Z M534,1089 C525.163,1089 518,1096.16 518,1105 C518,1113.84 525.163,1121 534,1121 C542.837,1121 550,1113.84 550,1105 C550,1096.16 542.837,1089 534,1089 L534,1089 Z"
                  id="minus-circle"
                  sketch:type="MSShapeGroup"
                ></path>
              </g>
            </g>
          </g>
        </svg>
        <div class="d-flex mx-2 flex-column">
          <span>{{ $t("Show less") }}</span>
          <small>{{ $t("Less of your posts will be like that.") }}</small>
        </div>
      </li>

      <!-- Edit Button In Page -->
      <li
        v-if="location == 3 && singlePage.has_role && ![6, 9].includes(post.type)"
        class="dropdown-item pro guest"
        data-bs-toggle="modal"
        :data-bs-target="'#staticEdite' + post.id"
        :dir="!DefineDeriction() ? 'ltr' : 'rtl'"
      >
        <img crossorigin="anonymous" 
          src="@/assets/images/edit_mode_icon.png"
          style="width: 20px; height: 20px"
          alt=""
          class="for-lang"
        />
        {{ $t("Edit Post") }}
      </li>
      <li
        v-else-if="location == 3 && singlePage.has_role && post.type == 6"
        class="dropdown-item pro guest"
        data-bs-toggle="modal"
        :data-bs-target="'#updateNewQuotePage' + post.id"
        :dir="!DefineDeriction() ? 'ltr' : 'rtl'"
      >
        <img crossorigin="anonymous" 
          src="@/assets/images/edit_mode_icon.png"
          style="width: 20px; height: 20px"
          alt=""
          class="for-lang"
        />
        {{ $t("Edit Post") }}
      </li>
      <li
        v-else-if="location == 3 && singlePage.has_role && post.type == 9"
        class="dropdown-item pro guest"
        data-bs-toggle="modal"
        :data-bs-target="'#updateNewPoll' + post.id"
        :dir="!DefineDeriction() ? 'ltr' : 'rtl'"
      >
        <img crossorigin="anonymous" 
          src="@/assets/images/edit_mode_icon.png"
          style="width: 20px; height: 20px"
          alt=""
          class="for-lang"
        />
        {{ $t("Edit Post") }}
      </li>

      <!-- Edit Button -->
      <li
        v-if="USER.profile.id == post.model.id && location == 2 && ![6, 9].includes(post.type)"
        class="dropdown-item pro guest"
        @click="GetPostDetailForEdit(post.id)"
        :dir="!DefineDeriction() ? 'ltr' : 'rtl'"
      >
        <img crossorigin="anonymous" 
          src="@/assets/images/edit_mode_icon.png"
          style="width: 20px; height: 20px"
          alt=""
          class="for-lang"
        />
        {{ $t("Edit Post") }}
      </li>
      <!-- Edit Button -->
      <li
        v-if="
          ((USER.profile.id == post.model.id) && location == 2 && post.type == 6)
        "
        class="dropdown-item pro guest"
        data-bs-toggle="modal"
        :data-bs-target="'#updateNewQuotePage' + post.id"
        :dir="!DefineDeriction() ? 'ltr' : 'rtl'"
      >
        <img crossorigin="anonymous" 
          src="@/assets/images/edit_mode_icon.png"
          style="width: 20px; height: 20px"
          alt=""
          class="for-lang"
        />
        {{ $t("Edit Post") }}
      </li>
      <li
        v-if="
          ((USER.profile.id == post.model.id) && location == 2 && post.type == 9)
        "
        class="dropdown-item pro guest"
        data-bs-toggle="modal"
        :data-bs-target="'#updateNewPoll' + post.id"
        :dir="!DefineDeriction() ? 'ltr' : 'rtl'"
      >
        <img crossorigin="anonymous" 
          src="@/assets/images/edit_mode_icon.png"
          style="width: 20px; height: 20px"
          alt=""
          class="for-lang"
        />
        {{ $t("Edit Post") }}
      </li>

      <!-- Delete Button -->
      <li
        v-if="
          (this.USER.profile.id == post.model.id &&
            (location == 1 || location == 2)) ||
          (location == 3 && singlePage.has_role)
        "
        class="dropdown-item pro guest"
        @click="SetDeletePostId(post.id)"
        data-bs-toggle="modal"
        :data-bs-target="'#staticDelete'"
        :dir="!DefineDeriction() ? 'ltr' : 'rtl'"
      >
        <DeleteSvg></DeleteSvg>
        {{ $t("Delete") }}
      </li>

      <!-- Report Post -->
      <li
        class="dropdown-item pro guest"
        @click="SetDeletePostId(post.id)"
        data-bs-toggle="modal"
        :data-bs-target="'#staticPostReport'"
        v-if="
          (this.USER.id != post.model.id && location == 1) ||
          (this.USER.id != post.model.id && location == 2) ||
          (location == 3 && !singlePage.has_role)
        "
        :dir="!DefineDeriction() ? 'ltr' : 'rtl'"
      >
        <ReportPostSvg></ReportPostSvg>
        {{ $t("Report a post") }}
      </li>

      <!-- Save Post -->
      <li
        v-if="post.saved == false"
        class="dropdown-item pro guest"
        @click="SetDeletePostId(post.id)"
        data-bs-toggle="modal"
        :data-bs-target="'#staticSelectCollection'"
        :dir="!DefineDeriction() ? 'ltr' : 'rtl'"
      >
        <SaveSvg></SaveSvg>
        {{ $t("Save Post") }}
      </li>

      <!-- Cancle Save -->
      <li
        v-if="post.saved == true"
        class="dropdown-item pro guest"
        @click="cancel_save()"
        :dir="!DefineDeriction() ? 'ltr' : 'rtl'"
      >
        <CancelSave></CancelSave>
        {{ $t("Cancel Save") }}
      </li>

      <!-- Remove Tag -->
      <li
        v-if="post.taged == true"
        class="dropdown-item pro guest"
        @click="remove_tag(post.id)"
        :dir="!DefineDeriction() ? 'ltr' : 'rtl'"
      >
        <CancelSave></CancelSave>
        {{ $t("Remove Tag") }}
      </li>
    </ul>
    <!-- For Edit Post Page -->
    <EditPoll v-if="post.type == 9" :location="location" :post="post"></EditPoll>
    <EditPostQuote v-if="post.type == 6" :location="location" :post="post"></EditPostQuote>
    <EditePostComponent v-else :post="post" />
  </div>
</template>
<script>
import CancelSave from "@/components/OtherComponent/SVG/CancelSave.vue";
import DeleteSvg from "@/components/OtherComponent/SVG/DeleteSvg.vue";
import MoreSvg from "@/components/OtherComponent/SVG/MoreSvg.vue";
import ReportPostSvg from "@/components/OtherComponent/SVG/ReportPostSvg.vue";
import SaveSvg from "@/components/OtherComponent/SVG/SaveSvg.vue";
import postServices from "@/services/HomePage/post.services";
import SaveServices from "@/services/HomePage/Save/SaveServices";
import ProfileService from "@/services/Profile/ProfileService";
import EditePostComponent from "../EditePostComponent.vue";
import EditPostQuote from "@/Elements/post/editPostForm/EditPostQuote.vue";
import EditPoll from "@/Elements/post/editPostForm/poll/EditPoll.vue";
import { mapActions, mapGetters } from "vuex";
import LangMixin from "@/mixins/LangMixin";
import { useRoute } from "vue-router";

export default {
  props: ["post", "USER", "location"],
  data() {
    return {
      route: useRoute(),

      isloading: null,
      formSave: {
        item_id: this.post.id,
        collection_id: -1,
      },
    };
  },
  mixins: [LangMixin],
  computed: {
    ...mapGetters({
      singlePage: "singlePage/singlePage",
    }),
  },
  methods: {
    ...mapActions({
      GET_ALL_Reports_On_Post_Or_Comment: "report/GET_ALL_Reports_On_Post_Or_Comment",
    }),
    SetDeletePostId(id){
      this.$store.commit("SetSelectedPostId", id);
      this.GET_ALL_Reports_On_Post_Or_Comment();
      this.$store.dispatch("collections/a_Get_UserCollections")
    },
    async GetPostDetailForEdit(id) {
      this.$emit("GetPostDetailForEdit", id);
    },
    remove_tag(id) {
      ProfileService.s_remove_tag_friend(id).then(() => {
        this.$store.commit("profile/DELETE_REVIEW_POST", id);

        this.$snotify.success(
          this.$i18n.t("The Tag has been removed successfully"),
          this.$i18n.t("Post")
        );
      });
    },
    cancel_save() {
      try {
        SaveServices.cancel_Save({
        item_id: this.post.id,
        item_type: "Post",
      }).then(() => {
        if(this.route.fullPath.includes('pages')) {
            this.$store.commit("singlePage/UPDATE_STATUS_TO_SAVE_POST", this.post.id);
          }else if(this.route.fullPath.includes('profile')) {
            this.$store.commit("profile/UPDATE_STATUS_TO_SAVE_POST", this.post.id);
          }else {
            this.$store.commit("post/UPDATE_STATUS_TO_SAVE_POST", this.post.id);
          }
      });
      } catch (error) {
        this.$snotify.error(
            this.$i18n.t("Error"),
            this.$i18n.t("Post")
          );
      }
      
    },
    savePost() {
      SaveServices.savePost(this.formSave).then(() => {
        this.$store.commit(
          "post/UPDATE_STATUS_TO_CANCEL_SAVE_POST",
          this.post.id
        );
      });
    },
    addbehaviour(post_id, type) {
      let data = {
        post_id: post_id,
        type: type,
      };
      postServices.addbehaviour(data);
    },
  },

  components: {
    EditPoll,
    EditPostQuote,
    DeleteSvg,
    MoreSvg,
    ReportPostSvg,
    SaveSvg,
    CancelSave,
    EditePostComponent,
  },
};
</script>

<style lang="scss" scoped>
//
.router-link-to-profile {
  text-decoration: none !important;
  color: #111;
}

.router-link-to-profile.active_link {
  color: #111 !important;
  border: none !important;
  font-weight: normal !important;
}
.btn_comment_style {
  background: white;
  border: 0px;
  color: #111;
}

.row {
  .card {
    margin-bottom: 0px;
  }

  .card_post {
    padding: 0 0rem 0 0;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    .card-header {
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }

    .card-body {
      padding: 0;
      overflow: hidden;

      p {
        padding: 0px 20px;
      }

      .media_container {
        max-height: 500px;
        width: 100% !important;
        overflow: hidden;
        background: #ccc;
        .media_one {
          max-height: 100% !important;
          width: 100% !important;
          // height: 100% !important;
          overflow: hidden;
          text-align: center;

          img {
            width: 100% !important;
            height: 50% !important;
            object-fit: cover;
          }

          video {
            object-fit: cover;
            width: 100%;
            height: 450px;
          }
        }

        .media_two {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_two_box {
            width: 50%;
            height: 100% !important;
            overflow: hidden;

            img {
              width: 415px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 100% !important;
              height: 235px;
              object-fit: contain;
            }
          }
        }

        .media_three {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_three_box {
            width: 50%;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 375px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 410px;
              margin: auto;
              display: block;
              height: 248px;
              object-fit: cover;
            }
          }

          .media_three_box_under {
            width: 100%;
            margin: auto;
            display: block;
            height: 248px;
            overflow: hidden;

            img {
              width: 332px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .media_four {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_four_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 410px;
              margin: auto;
              display: block;
              height: 290px;
            }

            video {
              width: 410px;
              margin: auto;
              display: block;
              height: 248px;
              object-fit: cover;
            }
          }
        }

        .media_bigger {
          height: 100%;
          width: 100% !important;
          overflow: hidden;
          position: relative;

          .media_bigger_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100% !important;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }

          .bigger_p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-weight: bolder;
            font-size: 20px;
            width: 100px;
            height: 100px;
            padding: 0 !important;
            background-color: rgba(119, 119, 119, 0.699);
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }

      .body_box {
        margin-top: 1.5rem !important;
        padding: 0 2rem;
        margin-bottom: 0.5rem;
      }
      .shared {
        padding: 0px 3.2rem;
      }
    }

    .card-footer {
      small {
        margin-left: 0.5rem;
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items:lang(ar) {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            margin: 0 -160px;
            direction: rtl;
            span {
              margin: 0 5px;
            }
          }
          .box-reaction-items:lang(ku) {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            margin: 0 -160px;
            direction: rtl;
            span {
              margin: 0 5px;
            }
          }
          .box-reaction-items {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            span {
              margin: 0 5px;
              // margin: 0px -110px;
            }
          }
        }
      }

      .like {
        color: blue !important;
        font-weight: bold !important;
      }
      .love {
        color: red !important;
        font-weight: bold !important;
      }

      .angry {
        color: green !important;
        font-weight: bold !important;
      }

      .HaHaHa {
        color: #ffdf00 !important;
        font-weight: bold !important;
      }

      .Sad {
        color: black !important;
        font-weight: bold !important;
      }

      .WOW {
        color: rgb(64, 11, 211) !important;
        font-weight: bold !important;
      }

      .Care {
        color: rgb(236, 10, 161) !important;
        font-weight: bold !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .card-footer {
    small {
      margin-left: 1rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -100% !important;
          width: auto !important;
          span {
            margin: 0 5px;
            font-size: 10px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .div {
    padding: 0 0.5rem !important;
    .row {
      margin: 0 0 1rem 0 !important;

      .card-header {
        p {
          font-size: 0.8rem !important;
        }

        .right :nth-child(1),
        .right :nth-child(2) {
          margin-right: 0.3rem !important;
        }
      }

      .card-header {
        img {
          width: 40px !important;
          height: 40px !important;
          border-radius: 50%;
        }
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items {
            display: inline-block !important;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.548);
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -40px;
            left: 50%;
            width: 50px !important;
            span {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .card-footer {
    small {
      margin-left: 0.2rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -150% !important;
          width: auto !important;
          span {
            margin: 0 3px !important;
            font-size: 2px !important;
          }
        }
      }
    }
  }
}
.vcomments__info:lang(ar) {
  direction: rtl;
}
// start
.img-reaction {
  width: 40px;
  transition: 0.2s ease;
}
.img-reaction:hover {
  transform: scale(1.5);
  cursor: pointer;
}
.care-img {
  width: 23px;
  margin: 11px;
}

.body_box:lang(ar) {
  flex-direction: row-reverse;

  div {
    direction: rtl !important;
  }
}

.card-footer:lang(ar) {
  flex-direction: row-reverse;

  .addreaction {
    .box-reaction-items {
      display: none;
    }
    cursor: pointer;
    position: relative;

    &:hover {
      .box-reaction-items {
        display: inline-flex !important;
        position: absolute;
        background-color: white;
        color: white;
        padding: 10px;
        border-radius: 5px;
        top: -60px !important;
        left: -100% !important;
        width: auto !important;
        span {
          margin: 0 5px;
        }
      }
    }
  }
}

.dropstart .dropdown-menu {
  right: auto;
  left: 100%;
  cursor: pointer;
}

a.dropdown-item.pro.guest {
  padding: 0px 0px 10px 0px;
  font-size: 14px;
}
.dropdown-item.pro.guest:lang(ar) {
  text-align: right !important;
}
.vcomments__add-input:lang(ar) {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  // border: 1px solid #eee;
  padding: 10px 17px 10px 15px;
  border-radius: 40px;
  transition: all 0.2s ease-out;
}
.vcomments__add-input {
  box-sizing: border-box;
  width: 100%;
  outline: none;
  // border: 1px solid #eee;
  padding: 10px 17px 10px 15px;
  border-radius: 40px;
  transition: all 0.2s ease-out;
  margin: 1px 0px 5px;
}
.vcomments {
  $this: &;
  &__add-input {
    box-sizing: border-box;
    width: 100%;
    outline: none;
    border: 1px solid #000;
    padding: 10px 75px 10px 15px;
    border-radius: 40px;
    transition: all 0.2s ease-out;
    &:focus {
      border-color: $defaultColor;
      & + #{$this} {
        &__add-button {
          background: $defaultColor;
          color: white;
          transition: all 0.2 ease-in;
        }
      }
    }
  }
  &__add-block {
    margin-top: 15px;
    position: relative;
  }
}
ul.dropdown-menu.show {
  box-shadow: 0px 1px 10px 0px;
}
</style>
