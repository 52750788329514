<template>
  <div
    class="modal fade"
    :id="`showShares` + post.id"
    aria-hidden="true"
    aria-labelledby="showSharesToggleLabel2"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100" id="showSharesToggleLabel2">
            {{ $t("Shares on this post") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="mt-1" v-for="(v, index) in shares" :key="index">
            <img crossorigin="anonymous" 
              v-if="v.profile.has_media_profile === false"
              class="pd-image"
              src="@/assets/img_friends/default1.png"
              alt="User Profile Image"
            />
            <img crossorigin="anonymous" 
              v-else-if="
                v.profile.has_media_profile === true &&
                v.profile.media[0].collection_name === 'profile'
              "
              class="pd-image"
              :src="v.profile.media[0].src_url"
              alt="User Profile Image"
            />
            <img crossorigin="anonymous" 
              v-else-if="
                v.profile.has_media_profile === true &&
                v.profile.media[1].collection_name === 'profile'
              "
              class="pd-image"
              :src="v.profile.media[1].src_url"
              alt="User Profile Image"
            />
            {{ v.profile.name }}
          </div>
          <div class="text-center" v-if="this.shares.length == 0">
            <h4>{{ $t("There are no shares") }}</h4>
          </div>
        </div>
        <!-- <div v-else class="modal-body">
          <div class="d-flex align-items-center justify-content-center">
            <div class="spinner-grow text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["post", "shares"],
};
</script>

<style lang="scss" scoped>
.pd-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 20px;
}
</style>
