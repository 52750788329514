<template>
  <div class="col-md-12 col-lg-5 col-sm-12 py-0 center" id="aa">
    <div ref="dropdownMenuButtonNotification" class="notify">
      <div class="text-center w-100 p-2 mt-4 mb-4" style="cursor: default">
        <!-- <span class="show_all">{{ $t("Manage Notification") }}</span> -->
        <p
          style="cursor: pointer"
          class="d-inline-flex mb-0"
          @click="MarkAllNotificationsAsRead"
        >
          {{ $t("Make All as Reading") }}
        </p>
      </div>

      <div
        v-for="(notification, index) in allNotifications"
        :key="index"
        class="d-flex row align-items-center box_notification dir-lang"
      >
        <!-- New Div -->
        <div class="col-11 d-flex justify-content-start align-items-start">
          <!-- {{ notification.category }} -->
          <div class="dropdown text-center">
            <small
              style="cursor: pointer"
              :id="'dropdownMenuButton1' + notification.id"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img crossorigin="anonymous" 
                class="image-dropdown"
                src="@/assets/images/more.png"
                alt="action more"
              />
            </small>
            <ul
              class="dropdown-menu"
              :aria-labelledby="'dropdownMenuButton1' + notification.id"
            >
              <li style="cursor: pointer">
                <a
                  class="dropdown-item"
                  @click="delete_notification(notification.id)"
                >
                  {{ $t("Delete") }}
                </a>
              </li>
            </ul>
          </div>

          <HandleNotifications :notification="notification"></HandleNotifications>
        </div>
        <p
          v-if="notification.pivot.isClicked === 0"
          class="dot m-0 p-0 col-1 text-center"
          @click="readOne(notification.id)"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            class="bi bi-dot"
            viewBox="0 0 16 16"
          >
            <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
          </svg>
        </p>
      </div>
      <div
        v-if="allNotifications.length == 0"
        class="d-flex row align-items-center box_notification"
      >
        <h4 class="text-center">No notifications yet!</h4>
      </div>
    </div>
  </div>
</template>

<script>
import HandleNotifications from "@/components/notificationsComponents/HandleNotifications.vue";
import notificationServices from "@/services/Notification/notificationServices";
import { mapState, mapGetters } from "vuex";

export default {
  name: "managementNotificationComponent",
  components: {
    HandleNotifications,
  },
  computed: {
    ...mapState("notification", {
      allNotifications: "allNotifications",
    }),
    ...mapGetters({
      lang: "auth/lang",
    }),
  },

  methods: {
    readOne(id) {
      let item1 = this.$refs.dropdownMenuButtonNotification;
      item1.classList.add("show");
      this.$store.commit("notification/MAKE_NOTIFICATION_READ", id);
      notificationServices.MakeOneNotificationRead(id);
    },
    delete_notification(id) {
      notificationServices
        .DeleteOneNotification(id)
        .then((res) => {
          this.$store.commit("notification/REMOVE_NOTIFICATION", id);
          this.$snotify.success(
            this.$i18n.t(res.data.message),
            this.$i18n.t("Notifications")
          );
        })
        .catch(() => {
          this.$snotify.error(this.$i18n.t("Error"), this.$i18n.t("Error"));
        });
    },
    MarkAllNotificationsAsRead() {
      notificationServices.MarkAllAsRead().then((res) => {
        this.$snotify.success(
          this.$i18n.t(res.data.message),
          this.$i18n.t("Notifications")
        );
        this.$store.commit("notification/CHANGE_STATUS_MARK_NOTIFICATION");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.notify {
  background-color: white;
  width: 100%;
  top: 50px !important;
  // transform: translateX(-50%);
  // padding: 0;
  // overflow: hidden;
  // max-height: 90vh;

  &:hover {
    cursor: pointer;
  }

  .p-2 {
    .show_all {
      text-decoration: none;
      color: #0d988c;
      font-weight: bold;
    }

    p {
      color: #0d988c;
      font-weight: bold;
    }
  }

  .box_notification {
    margin: 0 !important;
    // padding: 0.5rem;
    padding: 0.5rem 0;
    width: 100%;
    .lh-base {
      // overflow: hidden;
      text-decoration: none;
      color: black;
    }
    .dot {
      color: #0d988c;
    }

    &:hover {
      background-color: #0d988c;
      .lh-base {
        color: white !important;
      }

      .dot {
        color: white !important;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .notify {
    width: 96% !important;
    top: 110px !important;
    overflow-y: scroll;

    .box_notification {
      margin: 0 !important;
      padding: 0.5rem 1rem;
      width: 100%;
      .lh-base {
        // overflow: hidden;
        text-decoration: none;
        color: black;
      }
      .dot {
        color: #0d988c;
      }

      &:hover {
        background-color: #0d988c;
        .lh-base {
          color: white !important;
        }

        .dot {
          color: white !important;
        }
      }
    }
  }
}

.dropdown {
  position: relative;
  display: inline-block;
  float: right;
  // display: none;
}
// .notify:hover {
//   .dropdown {
//     display: inline-block;
//     float: right;
//   }
// }

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f8f8f8;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 2px 1px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f8f8f8;
}

.dropdown:hover .dropdown-content {
  display: block;
}
/*  end css action */

/* interaction */

.image-dropdown {
  width: 55%;
}

.img-notification {
  width: 50px;
  border-radius: 50%;
  height: 50px;
}
.time {
  color: blue;
}

.dir-lang:lang(ar) {
  float: right;
  direction: rtl;
}
.dir-lang:lang(ku) {
  float: right;
  direction: rtl;
}
.for-lang:lang(ar) {
  text-align: right !important;
}
img.position-style {
  position: relative;
  bottom: 20px;
  // left: 50%;
  // transform: translateX(-50%);
}
</style>@/services/Notification/notificationServices
