<template>
  <!-- When Refresh Page -->
  <div>
    <!-- Loading post data -->
    <LoadingPost v-if="loading_post_data"></LoadingPost>
    <div
      v-else
      class="row m-0"
      dir="auto"
      style="height: calc(100vh - 71px) !important"
    >
      <!-- For SideBar -->
      <div class="col-4" style="background-color: #fdf8f8">
        <!-- For sidebar contain content post and comment and replies-->
        <SidebarShowPost :postinfo="postinfo"></SidebarShowPost>
        <!-- For Add Comment -->
        <CommentShowPost></CommentShowPost>
      </div>
      <!-- For Carousel -->
      <div class="col-8">
        <div v-if="postinfo.media.length != 0">
          <div
            v-if="postinfo.media[0].collection_name == 'files'"
            class="text-center"
          >
            <h4>File</h4>
            <a
              :href="postinfo.media[0].src_url"
              download="download"
              class="style-pdf"
              target="_blank"
            >
              <div
                class="div"
                style="
                  max-height: 100% !important;
                  width: 100% !important;
                  height: 100%;
                  overflow: hidden;
                  background: #fff;
                  display: flex;
                  align-items: center;
                  border: none;
                  background-color: #ffffff;
                  box-shadow: rgba(50, 50, 93, 0.25) 0px -2px 3px -2px inset,
                    rgba(0, 0, 0, 0.3) 0px 6px 29px -16px inset;
                  border-radius: 2px;
                  /* width: 100%; */
                  padding: 22px 25px 25px 55px;
                  /* margin: 8px 0px; */
                  border: 0px solid;
                "
              >
                <div class="logo-file">
                  <PdfSvg></PdfSvg>
                </div>
                <div class="two">
                  <span class="name-pdf" style="font-weight: 500">
                    &nbsp; {{ postinfo.media[0].fullPath.substr(12) + "" }}
                  </span>
                </div>
                <div class="three">
                  <DownloadSvg></DownloadSvg>
                </div>
              </div>
            </a>
          </div>
          <div
            v-else
            id="carouselExampleControls"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div v-if="isLoading" class="text-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <div v-else>
                <div v-if="postinfo.media.length === 1">
                  <div v-if="postinfo.media[0].media_type == 'Image'">
                    <div class="back" style="background: black">
                      <!-- <div class="layer"></div> -->
                      <img crossorigin="anonymous" 
                        :src="postinfo.media[0].src_url"
                        class="d-block"
                        alt="Image"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <div class="back">
                      <video crossorigin="anonymous"  controls :src="postinfo.media[0].src_url"></video>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <viewer :images="postinfo.media">
                    <div
                      v-for="(media, index) in postinfo.media"
                      :key="index"
                      :class="['carousel-item', index === 0 ? 'active' : '']"
                    >
                      <div v-if="media.media_type == 'Image'">
                        <div class="back" style="background: #1f1f1f">
                          <div class="layer"></div>
                          <img crossorigin="anonymous" 
                            :src="media.src_url"
                            class="d-block"
                            alt="Image"
                          />
                        </div>
                      </div>
                      <div v-else>
                        <div class="back">
                          <video crossorigin="anonymous" 
                            :id="'vid' + index"
                            controls
                            :src="media.src_url"
                          ></video>
                        </div>
                      </div>

                      <button
                        class="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                        @click="stop(index, media)"
                      >
                        <span
                          class="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">{{
                          $t("Previous")
                        }}</span>
                      </button>
                      <button
                        class="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                        @click="stop(index, media)"
                      >
                        <span
                          class="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">{{ $t("Next") }}</span>
                      </button>
                    </div>
                  </viewer>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="postinfo.survey != null">
          <!-- survey -->
          <div v-if="postinfo.survey.is_active == 1" class="mt-4">
            <div class="content" v-if="postinfo.survey.multiple_votes == 1">
              <fieldset>
                <legend class="text-center">
                  {{ postinfo.survey.content }}
                </legend>
                <div class="container">
                  <ul>
                    <li
                      v-for="(s, index) in postinfo.survey.options"
                      :key="index"
                    >
                      <div class="row">
                        <div class="col-1">
                          <span
                            v-if="postinfo.profile.id == USER.profile.id"
                            class="delete-option"
                            @click="delete_option(this.postinfo, s.id)"
                            title="remove option"
                          >
                            <svg
                              width="30"
                              height="50"
                              viewBox="-133.12 -133.12 1290.24 1290.24"
                              fill="#ff0000"
                              class="icon"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#ff0000"
                              stroke-width="11.264"
                            >
                              <g
                                id="SVGRepo_bgCarrier"
                                stroke-width="0"
                                transform="translate(199.68,199.68), scale(0.61)"
                              >
                                <rect
                                  x="-133.12"
                                  y="-133.12"
                                  width="1290.24"
                                  height="1290.24"
                                  rx="645.12"
                                  fill="#eaebec"
                                  strokewidth="0"
                                />
                              </g>

                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />

                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M512 897.6c-108 0-209.6-42.4-285.6-118.4-76-76-118.4-177.6-118.4-285.6 0-108 42.4-209.6 118.4-285.6 76-76 177.6-118.4 285.6-118.4 108 0 209.6 42.4 285.6 118.4 157.6 157.6 157.6 413.6 0 571.2-76 76-177.6 118.4-285.6 118.4z m0-760c-95.2 0-184.8 36.8-252 104-67.2 67.2-104 156.8-104 252s36.8 184.8 104 252c67.2 67.2 156.8 104 252 104 95.2 0 184.8-36.8 252-104 139.2-139.2 139.2-364.8 0-504-67.2-67.2-156.8-104-252-104z"
                                  fill=""
                                />

                                <path
                                  d="M707.872 329.392L348.096 689.16l-31.68-31.68 359.776-359.768z"
                                  fill=""
                                />

                                <path
                                  d="M328 340.8l32-31.2 348 348-32 32z"
                                  fill=""
                                />
                              </g>
                            </svg>
                          </span>
                        </div>
                        <div class="col-11">
                          <input
                            type="checkbox"
                            :id="`checkbox2` + s.id"
                            name="checkbox01"
                            :checked="s.user_has_voted"
                            @click="check_vote(s.id)"
                          />

                          <label :for="`checkbox2` + s.id">
                            {{ s.content }}
                          </label>

                          <span class="show-voters vote">
                            <span
                              v-for="(voter, index) in s.last_three_voters"
                              :key="index"
                            >
                              <img crossorigin="anonymous" 
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                :src="voter.photoURL"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />
                            </span>
                            &nbsp;
                            <span
                              style="cursor: pointer"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              >{{ s.votes_count }}</span
                            >
                            <!-- modal voters to option -->
                            <div
                              class="modal fade"
                              :id="`showVotersToOPtion` + s.id"
                              aria-hidden="true"
                              aria-labelledby="showVotersToOPtionToggleLabel2"
                              tabindex="-1"
                            >
                              <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5
                                      class="modal-title"
                                      id="showVotersToOPtionToggleLabel2"
                                    >
                                      {{ $t("Voters on the option") }}
                                    </h5>
                                    <button
                                      type="button"
                                      class="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div
                                    class="modal-body"
                                    v-if="this.loading_voters == false"
                                  >
                                    <div
                                      class="mt-1"
                                      v-for="(v, index) in all_voters"
                                      :key="index"
                                    >
                                      <img crossorigin="anonymous" 
                                        v-if="
                                          v.voter.has_media_profile === false
                                        "
                                        class="pd-image"
                                        src="@/assets/img_friends/default1.png"
                                        alt="User Profile Image"
                                      />
                                      <img crossorigin="anonymous" 
                                        v-else-if="
                                          v.voter.has_media_profile === true &&
                                          v.voter.media[0].collection_name ===
                                            'profile'
                                        "
                                        class="pd-image"
                                        :src="v.voter.media[0].src_url"
                                        alt="User Profile Image"
                                      />
                                      <img crossorigin="anonymous" 
                                        v-else-if="
                                          v.voter.has_media_profile === true &&
                                          v.voter.media[1].collection_name ===
                                            'profile'
                                        "
                                        class="pd-image"
                                        :src="v.voter.media[1].src_url"
                                        alt="User Profile Image"
                                      />
                                      {{ v.voter.name }}
                                    </div>
                                  </div>
                                  <div v-else class="modal-body">
                                    <div
                                      class="d-flex align-items-center justify-content-center"
                                    >
                                      <div
                                        class="spinner-grow text-success"
                                        role="status"
                                      >
                                        <span class="visually-hidden"
                                          >Loading...</span
                                        >
                                      </div>
                                      <div
                                        class="spinner-grow text-success"
                                        role="status"
                                      >
                                        <span class="visually-hidden"
                                          >Loading...</span
                                        >
                                      </div>
                                      <div
                                        class="spinner-grow text-success"
                                        role="status"
                                      >
                                        <span class="visually-hidden"
                                          >Loading...</span
                                        >
                                      </div>
                                      <div
                                        class="spinner-grow text-success"
                                        role="status"
                                      >
                                        <span class="visually-hidden"
                                          >Loading...</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- end modal -->
                          </span>
                        </div>
                      </div>
                    </li>
                    <div
                      class="row"
                      v-if="this.postinfo.survey.allow_user_options == 1"
                    >
                      <div class="col-1"></div>
                      <div class="col-11">
                        <label
                          @click="open_add_other_option"
                          class="add-other-option"
                          style="padding: 10px"
                        >
                          {{ $t("Add Other Option") }}
                        </label>
                        <div v-if="this.enableAddOption == true">
                          <div class="input-wrapper">
                            <input
                              type="text"
                              placeholder="write option here ..."
                              name="text"
                              class="input"
                              v-model="content_other_option"
                              autocomplete="off"
                            />
                          </div>
                          <br />

                          <div class="text-center m-2">
                            <button
                              @click="add_other_option(this.postinfo.survey.id)"
                              class="button"
                            >
                              {{ $t("Save") }}
                            </button>

                            &nbsp;
                            <button
                              class="button-cancel"
                              @click="open_add_other_option"
                            >
                              {{ $t("Close") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </fieldset>
            </div>
            <div class="content" v-if="postinfo.survey.multiple_votes == 0">
              <fieldset>
                <legend class="text-center">
                  {{ postinfo.survey.content }}
                </legend>
                <div class="container">
                  <ul>
                    <li
                      v-for="(s, index) in postinfo.survey.options"
                      :key="index"
                    >
                      <div class="row">
                        <div class="col-1">
                          <span
                            v-if="postinfo.profile.id == USER.profile.id"
                            class="delete-option"
                            @click="delete_option(this.postinfo, s.id)"
                            title="remove option"
                          >
                            <svg
                              width="30"
                              height="50"
                              viewBox="-133.12 -133.12 1290.24 1290.24"
                              fill="#ff0000"
                              class="icon"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              stroke="#ff0000"
                              stroke-width="11.264"
                            >
                              <g
                                id="SVGRepo_bgCarrier"
                                stroke-width="0"
                                transform="translate(199.68,199.68), scale(0.61)"
                              >
                                <rect
                                  x="-133.12"
                                  y="-133.12"
                                  width="1290.24"
                                  height="1290.24"
                                  rx="645.12"
                                  fill="#eaebec"
                                  strokewidth="0"
                                />
                              </g>

                              <g
                                id="SVGRepo_tracerCarrier"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />

                              <g id="SVGRepo_iconCarrier">
                                <path
                                  d="M512 897.6c-108 0-209.6-42.4-285.6-118.4-76-76-118.4-177.6-118.4-285.6 0-108 42.4-209.6 118.4-285.6 76-76 177.6-118.4 285.6-118.4 108 0 209.6 42.4 285.6 118.4 157.6 157.6 157.6 413.6 0 571.2-76 76-177.6 118.4-285.6 118.4z m0-760c-95.2 0-184.8 36.8-252 104-67.2 67.2-104 156.8-104 252s36.8 184.8 104 252c67.2 67.2 156.8 104 252 104 95.2 0 184.8-36.8 252-104 139.2-139.2 139.2-364.8 0-504-67.2-67.2-156.8-104-252-104z"
                                  fill=""
                                />

                                <path
                                  d="M707.872 329.392L348.096 689.16l-31.68-31.68 359.776-359.768z"
                                  fill=""
                                />

                                <path
                                  d="M328 340.8l32-31.2 348 348-32 32z"
                                  fill=""
                                />
                              </g>
                            </svg>
                          </span>
                        </div>
                        <div class="col-11">
                          <input
                            type="radio"
                            :id="`radio2` + s.id"
                            name="radio01"
                            :checked="s.user_has_voted"
                            @click="check_radio_vote(s.id)"
                          />
                          <label :for="`radio2` + s.id">{{ s.content }} </label>
                          <span class="show-voters vote">
                            <span
                              v-for="(voter, index) in s.last_three_voters"
                              :key="index"
                            >
                              <img crossorigin="anonymous" 
                                @click="get_voters(s.id)"
                                :data-bs-target="`#showVotersToOPtion` + s.id"
                                data-bs-toggle="modal"
                                data-bs-dismiss="modal"
                                width="30"
                                height="30"
                                :src="voter.photoURL"
                                loading="lazy"
                                :title="
                                  voter.first_name + ' ' + voter.last_name
                                "
                                style="cursor: pointer"
                              />
                            </span>
                            &nbsp;
                            <span
                              style="cursor: pointer"
                              @click="get_voters(s.id)"
                              :data-bs-target="`#showVotersToOPtion` + s.id"
                              data-bs-toggle="modal"
                              data-bs-dismiss="modal"
                              >{{ s.votes_count }}</span
                            >
                            <!-- modal voters to option -->
                            <div
                              class="modal fade"
                              :id="`showVotersToOPtion` + s.id"
                              aria-hidden="true"
                              aria-labelledby="showVotersToOPtionToggleLabel2"
                              tabindex="-1"
                            >
                              <div class="modal-dialog modal-dialog-centered">
                                <div class="modal-content">
                                  <div class="modal-header">
                                    <h5
                                      class="modal-title"
                                      id="showVotersToOPtionToggleLabel2"
                                    >
                                      {{ $t("Voters on the option") }}
                                    </h5>
                                    <button
                                      type="button"
                                      class="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                  <div
                                    class="modal-body"
                                    v-if="this.loading_voters == false"
                                  >
                                    <div
                                      class="mt-1"
                                      v-for="(v, index) in all_voters"
                                      :key="index"
                                    >
                                      <img crossorigin="anonymous" 
                                        v-if="
                                          v.voter.has_media_profile === false
                                        "
                                        class="pd-image"
                                        src="@/assets/img_friends/default1.png"
                                        alt="User Profile Image"
                                      />
                                      <img crossorigin="anonymous" 
                                        v-else-if="
                                          v.voter.has_media_profile === true &&
                                          v.voter.media[0].collection_name ===
                                            'profile'
                                        "
                                        class="pd-image"
                                        :src="v.voter.media[0].src_url"
                                        alt="User Profile Image"
                                      />
                                      <img crossorigin="anonymous" 
                                        v-else-if="
                                          v.voter.has_media_profile === true &&
                                          v.voter.media[1].collection_name ===
                                            'profile'
                                        "
                                        class="pd-image"
                                        :src="v.voter.media[1].src_url"
                                        alt="User Profile Image"
                                      />
                                      {{ v.voter.name }}
                                    </div>
                                  </div>
                                  <div v-else class="modal-body">
                                    <div
                                      class="d-flex align-items-center justify-content-center"
                                    >
                                      <div
                                        class="spinner-grow text-success"
                                        role="status"
                                      >
                                        <span class="visually-hidden"
                                          >Loading...</span
                                        >
                                      </div>
                                      <div
                                        class="spinner-grow text-success"
                                        role="status"
                                      >
                                        <span class="visually-hidden"
                                          >Loading...</span
                                        >
                                      </div>
                                      <div
                                        class="spinner-grow text-success"
                                        role="status"
                                      >
                                        <span class="visually-hidden"
                                          >Loading...</span
                                        >
                                      </div>
                                      <div
                                        class="spinner-grow text-success"
                                        role="status"
                                      >
                                        <span class="visually-hidden"
                                          >Loading...</span
                                        >
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- end modal -->
                          </span>
                        </div>
                      </div>
                    </li>
                    <div
                      class="row"
                      v-if="this.postinfo.survey.allow_user_options == 1"
                    >
                      <div class="col-1"></div>
                      <div class="col-11">
                        <label
                          @click="open_add_other_option"
                          class="add-other-option"
                          style="padding: 10px"
                        >
                          {{ $t("Add Other Option") }}
                        </label>
                        <div v-if="this.enableAddOption == true">
                          <div class="input-wrapper">
                            <input
                              type="text"
                              placeholder="write option here ..."
                              name="text"
                              class="input"
                              v-model="content_other_option"
                              autocomplete="off"
                            />
                          </div>
                          <br />

                          <div class="text-center m-2">
                            <button
                              @click="add_other_option(this.postinfo.survey.id)"
                              class="button"
                            >
                              {{ $t("Save") }}
                            </button>

                            &nbsp;
                            <button
                              class="button-cancel"
                              @click="open_add_other_option"
                            >
                              {{ $t("Close") }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </fieldset>
            </div>
          </div>
          <div v-if="postinfo.survey.is_active == 0" class="mt-4">
            <div class="content" v-if="postinfo.survey.multiple_votes == 1">
              <fieldset>
                <legend class="text-center">
                  {{ postinfo.survey.content }}
                </legend>
                <div class="container">
                  <ul>
                    <li
                      v-for="(s, index) in postinfo.survey.options"
                      :key="index"
                    >
                      <input
                        type="checkbox"
                        :id="`checkbox4` + s.id"
                        name="checkbox02"
                        disabled="disabled"
                        :checked="s.user_has_voted"
                      />

                      <label :for="`checkbox4` + s.id">{{ s.content }} </label>
                      <span class="show-voters vote">
                        <span
                          v-for="(voter, index) in s.last_three_voters"
                          :key="index"
                        >
                          <img crossorigin="anonymous" 
                            @click="get_voters(s.id)"
                            :data-bs-target="`#showVotersToOPtion` + s.id"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            width="30"
                            height="30"
                            :src="voter.photoURL"
                            loading="lazy"
                            :title="voter.first_name + ' ' + voter.last_name"
                            style="cursor: pointer"
                          />
                        </span>
                        &nbsp;
                        <span
                          style="cursor: pointer"
                          @click="get_voters(s.id)"
                          :data-bs-target="`#showVotersToOPtion` + s.id"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          >{{ s.votes_count }}</span
                        >
                        <!-- modal voters to option -->
                        <div
                          class="modal fade"
                          :id="`showVotersToOPtion` + s.id"
                          aria-hidden="true"
                          aria-labelledby="showVotersToOPtionToggleLabel2"
                          tabindex="-1"
                        >
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5
                                  class="modal-title"
                                  id="showVotersToOPtionToggleLabel2"
                                >
                                  {{ $t("Voters on the option") }}
                                </h5>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div
                                class="modal-body"
                                v-if="this.loading_voters == false"
                              >
                                <div
                                  class="mt-1"
                                  v-for="(v, index) in all_voters"
                                  :key="index"
                                >
                                  <img crossorigin="anonymous" 
                                    v-if="v.voter.has_media_profile === false"
                                    class="pd-image"
                                    src="@/assets/img_friends/default1.png"
                                    alt="User Profile Image"
                                  />
                                  <img crossorigin="anonymous" 
                                    v-else-if="
                                      v.voter.has_media_profile === true &&
                                      v.voter.media[0].collection_name ===
                                        'profile'
                                    "
                                    class="pd-image"
                                    :src="v.voter.media[0].src_url"
                                    alt="User Profile Image"
                                  />
                                  <img crossorigin="anonymous" 
                                    v-else-if="
                                      v.voter.has_media_profile === true &&
                                      v.voter.media[1].collection_name ===
                                        'profile'
                                    "
                                    class="pd-image"
                                    :src="v.voter.media[1].src_url"
                                    alt="User Profile Image"
                                  />
                                  {{ v.voter.name }}
                                </div>
                              </div>
                              <div v-else class="modal-body">
                                <div
                                  class="d-flex align-items-center justify-content-center"
                                >
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end modal -->
                      </span>
                    </li>
                  </ul>
                </div>
              </fieldset>
            </div>
            <div class="content" v-if="postinfo.survey.multiple_votes == 0">
              <fieldset>
                <legend class="text-center">
                  {{ postinfo.survey.content }}
                </legend>
                <div class="container">
                  <ul>
                    <li
                      v-for="(s, index) in postinfo.survey.options"
                      :key="index"
                    >
                      <input
                        type="radio"
                        :id="`radio4` + s.id"
                        name="radio02"
                        disabled="disabled"
                        :checked="s.user_has_voted"
                      />

                      <label :for="`radio4` + s.id">{{ s.content }} </label>
                      <span class="show-voters vote">
                        <span
                          v-for="(voter, index) in s.last_three_voters"
                          :key="index"
                        >
                          <img crossorigin="anonymous" 
                            @click="get_voters(s.id)"
                            :data-bs-target="`#showVotersToOPtion` + s.id"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            width="30"
                            height="30"
                            :src="voter.photoURL"
                            loading="lazy"
                            :title="voter.first_name + ' ' + voter.last_name"
                            style="cursor: pointer"
                          />
                        </span>
                        &nbsp;
                        <span
                          style="cursor: pointer"
                          @click="get_voters(s.id)"
                          :data-bs-target="`#showVotersToOPtion` + s.id"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          >{{ s.votes_count }}</span
                        >
                        <!-- modal voters to option -->
                        <div
                          class="modal fade"
                          :id="`showVotersToOPtion` + s.id"
                          aria-hidden="true"
                          aria-labelledby="showVotersToOPtionToggleLabel2"
                          tabindex="-1"
                        >
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5
                                  class="modal-title"
                                  id="showVotersToOPtionToggleLabel2"
                                >
                                  {{ $t("Voters on the option") }}
                                </h5>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div
                                class="modal-body"
                                v-if="this.loading_voters == false"
                              >
                                <div
                                  class="mt-1"
                                  v-for="(v, index) in all_voters"
                                  :key="index"
                                >
                                  <img crossorigin="anonymous" 
                                    v-if="v.voter.has_media_profile === false"
                                    class="pd-image"
                                    src="@/assets/img_friends/default1.png"
                                    alt="User Profile Image"
                                  />
                                  <img crossorigin="anonymous" 
                                    v-else-if="
                                      v.voter.has_media_profile === true &&
                                      v.voter.media[0].collection_name ===
                                        'profile'
                                    "
                                    class="pd-image"
                                    :src="v.voter.media[0].src_url"
                                    alt="User Profile Image"
                                  />
                                  <img crossorigin="anonymous" 
                                    v-else-if="
                                      v.voter.has_media_profile === true &&
                                      v.voter.media[1].collection_name ===
                                        'profile'
                                    "
                                    class="pd-image"
                                    :src="v.voter.media[1].src_url"
                                    alt="User Profile Image"
                                  />
                                  {{ v.voter.name }}
                                </div>
                              </div>
                              <div v-else class="modal-body">
                                <div
                                  class="d-flex align-items-center justify-content-center"
                                >
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end modal -->
                      </span>
                    </li>
                  </ul>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommentShowPost from "@/components/GroupsComponents/PostComponents/ShowPostComponents/CommentShowPost.vue";
import LoadingPost from "@/components/GroupsComponents/PostComponents/ShowPostComponents/LoadingPost.vue";
import SidebarShowPost from "@/components/GroupsComponents/PostComponents/ShowPostComponents/SidebarShowPost.vue";
import DownloadSvg from "@/icons/Groups/DownloadSvg.vue";
import PdfSvg from "@/icons/Groups/PdfSvg.vue";
import { useRoute } from "vue-router";
import { mapMutations, mapState, mapGetters, mapActions } from "vuex";
import formatTime from "@/mixins/formatTime";
import GroupPostServices from "@/services/GroupsServices/GroupPostServices";

export default {
  name: "ShowGroupPost",
  data() {
    return {
      loading_post_data: null,
      postinfo: null,
      id: useRoute().params.id,
      post_id: useRoute().params.postid,
      all: 0,
      isliked: null,
      interaction_model: null,
      // survey
      survey_option_id: [],
      all_voters: [],
      loading_voters: false,
      enableAddOption: false,
      content_other_option: null,
    };
  },
  mixins: [formatTime],
  mounted() {
    var videoEl = document.querySelectorAll("video");

    for (var i = 0; i < videoEl.length; i++) {
      videoEl[i].onplay = (event) => {
        for (var k = 0; k < videoEl.length; k++) {
          if (event.target.attributes[1] != videoEl[k].attributes[1]) {
            videoEl[k].pause();
          }
        }
      };
    }
  },

  methods: {
    stop(index, media) {
      // console.log(media)
      if (media.media_type != "Image") {
        var current = document.getElementById(`vid${index}`);
        current.pause();
      }
    },
    add_other_option(survey_id) {
      GroupPostServices.s_add_new_option_to_survey(survey_id, {
        content: this.content_other_option,
      }).then((res) => {
        this.$snotify.success("add option successfully", "add");
        this.$store.commit(
          "singleGroup/ADD_OTHER_OPTION",
          res.data.CreateOptionMutation
        );
        this.content_other_option = null;
        this.enableAddOption = false;
      });
    },
    open_add_other_option: function () {
      if (this.enableAddOption) {
        this.enableAddOption = false;
      } else {
        this.enableAddOption = true;
      }
    },
    delete_option(post_id, option_id) {
      GroupPostServices.s_delete_option_from_survey(option_id).then(() => {
        this.$snotify.success("Deleted option successfully", "option");
        let payload = {
          post_id: post_id.id,
          option_id: option_id,
        };
        console.log(payload);
        this.$store.commit("singleGroup/DELETE_OPTION", payload);
      });
    },
    get_voters(survey_id) {
      this.all_voters = [];
      this.loading_voters = true;
      GroupPostServices.s_get_voters_to_option(survey_id).then((res) => {
        this.all_voters = res.data.GetVotesForOptionQuery;
        this.loading_voters = false;
      });
    },
    async check_vote(id) {
      if (this.survey_option_id.length == 0) {
        this.survey_option_id.push(id);
      } else {
        if (!this.survey_option_id.includes(id)) {
          this.survey_option_id.push(id);
        } else {
          let index = this.survey_option_id.indexOf(id);
          this.survey_option_id.splice(index, 1);
        }
      }
      await GroupPostServices.s_vote_on_option_to_survey(
        this.postinfo.survey.id,
        {
          survey_option_id: this.survey_option_id,
        }
      ).then((res) => {
        this.postinfo.survey = res.data.VoteSurveyMutation;
      });
    },
    async check_radio_vote(id) {
      if (this.survey_option_id.length == 0) {
        this.survey_option_id.push(id);
      } else {
        this.survey_option_id = [];
        this.survey_option_id.push(id);
      }
      await GroupPostServices.s_vote_on_option_to_survey(
        this.postinfo.survey.id,
        {
          survey_option_id: this.survey_option_id,
        }
      ).then((res) => {
        this.postinfo.survey = res.data.VoteSurveyMutation;
      });
    },

    change() {
      this.interaction_model = null;
    },
    showinteractionmodel() {
      this.UPDATE_IS_MODAL_INTERACTION();
      this.interaction_model = true;
      if (document.documentElement.style.overflow === "hidden") {
        document.documentElement.style.overflow = "auto";
      } else {
        document.documentElement.style.overflow = "hidden";
      }
    },
    ...mapMutations({
      UPDATE_IS_MODAL_INTERACTION: "post/UPDATE_IS_MODAL_INTERACTION",
    }),
    a_get_comment(post_id) {
      this.$store.dispatch("groupComment/a_getComments", { post_id: post_id });
    },
    ...mapMutations({
      UPDATE_SHOW_POST: "post/UPDATE_SHOW_POST",
    }),
    async getpostinfo(post_id) {
      this.loading_post_data = true;
      return GroupPostServices.s_get_single_post(post_id)
        .then((response) => {
          this.postinfo = null;
          this.isLoading = false;
          this.postinfo = response.data.GroupPostsSpecificQuery;
          this.loading_post_data = null;
        })
        .catch((error) => {
          console.log(error.message);
        });
    },

    async getiteracion(id, data) {
      await GroupPostServices.s_get_all_reactions_with_post(id, data).then(
        (response) => {
          // console.log(response.data.AllPostInteractionsQuery.items)
          this.all = response.data.AllPostInteractionsQuery.total;
          response.data.AllPostInteractionsQuery.items.forEach((item) => {
            if (
              item.profile.user_id ==
              JSON.parse(localStorage.getItem("user")).id
            ) {
              this.isliked = true;
            }
          });
          this.isshow = null;
        }
      );
    },

    back() {
      this.$router.go(-1);
    },
    ...mapActions({
      get_all_friends: "friendStore/get_all_friends",
    }),
  },
  notifications: {
    showSuccess: {
      title: "Comment",
      message: "Commented successfully",
      type: "success",
    },
    showError: {
      title: "Comment",
      message: "Something went Wrong",
      type: "error",
    },
  },
  components: {
    // InteractionGroupPost,
    // DeleteSvg,
    // CommentReportComponent,
    // Mentionable,
    // ButtonsPost,
    // AddReactionToCommentReply,
    // AddReactionToCommentInGroup,
    PdfSvg,
    DownloadSvg,
    // ReportPostSvg
    LoadingPost,
    SidebarShowPost,
    CommentShowPost,
  },
  computed: {
    ...mapState("page", {
      mypages: "mypages",
    }),
    ...mapState("post", {
      showpost: "showpost",
    }),
    ...mapState("comment", {
      loading_comment: "loading_comment",
    }),
    ...mapGetters({
      USER: "auth/USER",
    }),
    ...mapGetters({
      comments: "groupComment/comments",
      friendstagsarray: "friendStore/friendstagsarray",
    }),
    ...mapState("post", {
      interactionModel: "interactionModel",
    }),
  },
  created() {
    this.getpostinfo(this.post_id);
    this.a_get_comment(this.post_id);
    this.getiteracion(this.post_id, 0);
    this.UPDATE_SHOW_POST(this.id);
    this.friendstagsarray.length == 0 ? this.get_all_friends() : ""; // if we rfresh the page execute get all friends to have array tags
    // console.log('aa', this.comments)
  },
};
</script>

<style lang="scss" scoped>
@import "floating-vue/dist/style.css";
@import "@/Styles/Comments/comment/comment.scss";
.col-4:lang(ar) {
  padding: 0 0.5rem 0 0.5rem !important;
}

.col-4 {
  height: calc(100vh - 78px);
  overflow-y: scroll;
}
.one {
  img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: #a319c5 !important;
  border-radius: 5px;
}

// .d-block[data-v-2b000c5d] {
//   width: auto !important;
// }

.interaction {
  .img-reaction {
    width: 25px;
    height: 25px;
    margin: 0;
    border-radius: 50%;
  }
}

.add_comment {
  input {
    width: 100%;
    border-radius: 10px;
    padding: 0.2rem 1rem;
    height: 50px;
    outline: none;

    &:hover {
      outline: none;
    }
  }
}
#carouselExampleControls {
  height: 100%;
  // position: relative;

  .carousel-inner,
  .carousel-item.active {
    // height: 100% !important;
    height: calc(100vh - 78px);
    overflow: hidden;
    position: relative;

    .back {
      position: relative;
      height: calc(100vh - 78px);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-blend-mode: saturation;

      .d-block,
      video {
        border-radius: 10px;
        position: absolute;
        top: 50% !important;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto !important;
        height: auto !important;
        max-width: calc(100% - 20px) !important;
        max-height: calc(100% - 20px) !important;
        opacity: 1;
        z-index: 9;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px,
          rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
          rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px,
          rgba(0, 0, 0, 0.07) 0px 32px 64px;
      }
    }
    .cover_body {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    z-index: 10;
  }
}

.comments {
  .comment_box {
    border: 1px solid #777;
    border-radius: 10px;
    padding: 0.5rem 1rem;

    .comment-avatar {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .comment__info {
      padding: 0 0.5rem;
      margin-top: 0.1rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .col-4 {
    width: 100% !important;
  }

  .col-8 {
    order: -1;
    flex: 1 0 100%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .d-block,
  video {
    border-radius: 10px;
    position: absolute;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 20px) !important;
    max-height: calc(100% - 20px) !important;
    opacity: 1;
    z-index: 19 !important;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    z-index: 22 !important;
  }
}
video {
  width: 100%;
  height: 100%;
  border: 1px solid rgb(119, 119, 119);
  margin: 2px;
}

.rebly_button {
  padding: 0.1rem 0.5rem;
  cursor: pointer;
  border: 1px solid #77777774;
  border-radius: 5px;
  font-size: 14px;
}

.name-pdf {
  color: #000000;
  text-decoration: none;
}
.style-pdf:hover {
  cursor: pointer;
  filter: brightness(95%);
}

.logo-file {
  width: 10%;
  // background:red;
}

.two {
  width: 85%;
  // background:lightgreen;
}

.three {
  width: 10%;
}

//
fieldset {
  padding-left: 0.5em;
  padding-right: 1em;
  padding-bottom: 2em;
  border-color: #d4d4d4;
  border-style: solid;
  border-width: 2px;
  background: #fff;
}

legend {
  float: none;
  color: #272727;
  padding: 0.3em 0.6em;
  border: 2px solid #d4d4d4;
  background: #fff;
  font-size: 1em;
}
legend.radio {
  border-radius: 50px;
}
.container ul {
  margin-top: 0;
  padding-left: 0em;
}
.container ul li {
  list-style-type: none;
}
.container ul + ul {
  margin-bottom: 0;
}
.container ul + ul > li + li label {
  margin-bottom: 0;
}

label {
  font-weight: 600;
  color: #777777;
  margin-bottom: 11px;
  width: 100%;
  float: left;
  cursor: pointer;
  padding: 0 0.6em;
  box-sizing: border-box;
  background: #e6e6e6;
  transition: all 0.5s ease 0s;
}

input[type="radio"],
input[type="checkbox"] {
  display: none;
}

input[type="radio"] + label,
input[type="checkbox"] + label {
  line-height: 3em;
}

input[type="radio"] + label {
  border-radius: 50px;
}

input[type="radio"]:disabled + label,
input[type="checkbox"]:disabled + label {
  color: #ccc !important;
  cursor: not-allowed;
}
input[type="radio"]:checked:disabled + label:after,
input[type="checkbox"]:checked:disabled + label:after {
  border-color: #ccc;
}

input[type="radio"] + label:before,
input[type="checkbox"] + label:before {
  content: "";
  width: 26px;
  height: 26px;
  float: left;
  margin-right: 0.6em;
  border: 2px solid #ccc;
  background: #fff;
  margin-top: 0.6em;
}
input[type="radio"] + label:before {
  border-radius: 100%;
}

input[type="radio"]:checked + label,
input[type="checkbox"]:checked + label {
  background: #c1eec2;
}

input[type="radio"]:checked + label:after {
  content: "";
  width: 0;
  height: 0;
  border: 7px solid #0fbf12;
  float: left;
  margin-left: -1.85em;
  margin-top: 1em;
  border-radius: 100%;
}
input[type="checkbox"]:checked + label:after {
  content: "";
  width: 12px;
  height: 6px;
  border: 4px solid #0fbf12;
  float: left;
  margin-left: -1.95em;
  border-right: 0;
  border-top: 0;
  margin-top: 1em;
  transform: rotate(-55deg);
}

input[type="radio"]:checked + label:before,
input[type="checkbox"]:checked + label:before {
  border-color: #0fbf12;
}

input[type="radio"]:checked:disabled + label,
input[type="checkbox"]:checked:disabled + label {
  background: #ccc;
  color: #fff !important;
}

input[type="radio"]:checked:disabled + label:before,
input[type="checkbox"]:checked:disabled + label:before {
  border-color: #bdbdbd;
}

@media (max-width: 650px) {
  .content {
    width: 100%;
  }
}
.vote {
  float: right;
}

.show-voters {
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: -50px 10px 0px 0px;
  display: grid;
}
.vote img {
  border-radius: 50%;
}
.content {
  margin-top: 10px;
  margin-right: 20%;
  margin-left: 20%;
}
.pd-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 20px;
}
.delete-option {
  cursor: pointer;
}
// add other option
.input-wrapper input {
  background-color: #eee;
  border: none;
  padding: 1rem;
  font-size: 1rem;
  width: 100%;
  border-radius: 1rem;
  color: #9e5f9c;
  box-shadow: 0 0.4rem #dfd9d9;
  cursor: pointer;
}

.input-wrapper input:focus {
  outline-color: #9e5f9c;
}

.button {
  color: #ecf0f1;
  font-size: 15px;
  background-color: #741977;
  border: 1px solid #610c64;
  border-radius: 5px;
  padding: 7px;
  box-shadow: 0px 6px 0px #5c0557;
  transition: all 0.1s;
}

.button:active {
  box-shadow: 0px 2px 0px #5c0559;
  position: relative;
  top: 2px;
}
.button-cancel {
  color: #111111;
  font-size: 15px;
  background-color: #bdb9bd;
  border: 1px solid #999799;
  border-radius: 5px;
  padding: 7px;
  box-shadow: 0px 6px 0px #868486;
  transition: all 0.1s;
}

.button-cancel:active {
  box-shadow: 0px 2px 0px #3f3f3f;
  position: relative;
  top: 2px;
}
</style>
