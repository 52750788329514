<template>
  <section class="complateInformation">
    <div class="container" dir="auto">
      <div class="row justify-content-center align-items-center">
        <div class="col-xl-10">
          <div class="card">
            <div class="row g-0">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <img crossorigin="anonymous" 
                      src="@/assets/logo-edit.png"
                      alt=""
                      width="60"
                      height="60"
                    />
                    &nbsp;&nbsp;
                    <span class="font-mediaNas"
                      ><b style="color: #7543d9">{{ $t("Media") }}</b>
                      <b style="color: #601776">{{ $t("Nas") }}</b></span
                    >
                  </div>
                </div>
                <div class="text-center mb-5">
                  <h3 class="custom_text text-center mt-2">
                    {{ $t("Please complete your information") }}
                  </h3>
                </div>
                <div class="row m-3" dir="auto">
                  <div class="col-md-4">
                    <div class="form-outline mb-2">
                      <label
                        class="form-label custom_text_lable"
                        for="first_name"
                        >{{ $t("First Name") }}</label
                      ><span style="color: red"> *</span>
                      <input
                        autocomplete="off"
                        dir="auto"
                        type="text"
                        v-model="first_name"
                        id="first_name"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-outline mb-2">
                      <label
                        class="form-label custom_text_lable"
                        for="middle_name"
                        >{{ $t("Middle Name") }}</label
                      >
                      <input
                        autocomplete="off"
                        dir="auto"
                        type="text"
                        v-model="middle_name"
                        id="middle_name"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-outline mb-2">
                      <label
                        class="form-label custom_text_lable"
                        for="last_name"
                        >{{ $t("Last Name") }}</label
                      ><span style="color: red"> *</span>
                      <input
                        autocomplete="off"
                        dir="auto"
                        type="text"
                        v-model="last_name"
                        id="last_name"
                        class="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div class="row m-3" dir="auto">
                  <div class="col-md-6">
                    <label class="mb-0"
                      ><h6 class="mb-0 text-sm">
                        {{ $t("Birth day") }}
                        <span style="color: red"> *</span>
                      </h6></label
                    >
                      <span class="form-check">
                        <label class="" for="flexRadioDefault1">
                          <input class="form-control"  type="date" name="" v-model="dob" id="exampleFormControlInput1">
                        </label>
                      </span>
                    </div>
                  <div class="col-md-6">
                    <div class="form-outline mb-2">
                      <label
                        class="form-label custom_text_lable"
                        for="gender"
                        >{{ $t("gender") }}</label
                      ><span style="color: red"> *</span>
                      <br />
                      <div class="gender-border">
                        <lable for="gender1">{{ $t("male") }} &nbsp;</lable>
                        <input
                          type="radio"
                          v-model="gender"
                          id="gender1"
                          class="form-check-input"
                          value="0"
                        />
                        &nbsp;
                        <lable for="gender2">{{ $t("Female") }} &nbsp;</lable>
                        <input
                          type="radio"
                          v-model="gender"
                          id="gender2"
                          class="form-check-input"
                          value="1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center mb-5">
                  <button
                    v-if="!loading_update"
                    :disabled="!dob "
                    type="submit"
                    class="btn btn-lg btn-light w-100 text-dark fw-bold"
                    @click="update_profile()"
                  >
                    {{ $t("Save Updating") }}
                  </button>
                  <button
                    v-else
                    type="submit"
                    disabled
                    class="btn btn-outline-success"
                  >
                    {{ $t("Loading...") }}
                  </button>
                </div>
                <!-- <div class="text-center mb-5">
                      <button
                        ref="codeemailbutton"
                        @click="codeverifyemail()"
                        class="btn btn-success"
                      >
                        {{ $t("Verification") }}
                      </button>
                    </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import ProfileService from "@/services/Profile/ProfileService";
import init from "@/services/Firebase/Firbase_init";

export default {
  name: "UpdateInformationProfile",
  props: ["auth_user_info"],
  data() {
    return {
      first_name: this.auth_user_info.first_name,
      middle_name: this.auth_user_info.middle_name,
      last_name: this.auth_user_info.last_name,
      gender: this.auth_user_info.gender,
      loading_update: null,
      dob: null
    };
  },
  created() {
    if (this.isLogged) {
      // this.$router.push({ name: "complate-information" });
      // window.location.pathname = "home";
    }
  },
  methods: {
    async update_profile() {
      this.loading_update = true;
      // console.log('11')
      let data = {
        first_name: this.first_name,
        middle_name: this.middle_name,
        last_name: this.last_name,
        gender: this.gender,
      };
      // console.log(data)
      // return;
      await ProfileService.update_profile(data)
        .then((res) => {
          // console.log(res.data.data)
          this.$store.commit(
            "auth/UPDATE_INFO_PROFILEFROMSETTING",
            res.data.data
          );
          this.loading_update = false;
          this.$snotify.success(
            this.$i18n.t("Update User Information successfully"),
            this.$i18n.t("Profile")
          );
          // this.$router.push({ name: "home" });
          // window.location.pathname = "home";
          this.$router.push({
            name: "home",
          });
          init.subscribe();
        })
        .catch(() => {});
    },
  },
  computed: {
    ...mapState(["loading"]),
    isLogged() {
      return this.$store.getters["auth/isLogged"];
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  top: -55px;
  left: 0;
  z-index: 99999;
  height: calc(100% + 55px);
  width: 100%;
  background-image: linear-gradient(
    to right top,
    #39a95e,
    $defaultColor,
    #00b2af,
    #00b2cc,
    $defaultColor
  );
  opacity: 0.8;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  @keyframes spin {
    to {
      transform: rotateZ(360deg);
    }
  }
  h1 {
    color: white;
    margin: 1rem auto;
  }
  span {
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    border: 3px solid transparent;
    border-top-color: #fff;
    border-bottom-color: #fff;
    border-radius: 50%;
    animation: spin ease 1000ms infinite;
  }
}

.card {
  box-shadow: rgba(7, 161, 136, 0.56) -1px 4px 15px 0px;
}
.gradient-custom-2 {
  background-image: url("https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/img4.webp");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
}

input {
  color: $defaultColor !important;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
}

input::placeholder {
  color: $defaultColor !important;
}
.auth {
  cursor: pointer;
  border: 1px solid #777;
  padding: 0.5rem;
  margin-bottom: 1rem;
  p {
    margin: 0 0 0 1rem;
    padding: 0;
    font-size: large;
    font-weight: 600;
  }
  img {
    width: 30px;
    height: 30px;
  }
}
button.btn.custom_button {
  background: $defaultColor;
}
.router-link-to-profile {
  text-decoration: none !important;
  color: #111;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
span.font-s {
  padding: 10px;
  font-weight: 700;
  font-size: 110%;
  color: #444;
}
.font-lable {
  font-weight: 600;
  font-size: 100%;
  color: #444;
}
span.font-mediaNas {
  font-weight: 700;
  font-size: 200%;
}

.background {
  background: linear-gradient(90deg, rgb(255, 255, 255), rgb(238, 235, 235));
  background-size: 400% 400%;
  animation: anim 2.5s infinite ease-in0out;
}

@keyframes anim {
  0% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-postion: 0 100%;
  }
}
.social {
  font-size: 22px;
  height: 40px;
  width: 50px;
  background: #eaeaf3;
  border-radius: 60%;
  margin: 0px 10px;
  cursor: pointer;
}
.social:hover {
  font-size: 22px;
  height: 40px;
  width: 50px;
  background: #e0e0e7;
  border-radius: 60%;
  margin: 0px 10px;
  cursor: pointer;
}
//
span.font-mediaNas {
  font-weight: 700;
  font-size: 200%;
}
.custom_text_lable {
  color: #6f7070;
  font-weight: bold;
}
.gender-border {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}
</style>
