<template>
  <!-- Modal -->
  <div
    class="modal fade"
    :id="'staticEditGroup' + post.id"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header" dir="auto">
          <img crossorigin="anonymous" 
            class="profile_image"
            v-if="post.model.cover === null"
            src="@/assets/img_friends/default1.png"
            alt=""
          />
          <img crossorigin="anonymous" 
            class="profile_image"
            v-else-if="post.model.cover.src_url"
            :src="post.model.cover.src_url"
            alt=""
          />

          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("Edit Post") }}
          </h5>
          <div class="close-dir">
            <span
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></span>
          </div>
        </div>
        <div class="modal-body">
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexSwitchCheckChecked"
              checked
              v-model="this.anonymous"
              :true-value="true"
              :false-value="false"
            />
            <label class="form-check-label" for="flexSwitchCheckChecked">{{
              $t("Post anonymous content")
            }}</label>
          </div>
          <br />
          <form class="p-3">
            <textarea
              v-model="content"
              name="content"
              class="textarea"
              cols="30"
              rows="10"
              :placeholder="$t(`what are you thinking about?`)"
            ></textarea>

            <div class="mt-3 text-center">
              <label
                for="uploadnewmedia"
                class="d-flex align-items-center justify-content-center"
              >
                {{ $t("UploadNewMedia") }}
                <font-awesome-icon class="icon" icon="fas fa-file-upload" />
              </label>
              <input
                type="file"
                id="uploadnewmedia"
                ref="fileedite"
                @change="uploadFilenew()"
                hidden
                accept="video/*, image/*"
                multiple
              />
            </div>
            <div class="mt-3 text-center">
              <div
                v-for="(img, index) in postsmedia"
                :key="index"
                style="display: inline-block; position: relative"
              >
                <button
                  type="button"
                  class="btn-close"
                  aria-label="Close"
                  :disabled="this.isloading"
                  style="position: absolute; top: 2px; right: 2px"
                  @click="deleteitem(index, img.id, img)"
                ></button>
                <img crossorigin="anonymous" 
                  v-if="img.media_type === 'Image'"
                  :src="img.src_url"
                  style="
                    width: 75px;
                    height: 75px;
                    margin: 2px;
                    border: 1px solid #777;
                  "
                />
                <video crossorigin="anonymous" 
                  v-if="img.media_type === 'Video'"
                  :src="img.src_url"
                  style="
                    vertical-align: middle;
                    margin: 2px;
                    width: 75px;
                    height: 75px;
                    border: 1px solid #777;
                  "
                ></video>
              </div>
            </div>
            <div class="mt-3 text-center">
              <div
                v-for="(item, index1) in newpostmedia"
                :key="index1"
                style="display: inline-block; position: relative"
              >
                <button
                  type="button"
                  class="btn-close"
                  :disabled="this.isloading"
                  aria-label="Close"
                  style="position: absolute; top: 2px; right: 2px"
                  @click="deleteiteminnew(index1)"
                ></button>
                <img crossorigin="anonymous" 
                  v-if="item.type.includes('image')"
                  :src="item.url"
                  :id="'aspect_video' + index1"
                  style="
                    width: 75px;
                    height: 75px;
                    margin: 2px;
                    border: 1px solid #777;
                  "
                />
                <video crossorigin="anonymous" 
                  v-if="item.type.includes('video')"
                  :src="item.url"
                  :id="'aspect_video' + index1"
                  style="
                    vertical-align: middle;
                    margin: 2px;
                    width: 75px;
                    height: 75px;
                    border: 1px solid #777;
                  "
                ></video>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            v-if="!isloading"
            class="btn btn-lg btn-light w-100 text-dark fw-bold"
            @click.prevent="submitFile"
            type="submit"
          >
            {{ $t("Edit Post") }}
          </button>
          <button
            v-else
            class="btn btn-lg btn-light w-100 text-dark fw-bold d-flex align-items-center justify-content-center"
            type="submit"
          >
            {{ $t("Loading...") }}
            <div class="spinner-border text-dark ms-2" role="status">
              <span class="visually-hidden">{{ $t("Loading...") }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End modal -->
</template>
<script>
import { Modal } from "bootstrap";
import { mapGetters, mapMutations, mapState } from "vuex";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { uuid } from "vue-uuid";
import GroupPostServices from "@/services/GroupsServices/GroupPostServices";

export default {
  name: "EditPostComponent",
  props: ["post"],
  data() {
    return {
      anonymous:this.post.anonymous,
      content: this.post.content,
      media: [],
      postsmedia: this.post.media,
      newpostmedia: [],
      datasender: [],
      mediadeleted: [],
      mediaforediteinfo: [],
      isloading: null,
      addpersonalpost: null,
    };
  },
  computed: {
    ...mapGetters({
      my_post: "profile/my_post",
      singleGroup: "singleGroup/singleGroup",
      allPosts: "singleGroup/allPosts",
    }),
    ...mapState("post", {
      isLoadingNewPost: "isLoadingNewPost",
    }),
  },
  methods: {
    deleteitem(index, id, item) {
      this.postsmedia.splice(index, 1);
      this.mediadeleted.push(id);
      this.mediaforediteinfo.push(item);
    },
    deleteiteminnew(index) {
      this.newpostmedia.splice(index, 1);
      this.media.splice(index, 1);
    },
    uploadFilenew() {
      for (let i = 0; i < this.$refs.fileedite.files.length; i++) {
        this.media.push(this.$refs.fileedite.files[i]);
        this.newpostmedia.push({
          type: this.$refs.fileedite.files[i].type,
          url: URL.createObjectURL(this.$refs.fileedite.files[i]),
        });
      }
    },
    close(post_id) {
      let x = this.my_post.findIndex((post) => post.id === post_id);
      this.mediaforediteinfo.forEach((item) => {
        this.my_post[x].media.push(item);
      });
      this.media = [];
      this.mediadeleted = [];
      this.mediaforediteinfo = [];
      this.newpostmedia = [];
      this.datasender = [];
    },
    ...mapMutations({
      EditPost: "singleGroup/EditPost",
    }),
    async submitFile() {
      this.isloading = true;
      for (let k = 0; k < this.media.length; k++) {
        var vid = document.getElementById("aspect_video" + k);
        // returns the intrinsic width of the video
        this.width_video = vid.videoWidth;
        // returns the intrinsic height of the video
        this.height_video = vid.videoHeight;
        let storage = getStorage();
        let unique_number = this.media[k].name + uuid.v4();
        let storageRef = ref(storage, "groups/posts/" + unique_number);
        let item = null;
        await uploadBytes(storageRef, this.media[k])
          .then((snapshot) => {
            let meme = snapshot.metadata.contentType.split("/");
            item = {
              size: snapshot.metadata.size,
              media_type: meme[0],
              mime_type: meme[1],
              width: this.width_video,
              height: this.height_video,
              fullPath: snapshot.metadata.fullPath,
            };
            this.datasender.push(item);
          })
          .catch((error) => {
            this.$snotify.error(error.message, "Error!");
          });

        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
          item.src_thum = url;
          item.src_icon = url;
        });
      }

      let data = {
        delete_media: this.mediadeleted,
        media: this.datasender,
        content: this.content,
        anonymous:this.anonymous
      };
      try {
        await GroupPostServices.s_update_post(this.post.id, data).then(
          (response) => {
            this.EditPost(response.data.UpdatePostMutation);
            this.media = [];
            this.mediadeleted = [];
            this.datasender = [];
            this.newpostmedia = [];
          }
        );
        this.isloading = false;
        // if (this.mediaforediteinfo.length > 0) {
        //   this.EditeAllImagesAndAllVideos(this.mediaforediteinfo);
        // }

        this.mediaforediteinfo = [];
        // hidden modal
        this.addpersonalpost.hide();
        this.$snotify.success("Edit Post successfully", "Post");
        this.isloading = false;
      } catch (error) {
        this.media = [];
        this.mediadeleted = [];
        this.datasender = [];
        this.newpostmedia = [];
        this.mediaforediteinfo = [];
        this.isloading = false;
        this.$snotify.error(error.message, "Error!");
        // hidden modal
        this.addpersonalpost.hide();
      }
    },
  },
  mounted() {
    this.addpersonalpost = new Modal(`#staticEditGroup${this.post.id}`);
  },
};
</script>

<style scoped lang="scss">
.add_post_card {
  padding: 1rem;
  cursor: pointer;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin: 0px -5px;
  }

  form {
    height: 100%;
    cursor: pointer;
    overflow: hidden;

    .input_text {
      width: 100%;
      border-radius: 25px;
      height: 100% !important;
      border: 1px solid #777;
      padding: 0.5rem 1rem;
      font-weight: bold;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }
  }
}

.modal {
  background-color: rgba(255, 255, 255, 0.527);
  .modal-content {
    border-radius: 25px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

    .modal-header {
      .profile_image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .modal-body {
      label {
        cursor: pointer;
        font-weight: bold;
        font-size: 1.5rem;

        .icon {
          width: 40px;
          height: 40px;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
.textarea {
  width: 100% !important;
  background-color: transparent;
  color: black !important;
  border: 1px solid #777;
  padding: 0.5rem 1rem;
  font-weight: bold;
  border-radius: 15px;
  height: auto;

  &::placeholder {
    color: black !important;
  }

  &:focus {
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
}
@media screen and (min-width: 300px) and (max-width: 550px) {
  .add_post_card {
    img {
      width: 32px !important;
      height: 35px !important;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 299px) {
  .add_post_card {
    img {
      display: none !important;
    }
  }

  .input_text {
    width: 100%;
    border-radius: 25px;
    height: 100% !important;
    border: 1px solid #777;
    padding: 0.4rem 1rem !important;
    font-weight: normal !important;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}
.add_post_card:lang(ar) {
  direction: rtl;
}
</style>
