import { apolloClientPosts } from "@/graphql/apollo";
import { ALL_ANSWERS } from "@/graphql/Fragments/posts/question/allAnswers";
import { ALL_QUESTIONS } from "@/graphql/Fragments/posts/question/allQuestion";
import { DELETE_ANSWER } from "@/graphql/Fragments/posts/question/answers/deleteAnswer";
import { RATING } from "@/graphql/Fragments/posts/question/answers/rating";
import { STORE_ANSWER } from "@/graphql/Fragments/posts/question/answers/storeAnswer";
import { UPDATE_ANSWER } from "@/graphql/Fragments/posts/question/answers/updateAnswer";
import { STORE_QUESTION } from "@/graphql/Fragments/posts/question/storeQuestion";
import { UPDATE_QUESTION } from "@/graphql/Fragments/posts/question/updateQuestionMutation";

class questionsServices {
  saveQuestion(data) {
    const response = apolloClientPosts.mutate({
      mutation: STORE_QUESTION,
      variables: {
        page_id: parseInt(data.page_id) ? parseInt(data.page_id) : null,
        content: data.content ? data.content : undefined,
        privacy: data.privacy,
        custom_profile_ids: data.custom_profile_ids
          ? data.custom_profile_ids
          : [],
        friends_ids: data.friends_ids ? data.friends_ids : [],
        media: data.media ? data.media : undefined,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  updateQuestion(data) {
    const response = apolloClientPosts.mutate({
      mutation: UPDATE_QUESTION,
      variables: {
        post_id: parseInt(data.post_id) ? parseInt(data.post_id) : null,
        content: data.content ? data.content : undefined,
        privacy: data.privacy,
        custom_profile_ids: data.custom_profile_ids
          ? data.custom_profile_ids
          : [],
        friends_ids: data.friends_ids ? data.friends_ids : [],
        media: data.media ? data.media : undefined,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  saveAnswer(data) {
    const response = apolloClientPosts.mutate({
      mutation: STORE_ANSWER,
      variables: {
        page_id: parseInt(data.page_id) ? parseInt(data.page_id) : undefined,
        post_id: parseInt(data.post_id) ? parseInt(data.post_id) : null,
        body: data.body ? data.body : undefined,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  updateAnswer(data) {
    const response = apolloClientPosts.mutate({
      mutation: UPDATE_ANSWER,
      variables: {
        page_id: parseInt(data.page_id) ? parseInt(data.page_id) : undefined,
        id: parseInt(data.id) ? parseInt(data.id) : null,
        body: data.body ? data.body : undefined,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  rating(data) {
    const response = apolloClientPosts.mutate({
      mutation: RATING,
      variables: {
        page_id: parseInt(data.page_id) ? parseInt(data.page_id) : undefined,
        id: parseInt(data.id),
        rate: data.rate,
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }
  deleteAnswer(id) {
    const response = apolloClientPosts.mutate({
      mutation: DELETE_ANSWER,
      variables: {
        id: parseInt(id),
      },
      fetchPolicy: "no-cache",
    });
    return response;
  }

  allQuestions(data) {
    const response = apolloClientPosts.query({
      query: ALL_QUESTIONS,
      variables: {
        page: parseInt(data.page) ? parseInt(data.page) : undefined,
        // limit: parseInt(limit),
      },
      fetchPolicy: "no-cache",
    });

    return response;
  }
  allAnswers(data) {
    const response = apolloClientPosts.query({
      query: ALL_ANSWERS,
      variables: {
        post_id: parseInt(data.post_id),
        page: parseInt(data.page) ? parseInt(data.page) : undefined,
        topAnswers: data.topAnswers ? data.topAnswers : undefined,
      },
      fetchPolicy: "no-cache",
    });

    return response;
  }
}
export default new questionsServices();
