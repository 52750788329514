<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="AddStory2"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <!-- Vertically centered modal -->
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title w-100">
            <button
              v-if="!isloading"
              :disabled="postsmedia.length == 0 || videidurationErrorMessage.length > 0"
              type="button"
              class="btn btn-success"
              
              @click.prevent="addStroy"
            >
              {{ $t("Done") }}
            </button>
            <button v-else disabled class="btn btn-success" type="button">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              {{ $t("Loading ...") }}
            </button>
          </div>
          <div class="close-dir">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="deleteitem()"
            ></button>
          </div>
        </div>
        <div class="modal-body">
          <div>
            <div class="mt-3 text-center">
              <label
                for="uploadstory"
                class="d-flex align-items-center justify-content-center"
              >
                {{ $t("Upload Media") }}
                <font-awesome-icon class="icon" icon="fas fa-file-upload" />
              </label>
              <input
                type="file"
                id="uploadstory"
                :disabled="postsmedia.length > 0"
                ref="filestory"
                @change="uploadFile(this.postsmedia, this.videidurationErrorMessage)"
                hidden
                accept="image/*, video/*"
              />
            </div>
          </div>
          <div class="mt-3 mb-3 text-center">
            <p class="text-danger text-center" v-if="videidurationErrorMessage.length > 0">{{ $t(videidurationErrorMessage[0]) }}</p>
            <div
              v-if="postsmedia.length > 0"
              style="display: inline-block; position: relative; height: 200px;overflow: hidden;"
            >
              <button
                type="button"
                class="btn-close"
                aria-label="Close"
                style="position: absolute; top: 2px; right: 2px"
                @click="deleteitem()"
              ></button>
              <img crossorigin="anonymous" 
                v-if="postsmedia[0].type.includes('image')"
                :src="postsmedia[0].url"
                  style="
                  width: 100px;
                  height: 100px;
                  margin: 2px;
                  border: 1px solid #777;
                "
              />
              <video crossorigin="anonymous"  v-else id="aspect_video" controls style="width: 100%; height: 100%; object-fit: contain;" :src="postsmedia[0].url"></video>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";
import StoryService from "@/services/story/story.services";
import { getStorage, ref, uploadBytes, getDownloadURL, uploadString } from "firebase/storage";
import { uuid } from "vue-uuid";
export default {
  data() {
    return {
      page: localStorage.getItem('page') ? JSON.parse(localStorage.getItem("page")) : "",
      isloading: false,
      media: null,
      file: null,
      datasender: {},
      postsmedia: [],
      videidurationErrorMessage: [],
      addmediastory: null
    };
  },
  mounted() {
    this.addmediastory = new Modal("#AddStory2");
  },
  methods: {
    deleteitem() {
      this.$emit("close");
      this.postsmedia = [];
      this.videidurationErrorMessage = []
    },
    async uploadFile(postsmedia, videidurationErrorMessage) {
      this.file = this.$refs.filestory.files[0];
      var vid = document.createElement("video");
      var fileURL = URL.createObjectURL(this.$refs.filestory.files[0]);
      vid.src = fileURL;
      let files = this.$refs.filestory.files;
      if(files[0].type.includes('video')){
                vid.ondurationchange = await function () {
                    if (vid.duration <= 31) {
                    postsmedia.pop();
                    videidurationErrorMessage.pop();
                    postsmedia.push({
                        type: files[0].type,
                        url: URL.createObjectURL(files[0]),
                        });
                        
                    } else {
                    postsmedia.pop();
                    postsmedia.push({
                        type: files[0].type,
                        url: URL.createObjectURL(files[0]),
                        });
                    videidurationErrorMessage.pop();
                    videidurationErrorMessage.push(
                        "Video Must Be Less Than 30 Seconds"
                    );
                    }
                };
            }else {
                postsmedia.pop();
                    postsmedia.push({
                        type: files[0].type,
                        url: URL.createObjectURL(files[0]),
                        });
            }
            
      // this.postsmedia.push({
      //   type: this.$refs.filestory.files[0].type,
      //   url: URL.createObjectURL(this.$refs.filestory.files[0]),
      // });
      // console.log(this.postsmedia)
    },
    async addStroy() {
      // this.$emit("close");
      this.isloading = true;
      this.$store.commit("story/SET_IS_LOADING_NEW_STORY");
      // if (this.media != null) {
      let item = null;
      let storage = getStorage();
      if(this.file.type.includes('image')){
        let unique_number = this.file.name + uuid.v4();
        let storageRef = ref(storage,"stories/" + unique_number);
        await uploadBytes(storageRef, this.file).then((snapshot) => {
          let meme = snapshot.metadata.contentType.split("/");
          item = {
            size: snapshot.metadata.size,
            media_type: meme[0],
            mime_type: meme[1],
            fullPath: snapshot.metadata.fullPath,
          };
        });
        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
          item.src_thum = url;
          item.src_icon = url;
        });
        this.datasender.media = item;
  
        // }
        this.page != '' ? this.datasender.page_id = this.page.id : ''
  
        return StoryService.CreateStory(this.datasender).then((response) => {
            if (response.data.status == 201) {
              this.isloading = false;
              this.datasender = {};
              this.file = null;
              this.postsmedia = [];
              this.$store.commit("story/SET_IS_LOADING_NEW_STORY");
              this.$store.commit("story/ADD_NEW_STORY", response.data.data);
              this.addmediastory.hide()
              this.page != '' ? this.$router.push({ name: "StoriesView", params: { id: this.page.id }, query: {type: 'Page'} }) : this.$router.push({ name: "StoriesView", params: { id: response.data.data.user_id }, query: {type: 'Profile'} }) ;
  
              
            } else {
              this.$store.commit("story/SET_IS_LOADING_NEW_STORY");
            }
          }).catch((e) => {
            console.log(e)
            this.$snotify.error("Network Error!", "Error");
            this.$store.commit("story/SET_IS_LOADING_NEW_STORY");
          });

      }else{
        var vid = document.getElementById("aspect_video");
                // returns the intrinsic width of the video
                this.width_video = vid.videoWidth;
                // returns the intrinsic height of the video
                this.height_video = vid.videoHeight;

                let unique_number = this.file.name + uuid.v4();
                let storageRef = ref(storage, "stories/" + unique_number);
                await uploadBytes(storageRef, this.file).then((snapshot) => {
                    let meme = snapshot.metadata.contentType.split("/");
                    item = {
                    size: snapshot.metadata.size,
                    media_type: meme[0],
                    mime_type: meme[1],
                    width: this.width_video,
                    height: this.height_video,
                    fullPath: snapshot.metadata.fullPath,
                    };
                });
                await getDownloadURL(storageRef).then((url) => {
                    item.src_url = url;
                    item.src_thum = url;
                    item.src_icon = url;
                });

                // Get First Farame From Video To Img
                var canvas = document.createElement("canvas");
                canvas.width = vid.videoWidth;
                canvas.height = vid.videoHeight;
                var context = canvas.getContext('2d');
                context.drawImage(vid, 0, 0, canvas.width, canvas.height);
                // Convert the canvas to a data URL
                var dataUrl = canvas.toDataURL();
                // Upload the image to Firebase Storage
                let unique_number1 = uuid.v4();
                let storageRef1 = ref(storage, "CoverStoryVideo/" + unique_number1);
                await uploadString(storageRef1, dataUrl, 'data_url')
                await getDownloadURL(storageRef1).then((url) => {
                    item.src_thum =url
                });

                this.datasender.media = item;
                // console.log(item)
                this.page != '' ? this.datasender.page_id = this.page.id : ''
                return StoryService.CreateStory(this.datasender)
                    .then((response) => {
                    if (response.data.status == 201) {
                      this.isloading = false;
                      this.datasender = {};
                      this.file = null;
                      this.postsmedia = [];
                      this.$store.commit("story/SET_IS_LOADING_NEW_STORY");
                      this.$store.commit("story/ADD_NEW_STORY", response.data.data);
                      this.addmediastory.hide()
                      this.page != '' ? this.$router.push({ name: "StoriesView", params: { id: this.page.id }, query: {type: 'Page'} }) : this.$router.push({ name: "StoriesView", params: { id: response.data.data.user_id }, query: {type: 'Profile'} }) ;
          
                    }
                    })
                    .catch((error) => {
                        console.log(error)
                    });

      }
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  background-color: rgba(255, 255, 255, 0.527) !important;
}
.modal-content {
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.modal-header {
  border: none;
}
.modal-body {
  border: none;
  padding: 0px !important;

  label {
    cursor: pointer;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 2rem;

    .icon {
      width: 40px;
      height: 40px;
      margin-left: 0.5rem;
    }
  }
}
textarea {
  height: 300px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
.close-dir:lang(ar) {
  direction: ltr;
}
.close-dir:lang(ku) {
  direction: ltr;
}
</style>
