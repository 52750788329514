<template>
  <div dir="auto" class="row m-0 justify-content-center">
    <!-- Start Sidebar -->
    <div class="col-md-4 left order-first">
      <div class="content">
        <div class="card-header">
          <span>
            <router-link
              :to="{ name: 'HomeMarketPlace' }"
              class="router-link-to-path"
              style="font-size: 22px"
              >{{ $t("Market Place") }}
            </router-link>
            /
            <span class="font-mediaNas" style="font-size: 22px">
              <b>{{ $t("Add a new offer") }}</b>
            </span>
          </span>
        </div>
        <div class="card-body">
          <!-- user's Photo -->
          <div class="d-flex align-items-center">
            <img crossorigin="anonymous" 
              class="profile_image"
              v-if="USER.profile.has_media_profile === false"
              src="@/assets/story_img/user.png"
              alt=""
            />
            <img crossorigin="anonymous" 
              class="profile_image"
              v-else-if="USER.profile.media[0].collection_name === 'profile'"
              :src="USER.profile.media[0].src_url"
              alt=""
            />
            <img crossorigin="anonymous" 
              class="profile_image"
              v-else
              :src="USER.profile.media[1].src_url"
              alt=""
            />
            <div class="ms-1">
              <p class="mb-0">{{ full_name }}</p>
              <small class="text-muted">
                {{ $t("Item for sale on Marketplace") }}
              </small>
            </div>
          </div>
          <br />
          <div class="style-margin">
            <h5>{{ $t("Required") }}</h5>
            <p>{{ $t("Do as much of a description as possible.") }}</p>

            <!-- row2 upload photos home-->
            <div class="row mt-2 row2">
              <!-- col upload images -->
              <div class="col-11 mt-0">
                <div class="mt-2 text-center col-images-upload">
                  <label
                    title="Upload Media"
                    for="uploadSchedule"
                    class="d-flex align-items-center justify-content-center m-4"
                  >
                    <!-- {{ $t("Upload Media") }} -->
                    {{ $t("Add Photos") }}
                    <AddMedia width="45" height="45"></AddMedia>
                  </label>

                  <input
                    type="file"
                    id="uploadSchedule"
                    ref="photosHome"
                    @change="uploadPhotosHome"
                    hidden
                    accept="image/*"
                    multiple
                  />
                </div>
                <div class="w-100">
                  <!-- From Server -->
                  <small class="text-danger" v-if="errorMessages.media">
                    {{ errorMessages.media[0] }}
                  </small>
                </div>
              </div>

              <div class="mt-3 text-center">
                <div
                  v-for="(img, index) in mediaHome"
                  :key="index"
                  style="display: inline-block; position: relative"
                >
                  <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    style="
                      position: absolute;
                      top: 2px;
                      right: 2px;
                      cursor: pointer;
                      z-index: 9999;
                    "
                    @click="deleteitem(index)"
                  ></button>
                  <img crossorigin="anonymous" 
                    v-if="img.type.includes('image')"
                    :src="img.url"
                    style="
                      width: 50px;
                      height: 50px;
                      margin: 2px;
                      border: 1px solid #777;
                    "
                  />
                </div>
              </div>
            </div>
            <br />
            <!-- input name -->
            <div class="row">
              <div class="col-11">
                <div class="form-floating">
                  <input
                    ref="myNameRef"
                    :class="{ 'is-invalid': nameError }"
                    type="text"
                    class="form-control vinput__add-input"
                    id="floatingInput"
                    placeholder="Name"
                    dir="auto"
                    required
                    autocomplete="off"
                    min="0"
                    v-model="name"
                  />
                  <label for="floatingInput">{{ $t("Name") }}</label>
                </div>
                <div class="w-100">
                  <p style="color: red">
                    {{ this.nameErrMsg }}
                  </p>
                  <!-- From Server -->
                  <small class="text-danger" v-if="errorMessages.name">
                    {{ errorMessages.name[0] }}
                  </small>
                </div>
              </div>
            </div>
            <br />
            <!-- input  air Element status -->
            <div class="row">
              <div class="col-11">
                <div class="form-floating">
                  <select
                    class="form-select vinput__add-input"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    v-model="element_status"
                  >
                    <option value="new">{{ $t("new") }}</option>
                    <option value="used-Like New">
                      {{ $t("used-Like New") }}
                    </option>
                    <option value="used good">{{ $t("used good") }}</option>
                    <option value="used fair">{{ $t("used fair") }}</option>
                  </select>
                  <label for="floatingSelect">{{
                    $t("air Conditioning Type")
                  }}</label>
                </div>
              </div>
            </div>
            <br />
            <!-- input price sale -->
            <div class="row">
              <div class="col-11">
                <div class="form-floating">
                  <input
                    :class="{ 'is-invalid': priceError }"
                    type="number"
                    class="form-control vinput__add-input"
                    id="floatingInput"
                    placeholder="price"
                    dir="auto"
                    required
                    autocomplete="off"
                    min="0"
                    v-model="price"
                  />
                  <label for="floatingInput">{{ $t("price") }}</label>
                </div>
                <div class="w-100">
                  <p style="color: red">
                    {{ this.priceErrMsg }}
                  </p>
                </div>
              </div>
            </div>
            <br />
            <!-- input subcategory -->
            <div class="row">
              <div class="col-11">
                <div class="form-floating">
                  <select
                    ref="myCategoryRef"
                    :class="{ 'is-invalid': isFieldValid }"
                    class="form-select vinput__add-input"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    v-model="category_id"
                  >
                    <option
                      v-for="(sub_cat, index) in sub_categories"
                      :key="index"
                      :value="sub_cat.id"
                    >
                      {{ sub_cat.name }}
                    </option>
                  </select>
                  <label for="floatingSelect">{{ $t("Category") }}</label>
                </div>
                <div class="w-100">
                  <p style="color: red" v-if="isFieldValid">
                    this field is required
                  </p>
                  <!-- From Server -->
                  <small class="text-danger" v-if="errorMessages.category_id">
                    {{ errorMessages.category_id[0] }}
                  </small>
                </div>
              </div>
            </div>
            <br />
            <!-- input Country -->
            <div class="row">
              <div class="col-11">
                <div class="form-floating">
                  <input
                    class="form-control vinput__add-input"
                    type="text"
                    v-model="country_name"
                    :placeholder="$t('country')"
                  />
                  <label for="floatingInput">{{ $t("country") }}</label>
                  <div
                    class="displayallcities"
                    v-if="existCountry"
                    id="allcity"
                    v-click-outside="onClickOutside1"
                  >
                    <div
                      v-if="loading_search_Country"
                      class="d-flex justify-content-center pt-4 list-unstyled"
                    >
                      <div class="spinner-border text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <ul class="list-unstyled" v-else>
                      <li
                        class="mb-1 text-black"
                        v-for="item in allcountries"
                        :key="item.id"
                        style="cursor: pointer"
                        @click="choseCountry(item)"
                      >
                        {{ item.name }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <!-- input city -->
            <div class="row">
              <div class="col-11">
                <div class="form-floating">
                  <input
                    ref="myCityRef"
                    :class="{ 'is-invalid': cityError }"
                    class="form-control vinput__add-input"
                    type="text"
                    :readonly="country_name == ''"
                    v-model="city_name"
                    :placeholder="$t('City')"
                  />
                  <label for="floatingInput">{{ $t("City") }}</label>
                  <div
                    class="displayallcities"
                    v-if="exist"
                    id="allcity"
                    v-click-outside="onClickOutside"
                  >
                    <div
                      v-if="loading_search_City"
                      class="d-flex justify-content-center pt-4 list-unstyled"
                    >
                      <div class="spinner-border text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <ul class="list-unstyled" v-else>
                      <li
                        class="mb-1 text-black"
                        v-for="item in allcities"
                        :key="item.id"
                        style="cursor: pointer"
                        @click="choseCity(item)"
                      >
                        {{ item.name }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="w-100">
                  <p style="color: red">
                    {{ this.cityErrMsg }}
                  </p>
                  <!-- From Server -->
                  <small class="text-danger" v-if="errorMessages.city_id">
                    {{ errorMessages.city_id[0] }}
                  </small>
                </div>
              </div>
            </div>
            <br />
            <!-- input Description -->
            <div class="row">
              <div class="col-11">
                <div class="form-floating">
                  <input
                    ref="myDescriptionRef"
                    :class="{ 'is-invalid': descriptionError }"
                    type="text"
                    class="form-control vinput__add-input"
                    id="floatingInput"
                    placeholder="Description"
                    dir="auto"
                    required
                    autocomplete="off"
                    v-model="description"
                  />
                  <label for="floatingInput">{{ $t("Description") }}</label>
                </div>
                <div class="w-100">
                  <p style="color: red">
                    {{ this.descriptionErrMsg }}
                  </p>
                  <!-- From Server -->
                  <small class="text-danger" v-if="errorMessages.description">
                    {{ errorMessages.description[0] }}
                  </small>
                </div>
              </div>
            </div>
            <br />
            <!-- input Address -->
            <div class="row">
              <div class="col-11">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control vinput__add-input"
                    id="floatingInput"
                    placeholder="rental Address"
                    dir="auto"
                    required
                    autocomplete="off"
                    v-model="addtional_data[0].address"
                  />
                  <label for="floatingInput">{{ $t("Address") }}</label>
                </div>
              </div>
            </div>
            <br />
            <!-- input phoneNumber -->
            <div class="row">
              <div class="col-11">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control vinput__add-input"
                    id="floatingInput"
                    placeholder="rental Address"
                    dir="auto"
                    required
                    autocomplete="off"
                    v-model="addtional_data[0].phoneNumber"
                  />
                  <label for="floatingInput">{{ $t("phone Number") }}</label>
                </div>
              </div>
            </div>
            <br />
            <!-- add video -->
            <div class="row mt-2 row2">
              <!-- col upload images -->
              <div class="col-11 mt-0">
                <div class="mt-2 text-center col-images-upload">
                  <label
                    title="Upload Media"
                    for="uploadVideo"
                    class="d-flex align-items-center justify-content-center m-4"
                  >
                    <!-- {{ $t("Upload Media") }} -->
                    {{ $t("Add Video") }}
                    <AddMedia width="45" height="45"></AddMedia>
                  </label>

                  <input
                    type="file"
                    id="uploadVideo"
                    ref="videosHome"
                    @change="uploadVideoHome"
                    hidden
                    accept="video/*"
                    multiple
                  />
                </div>
              </div>

              <div class="mt-3 text-center">
                <div
                  v-for="(img, index) in mediaVideo"
                  :key="index"
                  style="display: inline-block; position: relative"
                >
                  <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    style="
                      position: absolute;
                      top: 2px;
                      right: 2px;
                      cursor: pointer;
                      z-index: 9999;
                    "
                    @click="delete_item_video(index)"
                  ></button>

                  <video crossorigin="anonymous" 
                    :id="'aspect_video' + index"
                    v-if="img.type.includes('video')"
                    :src="img.url"
                    style="
                      vertical-align: middle;
                      margin: 2px;
                      width: 50px;
                      height: 50px;
                      border: 1px solid #777;
                    "
                  ></video>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
        <div class="card-footer">
          <!-- <button
            v-if="!is_loading"
            class="btn btn-primary mt-4 btn-style"
            style="bottom: 20px; width: 100%"
            @click="saveItem()"
            :disabled="
              !this.name ||
              this.mediaHome.length == 0 ||
              !this.category_id ||
              !this.city_id ||
              !this.price ||
              !this.description ||
              this.addtional_data[0].address == null
            "
          >
            {{ $t("Create") }}
          </button> -->
          <button
            v-if="!is_loading"
            class="btn btn-primary mt-4 btn-style"
            style="bottom: 20px; width: 100%"
            @click="saveItem()"
          >
            {{ $t("Create") }}
          </button>
          <el-button v-else type="primary" loading size="large" class="m-2">{{
            $t("Pleas Wait")
          }}</el-button>
        </div>
      </div>
    </div>
    <!-- End Sidebar -->
    <div class="container-fluid">
      <div class="content">
        <div
          class="col-lg-8 offset-md-4 right center mb-0 pt-1 px-3 order-last"
        >
          <div
            class="container m-3"
            style="
              background: #ffffff;
              filter: drop-shadow(0px 6px 7px black);
              border-radius: 10px;
            "
          >
            <div class="row m-3">{{ $t("preview") }}</div>
            <div
              class="mb-2"
              style="height: 450px; border: 1px solid #ccc; border-radius: 10px"
            >
              <div class="row m-3">
                <div class="col-6">
                  <div class="">
                    <div class="row">
                      <div
                        class="col-md-12"
                        v-if="this.mediaHome.length != 0"
                        style="filter: opacity(0.5)"
                      >
                        <div
                          id="custCarousel"
                          class="carousel slide"
                          data-bs-ride="carousel"
                          align="center"
                        >
                          <!-- slides -->
                          <div class="carousel-inner">
                            <div
                              v-for="(photo, index) in this.mediaHome"
                              :key="index"
                              :class="[
                                'carousel-item',
                                index === 0 ? 'active' : '',
                              ]"
                            >
                              <img crossorigin="anonymous"  :src="photo.url" alt="PhotosItem" v-viewer />
                            </div>
                          </div>

                          <!-- Left right -->
                          <a
                            class="carousel-control-prev"
                            href="#custCarousel"
                            data-bs-slide="prev"
                            style="opacity: 1"
                          >
                            <PreviousSlide></PreviousSlide>
                          </a>
                          <a
                            class="carousel-control-next"
                            href="#custCarousel"
                            data-bs-slide="next"
                            style="opacity: 1"
                          >
                            <NextSlide></NextSlide>
                          </a>

                          <!-- Thumbnails -->
                          <ol class="carousel-indicators list-inline">
                            <li
                              v-for="(photo, index) in this.mediaHome"
                              :key="index"
                              :class="[
                                'list-inline-item',
                                index === 0 ? 'active' : '',
                              ]"
                            >
                              <a
                                :id="'carousel-selector-' + index"
                                class="selected"
                                :data-bs-slide-to="index"
                                data-bs-target="#custCarousel"
                              >
                                <img crossorigin="anonymous"  :src="photo.url" class="img-fluid" />
                              </a>
                            </li>
                          </ol>
                        </div>
                      </div>
                      <div v-else class="text-center">
                        <div class="container3">
                          <div class="child">
                            <h4>{{ $t("Preview your offers list") }}</h4>
                            <p>
                              {{
                                $t(
                                  "When you create your listing, you can preview how it appears to others in the store"
                                )
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-6"
                  style="
                    filter: opacity(0.5);
                    overflow-y: scroll;
                    height: 370px;
                  "
                >
                  <h3 style="word-wrap: break-word">
                    {{ this.name || $t("Name") }}
                  </h3>
                  <h5>{{ this.price || $t("price") }}</h5>
                  <h6>
                    {{ $t("Viewed a few seconds ago") }}
                  </h6>

                  <h4>{{ $t("Advanced details") }}</h4>
                  <p style="word-wrap: break-word">
                    {{
                      this.description || $t("The description will appear here")
                    }}
                  </p>
                  <span>
                    {{ $t("address") }} :
                    {{ this.addtional_data[0].address }}</span
                  >
                  <br />
                  <span>
                    {{ $t("phoneNumber") }} :
                    {{ this.addtional_data[0].phoneNumber }}</span
                  >
                  <br />
                  <hr />
                  <div class="clearfix mb-1">
                    <span class="float-start">
                      {{ $t("Seller information") }}
                    </span>

                    <span
                      class="float-end mx-3 mb-2"
                      style="color: #3614cc; font-weight: 600"
                    >
                      {{ $t("Seller details") }}
                    </span>
                  </div>
                  <div class="d-flex align-items-center">
                    <img crossorigin="anonymous" 
                      class="profile_image"
                      v-if="USER.profile.has_media_profile === false"
                      src="@/assets/story_img/user.png"
                      alt=""
                    />
                    <img crossorigin="anonymous" 
                      class="profile_image"
                      v-else-if="
                        USER.profile.media[0].collection_name === 'profile'
                      "
                      :src="USER.profile.media[0].src_url"
                      alt=""
                    />
                    <img crossorigin="anonymous" 
                      class="profile_image"
                      v-else
                      :src="USER.profile.media[1].src_url"
                      alt=""
                    />
                    <div class="ms-1">
                      <p class="mb-0">{{ USER.profile.name }}</p>
                      <!-- <small class="text-muted">
                {{ $t("Item for sale on Marketplace") }}
              </small> -->
                    </div>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PreviousSlide from "@/components/marketplace/icons/PreviousSlide.vue";
import NextSlide from "@/components/marketplace/icons/NextSlide.vue";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { uuid } from "vue-uuid";
import AddMedia from "@/icons/Groups/AddMedia.vue";
import { mapGetters } from "vuex";
import MarketPlaceService from "@/services/MarketPlace/MarketPlaceService";
import vClickOutside from "click-outside-vue3";
import LocationService from "@/services/Location/LocationService";
import validationInputsMixin from "@/mixins/marketplace/validationInputsMixin";
export default {
  components: {
    AddMedia,
    PreviousSlide,
    NextSlide,
  },
  data() {
    return {
      loading_sub_categories: false,
      sub_categories: [],

      width_video: null,
      height_video: null,
      media: [],
      mediaHome: [],
      datasender: [],
      // when add video
      media2: [],
      mediaVideo: [],
      data_sender2: [],

      is_loading: false,
      name: "",
      category_id: null,
      element_status: "new",
      price: 0,
      description: "",
      category: "ItemForSale",
      addtional_data: [
        {
          address: null,
          phoneNumber: null,
        },
      ],
      city_name: "",
      country_name: "",
      country_id: null,
      city_id: null,
      allcities: [],
      allcountries: [],
      loading_search_City: false,
      loading_search_Country: false,
      exist: false,
      existCountry: false,
    };
  },
  mixins: [validationInputsMixin],
  created() {
    this.get_sub_category(this.$route.params.base_id);
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
    }),
    // get Full Name
    full_name() {
      return (
        JSON.parse(localStorage.getItem("user")).profile.first_name +
        " " +
        JSON.parse(localStorage.getItem("user")).profile.last_name
      );
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  watch: {
    name() {
      if (this.name.length == 0) {
        this.nameError = true;
        this.nameErrMsg = this.$i18n.t("name field is required");
      } else {
        this.nameError = false;
        this.nameErrMsg = "";
      }
    },
    price() {
      if (this.price.length == 0 || this.price == 0) {
        this.priceError = true;
        this.priceErrMsg = this.$i18n.t("price field is required");
      } else {
        this.priceError = false;
        this.priceErrMsg = "";
      }
    },
    description() {
      if (this.description.length == 0) {
        this.descriptionError = true;
        this.descriptionErrMsg = this.$i18n.t("description field is required");
      } else {
        this.descriptionError = false;
        this.descriptionErrMsg = "";
      }
    },
    category_id() {
      // Watch for changes in the field value
      if (this.category_id == null) {
        this.isFieldValid = true;
      } else {
        this.isFieldValid = false;
      }
    },
    city_name() {
      if (this.city_name == "") {
        this.cityError = true;
        this.cityErrMsg = this.$i18n.t("city filed is required");
      } else {
        this.cityError = false;
        this.cityErrMsg = "";
      }
      this.getResults();
    },
    country_name(){
      this.getResultsCountry()
    },
  },
  methods: {
    onClickOutside() {
      this.exist = false;
    },
    onClickOutside1() {
      this.existCountry = false;
    },
    async getResultsCountry() {
        this.existCountry = true
        this.loading_search_Country = true;
        await LocationService.Get_All_Countries(this.country_name).then((res) => {
            this.allcountries = res.data.data.items
        });
        this.loading_search_Country = false;
    },
    async getResults() {
      this.exist = true;
      this.loading_search_City = true;
      await LocationService.Get_All_Cities(this.city_name, this.country_id).then((res) => {
        this.allcities = res.data.data.items;
      });
      this.loading_search_City = false;
      // document.getElementById('allcity').classList.remove('visually-hidden')
    },
    choseCountry(data){
        this.country_name = data.name
        this.country_id = data.id
        this.existCountry = false
    },
    choseCity(data) {
      this.city_name = data.name;
      this.city_id = data.id;
      // document.getElementById('allcity').classList.add('visually-hidden')
      this.exist = false;
    },

    async saveItem() {
      // Validate all inputs
      this.validateInputs();

      this.is_loading = true;
      for (let k = 0; k < this.media.length; k++) {
        let storage = getStorage();
        let unique_number = this.media[k].name + uuid.v4();
        let storageRef = ref(storage, "items/test/" + unique_number);
        let item = null;
        await uploadBytes(storageRef, this.media[k])
          .then((snapshot) => {
            let meme = snapshot.metadata.contentType.split("/");
            item = {
              size: snapshot.metadata.size,
              media_type: meme[0],
              mime_type: meme[1],
              fullPath: snapshot.metadata.fullPath,
            };
            this.datasender.push(item);
          })
          .catch((error) => {
            console.log(error);
            this.$snotify.error("Network Error!", "Error");
          });
        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
          item.src_thum = url;
          item.src_icon = url;
        });
      }
      if (this.media2.length != 0) {
        for (let k = 0; k < this.media2.length; k++) {
          var vid = document.getElementById("aspect_video" + k);
          // returns the intrinsic width of the video
          this.width_video = vid.videoWidth;
          // returns the intrinsic height of the video
          this.height_video = vid.videoHeight;
          let storage2 = getStorage();
          let unique_number2 = this.media2[k].name + uuid.v4();
          let storageRef2 = ref(storage2, "items/test/" + unique_number2);
          let item2 = null;
          await uploadBytes(storageRef2, this.media2[k])
            .then((snapshot) => {
              let meme2 = snapshot.metadata.contentType.split("/");
              item2 = {
                size: snapshot.metadata.size,
                media_type: meme2[0],
                mime_type: meme2[1],
                width: this.width_video,
                height: this.height_video,
                fullPath: snapshot.metadata.fullPath,
              };
              this.data_sender2.push(item2);
            })
            .catch((error) => {
              // this.UPDATE_IS_LOADING_NEW_POST();
              console.log(error);
              this.$snotify.error("Network Error!", "Error");
            });
          await getDownloadURL(storageRef2).then((url) => {
            item2.src_url = url;
            item2.src_thum = url;
            item2.src_icon = url;
          });
        }
      }
      let data = {
        name: this.name,
        category_id: this.category_id,
        element_status: this.element_status,
        city_id: this.city_id,
        price: this.price,
        description: this.description,
        category: this.category,
        addtional_data: this.addtional_data,
        media: this.datasender,
        VideoMedia: this.data_sender2,
      };
      MarketPlaceService.s_add_item(data)
        .then((res) => {
          this.$snotify.success("add successfully", "marketplace");
          this.is_loading = false;
          this.width_video = null;
          this.height_video = null;
          this.media = [];
          this.mediaHome = [];
          this.media2 = [];
          this.mediaVideo = [];
          this.name = "";
          this.element_status = "new";
          this.city_id = null;
          this.city_name = null;
          this.price = 0;
          this.category_id = null;
          this.description = "";
          this.addtional_data[0].address = null;
          this.addtional_data[0].phoneNumber = null;
          this.$router.push({
            name: "ShowItem",
            params: { id: res.data.data.id },
          });
        })
        .catch((error) => {
          this.errorMessages = error.response.data.data;
          this.is_loading = false;
          this.$snotify.error("Something Wrong!", "Error");
        });
    },
    uploadPhotosHome() {
      let fileslength = this.$refs.photosHome.files.length;
      let files = this.$refs.photosHome.files;
      for (let i = 0; i < fileslength; i++) {
        this.media.push(files[i]);
        this.mediaHome.push({
          type: files[i].type,
          url: URL.createObjectURL(files[i]),
        });
      }
    },
    deleteitem(index) {
      this.mediaHome.splice(index, 1);
      this.media.splice(index, 1);
    },
    uploadVideoHome() {
      let fileslength = this.$refs.videosHome.files.length;
      let files = this.$refs.videosHome.files;
      for (let i = 0; i < fileslength; i++) {
        this.media2.push(files[i]);
        this.mediaVideo.push({
          type: files[i].type,
          url: URL.createObjectURL(files[i]),
        });
      }
    },
    delete_item_video(index) {
      this.mediaVideo.splice(index, 1);
      this.media2.splice(index, 1);
    },
    get_sub_category(base_id) {
      this.loading_sub_categories = true;
      MarketPlaceService.s_sub_category(base_id).then((res) => {
        this.sub_categories = res.data.data.subCategories;
        this.loading_sub_categories = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/Styles/Groups/sidebarCreateGroup.scss";
.card-body {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 380px;
  @include scrollBar;
}

.col-images-upload {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 10px;
  background: #ffffff;
}
.col-images-upload:hover {
  background: #d8d6d6;
}

// carousel

.container {
  margin-top: 100px;
  margin-bottom: 100px;
}

.carousel-inner img {
  width: 100%;
  height: 100%;
}

#custCarousel .carousel-indicators {
  position: static;
  margin-top: 20px;
}

#custCarousel .carousel-indicators > li {
  width: 100px;
}

#custCarousel .carousel-indicators li img {
  display: block;
  opacity: 0.5;
  height: 75px;
  width: 75px;
}

#custCarousel .carousel-indicators li.active img {
  opacity: 1;
}

#custCarousel .carousel-indicators li:hover img {
  opacity: 0.75;
}

.carousel-item img {
  width: 75%;
  height: 270px;
}

.container3 {
  margin: 10px;
  width: 350px;
  height: 353px;
  outline: dashed 4px rgb(39, 39, 39);
  position: relative;
  filter: opacity(0.5);
  background: #cccccc;
}

.child {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 50px;
  transform: translate(-50%, -50%);
}
.center:lang(ar) {
  margin-right: 33% !important;
  margin-left: 0 !important;
}
.center:lang(ku) {
  margin-right: 33% !important;
  margin-left: 0 !important;
}
</style>
