<template>
  <div>
    <img crossorigin="anonymous" 
      v-if="!page && !USER.profile.has_media_profile"
      src="@/assets/img_friends/default1.png"
    />
    <img crossorigin="anonymous" 
      v-else-if="!page && USER.profile.media[0].collection_name === 'profile'"
      :src="USER.profile.media[0].src_url"
      alt=""
    />
    <img crossorigin="anonymous" 
      v-else-if="!page && USER.profile.media[1].collection_name === 'profile'"
      :src="USER.profile.media[1].src_url"
      alt=""
    />
    <img crossorigin="anonymous" 
      v-else-if="page && !page.has_media_profile"
      src="@/assets/page/pageprofile.png"
    />
    <img crossorigin="anonymous" 
      v-else-if="page && page.media[0].collection_name === 'profile'"
      :src="page.media[0].src_url"
      alt=""
    />
    <img crossorigin="anonymous" 
      v-else-if="page && page.media[1].collection_name === 'profile'"
      :src="page.media[1].src_url"
      alt=""
    />
  </div>
</template>
<script>
export default {
  name: "ProfilePicture",
  props: ["page", "USER"],
};
</script>
<style lang="scss" scoped>
img {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  object-fit: cover;
}
</style>
