<template>
  <swiper
    :effect="'coverflow'"
    :grabCursor="true"
    :centeredSlides="true"
    :slidesPerView="'auto'"
    :initialSlide="1"
    :coverflowEffect="{
      rotate: 50,
      stretch: 0,
      depth: 100,
      modifier: 1,
      slideShadows: true,
    }"
    :pagination="{ clickable: true }"
    :autoplay="{ delay: 3000 }"
    :modules="modules"
    class="mySwiper"
  >
    <swiper-slide v-for="(image, index) in post.media" :key="index"
      ><img crossorigin="anonymous"  :src="image.src_url" v-viewer
    /></swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { EffectCoverflow, Pagination ,Autoplay } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/pagination";
export default {
  props: ["post"],
  data() {
    return {
      modules: [EffectCoverflow, Pagination,Autoplay],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
};
</script>
<style lang="scss" scoped>
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
