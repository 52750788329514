<template>
  <div class="container">
    <img crossorigin="anonymous"  class="pd-image" src="@/assets/images/solidPure.png" alt="Profile image" />
    <div class="centered">{{ this.nameCollection }}</div>
    <span>
      <router-link
        class="router-link-to-profile"
        :to="{
          name: 'showCollection',
          params: { id: collection.id },
        }"
        ><b class="mx-2">{{ collection.name }}</b>
      </router-link>
    </span>
  </div>
  <!-- <span class="style-icon">
      <span class="m-3">
        <span class="col-2">{{ this.nameCollection }}</span>
      </span>
    </span>
    <router-link
      class="router-link-to-profile"
      :to="{
        name: 'showCollection',
        params: { id: collection.id },
      }"
      ><b>{{ collection.name }}</b></router-link
    > -->
</template>

<script>
export default {
  name: "CollectionComponent",
  data() {
    return {
      nameCollection: this.collection.name
        .split(" ")
        .map((x) => x[0].toUpperCase())
        .join(""),
    };
  },
  props: ["collection"],
};
</script>
<style lang="scss" scoped>
.style-icon {
  color: #fff;
  font-size: 30px;
  background: rgb(85 83 104);
  margin: 1px 11px;
  border-radius: 6px;
}
.router-link-to-profile {
  text-decoration: none;
  color: #111;
}
.pd-image {
  width: 25%;
  height: 45px;
  margin-right: 5%;
  border-radius: 6px;
  margin-bottom: 10px;
}
.centered {
  position: absolute;
  top: 40%;
  left: 15%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: 90%;
}
.centered:lang(ar) {
  left: 80%;
}
.centered:lang(ku) {
  left: 80%;
}
.container {
  position: relative;
  color: white;
}
</style>
