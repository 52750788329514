<template>
  <svg
    height="25"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-9.31019674359186 -9.31019674359186 200.60019674359185 200.59919674359185"
  >
    <linearGradient id="a_like" x1="47.061%" x2="47.061%" y1="-3.394%" y2="96.606%">
      <stop offset="0" stop-color="#37aeff" />
      <stop offset=".05" stop-color="#37aeff" />
      <stop offset="1" stop-color="#1861f7" />
    </linearGradient>
    <g fill="none">
      <path
        d="M0 95.645c0 52.823 42.822 95.644 95.645 95.644 52.823 0 95.644-42.821 95.644-95.644C191.29 42.822 148.468 0 95.645 0A95.617 95.617 0 0 0 0 95.645"
        fill="url(#a_like)"
      />
      <path
        d="M151.421 99.986a9.095 9.095 0 0 0-3.901-8.737 18.08 18.08 0 0 0 3.6-8.628c0-8.463-7.941-10.99-20.168-10.99-7.27.08-14.51.936-21.597 2.555.66-3.627 5.496-14.15 5.496-17.667 0-7.31-1.731-16.486-8.436-19.976a11.925 11.925 0 0 0-6.154-1.593c-2.68-.126-5.303.8-7.309 2.583a6.32 6.32 0 0 0-.742 3.681l1.21 13.738c0 10.99-16.899 24.729-16.899 40.528v33.136c0 5.88 7.886 10.056 19.234 10.056h31.46c8.243 0 10.084-1.428 12.2-5.275a7.583 7.583 0 0 0-.166-8.023 12.364 12.364 0 0 0 7.749-8.93c.487-2.412.118-4.92-1.044-7.089a9.48 9.48 0 0 0 5.495-9.369M48.743 80.945h9.836a8.243 8.243 0 0 1 8.243 8.243V135.1a8.243 8.243 0 0 1-8.243 8.242h-9.836a8.243 8.243 0 0 1-8.243-8.242V89.298a8.243 8.243 0 0 1 8.243-8.243"
        fill="#fff"
      />
    </g>
  </svg>
</template>
