<template>
  
  <div >
    <div class="row m-0" dir="auto">
      <!-- SidBar -->
      <StoryShowSkeltonSideBar v-if="peforeloadingSideBar" />
      <div class="col-lg-3 sidebar position-relative" v-else>
        <!-- Greate Story -->
        <div class="visibleSideBarClass" style="position: absolute;top: 0;left: 0;width: 100%; height: 100%;background-color: transparent;z-index: 999;" 
          v-if="visibleSideBar"></div>
        <div class="d-flex aligm-items-center pt-2 button">
          <!-- <button
            type="button"
            class="btn-close mb-3"
            @click="back"
            aria-label="Close"
          ></button>
          <br /> -->
          <button
            type="button"
            class="btn style-create-story w-100 forlarge ms-3 mb-3"
            @click="gotoaddstory"
          >
            {{ $t("Create Story") }}
          </button>
          <br />
          <button
            type="button"
            class="btn style-create-story w-100 formobile ms-3 mb-3"
            @click="stoptprogressandshowview()"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            {{ $t("Create Story") }}
          </button>
        </div>
        <CreateStoryFromStoriesViews
        />
        <!-- Greate Story -->
        
        <!-- Show Boxes Story -->
        <div
          v-for="(item, index) in Allusers_with_Story"
          :id="`boxstory${item.id}`"
          :class="[
            'box',
            (UserStory.length > 0 && item.model_id === UserStory[0].model_id && UserStory[0].model_type == 'Profile') 
            || 
            (UserStory.length > 0 && item.model_id == UserStory[0].model_id && UserStory[0].model_type == 'Page') ? 'active' : '',
          ]"
          :key="index"
        >
          <div @click="showStory(item)">
            <div class="d-flex justify-content-between align-items-center">
              <img crossorigin="anonymous" 
                v-if="item.model.has_media_profile === false"
                src="@/assets/story_img/user.png"
                alt=""
              />
              <img crossorigin="anonymous" 
                v-else-if="item.model.media[0].collection_name === 'profile'"
                :src="item.model.media[0].src_url"
                alt=""
              />
              <img crossorigin="anonymous"  v-else :src="item.model.media[1].src_url" alt="" />
              <p class="m-0 name">{{ item.model.name }}</p>
            </div>
            <div v-if="item.user_id != USER.id">
              <p
                class="m-0"
                style="color: #0baa92"
                v-if="(item.model.stories_count - (item.story_index - 1)) === 1"
              >
                {{ $t("One") }}
              </p>
              <p
                class="m-0"
                style="color: #0baa92"
                v-else-if="(item.model.stories_count - (item.story_index - 1)) === 2"
              >
                {{ $t("Tow") }}
              </p>
              <p
                class="m-0"
                style="color: #0baa92"
                v-else-if="(item.model.stories_count - (item.story_index - 1)) > 2"
              >
                {{ (item.model.stories_count - (item.story_index - 1)) }} {{ $t("more") }}
              </p>
              <p class="m-0" style="color: black" v-else>{{ $t("NoStory") }}</p>
            </div>
            <div  v-if="item.user_id == USER.id">
              <p class="m-0" style="color: #0baa92">{{ $t("My Story") }}</p>
            </div>
          </div>
        </div>
        <!-- Show Boxes Story -->

      </div> 
      <!-- End Col-lg-3 -->
      <!-- SidBar -->

        <StoryShowSkelton v-if="peforeloading" />
      <!-- Carusol -->
      <div v-else class="col-lg-9 p-0 overflow-hidden position-relative" style="">
        <div class="position-absolute closediv top-0" style="z-index: 99999999;"
        
        >
          <button
              type="button"
              style="opacity: 1 !important;"
              class="btn-close  btn-close-white "
              @click="back"
              aria-label="Close"
            ></button>
        </div>
        <div id="carouselExampleControls" ref="carouselExampleControlsStory" class="carousel carousel-fade position-relative d-flex justify-content-center  p-0 slide h-100" style="background-color: black; " data-bs-interval="false">
          <div class="carousel-inner overflow-visible h-100 "  >
              

              <!-- Show Story -->
              <div
                v-for="(story, index) in UserStory"
                :key="story.id"
                
                :class="[
                  'carousel-item h-100 w-100 ',
                  index == indexprogress ? 'active' : '',
                ]"
              >

                <!-- tab -->
                <div class=" divprogress " style="padding: 5px">

                  <!-- Brogress tab -->
                  <div class="d-flex justify-content-center">
                    <div
                      v-for="(story1, index1) in UserStory"
                      :key="story1.id"
                      class="progress"
                      :style="{
                        width: 'calc(100% /' + UserStory.length + ')',
                        margin: '0 5px',
                        height: '5px',
                      }"
                    >
                      <div
                        :class="[
                          'progress-bar bg-success',
                          index1 < indexprogress ? 'fully' : '',
                        ]"
                        role="progressbar"
                        :style="{
                          width: index1 === indexprogress ? widthbar + '%' : ``,
                        }"
                        aria-valuenow="45"
                        aria-valuemin="0"
                        aria-valuemax="50"
                      ></div>
                      
                    </div>
                  </div>
                  <!-- Brogress tab -->

                  <!-- Info -->
                  <div class="mt-2 px-1  d-flex align-items-center justify-content-between">

                    <div class="d-flex justify-content-start align-items-center">
                      <!-- Name -->
                      <div
                        v-for="(item, index1) in Allusers_with_Story"
                        :key="index1"
                      >
                        <div class="" v-if="
                          (UserStory && UserStory.length > 0 && UserStory[0].model_type == 'Page' && item.model_id === UserStory[0].model_id) ||
                          (UserStory && UserStory.length > 0 && UserStory[0].model_type == 'Profile' && item.model_id === UserStory[0].model_id)
                        ">
                          <div
                            class="d-flex justify-content-center  align-items-center"
                          >
                          <!-- <div class="text-white">
                            {{ UserStory.length }}
                            {{ UserStory[0] }}
                          </div> -->
                            <img crossorigin="anonymous" 
                              v-if="item.model.has_media_profile === false"
                              height="25"
                              width="25"
                              class="img_logo"
                              src="@/assets/img_friends/default1.png"
                              alt=""
                            />
                            <img crossorigin="anonymous" 
                              v-else-if="item.model.media[0].collection_name === 'profile'"
                              class="rounded"
                              height="25"
                              width="25"
                              :src="item.model.media[0].src_url"
                              alt=""
                            />
                            <img crossorigin="anonymous" 
                              v-else
                              :src="item.model.media[1].src_url"
                              height="25"
                              width="25"
                              class="img_logo"
                              alt=""
                            />
                            <p class="mx-2 mb-0 name text-white fw-bold">
                              {{ item.model.name }}
                            </p>
                          </div>
                        </div>

                        

                      </div>
                      <!-- Name -->

                      <!-- Pause And Play -->
                      <div v-if="!story.has_media || (story.has_media && (story.media[0].media_type == 'image' || story.media[0].media_type == 'Image' ))">
                        <span style="cursor: pointer;" v-if="!pause" @click="stoptprogressandshowview()">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            fill="#fff"
                            class="bi bi-stop-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                            <path
                              d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z"
                            />
                          </svg>
                        </span>
                        <span style="cursor: pointer;" v-else @click="closeshowviewandrestoreprogress()">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            fill="#fff"
                            class="bi bi-play"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"
                            />
                          </svg>
                        </span>
                      </div>
                      <!-- For Video Story Play And Puse -->
                      <div v-else>
                        <span style="cursor: pointer;" v-if="pause" @click="StopVideoStory(story)">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            fill="#fff"
                            class="bi bi-stop-circle"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                            />
                            <path
                              d="M5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5v-3z"
                            />
                          </svg>
                        </span>
                        <span v-else style="cursor: pointer;" @click="playVideoStory(story)">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            fill="#fff"
                            class="bi bi-play"
                            viewBox="0 0 16 16"
                          >
                            <path
                              d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"
                            />
                          </svg>
                        </span>
                        <!-- Mute -->
                        <span v-if="videoMute" class="mx-1" style="cursor: pointer;" @click="UnmuteVideo(story.id)">
                          <svg fill="#ffffff" width="26" height="26" viewBox="0 0 24 24" id="mute-2" data-name="Line Color" xmlns="http://www.w3.org/2000/svg" class="icon line-color" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path id="primary" d="M17,3,10.16,8H6A1,1,0,0,0,5,9v6a1,1,0,0,0,1,1h4.16L17,21Z" style="fill: none; stroke: #ffffff; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></path><line id="secondary" x1="3" y1="18.63" x2="21" y2="5.37" style="fill: none; stroke: #8f5fee; stroke-linecap: round; stroke-linejoin: round; stroke-width: 2;"></line></g>
                          </svg>
                        </span>
                        <!-- Un Mute -->
                        <span v-else class="mx-1" style="cursor: pointer;" @click="muteVideo(story.id)">
                          <svg width="26" height="26" fill="#8f5fee" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path fill-rule="evenodd" d="M11.553 3.064A.75.75 0 0112 3.75v16.5a.75.75 0 01-1.255.555L5.46 16H2.75A1.75 1.75 0 011 14.25v-4.5C1 8.784 1.784 8 2.75 8h2.71l5.285-4.805a.75.75 0 01.808-.13zM10.5 5.445l-4.245 3.86a.75.75 0 01-.505.195h-3a.25.25 0 00-.25.25v4.5c0 .138.112.25.25.25h3a.75.75 0 01.505.195l4.245 3.86V5.445z"></path><path d="M18.718 4.222a.75.75 0 011.06 0c4.296 4.296 4.296 11.26 0 15.556a.75.75 0 01-1.06-1.06 9.5 9.5 0 000-13.436.75.75 0 010-1.06z"></path><path d="M16.243 7.757a.75.75 0 10-1.061 1.061 4.5 4.5 0 010 6.364.75.75 0 001.06 1.06 6 6 0 000-8.485z"></path></g>
                          </svg>
                          
                          
                        </span>
                      </div>
                      <!-- For Video Story Play And Puse -->
                      <!-- Pause And Play -->
                    </div>

                    <!-- User Control His Story -->
                    <div  class="d-flex justify-content-end align-items-center  mx-2" 
                      v-if="UserStory.length > 0 && UserStory[0].user_id == USER.id"
                    >
                      <!-- delete -->
                        <svg
                          style="cursor: pointer;"
                          @click="stopforInteraction(story)"
                          title="Delete Story"
                          data-bs-toggle="modal"
                          :data-bs-target="'#staticDeleteStory' + story.id"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          fill="#cfcfcf"
                          viewBox="0 0 256 256"
                        >
                          <path
                            d="M200,56V208a8,8,0,0,1-8,8H64a8,8,0,0,1-8-8V56Z"
                            opacity="0.2"
                          ></path>
                          <path
                            d="M216,48H176V40a24,24,0,0,0-24-24H104A24,24,0,0,0,80,40v8H40a8,8,0,0,0,0,16h8V208a16,16,0,0,0,16,16H192a16,16,0,0,0,16-16V64h8a8,8,0,0,0,0-16ZM96,40a8,8,0,0,1,8-8h48a8,8,0,0,1,8,8v8H96Zm96,168H64V64H192ZM112,104v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Zm48,0v64a8,8,0,0,1-16,0V104a8,8,0,0,1,16,0Z"
                          ></path>
                        </svg>

                        <!-- modal delete story -->
                          <!-- Modal -->
                          <div
                            class="modal fade"
                            :id="'staticDeleteStory' + story.id"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabindex="-1"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                          >
                            <div class="modal-dialog modal-dialog-centered">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h5 class="modal-title" id="staticBackdropLabel">
                                    {{ $t("Are you sure to remove this Story?") }}
                                  </h5>
                                  <div class="close-dir">
                                    <button
                                      type="button"
                                      class="btn-close"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                      @click="playforinteraction(story)"
                                    ></button>
                                  </div>
                                </div>
                                <div class="modal-footer">
                                  <button
                                    type="button"
                                    class="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    {{ $t("Close") }}
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-danger"
                                    data-bs-dismiss="modal"
                                    @click="
                                      deleteStory(
                                        UserStory[indexprogress].id,
                                        index,
                                        UserStory
                                      )
                                    "
                                  >
                                    {{ $t("Leave") }}
                                  </button>
                                
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- End modal -->
                          <!-- modal delete story -->
                        <!-- delete -->

                        <!-- Pin Story -->
                        <svg
                        class="mx-2"
                        style="cursor: pointer;"
                        title="Pin Story"
                        width="22"
                        height="22"
                        v-if="story.index != 2"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdropPinStory"
                        @click="PinStory(story)"
                        viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path opacity="0.5" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="#ffffff"></path> <path d="M10.4127 8.49812L10.5766 8.20419C11.2099 7.06807 11.5266 6.5 12 6.5C12.4734 6.5 12.7901 7.06806 13.4234 8.20419L13.5873 8.49813C13.7672 8.82097 13.8572 8.98239 13.9975 9.0889C14.1378 9.19541 14.3126 9.23495 14.6621 9.31402L14.9802 9.38601C16.2101 9.66428 16.825 9.80341 16.9713 10.2739C17.1176 10.7443 16.6984 11.2345 15.86 12.215L15.643 12.4686C15.4048 12.7472 15.2857 12.8865 15.2321 13.0589C15.1785 13.2312 15.1965 13.4171 15.2325 13.7888L15.2653 14.1272C15.3921 15.4353 15.4554 16.0894 15.0724 16.3801C14.6894 16.6709 14.1137 16.4058 12.9622 15.8756L12.6643 15.7384C12.337 15.5878 12.1734 15.5124 12 15.5124C11.8266 15.5124 11.663 15.5878 11.3357 15.7384L11.0378 15.8756C9.88633 16.4058 9.31059 16.6709 8.92757 16.3801C8.54456 16.0894 8.60794 15.4353 8.7347 14.1272L8.76749 13.7888C8.80351 13.4171 8.82152 13.2312 8.76793 13.0589C8.71434 12.8865 8.59521 12.7472 8.35696 12.4686L8.14005 12.215C7.30162 11.2345 6.88241 10.7443 7.02871 10.2739C7.17501 9.80341 7.78994 9.66427 9.01977 9.38601L9.33794 9.31402C9.68743 9.23495 9.86217 9.19541 10.0025 9.0889C10.1428 8.98239 10.2328 8.82097 10.4127 8.49812Z" fill="#000000"></path> </g>
                        </svg>
                        <svg
                        class="mx-2"
                        style="cursor: pointer;"
                        title="Pin Story"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdropUnPinStory"
                        @click="PinStory(story)"
                        width="22"
                        height="22"
                        v-if="story.index == 2"

                        viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path opacity="0.5" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="#8f5fee"></path> <path d="M10.4127 8.49812L10.5766 8.20419C11.2099 7.06807 11.5266 6.5 12 6.5C12.4734 6.5 12.7901 7.06806 13.4234 8.20419L13.5873 8.49813C13.7672 8.82097 13.8572 8.98239 13.9975 9.0889C14.1378 9.19541 14.3126 9.23495 14.6621 9.31402L14.9802 9.38601C16.2101 9.66428 16.825 9.80341 16.9713 10.2739C17.1176 10.7443 16.6984 11.2345 15.86 12.215L15.643 12.4686C15.4048 12.7472 15.2857 12.8865 15.2321 13.0589C15.1785 13.2312 15.1965 13.4171 15.2325 13.7888L15.2653 14.1272C15.3921 15.4353 15.4554 16.0894 15.0724 16.3801C14.6894 16.6709 14.1137 16.4058 12.9622 15.8756L12.6643 15.7384C12.337 15.5878 12.1734 15.5124 12 15.5124C11.8266 15.5124 11.663 15.5878 11.3357 15.7384L11.0378 15.8756C9.88633 16.4058 9.31059 16.6709 8.92757 16.3801C8.54456 16.0894 8.60794 15.4353 8.7347 14.1272L8.76749 13.7888C8.80351 13.4171 8.82152 13.2312 8.76793 13.0589C8.71434 12.8865 8.59521 12.7472 8.35696 12.4686L8.14005 12.215C7.30162 11.2345 6.88241 10.7443 7.02871 10.2739C7.17501 9.80341 7.78994 9.66427 9.01977 9.38601L9.33794 9.31402C9.68743 9.23495 9.86217 9.19541 10.0025 9.0889C10.1428 8.98239 10.2328 8.82097 10.4127 8.49812Z" fill="#8f5fee"></path> </g>
                        </svg>
                        <!-- Pin Story -->

                        <!-- &nbsp; -->
                        <!-- Show Views -->
                        <svg
                          class="mx-2"
                          style="cursor: pointer;"
                          title="Display the Viewers"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdropViews"
                          @click="viewonstory(UserStory[indexprogress])"
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          fill="#cfcfcf"
                          viewBox="0 0 256 256"
                        >
                          <path
                            d="M128,56C48,56,16,128,16,128s32,72,112,72,112-72,112-72S208,56,128,56Zm0,112a40,40,0,1,1,40-40A40,40,0,0,1,128,168Z"
                            opacity="0.2"
                          ></path>
                          <path
                            d="M247.31,124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57,61.26,162.88,48,128,48S61.43,61.26,36.34,86.35C17.51,105.18,9,124,8.69,124.76a8,8,0,0,0,0,6.5c.35.79,8.82,19.57,27.65,38.4C61.43,194.74,93.12,208,128,208s66.57-13.26,91.66-38.34c18.83-18.83,27.3-37.61,27.65-38.4A8,8,0,0,0,247.31,124.76ZM128,192c-30.78,0-57.67-11.19-79.93-33.25A133.47,133.47,0,0,1,25,128,133.33,133.33,0,0,1,48.07,97.25C70.33,75.19,97.22,64,128,64s57.67,11.19,79.93,33.25A133.46,133.46,0,0,1,231.05,128C223.84,141.46,192.43,192,128,192Zm0-112a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Z"
                          ></path>
                        </svg>
                        <!-- Show Views -->
                        <!-- &nbsp; -->
                        

                        <!-- comment_status Active Or Deactive -->
                          <span class="position-relative" style="cursor: pointer;" 
                            @click="toggleComment(story.id)"
                            
                          >
                            <span class="blok" v-if="!story.comment_status">
                              <svg fill="#ff0000" height="28" width="28" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg" stroke="#ff0000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M 13.0117 40.0117 C 12.2148 40.8086 12.1914 42.1680 13.0117 42.9883 C 13.8086 43.8086 15.1680 43.7851 15.9883 42.9883 L 27.9883 30.9648 L 40.0117 42.9883 C 40.8086 43.7851 42.1680 43.8086 42.9648 42.9883 C 43.8086 42.1680 43.7851 40.8086 42.9648 40.0117 L 30.9648 27.9883 L 42.9648 15.9883 C 43.7851 15.1914 43.8086 13.8086 42.9648 13.0117 C 42.1680 12.1914 40.8086 12.2148 40.0117 13.0117 L 27.9883 25.0352 L 15.9883 13.0117 C 15.1680 12.2148 13.8086 12.1914 13.0117 13.0117 C 12.1914 13.8086 12.2148 15.1914 13.0117 15.9883 L 25.0117 27.9883 Z"></path></g></svg>
                            </span>
                            <span class="blok" v-else>
                              <svg viewBox="0 0 24 24" height="28" width="28" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#00ff00"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M3 12L9 18L21 6" stroke="#00ff00" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                            </span>
                            <svg  viewBox="0 0 32 32" width="22" style="cursor: pointer;"
                              height="22" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>comment 5</title> <desc>Created with Sketch Beta.</desc> <defs> </defs> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"> <g id="Icon-Set-Filled" sketch:type="MSLayerGroup" transform="translate(-362.000000, -257.000000)" fill="#ffffff"> <path d="M388.667,257 L367.333,257 C364.388,257 362,259.371 362,262.297 L362,279.187 C362,282.111 364.055,284 367,284 L373.639,284 L378,289.001 L382.361,284 L389,284 C391.945,284 394,282.111 394,279.187 L394,262.297 C394,259.371 391.612,257 388.667,257" id="comment-5" sketch:type="MSShapeGroup"> </path> </g> </g> </g>
                            </svg>
                          </span>
                          <!-- comment_status Active Or Deactive -->

                    </div>
                    <!-- User Control His Story -->
                  </div>
                  <!-- Info -->

                </div>
                <!-- Tab -->
                
                <!-- Start Of Show story div -->
                <div class="back">
                  <!-- <p class="text-white">{{ story.id }}</p> -->
                  <div v-if="story.index == 2 && story.user_id != USER.id" class="pinnedstory position-absolute start-50" style="top: 1rem;">
                    <svg
                          width="22"
                          height="22"
                          
                          viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path opacity="0.5" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" fill="#8f5fee"></path> <path d="M10.4127 8.49812L10.5766 8.20419C11.2099 7.06807 11.5266 6.5 12 6.5C12.4734 6.5 12.7901 7.06806 13.4234 8.20419L13.5873 8.49813C13.7672 8.82097 13.8572 8.98239 13.9975 9.0889C14.1378 9.19541 14.3126 9.23495 14.6621 9.31402L14.9802 9.38601C16.2101 9.66428 16.825 9.80341 16.9713 10.2739C17.1176 10.7443 16.6984 11.2345 15.86 12.215L15.643 12.4686C15.4048 12.7472 15.2857 12.8865 15.2321 13.0589C15.1785 13.2312 15.1965 13.4171 15.2325 13.7888L15.2653 14.1272C15.3921 15.4353 15.4554 16.0894 15.0724 16.3801C14.6894 16.6709 14.1137 16.4058 12.9622 15.8756L12.6643 15.7384C12.337 15.5878 12.1734 15.5124 12 15.5124C11.8266 15.5124 11.663 15.5878 11.3357 15.7384L11.0378 15.8756C9.88633 16.4058 9.31059 16.6709 8.92757 16.3801C8.54456 16.0894 8.60794 15.4353 8.7347 14.1272L8.76749 13.7888C8.80351 13.4171 8.82152 13.2312 8.76793 13.0589C8.71434 12.8865 8.59521 12.7472 8.35696 12.4686L8.14005 12.215C7.30162 11.2345 6.88241 10.7443 7.02871 10.2739C7.17501 9.80341 7.78994 9.66427 9.01977 9.38601L9.33794 9.31402C9.68743 9.23495 9.86217 9.19541 10.0025 9.0889C10.1428 8.98239 10.2328 8.82097 10.4127 8.49812Z" fill="#8f5fee"></path> </g>
                          </svg>
                          <strong class="color" style="color: #8f5fee;">{{ $t('Pinned Story') }}</strong>
                  </div>
                    <div v-if="story.has_media" class="cover_body">
                      <img crossorigin="anonymous" 
                        v-if="story.media[0].media_type == 'image' || story.media[0].media_type == 'Image'"
                        :src="story.media[0].src_url"
                        class="d-block img"
                        alt="Image"
                      />
                      <div v-else class="position-relative">
                        <video crossorigin="anonymous"  
                          @click="ClickOnVideo(story)"
                            :id="`videoinstory${story.id}`"
                            :src="story.media[0].src_url"  
                              style="width: 100%; height: 100%; object-fit: contain;cursor: pointer;"
                          >
                        </video>
                        <div v-if="!videostate" class="spinner-border text-primary" role="status" style="position: absolute; top: 45%; left: 43%;z-index: 999999;color: #8f5fee !important;">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                      </div>
                    </div>
                    <div
                      v-else
                      class="cover_body"
                      :style="[
                        story.backStyle || story.textStyle || story.fontStyle
                          ? `background-color: ${story.backStyle}; 
                                                background-image: ${story.backStyle};
                                                ${story.fontStyle};
                                                color: ${story.textStyle}
                                                `
                          : { 'background-color': '#777', color: '#000000' },
                      ]"
                    >
                      <p class="text_body">
                        {{ story.content }}
                      </p>
                    </div>
                  
                </div>
                <!-- End Of Show Story div -->

                <!-- Controllers -->
                <button
                  v-if="index != 0 && cickPrev"
                  @click="prev( UserStory[index], UserStory[index - 1])"
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">{{ $t("Previous") }}</span>
                </button>
                <button
                  v-if="index < UserStory.length - 1 && cickNext"
                  @click="next(UserStory[index + 1], UserStory[index])"
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">{{ $t("Next") }}</span>
                </button>
                <!-- Controllers -->

                <div class=" interaction_comment px-3 d-flex justify-content-between" style="height: 42px !important;">
                  <!-- Add Reaction To Story -->
                    <div class="addreactiontostory d-inline-flex " v-if="UserStory.length > 0 && UserStory[0].user_id != USER.id"
                      
                    >
                      <addInteractionComponents :story_id="story.id" :interacted="story.interacted" />
                    </div>
                    <div v-else></div>
                  <!-- Add Reaction To Story -->
                  <!-- Comment On Story -->
                    <div class="addcommenttostory d-flex align-items-center" 
                        v-if="(UserStory.length > 0 && UserStory[0].user_id == USER.id) || 
                        UserStory.length > 0 && UserStory[0].user_id != USER.id && story.comment_status
                        "
                      >
                      <!-- Show All Comment Or no -->
                        <div class="allcommentStory d-flex align-items-center" style="cursor: pointer;"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdropAllComments"
                              @click="DeterminateIdForCommentStory(story)"
                        >
                          <p  class="mb-0 text-white commentword mx-2">{{ $t('Comments') }}</p>
                          <svg
                            class="svgcomment"
                              height="25" width="25"
                              viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" fill="#000000" stroke="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>comment-2</title> <desc>Created with Sketch Beta.</desc> <defs> </defs> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage"> <g id="Icon-Set" sketch:type="MSLayerGroup" transform="translate(-152.000000, -255.000000)" fill="#ffffff"> <path d="M168,281 C166.832,281 165.704,280.864 164.62,280.633 L159.912,283.463 L159.975,278.824 C156.366,276.654 154,273.066 154,269 C154,262.373 160.268,257 168,257 C175.732,257 182,262.373 182,269 C182,275.628 175.732,281 168,281 L168,281 Z M168,255 C159.164,255 152,261.269 152,269 C152,273.419 154.345,277.354 158,279.919 L158,287 L165.009,282.747 C165.979,282.907 166.977,283 168,283 C176.836,283 184,276.732 184,269 C184,261.269 176.836,255 168,255 L168,255 Z M175,266 L161,266 C160.448,266 160,266.448 160,267 C160,267.553 160.448,268 161,268 L175,268 C175.552,268 176,267.553 176,267 C176,266.448 175.552,266 175,266 L175,266 Z M173,272 L163,272 C162.448,272 162,272.447 162,273 C162,273.553 162.448,274 163,274 L173,274 C173.552,274 174,273.553 174,273 C174,272.447 173.552,272 173,272 L173,272 Z" id="comment-2" sketch:type="MSShapeGroup"> </path> </g> </g> </g>
                            </svg>
                          
                            
                          <!-- {{ allcommentsonstory }} -->
                        </div>
                        <!-- Show All Comment Or no -->
                    </div>
                  <!-- Comment On Story -->
                </div>
                
                
              </div>
              <!-- Show Story -->
          </div>

          <!-- Modal For view -->
            <div
              class="modal fade"
              id="staticBackdropViews"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropViewsLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropViewsLabel">
                      {{ $t("views") }}
                    </h5>
                    <div>
                      <button
                        type="button"
                        class="btn-close"
                        @click="closeviewmodal"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div v-if="!viewstoryloading">
                      <div v-if="itemsviewonstory.length > 0">
                        <div v-for="(item, index) in itemsviewonstory" :key="index" class="d-flex justify-content-between mb-3 align-items-center">
                          <div>
                            <h5 class="">{{ item.model.name }}</h5>
                          </div>
                          <div  class="d-flex justify-content-center align-items-center">
                            <div v-if=" item.interactions.length > 0" class="d-flex justify-content-center align-items-center">
                              <span v-for="(interaction, index) in item.interactions" :key="index" class="d-flex text-center flex-column" :class="$i18n.locale == 'en' ? 'ms-2' : 'me-2'">
                                <img crossorigin="anonymous"  v-if="interaction.type==7" src="@/assets/reactions/like.gif" width="20" class="img" alt="" >
                                <img crossorigin="anonymous"  v-else-if="interaction.type==1" src="@/assets/reactions/love.gif" width="20" class="img" alt="">
                                <img crossorigin="anonymous"  v-else-if="interaction.type==6" src="@/assets/reactions/angry.gif" width="20" class="img" alt="">
                                <img crossorigin="anonymous"  v-else-if="interaction.type==2" src="@/assets/reactions/care.gif" width="20" class="img" alt="">
                                <img crossorigin="anonymous"  v-else-if="interaction.type==3" src="@/assets/reactions/haha.gif" width="20" class="img" alt="">
                                <img crossorigin="anonymous"  v-else-if="interaction.type==4" src="@/assets/reactions/wow.gif" width="20" class="img" alt="">
                                <img crossorigin="anonymous"  v-else-if="interaction.type==5" src="@/assets/reactions/sad.gif" width="20" class="img" alt="">
                                <small class="text-primary">{{ interaction.count }}</small>
                              </span>
                            </div>
                            <span v-if="item.visits_count > 0" class="d-flex text-center flex-column" :class="$i18n.locale == 'en' ? 'ms-2' : 'me-2'">
                              <svg
                                title="Display the Viewers"
                                data-bs-toggle="modal"
                                data-bs-target="#staticBackdropViews"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="#000000"
                                viewBox="0 0 256 256"
                              >
                                <path
                                  d="M128,56C48,56,16,128,16,128s32,72,112,72,112-72,112-72S208,56,128,56Zm0,112a40,40,0,1,1,40-40A40,40,0,0,1,128,168Z"
                                  opacity="0.2"
                                ></path>
                                <path
                                  d="M247.31,124.76c-.35-.79-8.82-19.58-27.65-38.41C194.57,61.26,162.88,48,128,48S61.43,61.26,36.34,86.35C17.51,105.18,9,124,8.69,124.76a8,8,0,0,0,0,6.5c.35.79,8.82,19.57,27.65,38.4C61.43,194.74,93.12,208,128,208s66.57-13.26,91.66-38.34c18.83-18.83,27.3-37.61,27.65-38.4A8,8,0,0,0,247.31,124.76ZM128,192c-30.78,0-57.67-11.19-79.93-33.25A133.47,133.47,0,0,1,25,128,133.33,133.33,0,0,1,48.07,97.25C70.33,75.19,97.22,64,128,64s57.67,11.19,79.93,33.25A133.46,133.46,0,0,1,231.05,128C223.84,141.46,192.43,192,128,192Zm0-112a48,48,0,1,0,48,48A48.05,48.05,0,0,0,128,80Zm0,80a32,32,0,1,1,32-32A32,32,0,0,1,128,160Z"
                                ></path>
                              </svg>
                              <small>{{ item.visits_count }}</small>
                            </span>
                          </div>
                        </div>
                        
                      </div>
                      <div v-else>
                        <h5 class="text-center">{{ $t("noviews") }}</h5>
                      </div>
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                      v-else
                    >
                      <div class="spinner-border text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal For view -->

          <!-- Modal For Pin Story -->
            <div
              class="modal fade"
              id="staticBackdropPinStory"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropPinStoryLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropPinStoryLabel">
                      {{ $t("Pin Story") }}
                    </h5>
                    <div>
                      <button
                        type="button"
                        class="btn-close"
                        @click="ClosePinStory"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div v-if="!PinInfoLoading && PinInfo">
                      <p >
                        {{ $t('The cost of this procedure is') }}
                        <strong style="color: #8f5fee ;" class="mx-2">
                          {{ PinInfo.points_awarded }} {{ PinInfo.unit }}
                        </strong>
                      </p>
                      <div class="mt-3 text-center">
                        <button class="btn btn-success" @click="ApplyPin">{{ $t('Apply') }}</button>
                      </div>
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                      v-else
                    >
                      <div class="spinner-border text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal For Pin Story -->

          <!-- Modal For Pin Story -->
            <div
              class="modal fade"
              id="staticBackdropUnPinStory"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropUnPinStoryLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropUnPinStoryLabel">
                      {{ $t("Unpin Story") }}
                    </h5>
                    <div>
                      <button
                        type="button"
                        class="btn-close"
                        @click="ClosePinStory"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                  </div>
                  <div class="modal-body">
                    <div v-if="!PinInfoLoading && PinInfo">
                      <div class="mt-3 text-center">
                        <button class="btn btn-danger" @click="ApplyPin">{{ $t('Unpin Story') }}</button>
                      </div>
                    </div>
                    <div
                      class="d-flex justify-content-center align-items-center"
                      v-else
                    >
                      <div class="spinner-border text-success" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal For Pin Story -->

            <!-- Modal For Comments -->
              <AllCommentList  
                  @deleteidforcomment="deleteidforcomment"
                  @loadMoreComments="loadMoreComments"
                  @AddCommentToList="AddCommentToList" 
                  @EditeComment="EditeComment"
                  @DeleteComment="DeleteComment"
                  :comment_status="comment_status" 
                  :story_id="idforcommentstory"
                  :allcommentsOnStory = "allcommentsOnStory"
                  :allcommentsloading = "allcommentsloading"
                  :lastpageinCommentsStory="lastpageinCommentsStory"
                  :pageInCommentsList="pageInCommentsList"
                  :loadingformorecomment="loadingformorecomment"
                />

              <!-- Modal For Comments -->
        </div>
      </div>
      <!-- Carusol -->
    </div>
      
  </div>
</template>

<script>
  import StoryShowSkelton from "@/components/SkeletonsLoaderComponent/StoryShowSkelton.vue";
  import StoryShowSkeltonSideBar from "@/components/SkeletonsLoaderComponent/StoryShowSkeltonSideBar.vue";
  import CreateStoryFromStoriesViews from "@/components/HomeComponent/Story/CreateStoryFromStoriesViews.vue";
  import { useRoute } from "vue-router";
  import { mapGetters } from "vuex";
  import StoryService from "@/services/story/story.services";
  import ViewsService from "@/services/views/storyviews";
  import formatTime from "@/mixins/formatTime";
  import storyComment from "@/services/story/storyComment"
  import storyServices from "@/services/story/story.services";
  import AllCommentList from "@/components/HomeComponent/Story/comment/AllCommentList.vue"
  import addInteractionComponents from "@/components/HomeComponent/Story/iteraction/addInteractionComponents.vue"
import pointsServices from "@/services/Points/pointsServices";


  export default {
    name: "StoriesView",
    components: {
      StoryShowSkeltonSideBar,
      StoryShowSkelton,
      CreateStoryFromStoriesViews,
      AllCommentList,
      addInteractionComponents
    },
    data() {
      return {
        page: localStorage.getItem('page') ? JSON.parse(localStorage.getItem("page")) : "",
        id: useRoute().params.id,
        type: useRoute().query.type,
        Allusers_with_Story: [],
        indexprogress: 0,
        pause: false,
        peforeloading: false,
        peforeloadingSideBar: false,
        intervalitem: 0,
        widthbar: 0,
        viewstoryloading: false,
        itemsviewonstory: [],
        idforcommentstory: '',
        comment_status: null,
        allcommentsOnStory: [],
        allcommentsloading: null,
        lastpageinCommentsStory: 1,
        pageInCommentsList: 1,
        loadingformorecomment: null,
        videostate: true,
        videoMute: false,
        indexofleft: null,
        cickNext: true,
        cickPrev: true,
        visibleSideBar: false,
        PinnedStoryId: null,
        PinInfoLoading: false,
        PinInfo: null,
      };
    },
    mixins: [formatTime],
    async mounted() {
      this.peforeloading = true;
      this.peforeloadingSideBar = true;
      // Determinate Story Type
      let obj =  {
        model_id: parseInt(this.id),
        model_type: this.type
      };
      this.page != '' ? obj.page_id = parseInt(this.page.id) : '';
      (await this.UserStory.length) == 0
        ? this.$store.dispatch("story/getStories", obj)
        : "";
      await this.getAllStories();
      this.peforeloading = false;
      this.peforeloadingSideBar = false;
      // console.log(this.UserStory)
      if(this.UserStory.length > 0) {
        let index = this.Allusers_with_Story.find(x => x.user_id == this.UserStory[0].user_id).story_index
        this.indexprogress = index -1
      }
      // Story Is Video
      if(this.UserStory.length > 0 && this.UserStory[this.indexprogress].has_media && (this.UserStory[this.indexprogress].media[0].media_type == 'video' || this.UserStory[this.indexprogress].media[0].media_type == 'Video')){
        clearInterval(this.intervalitem);
        this.pause = false
        await new Promise(r => setTimeout(r, 400));
        this.playVideoStory(this.UserStory[this.indexprogress])
      }else { // Story Not Video
        this.intervalitem = setInterval(this.startTimer, 100);
        this.pause = false
      }
      // console.log(this.UserStory)
      if (this.UserStory[this.indexprogress] && this.UserStory[this.indexprogress].user_id != this.USER.id) {
        // if(!this.UserStory[this.indexprogress].watched) {
        // }
        const data = {
          story_ids: [parseInt(this.UserStory[this.indexprogress].id)],
        };
        this.page != '' ? data.page_id = parseInt(this.page.id) : ''
        ViewsService.AddViewsStory(data);
      }
      
    },
    unmounted() {
      this.indexprogress = 0
      this.widthbar = 0
      this.indexofleft = null
      clearInterval(this.intervalitem);
      this.$store.commit("story/Claer_USER_STORIES");
      // console.log(('unMonted'))
    },
    computed: {
      ...mapGetters("story", {
        UserStory: "User_Stories", // All Story For User In State
        isLoading: "isLoading_status",
        User: "Get_User", // UserId For Story In State
      }),
      ...mapGetters("auth", {
        USER: "USER",
      }),
    },
    methods: {
      async ApplyPin(){
        let data = {
          id: this.PinnedStoryId
        }
        try {
          await storyServices.TogglePinStory(data).then(res => {
            this.$snotify.success(
                res.data.PinStoryToggle,
                this.$i18n.t("Story")
              );
          })
          
        } catch (error) {
          this.$snotify.error(
              error.message,
                this.$i18n.t("Story")
              );
        }

        let item = this.UserStory.find(x => x.id == this.PinnedStoryId)
        item.index != 2 ? item.index = 2 : item.index = 1 
        // this.PinnedStoryId = null
      },
      async PinStory(story){
        this.PinInfoLoading = true
        if(story.has_media && (story.media[0].media_type == 'video' || story.media[0].media_type == 'Video')){
          this.ClickOnVideo(story)
        }else { // Story Not Video
          this.pause = false
          clearInterval(this.intervalitem);
        }
        // console.log(id)
        await pointsServices.PinStoryInfo().then(res => {
          // console.log(res.data.PinStoryCategoryPointQuery)
          this.PinInfo = res.data.PinStoryCategoryPointQuery
        })
        this.PinnedStoryId = story.id
        this.PinInfoLoading = false
      },
      ClosePinStory(){
        let story = null
        this.UserStory.forEach(item => {
          if(item.id == this.UserStory[this.indexprogress].id) {
            story = item
          }
        })
        if(story.has_media && (story.media[0].media_type == 'video' || story.media[0].media_type == 'Video')){
          this.ClickOnVideo(story)
        }else { // Story Not Video
          this.intervalitem = setInterval(this.startTimer, 100);
          this.pause = true
        }
        this.PinnedStoryId = null
      },
      stopforInteraction(story){
        if(story.has_media && (story.media[0].media_type == 'video' || story.media[0].media_type == 'Video')){
          let video = document.getElementById(`videoinstory${story.id}`)
          video.pause()
          clearInterval(this.intervalitem);
          this.pause = false
        }else { // Story Not Video
          clearInterval(this.intervalitem);
          this.pause = false
        }
      },
      playforinteraction(story){
        if(story.has_media && (story.media[0].media_type == 'video' || story.media[0].media_type == 'Video')){
          let video = document.getElementById(`videoinstory${story.id}`)
          video.play()
          this.intervalitem = setInterval(this.startTimer, 100);
          this.pause = true
        }else { // Story Not Video
          this.intervalitem = setInterval(this.startTimer, 100);
          this.pause = true
        }
      },
      UnmuteVideo(id){
        let video = document.getElementById(`videoinstory${id}`)
        video.muted = !video.muted
        this.videoMute = false
      },
      muteVideo(id){
        let video = document.getElementById(`videoinstory${id}`)
        video.muted = !video.muted
        this.videoMute = true
      },
      ClickOnVideo(story){
        let video = document.getElementById(`videoinstory${story.id}`)
        if(video.paused){
          // console.log('yes')
          video.play()
          video.onplaying = () => {
            // console.log('playing')
            this.videostate = true
            this.intervalitem = setInterval(this.startTimer, 100);
            this.pause = true;
          }; 
          //  new Promise(r => setTimeout(r, 100));
          video.onwaiting = () => {
            // console.log('waiting')
            this.videostate = false
            clearInterval(this.intervalitem);
            this.pause = false;
          };
        
          
          
        }else {
          // console.log('No')
          video.pause()
          clearInterval(this.intervalitem);
          this.pause = false;
          
        }
      },
      playVideoStory(story){
        let video = document.getElementById(`videoinstory${story.id}`)
        video.paused ? "" : video.currentTime = 0;
        video.play()
        video.onwaiting = () => {
          // console.log('waiting')
          this.videostate = false
          clearInterval(this.intervalitem);
          this.pause = false;
        };
      
        video.onplaying = () => {
          // console.log('playing')
          this.videostate = true
          this.intervalitem = setInterval(this.startTimer, 100);
          this.pause = true;
        };        
        // video.play()
        //   this.intervalitem = setInterval(this.startTimer, 100);
        //   this.pause = true;
      },
      StopVideoStory(story){
        let video = document.getElementById(`videoinstory${story.id}`)
        video.pause()
        clearInterval(this.intervalitem);
        this.pause = false;
      },
      DeleteComment(payload){
        this.allcommentsOnStory = this.allcommentsOnStory.filter(item => item.id != payload)
      },
      EditeComment(payload){
        this.allcommentsOnStory = this.allcommentsOnStory.map(item => item.id == payload.id ? payload : item)
      },
      async DeterminateIdForCommentStory(story){
        if(story.has_media && (story.media[0].media_type == 'video' || story.media[0].media_type == 'Video')){
          this.ClickOnVideo(story)
        }else { // Story Not Video
          this.pause = false
          clearInterval(this.intervalitem);
        }
        this.idforcommentstory = story.id
        this.comment_status = story.comment_status
        this.allcommentsloading = true
        let datasend = {
          story_id: parseInt(story.id),
          page: this.pageInCommentsList
        }
        await storyComment.GetAllCommentOnStory(datasend).then(res => {
          this.allcommentsOnStory = res.data.CommentQuery.items
          this.lastpageinCommentsStory = res.data.CommentQuery.last_page
        })
        this.allcommentsloading = null
        // this.commentsModal.show()
      },
      deleteidforcomment(){
        let story = null
        this.UserStory.forEach(item => {
          if(item.id == this.idforcommentstory) {
            story = item
          }
        })
        // console.log(story.id)
        // await new Promise(r => setTimeout(r, 100));
        // this.playforinteraction(story)
        if(story.has_media && (story.media[0].media_type == 'video' || story.media[0].media_type == 'Video')){
          this.ClickOnVideo(story)
        }else { // Story Not Video
          this.intervalitem = setInterval(this.startTimer, 100);
          this.pause = true
        }
        // this.intervalitem = setInterval(this.startTimer, 100);
        this.idforcommentstory = ''
        this.comment_status = null
        this.allcommentsOnStory = []
        this.lastpageinCommentsStory = 1
        this.pageInCommentsList = 1
        
        // console.log(this.idforcommentstory)
      },
      // loadMoreComments In Story
      async loadMoreComments(){
        this.loadingformorecomment = true
        let datasend = {
          story_id: parseInt(this.idforcommentstory),
          page: this.pageInCommentsList + 1
        }
        await storyComment.GetAllCommentOnStory(datasend).then(res => {
          res.data.CommentQuery.items.forEach(item => {
            this.allcommentsOnStory.push(item)
          })
        })
        this.pageInCommentsList = this.pageInCommentsList + 1
        this.loadingformorecomment = false

      },
      AddCommentToList(payload){
        this.allcommentsOnStory.unshift(payload)
      },
      async toggleComment(id){
        this.stoptprogressandshowview()
        await storyComment.ActiveOrDeactiveStoryComment(id).then(res =>{
          this.$store.commit('story/TOOGLE_STORY_COMMENT_STATUS', id)
          if(res.data.UpdateCommentStatusMutation.comment_status) {
            this.$snotify.success(
              this.$i18n.t("Comment is avilable"),
              this.$i18n.t("comments")
            );
          }else {
            this.$snotify.success(
              this.$i18n.t("Comment is Closed"),
              this.$i18n.t("comments")
            );
          }
        })
      },
      async deleteStory(id, index, data) {
        let datasend = {
          story_id : id
        }
        await StoryService.DeleteStory(datasend).then(() => {
          // console.log(res.data.DeleteStoryMutation)
          if (data.length != 1 && index < data.length - 1) {
            this.widthbar = 0;
            this.$store.commit("story/DeleteStory", id);
          } else if (data.length != 1 && index == data.length - 1) {
            this.indexprogress--;
            this.widthbar = 0;
            this.$store.commit("story/DeleteStory", id);
          } else {
            this.Allusers_with_Story.forEach((story) => {
              if (story.user_id == data[0].user_id) {
                let index = this.Allusers_with_Story.indexOf(story);
                this.showStory(this.Allusers_with_Story[index + 1]);
                this.Allusers_with_Story = this.Allusers_with_Story.filter(
                  (aa) => aa.user_id != data[0].user_id
                );
              }
            });
            this.$store.commit("story/DeleteStory", id);
          }
          
        });
        // this.$router.push({ name: "home" });
      },
      async getAllStories() {
        await storyServices
          .getAllStories()
          .then((response) => {
            this.Allusers_with_Story = response.data.StoriesQuery.items;
          })
          .catch((error) => {
            console.log(error.message);
          });
      },
      back() {
        clearInterval(this.intervalitem);
        this.$router.push({ name: "home" });
      },
      stoptprogressandshowview() {
        // console.log('story not video')
        clearInterval(this.intervalitem);
        this.pause = true;
      },
      async viewonstory(story) {
        // this.stopforInteraction(story)
        // this.ClickOnVideo(story)
        if(story.has_media && (story.media[0].media_type == 'video' || story.media[0].media_type == 'Video')){
          this.ClickOnVideo(story)
        }else { // Story Not Video
          this.pause = false
          clearInterval(this.intervalitem);
        }
        this.viewstoryloading = true;
        let data = {
          story_id: parseInt(story.id)
        }
        await ViewsService.ShowViewsStory(data).then((res) => {
          this.itemsviewonstory = res.data.ViewsQuery.items;
        });
        this.viewstoryloading = false;
      },
      closeviewmodal() {
        // console.log(this.indexprogress)
        let story = null
        this.UserStory.forEach(item => {
          if(item.id == this.UserStory[this.indexprogress].id) {
            story = item
          }
        })
        // console.log(story)
        // this.playforinteraction(story)
        // this.ClickOnVideo(story)
        if(story.has_media && (story.media[0].media_type == 'video' || story.media[0].media_type == 'Video')){
          this.ClickOnVideo(story)
        }else { // Story Not Video
          this.intervalitem = setInterval(this.startTimer, 100);
          this.pause = true
        }
        this.itemsviewonstory = [];
      },
      closeshowviewandrestoreprogress() {
        this.intervalitem = setInterval(this.startTimer, 100);
        this.pause = false;
      },
      async showStory(item) {
        // console.log(item)
        this.visibleSideBar = true
        document.getElementById(`boxstory${item.id}`).classList.add('active')
        this.peforeloading = true;
        clearInterval(this.intervalitem);
        this.indexprogress = 0;
        this.widthbar = 0;
        this.pause = false;
        let obj =  {
          model_id: parseInt(item.model_id),
          model_type: item.model_type
        };
        await this.$store.dispatch("story/getStories", obj);

        let index = this.Allusers_with_Story.find(x => x.user_id == this.UserStory[0].user_id).story_index
        this.indexprogress = index -1

        if (this.UserStory[this.indexprogress] && this.UserStory[this.indexprogress].user_id != this.USER.id) {
          // if(!this.UserStory[this.indexprogress].watched) {
          // }
          const data = {
            story_ids: [parseInt(this.UserStory[this.indexprogress].id)],
          };
          this.page != '' ? data.page_id = parseInt(this.page.id) : ''
          await ViewsService.AddViewsStory(data);
        }
        this.peforeloading = false;
        if (this.UserStory[0] && this.Allusers_with_Story) {
          if(this.UserStory[0].model_type == 'Page'){
            this.Allusers_with_Story.forEach((storyitem) => {
              if (storyitem.id == this.UserStory[0].model_id) {
                this.indexofleft = this.Allusers_with_Story.indexOf(storyitem);
              }
            });
          }else {
            this.Allusers_with_Story.forEach((storyitem) => {
              if (storyitem.user_id == this.UserStory[0].user_id) {
                this.indexofleft = this.Allusers_with_Story.indexOf(storyitem);
              }
            });
          }
          
        }
        await new Promise(r => setTimeout(r, 400));
        // this.intervalitem = setInterval(this.startTimer, 100);
        if(item.has_media && (item.media[0].media_type == 'video' || item.media[0].media_type == 'Video')){
          this.playVideoStory(item)
        }else {
          this.intervalitem = setInterval(this.startTimer, 100);
          this.pause = false
        }
        this.visibleSideBar = false
      },
      startTimer() {
        // let indexofleft = null;
        if (this.UserStory[0] && this.Allusers_with_Story && this.indexofleft == null) {
          if(this.UserStory[0].model_type == 'Page'){
            this.Allusers_with_Story.forEach((storyitem) => {
              if (storyitem.id == this.UserStory[0].model_id) {
                this.indexofleft = this.Allusers_with_Story.indexOf(storyitem);
              }
            });
          }else {
            this.Allusers_with_Story.forEach((storyitem) => {
              if (storyitem.user_id == this.UserStory[0].user_id) {
                this.indexofleft = this.Allusers_with_Story.indexOf(storyitem);
              }
            });
          }
          
        }
        // console.log(this.indexofleft)
        if (this.widthbar < 100 && this.indexprogress < this.UserStory.length) {
          // if(this.UserStory[this.indexprogress].id) current story id
          if(!this.UserStory[this.indexprogress].has_media || (this.UserStory[this.indexprogress].has_media && ((this.UserStory[this.indexprogress].media[0].media_type == 'Image') || (this.UserStory[this.indexprogress].media[0].media_type == 'image')))){
            this.widthbar = this.widthbar + 2;
          }else {
            // console.log(this.UserStory[this.indexprogress].id)
            let dur = document.getElementById(`videoinstory${this.UserStory[this.indexprogress].id}`).duration
            // console.log(dur)
            // console.log(this.widthbar)
            this.widthbar = this.widthbar + ((100 / dur)/10);
          }
          
        } else if (
          this.widthbar > 98.8 &&
          this.indexprogress < this.UserStory.length - 1
        ) {
          if( (this.UserStory[this.indexprogress].has_media && ((this.UserStory[this.indexprogress].media[0].media_type == 'video') || (this.UserStory[this.indexprogress].media[0].media_type == 'Video')))){
            let video = document.getElementById(`videoinstory${this.UserStory[this.indexprogress].id}`)
            video.pause()
          }
          clearInterval(this.intervalitem);
          this.widthbar = 0;
          this.indexprogress++;
          if( (this.UserStory[this.indexprogress].has_media && ((this.UserStory[this.indexprogress].media[0].media_type == 'video') || (this.UserStory[this.indexprogress].media[0].media_type == 'Video')))){
            // clearInterval(this.intervalitem);
            // this.pause = false
            this.playVideoStory(this.UserStory[this.indexprogress])
            this.videoMute = false
          }else {
            this.intervalitem = setInterval(this.startTimer, 100);
            // this.pause = true
            this.pause = false
          }
        }  else {
          
          if (this.indexofleft < this.Allusers_with_Story.length - 1) {
            this.showStory(this.Allusers_with_Story[this.indexofleft + 1]);
            this.indexofleft++
            // console.log('indexofleft', indexofleft)
            // console.log('this.Allusers_with_Story.length', this.Allusers_with_Story.length)
          } else {
            clearInterval(this.intervalitem);
            this.back();
          }
        }
      },
      async next(data, current) {
        this.cickNext = false
        this.cickPrev = false
        
        // console.log(current)
        // console.log(data.watched)
        if(current.has_media && (current.media[0].media_type == 'video' || current.media[0].media_type == 'Video')){
          this.StopVideoStory(current)
          this.videostate = true
          let video = document.getElementById(`videoinstory${current.id}`)
          // return video to start
          video.currentTime = 0;
          // return sound
          this.videoMute = false
        }
        clearInterval(this.intervalitem);
        this.indexprogress++;
        this.widthbar = 0;
        await new Promise(r => setTimeout(r, 400));
        
        if(data.has_media && (data.media[0].media_type == 'video' || data.media[0].media_type == 'Video')){
          // clearInterval(this.intervalitem);
          this.playVideoStory(data)
        }else {
          // this.pause = true
          this.intervalitem = setInterval(this.startTimer, 100);
          this.pause = false
        }
        if (data && data.user_id != this.USER.id) {
          const send = {
            story_ids: [parseInt(data.id)],
          };
          this.page != '' ? send.page_id = parseInt(this.page.id) : ''

          ViewsService.AddViewsStory(send);
          data.watched = true
        }
        this.cickNext = true
        this.cickPrev = true
      },
      async prev(current , data) {
        this.cickPrev = false
        this.cickNext = false
        if(current.has_media && (current.media[0].media_type == 'video' || current.media[0].media_type == 'Video')){
          this.StopVideoStory(current)
          this.videostate = true
          let video = document.getElementById(`videoinstory${current.id}`)
          // return video to start
          video.currentTime = 0;
          // return sound
          this.videoMute = false
        }
        clearInterval(this.intervalitem);
        this.indexprogress--;
        this.widthbar = 0;
        await new Promise(r => setTimeout(r, 400));
        if(data.has_media && (data.media[0].media_type == 'video' || data.media[0].media_type == 'Video')){
          this.playVideoStory(data)
          
        }else {
          // this.pause = true
          this.intervalitem = setInterval(this.startTimer, 100);
          this.pause = false
        }

        if (data && data.user_id != this.USER.id) {
          const send = {
            story_ids: [parseInt(data.id)],
          };
          this.page != '' ? send.page_id = parseInt(this.page.id) : ''
          ViewsService.AddViewsStory(send);
          data.watched = true
        }

        this.cickPrev = true
        this.cickNext = true
      },

      gotoaddstory() {
        clearInterval(this.intervalitem);
        this.$router.push({ name: "AddStory" });
      },
    },
  };
</script>
<style lang="scss" scoped>

.closediv {
  right: 0;
  margin: 1rem;
}
.closediv:lang(ar), 
.closediv:lang(ur), 
.closediv:lang(ku)
{
  right: auto;
  left: 0% !important;
  margin: 1rem;
}

.carousel-control-next:lang(ar) {
  right: 100% !important;
  .carousel-control-next-icon {
    transform: rotate(180deg) !important;
  }
}
.carousel-control-prev:lang(ar) {
  left: 100% !important;

  .carousel-control-prev-icon {
    transform: rotate(180deg) !important;
  }
}
.carousel-control-next:lang(ku) {
  right: 100% !important;
  .carousel-control-next-icon {
    transform: rotate(180deg) !important;
  }
}
.carousel-control-prev:lang(ku) {
  left: 100% !important;

  .carousel-control-prev-icon {
    transform: rotate(180deg) !important;
  }
}
.carousel-control-next:lang(ur) {
  right: 100% !important;
  .carousel-control-next-icon {
    transform: rotate(180deg) !important;
  }
}
.carousel-control-prev:lang(ur) {
  left: 100% !important;

  .carousel-control-prev-icon {
    transform: rotate(180deg) !important;
  }
}

// SidBar
.style-create-story {
  background: $defaultColor;
  border: $defaultColor;
  color: #ffffff;
}
.style-create-story:hover {
  background: $defaultColor;
  border: $defaultColor;
  filter: brightness(80%);
  color: #ffffff;
}

.formobile {
  display: none;
}

.sidebar {
  background-color: #ffffff;
  height: calc(100vh - 68px);
  overflow-y: auto;
}

.box {
  cursor: pointer;
  border: 1px solid #0baa92bc;
  margin-bottom: 1rem;
  border-radius: 10px;
  padding: 0.5rem 1rem;

  &.active {
    border: 3px solid $defaultColor !important;
    background-color: $defaultColor;

    .name {
      color: white !important;
      font-weight: 500;
    }

    p {
      color: white !important;
    }
  }

  .name {
    color: #0baa92;
  }

  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
// SidBar

// Carousl

  .carousel-inner {
    width: 46%;
    margin: 0 auto !important;
  }

.back {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .cover_body {
      height: 84%;
      width: 100%;
      border: 1px solid black;
      // border-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden !important;

      .img {
        object-fit: contain;
        // height: 90%;
        width: 100%;
        height: 100%;
      }

      p {
        width: 95%;
        text-align: center;
      }
    }
  
}


// progress
.divprogress {
  position: absolute;
  background-color: transparent !important;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9;

  .progress-bar {
    background-color: $defaultColor !important;
  }

  .name {
    color: $defaultColor !important;
  }
  .img_logo {
    object-fit: cover;
    border-radius: 50% !important;
  }
}

.fully {
  width: 100% !important;
}

// Contollers
.carousel-control-next,
.carousel-control-prev {
  opacity: 1 !important;
}

.carousel-control-next {
  right: -15% !important;
}
.carousel-control-prev {
  left: -15% !important;
}

.carousel-control-next-icon, 
.carousel-control-prev-icon {
  background-color: $defaultColor;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.blok {
  // background-color: blue;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 77;
  height: 110%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.interaction_comment {
  position: absolute;
  bottom: 0.4rem;
  left: 0rem;
  z-index: 999;
  width: 100%;
}

.addreactiontostory {
  background-color: transparent;
  border: 1px solid white;
  width: auto;
  border-radius: 10px;
  padding: 0 0.2rem;
  box-shadow: rgba(233, 232, 232, 0.685) 0px 1px 4px;
}

// Carousl

.col-lg-9 {
  height: calc(100vh - 68px)
}

@media screen and (max-width: 992px) {
  .formobile {
    display: block !important;
  }

  .forlarge {
    display: none;
  }

  .col-lg-3 {
    display: none;
  }

  .col-lg-9 {
    width: 100%;
    height: calc(100vh - 115px) !important;
  
    .carousel-inner {
      width: 96% !important;
      margin: 0 auto !important;

      .carousel-control-next {
        right: -2% !important;
      }
      .carousel-control-prev {
        left: -2% !important;
      }
    }

    .interaction_comment {
      position: absolute;
      bottom: 0.1rem !important;
      left: 0rem;
      z-index: 999;
      width: 100%;
      // background-color: red !important;
      padding: 0 10px !important;

      .svgcomment {
        width: 20px !important;
        height: 20px !important;
      }

      .commentword {
        display: none !important;
      }
    }
  }


}


  // for modal views
  .modal-content {
  z-index: 99999999;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.modal-header:lang(ar) {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  button {
    text-align: left !important;
  }
}

.modal-body:lang(ar) {
  h5 {
    text-align: right !important;
  }
}
</style>
