<template>
    <div class="position-relative">
        <div class="showafterinteract overflow-hidden">
            <img crossorigin="anonymous"  v-if="type==7" src="@/assets/reactions/like.gif" width="50" class="img" alt="" >
            <img crossorigin="anonymous"  v-else-if="type==1" src="@/assets/reactions/love.gif" width="50" class="img" alt="">
            <img crossorigin="anonymous"  v-else-if="type==6" src="@/assets/reactions/angry.gif" width="50" class="img" alt="">
            <img crossorigin="anonymous"  v-else-if="type==2" src="@/assets/reactions/care.gif" width="50" class="img" alt="">
            <img crossorigin="anonymous"  v-else-if="type==3" src="@/assets/reactions/haha.gif" width="50" class="img" alt="">
            <img crossorigin="anonymous"  v-else-if="type==4" src="@/assets/reactions/wow1.gif" width="50" class="img" alt="">
            <img crossorigin="anonymous"  v-else-if="type==5" src="@/assets/reactions/sad1.gif" width="50" class="img" alt="">
        </div>
        <!-- addinteraction {{ story_id }} -->
        <small class="addreaction ">
            <div class="box-reaction-items d-flex align-items-center">
                <span class="position-relative more text-center" v-if="interacted" style="cursor: pointer;" >
                    <svg @click="toggle" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg"  fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#ffffff" fill-rule="evenodd" d="M3 8a2 2 0 100 4 2 2 0 000-4zm5 2a2 2 0 114 0 2 2 0 01-4 0zm7 0a2 2 0 114 0 2 2 0 01-4 0z"></path> </g>
                    </svg>
                    <div id="dropdownfordeletestory" class="dropdown d-none">
                        <p @click="deleteinteraction" class="item">{{ $t('delete_interaction') }}</p>
                    </div>
                </span>
                <span @click="addReactionToStory(story_id, 7)" class="spanmobile position-relative">
                    <img crossorigin="anonymous" 
                    class="img-reaction"
                    src="@/assets/reactions/like.gif"
                    alt="Like emoji"
                    />
                    <small v-if="interacted == 7" class="interacted">__</small>
                </span>
                <span @click="addReactionToStory(story_id, 1)" class="spanmobile position-relative">
                    <img crossorigin="anonymous" 
                    class="img-reaction"
                    src="@/assets/reactions/love.gif"
                    alt="Love emoji"
                    />
                    <small v-if="interacted == 1" class="interacted">__</small>

                </span>
                <span @click="addReactionToStory(story_id, 6)" class="spanmobile position-relative">
                    <img crossorigin="anonymous" 
                    class="img-reaction"
                    src="@/assets/reactions/angry.gif"
                    alt="Haha emoji"
                    />
                    <small v-if="interacted == 6" class="interacted">__</small>

                </span>
                <span @click="addReactionToStory(story_id, 2)" class="spanmobile position-relative">
                    <img crossorigin="anonymous" 
                    class="care-img img-reaction"
                    src="@/assets/reactions/care.gif"
                    alt="Care emoji"
                    />
                    <small v-if="interacted == 2" class="interacted">__</small>

                </span>
                <span @click="addReactionToStory(story_id, 3)" class="spanmobile position-relative" >
                    <img crossorigin="anonymous" 
                    class="img-reaction"
                    src="@/assets/reactions/haha.gif"
                    alt="Wow emoji"
                    />
                    <small class="interacted" v-if="interacted == 3">__</small>

                </span>
                <span @click="addReactionToStory(story_id, 4)" class="spanmobile position-relative">
                    <img crossorigin="anonymous" 
                    class="img-reaction"
                    src="@/assets/reactions/wow1.gif"
                    alt="Angry emoji"
                    />
                    <small v-if="interacted == 4" class="interacted">__</small>

                </span>
                <span @click="addReactionToStory(story_id, 5)" class="spanmobile position-relative" >
                    <img crossorigin="anonymous" 
                    class="img-reaction"
                    src="@/assets/reactions/sad1.gif"
                    alt="Sad emoji"
                    />
                    <small v-if="interacted == 5" class="interacted">__</small>

                </span>
            </div>
        </small>
    </div>
</template>

<script>
import storyInteractionService from "@/services/story/storyInteractionService"
import { mapGetters } from 'vuex'
export default {
    name: "addInteractionComponents",
    props: ["story_id", "interacted"],
    data(){
        return{
            type : null,
            page: localStorage.getItem("page")
            ? JSON.parse(localStorage.getItem("page"))
            : "",
        }
    },
    computed: {
        ...mapGetters("story", {
            UserStory: "User_Stories", // All Story For User In State
        }),
    },
    methods: {
        toggle(){
            document.getElementById('dropdownfordeletestory').classList.toggle('d-none')
        },
        deleteinteraction(){
            storyInteractionService.DeleteStoryIntecaction(parseInt(this.story_id)).then(() => {
                this.$store.commit('story/DELETE_ALL_INTERACTION', this.story_id )
                this.$snotify.success(
                    this.$i18n.t("Delete All Interactions"),
                    this.$i18n.t("interactions")
                );
            })
        },
        async addReactionToStory(id, type){
            let data = {
                story_id: parseInt(id),
                type: parseInt(type),
            }
            this.page != '' ? data.page_id = parseInt(this.page.id) : ''
            this.type = type
            await storyInteractionService.AddStoryIntecaction(data).then(() => {
                this.$store.commit('story/ADD_INTERACTIO_TO_STORY', data )
                this.$snotify.success(
                    this.$i18n.t("Add Interaction Successfully"),
                    this.$i18n.t("interactions")
                );
                this.type = null
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/Styles/Home/Post/BoxReactionPost.scss";

.showafterinteract {
    position: absolute;
    width: 50px;
    height: 200px;
    background-color: transparent;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);

    .img{
        position: absolute;
        animation-name: walk;
        animation-duration: 2s;
        animation-iteration-count: 3;
    }
}


@keyframes walk {
    0% {
        bottom: 0;
    }
    10%{
        bottom: 10%;
    }
    20%{
        bottom: 20%;
    }
    30%{
        bottom: 30%;
    }
    40%{
        bottom: 40%;
    }
    50%{
        bottom: 50%;
    }
    60%{
        bottom: 60%;
    }
    70%{
        bottom: 70%;
    }
    80%{
        bottom: 80%;
    }
    90%{
        bottom: 90%;
    }
    100% {
        left: 150%;    
    }
}

.dropdown {
    width: 200px;
    position: absolute;
    bottom: 80%;
    background-color: white;
    border-radius: 5px;
    z-index: 999;

    .item {
        text-decoration: none;
        padding: 0.2rem 0.5rem;
        border-radius: 5px;
        cursor: pointer;
        font-size: 15px;
        &:hover {
            background-color: rgba(35, 34, 34, 0.714);
            color: white;
        }
    }
    
}

.interacted {
    position: absolute;
    color: white;
    text-align: center;
    width: 100%;
    top: 52%;
    left: 50%;
    color: $defaultColor;
    font-weight: bolder;
    transform: translateX(-50%);
    z-index: 9999;
    font-weight: bolder !important;
}

.spanmobile {
    // border: 1px solid red;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;
    height: 40px !important;
    overflow: hidden;
}

@media screen and (max-width: 992px) {
    .box-reaction-items {
        flex-direction: row !important;

        .img-reaction {
            width: 30px !important;
            height: 30px !important;
        }
    }

    .interacted {
        position: absolute;
        color: white;
        text-align: center;
        width: 100%;
        top: 55%;
        left: 50%;
        color: blue;
        font-weight: bolder;
        transform: translateX(-50%);
        z-index: 9999;
    }

    .spanmobile {
        // background-color: red !important;
        padding: 0 !important;
        height: 45px !important;
        margin: 0 !important;
    }

    .more {
        // background-color: red;
        display: flex;
        align-items: center !important;
    }
}

</style>