import gql from "graphql-tag";
import { POST_DATA } from "../PostData";

export const STORE_QUESTION = gql`
  ${POST_DATA}
  mutation StoreQuestion(
    $page_id: Int
    $content: String
    $privacy: String
    $custom_profile_ids: [Int]
    $friends_ids: [Int]
    $media: [MediaInput]
  ) {
    StoreQuestion(
      page_id: $page_id
      content: $content
      privacy: $privacy
      custom_profile_ids: $custom_profile_ids
      friends_ids: $friends_ids
      media: $media
    ) {
      ...PostData
    }
  }
`;
