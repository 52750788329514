<template>
  <svg
    version="1.1"
    id="Uploaded to svgrepo.com"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="25"
    height="25"
    viewBox="0 0 16 23"
    xml:space="preserve"
    fill="#888888"
    stroke="#888888"
    stroke-width="0.00032"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      <g
        color="#888888"
        fill="gray"
        font-family="sans-serif"
        font-weight="400"
        white-space="normal"
      >
        <path
          d="M2 7v9h12V7zm1 1h10v7H3z"
          overflow="visible"
          style="
            line-height: normal;
            font-variant-ligatures: none;
            font-variant-position: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-variant-alternates: normal;
            font-feature-settings: normal;
            text-indent: 0;
            text-align: start;
            text-decoration-line: none;
            text-decoration-style: solid;
            text-decoration-color: #888888;
            text-transform: none;
            shape-padding: 0;
            isolation: auto;
            mix-blend-mode: normal;
            marker: none;
          "
        />
        <path
          d="M8 0a5 5 0 0 0-5 5v2h1V5c0-2.222 1.778-4 4-4s4 1.778 4 4v2h1V5a5 5 0 0 0-5-5z"
          overflow="visible"
          style="
            line-height: normal;
            text-indent: 0;
            text-align: start;
            text-decoration-line: none;
            text-decoration-style: solid;
            text-decoration-color: #000000;
            text-transform: none;
            shape-padding: 0;
            isolation: auto;
            mix-blend-mode: normal;
            marker: none;
          "
        />
        <path
          d="M2 7v9h12V7zm1 1h10v7H3zm4 2v3h2V9.75z"
          overflow="visible"
          style="
            line-height: normal;
            font-variant-ligatures: none;
            font-variant-position: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-variant-alternates: normal;
            font-feature-settings: normal;
            text-indent: 0;
            text-align: start;
            text-decoration-line: none;
            text-decoration-style: solid;
            text-decoration-color: #000000;
            text-transform: none;
            shape-padding: 0;
            isolation: auto;
            mix-blend-mode: normal;
            marker: none;
          "
        />
      </g>
    </g>
  </svg>
</template>
