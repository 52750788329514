<template>
  <!-- Start Sidebar -->
  <SidebarShowSingleGroup></SidebarShowSingleGroup>
  <!-- End Sidebar -->

  <!-- Start Content -->
  <div class="content" v-if="!is_loading">
    <div class="col-lg-9 offset-md-3 right mb-0 pt-1 px-3 center order-last style-div">
      <div class="container">
        <div class="row m-2 for-lang">
          <div class="col-12">
            <div class="d-flex" style="float: right">
              <div class="dropdown me-1">
                <button
                  type="button"
                  class="btn btn-secondary dropdown-toggle"
                  id="dropdownMenuOffset"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-offset="10,20"
                >
                  Other
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuOffset">
                  <li>
                    <router-link
                      :to="{ name: 'groupMembershipQuestions' }"
                      class="dropdown-item"
                      href="#"
                      ><b>Member question </b> <br />
                      <span style="font-size: 13px">
                        you can ask questions when request persons join to your
                        group
                      </span>
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <h5>{{ $t("Member Requests") }}</h5>
            <p class="mx-4">
              {{ this.requestsToJoin.length }} {{ $t("Request") }}
            </p>
          </div>
        </div>
        <br />
        <div class="row m-2 for-lang">
          <div
            v-for="item in requestsToJoin"
            :key="item.id"
            class="row align-items-center mb-2 pb-1 border-bottom"
          >
            <!-- {{ Invitations_Pages_Only_Liked }} -->
            <div class="col-lg-2 text-center">
              <img crossorigin="anonymous" 
                v-if="item.has_media_profile == false"
                src="@/assets/story_img/user.png"
                class="card-img-top"
              />
              <img crossorigin="anonymous" 
                v-else-if="item.media[0].collection_name == 'profile'"
                :src="item.media[0].src_url"
                class="card-img-top"
              />
              <img crossorigin="anonymous" 
                v-else-if="item.media[1].collection_name == 'profile'"
                :src="item.media[1].src_url"
                class="card-img-top"
              />

            </div>
            <div class="col-lg-10">
              <h6 class="m-3">
                <router-link
                  :to="{ name: 'homeMyProfile', params: { id: item.id } }"
                  class="text-decoration-none color-role"
                  >{{ item.name }}</router-link
                >
              </h6>

              <div class="text-end">
                <button
                  class="btn"
                  @click="handleRequest(this.group_id, item.id,true)"
                >
                  {{ $t("Accept") }}
                </button>
                <button class="btn mx-3" @click="handleRequest(this.group_id, item.id,false)">
                  {{ $t("Remove") }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row m-2 for-lang"
          v-if="this.requestsToJoin.length == 0"
        >
          <b class="text-center">{{ $t("There is no found any join request") }}</b>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="text-center mt-3" style="height: 505px">
    <br />
    <br />
    <span class="loader">&nbsp;Loading</span>
  </div>
</template>

<script>
import GroupService from "@/services/GroupsServices/GroupService";
import SidebarShowSingleGroup from "../../Sidebars/SidebarShowSingleGroup.vue";

export default {
  name: "MembershipRequests",
  data() {
    return {
      group_id:this.$route.params.id,
      requestsToJoin: [],
      is_loading: false,
    };
  },
  created() {
    this.get_all_member_requets();
    this.$store.commit("singleGroup/destroySingleGroupForReload");
    this.$store.dispatch("singleGroup/a_setSingleGroup", this.$route.params.id);
  },
  methods: {
    get_all_member_requets() {
      this.is_loading = true;
      GroupService.s_allMembersRequestsTryToJoin(
        this.$route.params.id,
        0
      ).then((res) => {
        this.requestsToJoin = res.data.AllMembersNotApprovedQuery.items;
        this.is_loading = false;
      });
    },

    //Accept_OR_Reject
    async handleRequest(group_id, user_id,status) {
      await GroupService.s_requestsJoining(group_id, user_id,status).then(
        (res) => {
          const index = this.requestsToJoin.findIndex(
            (item) => item.id == user_id
          );
          this.$snotify.success(res.data.HandleGroupRequestsMutation, "Group");
          this.requestsToJoin.splice(index, 1);
        }
      );
    },
  },
  components: { SidebarShowSingleGroup },
};
</script>

<style lang="scss" scoped>
.color-role {
  color: #b048ca !important;
  font-weight: bold;
}

.center:lang(ar) {
  margin-right: 25% !important;
  margin-left: 0 !important;
}
.card-img-top {
  border-radius: 50%;
  width: 40%;
}

.btn {
  // border: 1px solid black;
  background-color: rgb(187, 183, 183);
  padding: 0.5rem 1.2rem;
  border-radius: 8px;
  font-weight: 600;

  &:hover {
    background-color: rgb(177, 173, 173);
  }
}
.row.align-items-center.mb-2.pb-1.border-bottom {
  background: #ffffff;
  border-radius: 5px;
}
.card-img-top {
  border-radius: 20%;
  width: 50%;
}
.style-div {
  height: 505px;
}
// loading
.loader {
  color: #6e3c63;
  font-size: 10px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.3s infinite linear;
  transform: translateZ(0);
  border: none;
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
      0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
      0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
      0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
      0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.center:lang(ar) {
  margin-right: 25% !important;
  margin-left: 0 !important;
}
.center:lang(ku) {
  margin-right: 25% !important;
  margin-left: 0 !important;
}
</style>
