<template>
    <!-- Modal -->
    <div class="modal fade" :id="'staticDelete'" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">
                        {{ $t("Are you sure to delete this?") }}
                    </h5>
                    <div class="close-dir">
                        <button @click="ClearDeletePostId" type="button" class="btn-close" data-bs-dismiss="modal"
                            aria-label="Close"></button>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="ClearDeletePostId">
                        {{ $t("Close") }}
                    </button>
                    <button  type="button" class="btn btn-danger" :disabled="!SelectedPostId"
                        data-bs-dismiss="modal" @click="delete_post()">
                        {{ $t("Delete") }}
                    </button>
                    <!-- <button v-else
                        class="btn btn-lg btn-light w-100 text-dark fw-bold d-flex align-items-center justify-content-center"
                        type="submit">
                        Loading ...
                        <div class="spinner-border text-dark ms-2" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </button> -->
                </div>
            </div>
        </div>
    </div>
    <!-- End modal Delete -->
</template>

<script>
import postServices from "@/services/HomePage/post.services";
import { mapState } from "vuex";
export default {
    name: "DeletePost",
    computed: {
        ...mapState(["SelectedPostId"]),
    },
    methods: {
        ClearDeletePostId(){
            this.$store.commit("ClearSelectedPostId");
        },
        delete_post() {
            this.delete(this.SelectedPostId);
        },
        async delete(id) {
            // this.isloading = true;
            postServices.deletepost(id).then(() => {
                this.$snotify.success(
                    this.$i18n.t("Deleted successfully"),
                    this.$i18n.t("Post")
                );

                if (this.location == 3) {
                    this.$store.commit("singlePage/DELETE_POST_DATA", {
                        id: id,
                        page_id: parseInt(this.$route.params.id),
                    });
                } else {
                    this.$store.commit("profile/DELETE_POST", id);
                    this.$store.commit("post/DELETE_POST_DATA", id);
                }
                // this.isloading = false;
                this.$store.commit("ClearSelectedPostId")
            });
            
        },
    }
}
</script>

<style lang="scss" scoped></style>