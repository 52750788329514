<template>
  <span v-if="post.interacted == null">
    <LikeOff></LikeOff>
  </span>
  <LoveSvg v-else-if="post.interacted === 1"></LoveSvg>
  <LikeOn v-else-if="post.interacted === 7"></LikeOn>
  <AngrySvg v-else-if="post.interacted === 6"></AngrySvg>
  <CareSvg v-else-if="post.interacted === 2"></CareSvg>
  <HahaSvg v-else-if="post.interacted === 3"></HahaSvg>
  <WowSvg v-else-if="post.interacted === 4"></WowSvg>
  <SadSvg v-else-if="post.interacted === 5"></SadSvg>
  <!-- <img crossorigin="anonymous"  class="img-reaction" src="@/assets/reactions/like.gif" alt="Like emoji" /> -->
  <!-- <img crossorigin="anonymous" 
      v-else-if="post.interacted[0].type === 6"
      class="img-reaction"
      src="@/assets/reactions/angry.gif"
      alt="Haha emoji"
    />
    <img crossorigin="anonymous" 
      v-else-if="post.interacted[0].type === 2"
      class="care-img img-reaction"
      src="@/assets/reactions/care.gif"
      alt="Care emoji"
    />
    <img crossorigin="anonymous" 
      v-else-if="post.interacted[0].type === 3"
      class="img-reaction"
      src="@/assets/reactions/haha.gif"
      alt="Wow emoji"
    />
    <img crossorigin="anonymous" 
      v-else-if="post.interacted[0].type === 4"
      class="img-reaction"
      src="@/assets/reactions/wow.gif"
      alt="Angry emoji"
    />
    <img crossorigin="anonymous" 
      v-else-if="post.interacted[0].type === 5"
      class="img-reaction"
      src="@/assets/reactions/sad.gif"
      alt="Sad emoji"
    /> -->
</template>

<script>
import LikeOff from "@/icons/reactions/LikeOff.vue";
import LikeOn from "@/icons/reactions/LikeOn.vue";
import LoveSvg from "@/icons/reactions/LoveSvg.vue";
import AngrySvg from "@/icons/reactions/AngrySvg.vue";
import CareSvg from "@/icons/reactions/CareSvg.vue";
import HahaSvg from "@/icons/reactions/HahaSvg.vue";
import WowSvg from "@/icons/reactions/WowSvg.vue";
import SadSvg from "@/icons/reactions/SadSvg.vue";
export default {
  props: ["post"],
  components: {
    LikeOff,
    LikeOn,
    LoveSvg,
    AngrySvg,
    CareSvg,
    HahaSvg,
    WowSvg,
    SadSvg,
  },
};
</script>
