<template>
  <!-- Start Sidebar -->
  <SidebarShowSingleGroup></SidebarShowSingleGroup>
  <!-- End Sidebar -->

  <!-- Start Content -->
  <div class="content">
    <div
      class="col-lg-9 offset-md-3 right mb-0 pt-1 px-3 center order-last style-div"
    >
      <div class="container">
        <div class="row m-2" :dir="DefineDeriction() ? 'rtl' : 'ltr'">
          <div class="col-12">
            <div class="d-flex" :style="{ float: DefineDeriction() ? 'left' : 'right' }">
              <div
                class="dropdown me-1"
                :style="{ float: DefineDeriction() ? 'left' : 'right' }"
              >
                <div class="form-floating">
                  <select
                    class="form-select"
                    @change="onChange($event)"
                    v-model="type_post"
                  >
                    <option value="0">{{ $t("Pending Posts") }}</option>
                    <option value="2">{{ $t("Canceled Posts") }}</option>
                  </select>
                </div>
              </div>
            </div>
            <h5 v-if="this.type_post == 0">{{ $t("Pending Posts") }}</h5>
            <h5 v-else-if="this.type_post == 2">{{ $t("Canceled Posts") }}</h5>
            <p  class="mx-4">
              {{ this.countIsApproved }} {{ $t("Posts") }}
            </p>
          
          </div>
          <div class="container">
            <fieldset class="border rounded-3 p-3" style="background: #e0e0e0">
              <legend class="float-none w-auto px-3">Sort By</legend>
              <!-- Sort By -->
              <PostsControl
                @changeOrder="changeOrder"
                :rules="rules"
              ></PostsControl>
            </fieldset>
          </div>
        </div>
        <br />
        <div class="row m-1" :dir="DefineDeriction() ? 'rtl' : 'ltr'">
          <div class="col-lg-7 col-sm-12">
            <div class="row m-2" :dir="DefineDeriction() ? 'rtl' : 'ltr'">
              <div v-for="(post, index) in displayedPosts" :key="index">
                <PendingPostComponent
                  :post="post"
                  v-if="post.is_approved == 0 || post.is_approved == 2"
                ></PendingPostComponent>
              </div>
              <div v-show="displayedPosts.length == 0" class="text-center">
                <img crossorigin="anonymous" 
                  src="@/assets/5081-empty-box.gif"
                  alt=""
                  style="height: 40%; width: 90%; margin: 0px 7%"
                />
              </div>
              <div class="text-center" v-show="moreExistsPostsIsApproved">
                <button
                  type="button"
                  class="btn btn-block"
                  v-if="!loadingPostsIsApproved"
                  v-on:click="
                    a_getNext_posts_by_is_approved({
                      id: this.$route.params.id,
                      is_approved: this.type_post,
                    })
                  "
                >
                  <span>{{ $t("Load More") }} </span>
                </button>
                <button v-else class="btn btn-block" type="submit">
                  {{ $t("Loading ...") }}
                </button>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="style-sticky">
              <div class="row m-2" :dir="DefineDeriction() ? 'rtl' : 'ltr'">
                <div class="card add_post_card">
                  <div
                    class="row justify-content-center overflow-hidden align-items-center"
                  >
                    <h5>{{ $t("About") }}</h5>
                    <span>
                      {{ singleGroup.des }}
                    </span>
                    <span v-if="singleGroup.des == null">{{
                      $t("Contains no description")
                    }}</span>
                    <br />
                    <h6 v-if="singleGroup.type == 1">
                      <PublicSvg></PublicSvg> {{ $t("Public") }}
                    </h6>
                    <h6 v-if="singleGroup.type == 0">
                      <PrivateSvg></PrivateSvg> {{ $t("Private") }}
                    </h6>
                    <span class="detail-private">
                      {{
                        $t(
                          `Anyone can see who's in the group and what they post`
                        )
                      }}
                    </span>
                    <div v-if="singleGroup.available_access == 1">
                      <h6>
                        <VisibleSvg></VisibleSvg>
                        {{ $t("Visible") }}
                      </h6>
                      <span class="detail-visible">{{
                        $t("Anyone can find this group.")
                      }}</span>
                    </div>
                    <div v-if="singleGroup.available_access == 0">
                      <h6>
                        <InvisibleSvg></InvisibleSvg>
                        {{ $t("Invisible") }}
                      </h6>
                      <span>No can find this group</span>
                    </div>
                    <router-link
                      class="router-link-to-profile"
                      :to="{
                        name: 'aboutGroup',
                        params: this.$route.params.id,
                      }"
                    >
                      <div class="text-center mt-2 mb-2 back-style">
                        <span>{{ $t("Learn More") }}</span>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicSvg from "@/icons/Groups/PublicSvg.vue";
import VisibleSvg from "@/icons/Groups/VisibleSvg.vue";
import InvisibleSvg from "@/icons/Groups/InvisibleSvg.vue";
import PrivateSvg from "@/icons/Groups/PrivateSvg.vue";

import SidebarShowSingleGroup from "../Sidebars/SidebarShowSingleGroup.vue";
import { mapActions, mapGetters } from "vuex";
import PendingPostComponent from "./TypePosts/PendingPostComponent.vue";
import PostsControl from "./TypePosts/PostsControl.vue";
import LangMixin from "@/mixins/LangMixin";
export default {
  name: "PendingPosts",
  data() {
    return {
      type_post: 0,
      rules: {
        reverse: false,
        byId: false,
        byName: false,
      },
    };
  },
  mixins: [LangMixin],
  created() {
    this.$store.dispatch("singleGroup/a_setSingleGroup", this.$route.params.id);
    this.$store.dispatch("singleGroup/a_get_posts_by_is_approve", {
      id: this.$route.params.id,
      is_approved: this.type_post,
    });
  },
  computed: {
    ...mapGetters({
      posts_by_isapprove: "singleGroup/posts_by_isapprove",
      singleGroup: "singleGroup/singleGroup",
      moreExistsPostsIsApproved: "singleGroup/moreExistsPostsIsApproved",
      loadingPostsIsApproved: "singleGroup/loadingPostsIsApproved",
      countIsApproved: "singleGroup/countIsApproved",
    }),
    displayedPosts() {
      let final = [...this.posts_by_isapprove];
      if (this.rules.byName) {
        final = this.sortByName;
      }
      if (this.rules.byId) {
        final = this.sortById;
      }
      return this.rules.reverse ? [...final].reverse() : final;
    },
    sortById() {
      const result = [...this.posts_by_isapprove].sort(
        (a, b) => a.user_id.profile.id - b.user_id.profile.id
      );
      return result;
    },
    sortByName() {
      const result = [...this.posts_by_isapprove].sort((a, b) => {
        const NameOne = a.user_id.profile.name.toLowerCase();
        const NameTwo = b.user_id.profile.name.toLowerCase();
        if (NameOne > NameTwo) return 1;
        if (NameOne < NameTwo) return -1;
        return 0;
      });
      return result;
    },
  },
  methods: {
    ...mapActions({
      a_getNext_posts_by_is_approved:
        "singleGroup/a_getNext_posts_by_is_approved",
    }),
    onChange(event) {
      console.log(event.target.value);
      this.$store.dispatch("singleGroup/a_get_posts_by_is_approve", {
        id: this.$route.params.id,
        is_approved: this.type_post,
      });
    },
    changeOrder(payload) {
      const { name, checked } = payload;
      console.log(this.rules);
      this.rules[name] = checked;
    },
  },
  components: {
    SidebarShowSingleGroup,
    PendingPostComponent,
    PostsControl,
    InvisibleSvg,
    VisibleSvg,
    PublicSvg,
    PrivateSvg,
  },
};
</script>

<style lang="scss" scoped>
.btn {
  --btn-color: #983baf;
  --btn-bg: #99dbad;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: var(--btn-bg);
  color: var(--btn-color);
  padding: 14px 22px;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  font-weight: 600;
  font-size: 1rem;
  font-family: system-ui;
  border: 2px solid var(--btn-color);
  transition: 100ms ease;
  box-shadow: 5px 5px 0 0 var(--btn-color);
}

.btn:hover {
  box-shadow: 2px 2px 0 0 var(--btn-color);
}

.btn:active {
  transition: 50ms ease;
  box-shadow: 0 0 0 0 var(--btn-color);
}

.btn:focus-visible {
  outline: 0;
  --btn-color: #002cc8;
}

.btn:focus-visible::after {
  position: absolute;
  left: 50%;
  top: calc(100% + 12px);
  transform: translateX(-50%);
  content: "\21E7";
  animation: float 0.5s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translateX(-50%) translatey(0px);
  }
  50% {
    transform: translateX(-50%) translatey(-6px);
  }
  100% {
    transform: translateX(-50%) translatey(0px);
  }
}
///////////
.color-role {
  color: #983baf !important;
  font-weight: bold;
}
.right:lang(ar) {
  margin-right: 0% !important;
  margin-left: 0 !important;
}
.center:lang(ar) {
  margin-right: 25% !important;
  margin-left: 0 !important;
}
.card-img-top {
  border-radius: 50%;
  width: 40%;
}

.btn {
  // border: 1px solid black;
  background-color: rgb(187, 183, 183);
  padding: 0.5rem 1.2rem;
  border-radius: 8px;
  font-weight: 600;

  &:hover {
    background-color: rgb(177, 173, 173);
  }
}
.row.align-items-center.mb-5.pb-3.border-bottom {
  background: #ffffff;
  border-radius: 5px;
}
.card-img-top {
  border-radius: 20%;
  width: 75%;
}
.form-select {
  background-image: none;
}
.form-floating > .form-select {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.controls {
  display: flex;
  justify-content: space-around;
}

.control-label {
  display: block-inline;
  margin: 0 0.5rem;
}
fieldset {
  border: solid 1px gray;
  padding-top: 5px;
  padding-right: 12px;
  padding-bottom: 10px;
  padding-left: 12px;
}
legend {
  float: none;
  width: inherit;
}

.center:lang(ar) {
  margin-right: 25% !important;
  margin-left: 0 !important;
}
.center:lang(ku) {
  margin-right: 25% !important;
  margin-left: 0 !important;
}

.style-sticky {
  position: sticky;
  top: 125px;
}
.style-sticky-photos {
  position: sticky;
  top: 400px;
}
@media screen and (max-width: 992px) {
  .col-4 {
    display: none;
  }
}
.photo-box {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 10px;
  margin-top: 15px;
}

.photo-box div img {
  // width: 100%;
  cursor: pointer;
  width: 65px;
  height: 60px;
  border: 1px solid rgb(175, 171, 171);
  margin: 2px 5px;
  border-radius: 7px;
}

.write-post-container {
  width: 100%;
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  color: #626262;
  margin: 0px 5px 10px 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.detail-private {
  margin: 0 35px 0 60px;
}
.detail-visible {
  margin: 0px 32px 0px 23px;
}
.detail-private:lang(ar) {
  margin: 0;
}
.detail-visible:lang(ar) {
  margin: 0;
}
.detail-private:lang(ku) {
  margin: 0;
}
.detail-visible:lang(ku) {
  margin: 0;
}
.back-style {
  background: lightgray;
  border-radius: 4px;
  padding: 6px;
}
.back-style:hover {
  background: #b1abab;
  border-radius: 4px;
  padding: 6px;
}
.router-link-to-profile {
  text-decoration: none;
  color: #525252;
  font-weight: bold;
}
</style>
