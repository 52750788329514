<template>
  <div class="profile-intro" v-if="!loading_info">
    <div class="loading-info" v-if="this.userInformation.publicdetail.length != 0">
      <div v-for="(bio, index) in userInformation.publicdetail" :key="index">
        <div v-if="bio.details.value != null">
          <h3 v-if="bio.key_en == 'Biography'">
            {{ this.$i18n.t(bio.key_en) }}
          </h3>
          <p
            class="intro-text"
            style="word-break: break-word"
            v-if="bio.key_en == 'Biography'"
          >
            <span dir="auto" v-if="bio.details.value != null"
              >{{ bio.details.value }} <img crossorigin="anonymous"  src="@/assets/images/feeling.png"
            /></span>
          </p>
        </div>
      </div>

      <ul>
        <!-- Jobs -->
        <div v-if="this.userInformation.jobs.length != 0">
          <div v-for="job in userInformation.jobs" :key="job.id">
            <div v-if="job.status == true">
              <li>
                <img crossorigin="anonymous"  src="@/assets/images/profile-job.png" alt="" class="rtl-details" />
                <span  class="details-margin">{{ job.title }} <span v-if="job.city">in {{ job.city.name }}</span></span>
              </li>
            </div>
          </div>
        </div>
        <!-- Studies -->

        <div v-if="this.userInformation.studies.length != 0">
          <div v-for="study in userInformation.studies" :key="study.id">
            <div v-if="study.status == true">
              <li>
                <img crossorigin="anonymous"  src="@/assets/images/profile-study.png" alt="" class="rtl-details" />
                <span  class="details-margin"
                  >{{ study.name }} <span v-if="study.city">in {{ study.city.name }} </span></span
                >
              </li>
            </div>
          </div>
        </div>
        <!-- Other public details -->

        <!-- <div v-for="(bio, index) in profile_details" :key="index">
          <li v-if="bio.key_en == 'Home'">
            <img crossorigin="anonymous"  src="@/assets/images/profile-home.png" alt="" class="rtl-details" />
            <span class="details-margin">{{
              this.$i18n.t(this.profile_details[1].key_en)
            }}</span>

            :&nbsp;
            <span v-if="this.profile_details[1].details.value" class="details-margin">{{
              this.profile_details[1].details.value
            }}</span>
            <span v-else class="details-margin"> {{ $t("no value specified") }}</span>
          </li>
        </div> -->
        <div v-for="(hometown, index) in userInformation.publicdetail" :key="index">
          <div v-if="hometown.details.value">
            <li v-if="hometown.key_en == 'Hometown'">
              <HomeTownIcon></HomeTownIcon> &nbsp;
              <b class="details-margin">{{ $t("From") }}</b>
              &nbsp;
              <span v-if="hometown.details.value" class="details-margin">{{
                hometown.details.value
              }}</span>
            </li>
          </div>
        </div>
        <!-- TODO: USE CITIES  -->
        <div v-for="(city, index) in userInformation.publicdetail" :key="index">
          <div v-if="city.details.value">
            <li v-if="city.key_en == 'Current City'">
              <!-- icon -->
              <LocationIcon></LocationIcon> &nbsp;
              <b class="details-margin">{{ $t("lives in") }}</b>
              &nbsp;
              <span class="details-margin">{{ city.details.value }}</span>
            </li>
          </div>
        </div>
        <div v-for="(relationship, index) in userInformation.publicdetail" :key="index">
          <div v-if="relationship.details.value">
            <li v-if="relationship.key_en == 'Relationship'">
              <svg
                width="25"
                height="25"
                viewBox="0 0 21.00 21.00"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                fill="#000000"
                transform="rotate(0)"
                stroke="#000000"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0" />

                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />

                <g id="SVGRepo_iconCarrier">
                  <title>love [#5e5e5e]</title>
                  <desc>Created with Sketch.</desc>
                  <defs></defs>
                  <g
                    id="Page-1"
                    stroke-width="0.00021000000000000004"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g
                      id="Dribbble-Light-Preview"
                      transform="translate(-139.000000, -361.000000)"
                      fill="#5e5e5e"
                    >
                      <g id="icons" transform="translate(56.000000, 160.000000)">
                        <path
                          d="M103.991908,206.599878 C103.779809,210.693878 100.744263,212.750878 96.9821188,215.798878 C94.9997217,217.404878 92.0324261,217.404878 90.042679,215.807878 C86.3057345,212.807878 83.1651892,210.709878 83.0045394,206.473878 C82.8029397,201.150878 89.36438,198.971878 93.0918745,203.314878 C93.2955742,203.552878 93.7029736,203.547878 93.9056233,203.309878 C97.6205178,198.951878 104.274358,201.159878 103.991908,206.599878"
                          id="love-[#5e5e5e]"
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              &nbsp;
              <!-- <b>{{ this.$i18n.t(relationship.key_en) }}</b> -->

              <span style="word-break: break-word">{{ relationship.details.value }}</span>
            </li>
          </div>
        </div>
        <div v-for="(dataOfJoining, index) in userInformation.publicdetail" :key="index">
          <li v-if="dataOfJoining.key_en == 'Date of joining the application'">
            <b class="details-margin">{{ this.$i18n.t(dataOfJoining.key_en) }}</b>
            <br />
            <span v-if="dataOfJoining.details.value" class="details-margin">{{
              dataOfJoining.details.value
            }}</span>
            <span v-else class="details-margin"> {{ $t("no value specified") }}</span>
          </li>
        </div>
      </ul>
    </div>
  </div>

  <!--  -->
  <div v-else class="profile-intro">
    <div class="container loading-skeleton">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-head">
              <h5 class="card-title">Card title</h5>
              <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <p class="card-text">Some quick</p>

              <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HomeTownIcon from "@/icons/userProfile/HomeTownIcon.vue";
import LocationIcon from "@/icons/userProfile/LocationIcon.vue";
import { mapGetters } from "vuex";
export default {

  computed: {
    ...mapGetters({
      userInformation: "profile/userInformation",
      loading_info: "profile/loading_info",
    }),
  },
  components: { HomeTownIcon, LocationIcon },
};
</script>

<style lang="scss" scoped>
.profile-intro {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.profile-intro h3 {
  font-weight: 600;
}

.intro-text {
  text-align: 10px 0;
  font-size: 15px;
}

.intro-text img {
  width: 15px;
  margin-bottom: -3px;
}

.profile-intro hr {
  border: 0;
  height: 1px;
  background: #ccc;
  margin: 24px 0;
}

.profile-intro ul li {
  list-style: none;
  font-size: 13px;
  margin: 10px -33px;
  // display: flex;
  align-items: center;
}

.profile-intro ul li img {
  width: 26px;
  margin-right: 10px;
}
.profile-intro ul li:lang(ar) {
  margin-right: -35px;
}
.details-margin:lang(ar) {
  margin: 5px 8px;
}

.container {
  margin-top: 4em;
  margin-bottom: 4em;
}

// Bootstrap Loading Skeleton
%loading-skeleton {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;

  &::placeholder {
    color: transparent;
  }
}
@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;

  img {
    filter: grayscale(100) contrast(0%) brightness(1.8);
  }
  h5,
  p,
  li,
  .btn,
  label,
  .form-control {
    @extend %loading-skeleton;
  }
}
.card {
  border: none;
}
</style>
