<template>
  <ul
    ref="dropdownMenuButtonNotification"
    class="dropdown-menu notify"
    aria-labelledby="dropdownMenuButtonNotification"
  >
    

    <div class="pb-5" v-if="loading_notification">
      <LoaderSearch />
    </div>
    <div v-else>
      <div class="d-flex justify-content-between p-2">
      <span @click="redirect_to_pageNotification" class="show_all">
        {{ $t("Manage Notification") }}</span
      >
      <p @click="MarkAllNotificationsAsRead()" style="font-size: 15px">
        {{ $t("Make All as Reading") }}
      </p>
    </div>
      <div
        v-for="(notification, index) in allNotifications"
        :key="index"
        class="d-flex row align-items-center box_notification"
        :class="
          notification.pivot.isClicked === 0 ? 'notificationBackground' : ''
        "
      >
        <div class="col-11">
          <HandleNotifications :notification="notification"></HandleNotifications>
        </div>
        <p
          v-if="notification.pivot.isClicked === 0"
          class="dot m-0 p-0 col-1 text-center"
          @click="readOne(notification.id)"
        >
        <!-- {{ notification.category }} -->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            class="bi bi-dot"
            viewBox="0 0 16 16"
          >
            <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
          </svg>
        </p>
      </div>
      <div
        v-if="allNotifications.length == 0"
        class="d-flex row align-items-center box_notification"
      >
        <h4 class="text-center">No notifications yet!</h4>
      </div>
      <div class="text-center" v-show="moreExists_notify">
        <button
          v-if="!loading"
          type="button"
          class="button_loadMore"
          v-on:click="Execute_a_get_next_notification"
        >
          {{ $t("Load More of Notifications") }}
        </button>
        <button v-else class="btn btn-primary" type="button" disabled>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="visually-hidden">Loading...</span>
        </button>
      </div>
    </div>
  </ul>
</template>

<script>
import HandleNotifications from "@/components/notificationsComponents/HandleNotifications.vue";
import notificationServices from "@/services/Notification/notificationServices";
import { mapState, mapGetters, mapActions } from "vuex";
import LoaderSearch from "@/components/OtherComponent/LoaderSearch.vue";

export default {
  name: "notificationComponent",
  components: {
    HandleNotifications,
    LoaderSearch
  },
  computed: {
    ...mapState("notification", {
      allNotifications: "allNotifications",
      loading: "loading",
      moreExists_notify: "moreExists_notify",
      loading_notification: "loading_notification"
    }),
    
    ...mapGetters({
      lang: "auth/lang",
    }),
  },

  methods: {
    Execute_a_get_next_notification() {
      let item = this.$refs.dropdownMenuButtonNotification;
      item.classList.add("show");
      this.$store.dispatch("notification/a_get_next_notification");
    },
    redirect_to_visitor_profile(user_id) {
      this.$router.push({
        name: "homeMyProfile",
        params: { id: user_id },
      });
    },
    ...mapActions({
      a_get_next_notification: "notification/a_get_next_notification",
    }),

    readOne(id) {
      let item1 = this.$refs.dropdownMenuButtonNotification;
      item1.classList.add("show");
      this.$store.commit("notification/MAKE_NOTIFICATION_READ", id);
      notificationServices.MakeOneNotificationRead(id);
    },

    MarkAllNotificationsAsRead() {
      notificationServices.MarkAllAsRead().then((res) => {
        this.$snotify.success(
          this.$i18n.t(res.data.message),
          this.$i18n.t("Notifications")
        );
        this.$store.commit("notification/CHANGE_STATUS_MARK_NOTIFICATION");
      });
    },
    redirect_to_pageNotification() {
      this.$router.push({ name: "notifications" });
    },
  },
};
</script>

<style lang="scss" scoped>
.notify {
  background-color: white;
  width: 40%;
  position: absolute;
  left: 50% !important;
  top: 50px !important;
  transform: translateX(-50%);
  padding: 0;
  overflow: hidden;
  max-height: 80vh;

  &:hover {
    overflow-y: scroll;
  }
  .notify:lang(ar) {
    direction: rtl;
  }
  .notify:lang(ku) {
    direction: rtl;
  }
  .p-2 {
    .show_all {
      text-decoration: none;
      color: #0d988c;
      font-weight: bold;
      font-size: 15px;
    }

    p {
      color: #0d988c;
      font-weight: bold;
    }
  }

  .box_notification {
    margin: 0 !important;
    padding: 0.5rem 0;
    width: 100%;
    .lh-base {
      overflow: hidden;
      text-decoration: none;
      color: black;
    }
    .dot {
      color: #0d988c;
    }

    &:hover {
      background-color: #0d988c;
      .lh-base {
        color: white !important;
      }

      .dot {
        color: white !important;
      }
    }
  }

  .notificationBackground {
    background-color: #983baf3e;
  }
}

@media screen and (max-width: 992px) {
  .notify {
    width: 96% !important;
    top: 110px !important;
    overflow-y: scroll;

    .box_notification {
      margin: 0 !important;
      padding: 0.5rem 1rem;
      width: 100%;
      .lh-base {
        overflow: hidden;
        text-decoration: none;
        color: black;
      }
      .dot {
        color: #0d988c;
      }

      &:hover {
        background-color: #0d988c;
        .lh-base {
          color: white !important;
        }

        .dot {
          color: white !important;
        }
      }
    }
  }
}
.img-notification {
  width: 50px;
  border-radius: 50%;
  height: 50px;
}
p.p-0.mt-0.mb-0.me-3 {
  font-size: 13px;
  font-weight: 500;
}
p.px-3.mt-0.mb-0.me-3 {
  font-size: 13px;
  font-weight: 500;
}
.time {
  color: blue;
}

.dir-lang:lang(ar) {
  float: right;
  direction: rtl;
  word-break: break-all;
}
.dir-lang:lang(ku) {
  float: right;
  direction: rtl;
  word-break: break-all;
}

.button_loadMore {
  margin: 15px;
  color: black;
  background: #f8f8f8;
  border-radius: 17px;
  padding: 8px;
  border: groove;
}
.for-lang:lang(ar) {
  text-align: right !important;
}
.d-flex.row.align-items-center.box_notification:lang(ar) {
  direction: rtl;
}
.d-flex.row.align-items-center.box_notification:lang(ku) {
  direction: rtl;
}

img.position-style {
  position: relative;
  bottom: 20px;
}
</style>@/services/Notification/notificationServices
