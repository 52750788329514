<template>
  <div class="row" v-if="item.collectionable_type == 'Post'">
    <!-- Images and video that belong to  post  -->
    <div class="col-3">
      <!-- <video crossorigin="anonymous"  class="video-style" controls :src="item.model.media.src_url"></video> -->
      <span v-if="item.item.content == null">
        <span v-if="item.item.parent_id == null">
          <video crossorigin="anonymous" 
            v-if="item.item.media[0].media_type == 'Video'"
            class="video-style"
            :src="item.item.media[0].src_url"
          ></video>
          <img crossorigin="anonymous" 
            v-else-if="item.item.media[0].media_type == 'Image'"
            :src="item.item.media[0].src_url"
            class="cover-saved-items"
          />
        </span>
        <span v-if="item.item.parent_id != null">
          <video crossorigin="anonymous" 
            v-if="item.item.parent.media[0].media_type == 'Video'"
            class="video-style"
            :src="item.item.parent.media[0].src_url"
          ></video>
          <img crossorigin="anonymous" 
            v-else-if="item.item.parent.media[0].media_type == 'Image'"
            :src="item.item.parent.media[0].src_url"
            class="cover-saved-items"
          />
        </span>
      </span>
      <span v-if="item.item.content != null">
        <img crossorigin="anonymous" 
          v-if="item.item.model.has_media_profile === false"
          class="cover-saved-items"
          src="@/assets/img_friends/default1.png"
          alt="cover saved item"
        />
        <img crossorigin="anonymous" 
          v-else-if="item.item.model.media[0].collection_name === 'profile'"
          :src="item.item.model.media[0].src_url"
          class="cover-saved-items"
          alt="cover saved item"
        />
        <img crossorigin="anonymous" 
          v-else-if="item.item.model.media[1].collection_name === 'profile'"
          :src="item.item.model.media[1].src_url"
          class="cover-saved-items"
          alt="cover saved item"
        />
      </span>
    </div>
    <div class="col-9">
      <div class="row">
        <span class="content mr-2">{{ item.item.content }}</span>
      </div>

      <div v-if="item.item.content == null" class="txt-style">
        Post from
        <router-link
          class="router-link-to-profile"
          :to="{
            name: 'homeSinglePage',
            params: { id: item.item.id },
          }"
        >
          {{ item.item.model.name }}
        </router-link>
      </div>
      <div class="row m-2">
        Add to collection from :
        <img crossorigin="anonymous" 
          v-if="item.profile.has_media_profile === false"
          class="style-img"
          src="@/assets/img_friends/default1.png"
          alt=""
          style="width: 50px"
        />
        <img crossorigin="anonymous" 
          v-else-if="item.profile.media[0].collection_name === 'profile'"
          :src="item.profile.media[0].src_url"
          class="style-img"
          alt=""
          style="width: 50px"
        />
        <img crossorigin="anonymous" 
          v-else-if="item.profile.media[1].collection_name === 'profile'"
          :src="item.profile.media[1].src_url"
          class="style-img"
          alt=""
          style="width: 50px"
        />
        {{ item.profile.name }}
      </div>
      <div class="row">
        <router-link
          v-if="
            item.item.model_type == 'Profile' && USER.id == item.item.user_id
          "
          class="router-link-to-profile"
          :to="{ name: 'homeMyProfile', params: { id: USER.id } }"
        >
          <img crossorigin="anonymous" 
            class="style-img"
            src="@/assets/img_friends/default1.png"
            alt=""
          />
          {{ $t("Saved from Post") }}
          <b>
            {{ item.item.model.first_name }} {{ item.item.model.last_name }}</b
          ></router-link
        >
        <router-link
          v-else-if="
            item.item.model_type == 'Profile' && USER.id != item.item.user_id
          "
          class="router-link-to-profile"
          :to="{
            name: 'homeMyProfile',
            params: { id: item.item.user_id },
          }"
        >
          <img crossorigin="anonymous" 
            class="style-img"
            src="@/assets/img_friends/default1.png"
            alt=""
          />
          {{ $t("Saved from Post") }}
          <b>
            {{ item.item.model.first_name }} {{ item.item.model.last_name }}</b
          >
        </router-link>
        <router-link
          v-else
          :to="{
            name: 'homeSinglePage',
            params: { id: item.item.model.id },
          }"
          class="router-link-to-profile"
        >
          <img crossorigin="anonymous" 
            class="style-img"
            src="@/assets/img_friends/default1.png"
            alt=""
          />
          {{ $t("Saved from Post") }}
          <b> {{ item.item.model.name }}</b>
          <small style="color: green; font-weight: bold"> page</small>
        </router-link>
      </div>
      <div class="row">
        <div class="col-2" data-bs-toggle="dropdown" aria-expanded="false">
          <div class="style-more">
            <div class="dropstart">
              <a class="dropdown">
                <img crossorigin="anonymous"  class="img-style" src="@/assets/images/more.png" alt="" />
              </a>
              <ul class="dropdown-menu">
                <li
                  v-if="USER.id == item.item.model.id"
                  class="dropdown-item pro guest"
                  @click="cancel_save(item.item.id)"
                >
                  <CancelSave></CancelSave>
                  {{ $t("Cancel Save") }}
                </li>

                <li
                  v-if="
                    info_collection.pivot.role == 2 ||
                    USER.id == item.item.model.id
                  "
                  class="dropdown-item pro guest"
                  @click="remove_from_collection(item.item.id)"
                >
                  <CancelSave></CancelSave>
                  {{ $t("remove from collection") }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        &nbsp;
        <div class="col-6">
          <button
            class="btn btn-secondary btn-sm"
            data-bs-toggle="modal"
            :data-bs-target="'#staticSelectCollection' + item.item.id"
          >
            {{ $t("Add to Collection") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CancelSave from "@/components/OtherComponent/SVG/CancelSave.vue";
import SaveServices from "@/services/HomePage/Save/SaveServices";
import CollectionsServices from "@/services/Collections/CollectionsServices";
import { mapGetters } from "vuex";
export default {
  props: ["item"],
  computed: {
    ...mapGetters({
      USER: "auth/USER",
      info_collection: "collections/info_collection",
    }),
  },
  methods: {
    cancel_save(id) {
      SaveServices.cancel_Save({
        item_id: id,
        item_type: "Post",
      }).then(() => {
        this.$store.commit("collections/CANCEL_SAVE_ITEM_FROM_COLLECTION", id);
      });
    },
    cancel_save_video(id) {
      SaveServices.cancel_Save({
        item_id: id,
        item_type: "Video",
      }).then(() => {
        this.$snotify.success("Cancel Save successfully", "Saved");
      });
    },
    remove_from_collection(id) {
      CollectionsServices.remove_from_collection(
        id,
        this.item.collectionable_type,
        this.item.collection_id
      ).then(() => {
        this.$store.commit("collections/REMOVE_ITEM_FROM_COLLECTION", id);

        this.$snotify.success(
          "The item has been removed from the collection successfully",
          "Saved"
        );
      });
    },
  },
  components: { CancelSave },
};
</script>
<style lang="scss" scoped>
@import "@/Styles/SavedItems/savedItemsBelongsTome.scss";
</style>
