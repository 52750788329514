import axios from "axios";
import { removeAccessToken } from "@/utils/cookieManager";
const ApiNode = axios.create({
  // baseURL: "https://media-nas.net/api",
  // baseURL: "https://api.media-nas.net/api",
  // baseURL: "http://127.0.0.1:8000/api",
  baseURL: "https://api.media-nas.net:3000/api/",
  // timeout: 5000,
});
const Api = axios.create({
  // baseURL: "https://media-nas.net/api",
  // baseURL: "https://api.media-nas.net/api",
  // baseURL: "http://127.0.0.1:8000/api",
  baseURL: process.env.VUE_APP_API_URL,
  // timeout: 5000,
});

Api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isCancel(error)) {
      // Request was canceled
      return Promise.reject(error);
    }
    if (!navigator.onLine) {
      alert("No internet connection");
    }

    if (error.response) {
      if (error.response.status >= 500 && error.response.status <= 599) {
        // alert("Server Error");
      }

      if (error.response.status === 401) {
        removeAccessToken();
        localStorage.removeItem("user");
        window.location.pathname = "/";
      } else if (error.response.status === 404) {
        window.location.pathname = "/not-found-item";
        // console.log("HTTP Error:", error.response.status, error.response.data);
      } else {
        console.log("HTTP Error:", error.response.status, error.response.data);
      }
    } else if (error.request) {
      // Network error
      window.alert(error.message, "Network");
    } else {
      // Other errors
      console.error("Error:", error);
    }

    return Promise.reject(error);
  }
);
export {ApiNode};
export default Api;
// export default { Api, ApiNode };
