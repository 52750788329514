import { apolloClientProfile } from "@/graphql/apollo";
import { PROFILE_INFORMATION } from "@/graphql/profile/profileInformation";
import { PROFILE_INFORMATION_UPDATE } from "@/graphql/profile/profileInformationUpdate";
import ProfileModel from "@/models/users/profile/ProfileModel";
import JobService from "@/services/Profile/JobService";
import ProfileService from "@/services/Profile/ProfileService";
import StudiesService from "@/services/Profile/StudiesService";
export default {
  namespaced: true,
  state: {
    /************************ Start profile posts by profile id  ***************** */
    my_post: [],
    loadingPosts: false,
    currentPage: 1,
    lastPagePosts: null,
    limitMyPosts: 12,
    noPosts: null,
    /********************** End profile posts  **************************************/
    /********************** Start User information *********************************/
    loading_info: null,
    userInformation: [],
    numberVisitors: 0,
    /**********************End User information by profile id **********************/
    /*************************** Start TTop by profile id **************************/
    userTTop: [],
    loading_TTop: false,
    currentPageTTop: 1,
    lastPageTTop: null,
    limitTTop: 12,
    noTTop: null,
    /************************** End TTop by profile id ****************************/
    interactionModel: null,
    loading_photo: null,

    all_archive_story: [],
    loading_archive_story: null,

    posts_taged_friends: [],

    isLoadingNewPost: null,

    /************************ profile Media by profile id  ************************/
    loadingMedia: false,
    images: [],
    videos: [],
    /************************ End profile Media by profile id  *****************/
  },
  getters: {
    // information public details
    userInformation(state) {
      return state.userInformation;
    },
    numberVisitors(state) {
      return state.numberVisitors.visits_count;
    },
    // TODO: CHECK IF FOUND MUTUAL FRIEND IN RESPONSE.
    mutual_friends(state) {
      return state.userInformation.mutual_friends;
    },
    userTTop(state) {
      return state.userTTop;
    },
    noTTop(state) {
      return state.noTTop;
    },
    images(state) {
      return state.images;
    },
    videos(state) {
      return state.videos;
    },
    // check if hometown is found
    hasHometown: (state) => {
      let value =
        state.userInformation &&
        state.userInformation.cities &&
        state.userInformation.cities.some((city) => city.pivot.category === 1);
      return value;
    },
    // check if current city is found
    hasCity: (state) => {
      let value =
        state.userInformation &&
        state.userInformation.cities &&
        state.userInformation.cities.some((city) => city.pivot.category === 0);
      return value;
    },
    noPosts(state) {
      return state.noPosts;
    },
    posts_taged_friends(state) {
      return state.posts_taged_friends;
    },

    loading_photo(state) {
      return state.loading_photo;
    },
    // All my post
    my_post(state) {
      return state.my_post;
    },
    loadingPosts(state) {
      return state.loadingPosts;
    },
    loading_info(state) {
      return state.loading_info;
    },
    allarchivestory(state) {
      return state.all_archive_story;
    },
    loading_archive_story(state) {
      return state.loading_archive_story;
    },
    lastPagePosts(state) {
      return state.lastPagePosts;
    },
    currentPage(state) {
      return state.currentPage;
    },
  },
  mutations: {
    /************** Start Post Poll**********************************/
    //Start vote on answer post review
    DELETE_VOTE_ANSWER_FOR_REVIEW(state, payload) {
      if (state.posts_taged_friends && state.posts_taged_friends.length > 0) {
        const postIndex = state.posts_taged_friends.findIndex(
          (item) => item.id == payload.post_id
        );
        if (postIndex !== -1) {
          const poll = state.posts_taged_friends[postIndex].poll;
          const answerIndex = poll.answers.findIndex(
            (answer) => answer.id == payload.answer_id
          );
          if (answerIndex !== -1) {
            poll.answers.splice(answerIndex, 1);
          }
        }
      }
    },

    CHANGE_VOTE_ANSWER_FOR_REVIEW(state, payload) {
      if (state.posts_taged_friends && state.posts_taged_friends.length > 0) {
        const index = state.posts_taged_friends.find(
          (item) => item.id == payload.post_id
        );
        if (index) {
          index.poll = payload.poll;
        }
      }
    },

    //End vote on answer post review
    //Start vote on answer
    DELETE_VOTE_ANSWER(state, payload) {
      if (state.my_post && state.my_post.length > 0) {
        const postIndex = state.my_post.findIndex(
          (item) => item.id == payload.post_id
        );
        if (postIndex !== -1) {
          const poll = state.my_post[postIndex].poll;
          const answerIndex = poll.answers.findIndex(
            (answer) => answer.id == payload.answer_id
          );
          if (answerIndex !== -1) {
            poll.answers.splice(answerIndex, 1);
          }
        }
      }
    },

    CHANGE_VOTE_ANSWER(state, payload) {
      if (state.my_post && state.my_post.length > 0) {
        const index = state.my_post.find((item) => item.id == payload.post_id);
        if (index) {
          index.poll = payload.poll;
        }
      }
    },
    UPDATE_POST_POLL_PROFILE(state, payload) {
      if (state.my_post && state.my_post.length > 0) {
        const index = state.my_post.find((item) => item.id == payload.post_id);
        if (index) {
          index.poll.body = payload.body;
        }
      }
    },
    // this post is saved
    UPDATE_STATUS_TO_CANCEL_SAVE_POST(state, payload) {
      const p = state.my_post.find((item) => item.id == payload);
      p.saved = true;
    },
    // this post isn't saved
    UPDATE_STATUS_TO_SAVE_POST(state, payload) {
      const p = state.my_post.find((item) => item.id == payload);
      p.saved = false;
    },
    // End vote on answer
    /************** End Post Poll **********************************/
    // remove from list my_post
    REMOVE_ITEM_USER_PROFILE(state, id) {
      const index = state.my_post.findIndex((item) => item.id == id);
      state.my_post.splice(index, 1);
    },

    //******* in about Page , manage Location ( Hometown and current city )*********
    // create and update hometown
    editHometown(state, updatedData) {
      const hometownIndex = state.userInformation.cities.findIndex(
        (ho) => ho.pivot.category === 1
      );

      if (hometownIndex !== -1) {
        // if you found the city with category == 1   in the cities object،  do update
        state.userInformation.cities[hometownIndex] = updatedData;

        apolloClientProfile.writeQuery({
          query: PROFILE_INFORMATION,
          variables: {
            id: state.userInformation.id,
          },
          data: {
            ProfileQuery: {
              ...state.userInformation.cities,
              cities: state.userInformation.cities.map((city) => {
                if (city.pivot.category === 1) {
                  return updatedData;
                }
                return city;
              }),
            },
          },
        });
      } else {
        // if you do not found the city with category == 1 in the cities  do add
        const newHometown = updatedData;
        if (newHometown) {
          state.userInformation.cities.push(newHometown);
          apolloClientProfile.writeQuery({
            query: PROFILE_INFORMATION,
            variables: {
              id: state.userInformation.id,
            },
            data: {
              ProfileQuery: {
                ...state.userInformation.cities,
                cities: [...state.userInformation.cities, updatedData],
              },
            },
          });
        }
      }
    },
    // delete hometown
    deleteHometown(state, cityId) {
      const indexToRemove = state.userInformation.cities.findIndex(
        (city) => city.pivot.category === 1 && city.id === cityId
      );
      if (indexToRemove !== -1) {
        state.userInformation.cities.splice(indexToRemove, 1);

        apolloClientProfile.writeQuery({
          query: PROFILE_INFORMATION,
          variables: {
            id: state.userInformation.id,
          },
          data: {
            ProfileQuery: {
              ...state.userInformation.cities,
              cities: state.userInformation.cities.filter(
                (city) => !(city.pivot.category === 1 && city.id === cityId)
              ),
            },
          },
        });
      }
    },
    // create and update Current City
    editCity(state, updatedData) {
      const currentCityIndex = state.userInformation.cities.findIndex(
        (ho) => ho.pivot.category === 0
      );

      if (currentCityIndex !== -1) {
        // if you found the city with category == 0   in the cities object،  do update
        state.userInformation.cities[currentCityIndex] = updatedData;

        apolloClientProfile.writeQuery({
          query: PROFILE_INFORMATION,
          variables: {
            id: state.userInformation.id,
          },
          data: {
            ProfileQuery: {
              ...state.userInformation.cities,
              cities: state.userInformation.cities.map((city) => {
                if (city.pivot.category === 0) {
                  return updatedData;
                }
                return city;
              }),
            },
          },
        });
      } else {
        // if you do not found the city with category == 0 in the cities  do add
        const newCurrentCity = updatedData;
        if (newCurrentCity) {
          state.userInformation.cities.push(newCurrentCity);

          apolloClientProfile.writeQuery({
            query: PROFILE_INFORMATION,
            variables: {
              id: state.userInformation.id,
            },
            data: {
              ProfileQuery: {
                ...state.userInformation.cities,
                cities: [...state.userInformation.cities, updatedData],
              },
            },
          });
        }
      }
    },
    // Delete current city
    deleteCity(state, cityId) {
      const indexToRemove = state.userInformation.cities.findIndex(
        (city) => city.pivot.category === 0 && city.id === cityId
      );
      if (indexToRemove !== -1) {
        state.userInformation.cities.splice(indexToRemove, 1);

        apolloClientProfile.writeQuery({
          query: PROFILE_INFORMATION,
          variables: {
            id: state.userInformation.id,
          },
          data: {
            ProfileQuery: {
              ...state.userInformation.cities,
              cities: state.userInformation.cities.filter(
                (city) => !(city.pivot.category === 0 && city.id === cityId)
              ),
            },
          },
        });
      }
    },
    //******* End Location (Hometown and Current city)**************************

    // ***********  in My profile Manage media value ****************************
    // update cover in my profile
    UPDATE_PROFILE_COVER(state, payload) {
      state.userInformation.has_media_cover = true;

      const coverIndex = state.userInformation.media.findIndex(
        (mediaItem) => mediaItem.collection_name === "cover"
      );

      if (coverIndex !== -1) {
        // if you found the cover  in the media object،  do update
        state.userInformation.media[coverIndex] = payload.profile.media.find(
          (mediaItem) => mediaItem.collection_name === "cover"
        );
      } else {
        // if you do not found the cover in the media،  do add
        const newCover = payload.profile.media.find(
          (mediaItem) => mediaItem.collection_name === "cover"
        );
        if (newCover) {
          state.userInformation.media.push(newCover);
        }
      }

      if (coverIndex !== -1) {
        // If the cover is found in the cache, update the cache
        apolloClientProfile.writeQuery({
          query: PROFILE_INFORMATION_UPDATE,
          variables: {
            id: state.userInformation.id,
          },
          data: {
            ProfileQuery: {
              ProfileQuery: {
                ...state.userInformation.media,
                media: state.userInformation.media.filter(
                  (mediaItem) => mediaItem.collection_name == "cover"
                ),
                has_media_cover: true,
              },
            },
          },
        });
      }
      // Add th the cover to the cache if it does not already exist
      else {
        apolloClientProfile.writeQuery({
          query: PROFILE_INFORMATION_UPDATE,
          variables: {
            id: state.userInformation.id,
          },
          data: {
            ProfileQuery: {
              ...state.userInformation.media,
              media: [
                ...state.userInformation.media,
                payload.profile.media.find(
                  (mediaItem) => mediaItem.collection_name === "cover"
                ),
              ],
            },
          },
        });
      }
    },
    // delete cover from my profile
    DELETE_PROFILE_COVER(state) {
      state.userInformation.media.forEach((mediaItem) => {
        if (state.userInformation.has_media_cover == true) {
          if (mediaItem.collection_name == "cover") {
            state.userInformation.has_media_cover = false;
            const indexToRemove = state.userInformation.media.findIndex(
              (item) => item.collection_name == "cover"
            );

            if (indexToRemove !== -1) {
              state.userInformation.media.splice(indexToRemove, 1);

              apolloClientProfile.writeQuery({
                query: PROFILE_INFORMATION_UPDATE,
                variables: {
                  id: state.userInformation.id,
                },
                data: {
                  ProfileQuery: {
                    media: state.userInformation.media,
                    has_media_cover: false,
                    has_media_profile: true,
                  },
                },
              });
            }
          }
        }
      });
    },
    // update photo in my profile src_url
    UPDATE_PROFILE_PHOTO_src_url(state, payload) {
      state.userInformation.has_media_profile = true;
      let profileMediaItem = state.userInformation.media.find(
        (mediaItem) => mediaItem.collection_name === "profile"
      );

      if (!profileMediaItem) {
        profileMediaItem = {
          collection_name: "profile",
          src_url: `${payload}&timestamp=${new Date().getTime()}`,
        };
        state.userInformation.media.push(profileMediaItem);
      } else {
        profileMediaItem.src_url = `${payload}&timestamp=${new Date().getTime()}`;
      }

      apolloClientProfile.writeQuery({
        query: PROFILE_INFORMATION_UPDATE,
        variables: {
          id: state.userInformation.id,
        },
        data: {
          ProfileQuery: {
            ...state.userInformation,
            media: state.userInformation.media.length
              ? state.userInformation.media.map((mediaItem) => ({
                  ...mediaItem,
                }))
              : [],
          },
        },
      });
    },
    // update photo in my profile src_thum
    UPDATE_PROFILE_PHOTO_src_thum(state, payload) {
      state.userInformation.has_media_profile = true;
      state.userInformation.media.forEach((mediaItem) => {
        if (mediaItem.collection_name == "profile") {
          mediaItem.src_thum = `${payload}&timestamp=${new Date().getTime()}`;
        }
      });

      apolloClientProfile.writeQuery({
        query: PROFILE_INFORMATION_UPDATE,
        variables: {
          id: state.userInformation.id,
        },
        data: {
          ProfileQuery: {
            ...state.userInformation,
            media: state.userInformation.media.length
              ? state.userInformation.media.map((mediaItem) => ({
                  ...mediaItem,
                }))
              : [],
          },
        },
      });
    },
    // delete my photo from profile
    DELETE_PROFILE_PHOTO(state) {
      state.userInformation.media.forEach((mediaItem) => {
        if (state.userInformation.has_media_profile == true) {
          if (mediaItem.collection_name == "profile") {
            state.userInformation.has_media_profile = false;
            const indexToRemove = state.userInformation.media.findIndex(
              (item) => item.collection_name == "profile"
            );

            if (indexToRemove !== -1) {
              state.userInformation.media.splice(indexToRemove, 1);

              apolloClientProfile.writeQuery({
                query: PROFILE_INFORMATION_UPDATE,
                variables: {
                  id: state.userInformation.id,
                },
                data: {
                  ProfileQuery: {
                    ...state.userInformation,
                    media: state.userInformation.media.length
                      ? state.userInformation.media.map((mediaItem) => ({
                          ...mediaItem,
                        }))
                      : [],
                    has_media_cover: false,
                    has_media_profile: false,
                  },
                },
              });
            }
          }
        }
      });
    },
    //************************ End Update value media ***********************************/

    //************************ Start "Jobs" From User Information ************************/
    // add new job
    ADD_JOB(state, job) {
      state.userInformation.jobs.unshift(job);
      apolloClientProfile.writeQuery({
        query: PROFILE_INFORMATION,
        variables: {
          id: state.userInformation.id,
        },
        data: {
          ProfileQuery: {
            ...state.userInformation.jobs,
            jobs: state.userInformation.jobs,
          },
        },
      });
    },
    // delete job
    DELETE_JOB(state, id) {
      const index = state.userInformation.jobs.findIndex(
        (item) => item.id === id
      );

      if (index !== -1) {
        state.userInformation.jobs.splice(index, 1);

        apolloClientProfile.writeQuery({
          query: PROFILE_INFORMATION,
          variables: {
            id: state.userInformation.id,
          },
          data: {
            ProfileQuery: {
              ...state.userInformation.jobs,
              jobs: state.userInformation.jobs,
            },
          },
        });
      }
    },

    // update job
    UPDATE_JOB(state, payload) {
      state.userInformation.jobs = state.userInformation.jobs.map((job) => {
        if (job.id === payload.id) {
          return { ...job, ...payload };
        }
        return job;
      });

      apolloClientProfile.writeQuery({
        query: PROFILE_INFORMATION,
        variables: {
          id: state.userInformation.id,
        },
        data: {
          ProfileQuery: {
            ...state.userInformation.jobs,
            jobs: state.userInformation.jobs,
          },
        },
      });
    },
    //************************ End Jobs and "Jobs" From User Information *****************/

    /************************* Start "Studies" From User Information ************************/
    // add new study
    ADD_STUDY(state, study) {
      state.userInformation.studies.unshift(study);
      apolloClientProfile.writeQuery({
        query: PROFILE_INFORMATION,
        variables: {
          id: state.userInformation.id,
        },
        data: {
          ProfileQuery: {
            ...state.userInformation.studies,
            studies: state.userInformation.studies,
          },
        },
      });
    },
    // delete study
    DELETE_STUDY(state, id) {
      const index = state.userInformation.studies.findIndex(
        (item) => item.id == id
      );

      if (index !== -1) {
        state.userInformation.studies.splice(index, 1);

        apolloClientProfile.writeQuery({
          query: PROFILE_INFORMATION,
          variables: {
            id: state.userInformation.id,
          },
          data: {
            ProfileQuery: {
              ...state.userInformation.studies,
              studies: state.userInformation.studies,
            },
          },
        });
      }
    },
    // update study
    UPDATE_STUDY(state, payload) {
      const studyIndex = state.userInformation.studies.findIndex(
        (study) => study.id === payload.id
      );

      if (studyIndex !== -1) {
        state.userInformation.studies[studyIndex] = {
          ...state.userInformation.studies[studyIndex],
          ...payload,
        };
      }

      apolloClientProfile.writeQuery({
        query: PROFILE_INFORMATION,
        variables: {
          id: state.userInformation.id,
        },
        data: {
          ProfileQuery: {
            ...state.userInformation.studies,
            studies: state.userInformation.studies,
          },
        },
      });
    },
    /************************* End "Studies" From User Information ***********************/

    /************************* Start in My Profile "public details" From User Information ******/
    // update public details value from my profile
    UPDATE_PROFILE_DETAILS(state, payload) {
      const { id, key_ar, key_en, details } = payload;
      state.userInformation.publicdetail =
        state.userInformation.publicdetail.map((profile) => {
          if (profile.details.publicdetail_id === id) {
            return {
              ...profile,
              id,
              key_ar,
              key_en,
              details: {
                ...profile.details,
                ...details,
              },
            };
          }
          return profile;
        });

      apolloClientProfile.writeQuery({
        query: PROFILE_INFORMATION,
        variables: {
          id: state.userInformation.id,
        },
        data: {
          ProfileQuery: {
            ...state.userInformation.publicdetail,
            publicdetail: state.userInformation.publicdetail,
          },
        },
      });
    },
    // delete public details  from my profile
    DELETE_PROFILE_DETAILS(state, payload) {
      const { id, key_ar, key_en, details } = payload;
      state.userInformation.publicdetail =
        state.userInformation.publicdetail.map((profile) => {
          if (profile.details.publicdetail_id === id) {
            return {
              ...profile,
              id,
              key_ar,
              key_en,
              details: {
                ...details,
                value: null,
              },
            };
          }
          return profile;
        });

      apolloClientProfile.writeQuery({
        query: PROFILE_INFORMATION,
        variables: {
          id: state.userInformation.id,
        },
        data: {
          ProfileQuery: {
            ...state.userInformation.publicdetail,
            publicdetail: state.userInformation.publicdetail,
          },
        },
      });
    },
    /************************* End in My Profile "public details" From User Information ******/

    /************************* Start general userInformation *********************************/
    destroyedProfile(state) {
      state.userInformation = [];
      state.my_post = [];
    },
    // userInformation
    SET_USER_INFO(state, payload) {
      state.userInformation = payload;
    },
    // set number visitors
    SET_NUMBER_VISITORS(state, payload) {
      const newNumberVisitors = Object.assign({}, state.numberVisitors);

      Object.keys(payload).forEach((key) => {
        newNumberVisitors[key] = payload[key];
      });

      state.numberVisitors = newNumberVisitors;
    },
    // return number visitors to  0
    Empty_Number_Visitors(state) {
      state.numberVisitors = Object.assign({}, state.numberVisitors, {
        visits_count: 0,
      });
    },
    SET_TTop_BY_PROFILE(state, payload) {
      state.userTTop = payload;
    },
    CHANGE_LOCK_PROFILE_STATUS(state) {
      state.userInformation.isLocked = !state.userInformation.isLocked;
    },
    CHANGE_STATUS_BIRTHDAY_CONGRATULATION(state, status) {
      state.userInformation.post_congratulations = status;
    },
    CHANGE_STATUS_POSTING_ON_MY_PROFILE(state, status) {
      state.userInformation.posting_on_profile = status;
    },
    /************************* End general userInformation *********************************/
    UPDATE_IS_LOADING_NEW_POST(state) {
      state.isLoadingNewPost = !state.isLoadingNewPost;
    },
    SET_NEW_POST_DATA(state, payload) {
      state.my_post.unshift(payload);
    },
    SET_posts_taged_friends(state, payload) {
      state.posts_taged_friends = payload;
    },
    // Increase the number of comments by one each time a new comment is added
    Increase_COUNT_COMMENT(state, payload) {
      const comment_index = state.my_post.find(
        (item) => item.id == payload.commentable_id
      );
      comment_index.comments_count++;
    },
    // Increase the number of comments by one each time a new comment is added
    Increase_COUNT_COMMENT_in_Review_Post(state, payload) {
      const comment_index = state.posts_taged_friends.find(
        (item) => item.id == payload.commentable_id
      );
      comment_index.comments_count++;
    },
    // Decrease the number of comments by one each time a new comment is added
    Decrease_COUNT_COMMENT(state, payload) {
      const comment_index = state.my_post.find(
        (item) => item.id == payload.commentable_id
      );
      comment_index.comments_count--;
    },
    // Decrease the number of comments by one each time a new comment is deleted
    Decrease_COUNT_COMMENT_in_Review_Post(state, payload) {
      const comment_index = state.posts_taged_friends.find(
        (item) => item.id == payload.commentable_id
      );
      comment_index.comments_count--;
    },
    SET_MY_POST(state, post) {
      state.my_post = post;
    },
    PUSH_NEW_POST(state, payload) {
      state.my_post.unshift(payload);
    },
    SET_POST_DATA(state, payload) {
      state.my_post.push(payload);
    },

    REMOVE_ITEM(state, id) {
      const index = state.my_post.findIndex((item) => item.id == id);
      state.my_post.splice(index, 1);
    },
    DELETE_POST(state, id) {
      const index = state.my_post.findIndex((item) => item.id == id);
      state.my_post.splice(index, 1);
    },
    DELETE_REVIEW_POST(state, id) {
      const index = state.posts_taged_friends.findIndex(
        (item) => item.id == id
      );
      state.posts_taged_friends.splice(index, 1);
    },
    UPDATE_IS_MODAL_INTERACTION(state) {
      state.interactionModel = !state.interactionModel;
    },

    UPDATE_POST_TO_LIKE(state, payload) {
      state.my_post.forEach((post) => {
        if (post.id === payload[0]) {
          if (payload[1] != null) {
            if (post.interacted != null) {
              post.interacted = payload[1].type;
            } else {
              post.interacted = payload[1].type;
              post.interactions_count++;
            }
          } else {
            post.interacted = null;
            post.interactions_count--;
          }
        }
      });
    },
    UPDATE_REVIEW_POST_TO_LIKE(state, payload) {
      state.posts_taged_friends.forEach((post) => {
        if (post.id === payload[0]) {
          if (payload[1] != null) {
            if (post.interacted.length > 0) {
              post.interacted[0].type = payload[1].type;
            } else {
              post.interacted.push(payload[1]);
              post.interactions_count++;
            }
          } else {
            post.interacted = [];
            post.interactions_count--;
          }
        }
      });
    },

    EditePost(state, payload) {
      let x = state.my_post.findIndex((item) => item.id == payload.id);
      state.my_post[x] = payload;
    },
  },
  /****************************             Actions             ************** */
  actions: {
    /************************ Start userInformation ***************************/
    // get user-information by user id
    async a_get_user_info({ state, commit }, id) {
      state.userInformation = [];
      state.my_post = [];
      state.userInformation = state.loading_info = true;
      state.loading_photo = true;
      await ProfileService.Get_user_information_by_profile_id(id).then(
        (res) => {
          // console.log(res.data.ProfileQuery);
          commit("SET_NUMBER_VISITORS", res.data.NumberUnClickedVisitsQuery);
          const data = res.data.ProfileQuery;
          const ProfileData = new ProfileModel(data);
          commit("SET_USER_INFO", ProfileData);
          state.loading_info = false;
          state.loading_photo = null;
        }
      );
    },
    // Toggle Profile lock
    async a_toggle_lock({ commit }) {
      await ProfileService.Toggle_Lock_Profile().then(() => {
        commit("CHANGE_LOCK_PROFILE_STATUS");
      });
    },
    // delete job
    async a_delete_job({ commit }, id) {
      await JobService.Delete_job(id).then(() => {
        commit("DELETE_JOB", id);
      });
    },
    // delete study
    async a_delete_study({ commit }, id) {
      await StudiesService.Delete_study(id).then(() => {
        commit("DELETE_STUDY", id);
      });
    },
    /********************** End userInformation ***********************************/

    /********************** Start Posts by profile id ****************************/
    // get posts by profile id
    async getProfilePost({ state }, id) {
      // console.log('from store',typeof(id))
      // if (state.my_post.length > 0) return state.my_post;
      // else {
      state.loadingPosts = true;
      state.my_post = [];
      state.currentPage = 1;
      ProfileService.s_posts_by_profile_id(
        id,
        state.currentPage,
        state.limitMyPosts
      ).then((response) => {
        state.lastPagePosts = response.data.PostByProfileIDQuery.last_page;
        state.my_post = response.data.PostByProfileIDQuery.items;
        state.loadingPosts = false;
      });
      // }
    },
    //  get all posts by profile id for pagination
    async loadMoreProfilePosts({ state }, id) {
      state.currentPage += 1;
      if (state.currentPage <= state.lastPagePosts) {
        await ProfileService.s_posts_by_profile_id(
          id,
          state.currentPage,
          state.limitMyPosts
        ).then((response) => {
          response.data.PostByProfileIDQuery.items.forEach((item) => {
            state.my_post.push(item);
          });
        });
      } else {
        state.noPosts = "There are no post yet";
      }
    },
    /*********************** End posts by profile id *****************************/

    /*********************** Start profile Media *****************************/
    async getAllMedia({ state }, payload) {
      state.loadingMedia = true;
      if (payload.media_type === "Image") {
        await ProfileService.allProfileMedia(
          payload.profile_id,
          payload.media_type
        ).then((res) => {
          state.images = res.data.ProfileMediaQuery.items;
          state.loadingMedia = false;
        });
      } else if (payload.media_type === "Video") {
        await ProfileService.allProfileMedia(
          payload.profile_id,
          payload.media_type
        ).then((res) => {
          state.videos = res.data.ProfileMediaQuery.items;
          state.loadingMedia = false;
        });
      }
    },
    /*********************** End profile Media *****************************/
    //
    async UPDATE_POST_TO_LIKE({ commit }, data) {
      await commit("UPDATE_POST_TO_LIKE", data);
    },
    async UPDATE_REVIEW_POST_TO_LIKE({ commit }, data) {
      await commit("UPDATE_REVIEW_POST_TO_LIKE", data);
    },
    // get posts_taged_friends
    async get_posts_taged_friends({ commit, state }) {
      if (state.posts_taged_friends.length > 0)
        return state.posts_taged_friends;
      else {
        state.loading_info = true;
        ProfileService.s_get_post_for_review().then((res) => {
          commit(
            "SET_posts_taged_friends",
            res.data.PostesForReviewQuery.items
          );
          state.loading_info = null;
        });
      }
    },
    // Get Archive Story
    async GET_ARCHIVE_STORY({ state }) {
      if (state.all_archive_story.length > 0) return state.all_archive_story;
      else {
        state.loading_archive_story = true;
        await ProfileService.Get_archive_story().then((res) => {
          // console.log(res.data.data.items)
          state.all_archive_story = res.data.data.items;
          state.loading_archive_story = null;
        });
      }
    },
    /************************** Start TTop By Profile id ******************************/
    // T_TOP BY profile id
    async TTopByProfileId({ state, commit }, id, currentPageTTop, limitTTop) {
      state.currentPageTTop = 1;
      await ProfileService.TTopByProfileId(id, currentPageTTop, limitTTop).then(
        (res) => {
          state.lastPageTTop = res.data.ReelsByProfileIDQuery.last_page;
          commit("SET_TTop_BY_PROFILE", res.data.ReelsByProfileIDQuery.items);
        }
      );
    },
    //  get all TTops by profile id for pagination
    async loadMoreProfileTTop({ state }, id) {
      state.currentPageTTop += 1;
      if (state.currentPageTTop <= state.lastPageTTop) {
        await ProfileService.TTopByProfileId(
          id,
          state.currentPageTTop,
          state.limitTTop
        ).then((response) => {
          response.data.ReelsByProfileIDQuery.items.forEach((item) => {
            state.userTTop.push(item);
          });
        });
      } else {
        state.noTTop = "There are no TTop yet";
      }
    },
    /************************** End TTop By Profile id ******************************/
  },
};
