<template>
    <div class="modal fade" data-bs-backdrop="static" id="staticBackdropCommentInteractionReels" tabindex="-1" aria-labelledby="CommentstaticBackdropViewsLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="CommentstaticBackdropViewsLabel">{{ $t('interactions') }}</h5>
                    <div><button type="button" class="btn-close" data-bs-target="#reelscomments" data-bs-toggle="modal" data-bs-dismiss="modal" aria-label="Close"></button></div>
                </div>
                <div v-if="!status"  class="modal-body">
                    
                    <div v-if="interactionitems.length > 0">
                        <div v-for="(item, index) in interactionitems" class="d-flex justify-content-start align-items-center" :key="index">
                            <h5 class="mb-3">{{ item.profile.name }}</h5>
                            <p class="ms-1 me-1">
                                <span v-if="item.type == 7">
                                    <img crossorigin="anonymous" 
                                        class=""
                                        src="@/assets/reactions/like.gif"
                                        alt="Like emoji"
                                        height="22"
                                    />
                                </span>
                                <span v-else-if="item.type == 6" >
                                    
                                    <img crossorigin="anonymous" 
                                        class=""
                                        src="@/assets/reactions/angry.gif"
                                        alt="Like emoji"
                                        height="22"
                                    />
                                </span>
                                <span v-else-if="item.type == 2" >
                                    
                                    <img crossorigin="anonymous" 
                                        class=""
                                        src="@/assets/reactions/care.gif"
                                        alt="Like emoji"
                                        height="22"
                                    />
                                </span>
                                <span v-else-if="item.type == 3" >
                                    
                                    <img crossorigin="anonymous" 
                                        class=""
                                        src="@/assets/reactions/haha.gif"
                                        alt="Like emoji"
                                        height="22"
                                    />
                                </span>
                                <span v-else-if="item.type == 1" >
                                    
                                    <img crossorigin="anonymous" 
                                        class=""
                                        src="@/assets/reactions/love.gif"
                                        alt="Like emoji"
                                        height="22"
                                    />
                                </span>
                                <span v-else-if="item.type == 5" >
                                    
                                    <img crossorigin="anonymous" 
                                        class=""
                                        src="@/assets/reactions/sad.gif"
                                        alt="Like emoji"
                                        height="22"
                                    />
                                </span>
                                <span v-else-if="item.type == 4">
                                    
                                    <img crossorigin="anonymous" 
                                        class=""
                                        src="@/assets/reactions/wow.gif"
                                        alt="Like emoji"
                                        height="22"
                                    />
                                </span>
                            </p>
                        </div>
                    </div>
                    <div v-else>
                        <h5 class="text-center">{{ $t('nointeractions') }}</h5>
                    </div>
                </div>
                <div v-else  class="modal-body">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="spinner-grow text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CommentInteractionComponent",
    props: ["interactionitems", "status"]
}
</script>

<style scoped lang="scss">
.modal-content {
    z-index: 99999999;
    border-radius: 25px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.modal-header:lang(ar) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    button {
        text-align: left !important;
    }
}

.modal-body:lang(ar) {
    h5 {
        text-align: right !important;
    }
}
</style>