<template>
    <div class="card">
        <div class="card-body">
            <div class="media align-items-center">
                <span class="avatar avatar-xl mr-3">
                    <router-link class="router-link-to-path" :to="{
                        name: 'homeMyProfile',
                        params: { id: EncryptionRoute(account.id) },
                    }">
                        <span v-if="account.has_media_profile === false">
                            <img crossorigin="anonymous"  v-if="account.gender == 0" class="card-img-top"
                                src="@/assets/img_friends/avatar_male.jpg" loading="lazy" />
                            <img crossorigin="anonymous"  v-if="account.gender == 1" class="card-img-top"
                                src="@/assets/img_friends/avatar_female.jpg" loading="lazy" />
                        </span>
                        <img crossorigin="anonymous"  v-else-if="
                            account.has_media_profile === true &&
                            account.media[0].collection_name === 'profile'
                        " class="card-img-top" :src="account.media[0].src_url" loading="lazy" />
                        <img crossorigin="anonymous"  v-else-if="
                            account.has_media_profile === true &&
                            account.media[1].collection_name === 'profile'
                        " class="card-img-top" :src="account.media[1].src_url" loading="lazy" />
                    </router-link>
                </span>
                <div class="media-body overflow-hidden" dir="auto">
                    <router-link class="router-link-to-path" :to="{
                        name: 'homeMyProfile',
                        params: { id: EncryptionRoute(account.id) },
                    }">
                        <h6 class="card-text custom_lo">{{ account.name }}</h6>
                    </router-link>
                    <span class="card-text custom_date text-muted">{{ this.account.mutualfriends_count }} {{ $t("mutual friends") }}
                    </span>
                </div>
            </div>
            <div class="card-footer">
                <button class="btn-primary" @click="add_friend()">
                    {{ $t("Add Friend") }}
                </button>
                <br />
            </div>
        </div>
    </div>
</template>
<script>
import { EncryptionRoute } from "@/utils/EncryptionRoute"

export default {
    props: ["account"],
    data() {
        return {};
    },
    created() {
        // this.scrollToTop();
    },
    computed: {},

    methods: {
        EncryptionRoute,
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        async add_friend() {
            this.$store.dispatch("friendships/a_add_friend_suggested", this.account.id);
            this.$emit('removefromfriendssuggested', this.account.id)
        },
    },
};
</script>
<style lang="scss" scoped>
@import "@/Styles/Friendships/AccountUser.scss";
</style>