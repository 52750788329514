<template>
  <svg
    width="45"
    height="20"
    viewBox="0 0 256 256"
    xmlns="http://www.w3.org/2000/svg"
    fill="#000000"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0" />

    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    />

    <g id="SVGRepo_iconCarrier">
      <path
        d="m40.59 49.24c-1.28 0-33.08 24.32-33.08 76 0 53.23 30.89 81.28 32.64 81.49s16.43-13.58 16.43-14.68-26.95-20.59-26.95-65.28 26.73-61.33 26.73-62.85c0-.92-12.26-14.68-15.77-14.68z"
        fill="#191919"
      />

      <path
        d="m40.29 214.77a9.09 9.09 0 0 1 -1.14-.07c-1.64-.2-4.12-.51-10.79-7.93a101.48 101.48 0 0 1 -12.11-16.87c-7.63-13.17-16.74-35.07-16.74-64.64 0-28.86 9.43-49.51 17.34-61.74a89.8 89.8 0 0 1 12.44-15.39c7-6.88 9.5-6.88 11.3-6.88 3.49 0 7.2 1.57 14.9 9.4 8.87 9 8.87 10.89 8.87 13.28 0 3.72-2.18 5.75-4.29 7.71-5.55 5.17-22.44 20.89-22.44 55.16 0 23.42 7.81 43 23.21 58.11 1.75 1.72 3.74 3.67 3.74 7.18 0 2.89 0 4.81-12.22 15.59-5.72 5.04-8.69 7.09-12.07 7.09zm.84-155.77c-7.46 7.05-25.62 28.17-25.62 66.26 0 39.53 17.7 63 25.27 71.28 1.54-1.33 3.28-2.92 4.85-4.4-15.92-17.6-24-39.56-24-65.35 0-25.52 8.06-46.81 24-63.36-1.48-1.54-3.1-3.16-4.5-4.43z"
        fill="#191919"
      />

      <path
        d="m74.77 78.6c-1.55 0-21.69 12.71-22.13 46.88-.45 35.05 19.72 52.36 21 52.58s14.24-13.36 14.24-14.46-15.48-11.84-14.68-35.49c.88-25.85 14.9-34.61 14.9-35.71s-11.36-13.8-13.33-13.8z"
        fill="#191919"
      />

      <path
        d="m73.73 186.06a8.38 8.38 0 0 1 -1.38-.12c-7.35-1.22-16.66-16.9-16.75-17.05-5.14-8.72-11.22-23.36-11-43.52.26-19.94 6.73-33.19 12.12-40.78 3.43-4.84 11.62-14 18-14 3.75 0 6.73 2 13.27 9 7.33 7.85 8.1 9.92 8.1 12.77 0 3.51-2.09 5.59-3.47 7-3 3-10.82 10.79-11.43 29a36.5 36.5 0 0 0 11.21 28.19c1.56 1.61 3.51 3.61 3.51 7s-1.46 5.38-6.84 11.14a116.63 116.63 0 0 1 -8.33 8.18c-1.34 1.19-3.74 3.19-7.01 3.19zm.68-97.53c-4.83 4.5-13.5 15.57-13.77 37-.28 21.97 8.26 35.61 13.36 41.79 1.12-1.12 2.3-2.35 3.38-3.5a51.81 51.81 0 0 1 -12.14-36c .64-18.82 7.56-30 12.45-35.83-1.06-1.16-2.22-2.39-3.28-3.46z"
        fill="#191919"
      />

      <path
        d="m215.41 49.24c1.28 0 33.08 24.32 33.08 76 0 53.23-30.89 81.28-32.64 81.49s-16.43-13.58-16.43-14.68 26.95-20.59 26.95-65.28-26.73-61.36-26.73-62.87c0-.9 12.26-14.66 15.77-14.66z"
        fill="#191919"
      />

      <path
        d="m215.7 214.77c-3.38 0-6.34-2-12.06-7.1-12.22-10.79-12.22-12.7-12.22-15.59 0-3.5 2-5.45 3.74-7.18 15.4-15.14 23.21-34.69 23.21-58.11 0-34.27-16.89-50-22.44-55.16-2.11-2-4.29-4-4.29-7.71 0-2.39 0-4.27 8.87-13.28 7.71-7.83 11.41-9.4 14.9-9.4 1.79 0 4.25 0 11.3 6.88a89.8 89.8 0 0 1 12.44 15.39c7.91 12.24 17.34 32.88 17.34 61.74 0 29.57-9.1 51.47-16.74 64.64a101.48 101.48 0 0 1 -12.11 16.86c-6.67 7.41-9.15 7.72-10.8 7.93a9 9 0 0 1 -1.14.09zm-5.33-22.62c1.56 1.48 3.31 3.07 4.85 4.4 7.57-8.23 25.27-31.75 25.27-71.28 0-38.09-18.16-59.21-25.62-66.26-1.4 1.27-3 2.89-4.48 4.43 15.91 16.55 24 37.84 24 63.36-.03 25.78-8.09 47.74-24.02 65.34z"
        fill="#191919"
      />

      <path
        d="m181.23 78.6c1.55 0 21.69 12.71 22.13 46.88.45 35.05-19.72 52.36-21 52.58s-14.24-13.36-14.24-14.46 15.48-11.84 14.68-35.49c-.88-25.85-14.9-34.61-14.9-35.71s11.36-13.8 13.33-13.8z"
        fill="#191919"
      />

      <path
        d="m182.27 186.06c-3.3 0-5.66-2-7-3.15a116.63 116.63 0 0 1 -8.33-8.18c-5.38-5.77-6.84-7.8-6.84-11.14s1.95-5.44 3.51-7a36.5 36.5 0 0 0 11.17-28.17c-.62-18.22-8.47-26.06-11.43-29-1.38-1.38-3.47-3.46-3.47-7 0-2.85.77-4.92 8.1-12.77 6.54-7 9.52-9 13.27-9 6.39 0 14.57 9.16 18 14 5.39 7.6 11.86 20.84 12.12 40.78.26 20.16-5.82 34.79-11 43.52-.09.16-9.41 15.83-16.75 17.05a8.38 8.38 0 0 1 -1.35.06zm-3.65-22.25c1.08 1.15 2.26 2.38 3.38 3.5 5.11-6.17 13.64-19.82 13.36-41.74-.27-21.48-8.94-32.54-13.77-37-1.06 1.07-2.22 2.3-3.28 3.47 4.9 5.87 11.81 17 12.45 35.83a51.81 51.81 0 0 1 -12.14 35.95z"
        fill="#191919"
      />

      <path
        d="m151.07 128.11c0 15.17-10.17 28.47-26.79 27.47-14.77-.89-26.79-12.3-26.79-27.47s10.76-27.07 26.79-27.47c15.83-.39 26.79 12.3 26.79 27.47z"
        fill="#191919"
      />

      <path
        d="m126.06 163.63q-1.12 0-2.27-.07c-19.56-1.18-34.31-16.42-34.31-35.45 0-20 14.55-35 34.59-35.47a33.24 33.24 0 0 1 24.35 9.36 36.5 36.5 0 0 1 10.65 26.09c0 10.56-4 20.4-11 27a31.37 31.37 0 0 1 -22.01 8.54zm-1.07-55h-.52c-11.18.28-19 8.29-19 19.47 0 10.44 8.29 18.82 19.27 19.48a15.85 15.85 0 0 0 12.33-4.15c3.8-3.58 6-9.17 6-15.34a20.43 20.43 0 0 0 -5.82-14.64 17.14 17.14 0 0 0 -12.25-4.82z"
        fill="#191919"
      />

      <path
        d="m40.59 49.24c-1.28 0-33.08 24.32-33.08 76 0 53.23 30.89 81.28 32.64 81.49s16.43-13.58 16.43-14.68-26.95-20.59-26.95-65.28 26.73-61.33 26.73-62.85c0-.92-12.26-14.68-15.77-14.68z"
        fill="#ffffff"
      />

      <path
        d="m74.77 78.6c-1.55 0-21.69 12.71-22.13 46.88-.45 35.05 19.72 52.36 21 52.58s14.24-13.36 14.24-14.46-15.48-11.84-14.68-35.49c.88-25.85 14.9-34.61 14.9-35.71s-11.36-13.8-13.33-13.8z"
        fill="#ffffff"
      />

      <path
        d="m215.41 49.24c1.28 0 33.08 24.32 33.08 76 0 53.23-30.89 81.28-32.64 81.49s-16.43-13.58-16.43-14.68 26.95-20.59 26.95-65.28-26.73-61.36-26.73-62.87c0-.9 12.26-14.66 15.77-14.66z"
        fill="#ffffff"
      />

      <path
        d="m181.23 78.6c1.55 0 21.69 12.71 22.13 46.88.45 35.05-19.72 52.36-21 52.58s-14.24-13.36-14.24-14.46 15.48-11.84 14.68-35.49c-.88-25.85-14.9-34.61-14.9-35.71s11.36-13.8 13.33-13.8z"
        fill="#ffffff"
      />

      <path
        d="m151.07 128.11c0 15.17-10.17 28.47-26.79 27.47-14.77-.89-26.79-12.3-26.79-27.47s10.76-27.07 26.79-27.47c15.83-.39 26.79 12.3 26.79 27.47z"
        fill="#e83a2a"
      />
    </g>
  </svg>
</template>
