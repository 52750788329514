import authMiddleware from "@/middleware/authMiddleware";
const questionRoutes = {
  path: "/questions",
  name: "Questions",
  alwaysShow: true,
  beforeEnter: authMiddleware,
  meta: {
    requiresAuth: true,
    progress: {
      func: [
        { call: "color", modifier: "temp", argument: "$defaultColor" },
        { call: "fail", modifier: "temp", argument: "#6e0000" },
        { call: "location", modifier: "temp", argument: "top" },
        {
          call: "transition",
          modifier: "temp",
          argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
        },
      ],
    },
  },
  children: [
    {
      path: "all",
      name: "allQuestions",
      component: () => import("@/components/questions/HomeQuestions.vue"),
      meta: { title: "all-questions", noCache: true },
    },
  ],
};

export default questionRoutes;
