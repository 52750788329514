<template>
  <div
    ref="modalEle"
    class="modal fade"
    :id="'updateNewPoll' + post.id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-keyboard="false"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100" id="ModalToggleAddSurveyLabel">
            {{ $t("Create Poll") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            v-if="!page"
            class="d-flex justify-start-center align-items-center"
          >
            <ProfilePicture :page="page" :USER="USER"></ProfilePicture>
            <div class="m-2">
              <div class="d-flex justify-content-start">
                <p class="mb-1 fw-bold">{{ full_name }}</p>
              </div>

              <div
                class="d-inline-flex d-flex align-items-center privacy"
                data-bs-target="#exampleModalToggleForPrivacyUpdatePoll"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                title="Privacy Post"
              >
                <p class="m-0 text-white">{{ $t(privacy) }}</p>
                <svg
                  viewBox="0 -4.5 20 20"
                  width="10"
                  class="mx-1"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <title>arrow_down [#338]</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-220.000000, -6684.000000)"
                        fill="#ffffff"
                      >
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          <path
                            d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                            id="arrow_down-[#338]"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <span
                data-bs-target="#exampleModalToggleFriendsUpdate"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                title="tag friend"
                class="d-inline-flex d-flex align-items-center privacy mx-2"
              >
                {{ $t("Add Friend Tag") }}
              </span>
            </div>
          </div>
          <h6>{{ $t("update Your Question") }}</h6>
          <div class="row" id="addCount">
            <div class="col-xs-12">
              <!-- Your Question -->
              <input
                class="form-control"
                type="text"
                :placeholder="$t('Write Your Question here')"
                v-model="question"
              />
              <br />
            </div>
          </div>

          <div
            class="poll-question"
            v-for="(answer, index) in post.poll.answers"
            :key="index"
          >
            {{ index + 1 }} - {{ answer.body }}
          </div>
        </div>

        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal">
            {{ $t("Cancel") }}
          </button>
          <button
            v-if="!isLoading"
            class="btn btn-special"
            @click="updatePoll()"
          >
            {{ $t("Save") }}
          </button>
          <button
            v-else
            class="btn btn-lg btn-light w-100 text-dark fw-bold d-flex align-items-center justify-content-center"
            type="submit"
          >
            {{ $t("Loading...") }}
            <div class="spinner-border text-dark ms-2" role="status">
              <span class="visually-hidden">{{ $t("Loading...") }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!---------------------------- Start Modal tag a friend ----------------------------->
  <ModalTagFriendsUpdate
  :post="post"
    @dataTagFriends="dataFromTagFriends"
    ref="tagFriendsComponentRef"
  ></ModalTagFriendsUpdate>
  <!---------------------------- End Modal tag a friend ------------------------------->

  <!---------------------------- Start Privacy Modal  --------------------------------->
  <ModalPrivacyUpdate
    :post="post"
    @dataPrivacy="dataFromPrivacy"
    ref="privacyComponentRef"
  ></ModalPrivacyUpdate>
  <!---------------------------- end Privacy Modal  ----------------------------------->
</template>

<script>
import ModalTagFriendsUpdate from "@/Elements/post/editPostForm/poll/ModalTagFriendsUpdate.vue";
import ModalPrivacyUpdate from "@/Elements/post/editPostForm/poll/ModalPrivacyUpdate.vue";
import ProfilePicture from "@/Elements/post/addPostForm/ProfilePicture.vue";
import postServices from "@/services/HomePage/post.services";
import { Modal } from "bootstrap";
import { mapGetters, mapMutations } from "vuex";
import tagFriendMixin from "@/mixins/tagFriendMixin";
export default {
  name: "EditPoll",
  mixins: [tagFriendMixin],
  props: ["post", "location"],
  data() {
    return {
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
      updateNewPoll: null,
      isLoading: false,
      question: this.post.poll.body,
      privacy: "public",
      privacychild: "public",
      custom_profile_ids: [],
    };
  },
  components: {
    ModalTagFriendsUpdate,
    ModalPrivacyUpdate,
    ProfilePicture,
  },
  async mounted() {
    this.updateNewPoll = new Modal(`#updateNewPoll${this.post.id}`);
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
    }),
    full_name() {
      const user = JSON.parse(localStorage.getItem("user"));
      return `${user.profile.first_name} ${user.profile.last_name}`;
    },
  },
  methods: {
    dataFromTagFriends(data) {
      this.invited_ids = data.invited_ids;
    },
    dataFromPrivacy(data) {
      this.privacy = data.privacy;
      this.privacychild = data.privacychild;
      this.custom_profile_ids = data.custom_profile_ids;
    },
    updatePoll() {
      this.isLoading = true;
      let payloadPost = {}; 
      if (this.question) {
        payloadPost.body = this.question;
      }
      payloadPost.post_id = this.post.id;
      payloadPost.poll_id = this.post.poll.id;

      if (!this.page) {
        payloadPost.privacy = this.privacy;
        if (this.invited_ids != null) {
          payloadPost.friends_ids = this.invited_ids;
        }
        if (this.privacy === "custom") {
          payloadPost.custom_profile_ids = this.custom_profile_ids;
        }
      } else {
        payloadPost.page_id = this.page.id;
        payloadPost.privacy = this.privacy;
        if (this.privacy === "custom") {
          payloadPost.custom_profile_ids = this.custom_profile_ids;
        }
      }
      postServices
        .updatePoll(payloadPost)
        .then(() => {
          this.isLoading = false;
          if(this.location == 3) {
            // console.log('aaa')
            this.UPDATE_POST_POLL_PAGE(payloadPost);
          }else {
            this.UPDATE_POST_POLL_PROFILE(payloadPost);
          }
          this.updateNewPoll.hide();
          this.$refs.privacyComponentRef.clearData();
          this.$refs.tagFriendsComponentRef.clearData();
        })
        .catch((error) => {
          if (
            error.graphQLErrors &&
            error.graphQLErrors.length > 0 &&
            error.graphQLErrors[0].extensions
          ) {
            const validationErrors =
              error.graphQLErrors[0].extensions.validation;
            if (validationErrors) {
              this.$snotify.error(this.$i18n.t("Verify all entries"), "Error");
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    resetForm() {
      this.question = null;
      this.privacy = "public";
      this.custom_profile_ids = [];
    },
    ...mapMutations({
      UPDATE_POST_POLL_PROFILE: "profile/UPDATE_POST_POLL_PROFILE",
      UPDATE_POST_POLL_PAGE: "singlePage/UPDATE_POST_POLL_PAGE",
    }),
  },
};
</script>

<style lang="scss" scoped>
.poll-question {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: #333;
  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
}
.privacy {
  background-color: #8f5fee;
  color: white;
  border: 1px solid #8f5fee;
  border-radius: 3px;
  cursor: pointer;
  font-size: smaller;
  padding: 2px 5px;
}
textarea:focus,
input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
}
textarea::placeholder,
input::placeholder {
  color: #6c757d;
  font-size: 16px;
}

textarea {
  width: 100% !important;
  border: none;
  padding: 0.5rem 1rem;
  font-weight: bold;
  border-radius: 15px;
  height: auto;
  resize: none;
  transition: all 0.3s ease;
  &::placeholder {
    color: rgb(107, 106, 106) !important;
    font-size: 20px;
  }
}

.modal {
  background-color: rgba(255, 255, 255, 0.527);
  .modal-header {
    background: #e3e2e2;
  }
  .modal-content {
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

    .modal-body {
      label {
        font-weight: bold;
      }
    }
  }
}

.input-group {
  margin-bottom: 1rem;
}

.btn-special {
  background-color: #983baf;
  color: #fff;
}
.btn-special:hover {
  background-color: #6a1380;
  color: #fff;
}
.btn-custom {
  background-color: #77797c;
  color: #fff;
}

.btn-custom:hover {
  background-color: #77797c;
  color: #fff;
}
.add-new-answer {
  width: 50%;
}
.form-check-input:checked {
  background-color: #0dfd14;
  border-color: #0dfd14;
}
</style>
