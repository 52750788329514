import notificationServices from "@/services/Notification/notificationServices";

export default {
  namespaced: true,
  state: {
    unreadNotifications: 0,
    allNotifications: [],
    friendRequestCount: 0,
    page: 1,
    last_page_notification: null,
    limit_notification: 12,
    loading_notification: false,
    moreExists_notify: false,
    loading: false,
  },
  getters: {
    moreExists_notify(state) {
      return state.moreExists_notify;
    },
    loading(state) {
      return state.loading;
    },
  },
  mutations: {
    HideCountWhenOpenNotificationList(state) {
      state.unreadNotifications = 0;
    },
    ADD_NEW_NOTIFICATION(state, payload) {
      if (payload.category === 3 && payload.content_en.includes("sent")) {
        state.friendRequestCount++;
      }
      if (payload.category) {
        state.unreadNotifications++;
        state.allNotifications.unshift(payload);
      }
      // else {
      //   // console.log(payload)
      //   this.$store.commit("auth/Change_Silver_Gold_Point", payload);
      // }
    },
    MAKE_NOTIFICATION_READ(state, payload) {
      state.allNotifications.forEach((item) => {
        if (item.id === payload) {
          if (item.pivot.isClicked != 1) {
            item.pivot.isClicked = 1;
            state.unreadNotifications--;
          }

          if (item.category === 3) {
            state.friendRequestCount--;
          }
        }
      });
    },
    REMOVE_NOTIFICATION(state, id) {
      const index = state.allNotifications.findIndex((item) => item.id == id);
      state.allNotifications.splice(index, 1);
    },
    CHANGE_STATUS_MARK_NOTIFICATION(state) {
      state.allNotifications.forEach((item) => {
        item.pivot.isClicked = 1;
        state.unreadNotifications = 0;
        if (item.category === 3) {
          state.friendRequestCount = 0;
        }
      });
    },
  },
  actions: {
    async GET_ALL_NOTIFICATIONS({ state }) {
      state.loading_notification = true
      await notificationServices
        .getAllNotification(state.page, state.limit_notification)
        .then((response) => {
          state.last_page_notification =
            response.data.UserNotificationsQuery.last_page;
          //  for click on button
          if (
            response.data.UserNotificationsQuery.current_page <
            response.data.UserNotificationsQuery.last_page
          ) {
            state.moreExists_notify = true;
            // state.page = response.data.data.current_page + 1;
          } else {
            state.moreExists_notify = false;
          }
          response.data.UserNotificationsQuery.items.forEach((item) => {
            //  counter for friendship request
            if (
              !state.allNotifications.some((notify) => notify.id === item.id)
            ) {
              if (item.pivot.isClicked === 0) {
                state.unreadNotifications++;
                if (item.category === 3 && item.content_en.includes("sent")) {
                  state.friendRequestCount++;
                }
              }
              state.allNotifications.push(item);
            }
            // expect some of category 12 and 31
            if (
              item.category == 12 ||
              item.category == 33 ||
              item.category == 32
            ) {
              const i = state.allNotifications.findIndex(
                ({ id }) => id === item.id
              );
              state.allNotifications.splice(i, 1);
              item.pivot.isClicked = 1;
              state.unreadNotifications--;
            }
          });
        });
        state.loading_notification = false
    },
    GetNumberUnClickedNotification({ state }) {
      notificationServices.NumberUnClickedNotification().then((res) => {
        state.unreadNotifications = res.data.data;
      });
    },
    // get next notification
    async a_get_next_notification({ state }) {
      this.loading = true;
      state.page++;
      if (state.page <= state.last_page_notification) {
        // button load more
        state.moreExists_notify = true;
        await notificationServices
          .getAllNotification(state.page, state.limit_notification)
          .then((res) => {
            res.data.UserNotificationsQuery.items.forEach((item) => {
              state.allNotifications.push(item);
            });
            this.loading = true;
          });
      } else {
        state.moreExists_notify = false;
      }
    },
    async GET_NEW_NOTIFICATIONS({ state }) {
      await notificationServices.getAllNotification().then((response) => {
        response.data.UserNotificationsQuery.items.forEach((item) => {
          if (!state.allNotifications.some((notify) => notify.id === item.id)) {
            if (item.pivot.isClicked === 0) {
              state.unreadNotifications++;
              if (item.category === 3 && item.content_en.includes("sent")) {
                state.friendRequestCount++;
              }
            }

            state.allNotifications.unshift(item);
          }
          if (item.category == 12 || item.category == 31) {
            const i = state.allNotifications.findIndex(
              ({ id }) => id === item.id
            );
            state.allNotifications.splice(i, 1);
            item.pivot.isClicked = 1;
            state.unreadNotifications--;
          }
        });
      });
    },
  },
};
