<template>
  <div class="row m-0 justify-content-center backgroundCenterHome">
    <LeftHomeComponent />
    <div class="mt-5 mb-5 col-md-12 col-lg-5 col-sm-12 py-0">
      <QuestionComponent
        :question="question"
        @toggleAnswers="toggleAnswers"
        @show-reactions="showReactions"
        @hide-reactions="hideReactions"
        @react="react"
        @update-answers-count="updateAnswersCount"
        @delete-question="deleteQuestion"
        @data-update-question="dataFromUpdateQuestion"
      />
    </div>
    <RightHomeComponent />
  </div>
</template>

<script>
import LeftHomeComponent from "@/components/HomeComponent/LeftHomeComponent.vue";
import QuestionComponent from "@/components/questions/QuestionComponent.vue";

import RightHomeComponent from "@/components/HomeComponent/RightHomeComponent.vue";
export default {
  name: "HomeQuestions",
  props: ["question"],
  components: { LeftHomeComponent, RightHomeComponent, QuestionComponent },
  methods: {
    // 1
    toggleAnswers(data) {
        this.$emit("toggleAnswers",data);
    },
    // 2
    showReactions() {
        this.$emit("show-reactions");
    },
    // 3
    hideReactions() {
        this.$emit("hide-reactions");
    },
    // 4
    react(data) {
        this.$emit("react", data);
    },
    // 5
    updateAnswersCount() {
        this.$emit("data-update-question");
    },
    // 6
    deleteQuestion(data) {
        this.$emit("delete-question",data)
    },
    // 7
    dataFromUpdateQuestion(data) {
        this.$emit("data-from-update-question",data);
    },
  },
};
</script>

<style lang="scss" scoped>
.backgroundCenterHome {
  min-height: calc(100vh - 63px);
}
</style>
