import { createRouter, createWebHistory } from "vue-router";
import friendshipsRoutes from "@/router/modules/friendships";
import ActivitiesRoutes from "@/router/modules/Activities";
import SavedItemRoutes from "@/router/modules/SavedItem";
import PagesRoutes from "@/router/modules/PagesRoute";
import WatchesRoutes from "@/router/modules/Watches";
import GroupsRoutes from "@/router/modules/Groups";
import authRoutes from "@/router/modules/auth";
import MarketPlace from "@/router/modules/MarketPlace";
import PointsRoutes from "@/router/modules/Points";
import JobsRoutes from "@/router/modules/JobsRoutes";
import businessRoute from "@/router/modules/businessRoute";
import guideRoutes from "@/router/modules/guideRoutes";
import ProfileRoutes from "@/router/modules/ProfileRoutes";
import ChallengesRoutes from "@/router/modules/challenges";
import questionRoutes from "@/router/modules/questionRoutes";
import HomeView from "@/views/HomeView.vue";
import BirthDaysView from "@/views/BirthDaysView.vue";
import HashtagsView from "@/views/HashtagsView.vue";

import AddReels from "@/views/AddReels.vue";

// import otherProfile from "@/views/myprofile/otherProfile/otherProfile.vue";
import ShowHighLights from "@/views/ShowHighLights";
import StoriesView from "@/views/StoriesView";
import ServiceView from "@/views/Service/ServiceView";
// import StoriesViewnew from "@/views/StoriesViewnew";
import ShowPostView from "@/views/ShowPostView";
import ShowCommentPostView from "@/views/ShowCommentPostView";
// import SoonView from "@/views/SoonView";
import GuestView from "@/views/GuestView";

import ShowReelView from "@/views/ShowReelView.vue";
// import store from "@/store";
import ShowReeFromProfilelView from "@/views/ShowReeFromProfilelView.vue";
import GuestShowReelView from "@/views/GuestShowReelView.vue";
import GuestStoriesView from "@/views/GuestStoriesView.vue";
import settingView from "@/views/settingView.vue";
import AddStory from "@/views/AddStory.vue";
import ComplateInformationView from "@/views/ComplateInformationView.vue";
import reelscommentnotification from "@/views/notification/ReelsCommentNotification.vue";
// import StoryInteractionShow from "@/views/notification/StoryInteractionShow.vue";
import StoryInteractionShow from "@/views/notification/StoryInteractionShowNew.vue";
import StoryCommentShow from "@/views/notification/StoryCommentShow.vue";
import ShowProblem from "@/views/notification/ShowProblem.vue";
import ContactComponents from "@/components/ContactComponents/ContactComponents.vue";
import NotificationView from "@/views/notification/NotificationView.vue";

import HomeSearchView from "@/views/Search/HomeSearchView.vue";

import authMiddleware from "@/middleware/authMiddleware";
import guestMiddleware from "@/middleware/guestMiddleware";

const routes = [
  friendshipsRoutes,
  ActivitiesRoutes,
  SavedItemRoutes,
  PagesRoutes,
  WatchesRoutes,
  GroupsRoutes,
  authRoutes,
  MarketPlace,
  JobsRoutes,
  PointsRoutes,
  businessRoute,
  guideRoutes,
  ProfileRoutes,
  ChallengesRoutes,
  questionRoutes,
  {
    path: "/",
    name: "guest",
    component: GuestView,
    beforeEnter: guestMiddleware,
    meta: {
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#375493" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  // For Search
  {
    path: "/search",
    name: "search",
    component: HomeSearchView,
    beforeEnter: authMiddleware,
    meta: {
      requiresAuth: true,
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#375493" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  // For Search
  {
    path: "/Hashtags",
    name: "Hashtags",
    component: HashtagsView,
    beforeEnter: authMiddleware,
    meta: {
      requiresAuth: true,
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#375493" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    beforeEnter: authMiddleware,
    meta: {
      requiresAuth: true,
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#375493" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/birthdays",
    name: "birthdays",
    component: BirthDaysView,
    beforeEnter: authMiddleware,
    meta: {
      requiresAuth: true,
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#375493" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/allservices",
    name: "allservices",
    component: ServiceView,
    beforeEnter: authMiddleware,
    meta: {
      requiresAuth: true,
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#375493" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/add-reels",
    name: "add-reels",
    component: AddReels,
    beforeEnter: authMiddleware,
    meta: {
      requiresAuth: true,
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#00a79d" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/complate-information",
    name: "complate-information",
    component: ComplateInformationView,
    beforeEnter: authMiddleware,
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/profile/:id",
  //   name: "profile2",
  //   component: otherProfile,
  //   beforeEnter: authMiddleware,
  //   meta: {
  //     requiresAuth: true,
  //     progress: {
  //       func: [
  //         { call: "color", modifier: "temp", argument: "#375493" },
  //         { call: "fail", modifier: "temp", argument: "#6e0000" },
  //         { call: "location", modifier: "temp", argument: "top" },
  //         {
  //           call: "transition",
  //           modifier: "temp",
  //           argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
  //         },
  //       ],
  //     },
  //   },
  // },
  {
    path: "/profile/:id/satistics",
    name: "profileSatistics",
    component: () =>
      import(
        /* webpackChunkName: "User Profile Satistics" */ "@/components/ProfileComponent/user profile/UserProfileSatistics.vue"
      ),
    beforeEnter: authMiddleware,
    meta: {
      requiresAuth: true,
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#375493" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/add-story-filter-audio",
    name: "filterAudio",
    beforeEnter: authMiddleware,
    component: () => import("@/views/AddStoryWithFilterAudio.vue"),
  },
  {
    path: "/add_story",
    name: "AddStory",
    component: AddStory,
    beforeEnter: authMiddleware,
    meta: {
      requiresAuth: true,
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#375493" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },

  },
  {
    path: "/stories/:id",
    name: "StoriesView",
    component: StoriesView,
    beforeEnter: authMiddleware,
    meta: {
      requiresAuth: true,
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#375493" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },

  // For HighLights Show
  {
    path: "/ShowHighLights/:id",
    name: "ShowHighLights",
    component: ShowHighLights,
    beforeEnter: authMiddleware,
    meta: {
      requiresAuth: true,
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#375493" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },

  // Notification Story
  {
    path: "/shpwstoryinteraction/:id",
    name: "shpwstoryinteraction",
    component: StoryInteractionShow,
    beforeEnter: authMiddleware,
    meta: { requiresAuth: true },
  },
  // Notification Story
  {
    path: "/showstorycomment/:id",
    name: "showstorycomment",
    component: StoryCommentShow,
    beforeEnter: authMiddleware,
    meta: { requiresAuth: true },
  },

  {
    path: "/showpost/:id",
    name: "shpwpost",
    component: ShowPostView,
  },
  {
    path: "/showcommentpost/:id",
    name: "shpwcomentpost",
    component: ShowCommentPostView,
    beforeEnter: authMiddleware,
    meta: { requiresAuth: true },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: NotificationView,
    beforeEnter: authMiddleware,
    meta: { requiresAuth: true },
  },
  {
    path: "/setting",
    name: "setting",
    component: settingView,
    beforeEnter: authMiddleware,
    meta: { requiresAuth: true },
  },
  {
    path: "/show-problem/:id",
    name: "showProblem",
    component: ShowProblem,
    beforeEnter: authMiddleware,
    meta: { requiresAuth: true },
  },
  {
    path: "/contact",
    name: "contactUS",
    component: ContactComponents,
    beforeEnter: authMiddleware,
    meta: {
      requiresAuth: true,
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#375493" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/showreel/:id",
    name: "ReelsView",
    component: ShowReelView,
    meta: {
      requiresAuth: true,
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#375493" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/showreelcomment/:id",
    name: "Reel_comment_view",
    component: reelscommentnotification,
    beforeEnter: authMiddleware,
    meta: {
      requiresAuth: true,
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#375493" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/showreelFromProfile/:id",
    name: "ReelsViewFromProfile",
    component: ShowReeFromProfilelView,
    beforeEnter: authMiddleware,
    meta: {
      requiresAuth: true,
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#375493" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/guestshowreel/:id",
    name: "GuestReelsView",
    component: GuestShowReelView,
    beforeEnter: guestMiddleware,
    meta: {
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#375493" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/guestshowstories/:id",
    name: "GuestStoriesView",
    component: GuestStoriesView,
    beforeEnter: guestMiddleware,
    meta: {
      progress: {
        func: [
          { call: "color", modifier: "temp", argument: "#375493" },
          { call: "fail", modifier: "temp", argument: "#6e0000" },
          { call: "location", modifier: "temp", argument: "top" },
          {
            call: "transition",
            modifier: "temp",
            argument: { speed: "1.5s", opacity: "0.6s", termination: 400 },
          },
        ],
      },
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotfoundPage",
    component: () => import("@/views/PagesNotFound/PageNotFound.vue"),
  },
  {
    path: "/not-found-item",
    name: "NotfoundItem",
    component: () => import("@/views/PagesNotFound/PageNotFound.vue"),
  },
  {
    path: "/chat",
    name: "chatAi",
    component: () => import("@/components/chatAi/HomeChatAi.vue"),
  },
  {
    path: "/maps",
    name: "maps",
    component: () => import("@/components/maps/HomeMaps.vue"),
  },
  {
    path: "/live",
    name: "live",
    component: () => import("@/components/live/VideoLive.vue"),
  },
  {
    path: "/all-live",
    name: "allLive",
    component: () => import("@/components/live/AllLive.vue"),
  },
  {
    path: "/join-live/:roomId",
    name: "joinLive",
    component: () => import("@/components/live/JoinLive.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active_link",
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ["/login", "/register", "/", "/watches/home","/showreel/"];
//   const homePage = ["/"];
//   const authRequired = !publicPages.includes(to.path);
//   const homeRequired = homePage.includes(to.path);
//   // const loggedIn = store.state.CURRENT_TOKEN;
//   if (authRequired && !loggedIn) {
//     next("/login");
//   } else if (loggedIn && homeRequired) {
//     next("/home");
//   } else {
//     next();
//   }
// });

export default router;
