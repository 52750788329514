<template>
  <div
    class="row justify-content-center m-0 align-items-center"
    style="height: 100vh"
    v-if="loading"
  >
    <div class="spinner-border text-success" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-border text-success" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div class="spinner-border text-success" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-else class="row m-0">
    <!-- SideBar -->
    <div class="col-4" style="background-color: #fdf8f8">
      <button
        type="button"
        class="btn-close mb-3"
        @click="back"
        aria-label="Close"
      ></button>
      <div class="title mb-3 d-flex justify-content-between">
        <div class="one d-flex justify-content-center align-items-center">
          <div v-if="data.model.has_media_profile === true">
            <img crossorigin="anonymous" 
              v-if="data.model.media[0].collection_name === 'profile'"
              :src="data.model.media[0].src_url"
              alt=""
            />
            <img crossorigin="anonymous"  v-else :src="data.model.media[1].src_url" alt="" />
          </div>
          <div v-else>
            <img crossorigin="anonymous"  src="@/assets/img_friends/default1.png" alt="" />
          </div>
          <div class="info ms-2">
            <p class="m-0 fw-bold" v-if="data.model_type != 'Page'">
              {{ data.model.first_name }}
              {{ data.model.last_name }}
            </p>
            <p class="m-0 fw-bold" v-else>
              {{ data.model.name }} <small style="color: green; font-weight: bold"> page</small>
            </p>
            <p class="m-0 text-muted">{{ format_date(data.created_at) }}</p>
          </div>
        </div>
        <div class="tow mb-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5"
            height="5"
            viewBox="0 0 5 5"
          >
            <circle
              id="Ellipse_181"
              data-name="Ellipse 181"
              cx="2.5"
              cy="2.5"
              r="2.5"
              fill="#a5a5a5"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5"
            height="5"
            viewBox="0 0 5 5"
          >
            <circle
              id="Ellipse_181"
              data-name="Ellipse 181"
              cx="2.5"
              cy="2.5"
              r="2.5"
              fill="#a5a5a5"
            />
          </svg>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="5"
            height="5"
            viewBox="0 0 5 5"
          >
            <circle
              id="Ellipse_181"
              data-name="Ellipse 181"
              cx="2.5"
              cy="2.5"
              r="2.5"
              fill="#a5a5a5"
            />
          </svg>
        </div>
      </div>
      <div class="body mb-5">
        <p>{{ data.content }}</p>
      </div>

      <div class="interaction mb-3 d-flex justify-content-between">
        <div>
          <span>{{ data.comments_count }} {{ $t("Comment") }}</span>
        </div>
        <div
          @click="showinteractionmodel"
          v-if="data.interacted"
          style="cursor: pointer"
        >
          <img crossorigin="anonymous" 
            class="img-reaction"
            src="@/assets/reactions/like.gif"
            alt="Like emoji"
          />
          <img crossorigin="anonymous" 
            class="img-reaction"
            src="@/assets/reactions/love.gif"
            alt="Love emoji"
          />
          <!-- {{ all }} -->
          <span>أنت و {{ data.interactions_count - 1 }} من الأشخاص</span>
        </div>
        <div @click="showinteractionmodel" v-else>
          <img crossorigin="anonymous" 
            class="img-reaction"
            src="@/assets/reactions/like.gif"
            alt="Like emoji"
          />
          <img crossorigin="anonymous" 
            class="img-reaction"
            src="@/assets/reactions/love.gif"
            alt="Love emoji"
          />
          {{ data.interactions_count }}
        </div>
      </div>

      <InteractionComponent
        v-if="interactionModel && interaction_model"
        v-on:change="change()"
        :id="id"
      />
<!-- {{ comments[0] }} -->
      <div class="comments mt-5">
        <div class="vcomments__item">
          <div class="row">
            <div class="col-12" v-if="comments">
              <div class="comment-avatar">
                <img crossorigin="anonymous" 
                  v-if="comments.model.has_media_profile === false"
                  src="@/assets/img_friends/default1.png"
                  alt=""
                />
                <img crossorigin="anonymous" 
                  v-else
                  :src="comments.model.media[0].src_url"
                  alt=""
                />
              </div>
              <div class="vcomments__info">
                <span class="vcomments__author"
                  ><router-link
                    class="router-link-to-profile"
                    :to="{
                      name: 'homeMyProfile',
                      params: { id: EncryptionRoute(comments.user_id) },
                    }"
                    >{{ comments.model.name }}</router-link
                  ></span
                >
                <div class="dropdown text-center">
                  <small
                    style="cursor: pointer"
                    :id="'dropdownMenuButton1' + comments.id"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img crossorigin="anonymous" 
                      class="image-dropdown"
                      src="@/assets/images/more.png"
                      alt="action more"
                    />
                  </small>
                  <ul
                    class="dropdown-menu"
                    :aria-labelledby="'dropdownMenuButton1' + comments.id"
                  >
                    <li v-if="this.USER.id == comments.user_id">
                      <a
                        class="dropdown-item"
                        @click="delete_comment(comments.id)"
                      >
                        <DeleteSvg></DeleteSvg>
                        {{ $t("Delete") }}
                      </a>
                    </li>
                    <li
                      v-if="this.USER.id == comments.user_id"
                      class="dropdown-item"
                      style="cursor: pointer"
                    >
                      <img crossorigin="anonymous" 
                        src="@/assets/images/edit_mode_icon.png"
                        style="width: 20px; height: 20px"
                        alt=""
                      />
                      {{ $t("Edit") }}
                    </li>
                    <li
                      v-if="this.USER.id != comments.user_id"
                      class="dropdown-item"
                      style="cursor: pointer"
                      :data-bs-target="'#staticCommentReport' + comments.id"
                      data-bs-toggle="modal"
                    >
                      <ReportPostSvg></ReportPostSvg>
                      {{ $t("Report Comment") }}
                    </li>
                  </ul>
                </div>
                <!-- <CommentReportComponent :id="comments.id" /> -->

                <span class="vcomments__time">
              {{ format_date(comments.created_at) }}  
              </span>
              </div>
              <div class="card-body" dir="auto">
                <div class="card-text vcomments__quote">
                  <div
                  class="mt-5 mb-1 px-3"
                  v-if="comments.comment_body"
                    v-html="
                      this.showcontentComment(
                        comments.comment_body,
                        comments.tags
                      )
                    "
                    v-linkified
                  ></div>
                  <div v-if="comments.media && comments.media.media_type == 'Image'	" class="mt-2 text-center">
                      <img crossorigin="anonymous"  :src="comments.media.src_url" style="max-width: 150px;max-height: 150px;min-height: 75px;min-width: 75px;" alt="CommentImage">
                    </div>
                    <div v-else-if="comments.media && comments.media.media_type == 'Sound'	" class="mt-2 text-center">
                      <audio  :src="comments.media.src_url" controls></audio>
                    </div>
                </div>
              </div>
            </div>
            <div class="vcomments__btns" v-if="comments">
              <!-- Start Button Reaction to comment -->
              <AddReactionToComment
                v-on:changeint="changeint"
                :comment="comments"
              ></AddReactionToComment>
              <!-- End Button Reaction to comment -->
              <div class="vcomments__bleft">
                <span
                  class="vcomments__btn vcomments__btn--reply"
                  @click="clickReply(comments.model.name)"
                  >{{ replyText }}</span
                >
              </div>
              <div class="vcomments__bright">
                <span
                  class="vcomments__btn vcomments__btn--toggle"
                  @click="ShowReplies"
                >
                  <span v-if="!replyStatus">show Replies</span>
                  <span v-else>Hide Replies</span>
                </span>
              </div>
            </div>
            <div class="vcomments__group" v-if="replies">
              <ReplyComponent
                v-for="reply in replies"
                :key="reply.id"
                :comment="reply"
                :replyStatus="replyStatus"
                :friendstagsarray="friendstagsarray"
                v-on:deletereply="deletereply"
                v-on:addreactiontoreply="addreactiontoreply"
                v-on:editreply="editreply"
              ></ReplyComponent>
            </div>
            <AddComment
              v-if="comments"
              ref="form"
              :replytag="replytag"
              v-on:addreply="addreply"
              v-bind:comment="comments"
            ></AddComment>
          </div>
        </div>
      </div>
    </div>
    <!-- SideBar -->

    <!-- Slider -->
    <div class="col-8" v-if="data.type != 3">
      <div
        v-if="data.media.length > 0"
        id="carouselExampleControls"
        class="carousel slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div>
            <div v-if="data.media.length === 1">
              <div v-if="data.media[0].media_type == 'Image'">
                <div
                  class="back"
                  :style="{
                    'background-image': 'url(' + data.media[0].src_url + ')',
                  }"
                >
                  <!-- <div class="layer"></div> -->
                  <img crossorigin="anonymous" 
                    :src="data.media[0].src_url"
                    class="d-block"
                    alt="Image"
                  />
                </div>
              </div>
              <div v-else>
                <div class="back">
                  <video crossorigin="anonymous"  controls :src="data.media[0].src_url"></video>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                :class="['carousel-item', index === 0 ? 'active' : '']"
                v-for="(media, index) in data.media"
                :key="index"
              >
                <div v-if="media.media_type == 'Image'">
                  <div
                    class="back"
                    :style="{
                      'background-image': 'url(' + media.src_url + ')',
                    }"
                  >
                    <div class="layer"></div>
                    <img crossorigin="anonymous" 
                      :src="media.src_url"
                      style="
                        max-width: calc(100% - 20px) !important;
                        max-height: calc(100% - 20px) !important;
                      "
                      class="d-block"
                      alt="Image"
                    />
                  </div>
                </div>
                <div v-else>
                  <div class="back">
                    <video crossorigin="anonymous" 
                      :id="`vid${index}`"
                      controls
                      :src="media.src_url"
                    ></video>
                  </div>
                </div>
                <button
                  class="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                  @click="stop(index)"
                >
                  <span
                    class="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">{{ $t("Previous") }}</span>
                </button>
                <button
                  class="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                  @click="stop(index)"
                >
                  <span
                    class="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span class="visually-hidden">{{ $t("Next") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- If Post Contain Share Post -->
      <div
        v-if="data.sharing_post && data.parent"
        class=""
      >
        <div class="w-100">
          <SharedPost :post="data.parent" :showfooter="true"/>
        </div>
      </div>
      <!-- If Post Contain Share Post -->
    </div>
    <!-- Slider -->

    <!-- If Post Contain Life Event -->
    <div v-else class="col-8 d-flex justify-content-center align-items-center">
        <div class=" w-50" v-if="data.body">
          <LifeEvent :item="data.body"></LifeEvent>
        </div>
      </div>
      <!-- If Post Contain Life Event -->
  </div>
</template>

<script>
// import CommentReportComponent from "@/components/HomeComponent/CommentReportComponent.vue";
import DeleteSvg from "@/components/OtherComponent/SVG/DeleteSvg.vue";
import { useRoute } from "vue-router";
import postServices from "@/services/HomePage/post.services";
import AddComment from "@/components/ShowComment/Comment/AddComment.vue";
import InteractionComponent from "@/components/HomeComponent/InteractionComponent.vue";
import CommentService from "@/services/Comment/CommentPostService";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import ReplyComponent from "@/components/ShowComment/Replies/ReplyComponent.vue";
import AddReactionToComment from "@/components/ShowComment/Comment/AddReactionToComment.vue";
import CommentPostService from "@/services/Comment/CommentPostService";
import formatTime from "@/mixins/formatTime";
// import LifeEvent from "@/components/HomeComponent/PostComponents/LifeEvent.vue";
import LifeEvent from "@/Elements/GroupPost/LifeEvent.vue";
import SharedPost from "@/Elements/post/body/SharedPostComponent.vue"

import { EncryptionRoute } from "@/utils/EncryptionRoute"

export default {
  name: "ShowCommentPostView",
  data() {
    return {
      route: useRoute(),
      id: useRoute().params.id,
      comment_id: useRoute().query.comment_id,
      comment: useRoute().query.comment,
      reply_id: useRoute().query.reply,
      data: null,
      loading: false,
      comments: [],
      interaction_model: null,
      replies: [],
      replyStatus: false,
      replytag: "",
    };
  },
  mixins: [formatTime],
  components: {
    InteractionComponent,
    ReplyComponent,
    AddReactionToComment,
    AddComment,
    DeleteSvg,
    // CommentReportComponent,
    LifeEvent,
    SharedPost
  },
  computed: {
    ...mapState("post", {
      interactionModel: "interactionModel",
    }),
    replyText: function () {
      if (this.isReplying) {
        return this.$i18n.t("Cancel reply");
      } else {
        return this.$i18n.t("Reply");
      }
    },
    ...mapGetters({
      friendstagsarray: "friendStore/friendstagsarray",
      USER: "auth/USER",
    }),
  },
  methods: {
    EncryptionRoute,
    stop(index) {
      var current = document.getElementById(`vid${index}`);
      current.pause();
    },
    delete_comment(id) {
      this.$store.dispatch("comment/a_delete_comment", id);
      this.$snotify.success(
        this.$i18n.t("Deleted successfully"),
        this.$i18n.t("Comment")
      );
      this.data.comments_count = this.data.comments_count - 1;
      this.replies = [];
      this.comments = this.comments.filter((comment) => comment.id != id);
    },
    back() {
      this.$router.go(-1);
    },
    async ShowReplies() {
      if (this.route.query.comment_id) {
        this.replyStatus = !this.replyStatus;
        await this.getallreplies(this.comment_id);
      } else {
        this.replyStatus = !this.replyStatus;
        await this.getallreplies(this.comment);
      }
    },
    async Get_Comment_On_Post(id) {
      await CommentService.Get_Comment_On_Post(id).then((res) => {
        // console.log('000',res.data.data)
        this.replies.push(res.data.data);
      });
    },
    addreactiontoreply(payload) {
      // console.log(payload)
      this.replies.forEach((item) => {
        if (item.id == payload.id) {
          if (payload.data) {
            item.interacted = payload.data.type;
          } else {
            item.interacted = null;
          }
        }
      });
    },
    editreply(data) {
      // console.log(data)
      this.replies = this.replies.map((item) =>
        item.id == data.id ? data : item
      );
      // console.log(this.replies)
    },
    async deletereply(id) {
      this.replies = this.replies.filter((reply) => reply.id != id);
      await CommentPostService.Delete_Comment(id);
    },
    addreply(data) {
      data.interacted = null;
      this.replies.push(data);
      this.replyStatus = true;
    },
    clickReply(name) {
      this.isReplying = !this.isReplying;
      if (this.isReplying) {
        var self = this;
        setTimeout(function () {
          self.$refs.form.$refs.input.focus();
        }, 0);
      }
      // console.log(name)
      this.replytag = "@" + name.replaceAll(" ", "_") + " ";
      // console.log(this.replytag)
    },
    changeint(data) {
      if (data) {
        if (!this.comments.interacted) {
          this.comments.Interactions++;
        }
        this.comments.interacted = data.type;
      } else {
        this.comments.interacted = null;
      }
    },
    async getPostWithComment(post_id, comment_id) {
      await postServices.getPostWithComment(post_id, comment_id).then((res) => {
        // console.log(res.data.data)
        this.data = res.data.data;
        this.comments = res.data.data.comments;
      });
    },
    showinteractionmodel() {
      this.UPDATE_IS_MODAL_INTERACTION();
      this.interaction_model = true;
      if (document.documentElement.style.overflow === "hidden") {
        document.documentElement.style.overflow = "auto";
      } else {
        document.documentElement.style.overflow = "hidden";
      }
    },
    ...mapMutations({
      UPDATE_IS_MODAL_INTERACTION: "post/UPDATE_IS_MODAL_INTERACTION",
    }),
    change() {
      this.interaction_model = null;
    },

    ...mapActions({
      get_all_friends: "friendStore/get_all_friends",
    }),

    async getallreplies(id) {
      // console.log(typeof(id))
      let data = {
        comment_id: parseInt(id)
      }
      await CommentService.Get_Replies_to_comment(data).then((res) => {
        // console.log('aaa',res.data.data.items)
        if (this.replies.length == 0) {
          this.replies = res.data.CommentRepliesQuery.items;
        } else {
          // this.replies = this.replies[0]
          res.data.CommentRepliesQuery.items.forEach((item) => {
            if (!this.replies.find((x) => x.id === item.id)) {
              this.replies.push(item);
            }
          });
        }
      });
    },
    showcontentComment(content, array) {
      if (content == null) {
        array.forEach((user) => {
          let a = `<a 
          href="/profile/${this.EncryptionRoute(user.id)}
          "
          ><p class="mb-1 me-1" 
                    style="cursor:pointer;font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
                  >
                    ${user.name.replaceAll(" ", "_")}
                  </p></a>`;

          content = content.replace("^@^", a);
          a = null;
        });
        // console.log("AFTER",content)
        content = content.replaceAll("^@^", "");
        return content;
      }
      if (array.length == 0) {
        return content;
      } else {
        array.forEach((user) => {
          let a = `<a 
          href="/profile/${this.EncryptionRoute(user.id)}
          "
          ><p class="mb-1 me-1" 
                    style="cursor:pointer; font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
                  >
                    ${user.name.replaceAll(" ", "_")}
                  </p></a>`;

          content = content.replace("^@^", a);
          a = null;
        });
        // console.log("AFTER",content)
        content = content.replaceAll("^@^", "");
        return content;
      }
    },
  },
  async created() {
    this.loading = true;
    if (useRoute().query.comment_id) {
      // console.log('yyy')
      await this.getPostWithComment(this.id, this.comment_id);
      this.loading = false;
      this.replyStatus = false;
    } else {
      // console.log('111aaa')
      await this.getPostWithComment(this.id, this.comment);
      await this.Get_Comment_On_Post(this.reply_id);
      this.replyStatus = true;
      this.loading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "floating-vue/dist/style.css";
@import "@/Styles/Comments/comment/comment.scss";
.col-4:lang(ar) {
  padding: 0 0.5rem 0 0.5rem !important;
}

.col-4 {
  height: calc(100vh - 78px);
  overflow-y: scroll;
}
.one {
  img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: green !important;
}

.d-block[data-v-2b000c5d] {
  width: auto !important;
}

.interaction {
  .img-reaction {
    width: 25px;
    height: 25px;
    margin: 0;
  }
}

.add_comment {
  input {
    width: 100%;
    border-radius: 10px;
    padding: 0.2rem 1rem;
    height: 50px;
    outline: none;

    &:hover {
      outline: none;
    }
  }
}
#carouselExampleControls {
  height: 100%;
  // position: relative;

  .carousel-inner,
  .carousel-item.active {
    // height: 100% !important;
    height: calc(100vh - 78px);
    overflow: hidden;
    position: relative;

    .back {
      position: relative;
      height: calc(100vh - 78px);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-blend-mode: saturation;

      .d-block,
      video {
        border-radius: 10px;
        position: absolute;
        top: 50% !important;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: calc(100% - 20px) !important;
        max-height: calc(100% - 20px) !important;
        opacity: 1;
        z-index: 9;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px,
          rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px,
          rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px,
          rgba(0, 0, 0, 0.07) 0px 32px 64px;
      }
    }
    .cover_body {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 25px;
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    z-index: 10;
  }
}

.comments {
  .comment_box {
    border: 1px solid #777;
    border-radius: 10px;
    padding: 0.5rem 1rem;

    .comment-avatar {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .comment__info {
      padding: 0 0.5rem;
      margin-top: 0.1rem;
    }
  }
}

@media screen and (max-width: 992px) {
  .col-4 {
    width: 100% !important;
  }

  .col-8 {
    order: -1;
    flex: 1 0 100%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .d-block,
  video {
    border-radius: 10px;
    position: absolute;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 20px) !important;
    max-height: calc(100% - 20px) !important;
    opacity: 1;
    z-index: 19 !important;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }
  .carousel-control-prev,
  .carousel-control-next {
    z-index: 22 !important;
  }
}
video {
  width: 100%;
  height: 100%;
  border: 1px solid rgb(119, 119, 119);
  margin: 2px;
}

.rebly_button {
  padding: 0.1rem 0.5rem;
  cursor: pointer;
  border: 1px solid #77777774;
  border-radius: 5px;
  font-size: 14px;
}
</style>
