import gql from "graphql-tag";
import { POST_DATA } from "../PostData";
export const ALL_QUESTIONS = gql`
  ${POST_DATA}
  query GetQuestions($limit: Int, $page: Int) {
    GetQuestions(limit: $limit, page: $page) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        ...PostData
        answersCount
      }
    }
  }
`;
