<template>
  <div
    ref="modalEle"
    class="modal fade"
    id="addNewQuestion"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    data-bs-keyboard="false"
    data-bs-backdrop="static"
  >
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100" id="ModalToggleAddSurveyLabel">
            {{ $t("Create Question") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            v-if="!page"
            class="d-flex justify-start-center align-items-center"
          >
            <ProfilePicture :page="page" :USER="USER"></ProfilePicture>
            <div class="m-2">
              <div class="d-flex justify-content-start">
                <p class="mb-1 fw-bold">{{ full_name }}</p>
              </div>

              <div
                class="d-inline-flex d-flex align-items-center privacy"
                data-bs-target="#exampleModalToggleForPrivacyQuestion"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                title="Privacy Post"
              >
                <p class="m-0 text-white">{{ $t(privacy) }}</p>
                <svg
                  viewBox="0 -4.5 20 20"
                  width="10"
                  class="mx-1"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <title>arrow_down [#338]</title>
                    <desc>Created with Sketch.</desc>
                    <defs></defs>
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-220.000000, -6684.000000)"
                        fill="#ffffff"
                      >
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          <path
                            d="M164.292308,6524.36583 L164.292308,6524.36583 C163.902564,6524.77071 163.902564,6525.42619 164.292308,6525.83004 L172.555873,6534.39267 C173.33636,6535.20244 174.602528,6535.20244 175.383014,6534.39267 L183.70754,6525.76791 C184.093286,6525.36716 184.098283,6524.71997 183.717533,6524.31405 C183.328789,6523.89985 182.68821,6523.89467 182.29347,6524.30266 L174.676479,6532.19636 C174.285736,6532.60124 173.653152,6532.60124 173.262409,6532.19636 L165.705379,6524.36583 C165.315635,6523.96094 164.683051,6523.96094 164.292308,6524.36583"
                            id="arrow_down-[#338]"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <span
                data-bs-target="#exampleModalToggleFriendsQuestion"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                title="tag friend"
                class="d-inline-flex d-flex align-items-center privacy mx-2"
              >
                {{ $t("Add Friend Tag") }}
              </span>
            </div>
          </div>
          <div class="row" id="addCount">
            <div class="col-xs-12">
              <textarea
                dir="auto"
                class="input_text"
                v-model="content"
                name="content"
                id="questionContent"
                cols="30"
                rows="6"
                :placeholder="$t('Write Your Question here')"
                @input="handleInput"
              ></textarea>
              <br />
              <div v-if="contentLength == 400" class="text-danger">
                {{ contentLength }}/400
              </div>
              <div v-else class="text-secondary">{{ contentLength }}/400</div>
            </div>
          </div>
          <div class="upload-form">
            <h6>{{ $t("Add photos and videos") }}</h6>
            <form action="/upload" method="post" enctype="multipart/form-data">
              <label for="media">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-image"
                >
                  <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                  <circle cx="8.5" cy="8.5" r="1.5"></circle>
                  <path d="M21 15l-5-5L5 21"></path>
                </svg>

                <span class="txt-media">
                  {{ $t("Choose photos or video") }}
                </span>
              </label>
              <!-- <input
                type="file"
                id="media"
                name="media"
                accept="image/*,video/*"
                multiple
              /> -->
              <input
                type="file"
                id="media"
                ref="mediaPagePost"
                @change="uploadMediaPagePost()"
                accept="image/*"
                multiple
              />
            </form>
            <!-- preview div upload media -->
            <div class="text-center">
              <div
                v-for="(img, index) in postsmedia"
                :key="index"
                style="display: inline-block; position: relative"
              >
                <button
                  type="button"
                  class="btn-close"
                  :disabled="this.isLoading"
                  aria-label="Close"
                  style="
                    position: absolute;
                    top: 2px;
                    right: 2px;
                    cursor: pointer;
                    z-index: 9999;
                  "
                  @click="deleteitem(index)"
                ></button>
                <img
                  v-viewer
                  v-if="img.type.includes('image')"
                  :src="img.url"
                  :id="'aspect_video' + index"
                  style="
                    width: 75px;
                    height: 75px;
                    margin: 2px;
                    border: 1px solid #777;
                  "
                />
                <video
                  :id="'aspect_video' + index"
                  v-if="img.type.includes('video')"
                  :src="img.url"
                  style="
                    vertical-align: middle;
                    margin: 2px;
                    width: 75px;
                    height: 75px;
                    border: 1px solid #777;
                  "
                ></video>
                <!-- <span
                  class="style-preview"
                  v-if="img.type.includes('application')"
                >
                  {{ this.media[0].name }}
                </span>
                <span class="style-preview" v-if="img.type.includes('text')">
                  {{ this.media[0].name }}
                </span> -->
              </div>
            </div>
            <!-- End preview div -->
          </div>
        </div>
        <div class="px-1 mb-3" v-if="isLoading">
          <div
            class=""
            v-if="media.length > 0 && media.length == 1"
            style="border-radius: 25px"
          >
            <p class="mb-1 text-danger text-center">
              {{ $t("File is uploaded") }}
            </p>
            <div class="progress">
              <div
                id="brogressbarForOne"
                class="progress-bar progress-bar-striped"
                role="progressbar"
                style="border-radius: 25px"
                :style="`width: ${progressWidth}%`"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div class="" v-if="media.length > 1" style="border-radius: 25px">
            <p class="mb-1 text-danger text-center">
              {{ $t("Files are uploaded") }}
            </p>
            <div class="progress">
              <div
                v-for="(item, index) in media.length"
                :key="index"
                class="progress-bar progress-bar-striped"
                role="progressbar"
                :aria-valuenow="100 / media.length"
                :style="`width: ${progressWidthArray[index] / media.length}%`"
              ></div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" data-bs-dismiss="modal">
            {{ $t("Cancel") }}
          </button>
          <button
            v-if="!isLoading"
            class="btn btn-special"
            @click="saveQuestion()"
            :disabled="!this.content || content.length > 400"
          >
            {{ $t("Save") }}
          </button>
          <button
            v-else
            class="btn btn-lg btn-light w-100 text-dark fw-bold d-flex align-items-center justify-content-center"
            type="submit"
          >
            {{ $t("Loading...") }}
            <div class="spinner-border text-dark ms-2" role="status">
              <span class="visually-hidden">{{ $t("Loading...") }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!---------------------------- Start Modal tag a friend ----------------------------->
  <ModalTagFriends
    @dataTagFriends="dataFromTagFriends"
    ref="tagFriendsComponentRef"
  ></ModalTagFriends>
  <!---------------------------- End Modal tag a friend ------------------------------->

  <!---------------------------- Start Privacy Modal  --------------------------------->
  <ModalPrivacy
    @dataPrivacy="dataFromPrivacy"
    ref="privacyComponentRef"
  ></ModalPrivacy>
  <!---------------------------- end Privacy Modal  ----------------------------------->
</template>

<script>
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { uuid } from "vue-uuid";
import ModalTagFriends from "@/Elements/post/addPostForm/allTypes/question/ModalTagFriends.vue";
import ModalPrivacy from "@/Elements/post/addPostForm/allTypes/question/ModalPrivacy.vue";
import ProfilePicture from "@/Elements/post/addPostForm/ProfilePicture.vue";
import questionsServices from "@/services/HomePage/Questions/questionsServices";
import { Modal } from "bootstrap";
import { mapGetters, mapMutations } from "vuex";
import tagFriendMixin from "@/mixins/tagFriendMixin";
import createPostMixin from "@/mixins/createPostMixin";
export default {
  mixins: [createPostMixin, tagFriendMixin],
  data() {
    return {
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
      addNewQuestion: null,
      isLoading: false,
      content: "",
      width_video: null,
      height_video: null,
      datasender: [],
      privacy: "public",
      privacychild: "public",
      custom_profile_ids: [],
      progressWidth: 0,
      progressWidthArray: [],
    };
  },
  components: {
    ModalTagFriends,
    ModalPrivacy,
    ProfilePicture,
  },
  async mounted() {
    this.addNewQuestion = new Modal("#addNewQuestion");
  },
  computed: {
    contentLength() {
      return this.content.length;
    },
    ...mapGetters({
      USER: "auth/USER",
    }),
    full_name() {
      const user = JSON.parse(localStorage.getItem("user"));
      return `${user.profile.first_name} ${user.profile.last_name}`;
    },
  },
 
  methods: {
    handleInput() {
      if (this.content.length > 400) {
        this.content = this.content.slice(0, 400);
      }
    },
    dataFromTagFriends(data) {
      this.invited_ids = data.invited_ids;
    },
    dataFromPrivacy(data) {
      this.privacy = data.privacy;
      this.privacychild = data.privacychild;
      this.custom_profile_ids = data.custom_profile_ids;
    },
    async saveQuestion() {
      this.isLoading = true;
      for (let k = 0; k < this.media.length; k++) {
        var vid = document.getElementById("aspect_video" + k);
        // returns the intrinsic width of the video
        this.width_video = vid.videoWidth;
        // returns the intrinsic height of the video
        this.height_video = vid.videoHeight;
        let storage = getStorage();
        let unique_number = uuid.v4() + this.media[k].name;
        let storageRef = ref(storage, "posts/questions/" + unique_number);
        let item = null;
        // Progress Bar
        if (this.media.length == 1) {
          const uploadTask = uploadBytesResumable(storageRef, this.media[k]);
          uploadTask.on("state_changed", (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.progressWidth = `${progress}`;
          });
        } else if (this.media.length > 1) {
          const uploadTask = uploadBytesResumable(storageRef, this.media[k]);
          uploadTask.on("state_changed", (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            this.progressWidthArray[k] = `${progress}`;
          });
        }
        // Progress Bar
        await uploadBytes(storageRef, this.media[k])
          .then((snapshot) => {
            let meme = snapshot.metadata.contentType.split("/");
            item = {
              size: snapshot.metadata.size,
              media_type: meme[0],
              mime_type: meme[1],
              width: this.width_video,
              height: this.height_video,
              fullPath: snapshot.metadata.fullPath,
            };
            this.datasender.push(item);
          })
          .catch((error) => {
            this.UPDATE_IS_LOADING_NEW_POST();
            console.log(error);
            this.$snotify.error("Network Error!", "Error");
          });
        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
          item.src_thum = url;
          item.src_icon = url;
        });
      }
      let payloadPost = {};
      if (!this.page) {
        payloadPost = {
          content: this.content,
          privacy: this.privacy,
        };
        payloadPost.media = this.datasender;
        if (this.invited_ids != null) {
          payloadPost.friends_ids = this.invited_ids;
        }
        if (this.privacy === "custom") {
          payloadPost.custom_profile_ids = this.custom_profile_ids;
        }
      } else {
        payloadPost = {
          page_id: this.page.id,
          content: this.content,
          privacy: this.privacy,
        };
        payloadPost.media = this.datasender;
        if (this.privacy === "custom") {
          payloadPost.custom_profile_ids = this.custom_profile_ids;
        }
      }
      questionsServices
        .saveQuestion(payloadPost)
        .then(() => {
          this.isLoading = false;
          this.addNewQuestion.hide();
          this.resetForm();
          this.$refs.privacyComponentRef.clearData();
          this.$refs.tagFriendsComponentRef.clearData();
        })
        .catch((error) => {
          if(error.graphQLErrors[0].message){
            this.$snotify.error(error.graphQLErrors[0].message, "Error");
          }
          if (
            error.graphQLErrors &&
            error.graphQLErrors.length > 0 &&
            error.graphQLErrors[0].extensions
          ) {
            const validationErrors =
              error.graphQLErrors[0].extensions.validation;
            if (validationErrors) {
              this.$snotify.error(this.$i18n.t("Verify all entries"), "Error");
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetForm() {
      this.content = "";
      this.media = [];
      this.postsmedia = [];
      this.datasender = [];
      this.privacy = "public";
      this.custom_profile_ids = [];
      this.progressWidth = 0;
      this.progressWidthArray = [];
    },

    ...mapMutations({
      UPDATE_IS_LOADING_NEW_POST: "post/UPDATE_IS_LOADING_NEW_POST",
      SET_NEW_POST_DATA: "post/SET_NEW_POST_DATA",
      SET_NEW_POST_PROFILE: "profile/SET_NEW_POST_DATA",
    }),
  },
};
</script>

<style lang="scss" scoped>
.text-secondary {
  color: #6c757d !important;
}
.text-danger {
  color: #dc3545 !important;
}
.upload-form {
  background-color: #fff;
  border: 2px dotted #ccc;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 100%;
}
.upload-form h6 {
  margin-bottom: 20px;
  color: #333;
}
.txt-media {
  font-size: 11px;
}
.upload-form label {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}
.upload-form label svg {
  margin-right: 10px;
}
.upload-form input[type="file"] {
  display: none;
}
.upload-form button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.upload-form button:hover {
  background-color: #45a049;
}
.input_text-field {
  width: 100% !important;
  border-radius: 25px;
  height: 100% !important;
  border: 1px solid #777;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;

  &:focus {
    outline: none;
  }
}
@media screen and (max-width: 299px) {
  .input_text {
    width: 100%;
    border-radius: 25px;
    height: 100% !important;
    border: 1px solid #777;
    padding: 0.4rem 1rem !important;
    font-weight: normal !important;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}
.privacy {
  background-color: #8f5fee;
  color: white;
  border: 1px solid #8f5fee;
  border-radius: 3px;
  cursor: pointer;
  font-size: smaller;
  padding: 2px 5px;
}
textarea:focus,
input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.2);
}
textarea::placeholder,
input::placeholder {
  color: #6c757d;
  font-size: 16px;
}

textarea {
  width: 100% !important;
  border: none;
  padding: 0.5rem 1rem;
  font-weight: bold;
  border-radius: 15px;
  height: auto;
  resize: none;
  transition: all 0.3s ease;
  &::placeholder {
    color: rgb(107, 106, 106) !important;
    font-size: 20px;
  }
}

.modal {
  background-color: rgba(255, 255, 255, 0.527);
  .modal-header {
    background: #e3e2e2;
  }
  .modal-content {
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

    .modal-body {
      label {
        font-weight: bold;
      }
    }
  }
}

.input-group {
  margin-bottom: 1rem;
}

.btn-special {
  background-color: #983baf;
  color: #fff;
}
.btn-special:hover {
  background-color: #6a1380;
  color: #fff;
}
.btn-custom {
  background-color: #77797c;
  color: #fff;
}

.btn-custom:hover {
  background-color: #77797c;
  color: #fff;
}
.add-new-answer {
  width: 50%;
}
.form-check-input:checked {
  background-color: #0dfd14;
  border-color: #0dfd14;
}
</style>
