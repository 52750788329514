<template>
  <div class="row bg-white me-0 mb-3">
    <div class="header">
      <svg
        style="cursor: pointer"
        @click="redirectToAllQuestions()"
        class="arrow-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <polyline points="15 18 9 12 15 6"></polyline>
      </svg>
      <h2>
        {{ $t("Questions that may interest you") }}
        <Question1Icon />
      </h2>
    </div>
    <swiper
      :pagination="{ clickable: true, el: '.swiper-pagination' }"
      :modules="modules"
      :grabCursor="true"
      :slidesPerView="1"
      :spaceBetween="30"
      :initialSlide="1"
      class="mySwiper"
    >
      <swiper-slide v-for="(question, index) in questions" :key="index">
        <div class="card">
          <div
            class="view-icon"
            :style="DefineDeriction() ? 'left:10px' : 'right:10px'"
          >
            <svg
              style="cursor: pointer"
              @click="viewQuestion(question.id)"
              title="view"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zm0 13c-3.03 0-5.5-2.47-5.5-5.5S8.97 6.5 12 6.5 17.5 8.97 17.5 12 15.03 17.5 12 17.5zm0-9c-1.93 0-3.5 1.57-3.5 3.5S10.07 15.5 12 15.5 15.5 13.93 15.5 12 13.93 8.5 12 8.5zm0 5.5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                fill="#000"
              />
            </svg>
          </div>
          <div
            class="user-info"
            :style="DefineDeriction() ? 'direction:rtl' : 'direction:ltr'"
          >
            <img crossorigin="anonymous" 
              v-if="!question.model.has_media_profile"
              src="@/assets/img_friends/default1.png"
              alt="User Image"
              class="user-image"
              :style="
                DefineDeriction() ? 'margin-left:10px' : 'margin-right:10px'
              "
            />
            <img crossorigin="anonymous" 
              v-else-if="question.model.media[0].collection_name === 'profile'"
              :src="question.model.media[0].src_url"
              alt="User Image"
              class="user-image"
              :style="
                DefineDeriction() ? 'margin-left:10px' : 'margin-right:10px'
              "
            />
            <img crossorigin="anonymous" 
              v-else-if="question.model.media[1].collection_name === 'profile'"
              :src="question.model.media[1].src_url"
              alt="User Image"
              class="user-image"
              :style="
                DefineDeriction() ? 'margin-left:10px' : 'margin-right:10px'
              "
            />
            <div>
              <h3>{{ question.model.name }}</h3>
              <small>
                <FriendsIcon v-if="question.privacy == 'friends'" />
                <PublicIcon v-if="question.privacy == 'public'" />
                <JustMeIcon v-if="question.privacy == 'just_me'" />
              </small>
              &nbsp;
              <small class="date" dir="auto">{{
                formatRelativeDate(question.created_at)
              }}</small>
            </div>
          </div>
          <p class="question-text" dir="auto">{{ question.content }}</p>
          <p class="answer-count" dir="auto">
            ({{ question.answersCount }}) {{ $t("answers") }}
          </p>
          <hr />
          <div class="actions">
            <div
              class="reaction-wrapper"
              @mouseleave="hideReactions(question.id)"
            >
              <button
                v-if="question.interacted == null"
                :class="[
                  'like-button',
                  { liked: question.interacted !== null },
                ]"
                @mouseover="showReactions(question.id)"
                @click="react(question.id, 7)"
              >
                <svg
                  class="like-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  :class="{ 'liked-icon': question.interacted !== null }"
                >
                  <path
                    d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                  ></path>
                </svg>
                {{
                  question.interactions_count ? question.interactions_count : 0
                }}
              </button>
              <button
                v-if="question.interacted != null"
                :class="[
                  'like-button',
                  { liked: question.interacted !== null },
                ]"
                @mouseover="showReactions(question.id)"
                @click="react(question.id, null)"
              >
                <!-- <svg
                  class="like-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  :class="{ 'liked-icon': question.interacted !== null }"
                >
                  <path
                    d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                  ></path>
                </svg> -->
                <svg
                  class="like-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  :class="{ 'liked-icon': false }"
                  v-if="question.interacted == null"
                >
                  <path
                    d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"
                  ></path>
                </svg>
                <LikeIcon v-if="question.interacted == 7" />
                <LoveIcon v-if="question.interacted == 1" />
                <AngryIcon v-if="question.interacted == 6" />
                <SadIcon v-if="question.interacted == 5" />
                <WowIcon v-if="question.interacted == 4" />
                <HahahaIcon v-if="question.interacted == 3" />
                <SupportReactionIcon v-if="question.interacted == 2" />
                {{
                  question.interactions_count ? question.interactions_count : 0
                }}
              </button>

              <div
                class="reaction-box"
                v-if="question.showReactions"
                @mouseover="keepReactionsVisible"
                @mouseleave="hideReactions(question.id)"
              >
                <button @click="react(question.id, 7)"><LikeIcon /></button>
                <button @click="react(question.id, 1)"><LoveIcon /></button>
                <button @click="react(question.id, 6)"><AngryIcon /></button>
                <button @click="react(question.id, 3)"><SadIcon /></button>
                <button @click="react(question.id, 4)"><WowIcon /></button>
                <button @click="react(question.id, 5)"><HahahaIcon /></button>
                <button @click="react(question.id, 2)">
                  <SupportReactionIcon />
                </button>
              </div>
            </div>
            <input
              type="text"
              class="answer-input"
              :placeholder="$t('Add your answer')"
              @keyup.enter="submitAnswer(question.id, $event)"
            />
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination"></div>
    </swiper>
  </div>
</template>

<script>
import SupportReactionIcon from "@/components/questions/icons/emojis/SupportReactionIcon.vue";
import HahahaIcon from "@/components/questions/icons/emojis/HahahaIcon.vue";
import SadIcon from "@/components/questions/icons/emojis/SadIcon.vue";
import AngryIcon from "@/components/questions/icons/emojis/AngryIcon.vue";
import WowIcon from "@/components/questions/icons/emojis/WowIcon.vue";
import LikeIcon from "@/components/questions/icons/emojis/LikeIcon.vue";
import LoveIcon from "@/components/questions/icons/emojis/LoveIcon.vue";
import postServices from "@/services/HomePage/post.services";
import Question1Icon from "@/components/questions/icons/Question1Icon.vue";
import FriendsIcon from "@/components/questions/icons/FriendsIcon.vue";
import PublicIcon from "@/components/questions/icons/PublicIcon.vue";
import JustMeIcon from "@/components/questions/icons/JustMeIcon.vue";
import questionsServices from "@/services/HomePage/Questions/questionsServices";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/pagination";
import LangMixin from "@/mixins/LangMixin";
import { EncryptionRoute } from "@/utils/EncryptionRoute";
export default {
  name: "QuestionsComponents",
  data() {
    return {
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : "",
      modules: [Pagination, Autoplay],
      isLoadingQuestion: false,
      questions: [],
      has_more_pages: false,
      isLoadingMoreQuestion: false,
      pageQuestion: 1,
    };
  },
  mixins: [LangMixin],
  created() {
    this.getAllQuestions();
  },

  methods: {
    viewQuestion(question_id) {
      this.$router.push({
        name: "shpwpost",
        params: { id: EncryptionRoute(question_id) },
      });
    },
    formatRelativeDate(dateString) {
      const date = new Date(dateString);
      const now = new Date();

      const diff = now - date;
      const seconds = Math.floor(diff / 1000);
      const minutes = Math.floor(seconds / 60);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);

      if (seconds < 60) {
        return this.$i18n.t("Moments ago");
      } else if (minutes < 60) {
        return `${this.$i18n.t("since")} ${minutes} ${
          minutes > 1 ? this.$i18n.t("minutes") : this.$i18n.t("minute")
        }`;
      } else if (hours < 24) {
        return `${this.$i18n.t("since")} ${hours} ${
          hours > 1 ? this.$i18n.t("hours") : this.$i18n.t("hour")
        }`;
      } else if (days < 2) {
        return this.$i18n.t("yesterday");
      } else if (days < 7) {
        return `${this.$i18n.t("since")} ${days} ${
          days > 1 ? this.$i18n.t("days") : this.$i18n.t("day")
        }`;
      } else {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        return date.toLocaleDateString("ar", options);
      }
    },
    redirectToAllQuestions() {
      this.$router.push({ name: "allQuestions" });
    },
    getAllQuestions() {
      this.pageQuestion = 1;
      let data = {
        page: this.pageQuestion,
      };
      this.isLoadingQuestion = true;
      questionsServices.allQuestions(data).then((res) => {
        this.has_more_pages = res.data.GetQuestions.has_more_pages;

        this.questions = res.data.GetQuestions.items;
        this.isLoadingQuestion = false;
      });
    },
    async LoadMoreQuestion() {
      this.pageQuestion = this.pageQuestion + 1;
      let data = {
        page: this.pageQuestion,
      };
      await questionsServices.allQuestions(data).then((res) => {
        const newQuestions = res.data.GetQuestions.items;
        this.questions.push(...newQuestions);
        this.has_more_pages = res.data.GetQuestions.has_more_pages;
      });
    },
    showReactions(questionId) {
      const question = this.questions.find((q) => q.id === questionId);
      if (question) {
        question.showReactions = true;
      }
    },
    keepReactionsVisible() {
      //  reactions visible
    },
    hideReactions(questionId) {
      const question = this.questions.find((q) => q.id === questionId);
      if (question) {
        question.showReactions = false;
      }
    },
    submitAnswer(questionId, event) {
      const answer = event.target.value;
      let answerData = {};
      if (!this.page) {
        answerData = {
          page_id: this.page.id,
          post_id: questionId,
          body: answer,
        };
      } else {
        answerData = {
          post_id: questionId,
          body: answer,
        };
      }
      questionsServices.saveAnswer(answerData).then(() => {
        event.target.value = "";
      });
    },
    react(questionId, reactionType) {
      postServices
        .InteractionWithPost(questionId, { type: reactionType })
        .then((response) => {
          if (response.data.data != null) {
            const payload = [questionId, response.data.data];
            this.questions.forEach((question) => {
              if (question.id === payload[0]) {
                if (payload[1] != null) {
                  if (question.interacted != null) {
                    question.interacted = payload[1].type;
                  } else {
                    question.interacted = payload[1].type;
                    question.interactions_count++;
                  }
                } else {
                  question.interacted = null;
                  question.interactions_count--;
                }
              }
            });
          } else {
            const payload = [questionId, null];
            this.questions.forEach((question) => {
              if (question.id === payload[0]) {
                if (payload[1] != null) {
                  if (question.interacted != null) {
                    question.interacted = payload[1].type;
                  } else {
                    question.interacted = payload[1].type;
                    question.interactions_count++;
                  }
                } else {
                  question.interacted = null;
                  question.interactions_count--;
                }
              }
            });
          }
        });
      // hide the reactions box after reacting
      const question = this.questions.find((q) => q.id === questionId);
      if (question) {
        question.showReactions = false;
      }
    },
  },
  components: {
    SupportReactionIcon,
    HahahaIcon,
    SadIcon,
    AngryIcon,
    WowIcon,
    LoveIcon,
    LikeIcon,
    Question1Icon,
    Swiper,
    SwiperSlide,
    FriendsIcon,
    PublicIcon,
    JustMeIcon,
  },
};
</script>

<style scoped>
.row.bg-white.me-0.mb-3 {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 3px;
}

.swiper-slide {
  height: 220px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

h2 {
  margin: 0;
  font-size: 15px;
  font-weight: bold;
}

.arrow-icon {
  width: 24px;
  height: 24px;
}

.mySwiper {
  height: 100%;
}

.card {
  display: grid;
  grid-template-rows: auto 1fr auto;
  align-items: start;
  height: 190px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.user-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* margin-right: 10px; */
}

h3 {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
}

.date {
  font-size: 12px;
  color: #888;
}

.question-text {
  margin: 0;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-height: 40px;
}

.answer-count {
  margin-top: 20px;
  font-size: 13px;
  color: #555;
  margin-bottom: 0px;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}

.reaction-wrapper {
  position: relative;
}

.like-button {
  background-color: #f0f0f0;
  color: #555;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.like-button:hover {
  background-color: #e0e0e0;
}

.like-icon {
  width: 16px;
  height: 16px;
}

.liked {
  background-color: #ffe0e0;
  border-color: #ffcccc;
  color: #e60000;
}

.liked-icon {
  stroke: #e60000;
  fill: #e60000;
}

.reaction-box {
  display: none;
  position: absolute;
  top: -50px;
  left: 200px;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: row;
  padding: 10px;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .reaction-box {
    top: -50px;
    left: 175%;
    transform: translateX(-50%);
    width: auto;
  }
}

html[dir="rtl"] .reaction-box {
  left: auto;
  right: 130%;
  transform: translateX(50%);
}

.reaction-box button {
  background: none;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  text-align: left;
}

.reaction-box button:hover {
  background-color: #f0f0f0;
}

.answer-input {
  flex-grow: 1;
  margin-left: 52px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 15px;
  font-size: 13px;
}
.answer-input::placeholder {
  padding: 5px;
}
.answer-input:focus {
  border-color: #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

@keyframes border-expand {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(1.05);
  }
}
.swiper-pagination {
  margin-top: 20px;
  text-align: center;
}

/* Display reaction box on like-button hover */
.reaction-wrapper:hover .reaction-box,
.reaction-box:hover {
  display: flex;
}

.view-icon {
  position: absolute;
  top: 10px;
}
</style>
