import postServices from "@/services/HomePage/post.services";

export default {
  namespaced: true,
  state: {
    postData: [],
    postDataAuth: [],
    isLoadingNewPost: null,
    isLoadingallPosts: false,
    interactionModel: null,
    page: 1,
    lastpage: null,
    finishedPost: false,
    showpost: [],
  },
  getters: {
    postData(state) {
      return state.postData;
    },
    postDataAuth(state) {
      return state.postDataAuth;
    },
  },

  mutations: {
     /************** Start vote on answer **********************************/
    DELETE_VOTE_ANSWER(state, payload) {
      if (state.postDataAuth && state.postDataAuth.length > 0) {
        const postIndex = state.postDataAuth.findIndex((item) => item.id == payload.post_id);
        if (postIndex !== -1) {
          const poll = state.postDataAuth[postIndex].poll;
          const answerIndex = poll.answers.findIndex((answer) => answer.id == payload.answer_id);
          if (answerIndex !== -1) {
            poll.answers.splice(answerIndex, 1);
          }
        }
      }
    },
    
    
    CHANGE_VOTE_ANSWER(state, payload) {
      if (state.postDataAuth && state.postDataAuth.length > 0) {
        const post = state.postDataAuth.find((item) => item.id == payload.post_id);
        if (post) {
          post.poll = payload.poll;
        }
      }
    },
     /************** End vote on answer **********************************/
    Initial_PostDataAuth(state) {
      state.postDataAuth = [];
    },
    Initial_PostDataGuest(state) {
      state.postData = [];
    },
    // update page to follow
    UPDATE_FOLLOWING(state, paload) {
      state.postDataAuth.forEach((item) => {
        if (item.model_type == "Page") {
          if (item.model.id == paload) {
            item.model.following = !item.model.following;
          }
        }
      });
    },
    // Increase the number of comments by one each time a new comment is added
    Increase_COUNT_COMMENT(state, payload) {
      const comment_index = state.postDataAuth.find(
        (item) => item.id == payload.commentable_id
      );
      comment_index.comments_count++;
    },
    // Decrease the number of comments by one each time a new comment is deleted
    Decrease_COUNT_COMMENT(state, payload) {
      const comment_index = state.postDataAuth.find(
        (item) => item.id == payload.commentable_id
      );
      comment_index.comments_count--;
    },
    // this post isn't saved
    UPDATE_STATUS_TO_SAVE_POST(state, payload) {
      const p = state.postDataAuth.find((item) => item.id == payload);
      p.saved = false;
    },
    // this post is saved
    UPDATE_STATUS_TO_CANCEL_SAVE_POST(state, payload) {
      const p = state.postDataAuth.find((item) => item.id == payload);
      p.saved = true;
    },
    REMOVE_ITEM(state, id) {
      const index = state.postDataAuth.findIndex((item) => item.id == id);
      state.postDataAuth.splice(index, 1);

      // state.postData.splice(index, 1);
    },
    async UPDATE_SHOW_POST(state, payload) {
      state.showpost = [];
      state.postDataAuth.forEach((post) => {
        if (post.id == payload) {
          state.showpost.push(post);
        }
      });
    },
    UPDATE_IS_LOADING_NEW_POST(state) {
      state.isLoadingNewPost = !state.isLoadingNewPost;
    },
    UPDATE_IS_LOADING_ALL_POSTS(state) {
      state.isLoadingallPosts = !state.isLoadingallPosts;
    },
    UPDATE_IS_MODAL_INTERACTION(state) {
      state.interactionModel = !state.interactionModel;
    },
    SET_POST_DATA(state, payload) {
      state.postData.push(payload);
    },
    SET_POST_DATA_USER_NEW(state, payload) {
      state.postDataAuth.push(payload);
    },
    SET_POST_DATA_USER(state, payload) {
      // let p = payload.filter((item) => item.__typename !== "GroupPostType");
      if (state.postDataAuth.length > 0) {
        payload.forEach((item) => {
          let index = state.postDataAuth.findIndex((x) => x.id == item.id);
          if (index >= 0) {
            state.postDataAuth[index].comments_count = item.comments_count;
            state.postDataAuth[index].content = item.content;
            state.postDataAuth[index].interactions_count =
              item.interactions_count;
            state.postDataAuth[index].shares_count = item.shares_count;
          } else {
            state.postDataAuth.unshift(item);
          }
        });
        // payload.forEach((item) => {
        //   let index = state.postDataAuth.findIndex((x) => x.id == item.id);
        //   if (index >= 0) {
        //     state.postDataAuth[index].comments_count = item.comments_count;
        //     state.postDataAuth[index].content = item.content;
        //     state.postDataAuth[index].interactions_count =
        //       item.interactions_count;
        //     state.postDataAuth[index].shares_count = item.shares_count;
        //   } else {
        //     state.postDataAuth.unshift(item);
        //   }
        // });
      } else {
        // let shuffledArray = p.slice();
        // for (let i = shuffledArray.length - 1; i > 0; i--) {
        //   const j = Math.floor(Math.random() * (i + 1));
        //   [shuffledArray[i], shuffledArray[j]] = [
        //     shuffledArray[j],
        //     shuffledArray[i],
        //   ];
        // }
        state.postDataAuth = payload;
      }
    },

    SET_NEW_POST_DATA(state, payload) {
      state.postDataAuth.unshift(payload);
    },
    UPDATE_POST_TO_LIKE(state, payload) {
      state.postDataAuth.forEach((post) => {
        if (post.id === payload[0]) {
          if (payload[1] != null) {
            if (post.interacted != null) {
              post.interacted = payload[1].type;
            } else {
              post.interacted = payload[1].type;
              post.interactions_count++;
            }
          } else {
            post.interacted = null;
            post.interactions_count--;
          }
        }
      });
    },
    INCREMENT_PAGE(state) {
      state.page++;
    },
    DELETE_POST_DATA(state, id) {
      const index = state.postDataAuth.findIndex((item) => item.id == id);
      state.postDataAuth.splice(index, 1);
    },
  },
  actions: {
    async GET_ALL_POSTS({ commit, state }, filters) {
      state.page = 1;
      state.lastpage = null;
      let user = JSON.parse(localStorage.getItem("user"));

      if (!user) {
        if (state.postData.length > 0) {
          return state.postData;
        } else {
          await postServices
            .getAllPosts(state.page, filters)
            .then((response) => {
              let result;
              if (
                filters.category === null &&
                filters.page_category_id != null
              ) {
                result = response.data.HomePageQuery;
              } else if (
                filters.category === "friends" 
              ) {
                result = response.data.HomePageQuery;
              } else if (
                filters.category === "forYou" || filters.category === "all"
              ) {
                result = response.data.SuggestedPostQuery;
              }  else {
                console.error("Invalid category:", filters.category);
                return;
              }

              state.last_page = result.last_page;
              state.postData = [];
              result.items.forEach((doc) => {
                commit("SET_POST_DATA", doc);
              });
            });
        }
      } else {
        state.finishedPost = false;
        await postServices
          .getAllPosts_with_filter(state.page, filters)
          .then((response) => {
            let result;
            if (filters.category === null && filters.page_category_id != null) {
              result = response.data.HomePageQuery;
            } else if (
              filters.category === "friends"
            ) {
              result = response.data.HomePageQuery;
            } else if (
              filters.category === "forYou" || filters.category === "all"
            ) {
              result = response.data.SuggestedPostQuery;
            } else {
              console.error("Invalid category:", filters.category);
              return;
            }

            state.last_page = result.last_page;
            // console.log(result.items)
            commit("SET_POST_DATA_USER", result.items);
          });
      }
    },

    async GET_ALL_NEW_POSTS({ commit, state }, filters) {
      await postServices
        .getAllPosts_with_filter(state.page, filters)
        .then((response) => {
          let result;
          if (filters.category === null && filters.page_category_id != null) {
            result = response.data.HomePageQuery;
          } else if (
            filters.category === "friends" 
          ) {
            result = response.data.HomePageQuery;
          } else if (
            filters.category === "forYou" || filters.category === "all"
          ) {
            result = response.data.SuggestedPostQuery;
          } 
          else {
            console.error("Invalid category:", filters.category);
            return;
          }
          state.last_page = result.last_page;
          result.items.forEach((doc) => {
            if (!state.postDataAuth.some((post) => post.id === doc.id)) {
              commit("SET_POST_DATA_USER_NEW", doc);
            }
          });
        });
    },
    async GET_ALL_NEW_POSTS_GUEST({ commit, state }, filters) {
      await postServices.getAllPosts(state.page, filters).then((response) => {
        // let result = response.data.data;
        let result;
        if (filters.category === null && filters.page_category_id != null) {
          result = response.data.HomePageQuery;
        } else if (
          filters.category === "friends"
        ) {
          result = response.data.HomePageQuery;
        } else if (
          filters.category === "forYou" || filters.category === "all"
        ) {
          result = response.data.SuggestedPostQuery;
        } else {
          console.error("Invalid category:", filters.category);
          return;
        }
        state.last_page = result.last_page;
        // state.postData = []
        result.items.forEach((doc) => {
          if (!state.postData.some((post) => post.id === doc.id)) {
            commit("SET_POST_DATA", doc);
          }
        });
      });
    },
    async GET_NEXT_USER({ commit, state, dispatch }, filters) {
      commit("INCREMENT_PAGE");
      if (state.page <= state.last_page) {
        state.finishedPost = false;
        await dispatch("GET_ALL_NEW_POSTS", filters);
      } else {
        state.finishedPost = true;
        state.page = 1;
      }
      // else {
      //   state.page = 1;
      //   await dispatch("GET_ALL_NEW_POSTS", filters);
      // }
    },
    async GET_NEXT({ commit, state, dispatch }, filters) {
      commit("INCREMENT_PAGE");
      if (state.page <= state.last_page) {
        await dispatch("GET_ALL_NEW_POSTS_GUEST", filters);
      } else {
        state.finishedPost = true;
        state.page = 1;
      }
    },
    async UPDATE_POST_TO_LIKE({ commit }, data) {
      await commit("UPDATE_POST_TO_LIKE", data);
    },
  },
};
