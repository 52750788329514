<template>
  <!-- Start loading component -->
  <LoadingComponent></LoadingComponent>
  <!-- End loading component -->
  <nav
    v-if="width > 992"
    class="navbar navbar-expand-lg position-sticky justify-content-center guestforlab"
  >
    <div class="container-fluid nonmargin row">
      <div
        class="row col-12 col-lg-3 col-md-12 col-sm-12 justify-content-between"
      >
        <div class="navbar-brand logo col-2">
          <router-link title="Guest Home" class="router-link-to-profile" to="/">
            <img crossorigin="anonymous"  src="@/assets/logo-edit.png" alt="" width="45" height="45" />
          </router-link>
        </div>

        <GlobalSearch></GlobalSearch>
        <button
          class="navbar-toggler togg col-2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarTogglerDemo03"
          aria-controls="navbarTogglerDemo03"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <div class="col-lg-3">
        <input
          type="text"
          v-model="email_or_phone"
          id="form2Example11"
          class="form-control font_Size"
          :placeholder="$t(`Email or Mobile Number`)"
          style="font-size: 14px;"
        />
      </div>
      <div class="col-lg-2">
        <input
          type="password"
          v-model="password"
          id="form2Example22"
          class="form-control font_Size"
          :placeholder="$t(`Password`)"
          @keyup.enter="loginUser"
          style="font-size: 14px;"
        />
      </div>
      <div class="col-lg-2">
        <div class="">
          <button
            @click="loginUser"
            v-if="!loading"
            class="btn custom_button"
            type="submit"
            :disabled="this.password === null || this.email_or_phone === null"
          >
            <span class="txt-buttom" style="color: white">{{
              $t("Login")
            }}</span>
          </button>
          <button
            v-else
            class="btn custom_button txt-buttom"
            style="color: white"
            type="submit"
          >
            {{ $t("Loading ...") }}
          </button>
        </div>
      </div>

      <div class="col-lg-1" style="width: 12%">
        <div
          class="collapse navbar-collapse navStar"
          ref="list"
          id="navbarTogglerDemo03"
        >
          <div class="d-flex align-items-center nav_end">
            <ul class="nav justify-content-end">
              <li class="nav-item">
                <div class="dropstart">
                  <a
                    class="dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      width="40"
                      height="40"
                      viewBox="0 0 54 54"
                    >
                      <defs>
                        <linearGradient
                          id="linear-gradient"
                          x1="0.5"
                          x2="0.5"
                          y2="1"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stop-color="#e8e8e8" />
                          <stop offset="1" stop-color="#b8b8b8" />
                        </linearGradient>
                      </defs>
                      <path
                        id="user"
                        d="M46.091,7.908A27,27,0,0,0,7.908,46.092,27,27,0,0,0,46.091,7.908Zm-35.3,36.555c2.276-6.778,9.5-9.1,16.2-9.1s12.175,1.983,16.2,9.1c-2.853,3.559-11.212,6.373-16.2,6.373S15.715,50.065,10.795,44.463Zm7.62-20.852A8.585,8.585,0,1,1,27,32.2,8.594,8.594,0,0,1,18.415,23.611ZM27,11.862Z"
                        transform="translate(0.001)"
                        fill="#a9a9a9"
                        stroke="#a9a9a9"
                      />
                    </svg>
                  </a>

                  <ul class="dropdown-menu">
                    <li>
                      <router-link
                        class="dropdown-item pro guest"
                        to="/auth/login"
                        title="Profile"
                        @click="close()"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path
                            d="M20.5,15.1a1,1,0,0,0-1.34.45A8,8,0,1,1,12,4a7.93,7.93,0,0,1,7.16,4.45,1,1,0,0,0,1.8-.9,10,10,0,1,0,0,8.9A1,1,0,0,0,20.5,15.1ZM21,11H11.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L11.41,13H21a1,1,0,0,0,0-2Z"
                          />
                        </svg>
                        {{ $t("Login") }}
                      </router-link>
                    </li>
                    <li>
                      <router-link
                        class="dropdown-item pro guest"
                        to="/auth/register"
                        title="Profile"
                        @click="close()"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                        >
                          <path
                            d="M20.5,15.1a1,1,0,0,0-1.34.45A8,8,0,1,1,12,4a7.93,7.93,0,0,1,7.16,4.45,1,1,0,0,0,1.8-.9,10,10,0,1,0,0,8.9A1,1,0,0,0,20.5,15.1ZM21,11H11.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L11.41,13H21a1,1,0,0,0,0-2Z"
                          />
                        </svg>
                        {{ $t("Register Now") }}
                      </router-link>
                    </li>
                    <div dir="auto" class="direction">
                      <li>
                        <div
                          id="language-picker-container"
                          class="d-inline-flex"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path fill="none" d="M0 0h24v24H0V0z" />
                            <path
                              d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"
                            />
                          </svg>
                          <div class="direction" dir="auto">
                            <select
                              class="form-select"
                              name="language-picker"
                              id="language-picker"
                              v-model="lang"
                              @change="handleChange($event)"
                            >
                              <option value="choose-one" disabled="true">
                                Select your language…
                              </option>
                              <option lang="ku" value="ku">الكردية</option>
                              <option lang="ar" value="ar">العربية</option>
                              <option lang="en" value="en">English</option>
                              <option lang="fr" value="fr">French</option>
                            </select>
                          </div>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <div class="memo_icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="35"
                    height="35"
                    viewBox="0 0 44 44"
                  >
                    <circle
                      id="Ellipse_19"
                      data-name="Ellipse 19"
                      cx="22"
                      cy="22"
                      r="22"
                      fill="#e1e1e1"
                    />
                  </svg>
                  <router-link
                    title="Guest Home"
                    class="router-link-to-profile"
                    to="/"
                  >
                    <svg
                      class="memo_svg"
                      id="MemoEmptyLogo"
                      xmlns="http://www.w3.org/2000/svg"
                      width="27.008"
                      height="20.228"
                      viewBox="0 0 27.008 20.228"
                    >
                      <rect
                        id="Rectangle_112"
                        data-name="Rectangle 112"
                        width="5.791"
                        height="21.807"
                        rx="2.895"
                        transform="matrix(0.753, 0.658, -0.658, 0.753, 14.356, 0)"
                        fill="#fff"
                      />
                      <rect
                        id="Rectangle_113"
                        data-name="Rectangle 113"
                        width="5.791"
                        height="17.936"
                        rx="2.895"
                        transform="translate(20.053 1.226)"
                        fill="#fff"
                      />
                      <rect
                        id="Rectangle_114"
                        data-name="Rectangle 114"
                        width="5.791"
                        height="21.807"
                        rx="2.895"
                        transform="matrix(0.753, 0.658, -0.658, 0.753, 22.649, 0.001)"
                        fill="#fff"
                      />
                    </svg>
                  </router-link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <nav
    v-else
    class="navbar navbar-expand-lg position-sticky justify-content-center guestformobile"
  >
    <div class="container">
      <div class="row w-100 d-flex justify-content-between align-items-center">
        <div class="col-3">
          <router-link class="navbar-brand" to="/">
            <img crossorigin="anonymous" 
              src="@/assets/logo-edit.png"
              alt=""
              width="40"
              height="40"
            />
          </router-link>
        </div>
        <div class="col-6">
          <GlobalSearch></GlobalSearch>
        </div>
        <div class="col-3 d-flex justify-content-end">
          <button
            class="navbar-toggler togg"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo033guest"
            aria-controls="navbarTogglerDemo033guest"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>

      <div
        class="row w-100 mt-4 d-flex justify-content-between align-items-center"
      >
        <div class="col-6">
          <router-link
            class="dropdown-item pro guest"
            to="auth/register"
            title="Profile"
            @click="close()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                d="M20.5,15.1a1,1,0,0,0-1.34.45A8,8,0,1,1,12,4a7.93,7.93,0,0,1,7.16,4.45,1,1,0,0,0,1.8-.9,10,10,0,1,0,0,8.9A1,1,0,0,0,20.5,15.1ZM21,11H11.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L11.41,13H21a1,1,0,0,0,0-2Z"
              />
            </svg>
            {{ $t("Register Now") }}
          </router-link>
        </div>
        <div class="col-6 text-end">
          <router-link
            class="dropdown-item pro guest"
            to="auth/login"
            title="Profile"
            @click="close()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                d="M20.5,15.1a1,1,0,0,0-1.34.45A8,8,0,1,1,12,4a7.93,7.93,0,0,1,7.16,4.45,1,1,0,0,0,1.8-.9,10,10,0,1,0,0,8.9A1,1,0,0,0,20.5,15.1ZM21,11H11.41l2.3-2.29a1,1,0,1,0-1.42-1.42l-4,4a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l4,4a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L11.41,13H21a1,1,0,0,0,0-2Z"
              />
            </svg>
            {{ $t("Login") }}
          </router-link>
        </div>
      </div>

      <div
        class="collapse navbar-collapse navbarTogglerDemo033guest"
        ref="navbarTogglerDemo033guest"
        id="navbarTogglerDemo033guest"
      >
        <div
          class="w-100 p-0 d-flex mb-3 justify-content-center align-items-center"
        >
          <div class="col-9">
            <GlobalSearch></GlobalSearch>
          </div>
          <div class="col-1 text-end">
            <button
              type="button"
              @click="closeit()"
              class="btn-close"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div @click="closeit()" class="w-100 px-3 d-flex mb-3">
          <router-link
            to="/setting"
            title="Settings"
            class="dropdown-item d-flex justify-content-start align-items-center"
            @click="close()"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="38"
              height="38"
              viewBox="0 0 33.327 31.327"
            >
              <g id="settings-svgrepo-com" transform="translate(0 -14.643)">
                <g
                  id="Group_2"
                  data-name="Group 2"
                  transform="translate(0 14.643)"
                >
                  <path
                    id="Path_8"
                    data-name="Path 8"
                    d="M266.911,243.89l-.089-1.052a.681.681,0,0,0-.738-.621l-1.031.089a6.424,6.424,0,0,0-1.209-2.349l.663-.785a.683.683,0,0,0-.082-.963l-.806-.683a.683.683,0,0,0-.963.082l-.656.779a6.412,6.412,0,0,0-2.527-.806l-.089-1a.681.681,0,0,0-.738-.621l-1.052.089a.681.681,0,0,0-.621.738l.089,1a6.409,6.409,0,0,0-2.363,1.223l-.765-.642a.683.683,0,0,0-.963.082l-.683.806a.683.683,0,0,0,.082.963l.765.642a6.351,6.351,0,0,0-.8,2.541l-1,.089a.681.681,0,0,0-.621.738l.089,1.052a.681.681,0,0,0,.738.621l1-.089a6.419,6.419,0,0,0,1.229,2.349l-.656.779a.683.683,0,0,0,.082.963l.806.683a.683.683,0,0,0,.963-.082l.663-.785a6.471,6.471,0,0,0,2.52.792l.089,1.031a.681.681,0,0,0,.738.622l1.052-.089a.681.681,0,0,0,.622-.738l-.089-1.038a6.489,6.489,0,0,0,2.336-1.216l.8.676a.683.683,0,0,0,.963-.082l.683-.806a.683.683,0,0,0-.082-.963l-.8-.676a6.418,6.418,0,0,0,.8-2.506l1.038-.089A.7.7,0,0,0,266.911,243.89Zm-5.409,2.431a3.524,3.524,0,1,1-.423-4.965A3.52,3.52,0,0,1,261.5,246.321Z"
                    transform="translate(-233.585 -220.836)"
                    fill="#a9a9a9"
                  />
                  <path
                    id="Path_9"
                    data-name="Path 9"
                    d="M17.214,34.016l-.656-1.27a8.934,8.934,0,0,0,2.356-2.814l1.373.437a.954.954,0,0,0,1.2-.622l.444-1.4a.954.954,0,0,0-.622-1.2l-1.38-.437a8.974,8.974,0,0,0-.314-3.647L20.9,22.4a.956.956,0,0,0,.41-1.284l-.676-1.3a.956.956,0,0,0-1.284-.41l-1.291.669a8.973,8.973,0,0,0-2.793-2.363l.437-1.38a.954.954,0,0,0-.622-1.2l-1.4-.444a.954.954,0,0,0-1.2.621l-.437,1.373a9.022,9.022,0,0,0-3.661.3l-.656-1.27a.956.956,0,0,0-1.284-.41l-1.3.676a.956.956,0,0,0-.41,1.284l.649,1.257a9.46,9.46,0,0,0-2.363,2.834L1.684,20.93a.954.954,0,0,0-1.2.621l-.444,1.4a.954.954,0,0,0,.621,1.2L2,24.57a9.039,9.039,0,0,0,.307,3.688L1.069,28.9a.956.956,0,0,0-.41,1.284l.676,1.3a.956.956,0,0,0,1.284.41l1.236-.642A9.1,9.1,0,0,0,6.69,33.64l-.423,1.332a.954.954,0,0,0,.622,1.2l1.4.444a.954.954,0,0,0,1.2-.621l.423-1.339a9,9,0,0,0,3.674-.328l.649,1.257a.956.956,0,0,0,1.284.41l1.3-.676A.981.981,0,0,0,17.214,34.016Zm-4.521-9.275a2.042,2.042,0,1,1-2.752-.874A2.039,2.039,0,0,1,12.693,24.741Zm-4.5-4.48a5.841,5.841,0,0,1,1.824-.6l-.082,1.864a4.356,4.356,0,0,0-1.018.376A4.283,4.283,0,0,0,6.7,26.592l-1.537.785A6.063,6.063,0,0,1,8.192,20.261Zm5.58,10.777a6.07,6.07,0,0,1-7.581-1.66l1.544-.8a4.261,4.261,0,0,0,6.017.266l1.6,1.018A6.249,6.249,0,0,1,13.772,31.038Zm1.182-4.091a4.271,4.271,0,0,0-2.78-5.334l.082-1.9a6.067,6.067,0,0,1,4.33,8.271Z"
                    transform="translate(0 -14.643)"
                    fill="#a9a9a9"
                  />
                </g>
              </g>
            </svg>

            <p class="m-0 px-3">{{ $t("Settings") }}</p>
          </router-link>
        </div>
        <div class="px-3 w-100 d-flex mb-3">
          <div id="language-picker-container" class="d-inline-flex">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="24"
              viewBox="0 0 24 24"
            >
              <path fill="none" d="M0 0h24v24H0V0z" />
              <path
                d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2s.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2s.07-1.35.16-2h4.68c.09.65.16 1.32.16 2s-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2s-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"
              />
            </svg>
            <div>
              <select
                class="form-select"
                name="language-picker"
                id="language-picker"
                v-model="lang"
                @change="handleChange($event)"
              >
                <option value="choose-one" disabled="true">
                  Select your language…
                </option>
                <option lang="ku" value="ku">الكردية</option>
                <option lang="ar" value="ar">العربية</option>
                <option lang="en" value="en">English</option>
                <option lang="fr" value="fr">French</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
import LoadingComponent from "@/views/authentication/LoadingComponent.vue";
import GlobalSearch from "@/components/OtherComponent/GlobalSearchComponent.vue";
import { mapActions } from "vuex";
import init from "@/services/Firebase/Firbase_init";
export default {
  name: "NavbarGuestComponent",
  data() {
    const lang = localStorage.getItem("lang") || "en";
    return {
      loading: null,
      lang: lang,
      email_or_phone: null,
      password: null,
      waiting: null,
      width: window.innerWidth,
    };
  },
  components: {
    GlobalSearch,
    LoadingComponent,
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  methods: {
    myEventHandler() {
      this.width = window.innerWidth;
    },
    closeit() {
      let item = this.$refs.navbarTogglerDemo033guest;
      item.classList.remove("show");
    },
    close() {
      let item = this.$refs.list;
      item.classList.remove("show");
    },
    handleChange(event) {
      localStorage.setItem("lang", event.target.value);
      window.location.reload();
    },
    ...mapActions({
      LOGIN_USER: "auth/LOGIN_USER",
      UPDATE_FCM_TOKEN: "auth/UPDATE_FCM_TOKEN",
    }),
    async loginUser() {
      this.$store.commit("TOGGLE_LOADING");
      this.errorMessage = [];
      const data = {
        email_or_phone: this.email_or_phone,
        password: this.password,
      };
      let res = await this.LOGIN_USER(data);
      if (res.errorCode === 1) {
        for (const message in res.message) {
          this.errorMessage.push(res.message[message]);
        }
        this.$store.commit("TOGGLE_LOADING");
        return;
      } else {
        this.$store.commit("TOGGLE_LOADING");
        this.$router.push({ name: "home" });
        init.subscribe();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.guestformobile {
  display: none;
}
nav {
  // overflow: hidden;
  .logo {
    width: auto;
  }
  z-index: 1055;
  top: 0px;
  .navStar {
    li {
      margin: 10px;
    }

    .nav_end li {
      margin: 5px;
    }

    .pro:lang(ar) {
      display: flex;
      flex-direction: row-reverse;
      svg {
        margin: 0 0 0 0.5rem !important;
      }
    }
  }

  .memo_icon {
    position: relative;
    .memo_svg {
      position: absolute;
      top: 50%;
      left: 45%;
      transform: translate(-50%, -50%);
    }
  }

  .dropstart .dropdown-toggle::before {
    display: none;
  }
  select:focus {
    box-shadow: none;
  }
  .style-mobile {
    margin-left: unset;
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .guestforlab {
    display: none !important;
  }
  .guestformobile {
    display: block !important;
    .navbar-toggler {
      margin-right: 10px;
      height: 38.4px;
      border-radius: 50%;
      padding: 0.2rem;
      border-color: $defaultColor;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .navbarTogglerDemo033guest {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      background: #f3f3f3;
      height: 100vh;
      overflow-y: auto;
    }
  }
}

a.dropdown-item.pro.guest {
  padding: 0px 0px 10px 0px;
}
button.btn.custom_button {
  background: $defaultColor;
}

.txt-buttom {
  font-size: small;
  font-weight: 600;
}
.direction:lang(ar) {
  direction: rtl;
}
.direction:lang(ku) {
  direction: rtl;
}
.dropstart .dropdown-menu {
  box-shadow: 0px 1px 10px 0px;
}
.form-select {
  background-image: none;
}
</style>
