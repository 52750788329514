<template>
    <!-- {{ data[0] }} -->
    <div class="row align-items-center mb-3 " v-for="friend in data" :key="friend.id">
        <div class="col-lg-9">
            <div class="d-flex justify-content-start align-content-start">
                <div>
                    <router-link class="router-link-to-profile" :to="{
                        name: 'homeMyProfile',
                        params: { id: EncryptionRoute(friend.user_id) },
                    }">
                        <span v-if="friend.has_media_profile === false">
                            <img crossorigin="anonymous"  c v-if="friend.gender == 0" class="logoimage" src="@/assets/img_friends/avatar_male.jpg"
                                loading="lazy" />
                            <img crossorigin="anonymous"  c v-if="friend.gender == 1" class="logoimage" src="@/assets/img_friends/avatar_female.jpg"
                                loading="lazy" />
                        </span>
                        <img crossorigin="anonymous"  c v-else-if="friend.has_media_profile === true &&
                            friend.media[0].collection_name === 'profile'
                            " class="logoimage" :src="friend.media[0].src_url" loading="lazy" />
                        <img crossorigin="anonymous"  c v-else-if="friend.has_media_profile === true &&
                            friend.media[1].collection_name === 'profile'
                            " class="logoimage" :src="friend.media[1].src_url" loading="lazy" />
                    </router-link>
                </div>
                <div :class="$i18n.locale == 'en' ? 'ms-3' : 'me-3'">
                    <router-link class="router-link-to-profile text-decoration-none text-black" :to="{
                        name: 'homeMyProfile',
                        params: { id: EncryptionRoute(friend.user_id) },
                    }">
                        <span>{{ friend.name }}</span>
                    </router-link>
                    <p class="mb-1 text-muted">
                        <span v-if="friend.mutualfriends_count > 0">
                            {{ friend.mutualfriends_count }} {{ $t('mutual friend') }}
                        </span>
                        <span  v-else>{{ $t('There are no mutual friends') }}</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <!-- {{ friend }} -->
            <button class="btn btn-primary d-flex align-items-center" style="background-color: white;" v-if="friend.is_friend == 1" disable>
                <svg fill="#8e4fff" width="20" height="20" viewBox="0 0 200 200" data-name="Layer 1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><title></title><path d="M100,15a85,85,0,1,0,85,85A84.93,84.93,0,0,0,100,15Zm0,150a65,65,0,1,1,65-65A64.87,64.87,0,0,1,100,165Zm25-91.5-29,35L76,94c-4.5-3.5-10.5-2.5-14,2s-2.5,10.5,2,14c6,4.5,12.5,9,18.5,13.5,4.5,3,8.5,7.5,14,8,1.5,0,3.5,0,5-1l3-3,22.5-27c4-5,8-9.5,12-14.5,3-4,4-9,.5-13L138,71.5c-3.5-2.5-9.5-2-13,2Z"></path></g>
                </svg>
                <span class="mx-1 fw-bold" style="color: #8e4fff;">{{ $t('Friend') }}</span>
            </button>
            <button @click="$router.push({ name : 'homeMyProfile' , params : { id: friend.id }})"  class="btn btn-primary text-white" style="background-color: #8e4fff;"  v-else>
                {{ $t("Add Friend") }}
            </button>
        </div>
    </div>
</template>

<script>
import {EncryptionRoute} from "@/utils/EncryptionRoute"

export default {
    name: "PeopleCartComponent",
    props: ["data"],
    methods: {
        EncryptionRoute
    }
}
</script>

<style lang="scss" scoped>

    .logoimage {
        width: 60px;
        height: 60px;
        border-radius: 50%;
    }
</style>