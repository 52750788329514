<template>
  <div
    class="modal fade"
    id="exampleModalToggleFriends"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel3"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title w-100" id="exampleModalToggleLabel3">
            {{ $t("Tag a friend on this post") }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="col-lg-12">
            <input
              type="text"
              v-model="input_search"
              class="form-control mb-4"
              :placeholder="$t('Search By Name')"
              id=""
            />
            <div v-if="input_search == ''">
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="v-pills-sent"
                  role="tabpanel"
                  aria-labelledby="v-pills-sent-tab"
                >
                  <div v-if="friends.length > 0">
                    <div v-for="item in friends" :key="item.id" class="mb-3">
                      <div
                        class="col-lg-12 d-flex justify-content-between align-items-center"
                      >
                        <div
                          class="d-flex justify-content-start align-items-center"
                        >
                          <img crossorigin="anonymous" 
                            height="30"
                            width="30"
                            v-if="item.has_media_profile === false"
                            src="@/assets/img_friends/default1.png"
                          />
                          <img crossorigin="anonymous" 
                            height="30"
                            width="30"
                            v-else-if="
                              item.media[0].collection_name === 'profile'
                            "
                            :src="item.media[0].src_url"
                            alt=""
                          />
                          <img crossorigin="anonymous" 
                            height="30"
                            width="30"
                            v-else-if="
                              item.media[1].collection_name === 'profile'
                            "
                            :src="item.media[1].src_url"
                            alt=""
                          />
                          <p class="mb-0 mx-3">{{ item.name }}</p>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            :checked="invited_ids.includes(item.id)"
                            type="checkbox"
                            @click="checked(item)"
                            :id="`flexCheckDefault${item.id}`"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-pane fade show"
                  id="v-pills-sented"
                  role="tabpanel"
                  aria-labelledby="v-pills-sented-tab"
                >
                  <div v-for="item in friends" :key="item.id" class="mb-3">
                    <div
                      class="col-lg-12 d-flex justify-content-between align-items-center"
                    >
                      <div
                        class="d-flex justify-content-start align-items-center"
                      >
                        <img crossorigin="anonymous" 
                          height="30"
                          width="30"
                          v-if="item.has_media_profile === false"
                          src="@/assets/img_friends/default1.png"
                        />
                        <img crossorigin="anonymous" 
                          height="30"
                          width="30"
                          v-else-if="
                            item.media[0].collection_name === 'profile'
                          "
                          :src="item.media[0].src_url"
                          alt=""
                        />
                        <img crossorigin="anonymous" 
                          height="30"
                          width="30"
                          v-else-if="
                            item.media[1].collection_name === 'profile'
                          "
                          :src="item.media[1].src_url"
                          alt=""
                        />
                        <p class="mb-0 mx-3">{{ item.name }}</p>
                      </div>
                      <div>
                        {{ item.page_status }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div v-if="waitingForSearch">
                <loader-search></loader-search>
              </div>
              <div v-else>
                <div class="list-search" v-if="SearchResult.length > 0">
                  <div v-for="item in SearchResult" :key="item.id" class="mb-3">
                    <div
                      class="col-lg-12 d-flex justify-content-between align-items-center"
                    >
                      <div
                        class="d-flex justify-content-start align-items-center"
                      >
                        <img crossorigin="anonymous" 
                          height="30"
                          width="30"
                          v-if="item.has_media_profile === false"
                          src="@/assets/img_friends/default1.png"
                        />
                        <img crossorigin="anonymous" 
                          height="30"
                          width="30"
                          v-else-if="
                            item.media[0].collection_name === 'profile'
                          "
                          :src="item.media[0].src_url"
                          alt=""
                        />
                        <img crossorigin="anonymous" 
                          height="30"
                          width="30"
                          v-else-if="
                            item.media[1].collection_name === 'profile'
                          "
                          :src="item.media[1].src_url"
                          alt=""
                        />
                        <p class="mb-0 mx-3">{{ item.name }}</p>
                      </div>
                      <div class="form-check" v-if="!item.invitedBefore">
                        <input
                          class="form-check-input"
                          :checked="invited_ids.includes(item.id)"
                          type="checkbox"
                          @click="checked(item)"
                          :id="`flexCheckDefault${item.id}`"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <p class="text-center text-danger">{{ $t("No_Result") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-custom"
            data-bs-toggle="modal"
            data-bs-target="#addNewPoll"
          >
            {{ $t("Back to the first page") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoaderSearch from "@/components/OtherComponent/LoaderSearch.vue";
import { mapGetters } from "vuex";
import tagFriendMixin from "@/mixins/tagFriendMixin";
export default {
  mixins: [tagFriendMixin],
  computed: {
    ...mapGetters({
      friends: "friendStore/friends",
      moreExists_Friends: "friendStore/moreExists_Friends",
      loading_more_friends: "friendStore/loading_more_friends",
    }),
  },
  components: {
    LoaderSearch,
  },
};
</script>
<style lang="scss" scoped>
@import "vue-select/dist/vue-select.css";
@import "floating-vue/dist/style.css";
@import "@/Styles/Comments/comment/addComment.scss";

.privacy {
  background-color: #8f5fee;
  color: white;
  border: 1px solid #8f5fee;
  border-radius: 3px;
  cursor: pointer;
  font-size: smaller;
  padding: 2px 5px;
}

.st0 {
  fill: #8f5fee;
}

.row {
  .add_post_card {
    padding: 1rem;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      object-fit: cover;
    }

    form {
      height: 100%;
      cursor: pointer;
      overflow: hidden;

      .input_text {
        width: 100%;
        border-radius: 10px;
        height: 100% !important;
        border: 1px solid #777;
        padding: 0.5rem 1rem;
        cursor: pointer;
        background-color: #f0f2f5;
        &:focus {
          outline: none;
        }
      }
    }
  }
  .modal {
    background-color: rgba(255, 255, 255, 0.527);
    .modal-content {
      border-radius: 10px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;

      .modal-header {
        .profile_image {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }
      }

      .modal-body {
        textarea {
          width: 100% !important;
          // background-color: #f0f2f5;
          // color: black !important;
          // border: 1px solid #d1caca;
          border: none;
          padding: 0.5rem 1rem;
          font-weight: bold;
          border-radius: 15px;
          height: auto;
          resize: none;

          &::placeholder {
            color: rgb(107, 106, 106) !important;
            font-size: 20px;
          }

          &:focus {
            outline: none;
            &::placeholder {
              color: transparent;
            }
          }
        }
        label {
          cursor: pointer;
          font-weight: bold;
          font-size: 1.5rem;

          // .icon {
          //   width: 25px;
          //   height: 25px;
          //   margin-left: 0.5rem;
          //   color: #242323;
          // }
        }
      }
    }
  }
}

.input_text-field {
  width: 100% !important;
  border-radius: 25px;
  height: 100% !important;
  border: 1px solid #777;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-top: 1rem;

  &:focus {
    outline: none;
  }
}

@media screen and (min-width: 300px) and (max-width: 550px) {
  .add_post_card {
    img {
      width: 25px !important;
      height: 25px !important;
      border-radius: 50%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 299px) {
  .add_post_card {
    img {
      display: none !important;
    }
  }

  .input_text {
    width: 100%;
    border-radius: 25px;
    height: 100% !important;
    border: 1px solid #777;
    padding: 0.4rem 1rem !important;
    font-weight: normal !important;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
}
.add_post_card:lang(ar) {
  direction: rtl;
}
.add_post_card:lang(ku) {
  direction: rtl;
}

#chosebetween {
  .modal-header:lang(ar) {
    flex-direction: row-reverse;

    .butt {
      text-align: start;
    }
  }
  h4 {
    cursor: pointer;
    border: 1px solid $defaultColor;
    padding: 0.5rem;
    border-radius: 4px;
  }

  .chose:lang(ar) {
    flex-direction: row-reverse;
  }
}

.row .modal .modal-content .modal-body label {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.5rem;
  font-size: 15px;
}
@media screen and (max-width: 655px) {
  .col-1 {
    width: 12%;
  }
  .col-11 {
    width: 88%;
  }
}

// Create New Post
p {
  font-weight: bold;
  font-size: 12px;
  color: #000000;
  margin-top: 13px;
}

.modal {
  background-color: rgba(255, 255, 255, 0.527) !important;
}
.modal-content {
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}
.modal-body {
  .card {
    cursor: pointer;
    background: linear-gradient(
      to right top,
      #5a86d8,
      #519fff,
      #4489dd,
      #4489dd,
      #4489dd,
      #3780d8,
      #3780d8,
      #3780d8,
      #3780d8,
      #2e74c9,
      #2e74c9,
      #2e74c9
    );
    height: 100%;

    .card-body {
      .bo {
        position: relative;
        border-radius: 100%;
        background: white;
        margin-top: 50%;
        padding: 30%;
        display: flex;
        justify-content: center;

        svg {
          font-size: x-large !important;
        }
      }
    }
  }
}
.close-dir:lang(ar) {
  direction: ltr;
}
.close-dir:lang(ku) {
  direction: ltr;
}
.card.cardQuote {
  background: radial-gradient(
    circle,
    rgba(146, 47, 182, 1) 0%,
    rgba(126, 42, 167, 1) 35%,
    rgba(192, 0, 255, 1) 100%
  );
}
.card.cardttop {
  background-image: linear-gradient(
    to right top,
    #fe9f4b,
    #ff9851,
    #ff9157,
    #fe8a5e,
    #fc8464,
    #fd7c69,
    #fc746f,
    #fb6d75,
    #fb627e,
    #f95788,
    #f54e93,
    #ee47a0
  );
}
.row2 {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 10px;
  background: #f0f2f5;
}
.col-images-upload {
  border: 1px solid rgb(221, 221, 221);
  border-radius: 10px;
  background: #ffffff;
}
.col-images-upload:hover {
  background: #d8d6d6;
}
.col-video-upload {
  border: 1px solid rgb(243, 242, 242);
  border-radius: 10px;
  background: #e7e3e3;
}
.col-video-upload:hover {
  background: #e2e0e0;
}
img.profile_image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

//
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.column1 {
  // flex: 1;
  padding: 10px 5px;
  text-align: left;
  // border: 1px solid #ccc;
}
.column2 {
  flex: 1;
  padding: 10px 5px;
  // text-align: right;
  // border: 1px solid #ccc;
}
.column2:lang(en) {
  flex: 1;
  padding: 10px 5px;
  text-align: right;
  // border: 1px solid #ccc;
}
.column2:lang(ar) {
  flex: 1;
  padding: 10px 5px;
  text-align: left;
  // border: 1px solid #ccc;
}

@media (max-width: 600px) {
  .container {
    flex-direction: column;
  }

  .column {
    width: 100%;
    margin-bottom: 10px;
  }
}
// style add more
.container-add-more {
  display: grid;
  width: 455px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 50px;
  grid-gap: 1rem;
}

.item1 {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}

.item {
  color: #1b1b1be6;
  border-radius: 4px;
  // border: 6px solid #171717;
  display: flex;
  // justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
}

.item.item1.m-2:hover {
  background: #ebe8e8;
}
// end style add more
.btn-custom {
  background: $defaultColor;
  color: #ffffff;
  font-weight: 600;
}
.form-check.form-switch {
  background: #efebeb;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}

.container.col-images-upload:lang(ar) {
  direction: rtl;
}
.container.col-images-upload:lang(ku) {
  direction: rtl;
}

.form-check-input {
  cursor: pointer;
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.btn-primary {
  background-color: #8f5fee !important;
  border-color: #8f5fee !important;
}
.nav-link {
  color: #8f5fee !important;
  border: 1px solid #8f5fee !important;
  background-color: white;
  padding: 0.2rem 0.5rem !important;
  margin: 0 1rem 1rem;

  &.active {
    background-color: #8f5fee !important;
    color: white !important;
  }
}

.form-control {
  &:hover {
    outline: none;
    box-shadow: none;
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
div#pills-tabContent {
  height: 196px;
  top: 64px;
  left: 0;
  bottom: 0;
  right: 0;
  background: snow;
  overflow-y: scroll;
  @include scrollBar;
}
.list-search {
  height: 196px;
  top: 64px;
  left: 0;
  bottom: 0;
  right: 0;
  background: snow;
  overflow-y: scroll;
  @include scrollBar;
}
.modal-body {
  @include scrollBar;
}
</style>
