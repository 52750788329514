import AuthenticationService from "@/services/Auth/authentication.services";
import ProfileService from "@/services/Profile/ProfileService";
import init from "@/services/Firebase/Firbase_init";
import { connectSocket } from '@/socket';


import {
  setAccessToken,
  getAccessToken,
  removeAccessToken,
} from "@/utils/cookieManager";
import UserStorage from "@/utils/UserStorage";
import Store from "@/store"
export default {
  namespaced: true,
  state: {
    CURRENT_TOKEN: getAccessToken() || null,
    CURRENT_USER: UserStorage.getUser() || null,
    lang: localStorage.getItem("lang") || "en",
    color: localStorage.getItem("theme-color") || "theme-white",
  },
  getters: {
    color(state) {
      return state.color;
    },
    USER(state) {
      return state.CURRENT_USER;
    },
    isLogged(state) {
      return !!state.CURRENT_TOKEN;
    },
    AUTHINTICATED(state) {
      return state.CURRENT_TOKEN && state.CURRENT_USER;
    },
    lang(state) {
      return state.lang;
    },
  },
  mutations: {
    // Update Points 
    Change_Silver_Gold_Point(state, payload){
      // console.log(state.CURRENT_USER) //point_silver point_gold 
      state.CURRENT_USER.profile.point_silver = payload.point_silver
      state.CURRENT_USER.profile.point_gold = payload.point_gold
      localStorage.setItem("user", JSON.stringify(state.CURRENT_USER));
      // console.log('state.CURRENT_USER.profile',state.CURRENT_USER.profile)
    },

    // update information my profile ex:"first name"-"last name"-"gender" ...
    UPDATE_INFO_PROFILE(state, payload) {
      state.CURRENT_USER = payload;
    },
    UPDATE_INFO_PROFILE_DATE_BIRTH(state, payload) {
      state.CURRENT_USER = payload;
      UserStorage.updateUserDateOfBirth(payload);
    },
    UPDATE_EMAIL_PROFILE(state, payload) {
      state.CURRENT_USER.email = payload;
    },
    UPDATE_PHONE_PROFILE(state, payload) {
      state.CURRENT_USER.phone = payload;
    },
    DELETE_PROFILE_COVER(state) {
      state.CURRENT_USER.profile.media.forEach((mediaItem) => {
        if (state.CURRENT_USER.has_media_cover == true) {
          if (mediaItem.collection_name == "cover") {
            state.CURRENT_USER.has_media_cover = false;
            const indexToRemove = state.CURRENT_USER.profile.media.findIndex(
              (item) => item.collection_name == "cover"
            );
            if (indexToRemove !== -1) {
              state.CURRENT_USER.profile.media.splice(indexToRemove, 1);
            }
          }
        }
      });
    },
    // delete my photo from profile
    DELETE_PROFILE_PHOTO(state) {
      state.CURRENT_USER.profile.media.forEach((mediaItem) => {
        if (state.CURRENT_USER.profile.has_media_profile == true) {
          state.CURRENT_USER.profile.has_media_profile = false;
          if (mediaItem.collection_name == "profile") {
            const indexToRemove = state.CURRENT_USER.profile.media.findIndex(
              (item) => item.collection_name == "profile"
            );
            if (indexToRemove !== -1) {
              state.CURRENT_USER.profile.media.splice(indexToRemove, 1);
            }
          }
        }
      });
    },

    UPDATE_PROFILE_COVER(state, payload) {
      state.CURRENT_USER.profile.media.forEach((mediaItem) => {
        if (state.CURRENT_USER.has_media_cover == true) {
          if (mediaItem.collection_name == "cover") {
            state.CURRENT_USER.has_media_cover = true;
            mediaItem.src_url = `${payload}&timestamp=${new Date().getTime()}`;
          }
        }
        if (state.CURRENT_USER.has_media_cover == false) {
          state.CURRENT_USER.has_media_cover = true;
          let mediaCover = {
            collection_name: "cover",
            fullPath: `cover/${payload}&timestamp=${new Date().getTime()}`,
            height: 600,
            media_type: "Image",
            mime_type: "png",
            model_type: "Profile",
            size: 201412,
            src_url: `${payload}&timestamp=${new Date().getTime()}`,
            width: 442,
          };
          state.CURRENT_USER.profile.media.push(mediaCover);
        }
      });
    },
    UPDATE_PROFILE_PHOTO_src_url(state, payload) {
      state.CURRENT_USER.has_media_profile = true;

      let profileMediaItem = state.CURRENT_USER.profile.media.find(
        (mediaItem) => mediaItem.collection_name === "profile"
      );

      if (!profileMediaItem) {
        profileMediaItem = {
          collection_name: "profile",
          src_url: `${payload}&timestamp=${new Date().getTime()}`,
        };
        state.CURRENT_USER.profile.media.push(profileMediaItem);
      } else {
        profileMediaItem.src_url = `${payload}&timestamp=${new Date().getTime()}`;
      }
    },
    UPDATE_PROFILE_PHOTO_src_thum(state, payload) {
      state.CURRENT_USER.has_media_profile = true;

      let profileMediaItem = state.CURRENT_USER.profile.media.find(
        (mediaItem) => mediaItem.collection_name === "profile"
      );

      if (!profileMediaItem) {
        profileMediaItem = {
          collection_name: "profile",
          src_url: `${payload}&timestamp=${new Date().getTime()}`,
        };
        state.CURRENT_USER.profile.media.push(profileMediaItem);
      } else {
        profileMediaItem.src_url = `${payload}&timestamp=${new Date().getTime()}`;
      }
    },
    changeColor(state, payload) {
      state.color = payload;
    },
    SET_TOKEN(state, payload) {
      setAccessToken(payload);
      state.CURRENT_TOKEN = payload;
    },
    SET_USER(state, payload) {
      state.CURRENT_USER = payload;
      localStorage.setItem("user", JSON.stringify(payload));
    },
    SET_LANG(state, payload) {
      state.lang = payload;
      if (!localStorage.getItem("lang")) {
        localStorage.setItem("lang", "en");
      } else {
        localStorage.setItem("lang", localStorage.getItem("lang"));
      }
    },
    UPDATE_LANG_USER(state, payload) {
      state.lang = payload.lang;
      state.CURRENT_USER.lang = payload.lang;
    },
    UPDATE_INFO_PROFILEFROMSETTING(state, payload) {
      state.CURRENT_USER = payload;
      localStorage.setItem("user", JSON.stringify(payload));
    },

    removeUserToken(state) {
      state.CURRENT_TOKEN = null;
      removeAccessToken();
      localStorage.removeItem("token");
      localStorage.removeItem("user");
    },
    logout(state) {
      state.CURRENT_TOKEN = null;
      state.CURRENT_USER = null;
      removeAccessToken();
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.pathname = "/";
    },
  },
  actions: {
    // function Login
    LOGIN_USER({ commit }, data) {
      return AuthenticationService.login(data).then(async (response) => {
        if (response.status === 200) {
          commit("SET_TOKEN", response.data.data.token);
          commit("SET_USER", response.data.data.user);
          commit("SET_LANG", localStorage.getItem("lang"));
          const socket = await connectSocket(response.data.data.token);
          Store.commit("setSocket", socket);
          socket.on('update_points', (res) => {
            Store.commit("auth/Change_Silver_Gold_Point", res.points )
          });
          
        }
        return response;
      });
    },
    // function Register
    REGISTER_USER({ commit }, data) {
      return AuthenticationService.register(data).then(async (response) => {
        if (response.status === 201) {
          commit("SET_TOKEN", response.data.data.token);
          commit("SET_USER", response.data.data.user);
          commit("SET_LANG", "en");
          const socket = await connectSocket(response.data.data.token);
          Store.commit("setSocket", socket);
          socket.on('update_points', (res) => {
            Store.commit("auth/Change_Silver_Gold_Point", res.points )
          });
        }
        return response;
      });
    },

    LOGIN_USER_OTHER_APP({ commit }, data) {
      return AuthenticationService.loginwithotherapp(data).then((response) => {
        if (response.status === 200) {
          commit("SET_TOKEN", response.data.data.token);
          commit("SET_USER", response.data.data.user);
          commit("SET_LANG", "en");
          init.subscribe();
          // this.$router.push({ name: "home" });
          window.location.pathname = "home";
        }
        if (response.status === 201) {
          commit("SET_TOKEN", response.data.data.token);
          commit("SET_USER", response.data.data.user);
          commit("SET_LANG", "en");
          window.location.pathname = "/complate-information";
          // this.$router.push({ name: "complate-register" });
        }
        return response;
      });
    },
    async a_destroy_user({ commit }, data) {
      return ProfileService.Destroy_User(data).then(() => {
        commit("logout");
        AuthenticationService.logout();
      });
    },
  },
};
