import gql from "graphql-tag";
import { Media_FRAGMENT } from "../../MediaData";
export const ALL_ANSWERS = gql`
  ${Media_FRAGMENT}
  query GetAnswers(
    $post_id: Int
    $topAnswers: Boolean
    $limit: Int
    $page: Int
  ) {
    GetAnswers(
      post_id: $post_id
      topAnswers: $topAnswers
      limit: $limit
      page: $page
    ) {
      total
      per_page
      current_page
      from
      to
      last_page
      has_more_pages
      items {
        id
        model_type
        model_id
        post_id
        body
        rate
        ratesCount
        reviewed
        created_at
        updated_at
        model {
          ... on PageType {
            id
            name
            has_media_profile
            has_media_cover
            has_role
            media {
              ...MediaData
            }
          }
          ... on ProfileType {
            id
            first_name
            middle_name
            last_name
            name
            has_media_profile
            has_media_cover
            media {
              ...MediaData
            }
          }
        }
      }
    }
  }
`;
