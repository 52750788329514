<template>
  <div class="iteraction-modal" @click="checkClick" ref="iteractiontWrap">
    <div class="Interaction">
      <div v-if="!isshow">
        <ul
          class="nav nav-tabs d-flex justify-content-between"
          id="myTab"
          role="tablist"
        >
          <!-- aLL Tab -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="all-tab"
              data-bs-toggle="tab"
              data-bs-target="#all"
              type="button"
              role="tab"
              aria-controls="all"
              aria-selected="true"
            >
              {{ $t("all") }} {{ interaction.length }}
            </button>
          </li>
          <!-- Like tab -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="like-tab"
              data-bs-toggle="tab"
              data-bs-target="#like"
              type="button"
              role="tab"
              aria-controls="like"
              aria-selected="true"
            >
              <img crossorigin="anonymous" 
                class="img-reaction"
                src="@/assets/reactions/like.gif"
                alt="Like emoji"
              />
              {{ likelength }}
            </button>
          </li>
          <!-- Love tab -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="love-tab"
              data-bs-toggle="tab"
              data-bs-target="#love"
              type="button"
              role="tab"
              aria-controls="love"
              aria-selected="false"
            >
              <img crossorigin="anonymous" 
                class="img-reaction"
                src="@/assets/reactions/love.gif"
                alt="Love emoji"
              />
              {{ lovelength }}
            </button>
          </li>
          <!-- angry tab -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="Angry-tab"
              data-bs-toggle="tab"
              data-bs-target="#Angry"
              type="button"
              role="tab"
              aria-controls="Angry"
              aria-selected="false"
            >
              <img crossorigin="anonymous" 
                class="img-reaction"
                src="@/assets/reactions/angry.gif"
                alt="Haha emoji"
              />

              {{ angrylength }}
            </button>
          </li>
          <!-- sad tab -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="Sad-tab"
              data-bs-toggle="tab"
              data-bs-target="#Sad"
              type="button"
              role="tab"
              aria-controls="Sad"
              aria-selected="false"
            >
              <img crossorigin="anonymous" 
                class="img-reaction"
                src="@/assets/reactions/sad.gif"
                alt="Sad emoji"
              />
              {{ sadlength }}
            </button>
          </li>
          <!-- haha tab -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="HaHaHa-tab"
              data-bs-toggle="tab"
              data-bs-target="#HaHaHa"
              type="button"
              role="tab"
              aria-controls="HaHaHa"
              aria-selected="false"
            >
              <img crossorigin="anonymous" 
                class="img-reaction"
                src="@/assets/reactions/haha.gif"
                alt="Wow emoji"
              />
              {{ hahahalength }}
            </button>
          </li>
          <!-- care tab -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="care-tab"
              data-bs-toggle="tab"
              data-bs-target="#care"
              type="button"
              role="tab"
              aria-controls="care"
              aria-selected="false"
            >
              <img crossorigin="anonymous" 
                class="care-img img-reaction"
                src="@/assets/reactions/care.gif"
                alt="Care emoji"
              />
              {{ carelength }}
            </button>
          </li>
          <!-- wow tab -->
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="wow-tab"
              data-bs-toggle="tab"
              data-bs-target="#wow"
              type="button"
              role="tab"
              aria-controls="wow"
              aria-selected="false"
            >
              <img crossorigin="anonymous" 
                class="img-reaction"
                src="@/assets/reactions/wow.gif"
                alt="Angry emoji"
              />
              {{ wowlength }}
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane overflow-auto fade show active"
            id="all"
            role="tabpanel"
            aria-labelledby="like-tab"
          >
            <div v-for="(item, index) in interaction" :key="index">
              <div class="mt-4">
                <h5>
                  <router-link
                    @click="hidemodal"
                    class="text-decoration-none"
                    :to="`/profile/${EncryptionRoute(item.user.id)}`"
                  >
                    <img crossorigin="anonymous" 
                      v-if="item.user.has_media_profile === false"
                      class="pd-image"
                      src="@/assets/img_friends/default1.png"
                      alt="User Profile Image"
                    />
                    <img crossorigin="anonymous" 
                      v-else-if="
                        item.user.has_media_profile === true &&
                        item.user.media[0].collection_name === 'profile'
                      "
                      class="pd-image"
                      :src="item.user.media[0].src_url"
                      alt="User Profile Image"
                    />
                    <img crossorigin="anonymous" 
                      v-else-if="
                        item.user.has_media_profile === true &&
                        item.user.media[1].collection_name === 'profile'
                      "
                      class="pd-image"
                      :src="item.user.media[1].src_url"
                      alt="User Profile Image"
                    />
                    {{ item.user.name }}
                  </router-link>
                  <LoveSvg v-if="item.type === 1"></LoveSvg>
                  <CareSvg v-else-if="item.type === 2"></CareSvg>
                  <HahaSvg v-else-if="item.type === 3"></HahaSvg>
                  <WowSvg v-else-if="item.type === 4"></WowSvg>
                  <SadSvg v-else-if="item.type === 5"></SadSvg>
                  <AngrySvg v-else-if="item.type === 6"></AngrySvg>
                  <LikeOn v-else-if="item.type === 7"></LikeOn>
                </h5>
              </div>
            </div>
            <div v-show="!this.interaction.length" class="text-center">
              <br />
              <b>{{ $t("Not Found any reaction yet") }}</b>
            </div>
          </div>
          <div
            class="tab-pane overflow-auto fade show"
            id="like"
            role="tabpanel"
            aria-labelledby="like-tab"
          >
            <div v-for="(item, index) in interaction" :key="index">
              <div v-if="item.type === 7" class="mt-4">
                <h5>
                  <img crossorigin="anonymous" 
                    v-if="item.user.has_media_profile === false"
                    class="pd-image"
                    src="@/assets/img_friends/default1.png"
                    alt="User Profile Image"
                  />
                  <img crossorigin="anonymous" 
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[0].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[0].src_url"
                    alt="User Profile Image"
                  />
                  <img crossorigin="anonymous" 
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[1].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[1].src_url"
                    alt="User Profile Image"
                  />
                  {{ item.user.name }}
                  <LikeOn></LikeOn>
                </h5>
              </div>
            </div>
          </div>
          <div
            class="tab-pane overflow-auto fade"
            id="love"
            role="tabpanel"
            aria-labelledby="love-tab"
          >
            <div v-for="(item, index) in interaction" :key="index">
              <div v-if="item.type === 1" class="mt-4">
                <h5>
                  <img crossorigin="anonymous" 
                    v-if="item.user.has_media_profile === false"
                    class="pd-image"
                    src="@/assets/img_friends/default1.png"
                    alt="User Profile Image"
                  />
                  <img crossorigin="anonymous" 
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[0].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[0].src_url"
                    alt="User Profile Image"
                  />
                  <img crossorigin="anonymous" 
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[1].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[1].src_url"
                    alt="User Profile Image"
                  />
                  {{ item.user.name }} <LoveSvg></LoveSvg>
                </h5>
              </div>
            </div>
          </div>
          <div
            class="tab-pane overflow-auto fade"
            id="Angry"
            role="tabpanel"
            aria-labelledby="Angry-tab"
          >
            <div v-for="(item, index) in interaction" :key="index">
              <div v-if="item.type === 6" class="mt-4">
                <h5>
                  <img crossorigin="anonymous" 
                    v-if="item.user.has_media_profile === false"
                    class="pd-image"
                    src="@/assets/img_friends/default1.png"
                    alt="User Profile Image"
                  />
                  <img crossorigin="anonymous" 
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[0].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[0].src_url"
                    alt="User Profile Image"
                  />
                  <img crossorigin="anonymous" 
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[1].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[1].src_url"
                    alt="User Profile Image"
                  />
                  {{ item.user.name }} <AngrySvg></AngrySvg>
                </h5>
              </div>
            </div>
          </div>
          <div
            class="tab-pane overflow-auto fade"
            id="Sad"
            role="tabpanel"
            aria-labelledby="Sad-tab"
          >
            <div v-for="(item, index) in interaction" :key="index">
              <div v-if="item.type === 5" class="mt-4">
                <h5>
                  <img crossorigin="anonymous" 
                    v-if="item.user.has_media_profile === false"
                    class="pd-image"
                    src="@/assets/img_friends/default1.png"
                    alt="User Profile Image"
                  />
                  <img crossorigin="anonymous" 
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[0].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[0].src_url"
                    alt="User Profile Image"
                  />
                  <img crossorigin="anonymous" 
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[1].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[1].src_url"
                    alt="User Profile Image"
                  />
                  {{ item.user.name }} <SadSvg></SadSvg>
                </h5>
              </div>
            </div>
          </div>
          <div
            class="tab-pane overflow-auto fade"
            id="HaHaHa"
            role="tabpanel"
            aria-labelledby="HaHaHa-tab"
          >
            <div v-for="(item, index) in interaction" :key="index">
              <div v-if="item.type === 3" class="mt-4">
                <h5>
                  <img crossorigin="anonymous" 
                    v-if="item.user.has_media_profile === false"
                    class="pd-image"
                    src="@/assets/img_friends/default1.png"
                    alt="User Profile Image"
                  />
                  <img crossorigin="anonymous" 
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[0].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[0].src_url"
                    alt="User Profile Image"
                  />
                  <img crossorigin="anonymous" 
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[1].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[1].src_url"
                    alt="User Profile Image"
                  />
                  {{ item.user.name }} <HahaSvg></HahaSvg>
                </h5>
              </div>
            </div>
          </div>
          <div
            class="tab-pane overflow-auto fade"
            id="care"
            role="tabpanel"
            aria-labelledby="HaHaHa-tab"
          >
            <div v-for="(item, index) in interaction" :key="index">
              <div v-if="item.type === 2" class="mt-4">
                <h5>
                  <img crossorigin="anonymous" 
                    v-if="item.user.has_media_profile === false"
                    class="pd-image"
                    src="@/assets/img_friends/default1.png"
                    alt="User Profile Image"
                  />
                  <img crossorigin="anonymous" 
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[0].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[0].src_url"
                    alt="User Profile Image"
                  />
                  <img crossorigin="anonymous" 
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[1].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[1].src_url"
                    alt="User Profile Image"
                  />
                  {{ item.user.name }} <CareSvg></CareSvg>
                </h5>
              </div>
            </div>
          </div>
          <div
            class="tab-pane overflow-auto fade"
            id="wow"
            role="tabpanel"
            aria-labelledby="HaHaHa-tab"
          >
            <div v-for="(item, index) in interaction" :key="index">
              <div v-if="item.type === 4" class="mt-4">
                <h5>
                  <img crossorigin="anonymous" 
                    v-if="item.user.has_media_profile === false"
                    class="pd-image"
                    src="@/assets/img_friends/default1.png"
                    alt="User Profile Image"
                  />
                  <img crossorigin="anonymous" 
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[0].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[0].src_url"
                    alt="User Profile Image"
                  />
                  <img crossorigin="anonymous" 
                    v-else-if="
                      item.user.has_media_profile === true &&
                      item.user.media[1].collection_name === 'profile'
                    "
                    class="pd-image"
                    :src="item.user.media[1].src_url"
                    alt="User Profile Image"
                  />
                  {{ item.user.name }} <WowSvg></WowSvg>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="justify-content-center d-flex align-items-center h-100"
      >
        <div class="spinner-border" role="status" style="z-index: 5555">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EncryptionRoute} from "@/utils/EncryptionRoute";
import { mapMutations } from "vuex";
import CommentPostService from "@/services/Comment/CommentPostService";
import LoveSvg from "@/icons/reactions/LoveSvg.vue";
import CareSvg from "@/icons/reactions/CareSvg.vue";
import SadSvg from "@/icons/reactions/SadSvg.vue";
import LikeOn from "@/icons/reactions/LikeOn.vue";
import HahaSvg from "@/icons/reactions/HahaSvg.vue";
import WowSvg from "@/icons/reactions/WowSvg.vue";
import AngrySvg from "@/icons/reactions/AngrySvg.vue";
export default {
  name: "InteractionReplyComment",
  props: ["id"],
  data() {
    return {
      interaction: [],
      isshow: null,
      likelength: 0,
      carelength: 0,
      wowlength: 0,
      angrylength: 0,
      sadlength: 0,
      hahahalength: 0,
      lovelength: 0,
    };
  },
  methods: {
    EncryptionRoute,
    ...mapMutations({
      UPDATE_IS_MODAL_INTERACTION: "comment/UPDATE_IS_MODAL_INTERACTION",
    }),
    checkClick(e) {
      if (e.target === this.$refs.iteractiontWrap) {
        this.UPDATE_IS_MODAL_INTERACTION();
        document.documentElement.style.overflow = "auto";
        this.$emit("change");
      }
    },
    hidemodal(){
      this.UPDATE_IS_MODAL_INTERACTION();
        document.documentElement.style.overflow = "auto";
        this.$emit("change");
    },

    async getiteracion(id, data) {
      let res = "";
      try {
        res = await CommentPostService.GetAllInteractionWithComment(id, {
          params: {
            type: data,
          }
        });
        // console.log(res.data.data.items)
        res.data.data.items.forEach((item) => {
          const data = {
            id: item.id,
            type: item.type,
            user: item.model,
          };
          if (data.type === 7) {
            this.likelength += 1;
          }
          if (data.type === 1) {
            this.lovelength += 1;
          }
          if (data.type === 2) {
            this.carelength += 1;
          }
          if (data.type === 3) {
            this.hahahalength += 1;
          }
          if (data.type === 4) {
            this.wowlength += 1;
          }
          if (data.type === 5) {
            this.sadlength += 1;
          }
          if (data.type === 6) {
            this.angrylength += 1;
          }
          this.interaction.push(data);
        });
        this.isshow = null;
      } catch (err) {
        // console.log(err.response.data);
      }
    },
  },
  created() {
    this.getiteracion(this.id, 0);

    this.isshow = true;
  },
  components: { LoveSvg, CareSvg, SadSvg, LikeOn, HahaSvg, WowSvg, AngrySvg },
};
</script>

<style lang="scss" scoped>
@import "@/Styles/Comments/comment/interactions.scss";
// @import "@/Styles/Comments/reply/interactionReply.scss";
.nav-link {
  padding: 0;
}
</style>
