<template>
    <div class="card">
        <div class="card-body">
            <div class="media align-items-center">
                <span class="avatar avatar-xl mr-3">
                    <router-link class="router-link-to-profile" :to="{
                        name: 'homeSinglePage',
                        params: { id: item.id },
                    }">
                        <img crossorigin="anonymous"  v-if="item.has_media_cover == false" src="@/assets/page/pagecover.png"
                            class="card-img-top" />
                        <img crossorigin="anonymous"  v-else-if="item.media[0].collection_name == 'cover'" :src="item.media[0].src_url"
                            class="card-img-top" />
                        <img crossorigin="anonymous"  v-else-if="item.media[1].collection_name == 'cover'" :src="item.media[1].src_url"
                            class="card-img-top" />
                    </router-link>
                </span>
                <div class="row">
                    <div class="col-1 m-2">
                        <router-link class="router-link-to-profile" :to="{
                            name: 'homeSinglePage',
                            params: { id: item.id },
                        }">
                            <img crossorigin="anonymous"  v-if="item.has_media_profile === false" src="@/assets/story_img/user.png"
                                class="img-profile" />
                            <img crossorigin="anonymous"  class="img-profile" v-else-if="item.media[0].collection_name === 'profile'"
                                :src="item.media[0].src_url" alt="" />
                            <img crossorigin="anonymous"  class="img-profile" v-else-if="item.media[1].collection_name === 'profile'"
                                :src="item.media[1].src_url" alt="" />
                        </router-link>
                    </div>
                    <div class="col-10">
                        <div class="media-body overflow-hidden">
                            <router-link class="router-link-to-profile" :to="{
                                name: 'homeSinglePage',
                                params: { id: item.id },
                            }">
                                <h6 class="card-text custom_lo">
                                    {{ uppercaseFirst(item.name) }}
                                </h6>
                            </router-link>
                            <span class="card-text custom_date m-2" v-for="(cat, index) in item.categories.slice(0, 3)"
                                :key="index" style="font-weight: 700; color: #ababab">
                                {{ cat.name }} &nbsp;
                            </span>
                            <br />
                            <span class="card-text custom_date text-muted m-2"><small class="text-muted">{{
                                    item.fans_count }} {{ $t("member") }}</small></span>
                            &nbsp;
                            <span class="card-text custom_date text-muted"><small class="text-muted">{{
                                    item.followers_count }} {{ $t("Followers") }}</small></span>
                        </div>
                    </div>
                </div>
                <!-- button like -->
                <div class="card-footer">
                    <div class="row">
                    <button class="custom-btn btn-2" @click="Like(item.id)">
                        <span v-if="!item.liked">
                        <!-- display icon like -->
                        <svgLike></svgLike>
                        &nbsp;
                        </span>
                        <span v-if="!item.liked"> {{ $t("Like") }}</span>
                    </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import svgLike from "@/components/PageComponent/HomePages/components/pageCard/svgLike.vue";
import GeneralPagesService from "@/services/Pages/GeneralPageServices";

import { lazyLoadCoverMixin } from "@/mixins/lazyLoadCoverMixin";
import { mapMutations } from "vuex";
export default {
    props: ["item"],
    mixins: [lazyLoadCoverMixin],
    methods: {
        uppercaseFirst(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        ...mapMutations({
      UPDATE_PAGE_TO_LIKE: "generalpages/UPDATE_PAGE_TO_LIKE",
      UPDATE_PAGE_TO_LIKE_IN_LIKED_PAGES:
        "generalpages/UPDATE_PAGE_TO_LIKE_IN_LIKED_PAGES",
        UPDATE_PAGE_TO_LIKE_IN_MY_PAGES:
        "generalpages/UPDATE_PAGE_TO_LIKE_IN_MY_PAGES",

    }),
    async Like(id) {
      await GeneralPagesService.Like_Specific_Page(id).then(() => {
        this.UPDATE_PAGE_TO_LIKE(id);
        this.UPDATE_PAGE_TO_LIKE_IN_LIKED_PAGES(id);
        this.UPDATE_PAGE_TO_LIKE_IN_MY_PAGES(id);
        this.$emit('removefrompagessuggested', this.item.id)
      });
    },
    },
    components: {
        svgLike
    },
};
</script>

<style lang="scss" scoped>
.router-link-to-profile {
  text-decoration: none;
  color: #111;
}

.card-img-top {
  height: 100px;
  width: 100%;
  object-fit: cover;
}

.avatar.avatar-xl {
  width: 100%;
  height: 100%;
}
.avatar {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 5%;
  display: inline-block;
  background: #ced4da no-repeat center/cover;
  position: relative;
  text-align: center;
  color: #868e96;
  font-weight: 600;
  vertical-align: bottom;
}
.custom_lo {
  margin: 5px 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.custom_date {
  font-size: smaller;
}

.card {
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1),
    -1px 0 2px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1), -1px 0 2px rgba(0, 0, 0, 0.05);
}
.card-body {
  padding: 0rem;
}
.special_btn {
  background-color: #983baf;
  color: white;
  cursor: pointer;
  border: none;
  padding: 0.4rem 1rem;
  border-radius: 5px;

  &:hover {
    background-color: #983baf;
    color: white;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    background-color: #983baf;
    color: white;
  }
}
.img-profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.card-footer {
  background-color: white;
  border-top: none;
}

.custom-btn {
  width: 100%;
  height: 35px;
  color: #fff;
  border-radius: 5px;
  padding: 8px 25px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
}
/* 2 */
.btn-2 {
  background: rgb(82, 5, 224);
  background: linear-gradient(0deg, $defaultColor 0%, $defaultColor 100%);
  border: none;
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.5),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.4);
}
.btn-2:before {
  height: 0%;
  width: 2px;
}
</style>