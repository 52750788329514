<template>
  <img crossorigin="anonymous" 
    v-if="!question.model.has_media_profile"
    src="@/assets/img_friends/default1.png"
    alt="User Image"
    class="user-image"
    :style="DefineDeriction() ? 'margin-left:10px' : 'margin-right:10px'"
  />
  <img crossorigin="anonymous" 
    v-else-if="question.model.media[0].collection_name === 'profile'"
    :src="question.model.media[0].src_url"
    alt="User Image"
    class="user-image"
    :style="DefineDeriction() ? 'margin-left:10px' : 'margin-right:10px'"
  />
  <img crossorigin="anonymous" 
    v-else-if="question.model.media[1].collection_name === 'profile'"
    :src="question.model.media[1].src_url"
    alt="User Image"
    class="user-image"
    :style="DefineDeriction() ? 'margin-left:10px' : 'margin-right:10px'"
  />
</template>

<script>
import LangMixin from "@/mixins/LangMixin";
export default {
  props: ["question"],
  mixins: [LangMixin],
};
</script>

<style lang="scss" scoped>
.user-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  // margin-right: 10px;
}
</style>
