<template>
  <img crossorigin="anonymous" 
    v-if="post.model.cover === null"
    class="cover-img cover-container"
    src="@/assets/groups/groups-default-cover-photo.jpg"
    alt=""
  />
  <img crossorigin="anonymous"  v-else :src="post.model.cover.src_url" alt="" />
  <div v-if="post.anonymous == true">
    <span v-if="singleGroup.has_role == false">
      <img crossorigin="anonymous" 
        class="profile-user"
        src="@/assets/cover/anonymous_member.jpg"
        alt=""
      />
    </span>
    <span v-if="singleGroup.has_role == true">
      <img crossorigin="anonymous" 
        class="profile-user"
        v-if="post.profile.has_media_profile === false"
        src="@/assets/story_img/user.png"
        alt=""
      />
      <div v-if="post.profile.has_media_profile === true">
        <img crossorigin="anonymous" 
          class="profile-user"
          v-if="post.profile.media[0].collection_name === 'profile'"
          :src="post.profile.media[0].src_url"
          alt=""
        />
        <img crossorigin="anonymous" 
          class="profile-user"
          v-else
          :src="post.profile.media[1].src_url"
          alt=""
        />
      </div>
    </span>
  </div>
  <div v-if="post.anonymous == false">
    <img crossorigin="anonymous" 
      class="profile-user"
      v-if="post.profile.has_media_profile === false"
      src="@/assets/story_img/user.png"
      alt=""
    />
    <div v-else>
      <img crossorigin="anonymous" 
        class="profile-user"
        v-if="post.profile.media[0].collection_name === 'profile'"
        :src="post.profile.media[0].src_url"
        alt=""
      />
      <img crossorigin="anonymous" 
        class="profile-user"
        v-else
        :src="post.profile.media[1].src_url"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["post"],
  computed: {
    ...mapGetters({
      singleGroup: "singleGroup/singleGroup",
    }),
  },
};
</script>
<style lang="scss" scoped>
img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
@media screen and (max-width: 992px) {
  img {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
  }
}
.profile-user {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin: 0px -22px -22px -11px;
}
</style>
