<template>
  <div class="div">
    <div class="row mb-3 me-0 bg-white">
      <div class="card card_post" v-if="post.survey != null">
        <div
          class="card-header bg-white border-bottom-0 d-flex justify-content-between"
        >
          <div class="d-flex align-items-center">
            <div>
              <img crossorigin="anonymous" 
                v-if="post.profile.has_media_profile === false"
                src="@/assets/img_friends/default1.png"
                alt=""
              />
              <div v-else>
                <img crossorigin="anonymous" 
                  v-if="
                    post.profile.media[0].collection_name === 'profile'
                  "
                  :src="post.profile.media[0].src_url"
                  alt=""
                />
                <img crossorigin="anonymous" 
                  v-else
                  :src="post.profile.media[1].src_url"
                  alt=""
                />
              </div>
            </div>
            <div :class="!DefineDeriction() ? 'mx-3' : 'me-3'">
              <p class="mb-0">
                <router-link
                  class="router-link-to-profile"
                  :to="{
                    name: 'userGroupProfile',
                    params: {
                      id: post.group_id,
                      user_id: post.profile.user_id,
                    },
                  }"
                  >{{ post.profile.first_name }}
                  {{ post.profile.last_name }}</router-link
                >
              </p>
              <small class="text-muted">
                {{ format_date(post.created_at) }}
              </small>
            </div>
          </div>
          <div class="d-flex align-items-center right">
            <div :class="DefineDeriction() ? 'mx-3' : 'me-3'" style="cursor: pointer" @click="delete_post(post.id)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.147"
                height="15.147"
                viewBox="0 0 15.147 15.147"
              >
                <g
                  id="Group_184"
                  data-name="Group 184"
                  transform="translate(7.573 -5.632) rotate(45)"
                >
                  <rect
                    id="Rectangle_151"
                    data-name="Rectangle 151"
                    width="18.675"
                    height="2.746"
                    rx="1.373"
                    transform="translate(0 7.964)"
                    fill="#a5a5a5"
                  />
                  <rect
                    id="Rectangle_152"
                    data-name="Rectangle 152"
                    width="18.675"
                    height="2.746"
                    rx="1.373"
                    transform="translate(10.71 0) rotate(90)"
                    fill="#a5a5a5"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div class="card-body mx-3">
          <p v-if="post.content" class="card-text" dir="auto">
            {{ post.content }}
          </p>
          <div v-if="post.has_media" class="media_container">
            <div class="media_one" v-if="post.media.length === 1">
              <img crossorigin="anonymous" 
                v-if="post.media[0].media_type.includes('Image')"
                :src="post.media[0].src_url"
                alt=""
                v-viewer
              />
              <router-link
                v-else-if="post.media[0].media_type.includes('Video')"
                :to="{
                  name: 'showGroupPost',
                  params: { postid: post.id },
                }"
              >
                <video crossorigin="anonymous"  controls :src="post.media[0].src_url"></video>
              </router-link>
              <a
                v-else
                :href="post.media[0].src_url"
                download="download"
                class="style-pdf"
                target="_blank"
              >
                <div
                  class="div"
                  style="
                    max-height: 100% !important;
                    width: 100% !important;
                    height: 100%;
                    overflow: hidden;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    border: none;
                    background-color: #ffffff;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px -2px 3px -2px inset,
                      rgba(0, 0, 0, 0.3) 0px 6px 29px -16px inset;
                    border-radius: 2px;
                    /* width: 100%; */
                    padding: 22px 25px 25px 55px;
                    /* margin: 8px 0px; */
                    border: 0px solid;
                  "
                >
                  <div class="one">
                    <PdfSvg></PdfSvg>
                  </div>
                  <div class="two">
                    <span style="font-weight: 500">
                      &nbsp; {{ post.media[0].fullPath.substr(12) + "" }}
                    </span>
                  </div>
                  <div class="three">
                    <DownloadSvg></DownloadSvg>
                  </div>
                </div>
              </a>
            </div>
            <router-link
              :to="{
                name: 'shpwpost',
                params: { id: post.id },
              }"
            >
              <div
                class="media_two d-flex justify-content-between align-items-center"
                v-if="post.media.length === 2"
              >
                <div class="media_two_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
                </div>
                <div class="media_two_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[1].media_type.includes('Image')"
                    :src="post.media[1].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[1].src_url"></video>
                </div>
              </div>
              <div class="media_three" v-if="post.media.length === 3">
                <div class="media_three_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
                </div>
                <div class="media_three_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[1].media_type.includes('Image')"
                    :src="post.media[1].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[1].src_url"></video>
                </div>
                <div class="media_three_box_under">
                  <img crossorigin="anonymous" 
                    v-if="post.media[2].media_type.includes('Image')"
                    :src="post.media[2].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[2].src_url"></video>
                </div>
              </div>
              <div class="media_four" v-if="post.media.length === 4">
                <div class="media_four_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
                </div>
                <div class="media_four_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[1].media_type.includes('Image')"
                    :src="post.media[1].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[1].src_url"></video>
                </div>
                <div class="media_four_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[2].media_type.includes('Image')"
                    :src="post.media[2].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[2].src_url"></video>
                </div>
                <div class="media_four_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[3].media_type.includes('Image')"
                    :src="post.media[3].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[3].src_url"></video>
                </div>
              </div>
              <div class="media_bigger" v-if="post.media.length > 4">
                <div class="media_bigger_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
                </div>
                <div class="media_bigger_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[1].media_type.includes('Image')"
                    :src="post.media[1].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[1].src_url"></video>
                </div>
                <div class="media_bigger_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[2].media_type.includes('Image')"
                    :src="post.media[2].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[2].src_url"></video>
                </div>
                <div class="media_bigger_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[3].media_type.includes('Image')"
                    :src="post.media[3].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[3].src_url"></video>
                </div>
                <p
                  class="bigger_p d-flex align-items-center justify-content-center"
                >
                  <span>+{{ post.media.length - 4 }}</span>
                </p>
              </div>
            </router-link>
          </div>
          <div v-if="post.survey.is_active == 1" class="mt-4">
            <div class="content" v-if="post.survey.multiple_votes == 1">
              <fieldset>
                <legend class="text-center">{{ post.survey.content }}</legend>
                <div class="container">
                  <ul>
                    <li v-for="(s, index) in post.survey.options" :key="index">
                      <input
                        type="checkbox"
                        :id="`checkbox2` + s.id"
                        name="checkbox01"
                        :checked="s.user_has_voted"
                        @click="check_vote(s.id)"
                      />

                      <label :for="`checkbox2` + s.id">{{ s.content }} </label>
                      <span class="show-voters vote">
                        <span
                          v-for="(voter, index) in s.last_three_voters"
                          :key="index"
                        >
                          <img crossorigin="anonymous" 
                            @click="get_voters(s.id)"
                            :data-bs-target="`#showVotersToOPtion` + s.id"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            width="30"
                            height="30"
                            :src="voter.photoURL"
                            loading="lazy"
                            :title="voter.first_name + ' ' + voter.last_name"
                            style="cursor: pointer"
                          />
                        </span>
                        &nbsp;
                        <span
                          style="cursor: pointer"
                          @click="get_voters(s.id)"
                          :data-bs-target="`#showVotersToOPtion` + s.id"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          >{{ s.votes_count }}</span
                        >
                        <!-- modal voters to option -->
                        <div
                          class="modal fade"
                          :id="`showVotersToOPtion` + s.id"
                          aria-hidden="true"
                          aria-labelledby="showVotersToOPtionToggleLabel2"
                          tabindex="-1"
                        >
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5
                                  class="modal-title"
                                  id="showVotersToOPtionToggleLabel2"
                                >
                                  {{ $t("Voters on the option") }}
                                </h5>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div
                                class="modal-body"
                                v-if="this.loading_voters == false"
                              >
                                <div
                                class="mt-1"
                                  v-for="(v, index) in all_voters"
                                  :key="index"
                                >
                                  <img crossorigin="anonymous" 
                                    v-if="v.voter.has_media_profile === false"
                                    class="pd-image"
                                    src="@/assets/img_friends/default1.png"
                                    alt="User Profile Image"
                                  />
                                  <img crossorigin="anonymous" 
                                    v-else-if="
                                      v.voter.has_media_profile === true &&
                                      v.voter.media[0].collection_name ===
                                        'profile'
                                    "
                                    class="pd-image"
                                    :src="v.voter.media[0].src_url"
                                    alt="User Profile Image"
                                  />
                                  <img crossorigin="anonymous" 
                                    v-else-if="
                                      v.voter.has_media_profile === true &&
                                      v.voter.media[1].collection_name ===
                                        'profile'
                                    "
                                    class="pd-image"
                                    :src="v.voter.media[1].src_url"
                                    alt="User Profile Image"
                                  />
                                  {{ v.voter.name }}
                                </div>
                              </div>
                              <div v-else class="modal-body">
                                <div
                                  class="d-flex align-items-center justify-content-center"
                                >
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end modal -->
                      </span>
                    </li>
                  </ul>
                </div>
              </fieldset>
            </div>
            <div class="content" v-if="post.survey.multiple_votes == 0">
              <fieldset>
                <legend class="text-center">{{ post.survey.content }}</legend>
                <div class="container">
                  <ul>
                    <li v-for="(s, index) in post.survey.options" :key="index">
                      <input
                        type="radio"
                        :id="`radio2` + s.id"
                        name="radio01"
                        :checked="s.user_has_voted"
                        @click="check_radio_vote(s.id)"
                      />

                      <label :for="`radio2` + s.id">{{ s.content }} </label>
                      <span class="show-voters vote">
                        <span
                          v-for="(voter, index) in s.last_three_voters"
                          :key="index"
                        >
                          <img crossorigin="anonymous" 
                            @click="get_voters(s.id)"
                            :data-bs-target="`#showVotersToOPtion` + s.id"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            width="30"
                            height="30"
                            :src="voter.photoURL"
                            loading="lazy"
                            :title="voter.first_name + ' ' + voter.last_name"
                            style="cursor: pointer"
                          />
                        </span>
                        &nbsp;
                        <span
                          style="cursor: pointer"
                          @click="get_voters(s.id)"
                          :data-bs-target="`#showVotersToOPtion` + s.id"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          >{{ s.votes_count }}</span
                        >
                        <!-- modal voters to option -->
                        <div
                          class="modal fade"
                          :id="`showVotersToOPtion` + s.id"
                          aria-hidden="true"
                          aria-labelledby="showVotersToOPtionToggleLabel2"
                          tabindex="-1"
                        >
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5
                                  class="modal-title"
                                  id="showVotersToOPtionToggleLabel2"
                                >
                                  {{ $t("Voters on the option") }}
                                </h5>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div
                                class="modal-body"
                                v-if="this.loading_voters == false"
                              >
                                <div
                                class="mt-1"
                                  v-for="(v, index) in all_voters"
                                  :key="index"
                                >
                                  <img crossorigin="anonymous" 
                                    v-if="v.voter.has_media_profile === false"
                                    class="pd-image"
                                    src="@/assets/img_friends/default1.png"
                                    alt="User Profile Image"
                                  />
                                  <img crossorigin="anonymous" 
                                    v-else-if="
                                      v.voter.has_media_profile === true &&
                                      v.voter.media[0].collection_name ===
                                        'profile'
                                    "
                                    class="pd-image"
                                    :src="v.voter.media[0].src_url"
                                    alt="User Profile Image"
                                  />
                                  <img crossorigin="anonymous" 
                                    v-else-if="
                                      v.voter.has_media_profile === true &&
                                      v.voter.media[1].collection_name ===
                                        'profile'
                                    "
                                    class="pd-image"
                                    :src="v.voter.media[1].src_url"
                                    alt="User Profile Image"
                                  />
                                  {{ v.voter.name }}
                                </div>
                              </div>
                              <div v-else class="modal-body">
                                <div
                                  class="d-flex align-items-center justify-content-center"
                                >
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end modal -->
                      </span>
                    </li>
                  </ul>
                </div>
              </fieldset>
            </div>
          </div>
          <div v-if="post.survey.is_active == 0" class="mt-4">
            <div class="content" v-if="post.survey.multiple_votes == 1">
              <fieldset>
                <legend class="text-center">{{ post.survey.content }}</legend>
                <div class="container">
                  <ul>
                    <li v-for="(s, index) in post.survey.options" :key="index">
                      <input
                        type="checkbox"
                        :id="`checkbox4` + s.id"
                        name="checkbox02"
                        disabled="disabled"
                        :checked="s.user_has_voted"
                      />

                      <label :for="`checkbox4` + s.id">{{ s.content }} </label>
                      <span class="show-voters vote">
                        <span
                          v-for="(voter, index) in s.last_three_voters"
                          :key="index"
                        >
                          <img crossorigin="anonymous" 
                            @click="get_voters(s.id)"
                            :data-bs-target="`#showVotersToOPtion` + s.id"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            width="30"
                            height="30"
                            :src="voter.photoURL"
                            loading="lazy"
                            :title="voter.first_name + ' ' + voter.last_name"
                            style="cursor: pointer"
                          />
                        </span>
                        &nbsp;
                        <span
                          style="cursor: pointer"
                          @click="get_voters(s.id)"
                          :data-bs-target="`#showVotersToOPtion` + s.id"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          >{{ s.votes_count }}</span
                        >
                        <!-- modal voters to option -->
                        <div
                          class="modal fade"
                          :id="`showVotersToOPtion` + s.id"
                          aria-hidden="true"
                          aria-labelledby="showVotersToOPtionToggleLabel2"
                          tabindex="-1"
                        >
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5
                                  class="modal-title"
                                  id="showVotersToOPtionToggleLabel2"
                                >
                                  {{ $t("Voters on the option") }}
                                </h5>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div
                                class="modal-body"
                                v-if="this.loading_voters == false"
                              >
                                <div
                                class="mt-1"
                                  v-for="(v, index) in all_voters"
                                  :key="index"
                                >
                                  <img crossorigin="anonymous" 
                                    v-if="v.voter.has_media_profile === false"
                                    class="pd-image"
                                    src="@/assets/img_friends/default1.png"
                                    alt="User Profile Image"
                                  />
                                  <img crossorigin="anonymous" 
                                    v-else-if="
                                      v.voter.has_media_profile === true &&
                                      v.voter.media[0].collection_name ===
                                        'profile'
                                    "
                                    class="pd-image"
                                    :src="v.voter.media[0].src_url"
                                    alt="User Profile Image"
                                  />
                                  <img crossorigin="anonymous" 
                                    v-else-if="
                                      v.voter.has_media_profile === true &&
                                      v.voter.media[1].collection_name ===
                                        'profile'
                                    "
                                    class="pd-image"
                                    :src="v.voter.media[1].src_url"
                                    alt="User Profile Image"
                                  />
                                  {{ v.voter.name }}
                                </div>
                              </div>
                              <div v-else class="modal-body">
                                <div
                                  class="d-flex align-items-center justify-content-center"
                                >
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end modal -->
                      </span>
                    </li>
                  </ul>
                </div>
              </fieldset>
            </div>
            <div class="content" v-if="post.survey.multiple_votes == 0">
              <fieldset>
                <legend class="text-center">{{ post.survey.content }}</legend>
                <div class="container">
                  <ul>
                    <li v-for="(s, index) in post.survey.options" :key="index">
                      <input
                        type="radio"
                        :id="`radio4` + s.id"
                        name="radio02"
                        disabled="disabled"
                        :checked="s.user_has_voted"
                      />

                      <label :for="`radio4` + s.id">{{ s.content }} </label>
                      <span class="show-voters vote">
                        <span
                          v-for="(voter, index) in s.last_three_voters"
                          :key="index"
                        >
                          <img crossorigin="anonymous" 
                            @click="get_voters(s.id)"
                            :data-bs-target="`#showVotersToOPtion` + s.id"
                            data-bs-toggle="modal"
                            data-bs-dismiss="modal"
                            width="30"
                            height="30"
                            :src="voter.photoURL"
                            loading="lazy"
                            :title="voter.first_name + ' ' + voter.last_name"
                            style="cursor: pointer"
                          />
                        </span>
                        &nbsp;
                        <span
                          style="cursor: pointer"
                          @click="get_voters(s.id)"
                          :data-bs-target="`#showVotersToOPtion` + s.id"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          >{{ s.votes_count }}</span
                        >
                        <!-- modal voters to option -->
                        <div
                          class="modal fade"
                          :id="`showVotersToOPtion` + s.id"
                          aria-hidden="true"
                          aria-labelledby="showVotersToOPtionToggleLabel2"
                          tabindex="-1"
                        >
                          <div class="modal-dialog modal-dialog-centered">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5
                                  class="modal-title"
                                  id="showVotersToOPtionToggleLabel2"
                                >
                                  {{ $t("Voters on the option") }}
                                </h5>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div
                                class="modal-body"
                                v-if="this.loading_voters == false"
                              >
                                <div
                                class="mt-1"
                                  v-for="(v, index) in all_voters"
                                  :key="index"
                                >
                                  <img crossorigin="anonymous" 
                                    v-if="v.voter.has_media_profile === false"
                                    class="pd-image"
                                    src="@/assets/img_friends/default1.png"
                                    alt="User Profile Image"
                                  />
                                  <img crossorigin="anonymous" 
                                    v-else-if="
                                      v.voter.has_media_profile === true &&
                                      v.voter.media[0].collection_name ===
                                        'profile'
                                    "
                                    class="pd-image"
                                    :src="v.voter.media[0].src_url"
                                    alt="User Profile Image"
                                  />
                                  <img crossorigin="anonymous" 
                                    v-else-if="
                                      v.voter.has_media_profile === true &&
                                      v.voter.media[1].collection_name ===
                                        'profile'
                                    "
                                    class="pd-image"
                                    :src="v.voter.media[1].src_url"
                                    alt="User Profile Image"
                                  />
                                  {{ v.voter.name }}
                                </div>
                              </div>
                              <div v-else class="modal-body">
                                <div
                                  class="d-flex align-items-center justify-content-center"
                                >
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                  <div
                                    class="spinner-grow text-success"
                                    role="status"
                                  >
                                    <span class="visually-hidden"
                                      >Loading...</span
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- end modal -->
                      </span>
                    </li>
                  </ul>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
        <div
          class="card-footer bg-white d-flex justify-content-around"
          v-if="post.is_approved == 0"
        >
          <div
            class="d-flex justify-content-center align-items-center btn-agree"
          >
            <svg
              fill="#000000"
              height="20"
              width="22"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 330 330"
              xml:space="preserve"
            >
              <g>
                <path
                  d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M165,300
		c-74.44,0-135-60.561-135-135S90.56,30,165,30s135,60.561,135,135S239.439,300,165,300z"
                />
                <path
                  d="M226.872,106.664l-84.854,84.853l-38.89-38.891c-5.857-5.857-15.355-5.858-21.213-0.001
		c-5.858,5.858-5.858,15.355,0,21.213l49.496,49.498c2.813,2.813,6.628,4.394,10.606,4.394c0.001,0,0,0,0.001,0
		c3.978,0,7.793-1.581,10.606-4.393l95.461-95.459c5.858-5.858,5.858-15.355,0-21.213
		C242.227,100.807,232.73,100.806,226.872,106.664z"
                />
              </g>
            </svg>
            <button class="btn_comment_style" @click="agree_post(this.post.id)">
              {{ $t("Agree") }}
            </button>
          </div>
          <div
            class="d-flex justify-content-center align-items-center btn-cancle"
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.70711 8.29289C9.31658 7.90237 8.68342 7.90237 8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711L10.5858 12L8.29289 14.2929C7.90237 14.6834 7.90237 15.3166 8.29289 15.7071C8.68342 16.0976 9.31658 16.0976 9.70711 15.7071L12 13.4142L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L13.4142 12L15.7071 9.70711C16.0976 9.31658 16.0976 8.68342 15.7071 8.29289C15.3166 7.90237 14.6834 7.90237 14.2929 8.29289L12 10.5858L9.70711 8.29289Z"
                fill="#000000"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
                fill="#000000"
              />
            </svg>
            <button
              class="btn_comment_style"
              style="cursor: pointer"
              @click="open_reason()"
            >
              {{ $t("Cancel") }}
            </button>
            <div></div>
          </div>
        </div>
        <div
          class="card-footer d-flex justify-content-around btn-cancle"
          v-if="post.is_approved == 2"
        >
          <div class="d-flex justify-content-center align-items-center">
            <b>{{ $t("Canceled") }} </b>
          </div>
        </div>
        <div v-if="this.enableDisable == true">
          <textarea
            class="vcomments__add-input ar_vcomment m-2"
            name=""
            id=""
            cols="60"
            rows="3"
            v-model="reason"
            placeholder="Add reason ..."
          ></textarea>
          <br />

          <div class="m-2">
            <button
              class="vcomments__add-button text-center"
              @click="canceled_post"
            >
              {{ $t("Cancel the Post") }}
            </button>
            &nbsp;
            <button
              class="vcomments__add-button-close text-center"
              @click="open_reason"
            >
              {{ $t("Close") }}
            </button>
          </div>
        </div>
      </div>
      <div class="card card_post" v-else>
        <div
          class="card-header bg-white border-bottom-0 d-flex justify-content-between"
        >
          <div class="d-flex align-items-center">
            <div>
              <img crossorigin="anonymous" 
                v-if="post.profile.has_media_profile === false"
                src="@/assets/img_friends/default1.png"
                alt=""
              />
              <div v-else>
                <img crossorigin="anonymous" 
                  v-if="
                    post.profile.media[0].collection_name === 'profile'
                  "
                  :src="post.profile.media[0].src_url"
                  alt=""
                />
                <img crossorigin="anonymous" 
                  v-else
                  :src="post.profile.media[1].src_url"
                  alt=""
                />
              </div>
            </div>
            <div :class="!DefineDeriction() ? 'mx-3' : 'me-3'">
              <p class="mb-0">
                <router-link
                  class="router-link-to-profile"
                  :to="{
                    name: 'userGroupProfile',
                    params: {
                      id: post.group_id,
                      user_id: post.profile.user_id,
                    },
                  }"
                  >{{ post.profile.first_name }}
                  {{ post.profile.last_name }}</router-link
                >
              </p>
              <small class="text-muted">
                {{ format_date(post.created_at) }}
              </small>
            </div>
          </div>
          <div class="d-flex align-items-center right">
            <div :class="DefineDeriction() ? 'mx-3' : 'me-3'">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="5"
                viewBox="0 0 5 5"
              >
                <circle
                  id="Ellipse_181"
                  data-name="Ellipse 181"
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  fill="#a5a5a5"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="5"
                viewBox="0 0 5 5"
              >
                <circle
                  id="Ellipse_181"
                  data-name="Ellipse 181"
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  fill="#a5a5a5"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="5"
                viewBox="0 0 5 5"
              >
                <circle
                  id="Ellipse_181"
                  data-name="Ellipse 181"
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  fill="#a5a5a5"
                />
              </svg>
            </div>

            <div style="cursor: pointer" @click="delete_post(post.id)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.147"
                height="15.147"
                viewBox="0 0 15.147 15.147"
              >
                <g
                  id="Group_184"
                  data-name="Group 184"
                  transform="translate(7.573 -5.632) rotate(45)"
                >
                  <rect
                    id="Rectangle_151"
                    data-name="Rectangle 151"
                    width="18.675"
                    height="2.746"
                    rx="1.373"
                    transform="translate(0 7.964)"
                    fill="#a5a5a5"
                  />
                  <rect
                    id="Rectangle_152"
                    data-name="Rectangle 152"
                    width="18.675"
                    height="2.746"
                    rx="1.373"
                    transform="translate(10.71 0) rotate(90)"
                    fill="#a5a5a5"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
        <div class="card-body">
          <p v-if="post.content" class="card-text" dir="auto">
            {{ post.content }}
          </p>
          <div v-if="post.has_media" class="media_container">
            <div class="media_one" v-if="post.media.length === 1">
              <img crossorigin="anonymous" 
                v-if="post.media[0].media_type.includes('Image')"
                :src="post.media[0].src_url"
                alt=""
                v-viewer
              />
              <router-link
                v-else-if="post.media[0].media_type.includes('Video')"
                :to="{
                  name: 'showGroupPost',
                  params: { postid: post.id },
                }"
              >
                <video crossorigin="anonymous"  controls :src="post.media[0].src_url"></video>
              </router-link>
              <a
                v-else
                :href="post.media[0].src_url"
                download="download"
                class="style-pdf"
                target="_blank"
              >
                <div
                  class="div"
                  style="
                    max-height: 100% !important;
                    width: 100% !important;
                    height: 100%;
                    overflow: hidden;
                    background: #fff;
                    display: flex;
                    align-items: center;
                    border: none;
                    background-color: #ffffff;
                    box-shadow: rgba(50, 50, 93, 0.25) 0px -2px 3px -2px inset,
                      rgba(0, 0, 0, 0.3) 0px 6px 29px -16px inset;
                    border-radius: 2px;
                    /* width: 100%; */
                    padding: 22px 25px 25px 55px;
                    /* margin: 8px 0px; */
                    border: 0px solid;
                  "
                >
                  <div class="one">
                    <PdfSvg></PdfSvg>
                  </div>
                  <div class="two">
                    <span style="font-weight: 500">
                      &nbsp; {{ post.media[0].fullPath.substr(12) + "" }}
                    </span>
                  </div>
                  <div class="three">
                    <DownloadSvg></DownloadSvg>
                  </div>
                </div>
              </a>
            </div>
            <router-link
              :to="{
                name: 'shpwpost',
                params: { id: post.id },
              }"
            >
              <div
                class="media_two d-flex justify-content-between align-items-center"
                v-if="post.media.length === 2"
              >
                <div class="media_two_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
                </div>
                <div class="media_two_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[1].media_type.includes('Image')"
                    :src="post.media[1].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[1].src_url"></video>
                </div>
              </div>
              <div class="media_three" v-if="post.media.length === 3">
                <div class="media_three_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
                </div>
                <div class="media_three_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[1].media_type.includes('Image')"
                    :src="post.media[1].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[1].src_url"></video>
                </div>
                <div class="media_three_box_under">
                  <img crossorigin="anonymous" 
                    v-if="post.media[2].media_type.includes('Image')"
                    :src="post.media[2].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[2].src_url"></video>
                </div>
              </div>
              <div class="media_four" v-if="post.media.length === 4">
                <div class="media_four_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
                </div>
                <div class="media_four_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[1].media_type.includes('Image')"
                    :src="post.media[1].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[1].src_url"></video>
                </div>
                <div class="media_four_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[2].media_type.includes('Image')"
                    :src="post.media[2].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[2].src_url"></video>
                </div>
                <div class="media_four_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[3].media_type.includes('Image')"
                    :src="post.media[3].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[3].src_url"></video>
                </div>
              </div>
              <div class="media_bigger" v-if="post.media.length > 4">
                <div class="media_bigger_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
                </div>
                <div class="media_bigger_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[1].media_type.includes('Image')"
                    :src="post.media[1].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[1].src_url"></video>
                </div>
                <div class="media_bigger_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[2].media_type.includes('Image')"
                    :src="post.media[2].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[2].src_url"></video>
                </div>
                <div class="media_bigger_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[3].media_type.includes('Image')"
                    :src="post.media[3].src_url"
                    alt=""
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[3].src_url"></video>
                </div>
                <p
                  class="bigger_p d-flex align-items-center justify-content-center"
                >
                  <span>+{{ post.media.length - 4 }}</span>
                </p>
              </div>
            </router-link>
          </div>
        </div>
        <div
          class="card-footer bg-white d-flex justify-content-around"
          v-if="post.is_approved == 0"
        >
          <div
            class="d-flex justify-content-center align-items-center btn-agree"
          >
            <svg
              fill="#000000"
              height="20"
              width="22"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 330 330"
              xml:space="preserve"
            >
              <g>
                <path
                  d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M165,300
		c-74.44,0-135-60.561-135-135S90.56,30,165,30s135,60.561,135,135S239.439,300,165,300z"
                />
                <path
                  d="M226.872,106.664l-84.854,84.853l-38.89-38.891c-5.857-5.857-15.355-5.858-21.213-0.001
		c-5.858,5.858-5.858,15.355,0,21.213l49.496,49.498c2.813,2.813,6.628,4.394,10.606,4.394c0.001,0,0,0,0.001,0
		c3.978,0,7.793-1.581,10.606-4.393l95.461-95.459c5.858-5.858,5.858-15.355,0-21.213
		C242.227,100.807,232.73,100.806,226.872,106.664z"
                />
              </g>
            </svg>
            <button class="btn_comment_style" @click="agree_post(this.post.id)">
              {{ $t("Agree") }}
            </button>
          </div>
          <div
            class="d-flex justify-content-center align-items-center btn-cancle"
          >
            <svg
              width="22"
              height="22"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.70711 8.29289C9.31658 7.90237 8.68342 7.90237 8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711L10.5858 12L8.29289 14.2929C7.90237 14.6834 7.90237 15.3166 8.29289 15.7071C8.68342 16.0976 9.31658 16.0976 9.70711 15.7071L12 13.4142L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L13.4142 12L15.7071 9.70711C16.0976 9.31658 16.0976 8.68342 15.7071 8.29289C15.3166 7.90237 14.6834 7.90237 14.2929 8.29289L12 10.5858L9.70711 8.29289Z"
                fill="#000000"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12Z"
                fill="#000000"
              />
            </svg>
            <button
              class="btn_comment_style"
              style="cursor: pointer"
              @click="open_reason()"
            >
              {{ $t("Cancel") }}
            </button>
            <div></div>
          </div>
        </div>
        <div
          class="card-footer d-flex justify-content-around btn-cancle"
          v-if="post.is_approved == 2"
        >
          <div class="d-flex justify-content-center align-items-center">
            <b>{{ $t("Canceled") }} </b>
          </div>
        </div>
        <div v-if="this.enableDisable == true">
          <textarea
            class="vcomments__add-input ar_vcomment m-2"
            name=""
            id=""
            cols="60"
            rows="3"
            v-model="reason"
            placeholder="Add reason ..."
          ></textarea>
          <br />

          <div class="m-2">
            <button
              class="vcomments__add-button text-center"
              @click="canceled_post"
            >
              {{ $t("Cancel the Post") }}
            </button>
            &nbsp;
            <button
              class="vcomments__add-button-close text-center"
              @click="open_reason"
            >
              {{ $t("Close") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GroupPostServices from "@/services/GroupsServices/GroupPostServices";
import formatTime from "@/mixins/formatTime";
import PdfSvg from "@/icons/Groups/PdfSvg.vue";
import DownloadSvg from "@/icons/Groups/DownloadSvg.vue";
import LangMixin from "@/mixins/LangMixin";
export default {
  name: "PendingPostComponent",
  components: { PdfSvg, DownloadSvg },
  data() {
    return {
      enableDisable: false,
      reason: null,
      survey_option_id: [],
      all_voters: [],
      loading_voters: false,
    };
  },
  props: ["post"],
  mixins: [formatTime,LangMixin],
  mounted() {
    if (this.post.survey != null) {
      if (this.post.survey.multiple_votes == 1) {
        this.post.survey.options.forEach((element) => {
          if (element.user_has_voted == true) {
            this.survey_option_id.push(element.id);
          }
        });
      }
    }
  },
  methods: {
    get_voters(survey_id) {
      this.all_voters = [];
      this.loading_voters = true;
      GroupPostServices.s_get_voters_to_option(survey_id).then((res) => {
        this.all_voters = res.data.GetVotesForOptionQuery;
        this.loading_voters = false;
      });
    },
    async check_vote(id) {
      if (this.survey_option_id.length == 0) {
        this.survey_option_id.push(id);
      } else {
        if (!this.survey_option_id.includes(id)) {
          this.survey_option_id.push(id);
        } else {
          let index = this.survey_option_id.indexOf(id);
          this.survey_option_id.splice(index, 1);
        }
      }
      await GroupPostServices.s_vote_on_option_to_survey(this.post.survey.id, {
        survey_option_id: this.survey_option_id,
      }).then((res) => {
        this.$store.commit(
          "singleGroup/increase_count_voters_pending_post",
          res.data.VoteSurveyMutation
        );
      });
    },
    async check_radio_vote(id) {
      if (this.survey_option_id.length == 0) {
        this.survey_option_id.push(id);
      } else {
        this.survey_option_id = [];
        this.survey_option_id.push(id);
      }
      await GroupPostServices.s_vote_on_option_to_survey(this.post.survey.id, {
        survey_option_id: this.survey_option_id,
      }).then((res) => {
        this.$store.commit(
          "singleGroup/increase_count_voters_pending_post",
          res.data.VoteSurveyMutation
        );
      });
    },
    open_reason: function () {
      if (this.enableDisable) {
        this.enableDisable = false;
      } else {
        this.enableDisable = true;
      }
    },
    agree_post() {
      GroupPostServices.s_change_status_post(this.post.id, 1).then((res) => {
        this.$snotify.success("accepted post successfully ", "Group");
        this.$store.commit("singleGroup/change_status_post", res.data.ChangePostStatusMutation);
      });
    },
    canceled_post() {
      GroupPostServices.s_change_status_post(this.post.id, 2, this.reason).then(
        (res) => {
          this.$snotify.success("reject post successfully", "Group");
          this.$store.commit("singleGroup/change_status_post", res.data.ChangePostStatusMutation);
        }
      );
    },
    delete_post(id) {
      if (confirm("Do you want to delete this post?") == true) {
        this.delete(id);
      }
    },
    async delete(id) {
      GroupPostServices.s_delete_group_post(id).then(() => {
        this.$store.commit("singleGroup/DELETE_POST_Is_approve", id);
        this.$snotify.success("Deleted successfully", "Post");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
//
.router-link-to-profile {
  text-decoration: none !important;
  color: #111;
}

.router-link-to-profile.active_link {
  color: #111 !important;
  border: none !important;
  font-weight: normal !important;
}
.btn_comment_style {
  // background: white;
  border: 0px;
  // color: #111;
  background: none;
}
.row {
  .card {
    margin-bottom: 0px;
  }

  .card_post {
    padding: 0 0rem 0 0;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    .card-header {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .card-body {
      padding: 0;
      overflow: hidden;

      p {
        padding-left: 1.2rem;
        white-space: pre-line;
      }

      .media_container {
        max-height: 700px;
        width: 100% !important;
        overflow: hidden;

        .media_one {
          max-height: 100% !important;
          width: 100% !important;
          overflow: hidden;
          text-align: center;

          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: cover;
          }

          video {
            object-fit: cover;
            width: 100%;
            height: 270px;
          }
        }

        .media_two {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_two_box {
            width: 50%;
            height: 100% !important;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100%;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 220px;
              object-fit: contain;
            }
          }
        }

        .media_three {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_three_box {
            width: 50%;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100%;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }

          .media_three_box_under {
            width: 100%;
            height: 50% !important;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100%;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 420px;
              object-fit: cover;
            }
          }
        }

        .media_four {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_four_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100%;
              margin: auto;
              display: block;
              height: 270px;
            }

            video {
              width: 100%;
              margin: auto;
              display: block;
              height: 248px;
              object-fit: cover;
            }
          }
        }

        .media_bigger {
          height: 100%;
          width: 100% !important;
          overflow: hidden;
          position: relative;

          .media_bigger_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100% !important;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }

          .bigger_p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-weight: bolder;
            font-size: 20px;
            width: 100px;
            height: 100px;
            padding: 0 !important;
            background-color: rgba(119, 119, 119, 0.699);
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }

      .body_box {
        margin-top: 1.5rem !important;
        padding: 0 2rem;
        margin-bottom: 0.5rem;
      }
      .shared {
        padding: 0 1.2rem;
      }
    }

    .card-footer {
      small {
        margin-left: 0.5rem;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .card-footer {
    small {
      margin-left: 1rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -100% !important;
          width: auto !important;
          span {
            margin: 0 5px;
            font-size: 10px !important;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .div {
    padding: 0 0.5rem !important;
    .row {
      margin: 0 0 1rem 0 !important;

      .card-header {
        p {
          font-size: 0.8rem !important;
        }

        .right :nth-child(1),
        .right :nth-child(2) {
          margin-right: 0.3rem !important;
        }
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .card-footer {
    small {
      margin-left: 0.2rem !important;

      .box-reaction-items {
        display: none;
      }
      cursor: pointer;
      position: relative;

      &:hover {
        .box-reaction-items {
          display: block !important;
          position: absolute;
          background-color: rgba(0, 0, 0, 0.548);
          color: white;
          padding: 10px;
          border-radius: 5px;
          top: -40px;
          left: -150% !important;
          width: auto !important;
          span {
            margin: 0 3px !important;
            font-size: 2px !important;
          }
        }
      }
    }
  }
}
.vcomments__info:lang(ar) {
  direction: rtl;
}

//
.btn-agree {
  background: #8c8cff;
  color: #ffffff;
  border-radius: 4px;
  padding: 0.5%;
}
.btn-cancle {
  background: #b4b4c5;
  color: #ffffff;
  border-radius: 4px;
  padding: 0.5%;
}

//
textarea {
  width: 300px;
  height: 45px;
  padding: 2px;
  resize: none;
  overflow: hidden;
  background-color: transparent;
  border: 2px solid #000;
  border-radius: 4px;
  font-family: "Inconsolata", monospace;
  font-size: 1rem;
  color: #000;
}

textarea:focus {
  outline: none;
}

.ar_vcomment:lang(ar) {
  padding: 11px 14px 10px 3px;
  height: 45px;
  resize: none;
}
textarea.vcomments__add-input.ar_vcomment:lang(ar) {
  direction: rtl;
}
.ar_vcomment:lang(ku) {
  padding: 11px 14px 10px 3px;
  height: 45px;
  resize: none;
}
textarea.vcomments__add-input.ar_vcomment:lang(ku) {
  direction: rtl;
}

.vcomments {
  $this: &;

  &__btn {
    font-size: 14px;
    cursor: pointer;
    position: relative;
    padding: 2px 5px;
    &--delete {
      color: red;
    }
    &--edit {
      color: orange;
    }
    &--reply {
      color: green;
    }
    &--toggle {
      color: #9e9e9e;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 2px;
      left: 50%;
      right: 50%;
      background: currentColor;
      height: 1px;
      transition: all 0.2s ease-in;
    }
    &:hover {
      &:after {
        left: 0;
        right: 0;
      }
    }
  }
  &__add-input {
    box-sizing: border-box;
    width: 100%;
    outline: none;
    border: 1px solid #eee;
    padding: 10px 75px 10px 15px;
    border-radius: 40px;
    transition: all 0.2s ease-out;
    background: #ccc;
    &:focus {
      border-color: $defaultColor;
      & + #{$this} {
        &__add-button {
          background: $defaultColor;
          color: white;
          transition: all 0.2 ease-in;
        }
      }
    }
  }
  &__add-button {
    // position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgb(255, 0, 0);
    transition: all 0.2s ease-out;
    cursor: pointer;
    user-select: none;
    padding: 5px 20px;
    border-radius: 40px;
    width: 75%;
    font-weight: 600;
    &:hover {
      background: $defaultColor !important;
      color: white;
    }
  }
  &__add-button-close {
    // position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background: #cfcdcd;
    transition: all 0.2s ease-out;
    cursor: pointer;
    user-select: none;
    padding: 5px 20px;
    border-radius: 40px;
    width: 20%;
    font-weight: 600;
    &:hover {
      background: $defaultColor !important;
      color: white;
    }
  }
}
a.style-pdf {
  color: #000;
  text-decoration: none;
}

.one {
  width: 10%;
  // background:red;
}

.two {
  width: 85%;
  // background:lightgreen;
}

.three {
  width: 10%;
}

.style-pdf:hover {
  cursor: pointer;
  filter: brightness(95%);
}

/*** This page styles (unnecessary) ***/
body {
  color: #333;
  font-weight: normal;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, Sans serif;
  background: url(https://josetxu.com/img/fondo_blanco.png) repeat 0 0 #fff;
}
h1 {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 0.5em;
  margin: 0 0 2em 0;
}
h2 {
  font-weight: normal;
  text-align: center;
  color: #777;
}
form {
  max-width: 980px;
  margin: 0 auto;
}
// .content {
// 	width: 50%;
// 	padding: 1em;
// 	box-sizing: border-box;
// 	float: left;
// }

fieldset {
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 2em;
  border-color: #d4d4d4;
  border-style: solid;
  border-width: 2px;
  background: #fff;
}

legend {
  float: none;
  color: #272727;
  padding: 0.3em 0.6em;
  border: 2px solid #d4d4d4;
  background: #fff;
  font-size: 1em;
}
legend.radio {
  border-radius: 50px;
}

.container ul {
  margin-top: 0;
  padding-left: 0em;
}
.container ul li {
  list-style-type: none;
}
.container ul + ul {
  margin-bottom: 0;
}
.container ul + ul > li + li label {
  margin-bottom: 0;
}

/*** Styling Radio & Checkbox Input Fields (start here) ***/
label {
  font-weight: 600;
  color: #777777;
  margin-bottom: 11px;
  width: 100%;
  float: left;
  cursor: pointer;
  padding: 0 0.6em;
  box-sizing: border-box;
  background: #e6e6e6;
  transition: all 0.5s ease 0s;
}

input[type="radio"],
input[type="checkbox"] {
  display: none;
}

input[type="radio"] + label,
input[type="checkbox"] + label {
  line-height: 3em;
}

input[type="radio"] + label {
  border-radius: 50px;
}

input[type="radio"]:disabled + label,
input[type="checkbox"]:disabled + label {
  color: #ccc !important;
  cursor: not-allowed;
}
input[type="radio"]:checked:disabled + label:after,
input[type="checkbox"]:checked:disabled + label:after {
  border-color: #ccc;
}

input[type="radio"] + label:before,
input[type="checkbox"] + label:before {
  content: "";
  width: 26px;
  height: 26px;
  float: left;
  margin-right: 0.6em;
  border: 2px solid #ccc;
  background: #fff;
  margin-top: 0.6em;
}
input[type="radio"] + label:before {
  border-radius: 100%;
}

input[type="radio"]:checked + label,
input[type="checkbox"]:checked + label {
  background: #c1eec2;
}

input[type="radio"]:checked + label:after {
  content: "";
  width: 0;
  height: 0;
  border: 7px solid #0fbf12;
  float: left;
  margin-left: -1.85em;
  margin-top: 1em;
  border-radius: 100%;
}
input[type="checkbox"]:checked + label:after {
  content: "";
  width: 12px;
  height: 6px;
  border: 4px solid #0fbf12;
  float: left;
  margin-left: -1.95em;
  border-right: 0;
  border-top: 0;
  margin-top: 1em;
  transform: rotate(-55deg);
}

input[type="radio"]:checked + label:before,
input[type="checkbox"]:checked + label:before {
  border-color: #0fbf12;
}

input[type="radio"]:checked:disabled + label,
input[type="checkbox"]:checked:disabled + label {
  background: #ccc;
  color: #fff !important;
}

input[type="radio"]:checked:disabled + label:before,
input[type="checkbox"]:checked:disabled + label:before {
  border-color: #bdbdbd;
}

@media (max-width: 650px) {
  .content {
    width: 100%;
  }
}
.vote {
  float: right;
}

.show-voters {
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: -50px 10px 0px 0px;
  display: flex;
}
.vote img {
  border-radius: 50%;
}
.content {
  margin: 10px;
}
.pd-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 20px;
}
</style>
