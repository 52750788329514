<template>
  <div class="row" v-if="item.collectionable_type == 'Media'">
    <!-- Images and video that belong to  post  -->
    <div class="col-3">
      <span v-if="item.item.parent_id == null">
        <video crossorigin="anonymous" 
          v-if="item.item.media_type == 'Video'"
          class="video-style"
          :src="item.item.src_url"
        ></video>
      </span>
      <span v-if="item.item.parent_id != null">
        <video crossorigin="anonymous" 
          v-if="item.item.parent.media_type == 'Video'"
          class="video-style"
          :src="item.item.src_url"
        ></video>
      </span>
    </div>
    <div class="col-9">
      <div class="row mt-2">
        <span class="content mr-2"
          >{{ $t("Video") }} {{ item.item.model.model.name }}</span
        >
        <p>{{ $t("Media") }}</p>
      </div>
      <div class="row m-2">
        <div class="row">
          Add to collection from :
          <img crossorigin="anonymous" 
            v-if="item.profile.has_media_profile === false"
            class="pd-image"
            src="@/assets/img_friends/default1.png"
            alt="Profile image"
          />
          <img crossorigin="anonymous" 
            v-else-if="item.profile.media[0].collection_name === 'profile'"
            :src="item.profile.media[0].src_url"
            class="pd-image"
            alt="personal profile image"
          />
          <img crossorigin="anonymous" 
            v-else-if="item.profile.media[1].collection_name === 'profile'"
            :src="item.profile.media[1].src_url"
            class="pd-image"
            alt="personal profile image"
          />
          {{ item.profile.name }}
        </div>
      </div>
      <div class="">
        <router-link
          v-if="
            item.item.model.model.model_type == 'Profile' &&
            USER.id == item.model.model.user_id
          "
          class="router-link-to-profile"
          :to="{ name: 'homeMyProfile', params: { id: USER.id } }"
        >
          <img crossorigin="anonymous" 
            v-if="item.item.model.model.has_media_profile === false"
            class="style-img"
            src="@/assets/img_friends/default1.png"
            alt="cover saved item"
            :title="item.item.model.model.name"
          />
          <img crossorigin="anonymous" 
            v-else-if="
              item.item.model.model.media[0].collection_name === 'profile'
            "
            :src="item.item.model.model.media[0].src_url"
            class="style-img"
            alt="cover saved item"
            :title="item.item.model.model.name"
          />
          <img crossorigin="anonymous" 
            v-else-if="
              item.item.model.model.media[1].collection_name === 'profile'
            "
            :src="item.item.model.model.media[1].src_url"
            class="style-img"
            alt="cover saved item"
            :title="item.item.model.model.name"
          />
          Saved from Post
          <b> {{ item.item.model.model.name }} </b></router-link
        >
        <router-link
          v-else-if="
            item.item.model.model.model_type == 'Profile' &&
            USER.id != item.item.model.model.user_id
          "
          class="router-link-to-profile"
          :to="{
            name: 'homeMyProfile',
            params: { id: item.item.model.model.user_id },
          }"
        >
          <img crossorigin="anonymous" 
            v-if="item.item.model.model.has_media_profile === false"
            class="style-img"
            src="@/assets/img_friends/default1.png"
            alt="cover saved item"
            :title="item.item.model.model.name"
          />
          <img crossorigin="anonymous" 
            v-else-if="
              item.item.model.model.media[0].collection_name === 'profile'
            "
            :src="item.item.model.model.media[0].src_url"
            class="style-img"
            alt="cover saved item"
            :title="item.item.model.model.name"
          />
          <img crossorigin="anonymous" 
            v-else-if="
              item.item.model.model.media[1].collection_name === 'profile'
            "
            :src="item.item.model.model.media[1].src_url"
            class="style-img"
            alt="cover saved item"
            :title="item.item.model.model.name"
          />
          Saved from Post
          <b> {{ item.item.model.model.name }} </b>
        </router-link>
        <router-link
          v-else
          :to="{
            name: 'homeSinglePage',
            params: { id: item.item.model.model.id },
          }"
          class="router-link-to-profile"
        >
          <img crossorigin="anonymous" 
            class="style-img"
            src="@/assets/img_friends/default1.png"
            alt=""
          />
          Saved from Post
          <b> {{ item.item.model.model.name }}</b>
          <small style="color: green; font-weight: bold"> page</small>
        </router-link>
      </div>
      <div class="row mt-2">
        <div class="col-2" data-bs-toggle="dropdown" aria-expanded="false">
          <div class="style-more">
            <div class="dropstart">
              <a class="dropdown">
                <img crossorigin="anonymous"  class="img-style" src="@/assets/images/more.png" alt="" />
              </a>
              <ul class="dropdown-menu">
                <li
                  class="dropdown-item pro guest"
                  @click="cancel_save_video(item.item.id)"
                >
                  <CancelSave></CancelSave>
                  {{ $t("Cancel Save") }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        &nbsp;
        <div class="col-6">
          <button
            class="btn btn-secondary btn-sm"
            data-bs-toggle="modal"
            :data-bs-target="'#staticSelectCollection' + item.item.id"
          >
            {{ $t("Add to Collection") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <PostSaved :item="item"></PostSaved>
  <GroupsPostsSaved :item="item"></GroupsPostsSaved>
  <MoveToAnotherCollection :all_item="item"></MoveToAnotherCollection>
</template>
<script>
import PostSaved from "@/components/SavedItems/Collections/TypePostSaved/PostSaved.vue";
import GroupsPostsSaved from "@/components/SavedItems/Collections/TypePostSaved/GroupsPostsSaved.vue";
import CancelSave from "@/components/OtherComponent/SVG/CancelSave.vue";
import SaveServices from "@/services/HomePage/Save/SaveServices";
import CollectionsServices from "@/services/Collections/CollectionsServices";
import { mapGetters } from "vuex";
import MoveToAnotherCollection from "../SavedItemsBelongingToMe/MoveToAnotherCollection.vue";
export default {
  name: "ItemFromCollection",
  props: ["item"],
  computed: {
    ...mapGetters({
      USER: "auth/USER",
      info_collection: "collections/info_collection",
    }),
  },
  methods: {
    cancel_save(id) {
      SaveServices.cancel_Save({
        item_id: id,
        item_type: "Post",
      }).then(() => {
        this.$store.commit("collections/CANCEL_SAVE_ITEM_FROM_COLLECTION", id);
      });
    },
    cancel_save_video(id) {
      SaveServices.cancel_Save({
        item_id: id,
        item_type: "Video",
      }).then(() => {
        this.$snotify.success("Cancel Save successfully", "Saved");
      });
    },
    remove_from_collection(id) {
      CollectionsServices.remove_from_collection(
        id,
        this.item.collectionable_type,
        this.item.collection_id
      ).then(() => {
        this.$store.commit("collections/REMOVE_ITEM_FROM_COLLECTION", id);

        this.$snotify.success(
          "The item has been removed from the collection successfully",
          "Saved"
        );
      });
    },
  },
  components: {
    CancelSave,
    MoveToAnotherCollection,
    GroupsPostsSaved,
    PostSaved,
  },
};
</script>
<style lang="scss" scoped>
.write-post-container {
  width: 100%;
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  color: #626262;
  margin: 12px 0px;
}
.txt-style {
  font-size: 25px;
  font-weight: 600;
}
.router-link-to-profile {
  text-decoration: none !important;
  color: #111;
}

.router-link-to-profile.active_link {
  color: #111 !important;
  border: none !important;
  font-weight: normal !important;
}
.row {
  .card {
    margin-bottom: 0px;
  }

  .card_post {
    padding: 0 0rem 0 0;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    .card-header {
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }

    .card-body {
      padding: 0;
      overflow: hidden;

      p {
        padding-left: 1.2rem;
      }

      .media_container {
        max-height: 500px;
        width: 100% !important;
        overflow: hidden;

        .media_one {
          max-height: 100% !important;
          width: 100% !important;
          // height: 100% !important;
          overflow: hidden;
          text-align: center;

          img {
            width: 100% !important;
            height: 50% !important;
            object-fit: cover;
          }

          video {
            object-fit: cover;
            width: 100%;
            height: 450px;
          }
        }

        .media_two {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_two_box {
            width: 50%;
            height: 100% !important;
            overflow: hidden;

            img {
              width: 415px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 100% !important;
              height: 235px;
              object-fit: contain;
            }
          }
        }

        .media_three {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_three_box {
            width: 50%;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 375px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 410px;
              margin: auto;
              display: block;
              height: 248px;
              object-fit: cover;
            }
          }

          .media_three_box_under {
            width: 100%;
            margin: auto;
            display: block;
            height: 248px;
            overflow: hidden;

            img {
              width: 332px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .media_four {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_four_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 410px;
              margin: auto;
              display: block;
              height: 290px;
            }

            video {
              width: 410px;
              margin: auto;
              display: block;
              height: 248px;
              object-fit: cover;
            }
          }
        }

        .media_bigger {
          height: 100%;
          width: 100% !important;
          overflow: hidden;
          position: relative;

          .media_bigger_box {
            width: 50% !important;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100% !important;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }

          .bigger_p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-weight: bolder;
            font-size: 20px;
            width: 100px;
            height: 100px;
            padding: 0 !important;
            background-color: rgba(119, 119, 119, 0.699);
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }

      .body_box {
        margin-top: 1.5rem !important;
        padding: 0 2rem;
        margin-bottom: 0.5rem;
      }
      .shared {
        padding: 0px 3.2rem;
      }
    }

    .card-footer {
      small {
        margin-left: 0.5rem;
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items:lang(ar) {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            margin: 0 -160px;
            direction: rtl;
            span {
              margin: 0 5px;
            }
          }
          .box-reaction-items:lang(ku) {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            margin: 0 -160px;
            direction: rtl;
            span {
              margin: 0 5px;
            }
          }
          .box-reaction-items {
            display: inline-flex !important;
            position: absolute;
            background-color: white;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -60px;
            left: 0%;
            margin: 0px -95px;
            width: auto !important;
            box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px,
              rgb(0 0 0 / 24%) 0px 1px 2px;
            span {
              margin: 0 5px;
              // margin: 0px -110px;
            }
          }
        }
      }

      .like {
        color: blue !important;
        font-weight: bold !important;
      }
      .love {
        color: red !important;
        font-weight: bold !important;
      }

      .angry {
        color: green !important;
        font-weight: bold !important;
      }

      .HaHaHa {
        color: #ffdf00 !important;
        font-weight: bold !important;
      }

      .Sad {
        color: black !important;
        font-weight: bold !important;
      }

      .WOW {
        color: rgb(64, 11, 211) !important;
        font-weight: bold !important;
      }

      .Care {
        color: rgb(236, 10, 161) !important;
        font-weight: bold !important;
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .div {
    padding: 0 0.5rem !important;
    .row {
      margin: 0 0 1rem 0 !important;

      .card-header {
        p {
          font-size: 0.8rem !important;
        }

        .right :nth-child(1),
        .right :nth-child(2) {
          margin-right: 0.3rem !important;
        }
      }

      .card-header {
        img {
          width: 40px !important;
          height: 40px !important;
          border-radius: 50%;
        }
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items {
            display: inline-block !important;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.548);
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -40px;
            left: 50%;
            width: 50px !important;
            span {
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}

video.video-style {
  width: 100%;
  height: 115px;
}

.col-2 {
}

.dropstart .dropdown-menu {
  right: auto;
  left: 100%;
  cursor: pointer;
}
.dropstart .dropdown-menu:lang(ar) {
  left: auto;
  right: 100%;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin: 5px;
}
ul.dropdown-menu.show {
  box-shadow: 0px 1px 10px 0px;
}

button.btn.btn-secondary.btn-sm {
  margin: 5px;
}

.style-more {
  border-radius: 8px;
  margin: 4px;
  padding: 1px 25px;
  background-color: lightgray;
}
.style-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.style-more {
  border-radius: 8px;
  margin: 4px;
  padding: 1px 25px;
  background-color: lightgray;
}
.cover-saved-items {
  width: 90%;
  height: 120px;
  // width: 267%;
  // height: 137%;
  border-radius: 20%;
  margin-right: 5%;
}
span.content.mr-2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 75ch;
  color: #111;
  font-weight: 600;
}
.img-style {
  width: 20px;
  margin: 0px -25%;
}
@media screen and (max-width: 800px) {
  .img-style {
    width: 20px;
    margin: 0px -55%;
  }
}
.pd-image {
  width: 60px;
  height: 30px;
  border-radius: 50%;
}
</style>
