<template>
  <svg
    height="25"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 192 192"
  >
    <linearGradient id="a_love" x1="50%" x2="50%" y1="0%" y2="100%">
      <stop offset="0" stop-color="#f65e7a" />
      <stop offset=".051" stop-color="#f65e7a" />
      <stop offset=".1" stop-color="#f65d79" />
      <stop offset=".146" stop-color="#f55c78" />
      <stop offset=".191" stop-color="#f45b76" />
      <stop offset=".233" stop-color="#f35974" />
      <stop offset=".274" stop-color="#f25771" />
      <stop offset=".314" stop-color="#f1546f" />
      <stop offset=".353" stop-color="#f0526b" />
      <stop offset=".39" stop-color="#ee4f68" />
      <stop offset=".427" stop-color="#ed4b64" />
      <stop offset=".464" stop-color="#eb4860" />
      <stop offset=".5" stop-color="#e9445c" />
      <stop offset=".536" stop-color="#e84057" />
      <stop offset=".573" stop-color="#e63c53" />
      <stop offset=".61" stop-color="#e5374e" />
      <stop offset=".647" stop-color="#e33349" />
      <stop offset=".686" stop-color="#e22e44" />
      <stop offset=".726" stop-color="#e02940" />
      <stop offset=".767" stop-color="#df253b" />
      <stop offset=".809" stop-color="#de2037" />
      <stop offset=".854" stop-color="#dd1c33" />
      <stop offset=".9" stop-color="#dd1830" />
      <stop offset=".949" stop-color="#dc152e" />
      <stop offset="1" stop-color="#dc142d" />
    </linearGradient>
    <g fill="none" fill-rule="evenodd">
      <circle cx="96" cy="96" fill="url(#a_love)" r="96" />
      <path
        d="M95.926 70.264c1.666-5.311 5.057-9.77 10.171-13.374 8.485-5.982 29.714-7.652 40.268 8.14 10.555 15.791 5.613 37.04-10.554 53.746-10.555 10.905-23.674 21.075-39.358 30.508a2 2 0 0 1-2.018.026c-13.021-7.386-26.062-17.564-39.12-30.534-20.1-19.962-21.546-37.989-10.773-53.747 10.772-15.757 31.73-14.12 40.215-8.14 5.115 3.606 8.52 8.065 10.215 13.377a.5.5 0 0 0 .954-.002z"
        fill="#fff"
      />
    </g>
  </svg>
</template>
