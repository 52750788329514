import gql from "graphql-tag";

export const RATING = gql`
  mutation Rating($page_id: Int, $id: Int, $rate: Int) {
    Rating(rate: $rate, page_id: $page_id, id: $id) {
      id
      rate
      ratesCount
      reviewed
    }
  }
`;
