<template>
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1500 1500"
    width="25"
    height="25"
  >
    <ellipse fill="#ffda6b" cx="750.6" cy="745.9" rx="750" ry="745.8" />
    <path
      fill="#262c38"
      d="M508.2 802.6c0 51.4-42.5 102.3-94.2 102.3s-93.1-50.9-93.1-102.3 42.5-106.7 94.2-106.7c51.8 0 93.1 55.2 93.1 106.7zM1177.8 802.6c0 51.4-42.5 102.3-94.2 102.3s-93.1-50.9-93.1-102.3 42.5-106.7 94.2-106.7 93.1 55.2 93.1 106.7z"
    />
    <path
      fill="none"
      stroke="#262c38"
      stroke-width="60"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      d="M287.9 647.6s44-106.8 172.4-83.9M1213.9 647.6s-44-106.8-172.4-83.9M571.6 1174s172.4-183.2 356.6 0"
    />
    <path
      fill="#6485c3"
      d="M1287.1 1329s-46.5-145.5-98.7-230.3l-2.8 5c0-.1 0-.1 0 0 0 0-52.9 97.7-98.6 219.1-8.8 23.3-13.3 48.1-11.8 73 2.7 45.7 24.8 104.4 120.4 104.4.1 0 141.4-9 91.5-171.2z"
    />
  </svg>
</template>
