<template>
  <div class="profile-container">
    <!-- Cover -->
    <div class="cover-profile">
      <img crossorigin="anonymous"  class="cover-img" src="@/assets/cover/images.jpg" alt="Cover Profile Image" />
    </div>
    <div class="profile-details" >
      <div class="pd-left">
        <div class="pd-row" style="position: relative">
          <div  v-if="info_collection.pivot.role == 2">
            <a
              class="dropdown"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="5"
                viewBox="0 0 5 5"
              >
                <circle
                  id="Ellipse_181"
                  data-name="Ellipse 181"
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  fill="#a5a5a5"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="5"
                viewBox="0 0 5 5"
              >
                <circle
                  id="Ellipse_181"
                  data-name="Ellipse 181"
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  fill="#a5a5a5"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5"
                height="5"
                viewBox="0 0 5 5"
              >
                <circle
                  id="Ellipse_181"
                  data-name="Ellipse 181"
                  cx="2.5"
                  cy="2.5"
                  r="2.5"
                  fill="#a5a5a5"
                />
              </svg>
            </a>
            <ul
              style="cursor: pointer"
              class="dropdown-menu"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <a
                  v-if="info_collection.pivot.role != null"
                  class="dropdown-item"
                  data-bs-toggle="modal"
                  :data-bs-target="'#staticBackdropMembers' + info_collection.id"
                >
                  {{ $t("Show Members Collection") }}
                </a>
              </li>
              <li>
                <a
                  v-if="info_collection.pivot.role == 2"
                  class="dropdown-item"
                  data-bs-toggle="modal"
                  :data-bs-target="'#exampleModalUpdateCollection' + info_collection.id"
                >
                  {{ $t("Rename Collection") }}
                </a>
              </li>
              <li>
                <a
                  v-if="info_collection.pivot.role == 2"
                  class="dropdown-item"
                  data-bs-toggle="modal"
                  :data-bs-target="'#staticDelete'"
                >
                  {{ $t("Delete") }}
                </a>
              </li>
            </ul>

            <button
              v-if="info_collection.pivot.role == 2"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              class="btn btn-sm btn-primary"
            >
              {{ $t("invitation") }}
            </button>
            <InvitationComponent :collection="info_collection"></InvitationComponent>
            <!-- <p>{{ this.friends.length }} Friend</p> -->
            <button
              v-if="info_collection.pivot.role == 1"
              class="btn btn-sm btn-danger"
              @click="leave_collection()"
            >
              {{ $t("Leave Collection") }}
            </button>
          </div>
          <span v-if="this.collection_members.length != 0">
            <img crossorigin="anonymous" 
              v-if="this.collection_members[0].has_media_profile === false"
              class="pd-image"
              src="@/assets/img_friends/default1.png"
              alt="Profile image"
              title="Owner this Collection"
            />
            <img crossorigin="anonymous" 
              v-else-if="
                this.collection_members[0].media[0].collection_name === 'profile'
              "
              :src="this.collection_members[0].media[0].src_url"
              class="pd-image"
              alt="personal profile image"
              title="Owner this Collection"
            />
            <img crossorigin="anonymous" 
              v-else-if="
                this.collection_members[0].media[1].collection_name === 'profile'
              "
              :src="this.collection_members[0].media[1].src_url"
              class="pd-image"
              alt="personal profile image"
              title="Owner this Collection"
            />
          </span>
        </div>
      </div>
      <!-- <div class="pd-right" v-if="show_collection.length > 0">
        <h3>{{ show_collection[0].name }}</h3>
        <p>public</p>
      </div> -->
      <div class="pd-right">
        <h3>{{ info_collection.name }}</h3>
        <p>{{ $t("Saved Items") }} ( {{ info_collection.items_count }} )</p>
      </div>
    </div>
    <ItemsCollection></ItemsCollection>
  </div>
  <ShowMembers :id="info_collection.id"></ShowMembers>
  <UpdateCollections
    :collection="info_collection"
    :id="info_collection.id"
  ></UpdateCollections>
  <DeleteCollection :id="this.id"></DeleteCollection>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import DeleteCollection from "./Buttons/DeleteCollection.vue";
import UpdateCollections from "./Buttons/UpdateCollections.vue";
import InvitationComponent from "./Buttons/InvitationComponent.vue";
import ShowMembers from "./Buttons/ShowMembers.vue";
import ItemsCollection from "./ItemsCollection.vue";
import CollectionsServices from "@/services/Collections/CollectionsServices";
export default {
  name: "ShowCollection",
  data() {
    return {
      id: this.$route.params.id,
    };
  },
  created() {
    // this.$store.dispatch("collections/");

    this.$store.dispatch("collections/a_get_info_items_collection", this.id);
    this.$store.dispatch("collections/a_get_collection_members", this.id);
    // this.UPDATE_SHOW_COLLECTION(this.id);
  },
  methods: {
    leave_collection() {
      CollectionsServices.s_leave_collection(this.id).then(() => {
        this.showSuccess({ message: "Leaved this collection successfully" });
        this.$router.push({
          name: "home",
        });
      });
    },
    // ...mapMutations({
    //   UPDATE_SHOW_COLLECTION: "collections/UPDATE_SHOW_COLLECTION",
    // }),
  },
  computed: {
    ...mapGetters({
      info_collection: "collections/info_collection",
      collection_members: "collections/collection_members",
    }),
    ...mapState("collections", {
      show_collection: "show_collection",
    }),
  },
  components: {
    InvitationComponent,
    DeleteCollection,
    UpdateCollections,
    ShowMembers,
    ItemsCollection,
  },
  notifications: {
    showSuccess: {
      title: "Collection",
      message: "leave collection   successfully",
      type: "success",
    },
    showError: {
      title: "Collection",
      message: "Something went Wrong",
      type: "error",
    },
  },
};
</script>
<style lang="scss" scoped>
.cover-img {
  width: 100%;
  height: 25rem;
  border-radius: 6px;
  // margin-bottom: 14px;
}
.dropstart .dropdown-menu {
  right: auto;
  left: 100%;
  cursor: pointer;
}
.dropstart .dropdown-menu:lang(ar) {
  left: auto;
  right: 100%;
  cursor: pointer;
}
.container {
  margin: -14px auto;
  // width: 1200px;
  .tabs-choose {
    margin-bottom: 0;
    li {
      display: inline-block;
      padding: 10px 20px;
      background-color: #eee;
      cursor: pointer;
      opacity: 0.3;
      &.active {
        opacity: 1;
      }
    }
  }
  .tabs-content {
    background-color: #eee;
    margin: 0 auto;
    padding: 20px;
  }
}
.post-col ul li {
  list-style: none;
  font-size: 15px;
  margin: 15px 0;
  align-items: center;
}
.post-col ul li img {
  width: 26px;
  margin-right: 10px;
}
/*  */
/* profile page */
.profile-container {
  padding: 20px 15%;
  background: #ddd;
}
.profile-details {
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 1px;
}
.pd-row {
  display: flex;
  align-items: flex-start;
}
.pd-image {
  width: 40px;
  height: 35px;
  margin: 0px 15px;
  border-radius: 6px;
  margin-bottom: 10px;
}
.pd-row div h3 {
  font-size: 25px;
  font-weight: 600;
  margin: 5px;
}
.pd-row div p {
  font-size: 13px;
  margin: 2px;
}

.pd-row div img {
  width: 25px;
  border-radius: 50%;
  margin-top: 12px;
}
.pd-right a img {
  width: 20px;
}
.profile-info {
  display: flex;
  align-self: flex-start;
  justify-content: space-between;
  margin-top: 20px;
}
.info-col {
  flex-basis: 33%;
  flex-grow: 1;
  flex-basis: 300px;
  align-self: start;
  position: sticky;
  top: 2rem;
}
@media (max-width: 900px) {
  .info-col {
    flex-basis: 33%;
    flex-grow: 0;
    flex-basis: 0px;
    align-self: flex-end;
    position: initial;
    top: 0rem;
  }
}
.post-col {
  flex-basis: 65%;
}
.write-post-container {
  width: 100%;
  background: #fff;
  border-radius: 6px;
  padding: 20px;
  color: #626262;
  margin: 0px 5px 10px 0px;
}
.profile-intro {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  margin-right: 10px;
}

.profile-intro h3 {
  font-weight: 600;
}

.intro-text {
  text-align: 10px 0;
  font-size: 15px;
}

.intro-text img {
  width: 15px;
  margin-bottom: -3px;
}

.profile-intro hr {
  border: 0;
  height: 1px;
  background: #ccc;
  margin: 24px 0;
}

.profile-intro ul li {
  list-style: none;
  font-size: 15px;
  margin: 15px 0;
  display: flex;
  align-items: center;
}

.profile-intro ul li img {
  width: 26px;
  margin-right: 10px;
}

.title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: -7px 0px -8px 0px;
}

.title-box a {
  text-decoration: none;
  color: #1876f2;
  font-size: 14px;
}

.photo-box {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 10px;
  margin-top: 15px;
}

.photo-box div img {
  // width: 100%;
  cursor: pointer;
  width: 75px;
  height: 60px;
  border: 1px solid rgb(175, 171, 171);
  margin: 2px 5px;
  border-radius: 7px;
}

.profile-intro p {
  font-size: 14px;
}

/* media query for personal profile page */
@media (max-width: 900px) {
  .profile-container {
    padding: 20px 5%;
  }
  .profile-details {
    flex-wrap: wrap;
  }

  .pd-right {
    text-align: left;
    margin-top: 15px;
  }
  .pd-right:lang(ar) {
    text-align: right;
    margin-top: 15px;
  }

  .pd-right button {
    margin-left: 10px;
  }

  .pd-right div h3 {
    font-size: 16px;
  }

  .profile-info {
    flex-wrap: wrap;
  }

  .info-col,
  .post-col {
    flex-basis: 100%;
  }
}

@media (max-width: 600px) {
  .pd-row div h3 {
    font-size: 18px;
  }
}

.profile-details:lang(ar) {
  direction: rtl;
}
.profile-details:lang(ku) {
  direction: rtl;
}

.profile-intro:lang(ar) {
  direction: rtl;
}
.profile-intro:lang(ku) {
  direction: rtl;
}
.profile-info:lang(ar) {
  direction: rtl;
}
.profile-info:lang(ku) {
  direction: rtl;
}
.pd-right:lang(ar) {
  direction: ltr;
}
.pd-right:lang(ku) {
  direction: ltr;
}
.modal:lang(ar) {
  direction: rtl;
}
.modal:lang(ku) {
  direction: rtl;
}
.close-dir:lang(ar) {
  direction: ltr;
}
.close-dir:lang(ku) {
  direction: ltr;
}
.pd-row div img:lang(ar) {
  margin: 2px 10px;
}
.pd-row div img:lang(ku) {
  margin: 2px 10px;
}
.pd-row div h3:lang(ar) {
  margin: 2px 10px;
}
.pd-row div h3:lang(ku) {
  margin: 2px 10px;
}
.pd-row div p:lang(ar) {
  margin: 2px 10px;
}
.pd-row div p:lang(ku) {
  margin: 2px 10px;
}

.text-muted:lang(ar) {
  margin-right: 10px;
}
.text-muted:lang(ku) {
  margin-right: 10px;
}

button.btn-edit-cover {
  position: relative;
  float: right;
  margin: -59px 36px;
  border: 1px;
  border-radius: 7px;
  background: aliceblue;
}

.btn-edit-profile {
  margin-bottom: 6px;
}
.modal-content {
  border-radius: 25px;
}
.video-style {
  height: 75px;
  cursor: pointer;
  margin: 0px 4px;
  border-radius: 6px;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.dropdown-menu {
  box-shadow: 0px 1px 10px 0px;
}
.dropdown {
  background: lightgray;
  border-radius: 6px;
  padding: 3px 12px 5px 11px;
  margin: 1px 5px;
}
@media screen and (max-width: 991px) {
  .dropdown {
    background: lightgray;
    border-radius: 6px;
    padding: 1px 6px 3px 7px;
    margin: 1px;
  }
}
</style>
