<template>
  <div dir="auto">
    <!-- Input what are you thinking about? -->
    <WhatThinkingComponent :page="page" :USER="USER"> </WhatThinkingComponent>
  </div>
</template>

<script>
import WhatThinkingComponent from "@/Elements/post/addPostForm/allTypes/WhatThinkingComponent.vue";
import { mapGetters } from "vuex";
export default {
  name: "AddPostComponent",
  data() {
    return {
      page: localStorage.getItem("page")
        ? JSON.parse(localStorage.getItem("page"))
        : null,
    };
  },
  computed: {
    ...mapGetters({
      USER: "auth/USER",
    }),
  },
  components: {
    WhatThinkingComponent,
  },
};
</script>