import gql from "graphql-tag";
import { POST_DATA } from "../PostData";
export const UPDATE_QUESTION = gql`
${POST_DATA}
  mutation UpdateQuestion(
    $post_id: Int
    $content: String
    $privacy: String
    $custom_profile_ids: [Int]
    $delete_friends_ids: [Int]
    $friends_ids: [Int]
    $delete_media: [Int]
    $media: [MediaInput]
  ) {
    UpdateQuestion(
      post_id: $post_id
      content: $content
      privacy: $privacy
      custom_profile_ids: $custom_profile_ids
      delete_friends_ids: $delete_friends_ids
      friends_ids: $friends_ids
      delete_media: $delete_media
      media: $media
    ) {
      ...PostData
    }
  }
`;
