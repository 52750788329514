<template>
  <div class="vcomments__item">
    <div class="comment-avatar">
      <img crossorigin="anonymous" 
        v-if="comment.profile.has_media_profile === false"
        src="@/assets/story_img/user.png"
        alt=""
      />
      <img crossorigin="anonymous" 
        v-else-if="
          comment.profile.has_media_profile === true &&
          comment.profile.media[0].collection_name === 'profile'
        "
        class="pd-image"
        :src="comment.profile.media[0].src_url"
        alt=""
        v-viewer
      />
      <img crossorigin="anonymous" 
        v-else-if="
          comment.profile.has_media_profile === true &&
          comment.profile.media[1].collection_name === 'profile'
        "
        class="pd-image"
        :src="comment.profile.media[1].src_url"
        alt=""
        v-viewer
      />
    </div>
    <div class="vcomments__info">
      <span class="vcomments__author"
        ><router-link
          class="router-link-to-profile"
          :to="{
            name: 'homeMyProfile',
                params: { id: EncryptionRoute(comment.profile.id) },
          }"
          >{{ comment.profile.name }}</router-link
        ></span
      >
      <span class="vcomments__time">{{ format_date(comment.created_at) }}</span>
      <div
        v-if="this.USER.id == comment.profile.id"
        class="dropdown text-center"
      >
        <small
          style="cursor: pointer"
          :id="'dropdownMenuButton1' + comment.id"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          ><img crossorigin="anonymous" 
            class="image-dropdown"
            src="@/assets/images/more.png"
            alt="action more"
          />
        </small>
        <ul
          class="dropdown-menu"
          :aria-labelledby="'dropdownMenuButton1' + comment.id"
        >
          <li>
            <a
              class="dropdown-item"
              style="cursor: pointer"
              @click="delete_comment(comment)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path fill="none" d="M0 0h24v24H0V0z" />
                <path
                  d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2H8c-1.1 0-2 .9-2 2v10zM18 4h-2.5l-.71-.71c-.18-.18-.44-.29-.7-.29H9.91c-.26 0-.52.11-.7.29L8.5 4H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z"
                />
              </svg>
              {{ $t("Delete") }}
            </a>
          </li>
          <li
            class="dropdown-item"
            style="cursor: pointer"
            @click="ShowEditeBox(comment)"
          >
            <img crossorigin="anonymous" 
              src="@/assets/images/edit_mode_icon.png"
              style="width: 20px; height: 20px"
              alt=""
            />
            {{ $t("Edit") }}
          </li>
        </ul>
      </div>
    </div>
    <!-- content reply and mentions -->
    <div class="card-body m-3" dir="auto">
      <div class="card-text vcomments__quote">


        <div :id="`boxreply${comment.id}`">
          <div v-if="comment.content" v-html="this.showcontentReply(comment.content, comment.tags)"
            v-linkified></div>
          <div class="text-center" v-if="comment.media">
            <img crossorigin="anonymous"  v-if="comment.media.media_type == 'Image'" :src="comment.media.src_url" alt="ReplyImage"
              style="max-width: 150px;max-height: 150px;min-height: 75px;min-width: 75px;">
            <audio v-else :src="comment.media.src_url" controls>
            </audio>
          </div>
        </div>

        
        <div class="visually-hidden vcomments__add-block" :id="`Soundboxreplyedite${comment.id}`">
          <Mentionable class="w-100 Mentionable" :keys="['@']" :items="friendstagsarray" filtering-disabled offset="6"
            insert-space @open="loadIssues()" @search="loadIssues($event)">
            <EmojiPicker v-click-outside="onClickOutside" v-if="this.visible_emoji == true" :native="true"
              @select="onSelectEmoji" />
            <textarea class="vcomments__add-input ar_vcomment" dir="auto" @input="resize()" ref="textarea"
              v-model="replyedite"></textarea>
            <span :class="DefineDeriction() ? 'commentsvgboxar' : 'commentsvgbox'" v-if="!recording">
              <span class="pointer" @click="open_emoji">
                <EmojiSvg></EmojiSvg>
              </span>

              <svg @click="startRecord" class=" pointer" width="25" height="25" fill="#7a7a7a" viewBox="0 0 1920 1920"
                xmlns="http://www.w3.org/2000/svg">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round">
                </g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M960.315 96.818c-186.858 0-338.862 152.003-338.862 338.861v484.088c0 186.858 152.004 338.862 338.862 338.862 186.858 0 338.861-152.004 338.861-338.862V435.68c0-186.858-152.003-338.861-338.861-338.861M427.818 709.983V943.41c0 293.551 238.946 532.497 532.497 532.497 293.55 0 532.496-238.946 532.496-532.497V709.983h96.818V943.41c0 330.707-256.438 602.668-580.9 627.471l-.006 252.301h242.044V1920H669.862v-96.818h242.043l-.004-252.3C587.438 1546.077 331 1274.116 331 943.41V709.983h96.818ZM960.315 0c240.204 0 435.679 195.475 435.679 435.68v484.087c0 240.205-195.475 435.68-435.68 435.68-240.204 0-435.679-195.475-435.679-435.68V435.68C524.635 195.475 720.11 0 960.315 0Z"
                    fill-rule="evenodd"></path>
                </g>
              </svg>
            </span>
            <!-- For Voice In Edit to add Record -->
            <span :class="DefineDeriction() ? 'audoicontrolcommentsvgboxar' : 'audoicontrolcommentsvgbox'" class=""
              v-else>
              <span class="text-danger" id="timerInEditeReplyOnComment">00:00</span>
              <svg @click="clearRecord" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 22"
                class="pointer mx-1" style="color: #ff7f7f;">
                <path d="M5,0,3,2H0V4H16V2H13L11,0ZM15,5H1V19.5A2.5,2.5,0,0,0,3.5,22h9A2.5,2.5,0,0,0,15,19.5Z"
                  fill="currentColor"></path>
              </svg>
            </span>
            <template #no-result>
              <div class="allfriendsbox" v-if="loadingForNewREsultInMentaion">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="allfriendsbox" v-else>
                {{ $t('No Result') }}
              </div>
            </template>

            <template #item-@="{ item }">
              <div class="allfriendsbox">
                {{ item.name }}
              </div>
            </template>
          </Mentionable>
          <!-- <textarea v-model="replyedite" id="" cols="" rows=""></textarea> -->
          <button class="btn btn-sm ms-3" style="background: #8f5fee; font-weight: 600" @click="sendMessage()">
            {{ $t("Edit") }}
          </button>
          <button v-if="!loadingStatusForEditeComment" class="btn btn-sm ms-3" style="background-color: #ddd"
            @click="cancle_update_comment(comment)">
            {{ $t("Cancel") }}
          </button>
        </div>

        <div class="visually-hidden vcomments__add-block" :id="`Otherboxreplyedite${comment.id}`">
          <Mentionable class="w-100 Mentionable" :keys="['@']" :items="friendstagsarray" filtering-disabled offset="6"
            insert-space @open="loadIssues()" @search="loadIssues($event)">
            <EmojiPicker v-click-outside="onClickOutside" v-if="this.visible_emoji == true" :native="true"
              @select="onSelectEmoji" />
            <textarea class="vcomments__add-input ar_vcomment" dir="auto" @input="resize()" ref="textarea"
              v-model="replyedite"></textarea>
            <span :class="DefineDeriction() ? 'commentsvgboxar' : 'commentsvgbox'">
              <span class="pointer" @click="open_emoji">
                <EmojiSvg></EmojiSvg>
              </span>

            </span>
            <template #no-result>
              <div class="allfriendsbox" v-if="loadingForNewREsultInMentaion">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span class="visually-hidden">Loading...</span>
              </div>
              <div class="allfriendsbox" v-else>
                {{ $t('No Result') }}
              </div>
            </template>

            <template #item-@="{ item }">
              <div class="allfriendsbox">
                {{ item.name }}
              </div>
            </template>
          </Mentionable>
          <!-- For Media -->
          <div class="d-flex mt-2 justify-content-start align-items-center">
            <button type="button" v-if="!IfdeleteImageFromCommentOrNot && !Commentmedia && comment.media && !onlyVoice"
              class="btn-close d-block" @click="deleteImageFromComment" aria-label="Close"></button>
            <label v-if="!onlyVoice" :for="`uploadMediaFromComment${comment.id}`" class="mx-2 pointer">
              <AddMediaComment></AddMediaComment>
            </label>
            <input type="file" :id="`uploadMediaFromComment${comment.id}`" ref="uploadMediaFroEditReplyOnComment"
              @change="uploadMediaFroEditReplyOnComment()" hidden accept="image/*" />
          </div>
          <!-- For Media -->

          <div class="mt-2 text-center position-relative">
                <img crossorigin="anonymous"  v-if="Commentmedia" :src="Commentmedia.url"
                style="min-width: 75px;min-height: 75px; max-width: 150px;max-height: 150px;" >
                <img crossorigin="anonymous"  v-if="!IfdeleteImageFromCommentOrNot && !Commentmedia && (comment.media && comment.media.media_type == 'Image')" :src="comment.media.src_url" style="min-width: 75px;min-height: 75px; max-width: 150px;max-height: 150px;" alt="">
              </div>
              <br />

          <button class="btn btn-sm ms-3" style="background: #8f5fee; font-weight: 600" @click="edit(comment.id)"
          :disabled="((replyedite == '' || replyedite == null) && !Commentmedia && (!comment.media ||(comment.media && IfdeleteImageFromCommentOrNot)))"

          >
            {{ $t("Edit") }}
          </button>
          <button v-if="!loadingStatusForEditeComment" class="btn btn-sm ms-3" style="background-color: #ddd"
            @click="cancle_update_comment(comment)">
            {{ $t("Cancel") }}
          </button>
        </div>

        
      </div>
    </div>
    <AddReactionToReplies
      v-on:addreaction="addreaction"
      :comment="comment"
    ></AddReactionToReplies>
  </div>
</template>

<script>
import GroupPostServices from "@/services/GroupsServices/GroupPostServices";
import AddReactionToReplies from "./AddReactionToReplies.vue";
import { Mentionable } from "vue-mention";
import formatTime from "@/mixins/formatTime";
import FriendshipService from "@/services/Friendships/FriendshipService";
import { mapActions, mapGetters } from "vuex";
import {EncryptionRoute} from "@/utils/EncryptionRoute";

import vClickOutside from "click-outside-vue3";
// import picker compopnent
import EmojiPicker from "vue3-emoji-picker";
// import css
import "vue3-emoji-picker/css";

import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { uuid } from "vue-uuid";
import AddMediaComment from "@/icons/Groups/AddMediaComment.vue";

import EmojiSvg from "@/icons/GlobalIcon/EmojiSvg.vue";
import LangMixin from "@/mixins/LangMixin";

export default {
  props: ["comment", "friendstagsarray"],
  // mixins: [formatTime],
  mixins: [formatTime, LangMixin],
  data() {
    return {
      body_reply: this.comment.comment_body,
      replyedite: this.comment.comment_body,
      friends_ids: [],
      delete_friends_ids: [],
      loadingStatusForEditeComment: false,
      loadingForNewREsultInMentaion: false,

      
      IfdeleteImageFromCommentOrNot: false, // true => i delete image from comment
      media: null,
      Commentmedia: null,
      datasender: null,

      // Voice Edit
      visible_emoji: false,
      timer: null,
      audioBlob: null,
      audioAction: '',
      recording: false,
      // Voice Edit
    };
  },
  created() {
    this.friendstagsarray.length == 0 ? this.get_all_friends() : ""; // if we refresh the page execute get all friends to have array tags
    this.Allfriendstagsarray.length == 0
      ? this.get_all_friends_For_Array_tags()
      : "";
    // console.log('aaa')
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  methods: {
    

    onClickOutside() {
      this.visible_emoji = false;
    },
    open_emoji() {
      if (this.visible_emoji == false) {
        this.visible_emoji = true;
      } else {
        this.visible_emoji = false;
      }
    },
    onSelectEmoji(emoji) {
      this.replyedite += emoji.i;
    },
    resize() {
      let element = this.$refs["textarea"];

      element.style.height = "11px";
      element.style.height = element.scrollHeight + "px";
    },

    // For Add Audio Comment 
    updateTimer(startTime) {
      this.timer = setInterval(function () {
        var currentTime = new Date().getTime();
        var elapsedTime = new Date(currentTime - startTime);
        var minutes = String(elapsedTime.getMinutes()).padStart(2, '0');
        var seconds = String(elapsedTime.getSeconds()).padStart(2, '0');
        document.getElementById('timerInEditeReplyOnComment').innerHTML = `${minutes}:${seconds}`;
      }, 1000);
    },
    async sendMessage() {
      if (this.recording) {
        this.audioAction = 'send';
        this.mediaRecorder.stop();
        this.audioContext.close();
        clearInterval(this.timer)
        this.recording = false;
      } else {
        let data = {
          comment_id: this.comment.id,
        };
        if (this.replyedite && this.replyedite != "") {
          let result = await this.filterCommentOrReplyContent(this.replyedite);
          if (result.length != 0) {
            data.content = result;
          }
        } else {
          data.content = null
        }
        if (this.friends_ids.length > 0) {
          data.friends_ids = this.friends_ids;
        } else {
          data.friends_ids = []
        }


        await GroupPostServices.Update_Comment(data).then(
          (res) => {
            this.$emit("editreply", res.data.UpdateGroupComment);
            this.friends_ids = [];
          }
        );
        document
          .getElementById(`boxreply${this.comment.id}`)
          .classList.remove("visually-hidden");
        document
          .getElementById(`Soundboxreplyedite${this.comment.id}`)
          .classList.add("visually-hidden");
      }
    },
    clearRecord() {
      if (this.recording) {
        clearInterval(this.timer);
        this.mediaRecorder.stop();
        this.audioContext.close();
        this.recording = false;
      }
    },
    async startRecord() {
      this.loadingStoreComment = true
      this.media = null
      this.audioChunks = [];
      navigator.mediaDevices.getUserMedia({ audio: true, video: false })
        .then((stream) => {
          var currentTime = new Date().getTime();
          this.updateTimer(currentTime);
          this.audioContext = new AudioContext();
          this.mediaRecorder = new MediaRecorder(stream, { mimeType: "audio/webm" });
          this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              this.audioChunks.push(event.data);
            }
          };
          this.mediaRecorder.onstop = async () => {
            if (this.audioAction == 'send') {
              this.audioBlob = new Blob(this.audioChunks, { type: 'audio/mp3' });
              let storage = getStorage();
              let unique_number = uuid.v4();
              const metadata = {
                contentType: 'audio/mp3',
              };
              let storageRef = ref(storage, "CommentVoice/" + unique_number);
              let item = null;
              const uploadTask = uploadBytesResumable(storageRef, this.audioBlob, metadata);
              uploadTask.on('state_changed',
                (snapshot) => {
                  const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                  this.progressWidth = `${progress}`
                },
              );
              // Progress Bar
              await uploadBytes(storageRef, this.media)
                .then((snapshot) => {
                  let meme = snapshot.metadata.contentType.split("/");
                  item = {
                    size: snapshot.metadata.size,
                    media_type: 'Sound',
                    mime_type: meme[1],
                    fullPath: snapshot.metadata.fullPath,
                  };
                  this.datasender = item;
                })
              await getDownloadURL(storageRef).then((url) => {
                item.src_url = url;
                item.src_thum = url;
                item.src_icon = url;
              });
              let data = {
                comment_id: this.comment.id,
              };
              data.media = this.datasender
              if (this.replyedite && this.replyedite != "") {
                let result = await this.filterCommentOrReplyContent(this.replyedite);
                if (result.length != 0) {
                  data.content = result;
                }
              } else {
                data.content = null
              }
              
              if (this.friends_ids.length > 0) {
                data.friends_ids = this.friends_ids;
              } else {
                data.friends_ids = []
              }


              await GroupPostServices.Update_Comment(data).then(
                (res) => {
                  this.$emit("editreply", res.data.UpdateGroupComment);
                  this.friends_ids = [];
                  this.delete_friends_ids = [];
                }
              );
              document
                .getElementById(`boxreply${this.comment.id}`)
                .classList.remove("visually-hidden");
              document
                .getElementById(`Soundboxreplyedite${this.comment.id}`)
                .classList.add("visually-hidden");
            }
            clearInterval(this.timer);
          };
          this.mediaRecorder.start();
          this.recording = true;
        }).catch((error) => {
          console.error('Error accessing microphone:', error);
        });

      this.loadingStoreComment = false
    },

    // For Add Audio Comment

    EncryptionRoute,
    async loadIssues(searchText = null) {
      // console.log(searchText)
      if (searchText != null) {
        this.loadingForNewREsultInMentaion = true;
        await FriendshipService.searchFriends(searchText).then(
          (res) => {
            // console.log(res.data.data.items.length)
            if (res.data.FriendQuery.items.length > 0) {
              this.$store.commit(
                "friendStore/SET_NEW_friendstagsarray",
                res.data.FriendQuery.items
              );
            }
          }
        );
        this.loadingForNewREsultInMentaion = false;
      } else {
        if (this.friendstagsarray.length < 4) {
          this.loadingForNewREsultInMentaion = true;
          this.$store.commit("friendStore/SET_NEW_friendstagsarray", []);
          await FriendshipService.searchFriends(searchText).then(
            (res) => {
              this.$store.commit(
                "friendStore/SET_NEW_friendstagsarray",
                res.data.FriendQuery.items
              );
            }
          );
          this.loadingForNewREsultInMentaion = false;
        }
      }
    },
    cancle_update_comment(comment) {
      if (comment.media && comment.media.media_type == 'Sound') {
        document
          .getElementById(`boxreply${comment.id}`)
          .classList.remove("visually-hidden");
        document
          .getElementById(`Soundboxreplyedite${comment.id}`)
          .classList.add("visually-hidden");
      } else {
        document
          .getElementById(`boxreply${comment.id}`)
          .classList.remove("visually-hidden");
        document
          .getElementById(`Otherboxreplyedite${comment.id}`)
          .classList.add("visually-hidden");
      }
    },

    addreaction(data) {
      // let newdata = {
      //   data: data,
      //   id: this.comment.id,
      // };
      this.$emit("addreactiontoreply", data);
    },
    delete_comment(comment) {
      this.$emit("deletereply", comment);
      this.$snotify.success(
        this.$i18n.t("Deleted successfully"),
        this.$i18n.t("Comment")
      );
    },
    showcontentReply(content, array) {
      if (content == null) {
        array.forEach((user) => {
          let a = `<a 
          href="/profile/${this.EncryptionRoute(user.id)}
          "
          ><p class="mb-1 me-1" 
                    style="cursor:pointer;font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
                  >
                    ${user.name.replaceAll(" ", "_")}
                  </p></a>`;

          content = content.replace("^@^", a);
          a = null;
        });
        // console.log("AFTER",content)
        content = content.replaceAll("^@^", "");
        return content;
      }
      if (array.length == 0) {
        return content;
      } else {
        array.forEach((user) => {
          let a = `<a 
          href="/profile/${this.EncryptionRoute(user.id)}
          "
          ><p class="mb-1 me-1" 
                    style="cursor:pointer; font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
                  >
                    ${user.name.replaceAll(" ", "_")}
                  </p></a>`;

          content = content.replace("^@^", a);
          a = null;
        });
        // console.log("AFTER",content)
        content = content.replaceAll("^@^", "");
        return content;
      }
    },
    // showcontentReply(content, array) {
    //   if (content == null) {
    //     array.forEach((user) => {
    //       let a = `<a 
    //       href="/profile/${user.user_id}
    //       "
    //       ><p class="mb-1 me-1" 
    //                 style="cursor:pointer;font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
    //               >
    //                 ${user.name.replaceAll(" ", "_")}
    //               </p></a>`;

    //       content = content.replace("^@^", a);
    //       a = null;
    //     });
    //     // console.log("AFTER",content)
    //     content = content.replaceAll("^@^", "");
    //     return content;
    //   }
    //   if (array.length == 0) {
    //     return content;
    //   } else {
    //     array.forEach((user) => {
    //       console.log(user.pivot.status);
    //       if (user.pivot.status == 1) {
    //         let a = `<a 
    //         href="/profile/${user.user_id}
    //         "
    //         ><p class="mb-1 me-1" 
    //                   style="cursor:pointer; font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
    //                 >
    //                   ${user.name.replaceAll(" ", "_")}
    //                 </p></a>`;

    //         content = content.replace("^@^", a);
    //         a = null;
    //       }
    //       if (user.pivot.status == 0) {
    //         let a = `<a 
    //         href="/profile/${user.user_id}
    //         "
    //         ><p class="mb-1 me-1" 
    //                   style="cursor:pointer; font-weight: bold;color: gray;display: inline-block;padding: 2px 4px;"
    //                 >
    //                 <svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#858585">

    //                   <g id="SVGRepo_bgCarrier" stroke-width="2"/>

    //                   <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>

    //                   <g id="SVGRepo_iconCarrier"> <path d="M5.63605 5.63603L18.364 18.364M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#6c6a6a" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </g>

    //                 </svg>
    //                 ${user.name.replaceAll(" ", "_")}
    //                 </p></a>`;

    //         content = content.replace("^@^", a);
    //         a = null;
    //       }
    //     });
    //     // console.log("AFTER",content)
    //     content = content.replaceAll("^@^", "");
    //     return content;
    //   }
    // },

    ShowEditeBox(data) {
      if (data.media && data.media.media_type == 'Sound') {
        let boxreply = document.getElementById(`boxreply${data.id}`);
        let Soundboxreplyedite = document.getElementById(`Soundboxreplyedite${data.id}`);
        boxreply.classList.add("visually-hidden");
        Soundboxreplyedite.classList.remove("visually-hidden");
        if (data.tags.length == 0) {
          if (data.content != null) {
            data.content = data.content.replaceAll("^@^", "");
            this.replyedite = data.content;
          } else {
            this.replyedite = "";
          }
        } else {
          if (data.content != null) {
            this.replyedite = data.content;
            data.tags.forEach((tag) => {
              this.replyedite = this.replyedite.replace(
                "^@^",
                " " + "@" + tag.name.replaceAll(" ", "_")
              );
            });
          } else {
            this.replyedite = "";
          }
        }
      } else {
        let boxreply = document.getElementById(`boxreply${data.id}`);
        let Otherboxreplyedite = document.getElementById(`Otherboxreplyedite${data.id}`);
        boxreply.classList.add("visually-hidden");
        Otherboxreplyedite.classList.remove("visually-hidden");
        if (data.tags.length == 0) {
          if (data.content != null) {
            data.content = data.content.replaceAll("^@^", "");
            this.replyedite = data.content;
          } else {
            this.replyedite = "";
          }
        } else {
          if (data.content != null) {
            this.replyedite = data.content;
            data.tags.forEach((tag) => {
              this.replyedite = this.replyedite.replace(
                "^@^",
                " " + "@" + tag.name.replaceAll(" ", "_")
              );
            });
          } else {
            this.replyedite = "";
          }
        }
      }
    },
    ...mapActions({
      get_all_friends: "friendStore/get_all_friends",
      get_all_friends_For_Array_tags:
        "friendStore/get_all_friends_For_Array_tags",
    }),

    // For Edit Comment With Media
    deleteImageFromComment(){
      this.IfdeleteImageFromCommentOrNot = true
    },
    uploadMediaFroEditReplyOnComment(){
      this.media = this.$refs.uploadMediaFroEditReplyOnComment.files[0];
      let data = {
        type: this.$refs.uploadMediaFroEditReplyOnComment.files[0].type,
        url: URL.createObjectURL(this.$refs.uploadMediaFroEditReplyOnComment.files[0]),
      }
      this.Commentmedia = data;
      this.IfdeleteImageFromCommentOrNot = true
    },
    // For Edit Comment With Media

    async edit(id) {
     let data = {
        comment_id: id,
      };
      if(this.IfdeleteImageFromCommentOrNot) {
        data.media = null
      }
      if(this.Commentmedia) {
        let storage = getStorage();
        let unique_number = uuid.v4() + this.media.name;
        // let storageRef = ref(storage, "CommentVoice/" + unique_number);
        let storageRef = ref(storage, "CommentImages/" + unique_number);
        let item = null;
        const uploadTask = uploadBytesResumable(storageRef,  this.media);
          uploadTask.on('state_changed', 
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              this.progressWidth = `${progress}`
            }, 
          );
          // Progress Bar
        await uploadBytes(storageRef, this.media)
          .then((snapshot) => {
            let meme = snapshot.metadata.contentType.split("/");
            item = {
              size: snapshot.metadata.size,
              media_type: 'Image',
              mime_type: meme[1],
              fullPath: snapshot.metadata.fullPath,
            };
            this.datasender = item;
          })
        await getDownloadURL(storageRef).then((url) => {
          item.src_url = url;
          item.src_thum = url;
          item.src_icon = url;
        });
        data.media = this.datasender
      }
      if(this.replyedite && this.replyedite != "") {
          let result = await this.filterCommentOrReplyContent(this.replyedite);
          if (result.length != 0) {
            data.content = result;
          }
        }else {
            data.content = null
          }
      if (this.friends_ids.length > 0) {
        data.friends_ids = this.friends_ids;
      }else {
        data.friends_ids = []
      }

      // console.log(data)
      await GroupPostServices.Update_Comment(data).then((res) => {
        // console.log(res.data.data)
        this.$emit("editreply", res.data.UpdateGroupComment);
      });
      document
          .getElementById(`boxreply${this.comment.id}`)
          .classList.remove("visually-hidden");
        document
          .getElementById(`Otherboxreplyedite${this.comment.id}`)
          .classList.add("visually-hidden");
      this.friends_ids = [];
    },
    filterCommentOrReplyContent(content) {
      if (content.match("@[^@ \t\r\n]")) {
        // if content contains tags
        //
        var output = "";
        let array = content.split(" ");
        // split string to array dependence on " "
        // console.log(array)
        array.forEach((item) => {
          if (item !== "") {
            if (!item.startsWith("@")) {
              // if item does not start with @ so it is content
              output = output + item + " ";
            } else {
              // if item start with @ so it is tag

              this.Allfriendstagsarray.forEach((friend) => {
                // selected id from friendstagsarray to any item start with @

                if (friend.value == item.substr(1)) {
                  if (!this.friends_ids.includes(friend.id)) {
                    output = output + "^@^ ";
                    this.friends_ids.push(friend.id);
                  }
                }
              });
              // filter array and remove duplicated values
              // if for any reason the user tage to the same driend more than one
              this.friends_ids = [...new Set(this.friends_ids)];
            }
          }
        });
        if (output.startsWith(" ")) {
          output = output.substring(1);
        }
        if (output.endsWith(" ")) {
          output = output.substring(0, output.length - 1);
        }
        return output; // return string from content contain tags and store tags id in friends_ids array
      } else {
        // content does not contain any tags
        return content;
      }
    },
    async UpdateTagsInUpdateComment(tags) {
      //First add all old tags to deleted array
      tags.forEach((onetag) => {
        this.delete_friends_ids.push(onetag.id);
      });

      var delete_friends_ids = this.delete_friends_ids;

      // check if does not any changes in tags .... means update content only with the same tags
      var is_same =
        this.friends_ids.length == this.delete_friends_ids.length &&
        this.friends_ids.every(
          await function (element) {
            let a = delete_friends_ids.indexOf(element);
            return element === delete_friends_ids[a];
          }
        );
      // console.log(is_same)
      if (is_same) {
        // if true .... Example:  11 @abd @hassn to 00 @abd @hassn
        this.delete_friends_ids = [];
        return this.delete_friends_ids;
      } else {
        // if there is any changes in tags array

        if (this.friends_ids.length == 0) {
          // if deleted all tags from new update comment .... Example:  11 @abd @hassn or another tags to 00
          return this.delete_friends_ids;
        } else if (this.friends_ids.length > this.delete_friends_ids.length) {
          // if there are new tags more than old tags
          // Example:  @abd @hassn to   @ abd @hassn @mohammd
          let a = this.delete_friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          if (result.length == this.delete_friends_ids.length) {
            // we save the old tags and add new tags so delete_friends_ids array must be null
            this.delete_friends_ids = [];
          } else {
            // changes in old tags and add new tags
            // delete from delete_friends_ids array the values that will be in new tags
            result.forEach((item) => {
              const index = this.delete_friends_ids.findIndex((x) => x == item);
              this.delete_friends_ids.splice(index, 1);
            });
          }
          return this.delete_friends_ids;
        } else if (this.friends_ids.length < this.delete_friends_ids.length) {
          // if there are new tags less than old tags
          // Example:  @abd @hassn @mohammd to   @ abd @hassn
          let a = this.friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.delete_friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          // delete from delete_friends_ids array the values that will be in new tags
          result.forEach((item) => {
            const index = this.delete_friends_ids.findIndex((x) => x == item);
            this.delete_friends_ids.splice(index, 1);
          });
          return this.delete_friends_ids;
        } else {
          // change one tag to anoter one tag but not same value .... Example:  @hassn to @abd
          // console.log("mmm")
          let a = this.delete_friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          // console.log(resultd)
          result.forEach((item) => {
            const index = this.delete_friends_ids.findIndex((x) => x == item);
            this.delete_friends_ids.splice(index, 1);
          });
          // console.log(this.delete_friends_ids)
          return this.delete_friends_ids;
        }
      }
    },
  },
  computed: {
    replyText: function () {
      if (this.isReplying) {
        return "Cancel reply";
      } else {
        return "Reply";
      }
    },
    toggleText: function () {
      if (this.isGroupRolledUp) {
        return "ShowReplies";
      } else {
        return "hide";
      }
    },
    replies: function () {
      return this.comment.replies.filter(
        (item) => item.parent_id === this.comment.id
      );
    },
    ...mapGetters({
      friendstagsarray: "friendStore/friendstagsarray",
      Allfriendstagsarray: "friendStore/Allfriendstagsarray",
      USER: "auth/USER",
    }),
  },

  components: { AddReactionToReplies, Mentionable, EmojiPicker, EmojiSvg,AddMediaComment },
};
</script>

<style lang="scss" scoped>
@import "floating-vue/dist/style.css";
@import "@/Styles/Comments/reply/ReplyComponent.scss";




.pointer {
  cursor: pointer;
}


.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0;
  background: transparent;
  border: 0;
}

.emoji-invoker:hover {
  transform: scale(1.1);
}

.emoji-invoker>svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}

.emoji-picker__search {
  display: flex;
}

.emoji-picker__search>input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}

.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}

.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}

.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}

.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}

.v3-emoji-picker {
  position: absolute;
  margin-top: 46px;
  z-index: 1;
  right: 2rem;
}

.v3-emoji-picker:lang(ar),
.v3-emoji-picker:lang(ku),
.v3-emoji-picker:lang(ur) {
  position: absolute;
  margin-top: 46px;
  z-index: 1;
  left: 0 !important;
}

span.emoji-visible {
  position: relative;
  float: right;
  margin: -43px 10px;
  cursor: pointer;
}

.commentsvgbox,
.audoicontrolcommentsvgbox {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  right: 1rem;
}

.commentsvgboxar,
.audoicontrolcommentsvgboxar {
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  left: 1rem;
}

.allfriendsbox {
    background-color: white !important;
    padding: 0.5rem !important;
    cursor: pointer;
    &:hover {
      background-color: rgba(60, 60, 60, 0.856) !important;
      color: white !important;
    }
  }
</style>
