<template>
    <div class="modal fade" dir="auto" id="ModalForSendGiftInPost" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
        aria-labelledby="ModalForSendGiftInPoststaticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title w-100" id="ModalForSendGiftInPoststaticBackdropLabel">{{ $t('Send gift') }}</h5>
                    <button @click="CloseGiftModal" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <LoaderSearch v-if="isLoading_giftsType" />
                    <div class="row" v-else>
                        <div class="col-lg-6 mb-5" v-for="item in giftstypes" :key="item.id">
                            <div class="giftbox p-2">
                                <img crossorigin="anonymous"  class="giftImage" :src="item.img_url" alt="">
                                <div class="d-flex justify-content-between align-items-center">
                                    <p class="fw-bold mb-0 color">{{ item.name }}</p>
                                    <p class="mb-0 text-muted">{{ $t('Coast') }}:  
                                        <strong>{{ item.points_cost }}</strong>
                                    </p>
                                </div>
                                <div class="text-center mt-2">
                                    <button
                                        :disabled="!ModelTypeThatISendToItThisGift || !selectedPostId"
                                    class="btn specialBtngift" @click="SendGiftToPost(item.id)">{{ $t('Send') }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import LoaderSearch from '@/components/OtherComponent/LoaderSearch.vue';
import giftService from '@/services/gifts/gift.service';
export default {
    computed: {
        ...mapGetters({
            isLoading_giftsType: "gift/isLoading_giftsType",
            giftstypes: "gift/giftstypes",
            selectedPostId: "gift/selectedPostId",
            ModelTypeThatISendToItThisGift: "gift/ModelTypeThatISendToItThisGift",
        })
    },
    components: {
        LoaderSearch
    },
    methods: {
        async SendGiftToPost(id){
            let data = {
                model_type: this.ModelTypeThatISendToItThisGift,
                model_id: parseInt(this.selectedPostId) ,
                gift_type_id: parseInt(id)
            }
            try {
                await giftService.Send_Gift_To_Item(data).then( () => {
                    this.$snotify.success(
                        this.$i18n.t("Gift sended Successfully"),
                        this.$i18n.t("Gift")
                    );
                })
            } catch (error) {

                this.$snotify.error(
                    this.$i18n.t("sorry, you do not have enough points to send this gift"),
                    this.$i18n.t("Gift")
                );
            }
        },
        ...mapMutations({
            ClearselectedPostId: "gift/ClearselectedPostId",
            ClearModelTypeThatISendToItThisGift: "gift/ClearModelTypeThatISendToItThisGift",
            }),
        CloseGiftModal(){
            this.ClearselectedPostId()
            this.ClearModelTypeThatISendToItThisGift()
    
        }
    },
}
</script>

<style lang="scss" scoped>
.specialBtngift {
    border: 1px solid #983baf;
    background-color: #983baf;
    color: white;
    border-radius: 0;
    padding: 5px 35px;

    &:hover {
        background-color: #983baf;
    color: white;
    }
}

.giftbox {
    border: 1px solid #983baf;
    border-radius: 5px;
    text-align: center;
}
.giftImage {
    width: 100%;
    object-fit: contain;
    height: 100px;
}

.color {
    color: #983baf !important;
}

@media screen and (max-width: 992px) {
  .modal {
    z-index: 999999 !important;
  }
}
</style>