<template>
  <div>
    <div
      class="modal fade"
      :id="'staticBackdropMembers' + id"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropViewsLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title w-100" dir="rtl" id="staticBackdropViewsLabel">{{ $t("Members") }}</h5>
            <div class="close-dir">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
          </div>
          <div class="modal-body">
            <!-- <input type="checkbox" v-model="multipleSelect" @change="selectAllMembers" /> -->
            <div v-if="collection_members.length > 0" class="modal-body">
              <div v-for="(item, index) in collection_members" :key="index">
                <label class="container">
                  <div class="row">
                    <div>
                      <input
                        v-if="item.pivot.role != 2"
                        type="checkbox"
                        v-model="SingleSelect"
                        @change="selectAllMembers"
                        :value="item.id"
                        class="checkmark"
                      />
                    </div>
                    <div class="col-10">
                      <h6 class="mb-3">
                        <img crossorigin="anonymous" 
                          v-if="item.has_media_profile === false"
                          class="pd-image"
                          src="@/assets/img_friends/default1.png"
                          alt=""
                        />
                        <img crossorigin="anonymous" 
                          v-else-if="item.media[0].collection_name === 'profile'"
                          :src="item.media[0].src_url"
                          class="pd-image"
                          alt=""
                        />
                        <img crossorigin="anonymous" 
                          v-else-if="item.media[1].collection_name === 'profile'"
                          :src="item.media[1].src_url"
                          class="pd-image"
                          alt=""
                        />
                        {{ item.name }}

                        <b style="float: right" v-if="item.pivot.role == 2">{{ $t("owner")}}</b>

                        <span style="float: right" v-else>{{ $t("Member")}}</span>
                      </h6>
                    </div>
                  </div>
                </label>
              </div>
              <span class="btn btn-sm btn-danger role-style">
                <span @click="remove_member()">{{ $t("Delete selected members") }} </span>
              </span>
            </div>
            <div v-else class="modal-body">
              <h6 class="text-center">{{ $t("No Members") }}</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CollectionsServices from "@/services/Collections/CollectionsServices";
// import { faB } from "@fortawesome/free-solid-svg-icons";
import { mapGetters } from "vuex";

export default {
  name: "ShowMembers",
  props: ["id"],
  data() {
    return {
      SingleSelect: [],
      multipleSelect: false,
      member_c: [],
    };
  },
  methods: {
    selectAllMembers() {
      if (this.multipleSelect == true) {
        this.member_c = [];
        for (var i = 0; i < this.collection_members.length; i++) {
          this.member_c.push(this.collection_members[i]["id"]);
        }
      } else {
        this.member_c = [];
      }
    },
    selectSingleMembers() {
      if (this.collection_members.length == this.SingleSelect.length) {
        this.multipleSelect = true;
      } else {
        this.multipleSelect = false;
      }
    },
    remove_member() {
      if (this.SingleSelect.length !== 0) {
        var data = new FormData();
        data.append("profile_ids", this.SingleSelect);
        data.append("collection_id", this.id);
        CollectionsServices.s_remove_member(this.SingleSelect,this.id).then(() => {
          this.$store.dispatch("collections/a_get_collection_members", this.id);
          this.showSuccess({ message: "Deleted Member Successfully" });
        });
      }
      // else {
      //   console.log(fd);
      // }
    },
  },
  computed: {
    ...mapGetters({
      collection_members: "collections/collection_members",
    }),
  },
  notifications: {
    showSuccess: {
      title: "Members",
      message: "Member Added successfully",
      type: "success",
    },
    showError: {
      title: "Members",
      message: "Something went Wrong",
      type: "error",
    },
  },
};
</script>

<style scoped lang="scss">
.modal-content {
  z-index: 99999999;
  border-radius: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.modal-header:lang(ar) {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  button {
    text-align: left !important;
  }
}

.modal-body:lang(ar) {
  h5 {
    text-align: right !important;
  }
}

.pd-image {
  width: 30px;
  height: 25px;
  margin: 0px 15px;
  border-radius: 6px;
  margin-bottom: 10px;
}

// checkbox
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.close-dir:lang(ar) {
  direction: ltr;
}
.close-dir:lang(ku) {
  direction: ltr;
}
</style>
