<template>
    <div class="modal fade" :id="'staticBackdropInteractionReels' + id" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropViewsLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropViewsLabel">{{ $t('interactions') }}</h5>
                    <div><button type="button" @click="closemodal" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                </div>
                <div v-if="!status"  class="modal-body">
        
                    <div v-if="interactionitems.length > 0">
                        <div class="d-flex mb-3 justify-content-center align-items-center" v-for="(item, index) in interactionitems" :key="index">
                            
                            <img crossorigin="anonymous"  v-if="!item.profile.has_media_profile" class="me-2 ms-2" height="35" src="@/assets/img_friends/default1.png" alt="">
                            <img crossorigin="anonymous"  v-else-if="item.profile.media[0].collection_name == 'profile'" width="35" style="border-radius: 50%;" class="me-2 ms-2" height="35" :src="item.profile.media[0].src_url" alt="">
                            <img crossorigin="anonymous"  v-else class="me-2 ms-2" style="border-radius: 50%;" height="35" width="35" :src="item.profile.media[1].src_url" alt="">
                            <h5 class="">{{ item.profile.name }}</h5>
                            <!-- {{ item.profile.media[0] }} -->
                        </div>
                    </div>
                    <div v-else>
                        <h5 class="text-center">{{ $t('nointeractions') }}</h5>
                    </div>
                </div>
                <div v-else  class="modal-body">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="spinner-grow text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="spinner-grow text-success" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InteractionReelsComponent",
    props: ["interactionitems", "id", "status"],
    methods: {
        closemodal(){
            this.$emit("playVideo")
        },
    }
}
</script>

<style scoped lang="scss">
.modal-content {
    z-index: 99999999 !important;
    // background-color: red;
    border-radius: 25px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

.modal-header:lang(ar) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    button {
        text-align: left !important;
    }
}

.modal-body:lang(ar) {
    h5 {
        text-align: right !important;
    }
}
</style>