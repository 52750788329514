<template>
  <div class="div">
    <div v-if="post">
      <div class="row mb-3 me-0 bg-white style-cart">
        <div class="card card_post">
          <div
            class="card-header bg-white border-bottom-0 d-flex justify-content-between"
          >
            <div class="d-flex align-items-center">
              <div>
                <img crossorigin="anonymous" 
                  v-if="post.model.has_media_profile === false"
                  class="pd-image"
                  src="@/assets/img_friends/default1.png"
                  alt="Profile image"
                />
                <img crossorigin="anonymous" 
                  v-else
                  :src="post.model.media[0].src_url"
                  class="pd-image"
                  alt="personal profile image"
                />
              </div>

              <div class="ms-3">
                <p class="m-0" v-if="post.model_type == 'Profile'">
                  <!-- <router-link
                    class="router-link-to-profile"
                    :to="{
                      name: 'homeMyProfile',
                      params: { id: post.model.id },
                    }"
                  > -->
                  {{ post.model.first_name }} {{ post.model.last_name }}
                  <!-- </router-link> -->
                </p>
                <p v-else class="m-0">
                  <!-- <router-link
                    class="router-link-to-profile"
                    :to="{
                      name: 'page',
                      params: { id: post.model.id },
                    }"
                  > -->
                    {{ post.model.name }}
                  <!-- </router-link> -->
                  <small style="color: green; font-weight: bold">page</small>
                </p>
                <small class="text-muted">
                  {{ format_date(post.created_at) }}
                </small>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p dir="auto" v-if="post.content" class="card-text">
              {{ post.content }}
            </p>
            <div v-if="post.has_media" class="media_container">
              <router-link
                :to="{
                  name: 'shpwpost',
                  params: { id: post.id },
                }"
              >
                <div class="media_one" v-if="post.media.length === 1">
                  <img crossorigin="anonymous" 
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
                </div>
                <div
                  class="media_two d-flex justify-content-between align-items-center"
                  v-if="post.media.length === 2"
                >
                  <div class="media_two_box">
                    <img crossorigin="anonymous" 
                      v-if="post.media[0].media_type.includes('Image')"
                      :src="post.media[0].src_url"
                      alt
                    />
                    <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
                  </div>
                  <div class="media_two_box">
                    <img crossorigin="anonymous" 
                      v-if="post.media[1].media_type.includes('Image')"
                      :src="post.media[1].src_url"
                      alt
                    />
                    <video crossorigin="anonymous"  v-else controls :src="post.media[1].src_url"></video>
                  </div>
                </div>
                <div class="media_three" v-if="post.media.length === 3">
                  <div class="media_three_box">
                    <img crossorigin="anonymous" 
                      v-if="post.media[0].media_type.includes('Image')"
                      :src="post.media[0].src_url"
                      alt
                    />
                    <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
                  </div>
                  <div class="media_three_box">
                    <img crossorigin="anonymous" 
                      v-if="post.media[1].media_type.includes('Image')"
                      :src="post.media[1].src_url"
                      alt
                    />
                    <video crossorigin="anonymous"  v-else controls :src="post.media[1].src_url"></video>
                  </div>
                  <div class="media_three_box_under">
                    <img crossorigin="anonymous" 
                      v-if="post.media[2].media_type.includes('Image')"
                      :src="post.media[2].src_url"
                      alt
                    />
                    <video crossorigin="anonymous"  v-else controls :src="post.media[2].src_url"></video>
                  </div>
                </div>
                <div class="media_four" v-if="post.media.length === 4">
                  <div class="media_four_box">
                    <img crossorigin="anonymous" 
                      v-if="post.media[0].media_type.includes('Image')"
                      :src="post.media[0].src_url"
                      alt
                    />
                    <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
                  </div>
                  <div class="media_four_box">
                    <img crossorigin="anonymous" 
                      v-if="post.media[1].media_type.includes('Image')"
                      :src="post.media[1].src_url"
                      alt
                    />
                    <video crossorigin="anonymous"  v-else controls :src="post.media[1].src_url"></video>
                  </div>
                  <div class="media_four_box">
                    <img crossorigin="anonymous" 
                      v-if="post.media[2].media_type.includes('Image')"
                      :src="post.media[2].src_url"
                      alt
                    />
                    <video crossorigin="anonymous"  v-else controls :src="post.media[2].src_url"></video>
                  </div>
                  <div class="media_four_box">
                    <img crossorigin="anonymous" 
                      v-if="post.media[3].media_type.includes('Image')"
                      :src="post.media[3].src_url"
                      alt
                    />
                    <video crossorigin="anonymous"  v-else controls :src="post.media[3].src_url"></video>
                  </div>
                </div>
              </router-link>
              <div class="media_bigger" v-if="post.media.length > 4">
                <div class="media_bigger_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[0].media_type.includes('Image')"
                    :src="post.media[0].src_url"
                    alt
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[0].src_url"></video>
                </div>
                <div class="media_bigger_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[1].media_type.includes('Image')"
                    :src="post.media[1].src_url"
                    alt
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[1].src_url"></video>
                </div>
                <div class="media_bigger_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[2].media_type.includes('Image')"
                    :src="post.media[2].src_url"
                    alt
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[2].src_url"></video>
                </div>
                <div class="media_bigger_box">
                  <img crossorigin="anonymous" 
                    v-if="post.media[3].media_type.includes('Image')"
                    :src="post.media[3].src_url"
                    alt
                  />
                  <video crossorigin="anonymous"  v-else controls :src="post.media[3].src_url"></video>
                </div>
                <p
                  class="bigger_p d-flex align-items-center justify-content-center"
                >
                  <router-link
                    style="text-decoration: none"
                    :to="{
                      name: 'shpwpost',
                      params: { id: post.id },
                    }"
                  >
                    <span>+{{ post.media.length - 4 }}</span>
                  </router-link>
                </p>
              </div>
            </div>

            <div
              class="d-flex justify-content-between align-items-center body_box"
            >
              <div class="d-flex justify-content-center align-items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.441"
                  height="11.567"
                  viewBox="0 0 12.441 11.567"
                >
                  <path
                    id="like-svgrepo-com"
                    d="M12.42,5.275A3.526,3.526,0,0,0,9.053,1.826,3.347,3.347,0,0,0,6.186,3.478a3.225,3.225,0,0,0-2.8-1.652A3.526,3.526,0,0,0,.021,5.275,3.556,3.556,0,0,0,.127,6.586,5.622,5.622,0,0,0,1.849,9.46l4.334,3.933L10.592,9.46a5.622,5.622,0,0,0,1.722-2.874A3.564,3.564,0,0,0,12.42,5.275Z"
                    transform="translate(0 -1.826)"
                    fill="#f55"
                  ></path>
                </svg>
                <!-- <span class="ms-2" >{{ post.Interactions.length }}</span> -->
              </div>
              <div>
                <span> {{ $t("comments") }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formatTime from "@/mixins/formatTime";

export default {
  name: "SharedPostComponent",
  props: ["post", "post_page"],
  mixins: [formatTime],
};
</script>

<style lang="scss" scoped>
.row {
  .card_post {
    padding: 0;
    box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
    .card-header {
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }

    .card-body {
      padding: 0;
      overflow: hidden;

      p {
        padding-left: 1.2rem;
        margin-right: 10px;
        margin-left: 10px;
      }

      .media_container {
        max-height: 700px;
        width: 100% !important;
        overflow: hidden;
        background: #ccc;
        .media_one {
          max-height: 100% !important;
          width: 100% !important;
          height: 100%;
          overflow: hidden;
          // text-align: center;

          img {
            width: 100% !important;
            height: 100% !important;
            object-fit: fill !important;
          }

          video {
            width: 100% !important;
            height: 100% !important;
            object-fit: fill !important;
          }
        }

        .media_two {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_two_box {
            width: 100%;
            height: 200px !important;
            overflow: hidden;

            img {
              width: 100%;
              margin: auto;
              display: block;
              height: 240px;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: contain;
            }
          }
        }

        .media_three {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_three_box {
            width: 50%;
            height: 50% !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 332px;
              margin: auto;
              display: block;
              height: 237px;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }

          .media_three_box_under {
            width: 100%;
            height: 50% !important;
            overflow: hidden;

            img {
              width: 100% !important;
              height: 100%;
              object-fit: contain;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .media_four {
          height: 100%;
          width: 100% !important;
          overflow: hidden;

          .media_four_box {
            width: 50% !important;
            height: 315px !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 365px;
              margin: auto;
              display: block;
              height: 315px;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .media_bigger {
          height: 100%;
          width: 100% !important;
          overflow: hidden;
          position: relative;

          .media_bigger_box {
            width: 50% !important;
            height: 249px !important;
            display: inline-block;
            overflow: hidden;

            img {
              width: 100%;
              margin: auto;
              display: block;
              height: 315px;
            }

            video {
              width: 100% !important;
              height: 100%;
              object-fit: cover;
            }
          }

          .bigger_p {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: white;
            font-weight: bolder;
            font-size: 20px;
            width: 100px;
            height: 100px;
            padding: 0 !important;
            background-color: rgba(119, 119, 119, 0.699);
            border-radius: 50%;
            cursor: pointer;
          }
        }
      }

      .body_box {
        margin-top: 1.5rem !important;
        padding: 0 2rem;
        margin-bottom: 0.5rem;
      }
      .shared {
        padding: 0 1.2rem;
      }
    }

    .card-footer {
      small {
        // margin-left: 2.5rem;
      }

      .addreaction {
        .box-reaction-items {
          display: none;
        }
        cursor: pointer;
        position: relative;

        &:hover {
          .box-reaction-items {
            display: block !important;
            position: absolute;
            background-color: black;
            color: white;
            padding: 10px;
            border-radius: 5px;
            top: -40px;
            left: 0;
            width: auto !important;
            span {
              margin: 0 5px;
            }
          }
        }
      }
      .like {
        color: blue !important;
        font-weight: bold !important;
      }
      .love {
        color: red !important;
        font-weight: bold !important;
      }

      .angry {
        color: green !important;
        font-weight: bold !important;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .card-footer {
    small {
      // margin-left: 1rem !important;
    }
  }
}

@media screen and (max-width: 992px) {
  .div {
    padding: 0 0.5rem !important;
    .row {
      // padding: 0 1rem !important;
      .style-cart {
        margin: 0 -35px 1rem -35px !important;
      }

      .card-header {
        p {
          font-size: 0.7rem !important;
        }

        .right :nth-child(1),
        .right :nth-child(2) {
          margin-right: 0.3rem !important;
        }
      }

      .card-header {
        img {
          width: 40px !important;
          height: 40px !important;
          border-radius: 50%;
        }
      }
    }
  }
}
.txt {
  margin: 0px 0px 0px -30px;
}
.router-link-to-profile {
  text-decoration: none;
  color: #111;
}
.router-link-to-profile:active {
  text-decoration: none;
  color: #111;
}
</style>
