<template>
  <CommentInteractionComponent
    :status="loadingCommentinteractionstatus"
    :interactionitems="Commentinteractionitems"
  />
  <div
    class="modal fade"
    data-bs-backdrop="static"
    id="reelscomments"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered"
    >
      <div class="modal-content">
        <div class="modal-header forlang">
          <h5 class="modal-title" id="staticBackdropLabel">
            {{ $t("AllComments") }}
          </h5>
          <div>
            <button
              type="button"
              @click="closemodal"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div v-if="!status" class="modal-body">
          <div class="container">
            <div class="row">
              <Mentionable
                    class="w-100 Mentionable"
                    :keys="['@']"
                    :items="friendstagsarray"
                    filtering-disabled
                    offset="6"
                    insert-space
                    @open="loadIssues()"
                    @search="loadIssues($event)"
                  >
                <input
                  type="text"
                  name=""
                  class="w-100"
                  :placeholder="$t(`Your comment`)"
                  v-model="comment"
                  id=""
                />
                <template #no-result>
                  <div class="allfriendsbox" v-if="loadingForNewREsultInMentaion">
                      <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Loading...</span>
                  </div>
                  <div class="allfriendsbox" v-else>
                    {{ $t('No Result') }}
                  </div>
                </template>

                <template #item-@="{ item }">
                  <div class="allfriendsbox">
                    {{ item.name }}
                  </div>
                </template>
              </Mentionable>
              <!--  message error if add empty comment  -->
              <p class="text-danger" v-if="errorifaddemptystring">
                {{ errorifaddemptystring }}
              </p>

              <div class="text-center">
                <button
                  :disabled="!comment"
                  class="btn btn-outline-success text-center mt-2"
                  @click="AddComment()"
                >
                  {{ $t("Comment") }}
                </button>
              </div>
            </div>
            <div
              v-if="addcommentstatus"
              class="row sceltonaddcomment mt-3"
            ></div>
            <div v-for="item in commentsitems" :key="item.id" class="box mt-3">
              <div
                class="row justify-content-between align-items-center forlang"
                dir="auto"
              >
                <div
                  class="col-10 d-flex text-center justify-content-start forlang"
                >
                  <div>
                    <img crossorigin="anonymous" 
                      class="reelimg"
                      width="50"
                      height="50"
                      v-if="!item.profile.has_media_profile"
                      src="@/assets/img_friends/default1.png"
                      alt=""
                    />
                    <img crossorigin="anonymous" 
                      class="reelimg"
                      width="50"
                      height="50"
                      v-else-if="
                        item.profile.media[0].collection_name == 'profile'
                      "
                      :src="item.profile.media[0].src_url"
                      alt=""
                    />
                    <img crossorigin="anonymous" 
                      class="reelimg"
                      width="50"
                      height="50"
                      v-else
                      :src="item.profile.media[1].src_url"
                      alt=""
                    />
                  </div>
                  <div class="ms-2 me-2">
                    <router-link
                      v-if="USER.id != item.profile_id"
                      class="text-decoration-none"
                      :to="`/profile/${item.profile_id}`"
                    >
                      <p data-bs-dismiss="modal" class="name mb-0 fw-bold">
                        <!-- {{ item.profile_id }} -->
                        {{ item.profile.first_name }}
                        {{ item.profile.last_name }}
                      </p>
                    </router-link>
                    <router-link
                      v-else
                      class="text-decoration-none"
                      :to="`/profile/${item.profile.id}`"
                    >
                      <p data-bs-dismiss="modal" class="name mb-0 fw-bold">
                        <!-- {{ item.profile_id }} -->
                        {{ item.profile.first_name }}
                        {{ item.profile.last_name }}
                      </p>
                    </router-link>

                    <p class="mb-0 date text-muted">
                      {{ format_date(item.created_at) }}
                    </p>
                  </div>
                </div>

                <div class="col-1">
                  <div class="dropdown text-center">
                    <small
                      style="cursor: pointer"
                      :id="'dropdownMenuButton1' + item.id"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img crossorigin="anonymous" 
                        class="image-dropdown"
                        width="20"
                        src="@/assets/images/more.png"
                        alt="action more"
                      />
                    </small>
                    <ul
                      class="dropdown-menu"
                      :aria-labelledby="'dropdownMenuButton1' + item.id"
                    >
                      <li
                        v-if="this.USER.id == item.profile_id"
                        style="cursor: pointer"
                      >
                        <a
                          class="dropdown-item"
                          @click="delete_comment(item.id)"
                        >
                          <DeleteSvg />
                          {{ $t("Delete") }}
                        </a>
                      </li>
                      <li
                        v-if="this.USER.id == item.profile_id"
                        class="dropdown-item"
                        style="cursor: pointer"
                        @click="
                          ShowEditeComment(item.id, item.content, item.tags)
                        "
                      >
                        <img crossorigin="anonymous" 
                          src="@/assets/images/edit_mode_icon.png"
                          style="width: 20px; height: 20px"
                          alt=""
                        />
                        {{ $t("Edit") }}
                      </li>
                      <li
                        v-if="this.USER.id != item.profile_id"
                        class="dropdown-item"
                        style="cursor: pointer"
                      >
                        <ReportPostSvg></ReportPostSvg>
                        {{ $t("Report Comment") }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- Body Of Comment -->
              <div class="row justify-content-center d-flex mt-2">
                <div class="col-10">
                  <div :id="`editecomment${item.id}`">
                    <div
                      v-html="this.showcontentComment(item.content, item.tags)"
                      v-linkified
                    ></div>
                  </div>
                  <div
                    :id="`inputeditecomment${item.id}`"
                    class="visually-hidden"
                  >
                  <Mentionable
                    class="w-100 Mentionable"
                    :keys="['@']"
                    :items="friendstagsarray"
                    filtering-disabled
                    offset="6"
                    insert-space
                    @open="loadIssues()"
                    @search="loadIssues($event)"
                  >
                      <textarea
                        name=""
                        v-model="editecommentinput"
                        style="height: 100%; width: 80%"
                      ></textarea>
                      <template #no-result>
                        <div class="allfriendsbox" v-if="loadingForNewREsultInMentaion">
                            <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <div class="allfriendsbox" v-else>
                          {{ $t('No Result') }}
                        </div>
                      </template>

                      <template #item-@="{ item }">
                        <div class="allfriendsbox">
                          {{ item.name }}
                        </div>
                      </template>
                    </Mentionable>

                    <button
                      v-if="!loadingStatusForEditeComment"
                      :disabled="!editecommentinput"
                      class="btn btn-sm btn-success ms-3"
                      @click="EditComment(item.id, 0, item.tags)"
                    >
                      {{ $t('Edit') }}
                    </button>
                    <button v-else class="btn btn-sm btn-success ms-3">
                      {{ $t('loading...') }}
                    </button>
                  </div>
                </div>
              </div>
              <div class="row mt-2 justify-content-center">
                <div
                  class="col-12 d-flex justify-content-between align-items-center"
                >
                  <div class="d-flex justify-content-start align-items-center">
                    <span class="replay border-0 position-relative">
                      <InteractionButton
                        :id="item.id"
                        :interacted="item.interacted"
                        @AddInteraction="AddNewInteraction"
                      />
                    </span>
                    <span
                      v-if="item.interactions_count > 0"
                      class="replay border-0"
                      data-bs-target="#staticBackdropCommentInteractionReels"
                      data-bs-toggle="modal"
                      data-bs-dismiss="modal"
                      @click="GetReelsInteraction(item.id)"
                    >
                      {{ item.interactions_count }}
                      <img crossorigin="anonymous" 
                        class=""
                        src="@/assets/reactions/like.gif"
                        alt="Like emoji"
                        height="20"
                      />
                    </span>
                  </div>
                  <div class="d-flex justify-content-end align-items-center">
                    <span
                      v-if="item.replies_count > 0"
                      :id="`showallreply${item.id}`"
                      class="replay ms-1 me-1"
                      dir="auto"
                      @click="getreplyoncomment(item.id)"
                    >
                      {{ $t("AllReply") }} <span>{{ item.replies_count }}</span>
                    </span>
                    <span
                      :id="`loadallreply${item.id}`"
                      class="replay visually-hidden"
                    >
                      {{ $t("loading....") }}
                    </span>
                    <span
                      class="replay"
                      @click="
                        ShowBoxOfAddReplyOnComment(item.id, item.profile.name)
                      "
                      >{{ $t("AddReply") }}</span
                    >
                  </div>
                </div>
              </div>

              <div
                class="addreply visually-hidden"
                ref="add_reply"
                :id="item.id"
              >
              <Mentionable
                    class="w-100 Mentionable"
                    :keys="['@']"
                    :items="friendstagsarray"
                    filtering-disabled
                    offset="6"
                    insert-space
                    @open="loadIssues()"
                    @search="loadIssues($event)"
                  >
                  <input
                    type="text"
                    name=""
                    :placeholder="$t(`Your Reply`)"
                    v-model="reply"
                    id=""
                  />
                  <template #no-result>
                        <div class="allfriendsbox" v-if="loadingForNewREsultInMentaion">
                          <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="visually-hidden">Loading...</span>
                      </div>
                      <div class="allfriendsbox" v-else>
                        {{ $t('No Result') }}
                      </div>
                    </template>
                  <template #item-@="{ item }">
                    <div class="allfriendsbox">
                      {{ item.name }}
                    </div>
                  </template>
                </Mentionable>

                <div class="text-center" v-if="!loadingaddreply">
                  <button
                    :disabled="!reply"
                    class="btn btn-outline-success text-center mt-2"
                    @click="AddReply(item.id)"
                  >
                    {{ $t("Comment") }}
                  </button>
                </div>
                <div class="text-center" v-else>
                  <button class="btn btn-outline-light text-center mt-2">
                    {{ $t("loading....") }}
                  </button>
                </div>
              </div>
              <div
                class="row mt-3"
                v-if="
                  allreplay.length > 0 && allreplay[0].commentable_id == item.id
                "
              >
                <div
                  class="ps-5 pe-5"
                  v-for="reply in allreplay"
                  :key="reply.id"
                >
                  <div class="mb-3 box1">
                    <div class="row">
                      <div
                        class="col-10 d-flex text-center justify-content-start"
                      >
                        <div>
                          <img crossorigin="anonymous" 
                            class="reelimg"
                            width="50"
                            height="50"
                            v-if="!reply.profile.has_media_profile"
                            src="@/assets/img_friends/default1.png"
                            alt=""
                          />
                          <img crossorigin="anonymous" 
                            class="reelimg"
                            width="50"
                            height="50"
                            v-else-if="
                              reply.profile.media[0].collection_name ==
                              'profile'
                            "
                            :src="reply.profile.media[0].src_url"
                            alt=""
                          />
                          <img crossorigin="anonymous" 
                            class="reelimg"
                            width="50"
                            height="50"
                            v-else
                            :src="reply.profile.media[1].src_url"
                            alt=""
                          />
                        </div>
                        <div class="ms-2">
                          <router-link
                            v-if="USER.id != item.profile_id"
                            class="text-decoration-none"
                            :to="`/profile/${item.profile_id}`"
                          >
                            <p
                              data-bs-dismiss="modal"
                              class="name mb-0 fw-bold"
                            >
                              <!-- {{ item.profile_id }} -->
                              {{ item.profile.first_name }}
                              {{ item.profile.last_name }}
                            </p>
                          </router-link>
                          <router-link
                            v-else
                            class="text-decoration-none"
                            :to="`/profile/${item.profile.id}`"
                          >
                            <p
                              data-bs-dismiss="modal"
                              class="name mb-0 fw-bold"
                            >
                              <!-- {{ item.profile_id }} -->
                              {{ item.profile.first_name }}
                              {{ item.profile.last_name }}
                            </p>
                          </router-link>
                          <p class="mb-0 date">
                            {{ format_date(reply.created_at) }}
                          </p>
                        </div>
                      </div>

                      <div class="col-2">
                        <div class="dropdown text-center">
                          <small
                            style="cursor: pointer"
                            :id="'dropdownMenuButton1Reply' + reply.id"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img crossorigin="anonymous" 
                              class="image-dropdown"
                              width="20"
                              src="@/assets/images/more.png"
                              alt="action more"
                            />
                          </small>
                          <ul
                            class="dropdown-menu"
                            :aria-labelledby="
                              'dropdownMenuButton1Reply' + reply.id
                            "
                          >
                            <li
                              v-if="this.USER.id == reply.profile_id"
                              style="cursor: pointer"
                            >
                              <a
                                class="dropdown-item"
                                @click="delete_reply(reply.id, item.id)"
                              >
                                <DeleteSvg />
                                {{ $t("Delete") }}
                              </a>
                            </li>
                            <li
                              v-if="this.USER.id == reply.profile_id"
                              class="dropdown-item"
                              style="cursor: pointer"
                              @click="
                                ShowEditeComment(
                                  reply.id,
                                  reply.content,
                                  reply.tags
                                )
                              "
                            >
                              <img crossorigin="anonymous" 
                                src="@/assets/images/edit_mode_icon.png"
                                style="width: 20px; height: 20px"
                                alt=""
                              />
                              {{ $t("Edit") }}
                            </li>
                            <li
                              v-if="this.USER.id != reply.profile_id"
                              class="dropdown-item"
                              style="cursor: pointer"
                            >
                              <ReportPostSvg></ReportPostSvg>
                              {{ $t("Report Comment") }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="row justify-content-center d-flex mt-2">
                      <div class="col-10">
                        <div :id="`editecomment${reply.id}`">
                          <div
                            v-html="
                              this.showcontentComment(reply.content, reply.tags)
                            "
                            v-linkified
                          ></div>
                          <!-- {{ reply.content }}
                          <div v-if="reply.tags.length > 0">
                            <p
                              class="mb-1 me-1 tagnameinshow"
                              v-for="tag in reply.tags"
                              :key="tag.id"
                            >
                              {{ tag.name.replaceAll(" ", "_") }}
                            </p>
                          </div> -->
                        </div>
                        <div
                          :id="`inputeditecomment${reply.id}`"
                          class="visually-hidden"
                        >
                        <Mentionable
                          class="w-100 Mentionable"
                          :keys="['@']"
                          :items="friendstagsarray"
                          filtering-disabled
                          offset="6"
                          insert-space
                          @open="loadIssues()"
                          @search="loadIssues($event)"
                        >
                            <textarea
                              name=""
                              v-model="editecommentinput"
                              style="height: 100%; width: 80%"
                            ></textarea>
                            <template #no-result>
                              <div class="allfriendsbox" v-if="loadingForNewREsultInMentaion">
                                  <span
                                  class="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span class="visually-hidden">Loading...</span>
                              </div>
                              <div class="allfriendsbox" v-else>
                                {{ $t('No Result') }}
                              </div>
                            </template>

                            <template #item-@="{ item }">
                              <div class="allfriendsbox">
                                {{ item.name }}
                              </div>
                            </template>
                          </Mentionable>
                          <button
                            v-if="!loadingStatusForEditeComment"
                            :disabled="!editecommentinput"
                            class="btn btn-sm btn-success ms-3"
                            @click="EditComment(reply.id, 1, reply.tags)"
                          >
                            {{ $t('Edit') }}
                          </button>
                          <button v-else class="btn btn-sm btn-success ms-3">
                            {{ $t('loading...') }}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-1">
                      <div class="col-12 d-flex justify-content-end">
                        <span class="replay border-0 position-relative">
                          <InteractionButtonReply
                            :id="reply.id"
                            :interacted="reply.interacted"
                            @AddInteractionReply="AddNewInteractionReply"
                          />
                        </span>
                        <span
                          v-if="reply.interactions_count > 0"
                          class="replay border-0"
                          data-bs-target="#staticBackdropCommentInteractionReels"
                          data-bs-toggle="modal"
                          data-bs-dismiss="modal"
                          @click="GetReelsInteraction(reply.id)"
                        >
                          {{ reply.interactions_count }}
                          <img crossorigin="anonymous" 
                            class="xx"
                            src="@/assets/reactions/like.gif"
                            alt="Like emoji"
                            height="22"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="modal-body">
          <div class="d-flex align-items-center justify-content-center">
            <div class="spinner-grow text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <div class="spinner-grow text-success" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReelsServices from "@/services/Reels/ReelsServices";
import DeleteSvg from "@/components/OtherComponent/SVG/DeleteSvg.vue";
import ReportPostSvg from "@/components/OtherComponent/SVG/ReportPostSvg.vue";
import CommentInteractionComponent from "@/components/HomeComponent/Reels/CommentInteractionComponent.vue";
import InteractionButton from "@/components/HomeComponent/Reels/InteractionButton.vue";
import InteractionButtonReply from "@/components/HomeComponent/Reels/InteractionButtonReply.vue";
import { mapActions, mapGetters } from "vuex";
import { Mentionable } from "vue-mention";
import formatTime from "@/mixins/formatTime";
import FriendshipService from "@/services/Friendships/FriendshipService";

export default {
  name: "CommentReels",
  props: ["id", "commentsitems", "status"],
  mixins: [formatTime],
  data() {
    return {
      comment: "",
      addcommentstatus: false,
      allreplay: [],
      idforcommenttoreply: null,
      reply: "",
      loadingaddreply: false,
      editecommentinput: null,
      editecommentid: null,
      loadingStatusForEditeComment: false,
      friends_ids: [],
      delete_friends_ids: [],
      errorifaddemptystring: null,
      loadingCommentinteractionstatus: false,
      Commentinteractionitems: [],
      loadingForNewREsultInMentaion: false,
    };
  },
  methods: {
    async loadIssues (searchText = null) { 
      if(searchText != null) {
        this.loadingForNewREsultInMentaion = true
        await FriendshipService.searchFriends(searchText).then((res) => {
          // console.log(res.data.data.items.length)
          if(res.data.FriendQuery.items.length > 0) {
            this.$store.commit("friendStore/SET_NEW_friendstagsarray", res.data.FriendQuery.items);
          }
        });
        this.loadingForNewREsultInMentaion = false

      }else {
        if(this.friendstagsarray.length < 4) {
          this.loadingForNewREsultInMentaion = true
          this.$store.commit("friendStore/SET_NEW_friendstagsarray", []);
          await FriendshipService.searchFriends(searchText).then((res) => {
            this.$store.commit("friendStore/SET_NEW_friendstagsarray", res.data.FriendQuery.items);
          })
          this.loadingForNewREsultInMentaion = false
        }
      }

    },
    AddNewInteractionReply(data) {
      // console.log("1111")
      // console.log(data)
      this.allreplay.forEach((item) => {
        if (item.id == data.comment_id) {
          // console.log("this item iss", item.interacted)
          if (item.interacted != null) {
            // console.log("yes")
            item.interacted = data.type;
            // item.interactions_count++
          } else {
            item.interactions_count++;
            item.interacted = data.type;
          }
          // console.log("this item iss after upgate", item.interacted)
        }
      });
    },
    AddNewInteraction(data) {
      this.$emit("AddNewInteraction", data);
    },
    async GetReelsInteraction(id) {
      this.loadingCommentinteractionstatus = true;
      await ReelsServices.GetCommentReelsInteraction(id).then((res) => {
        this.Commentinteractionitems = [];
        this.Commentinteractionitems = res.data.data.items;
      });
      this.loadingCommentinteractionstatus = false;
    },
    ...mapActions({
      get_all_friends: "friendStore/get_all_friends",
      get_all_friends_For_Array_tags: "friendStore/get_all_friends_For_Array_tags",

    }),
    async EditComment(id, status) {
      // console.log(this.editecommentinput)
      this.loadingStatusForEditeComment = true;
      let data = {
        comment_id: id,
      };
      let result = await this.filterCommentOrReplyContent(
        this.editecommentinput
      );

      if (result.length != 0) {
        data.content = result;
      }
      if (this.friends_ids.length > 0) {
        data.friends_ids = this.friends_ids;
      }
      // if (tags.length > 0) {
      //   await this.UpdateTagsInUpdateComment(tags);
      //   data.delete_friends_ids = this.delete_friends_ids;
      // } else {
      //   this.delete_friends_ids = [];
      //   data.delete_friends_ids = this.delete_friends_ids;
      // }
      await ReelsServices.UpdateComment(data).then((res) => {
        if (status == 0) {
          // if you are update comment so update from parent
          this.$emit("EditeComment", res.data.data);
        } else {
          // if you are update reply so update from allreply array in this component
          this.allreplay.forEach((item) => {
            if (item.id == res.data.data.id) {
              item.content = res.data.data.content;
              item.tags = res.data.data.tags;
            }
          });
        }
      });
      this.loadingStatusForEditeComment = false;
      this.friends_ids = [];
      this.delete_friends_ids = [];
      document
        .getElementById(`editecomment${id}`)
        .classList.remove("visually-hidden");
      document
        .getElementById(`inputeditecomment${id}`)
        .classList.add("visually-hidden");
    },
    async UpdateTagsInUpdateComment(tags) {
      //First add all old tags to deleted array
      tags.forEach((onetag) => {
        this.delete_friends_ids.push(onetag.id);
      });

      var delete_friends_ids = this.delete_friends_ids;

      // check if does not any changes in tags .... means update content only with the same tags
      var is_same =
        this.friends_ids.length == this.delete_friends_ids.length &&
        this.friends_ids.every(
          await function (element) {
            let a = delete_friends_ids.indexOf(element);
            return element === delete_friends_ids[a];
          }
        );
      // console.log(is_same)
      if (is_same) {
        // if true .... Example:  11 @abd @hassn to 00 @abd @hassn
        this.delete_friends_ids = [];
        return this.delete_friends_ids;
      } else {
        // if there is any changes in tags array

        if (this.friends_ids.length == 0) {
          // if deleted all tags from new update comment .... Example:  11 @abd @hassn or another tags to 00
          return this.delete_friends_ids;
        } else if (this.friends_ids.length > this.delete_friends_ids.length) {
          // if there are new tags more than old tags
          // Example:  @abd @hassn to   @ abd @hassn @mohammd
          let a = this.delete_friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          if (result.length == this.delete_friends_ids.length) {
            // we save the old tags and add new tags so delete_friends_ids array must be null
            this.delete_friends_ids = [];
          } else {
            // changes in old tags and add new tags
            // delete from delete_friends_ids array the values that will be in new tags
            result.forEach((item) => {
              const index = this.delete_friends_ids.findIndex((x) => x == item);
              this.delete_friends_ids.splice(index, 1);
            });
          }
          return this.delete_friends_ids;
        } else if (this.friends_ids.length < this.delete_friends_ids.length) {
          // if there are new tags less than old tags
          // Example:  @abd @hassn @mohammd to   @ abd @hassn
          let a = this.friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.delete_friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          // delete from delete_friends_ids array the values that will be in new tags
          result.forEach((item) => {
            const index = this.delete_friends_ids.findIndex((x) => x == item);
            this.delete_friends_ids.splice(index, 1);
          });
          return this.delete_friends_ids;
        } else {
          // change one tag to anoter one tag but not same value .... Example:  @hassn to @abd
          // console.log("mmm")
          let a = this.delete_friends_ids;
          // filter new tags with old tags and asigned to result
          // return same values between tow arrays
          let result = this.friends_ids.filter(
            await function (obj) {
              return a.indexOf(obj) !== -1;
            }
          );
          // console.log(resultd)
          result.forEach((item) => {
            const index = this.delete_friends_ids.findIndex((x) => x == item);
            this.delete_friends_ids.splice(index, 1);
          });
          // console.log(this.delete_friends_ids)
          return this.delete_friends_ids;
        }
      }
    },
    ShowEditeComment(id, content, tags) {
      // Hide display comment in card and show textarea with comment content to update
      if (this.editecommentid == null) {
        if (tags.length == 0) {
          content = content.replaceAll("^@^", "");
          this.editecommentinput = content;
        } else {
          if (content != null) {
            this.editecommentinput = content;
            tags.forEach((tag) => {
              this.editecommentinput = this.editecommentinput.replace(
                "^@^",
                " " + "@" + tag.name.replaceAll(" ", "_")
              );
            });
          } else {
            this.editecommentinput = "";
          }
        }

        this.editecommentid = id;
        document
          .getElementById(`editecomment${id}`)
          .classList.add("visually-hidden");
        document
          .getElementById(`inputeditecomment${id}`)
          .classList.remove("visually-hidden");
      } else {
        document
          .getElementById(`editecomment${this.editecommentid}`)
          .classList.remove("visually-hidden");
        document
          .getElementById(`inputeditecomment${this.editecommentid}`)
          .classList.add("visually-hidden");

        this.editecommentid = id;
        if (tags.length == 0) {
          content = content.replaceAll("^@^", "");
          this.editecommentinput = content;
        } else {
          if (content != null) {
            this.editecommentinput = content;
            tags.forEach((tag) => {
              this.editecommentinput = this.editecommentinput.replace(
                "^@^",
                " " + "@" + tag.name.replaceAll(" ", "_")
              );
            });
          } else {
            this.editecommentinput = "";
          }
        }
        document
          .getElementById(`editecomment${id}`)
          .classList.add("visually-hidden");
        document
          .getElementById(`inputeditecomment${id}`)
          .classList.remove("visually-hidden");
      }
    },
    ShowBoxOfAddReplyOnComment(id, name) {
      // show add reply form to this comment and hide reply form for all other comments
      this.reply = "@" + name.replaceAll(" ", "_") + " ";
      if (this.idforcommenttoreply != null) {
        document
          .getElementById(this.idforcommenttoreply)
          .classList.add("visually-hidden");
        document.getElementById(id).classList.remove("visually-hidden");
        this.idforcommenttoreply = id;
      } else {
        document.getElementById(id).classList.remove("visually-hidden");
        this.idforcommenttoreply = id;
      }
    },
    async AddReply(id) {
      // Add reply method
      this.loadingaddreply = true;
      let data = {
        comment_id: id,
      };

      let result = await this.filterCommentOrReplyContent(this.reply);
      //   console.log("result", result);
      //   console.log("result length", result.length);
      // console.log(this.friends_ids);
      if (result.length != 0) {
        data.content = result;
      }
      if (this.friends_ids.length > 0) {
        data.friends_ids = this.friends_ids;
      }
      //   console.log(this.friends_ids);
      // console.log(data);
      //   console.log(data);
      let newitem = null;
      await ReelsServices.addReplyOnComment(data).then((res) => {
        this.$emit("AddReply", res.data.data); // increase count replies from parent
        newitem = res.data.data;
      });
      newitem.profile = JSON.parse(localStorage.getItem("user")).profile;
      if (this.allreplay.length > 0) {
        // if allreply box open matched to this add reply comment
        if (this.allreplay[0].commentable_id == newitem.commentable_id) {
          this.allreplay.unshift(newitem);
        } else {
          // if allreply box open does not matched to this add reply comment
          // so first we have to select all reply for this comment
          await ReelsServices.getallreplyoncomment(id).then((res) => {
            this.allreplay = [];
            this.allreplay = res.data.data.items;
          });
        }
      } else {
        this.allreplay.unshift(newitem);
      }
      this.loadingaddreply = false;
      this.reply = null;
      this.friends_ids = [];
    },
    async getreplyoncomment(id) {
      if (this.allreplay.length > 0 && this.allreplay[0].commentable_id == id) {
        // if you click for this showallreply so hide replies for this comment
        this.allreplay = [];
      } else {
        // hide all reply box open and show all reply box for this comment with correct replies
        document
          .getElementById(`showallreply${id}`)
          .classList.add("visually-hidden");
        document
          .getElementById(`loadallreply${id}`)
          .classList.remove("visually-hidden");
        await ReelsServices.getallreplyoncomment(id).then((res) => {
          this.allreplay = res.data.data.items;
        });
        document
          .getElementById(`showallreply${id}`)
          .classList.remove("visually-hidden");
        document
          .getElementById(`loadallreply${id}`)
          .classList.add("visually-hidden");
      }
    },
    closemodal() {
      // close any edite comment box if open
      if (this.editecommentid != null) {
        document
          .getElementById(`editecomment${this.editecommentid}`)
          .classList.remove("visually-hidden");
        document
          .getElementById(`inputeditecomment${this.editecommentid}`)
          .classList.add("visually-hidden");
      }

      // close any box reply if open
      if (this.idforcommenttoreply != null) {
        document
          .getElementById(this.idforcommenttoreply)
          .classList.add("visually-hidden");
        // document.getElementById(id).classList.remove('visually-hidden')
      }
      this.errorifaddemptystring = null;
      this.delete_friends_ids = [];
      this.friends_ids = [];
      this.comment = "";
      this.allreplay = [];
      this.reply = "";
      this.addcommentstatus = false;
      this.idforcommenttoreply = null;
      this.loadingaddreply = false;
      this.editecommentinput = null;
      this.editecommentid = null;
      this.$emit("playVideo");
    },
    showcontentComment(content, array) {
      if (content == null) {
        array.forEach((user) => {
          let a = `<a 
          href="/profile/${user.user_id}
          "
          ><p class="mb-1 me-1" 
                    style="cursor:pointer;font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
                  >
                    ${user.name.replaceAll(" ", "_")}
                  </p></a>`;

          content = content.replace("^@^", a);
          a = null;
        });
        // console.log("AFTER",content)
        content = content.replaceAll("^@^", "");
        return content;
      }
      if (array.length == 0) {
        return content;
      } else {
        array.forEach((user) => {
          let a = `<a 
          href="/profile/${user.user_id}
          "
          ><p class="mb-1 me-1" 
                    style="cursor:pointer; background-color: #d5d2d2d2;font-weight: bold;color: blue;display: inline-block;padding: 2px 4px;"
                  >
                    ${user.name.replaceAll(" ", "_")}
                  </p></a>`;

          content = content.replace("^@^", a);
          a = null;
        });
        // console.log("AFTER",content)
        content = content.replaceAll("^@^", "");
        return content;
      }
    },
    filterCommentOrReplyContent(content) {
      if (content.match("@[^@ \t\r\n]")) {
        // if content contains tags
        //
        var output = "";
        let array = content.split(" "); // split string to array dependence on " "
        // console.log(array)
        array.forEach((item) => {
          if (item !== "") {
            if (!item.startsWith("@")) {
              // if item does not start with @ so it is content
              output = output + item + " ";
            } else {
              // if item start with @ so it is tag

              this.Allfriendstagsarray.forEach((friend) => {
                // selected id from friendstagsarray to any item start with @

                if (friend.value == item.substr(1)) {
                  if(!this.friends_ids.includes(friend.id)) {
                    output = output + "^@^ ";
                    this.friends_ids.push(friend.id);
                  }
                  
                }
              });
              // filter array and remove duplicated values
              // if for any reason the user tage to the same driend more than one
              this.friends_ids = [...new Set(this.friends_ids)];
            }
          }
        });
        if (output.startsWith(" ")) {
          output = output.substring(1);
        }
        if (output.endsWith(" ")) {
          output = output.substring(0, output.length - 1);
        }
        return output; // return string from content contain tags and store tags id in friends_ids array
      } else {
        // content does not contain any tags
        return content;
      }
    },
    async AddComment() {
      this.errorifaddemptystring = null;
      let data = {
        reel_id: this.id,
      };
      let result = await this.filterCommentOrReplyContent(this.comment);
      // result = result.replaceAll("*", '')
      //   console.log("result", result);
      //   console.log("result length", result.length);

      if (result.length != 0) {
        data.content = result;
      }
      if (this.friends_ids.length > 0) {
        data.friends_ids = this.friends_ids;
      }

      // console.log(data);
      this.comment = null;
      this.addcommentstatus = true;
      await ReelsServices.AddCommentOnReel(data)
        .then((res) => {
          //update from parent because you update comment not reply comment
          // console.log(res.data.data )
          this.$emit("AddComment", res.data.data);
        })
        .catch(() => {
          //   console.log("eee", err);
          this.errorifaddemptystring = "Please Add Valid Comment";
          this.addcommentstatus = false;
        });
      this.addcommentstatus = false;
      this.friends_ids = [];
    },
    async delete_comment(id) {
      //update from parent because you update comment not reply comment
      await ReelsServices.deleteCommentOnReel(id).then(() => {
        this.$emit("DeleteComment", id);
      });
    },
    async delete_reply(reply_id, comment_id) {
      await ReelsServices.deleteCommentOnReel(reply_id).then(() => {
        //update replies count from parent (decrease one)
        this.$emit("DecreacsRepliesCount", comment_id);

        // delete this reply from allreply array
        this.allreplay.splice(
          this.allreplay.findIndex((item) => item.id === reply_id),
          1
        );
      });
    },
  },
  computed: {
    ...mapGetters({
      friendstagsarray: "friendStore/friendstagsarray",
      Allfriendstagsarray: "friendStore/Allfriendstagsarray",
      USER: "auth/USER",

    }),
  },
  created() {
    this.friendstagsarray.length == 0 ? this.get_all_friends() : ""; // if we rfresh the page execute get all friends to have array tags
    this.Allfriendstagsarray.length == 0 ? this.get_all_friends_For_Array_tags() : ""; 

  },
  components: {
    DeleteSvg,
    ReportPostSvg,
    Mentionable,
    CommentInteractionComponent,
    InteractionButton,
    InteractionButtonReply,
  },
};
</script>

<style scoped lang="scss">
@import "floating-vue/dist/style.css";

.tagnameinshow {
  background-color: #d5d2d2d2;
  font-weight: bold;
  color: blue;
  display: inline-block;
  padding: 2px 4px;
}
.allfriendsbox {
  background-color: white !important;
  padding: 0.5rem !important;
  cursor: pointer;
  &:hover {
    background-color: rgba(60, 60, 60, 0.856) !important;
    color: white !important;
  }
}

.modal-content {
  z-index: 99999999 !important;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
}

input {
  border-radius: 10px;
  border: 1px solid rgba(0, 128, 0, 0.715);
  height: 40px;
  color: black;
  padding: 0.2rem 1rem;

  &:focus {
    outline: none;
  }
}

.reelimg {
  border-radius: 50%;
}

.addreply {
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  input {
    width: 100%;
    padding: 0 0.8rem;
    border-radius: 10px;
    border: 1px solid rgba(0, 128, 0, 0.715);
    height: 40px;
    color: black;

    &:focus {
      outline: none;
    }
  }
}

.replay {
  cursor: pointer;
  margin-right: 0.5rem;
  font-size: 12px;
  padding: 0.1rem 0.3rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.601);
  span {
    font-size: 10px;
  }
}

.modal-header:lang(ar) {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;

  button {
    text-align: left !important;
  }
}

.modal-body:lang(ar) {
  h5 {
    text-align: right !important;
  }
}

.name {
  font-size: 14px;
  text-align: start;
}
.date {
  font-size: 11px;
  text-align: start;
}
.box {
  color: black;
  padding: 0.5rem;
  border-radius: 15px;
  transition: all 0.2s ease-in-out;
  border: 1px solid #eee;
  background-color: #f8f8f8;

  // &:hover {
  //     background-color: #ffffffc4;
  //     border-color: black;
  // }
}

.forlang:lang(ar) {
  flex-direction: row-reverse;
}

.box1 {
  color: black;
  border-radius: 15px;
  padding: 0.5rem;
  transition: all 0.2s ease-in-out;
  border: 1px solid #d6d4d4;
  background-color: #f8f8f8;

  // &:hover {
  //     background-color: #ffffffc4;
  //     border-color: black;
  // }
}

.sceltonaddcomment {
  background-color: #77777790;
  color: white;
  padding: 0.5rem;
  border-radius: 15px;
  height: 110px;
  animation: skeleton-loading 2s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba($color: #7777777c, $alpha: 1);
  }
  25% {
    background-color: rgba($color: #7777774c, $alpha: 0.8);
  }
  50% {
    background-color: rgba($color: #77777733, $alpha: 0.6);
  }
  75% {
    background-color: rgba($color: #77777723, $alpha: 0.8);
  }
  100% {
    background-color: rgba($color: #77777711, $alpha: 1);
  }
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

@media screen and (max-width: 992px) {
  .reelimg {
    height: 30px;
    width: 30px;
  }

  .name {
    font-size: 13px !important;
  }

  .date {
    font-size: 11px !important;
  }

  .col-2 {
    padding-right: 1.5rem !important;
  }
  .col-1 {
    padding-right: 2rem !important;
  }
}
</style>
